import { Dispatch, FC, SetStateAction } from 'react';
import {
  Typography,
  Row,
  Col,
  Upload,
  message,
  Tooltip,
  Card,
  Image,
  Icons,
  Button,
  Spin,
} from 'taxaroo-ui';
import * as styles from './style.module.css';

const {
  DeleteOutlined,
  InboxOutlined,
} = Icons;
const { Title, Paragraph, Text } = Typography;
const { Dragger } = Upload;

interface CustomLogoProps {
  draggerProps: {
    name: string;
    multiple: boolean;
    beforeUpload: (file: File) => Promise<string | false>;
  };
  logo: string;
  setLogo: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
}

const CustomLogo: FC<CustomLogoProps> = (props) => {
  const {
    logo, draggerProps, setLogo, isLoading,
  } = props;

  return (
    <>
      <Row>
        <Col span={24}>
          <Typography className={styles.typography}>
            <Title level={5}>Step 2</Title>
            <Paragraph style={{ marginBottom: 0 }}>
              <Text><b>Your Logo</b></Text>
            </Paragraph>
            <Paragraph small>
              Optimal size: 200 pixels (width) x 100 pixels (height). Max file size: 5 MB.
            </Paragraph>
          </Typography>
        </Col>
      </Row>
      <Row gutter={2} style={{ marginTop: 18, minHeight: 200 }}>
        <Col span={24}>
          {!logo
            && (
              isLoading
                ? (
                  <Card
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Spin size="large" />
                  </Card>
                )
                : (
                  <Dragger {...draggerProps}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area
                      <br />
                      to upload your logo
                    </p>
                    <p className="ant-upload-hint">
                      The file must be a .jpeg, .jpg, or .png image
                    </p>
                  </Dragger>
                )
            )}
          {logo
            && (
            <Card
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Image
                width={275}
                src={`${logo}?cacheControl=${Math.trunc(Math.random() * 1e15)}`}
              />
              <Tooltip
                title="Delete logo"
                arrowPointAtCenter
              >
                <Button
                  type="primary"
                  danger
                  shape="circle"
                  style={{
                    cursor: 'pointer',
                    marginLeft: 15,
                    marginBottom: 5,
                  }}
                  onClick={() => {
                    setLogo('');
                    message.info("Don't forget to save changes!");
                  }}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Card>
            )}
        </Col>
      </Row>
    </>
  );
};

export default CustomLogo;
