import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetStripeCustomerQueryVariables = Types.Exact<{
  getStripeCustomerInput: Types.GetStripeCustomerInput;
}>;


export type GetStripeCustomerQuery = { __typename?: 'Query', getStripeCustomer: { __typename?: 'StripeCustomerEntity', id: string, default_source?: string | null, invoice_settings?: { __typename?: 'StripeInvoiceSettingsEntity', default_payment_method?: string | null } | null } };

export type GetCancellationPriceQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCancellationPriceQuery = { __typename?: 'Query', GetCancellationPrice: { __typename?: 'StripePriceEntity', id?: string | null, billing_scheme?: string | null, tiers_mode?: string | null, unit_amount?: number | null, unit_amount_decimal?: string | null, nickname?: string | null, product?: { __typename?: 'StripeProductEntity', id?: string | null, name: string } | null, tiers?: Array<{ __typename?: 'StripeTiersEntity', flat_amount?: number | null, flat_amount_decimal?: string | null, unit_amount?: number | null, unit_amount_decimal?: string | null, up_to?: number | null }> | null } };

export type GetPlanAccessLevelQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPlanAccessLevelQuery = { __typename?: 'Query', getPlanAccessLevel: { __typename?: 'StripePlanTypeEntity', planType?: Types.StripePlanType | null } };


export const GetStripeCustomerDocument = gql`
    query GetStripeCustomer($getStripeCustomerInput: GetStripeCustomerInput!) {
  getStripeCustomer(getStripeCustomerInput: $getStripeCustomerInput) {
    id
    default_source
    invoice_settings {
      default_payment_method
    }
  }
}
    `;

/**
 * __useGetStripeCustomerQuery__
 *
 * To run a query within a React component, call `useGetStripeCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeCustomerQuery({
 *   variables: {
 *      getStripeCustomerInput: // value for 'getStripeCustomerInput'
 *   },
 * });
 */
export function useGetStripeCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetStripeCustomerQuery, GetStripeCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeCustomerQuery, GetStripeCustomerQueryVariables>(GetStripeCustomerDocument, options);
      }
export function useGetStripeCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeCustomerQuery, GetStripeCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeCustomerQuery, GetStripeCustomerQueryVariables>(GetStripeCustomerDocument, options);
        }
export type GetStripeCustomerQueryHookResult = ReturnType<typeof useGetStripeCustomerQuery>;
export type GetStripeCustomerLazyQueryHookResult = ReturnType<typeof useGetStripeCustomerLazyQuery>;
export type GetStripeCustomerQueryResult = Apollo.QueryResult<GetStripeCustomerQuery, GetStripeCustomerQueryVariables>;
export const GetCancellationPriceDocument = gql`
    query GetCancellationPrice {
  GetCancellationPrice {
    id
    product {
      id
      name
    }
    billing_scheme
    tiers {
      flat_amount
      flat_amount_decimal
      unit_amount
      unit_amount_decimal
      up_to
    }
    tiers_mode
    unit_amount
    unit_amount_decimal
    nickname
  }
}
    `;

/**
 * __useGetCancellationPriceQuery__
 *
 * To run a query within a React component, call `useGetCancellationPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCancellationPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCancellationPriceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCancellationPriceQuery(baseOptions?: Apollo.QueryHookOptions<GetCancellationPriceQuery, GetCancellationPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCancellationPriceQuery, GetCancellationPriceQueryVariables>(GetCancellationPriceDocument, options);
      }
export function useGetCancellationPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCancellationPriceQuery, GetCancellationPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCancellationPriceQuery, GetCancellationPriceQueryVariables>(GetCancellationPriceDocument, options);
        }
export type GetCancellationPriceQueryHookResult = ReturnType<typeof useGetCancellationPriceQuery>;
export type GetCancellationPriceLazyQueryHookResult = ReturnType<typeof useGetCancellationPriceLazyQuery>;
export type GetCancellationPriceQueryResult = Apollo.QueryResult<GetCancellationPriceQuery, GetCancellationPriceQueryVariables>;
export const GetPlanAccessLevelDocument = gql`
    query GetPlanAccessLevel {
  getPlanAccessLevel {
    planType
  }
}
    `;

/**
 * __useGetPlanAccessLevelQuery__
 *
 * To run a query within a React component, call `useGetPlanAccessLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanAccessLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanAccessLevelQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlanAccessLevelQuery(baseOptions?: Apollo.QueryHookOptions<GetPlanAccessLevelQuery, GetPlanAccessLevelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanAccessLevelQuery, GetPlanAccessLevelQueryVariables>(GetPlanAccessLevelDocument, options);
      }
export function useGetPlanAccessLevelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanAccessLevelQuery, GetPlanAccessLevelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanAccessLevelQuery, GetPlanAccessLevelQueryVariables>(GetPlanAccessLevelDocument, options);
        }
export type GetPlanAccessLevelQueryHookResult = ReturnType<typeof useGetPlanAccessLevelQuery>;
export type GetPlanAccessLevelLazyQueryHookResult = ReturnType<typeof useGetPlanAccessLevelLazyQuery>;
export type GetPlanAccessLevelQueryResult = Apollo.QueryResult<GetPlanAccessLevelQuery, GetPlanAccessLevelQueryVariables>;
export const namedOperations = {
  Query: {
    GetStripeCustomer: 'GetStripeCustomer',
    GetCancellationPrice: 'GetCancellationPrice',
    GetPlanAccessLevel: 'GetPlanAccessLevel'
  }
}