/* eslint-disable max-len */
import { v4 as uuid } from 'uuid';
import { message } from 'taxaroo-ui';
import {
  ChangeEvent, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  CreateInterviewSectionInput, InterviewEntity, QuestionType, SectionEntity, UpdateInterviewInput,
} from '~src/graphql';
import { useAppDispatch, useAppSelector } from '~src/redux/hooks';
import { selectCurrentLanguage, selectSession } from '~src/redux/slices/sessionSlice';
import { useGetInterviewByIdLazyQuery, useGetInterviewNamesLazyQuery } from '~src/graphql/queries/interview';
import {
  setInterviews,
  selectInterviews,
  setCurrentInterview,
  selectCurrentInterview,
  selectCurrentInterviewData,
  clearCurrentInterview,
} from '~src/redux/slices/interviewsSlice';
import { useCreateConditionalsMutation, useUpdateConditionalsMutation } from '~src/graphql/mutations/conditional';
import {
  useCopyInterviewMutation, useCreateInterviewMutation, useCreateSpecialSectionMutation, useDeleteInterviewMutation, useUpdateInterviewMutation, useUpdateInterviewStateMutation,
} from '~src/graphql/mutations/interview';
import { omit } from 'lodash';
import {
  Interview,
  InterviewSection,
  Options as OptionsType,
  Language as LanguageType,
  Questions as QuestionsType,
  Translations as TranslationsType,
  Conditional,
} from '../templates/Interviews';
import useDebouncedEffect from './useDebouncedEffect';
import InterviewSectionList, { Question, Section } from '../organisms/InterviewSectionList';
import { UiOption } from '../organisms/ConditionalModal';
import { useUpgradeModal, Features } from '../atoms/UpgradeModal/ModalProvider';

export const TRANSLATION_REQUIRED_STRING = '❗TRANSLATION REQUIRED - ADD IT HERE❗';
export const SECTION_DEFAULT_DESCRIPTION_ES = 'ENTER YOUR SECTION NAME HERE';
export const SECTION_DEFAULT_DESCRIPTION_EN = `${SECTION_DEFAULT_DESCRIPTION_ES} - ❗SWITCH TO SPANISH ABOVE TO ADD TRANSLATION❗`;
export const QUESTION_DEFAULT_DESCRIPTION_ES = 'ENTER YOUR QUESTION HERE';
export const QUESTION_DEFAULT_DESCRIPTION_EN = `${QUESTION_DEFAULT_DESCRIPTION_ES} - ❗SWITCH TO SPANISH ABOVE TO ADD TRANSLATION❗`;
export const OPTION_DEFAULT_DESCRIPTION_ES = 'ENTER YOUR OPTION HERE';
export const OPTION_DEFAULT_DESCRIPTION_EN = `${OPTION_DEFAULT_DESCRIPTION_ES} - ❗SWITCH TO SPANISH ABOVE TO ADD TRANSLATION❗`;

const useInterviews = () => {
  const dispatch = useAppDispatch();
  const initialRender = useRef<boolean>(true);
  const interviews = useAppSelector(selectInterviews);
  // * currentLanguage is the language of the user in session
  const currentLanguage = useAppSelector(selectCurrentLanguage);
  const currentInterview = useAppSelector(selectCurrentInterview);
  const [hasTranslations, setHasTranslations] = useState<boolean>(true);
  const currentInterviewData = useAppSelector(selectCurrentInterviewData);
  const [interviewLanguage, setInterviewLanguage] = useState<LanguageType>({
    id: currentLanguage.id,
    lang: currentLanguage.lang,
    description: currentLanguage?.description ?? '',
  });
  const { languages } = useAppSelector(selectSession);
  const [interviewLanguageId, setInterviewLanguageId] = useState<string>('');
  const [selectedInterviewKey, setSelectedInterviewKey] = useState<string>('');

  const [getinterviewNames, { loading: getInterviewsLoading }] = useGetInterviewNamesLazyQuery();
  const [getInterviewById, { loading: loadingInterviewById }] = useGetInterviewByIdLazyQuery();

  const [interviewName, setInterviewName] = useState<string>(currentInterview?.name ?? '');
  const [createConditionals,
    { loading: createConditionalLoading }] = useCreateConditionalsMutation();
  const [updateConditionals,
    { loading: updateConditionalLoading }] = useUpdateConditionalsMutation();

  const [currentSectionToConditionId, setCurrentSectionToConditionId] = useState<string>('');
  const [isConditionalModalVisible, setIsConditionalModalVisible] = useState<boolean>(false);
  const [currentConditionals, setCurrentConditionals] = useState<Array<Conditional>>([]);
  const [currentOptionsToCondition, setCurrentOptionsToCondition] = useState<Array<{ value: string,
    label: string }>>([]);
  const [createInterview, { loading: createInterviewLoading }] = useCreateInterviewMutation();
  const [updateInterview, { loading: updateInterviewLoading }] = useUpdateInterviewMutation();
  const [deleteInterview, { loading: deleteInterviewLoading }] = useDeleteInterviewMutation();
  const [copyInterview, { loading: copyInterviewLoading }] = useCopyInterviewMutation();
  const [createSpecialSection,
    { loading: createSpecialSectionLoading }] = useCreateSpecialSectionMutation();

  const { showModal, accessObject } = useUpgradeModal();
  const [updateInterviewState, { loading: loadingUpdateInterviewState }] = useUpdateInterviewStateMutation();

  /*
    * Since in this component we use a different types than the UI components,
    * it is necessary to map the sections recursively in this way:  InterviewSection => Section.

    * types:
      * - InterviewSection (Type for store in redux)
      * - Section (Type for UI components)
  */
  const transformSectionsFromStoreToUi = (Sections: Array<InterviewSection>):
    Array<Section> => Sections.map(({
    Questions,
    SubSections,
    Translations,
    ...restSection
  }): Section => {
    const description = Translations.find(
      ({ Language }) => Language.id === interviewLanguageId,
    )?.description || `${TRANSLATION_REQUIRED_STRING} ${Translations.find((t) => t?.description)?.description || ''}`;

    const mappedQuestions = Questions.map(({
      Options,
      Translations: QuestionTranslations,
      ...question
    }): Question => {
      const questionDescription = QuestionTranslations?.find(
        ({ Language }) => Language.id === interviewLanguageId,
      )?.description || `${TRANSLATION_REQUIRED_STRING} ${QuestionTranslations.find((t) => t.description)?.description || ''}`;

      const mappedOptions = Options?.map(({
        id, temporalId, operation, Translations: OptionTranslations,
      }) => {
        const label = OptionTranslations.find(
          ({ Language }) => Language.id === interviewLanguageId,
        )?.description || `${TRANSLATION_REQUIRED_STRING} ${OptionTranslations.find((t) => t?.description)?.description || ''}`;

        return {
          id,
          label,
          operation,
          temporalId,
        };
      }) ?? [];

      return {
        ...question,
        Options: mappedOptions,
        label: questionDescription,
      };
    });

    return {
      ...restSection,
      name: description,
      Questions: mappedQuestions,
      SubSections: SubSections?.length ? (transformSectionsFromStoreToUi(SubSections)) : [],
    };
  });

  // * This method map from UI type to store type when a sections is new.
  const mapNewSectionToStore = (currentSection: Section, index: number): InterviewSection => {
    const { lang, id: languageId, description } = interviewLanguage;

    return {
      ...currentSection,
      order: index,
      interviewId: currentInterview.id,
      Translations: [{
        description: currentSection.name,
        Language: {
          lang,
          description,
          id: languageId,
        },
      }],
      Questions: currentSection.Questions.map((
        {
          label,
          Options,
          ...question
        },
        questionIndex,
      ) => ({
        ...question,
        sectionId: question.sectionId ?? question.temporalSectionId ?? '',
        order: questionIndex,
        Options: Options?.map(({ label: optionLabel, ...restOption }) => ({
          id: restOption.id,
          temporalId: restOption.temporalId,
          Translations: (question.type === QuestionType.Dropdown
            || question.type === QuestionType.Radio) ? [{
              description: optionLabel,
              Language: {
                lang,
                description,
                id: languageId,
              },
            }] : [],
        })) ?? [],
        Translations: [{
          description: label ?? '',
          Language: {
            lang,
            description,
            id: languageId,
          },
        }],
      })),
      SubSections: currentSection.SubSections?.map((
        subSection,
        subIndex,
      ) => (mapNewSectionToStore(subSection, subIndex))) ?? [],
    };
  };

  const getAllSections = (sections: Array<InterviewSection>):
  Array<InterviewSection> => sections?.map(({
    SubSections,
    ...restSection
  }) => [{ ...restSection, SubSections },
    ...getAllSections(SubSections ?? [])]).flatMap((element) => element) ?? [];

  /*
    * Since in this component we use a different types than the UI components,
    * it is necessary to map the sections recursively in this way: Section => InterviewSection.

    * types:
      * - Section (Type for UI components)
      * - InterviewSection (Type for store in redux)
  */
  const transformSectionsFromUiToStore = (Sections: Array<Section>):
 Array<InterviewSection> => Sections.map((section, sectionIndex) => {
    const selectedLanguage = languages.find(
      (language) => language.id === interviewLanguageId,
    );
    const { lang, id: languageId, description } = selectedLanguage;
    const {
      name, Questions, SubSections, operation, ...restSection
    } = section;

    const currentSectionData = getAllSections(currentInterviewData.Sections ?? [])
      .find(({ id, temporalId }) => (id && id === section.id) || (temporalId && temporalId === section.temporalId))
        ?? mapNewSectionToStore(section, sectionIndex);

    // * This is to pick all the questions without filtering by operation === 'delete'
    const {
      Questions: questionsFiltered,
      SubSections: filteredSubSections,
    } = {
      SubSections: [...currentSectionData.SubSections].filter(({ operation: subsectionOperation }) => subsectionOperation && subsectionOperation === 'delete'),
      Questions: [...currentSectionData.Questions].filter(({ operation: questionOperation }) => questionOperation && questionOperation === 'delete'),
    };

    return {
      ...restSection,
      operation,
      order: sectionIndex,
      interviewId: currentInterview.id,
      Conditionals: currentSectionData.Conditionals,
      Questions: [...Questions.map(({
        label, Options, operation: questionOperation, ...question
      }, questionIndex): QuestionsType => {
        const currentQuestion = currentSectionData
          .Questions.find((q) => q.id === question.id);

        return {
          ...question,
          operation: questionOperation ?? currentQuestion.operation,
          Options: (question.type === QuestionType.Radio
          || question.type === QuestionType.Dropdown)
          && Options && Options.length
            ? [
              ...(Options.map((option) => {
                const filteredOptionTranslations:
                Array<TranslationsType> = currentQuestion.Options.find(({
                  id,
                  temporalId,
                }) => ((id && id === option.id)
                  || (temporalId && temporalId === option.temporalId)
                ))?.Translations?.filter(({
                  optionId,
                  operation: translationOperation,
                }) => translationOperation === 'delete' && (optionId && optionId === option.id)) ?? [];

                const noFilteredOptionTranslations:
                 Array<TranslationsType> = currentQuestion.Options
                   .find(({ id, temporalId }) => (id && id === option.id) || (temporalId && temporalId === option.temporalId))
                   ?.Translations?.filter(({ operation: translationOperation }) => translationOperation !== 'delete')
                   .map(((translation) => {
                     if (translation.Language.id === languageId) {
                       return {
                         ...translation,
                         description: option.label ?? '',
                         optionId: option.id ?? option.temporalId,
                         operation: translation.operation ?? option.operation,
                       };
                     }

                     return translation;
                   }))
                    ?? [{
                      description: option.label,
                      operation: option.operation,
                      optionId: option.id ?? option.temporalId,
                      Language: {
                        lang,
                        id: languageId,
                        description: description ?? '',
                      },
                    }];

                return {
                  id: option.id ?? currentQuestion.Options.find(({ id }) => id === option.id)?.id,
                  questionId: option.questionId ?? currentQuestion.id ?? currentQuestion.temporalId,
                  operation: option.operation ?? currentQuestion.Options
                    .find(({ id }) => id && id === option.id)?.operation,
                  temporalId: option.temporalId,
                  Translations: [
                    ...noFilteredOptionTranslations,
                    ...filteredOptionTranslations,
                  ],
                };
              })),
              ...(currentQuestion.Options?.filter(({ operation: optionOperation }) => optionOperation && optionOperation === 'delete' as const) ?? []),
            ]
            : [],
          sectionId: section.id ?? section.temporalId ?? '',
          order: questionIndex,
          Translations: currentQuestion
            ?.Translations?.length
            ? (currentQuestion
              .Translations.map((translation) => {
                if (translation.Language.id === interviewLanguageId) {
                  return {
                    ...translation,
                    description: label,
                    operation: translation.operation ?? questionOperation,
                  };
                }

                return translation;
              }))
            : ([{
              questionId: question.id ?? question.temporalId ?? '',
              description: label.length ? label : '',
              Language: {
                lang,
                id: languageId,
                description: description ?? '',
              },
            }]),
        };
      }), ...questionsFiltered].sort(({ order }, { order: orderB }) => order - orderB),
      SubSections: [...transformSectionsFromUiToStore(SubSections ?? []),
        ...(filteredSubSections ?? [])],
      Translations: currentSectionData && currentSectionData.Translations
      && currentSectionData.Translations.length
        ? currentSectionData.Translations.map((translation) => {
          if (translation.Language.id === interviewLanguageId) {
            return {
              ...translation,
              description: name,
              operation: translation.operation ?? operation,
            };
          }

          return translation;
        })
        : [{
          description: name,
          sectionId: restSection.id,
          Language: {
            id: currentLanguage.id,
            lang: currentLanguage.lang,
            description: currentLanguage?.description,
          },
        }],
    };
  });

  // eslint-disable-next-line max-len
  // * This functionality is for transform the type from the Graphql output to the UI type that we need
  const mapSectionsToStore = (sections: Array<SectionEntity>):
  Array<InterviewSection> => sections.map(({
    id,
    order,
    parentId,
    Questions,
    isSpecial,
    interviewId,
    SubSections,
    Translations,
    Conditionals,
  }): InterviewSection => ({
    id,
    order,
    parentId,
    isSpecial,
    interviewId,
    Conditionals,
    Questions: Questions.map(({
      type,
      Options,
      required,
      sectionId,
      id: questionId,
      order: questionOrder,
      Translations: QuestionTranslations,
    }) => ({
      type,
      Options: Options.map(({ id: optionId, Translations: OptionTranslations }) => ({
        questionId,
        id: optionId,
        Translations: OptionTranslations.map(({
          id: optionTranslationId, description, Language, languageId,
        }) => ({
          id: optionTranslationId,
          optionId,
          languageId,
          description,
          Language: {
            id: Language?.id,
            lang: Language?.lang,
            description: Language?.description,
          },
        })),
      })),
      required,
      sectionId,
      id: questionId,
      order: questionOrder,
      Translations: QuestionTranslations.map(({
        Language,
        languageId,
        description,
        id: translationId,
        questionId: translationQuestionId,
      }) => ({
        languageId,
        description,
        id: translationId,
        questionId: translationQuestionId,
        Language: {
          id: Language.id,
          lang: Language.lang,
          description: Language?.description ?? '',
        },
      })),
    })),
    Translations: Translations.map(({
      id: translationId, sectionId: translationSectionId, description, Language, languageId,
    }) => ({
      languageId,
      description,
      id: translationId,
      sectionId: translationSectionId,
      Language: {
        id: Language.id,
        lang: Language.lang,
        description: Language?.description ?? '',
      },
    })),
    SubSections: mapSectionsToStore(SubSections ?? []),
  }));

  // * Map the getInterviews query result as expected in the interviews slice
  const mapInterviewsToStore = (interviewList: Array<InterviewEntity>) => interviewList.map(({
    id,
    name,
    isLive,
    Sections,
    isTemplate,
  }) => ({
    id,
    name,
    isLive,
    isTemplate,
    Sections: mapSectionsToStore(Sections),
  }));

  const handleInterviewLanguageChange = (languageId: string) => {
    setInterviewLanguageId(languageId);
  };

  const onInterviewNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInterviewName(event.target.value);
    dispatch(setCurrentInterview({ operation: 'update' }));
  };

  const onAddSectionClick = () => {
    const sectionId: string = uuid();
    const questionId: string = uuid();
    const selectedLanguage = languages.find(
      (language) => language.id === interviewLanguageId,
    );
    const { id: languageId, lang, description } = selectedLanguage;
    const newSection: InterviewSection = {
      isSpecial: false,
      temporalId: uuid(),
      operation: 'create',
      interviewId: currentInterview.id,
      order: currentInterview.Sections.length,
      Translations: [
        {
          sectionId,
          description: lang === 'EN' ? SECTION_DEFAULT_DESCRIPTION_EN : SECTION_DEFAULT_DESCRIPTION_ES,
          operation: 'create',
          Language: {
            lang,
            id: languageId,
            description: description ?? '',
          },
        },
      ],
      Questions: [
        {
          order: 0,
          sectionId,
          required: true,
          operation: 'create',
          temporalId: questionId,
          type: QuestionType.Text,
          Translations: [
            {
              questionId,
              description: lang === 'EN' ? QUESTION_DEFAULT_DESCRIPTION_EN : QUESTION_DEFAULT_DESCRIPTION_ES,
              operation: 'create',
              Language: {
                lang,
                id: languageId,
                description: description ?? '',
              },
            },
          ],
        },
      ],
      SubSections: [],
    };

    dispatch(setCurrentInterview({
      Sections: [...currentInterviewData.Sections, newSection],
    }));
  };

  /*
    * This function checks if an array of translations already has the translation of the language
    * selected in the interview, if it already exists it returns the same
    * array and if not it creates its translation.
  */
  const handleLanguageTranslations = (translations: Array<TranslationsType>):
  Array<TranslationsType> => {
    const alreadyHasTranslation = translations
      .some(({ Language }) => Language.id === interviewLanguageId);

    if (alreadyHasTranslation) {
      if (translations.filter(({ Language }) => Language.id === interviewLanguageId)
        .some(({ description }) => !description.length || description.startsWith(TRANSLATION_REQUIRED_STRING))) {
        setHasTranslations(false);
      } else if (!hasTranslations) {
        setHasTranslations(true);
      }

      const currentLang = translations.find(
        ({ Language }) => Language.id === interviewLanguageId,
      );

      if (currentLang.id !== interviewLanguage.id) {
        setInterviewLanguage(currentLang.Language);
      }

      return translations;
    }

    const selectedLanguage = languages.find(
      (language) => language.id === interviewLanguageId,
    );

    if (hasTranslations) {
      setHasTranslations(false);
    }

    const newTranslation = {
      operation: 'create' as const,
      description: '',
      Language: {
        id: selectedLanguage.id,
        lang: selectedLanguage.lang,
        description: selectedLanguage?.description ?? '',
      },
    };

    return [...translations, newTranslation];
  };

  // * Map the sections array recursively with the usage of the handleLangueageTranslations method
  const mapSectionsTranslations = (sections: Array<InterviewSection>) => sections.map(({
    Translations, Questions, SubSections, ...section
  }) => ({
    ...section,
    Translations: handleLanguageTranslations(Translations ?? []),
    Questions: Questions.map(({
      Options,
      Translations: QuestionTranslations,
      ...question
    }) => ({
      ...question,
      Translations: handleLanguageTranslations(QuestionTranslations ?? []),
      Options: Options?.map(({ Translations: OptionTranslations, ...option }) => ({
        ...option,
        Translations: handleLanguageTranslations(OptionTranslations),
      })) ?? [],
    })),
    SubSections: mapSectionsTranslations(SubSections ?? []),
  }));

  const loadInterviewByIdToStore = async (id: string) => {
    if (!id) return;

    const currentInterviewResult = await getInterviewById({
      variables: {
        id,
      },
    });

    const clickedInterviewData = currentInterviewResult?.data?.interview;
    if (clickedInterviewData) {
      const clickedInterview = {
        id: clickedInterviewData.id,
        name: clickedInterviewData.name,
        isLive: clickedInterviewData.isLive,
        isTemplate: clickedInterviewData.isTemplate,
        Sections: mapSectionsToStore(clickedInterviewData.Sections),
      };

      dispatch(setCurrentInterview(clickedInterview));
      dispatch(setInterviews(interviews.map((interview) => {
        const { id: inerviewId } = interview;
        if (inerviewId === clickedInterview.id) {
          return clickedInterview;
        }
        return interview;
      })));
    }
  };

  const handleInterviewClick = async ({ key }: { key: string }) => {
    // console.log("interview click", key);
    if (currentInterview?.id && currentInterview?.id !== key) {
      if (!accessObject?.[Features.entityInterviews]) {
        showModal(Features.entityInterviews);
        return;
      }
    }
    await loadInterviewByIdToStore(key);
    setSelectedInterviewKey(key);

    /* if (currentInterview) {
      if ((currentInterview.id && currentInterview.id !== key)
      || (currentInterview.temporalId && currentInterview.temporalId !== key)) {
        setHasTranslations(true);
        dispatch((clearCurrentInterview()));
      }

      if (interviewLanguage.id !== currentLanguage.id) {
        const { id, lang, description } = currentLanguage;
        setInterviewLanguage({
          id,
          lang,
          description: description ?? '',
        });
      }
    } */
  };

  /*
    * This functionality allows us to know if you have any element without
    * any translation (such as an empty string) through the 3 levels of depth that can
    * have a section
  */
  const hasPendingTranslations = (sections:
      Array<InterviewSection>) => sections.reduce((acc, section) => {
    const hasPendingSectionTranslations = section.Translations
      .filter(({ Language }) => Language.id === interviewLanguageId)
      .some(({ description }) => !description.length || description.startsWith(TRANSLATION_REQUIRED_STRING));

    const checkPendingQuestionTranslations = section.Questions.reduce((
      questionAcc,
      question,
    ) => {
      const hasPendingQuestionTranslations = question.Translations
        .filter(({ Language }) => Language.id === interviewLanguageId)
        .some(({ description }) => !description.length || description.startsWith(TRANSLATION_REQUIRED_STRING));

      const checkPendingOptionTranslations = (options: Array<OptionsType>) => {
        if ((question.type === QuestionType.Radio || question.type === QuestionType.Dropdown)
            && (options.length === 0)) {
          return { [`hasPendingOptionTranslations-${question.temporalId ?? question.id}`]: true };
        } if ((question.type === QuestionType.Radio
            || question.type === QuestionType.Dropdown) && options.length > 0) {
          return options.reduce((optionAcc, option, optionIndex) => {
            const hasPendingOptionTranslations = option.Translations
              ?.filter(({ Language, operation: optionOperation }) => Language.id === interviewLanguageId && (!optionOperation || optionOperation !== 'delete')).some((
                { description },
              ) => !description.length || description.startsWith(TRANSLATION_REQUIRED_STRING)) ?? false;

            return {
              ...optionAcc,
              [`hasPendingOptionTranslations-${option.temporalId ?? option.id ?? question.temporalId ?? question.id ?? optionIndex}`]: hasPendingOptionTranslations,
            };
          }, {});
        }

        return null;
      };

      return {
        ...questionAcc,
        ...(checkPendingOptionTranslations(question.Options ?? []) ?? {}),
        [`hasPendingQuestionTranslations-${question.temporalId ?? question.id}`]: hasPendingQuestionTranslations,
      };
    }, {});

    return {
      ...acc,
      ...checkPendingQuestionTranslations,
      ...(section.SubSections && section.SubSections.length
        ? (hasPendingTranslations(section.SubSections)) : {}),
      [`hasPendingSectionTranslations-${section.temporalId ?? section.id}`]: hasPendingSectionTranslations,
    };
  }, {});

  const onSectionsChangeHandler = (newSections: Array<Section>) => {
    const changes = transformSectionsFromUiToStore(newSections);
    const hasPendingLanguageTranslations = hasPendingTranslations(changes);

    if (Object.values(hasPendingLanguageTranslations).some((value) => !!value)) {
      setHasTranslations(false);
    } else if (Object.values(hasPendingLanguageTranslations).every((value) => !value)) {
      setHasTranslations(true);
    }

    dispatch(setCurrentInterview({
      Sections: [...currentInterviewData.Sections.filter(({ operation }) => operation && operation === 'delete'), ...changes],
    }));
  };

  const onAddConditionalHandler = (section: Section, index: number) => {
    const [{ id: sectionId, Conditionals }] = transformSectionsFromUiToStore([section]);

    // * Get the parent section
    const { Questions } = getAllSections(currentInterview.Sections ?? [])
      .find(({ id }) => id === section.parentId) ?? { Questions: [] };

    const optionsToConditioning = Questions.filter(({
      type,
      Options,
    }) => (type === QuestionType.Dropdown
      || type === QuestionType.Radio)
    && Options && Options.length).map(({ Options }) => Options).flatMap((option) => option)
      .map(({ id, Translations }) => {
        const currentLabel = Translations.find(({
          Language,
        }) => Language.id === interviewLanguageId)?.description;

        return {
          value: id,
          label: currentLabel,
        };
      });

    setCurrentSectionToConditionId(sectionId);
    setCurrentConditionals(Conditionals);
    setCurrentOptionsToCondition(optionsToConditioning);
    setIsConditionalModalVisible(true);
  };

  const handleConditionalOk = async (result: {
    selectedOptions: Array<UiOption> }) => {
    if (!currentConditionals || !currentConditionals.length) {
      const newConditionals = (await createConditionals({
        variables: {
          createConditionalsInput: result.selectedOptions.map(({ value: optionId }) => ({
            optionId,
            sectionId: currentSectionToConditionId,
          })),
        },
      })).data?.createConditionals;

      if (newConditionals.length) {
        const refreshedInterview = (await getInterviewById({
          variables: {
            id: currentInterview.id,
          },
        })).data.interview;

        dispatch(setCurrentInterview(refreshedInterview));
        dispatch(setInterviews(interviews.map((interview) => {
          const { id } = interview;
          if (id === refreshedInterview.id) {
            return refreshedInterview;
          }

          return interview;
        })));
        setIsConditionalModalVisible(false);
        setCurrentOptionsToCondition([]);
      }
    } else {
      const updates = currentConditionals.filter(({ id, optionId }) => {
        const currentOption = result.selectedOptions
          .find(({ value }) => value === optionId);

        return currentOption?.value.length;
      }).map(({ id, optionId }) => ({
        id,
        optionId,
      }));

      const creates = result.selectedOptions.filter(({ value, label }) => {
        const hasCondtional = currentConditionals.find(({ optionId }) => optionId === value);

        return !hasCondtional;
      }).map(({ value }) => ({
        optionId: value,
        sectionId: currentSectionToConditionId,
      }));

      let updateResult = [];
      let createResult = [];

      if (updates.length) {
        const updatedConditionals = (await updateConditionals({
          variables: {
            updateConditionalInput: updates,
          },
        })).data?.updateConditionals ?? [];

        if (updatedConditionals.length) {
          updateResult = updatedConditionals;
        }
      }
      if (creates.length) {
        const newConditionals = (await createConditionals({
          variables: {
            createConditionalsInput: creates,
          },
        })).data?.createConditionals ?? [];

        if (newConditionals.length) {
          createResult = newConditionals;
        }
      }

      if (!!([...updateResult, ...createResult].length) && currentInterview.id) {
        const refreshedInterview = (await getInterviewById({
          variables: {
            id: currentInterview.id,
          },
        })).data.interview;

        dispatch(setCurrentInterview(refreshedInterview));
        dispatch(setInterviews(interviews.map((interview) => {
          const { id } = interview;
          if (id === refreshedInterview.id) {
            return refreshedInterview;
          }

          return interview;
        })));
        setIsConditionalModalVisible(false);
        setCurrentOptionsToCondition([]);
      }
    }
  };

  const handleConditionalCancel = () => {
    if (currentConditionals && currentConditionals.length) {
      setCurrentConditionals([]);
    }
    setCurrentOptionsToCondition([]);
    setIsConditionalModalVisible(false);
  };

  const handleCreateInterviewClick = () => {
    if (!accessObject?.[Features.entityInterviews]) {
      showModal(Features.entityInterviews);
      return;
    }
    const temporalId: string = uuid();
    const newInterview: Interview = {
      temporalId,
      Sections: [],
      isLive: false,
      isTemplate: false,
      name: 'New Interview',
    };

    dispatch(setInterviews([...interviews, newInterview]));
    if (currentInterview) {
      if (interviewLanguage.id !== currentLanguage.id) {
        const { id, lang, description } = currentLanguage;

        setInterviewLanguage({
          id,
          lang,
          description: description ?? '',
        });
      }

      dispatch(clearCurrentInterview());
    }
    setSelectedInterviewKey(temporalId);
  };

  const onSaveChangesClick = async () => {
    const {
      id, temporalId, name, isLive, isTemplate, Sections,
    } = currentInterview;

    try {
      if (temporalId) {
        const getSectionsToCreate: (sections: Array<InterviewSection>) =>
         Array<CreateInterviewSectionInput> = (sections) => sections
           .map(({
             Questions, SubSections, Translations, ...restSection
           }) => {
             const { order } = restSection;

             return {
               order,
               translations: Translations.map(({ description, Language }) => {
                 const { id: languageId } = Language;

                 return {
                   languageId,
                   description,
                 };
               }),
               questions: Questions.map(({
                 Translations: QuestionTranslations, Options, required, order: questionOrder, type,
               }) => ({
                 type,
                 required,
                 order: questionOrder,
                 options: Options.map(({ Translations: OptionTranslations }) => ({
                   translations: OptionTranslations.map(({ description, Language }) => ({
                     description,
                     languageId: Language.id,
                   })),
                 })),
                 translations: QuestionTranslations.map(({ description, Language }) => {
                   const { id: languageId } = Language;

                   return {
                     languageId,
                     description,
                   };
                 }),
               })),
               subSections: getSectionsToCreate(SubSections ?? []),
             };
           });

        const newInterview = (await createInterview({
          variables: {
            createInterviewInput: {
              name,
              isLive,
              isTemplate,
              sections: getSectionsToCreate(Sections),
            },
          },
        })).data.CreateInterview;

        if (newInterview.id) {
          const {
            name: newName,
            id: interviewId,
            isLive: newIsLive,
            Sections: newSections,
            isTemplate: newIsTemplate,
          } = newInterview;

          dispatch(setInterviews(interviews.map((interview) => {
            if (interview.temporalId && currentInterview.temporalId
              && interview.temporalId === currentInterview.temporalId) {
              return {
                name: newName,
                id: interviewId,
                temporalId: null,
                isLive: newIsLive,
                isTemplate: newIsTemplate,
                Sections: mapSectionsToStore(newSections),
              };
            }

            return interview;
          })));

          dispatch(setCurrentInterview({
            name: newName,
            id: interviewId,
            isLive: newIsLive,
            isTemplate: newIsTemplate,
            Sections: mapSectionsToStore(newSections),
          }));

          setSelectedInterviewKey(interviewId);
          message.success('The Interview was created successfully');
        }
      } else if (id) {
        const {
          Sections: currentSections,
          temporalId: currentInterviewId,
          ...currentData
        } = currentInterviewData;

        const getTranslationsWithOperations = (translations:
          Array<TranslationsType>) => translations.filter(({ operation }) => !!operation)
          .map(({
            id: translationId, optionId, sectionId, Language, questionId, description, operation,
          }) => ({
            optionId,
            sectionId,
            operation,
            questionId,
            description,
            id: translationId,
            languageId: Language.id,
          }));

        const getOptionsWithOperations = (options:
          Array<OptionsType>) => options.filter(({ operation }) => !!operation)
          .map((option) => {
            const { Translations } = option;
            const translationsWithOperations = getTranslationsWithOperations(Translations);
            const { temporalId: temporalOptionId, questionId, ...restOption } = option;

            return {
              ...restOption,
              Translations: translationsWithOperations,
            };
          });

        const getQuestionsWithOperations = (questions:
          Array<QuestionsType>) => questions.filter(({ operation, Options }) => !!operation
          || Options.some(({ operation: optionOperation }) => !!optionOperation))
          .map((question) => {
            const { Translations, Options } = question;
            const translationsWithOperations = getTranslationsWithOperations(Translations);
            const optionsWithOperations = getOptionsWithOperations(Options);

            const {
              temporalId: temporalQuestionId, temporalSectionId, operation, ...restQuestion
            } = question;

            return {
              ...restQuestion,
              ...(!operation && optionsWithOperations.some(({ operation: optionOperation }) => !!optionOperation) ? { operation: 'update' } : { operation }),
              Options: optionsWithOperations,
              Translations: translationsWithOperations,
            };
          });

        const getSectionsWithOperations = (sections:
          Array<InterviewSection>) => sections.filter(({
          operation, SubSections,
        }) => !!operation
          || SubSections.some(({ operation: subOperation }) => subOperation))
          .map((section) => {
            const {
              Questions, Translations, SubSections: currentSubSections,
            } = section;
            const questionsWithOperations = getQuestionsWithOperations(Questions.map((question) => ({
              ...question,
              ...(!question.operation && question.Options.some(({ operation: optionOperation }) => optionOperation) ? { operation: 'update' } : { operation: question.operation }),
            })));
            const translationsWithOperations = getTranslationsWithOperations(Translations);
            const subSectionsWithOperations = getSectionsWithOperations(currentSubSections ?? []);

            const {
              parentId,
              isSpecial,
              operation,
              Conditionals,
              temporalParentId,
              temporalId: temporalSectionId,
              ...restSection
            } = section;

            return {
              ...restSection,
              parentId,
              ...(!operation && ((subSectionsWithOperations
                .some(({ operation: subOperation }) => !!subOperation))
                || questionsWithOperations.some(({ operation: questionOperation }) => !!questionOperation)) ? { operation: 'update' } : { operation }),
              Questions: questionsWithOperations,
              SubSections: subSectionsWithOperations,
              Translations: translationsWithOperations,
            };
          });

        const interviewOperations = {
          id: currentData.id,
          ...(currentData.operation
            ? {
              ...currentData,
            }
            : {}
          ),
          ...(currentSections.length
            ? {
              Sections: getSectionsWithOperations(currentSections),
            }
            : {}
          ),
        };

        const savedChanges = (await updateInterview({
          variables: {
            updateInterviewInput: omit(interviewOperations, ['createAt', 'updateAt', '__typename']) as UpdateInterviewInput,
          },
        })).data?.updateInterview;

        if (savedChanges && savedChanges.id) {
          const newInterview = {
            ...savedChanges,
            Sections: mapSectionsToStore(savedChanges.Sections),
          };

          dispatch(setCurrentInterview(newInterview));
          dispatch(setInterviews(interviews.map((interview) => {
            if (interview.id === newInterview.id) {
              return newInterview;
            }

            return interview;
          })));
          message.success('The changes was saved successfully!');
        }
      }
    } catch (error) {
      message.error(error?.message || 'Something went worng while trying to save interview changes');
    }
  };

  const onDeleteInterviewClick = async () => {
    if (!accessObject?.[Features.entityInterviews]) {
      showModal(Features.entityInterviews);
      return;
    }
    if (currentInterview.temporalId && currentInterview.temporalId.length) {
      dispatch(clearCurrentInterview());
      dispatch(
        setInterviews(interviews
          .filter(({ temporalId, id }) => (!id && temporalId
            && temporalId !== currentInterview.temporalId)
            || (id && id.length))),
      );
      setSelectedInterviewKey(undefined);
    } else if (currentInterview.id?.length) {
      try {
        const deleteSuccess = !!(await deleteInterview({
          variables: {
            id: currentInterview.id,
          },
        })).data?.softDeleteInterview.success;

        if (deleteSuccess) {
          dispatch(clearCurrentInterview());
          dispatch(setInterviews(interviews.filter(({
            id,
            temporalId,
          }) => (id && id.length && id !== currentInterview.id)
          || (temporalId && temporalId.length))));
          setSelectedInterviewKey(undefined);
        }
      } catch (error) {
        message.error('Something went wrong while trying to delete this Interview');
      }
    }
  };

  const onCopyInterviewClick = async () => {
    if (!accessObject?.[Features.entityInterviews]) {
      showModal(Features.entityInterviews);
      return;
    }
    const { id } = currentInterview;
    const copiedInterview = (await copyInterview({
      variables: {
        id,
      },
    })).data.copyInterview;

    dispatch(setInterviews([...interviews, ...(mapInterviewsToStore([copiedInterview]) ?? [])]));
    setSelectedInterviewKey(copiedInterview.id);
  };

  const handleSpecialSectionClick = async ({ key: type } : { key: any }) => {
    try {
      const newSpecialSection = (await createSpecialSection({
        variables: {
          createSpecialSectionInput: {
            type,
            parentId: null,
            interviewId: currentInterview.id,
            order: currentInterview.Sections.length,
          },
        },
      })).data?.createSpecialSection;

      if (newSpecialSection && newSpecialSection.id) {
        const [sectionToSave] = mapSectionsToStore([newSpecialSection]);
        dispatch(setCurrentInterview({
          ...currentInterview,
          Sections: [...currentInterview.Sections, sectionToSave],
        }));
        dispatch(setInterviews(interviews.map((interview) => {
          if (interview.id && interview.id === currentInterview.id) {
            return {
              ...interview,
              Sections: [...interview.Sections, sectionToSave],
            };
          }

          return interview;
        })));
        message.success('The Special Section Was Added Successfully!');
      }
    } catch (error) {
      message.error('Something went wrong while trying to add the special section');
    }
  };

  const SectionList = useMemo(() => (
    <InterviewSectionList
      onAddConditional={onAddConditionalHandler}
      onSectionsChange={onSectionsChangeHandler}
      sections={transformSectionsFromStoreToUi(currentInterview?.Sections ?? [])}
      interviewLanguage={interviewLanguage}
    />
  ), [interviewLanguageId, currentInterview.Sections]);

  const changeInterviewStatus = async () => {
    if (!accessObject?.[Features.entityInterviews]) {
      showModal(Features.entityInterviews);
      return;
    }
    if (currentInterview && currentInterview.id) {
      const result = await updateInterviewState({
        variables: {
          id: currentInterview.id,
          isLive: !currentInterview.isLive,
        },
      });

      const updatedInterview = result.data?.updateInterviewState;
      if (updatedInterview) {
        dispatch(setCurrentInterview(updatedInterview));
        dispatch(
          setInterviews(interviews
            .map((interview) => {
              if (interview.id === updatedInterview.id) {
                return updatedInterview;
              }
              return interview;
            })),
        );
        message.success('The changes was saved successfully!');
      } else {
        message.error(result.errors?.join(','));
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const fetchInterviews = async () => getinterviewNames({ variables: { filterLiveInterviews: false } });

    fetchInterviews().then((data) => {
      // dispatch(setInterviews(mapInterviewsToStore(data)));
      const interviewInitial = data?.data.interviewNames
        ?.slice()
        ?.sort((a, b) => (a.isLive && !b.isLive ? -1 : 0)) || [];

      dispatch(setInterviews(mapInterviewsToStore(interviewInitial.map((i) => ({
        ...i,
        Sections: [],
      })))));

      if (!accessObject?.[Features.entityInterviews]) {
        setSelectedInterviewKey(interviewInitial.filter((i) => i?.name?.toLowerCase().includes('individual'))[0]?.id || interviewInitial[0]?.id);
      } else {
        setSelectedInterviewKey(interviewInitial[0]?.id);
      }
    }).catch(() => {
      message.error('Something went wrong while trying to fetch the Interviews');
    });

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (interviews.length) {
      const current: Interview = interviews.find(
        (interview) => (!interview.temporalId && interview.id
          && interview.id === selectedInterviewKey)
          || (interview.temporalId && !interview.id
            && interview.temporalId === selectedInterviewKey),
      );

      if ((current?.id?.length && current?.id === selectedInterviewKey)
      || (current?.temporalId?.length && current?.temporalId === selectedInterviewKey)) {
        dispatch(setCurrentInterview({
          ...current,
          ...(current.id ? { operation: 'update' } : {}),
        }));
        setInterviewName(current.name);
        setInterviewLanguageId(currentLanguage.id);
      }

      loadInterviewByIdToStore(selectedInterviewKey);
    }
  }, [selectedInterviewKey]);

  useEffect(() => {
    if (!selectedInterviewKey && interviews.length) {
      setSelectedInterviewKey(interviews[0].id ?? interviews[0].temporalId ?? '');
    }
  }, [selectedInterviewKey, interviews]);

  useDebouncedEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (currentInterview && currentInterview.name !== interviewName) {
      dispatch(setCurrentInterview({
        name: interviewName,
      }));
    }
  }, 500, [interviewName]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (currentInterview) {
      const translatedSections = mapSectionsTranslations(currentInterview.Sections ?? []);

      dispatch(setCurrentInterview({ Sections: translatedSections }));
    }
  }, [interviewLanguageId]);

  return {
    interviews,
    SectionList,
    interviewName,
    hasTranslations,
    currentInterview,
    onAddSectionClick,
    interviewLanguage,
    mapSectionsToStore,
    onSaveChangesClick,
    interviewLanguageId,
    handleConditionalOk,
    currentConditionals,
    getInterviewsLoading,
    mapInterviewsToStore,
    handleInterviewClick,
    selectedInterviewKey,
    onCopyInterviewClick,
    copyInterviewLoading,
    changeInterviewStatus,
    onInterviewNameChange,
    createInterviewLoading,
    onDeleteInterviewClick,
    updateInterviewLoading,
    deleteInterviewLoading,
    handleConditionalCancel,
    createConditionalLoading,
    updateConditionalLoading,
    isConditionalModalVisible,
    currentOptionsToCondition,
    handleSpecialSectionClick,
    handleCreateInterviewClick,
    createSpecialSectionLoading,
    handleInterviewLanguageChange,
    transformSectionsFromUiToStore,
    loadingInterviewById,
    loadingUpdateInterviewState,
  };
};

export default useInterviews;
