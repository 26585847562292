import { ApolloError } from '@apollo/client';
import { FC } from 'react';
import {
  Dropzone,
  Icons,
  Typography,
} from 'taxaroo-ui';
import Papa from 'papaparse';

const { DownloadOutlined } = Icons;
const { Title, Paragraph } = Typography;

interface DropzoneFormProps {
  setUploaded: (value: any) => void;
  handleOnError: (error: ApolloError) => void;
}

const DropzoneForm: FC<DropzoneFormProps> = (props: DropzoneFormProps) => {
  const { setUploaded, handleOnError } = props;

  const beforeUpload = async (file: File) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      header: true,
      complete: (data) => {
        if (data.errors.length > 0) {
          handleOnError({ message: `${file.name} has nothing to import.` } as ApolloError);
        } else {
          setUploaded(data);
        }
      },
    });

    return false;
  };

  return (
    <>
      <Paragraph>
        Export clients from any tax software in CSV format.
      </Paragraph>
      <Paragraph>
        Then import clients so their information is pre-populated in their portal.
      </Paragraph>
      <Dropzone
        beforeUpload={beforeUpload}
        maxCount={1}
        fileList={[]}
      >
        <DownloadOutlined
          style={{
            fontSize: 48,
            margin: '26px 0',
          }}
        />
        <Title level={3}>
          Click or drag file to this area to upload
        </Title>
        <Paragraph type="secondary">
          Support for a single file.
        </Paragraph>
      </Dropzone>
    </>
  );
};

export default DropzoneForm;
