import { FC, useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
  Space,
  Radio,
  Form,
  Input,
  Icons,
  FormInstance,
} from 'taxaroo-ui';
import { KbaType } from '~src/graphql';
import moment from 'moment';
import KbaFormInputs from '../KbaFormInputs';

const { CloseOutlined } = Icons;

interface SignerKBASectionProps {
  index: number;
  handleClose: () => void;
  numApprovers: number;
  type: string;
  initialApproverFullName?: string;
  initialApproverLastName?: string;
  initialApproverEmail?: string;
  initialApproverPhone?: string;
  initialApproverBirthday?: Date;
  initialApproverLastSsn?: string;
  form: FormInstance;
  initialKbaType?: KbaType;
  fileRequestId?: string;
}

const SignerKBASection: FC<SignerKBASectionProps> = ({
  index,
  handleClose,
  numApprovers,
  type,
  initialApproverFullName,
  initialApproverLastName,
  initialApproverEmail,
  initialApproverPhone,
  initialApproverBirthday,
  initialApproverLastSsn,
  form,
  initialKbaType,
  fileRequestId,
}) => {
  const [kbaTypeSelection, setKbaTypeSelection] = useState(undefined);

  const signerOrApprover = type === 'signer' ? 'Signer' : 'Approver';

  useEffect(() => {
    setKbaTypeSelection(initialKbaType);
    form.setFields([
      { name: [`signerKBA[${index}]`, 'signatureKBAType'], value: initialKbaType },
      { name: [`signerKBA[${index}]`, 'signerName'], value: initialApproverFullName },
      { name: [`signerKBA[${index}]`, 'signerEmail'], value: initialApproverEmail },
      { name: [`signerKBA[${index}]`, 'name'], value: initialApproverLastName },
      { name: [`signerKBA[${index}]`, 'lastFourSSN'], value: initialApproverLastSsn },
      { name: [`signerKBA[${index}]`, 'dateOfBirth'], value: initialApproverBirthday ? moment(initialApproverBirthday) : null },
      { name: [`signerKBA[${index}]`, 'mobilePhone'], value: initialApproverPhone },
    ]);
  }, [fileRequestId]);

  return (
    <Card
      title={`${signerOrApprover} #${index + 1}`}
      extra={(index + 1 === numApprovers && index > 0) ? <CloseOutlined onClick={handleClose} /> : undefined}
    >
      <Row gutter={50}>
        <Col md={12} sm={24}>
          <Form.Item
            rules={[{ required: true, message: `${signerOrApprover} name is required` }]}
            name={[`signerKBA[${index}]`, 'signerName']}
            extra={`${signerOrApprover} Name`}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            rules={[{ required: true, type: 'email', message: 'A valid email is required' }]}
            name={[`signerKBA[${index}]`, 'signerEmail']}
            extra={`${signerOrApprover} Email`}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={2}>
        <Col span={24}>
          <Form.Item
            rules={[{ required: true, message: 'KBA type is required' }]}
            name={[`signerKBA[${index}]`, 'signatureKBAType']}
            label="KBA Type:"
          >
            <Radio.Group
              name="signatureKBAType"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setKbaTypeSelection(e.target.value)}
            >
              <Space direction="vertical">
                <Radio value={KbaType.Without}>Without KBA</Radio>
                <Radio value={KbaType.Basic}>{`Free KBA for this ${type}`}</Radio>
                <Radio value={KbaType.Enhanced}>{`Enhanced KBA for this ${type}`}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <KbaFormInputs
        index={index}
        kbaTypeValue={kbaTypeSelection}
        form={form}
      />
    </Card>
  );
};

SignerKBASection.defaultProps = {
  initialApproverFullName: '',
  initialApproverLastName: '',
  initialApproverEmail: '',
  initialApproverPhone: '',
  initialApproverBirthday: null,
  initialApproverLastSsn: '',
  initialKbaType: undefined,
  fileRequestId: undefined,
};

export default SignerKBASection;
