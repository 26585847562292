import { PathRouteProps } from 'react-router-dom';
import ActivatePreparerAccount from '~src/pages/ActivatePreparerAccount';
import ForgotPassword from '~src/pages/ForgotPassword';
import Login from '~src/pages/Login';
import RegisterPage from '~src/pages/Register';
import ResetPassword from '~src/pages/ResetPassword';
import ActivateAccount from '~src/pages/ActivateAccount';
import BillingPortalRedirect from '~src/pages/BillingPortalRedirect';
import StripePricingPage from '~src/pages/StripePricingPage';
import { PageViewProvider } from '~src/components/providers/pageview-provider';
import { ClientOnboarding, ClientOnboardingParams } from '../pages/ClientOnboarding';

export const PUBLIC_ROUTES: PathRouteProps[] = [
  {
    path: '/login',
    element: (
      <PageViewProvider>
        <Login />
      </PageViewProvider>
    ),
  },
  {
    path: '/forgot-password',
    element: (
      <PageViewProvider>
        <ForgotPassword />
      </PageViewProvider>
    ),
  },
  {
    path: '/reset-password/:id',
    element: (
      <PageViewProvider>
        <ResetPassword />
      </PageViewProvider>
    ),
  },
  {
    path: '/activate-preparer-account/:id',
    element: (
      <PageViewProvider>
        <ActivatePreparerAccount />
      </PageViewProvider>
    ),
  },
  {
    path: '/activate-account/:id',
    element: (
      <PageViewProvider>
        <ActivateAccount />
      </PageViewProvider>
    ),
  },
  {
    path: '/invite/activate/pre',
    element: (
      <PageViewProvider>
        <ClientOnboardingParams />
      </PageViewProvider>
    ),
  },
  {
    path: '/invite/activate',
    element: (
      <PageViewProvider>
        <ClientOnboarding />
      </PageViewProvider>
    ),
  },
  {
    path: '/register',
    element: (
      <PageViewProvider>
        <RegisterPage />
      </PageViewProvider>
    ),
  },
  {
    path: '/free-plan',
    element: (
      <PageViewProvider>
        <RegisterPage free />
      </PageViewProvider>
    ),
  },
  {
    path: '/billing-portal',
    element: (
      <PageViewProvider>
        <BillingPortalRedirect />
      </PageViewProvider>
    ),
  },
  {
    path: '/special-offer/:id',
    element: (
      <PageViewProvider>
        <StripePricingPage />
      </PageViewProvider>
    ),
  },
];
