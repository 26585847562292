import {
  Row, Col, Layout,
} from 'taxaroo-ui';
import { Link } from 'react-router-dom';
import Logo from '~src/assets/images/taxaroo_logo.svg';
import { useEffect } from 'react';
import FeaturesSection from './FeaturesSection';
import * as styles from './styles.module.css';
import RegisterSection from './RegisterSection';

const { Header } = Layout;

interface RegisterPageProps {
  free?: boolean;
}
const RegisterTemplate = ({ free = false }: RegisterPageProps) => {
  useEffect(() => {
    // destroy current help scout beacon and init the one starting on ask question
    window.Beacon('destroy');
    window.Beacon('init', '1749d000-7e8f-4fba-99fe-bf37ed9faed7');

    return () => {
      // destroy current help scout beacon and init the one starting on answers
      window.Beacon('destroy');
      window.Beacon('init', 'd3ee6f02-27a3-424d-832f-c3755284928e');
    };
  }, []);
  return (
    <Layout>
      <Header
        className={[styles.header, 'elevated']}
      >
        <Logo className={styles.logo} />
        <Link to="/login" className={styles.signInLink}>Sign In</Link>
      </Header>
      <Row justify="center" className={styles.signupContainer}>
        <Row gutter={{ xs: 0, lg: 100 }} className={styles.reverseOnSmall}>
          <Col span={24} xl={12} className={styles.textContainer}>
            <FeaturesSection free={free} />
          </Col>
          <Col span={24} xl={12} className={styles.registerSectionCol}>
            <RegisterSection free={free} />
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 60 }}>
          <p style={{ textAlign: 'center' }}>
            <a style={{ color: 'inherit' }} href="https://taxaroo.com/privacy" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
            {' '}
            |
            {' '}
            <a style={{ color: 'inherit' }} href="https://taxaroo.com//terms-of-use" target="_blank" rel="noreferrer">
              Terms of Service
            </a>
          </p>
        </Row>
      </Row>
    </Layout>
  );
};

RegisterTemplate.defaultProps = {
  free: false,
};

export default RegisterTemplate;
