import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserProfilePhotoMutationVariables = Types.Exact<{
  updateUserInformationInput: Types.UpdateUserInformationInput;
}>;


export type UpdateUserProfilePhotoMutation = { __typename?: 'Mutation', updateUserProfilePhoto: { __typename?: 'UserInformationEntity', userId?: string | null, profilePhoto?: string | null } };

export type CreateCustomDomainMutationVariables = Types.Exact<{
  createCustomDomainsInput: Types.CreateCustomDomainsInput;
}>;


export type CreateCustomDomainMutation = { __typename?: 'Mutation', createCustomDomains: { __typename?: 'CustomDomainsEntity', id: string, name: string, firmAccountId?: string | null } };

export type RemoveCustomDomainMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type RemoveCustomDomainMutation = { __typename?: 'Mutation', removeCustomDomain: { __typename?: 'CustomDomainsEntity', id: string, name: string } };

export type CreateCustomLayoutMutationVariables = Types.Exact<{
  createCustomLayoutInput: Types.CreateCustomLayoutInput;
}>;


export type CreateCustomLayoutMutation = { __typename?: 'Mutation', createCustomLayout: { __typename?: 'CustomLayoutEntity', id: string, firmAccountId?: string | null, headerBackgroundColor?: string | null, headerFontColor?: string | null } };

export type UpdateCustomLayoutMutationVariables = Types.Exact<{
  updateCustomLayoutInput: Types.UpdateCustomLayoutInput;
}>;


export type UpdateCustomLayoutMutation = { __typename?: 'Mutation', updateCustomLayout: { __typename?: 'CustomLayoutEntity', id: string, firmAccountId?: string | null, headerBackgroundColor?: string | null, headerFontColor?: string | null } };

export type UpdateUserMutationVariables = Types.Exact<{
  updateUserInput: Types.UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UserEntity', id: string, email?: string | null, languageId?: string | null } };

export type UpdateUserInformationMutationVariables = Types.Exact<{
  updateUserInformationInput: Types.UpdateUserInformationInput;
}>;


export type UpdateUserInformationMutation = { __typename?: 'Mutation', UpdateUserInformation: { __typename?: 'UserInformationEntity', id: string } };

export type UpdateFirmUniqueLinkMutationVariables = Types.Exact<{
  updateFirmAccountInput: Types.UpdateFirmAccountInput;
}>;


export type UpdateFirmUniqueLinkMutation = { __typename?: 'Mutation', updateFirmUniqueLink: { __typename?: 'FirmAccountEntity', id: string, uniqueLink?: string | null } };

export type UpdateUserUniqueLinkMutationVariables = Types.Exact<{
  updateUserInput: Types.UpdateUserInput;
}>;


export type UpdateUserUniqueLinkMutation = { __typename?: 'Mutation', updateUserUniqueLink: { __typename?: 'UserEntity', id: string, uniqueLink?: string | null } };

export type UpdateRandomUserUniqueLinkMutationVariables = Types.Exact<{
  updateUserInput: Types.UpdateUserInput;
}>;


export type UpdateRandomUserUniqueLinkMutation = { __typename?: 'Mutation', updateRandomUserUniqueLink: { __typename?: 'UserEntity', id: string, uniqueLink?: string | null } };

export type CreateSubscriptionMutationVariables = Types.Exact<{
  createStripeSubscriptionInput: Types.CreateStripeSubscriptionInput;
}>;


export type CreateSubscriptionMutation = { __typename?: 'Mutation', createSubscription: { __typename?: 'StripeSubscriptionEntity', id?: string | null } };

export type CreateProgressStatusesMutationVariables = Types.Exact<{
  createProgressStatusesInput: Types.CreateProgressStatusesInput;
}>;


export type CreateProgressStatusesMutation = { __typename?: 'Mutation', createProgressStatuses: { __typename?: 'ProgressStatusesEntity', id: string, firmAccountId: string, index: number } };

export type UpdateProgressStatusMutationVariables = Types.Exact<{
  updateProgressStatusInput: Types.UpdateProgressStatusesInput;
}>;


export type UpdateProgressStatusMutation = { __typename?: 'Mutation', updateProgressStatus: { __typename?: 'ProgressStatusesEntity', id: string, firmAccountId: string, status: string, underlyingStatus: Types.TaxYearStatus, index: number } };

export type SwitchOrderOfProgressStatusesMutationVariables = Types.Exact<{
  switchOrderOfProgressStatusesInput: Types.SwitchOrderOfProgressStatusesInput;
}>;


export type SwitchOrderOfProgressStatusesMutation = { __typename?: 'Mutation', switchOrderOfProgressStatuses: string };

export type RemoveProgressStatusesMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type RemoveProgressStatusesMutation = { __typename?: 'Mutation', removeProgressStatuses: { __typename?: 'ProgressStatusesEntity', id: string, firmAccountId: string, status: string, underlyingStatus: Types.TaxYearStatus, index: number } };

export type CreateUserFirmAccountMutationVariables = Types.Exact<{
  createUserFirmAccountInput: Types.CreateUserFirmAccountInput;
}>;


export type CreateUserFirmAccountMutation = { __typename?: 'Mutation', createUserFirmAccount: { __typename?: 'UserFirmAccountEntity', id: string, userId?: string | null, roleId: string, firmAccountId: string, createAt: any, updateAt: any } };

export type CanAddMemberToFirmQueryVariables = Types.Exact<{
  firmAccountId: Types.Scalars['String'];
}>;


export type CanAddMemberToFirmQuery = { __typename?: 'Query', canAddMemberToFirm: { __typename?: 'CanAddMemberEntity', success: boolean } };

export type AddMemberToFirmMutationVariables = Types.Exact<{
  firmAccountId: Types.Scalars['String'];
  memberAddInput: Types.MemberAddInput;
}>;


export type AddMemberToFirmMutation = { __typename?: 'Mutation', addMemberToFirm: Array<{ __typename?: 'UserFirmAccountEntity', id: string, roleId: string, firmAccountId: string, Users?: { __typename?: 'UserEntity', id: string, email?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, firstName: string, lastName: string } | null } | null, Roles?: { __typename?: 'RolesEntity', id: string, description: string } | null }> };

export type UpdateMemberRoleMutationVariables = Types.Exact<{
  firmAccountId: Types.Scalars['String'];
  userId: Types.Scalars['String'];
  newRoleId: Types.Scalars['String'];
  newEmail: Types.Scalars['String'];
}>;


export type UpdateMemberRoleMutation = { __typename?: 'Mutation', updateMemberRole: Array<{ __typename?: 'UserFirmAccountEntity', id: string, roleId: string, firmAccountId: string, Users?: { __typename?: 'UserEntity', id: string, email?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, firstName: string, lastName: string } | null } | null, Roles?: { __typename?: 'RolesEntity', id: string, description: string } | null }> };

export type DeleteTeamMemberMutationVariables = Types.Exact<{
  firmAccountId: Types.Scalars['String'];
  userId: Types.Scalars['String'];
}>;


export type DeleteTeamMemberMutation = { __typename?: 'Mutation', deleteTeamMember: { __typename?: 'UserEntity', id: string } };

export type ReactivateTeamMemberMutationVariables = Types.Exact<{
  firmAccountId: Types.Scalars['String'];
  userId: Types.Scalars['String'];
}>;


export type ReactivateTeamMemberMutation = { __typename?: 'Mutation', reactivateTeamMember: { __typename?: 'UserFirmAccountEntity', id: string } };

export type ValidateOtpMutationVariables = Types.Exact<{
  validateOtp: Types.ValidateOtpInput;
}>;


export type ValidateOtpMutation = { __typename?: 'Mutation', validateOTP: { __typename?: 'ValidateOtpEntity', secretOTP?: string | null } };

export type DeleteTwoStepAuthFromUserMutationVariables = Types.Exact<{
  validateOtp: Types.ValidateOtpInput;
}>;


export type DeleteTwoStepAuthFromUserMutation = { __typename?: 'Mutation', deleteTwoStepAuthFromUser: { __typename?: 'UserSuccessEntity', success: boolean } };

export type UpdateFirmAccountMutationVariables = Types.Exact<{
  updateFirmAccountInput: Types.UpdateFirmAccountInput;
}>;


export type UpdateFirmAccountMutation = { __typename?: 'Mutation', updateFirmAccount: { __typename?: 'FirmAccountEntity', id: string, name?: string | null, type: Types.FirmAccountType, businessType: Types.BusinessType, uniqueLink?: string | null, createAt: any, updateAt: any, deleteAt?: any | null } };

export type CreateBusinessInformationMutationVariables = Types.Exact<{
  createBusinessInformationInput: Types.CreateBusinessInformationInput;
}>;


export type CreateBusinessInformationMutation = { __typename?: 'Mutation', createBusinessInformation: { __typename?: 'BusinessInformationEntity', id?: string | null, taxReturns?: number | null, yearsInBusiness?: number | null, taxPercent?: number | null, accountingPercent?: number | null, bookKeepingPercent?: number | null, taxResolutionPercent?: number | null, payrollPercent?: number | null, other?: number | null, firmAccountId?: string | null } };

export type UpdateBusinessInformationMutationVariables = Types.Exact<{
  updateBusinessInformationInput: Types.UpdateBusinessInformationInput;
}>;


export type UpdateBusinessInformationMutation = { __typename?: 'Mutation', updateBusinessInformation: { __typename?: 'BusinessInformationEntity', id?: string | null, taxReturns?: number | null, yearsInBusiness?: number | null, taxPercent?: number | null, accountingPercent?: number | null, bookKeepingPercent?: number | null, taxResolutionPercent?: number | null, payrollPercent?: number | null, other?: number | null, firmAccountId?: string | null } };

export type RemoveBusinessInformationMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type RemoveBusinessInformationMutation = { __typename?: 'Mutation', removeBusinessInformation: { __typename?: 'BusinessInformationEntity', id?: string | null, taxReturns?: number | null, yearsInBusiness?: number | null, taxPercent?: number | null, accountingPercent?: number | null, bookKeepingPercent?: number | null, payrollPercent?: number | null, taxResolutionPercent?: number | null, other?: number | null, firmAccountId?: string | null } };

export type UpdateFirmNotificationsMutationVariables = Types.Exact<{
  updateFirmNotificationsStatusInput: Types.UpdateFirmNotificationsStatusInput;
}>;


export type UpdateFirmNotificationsMutation = { __typename?: 'Mutation', updateFirmNotificationsStatus: Array<{ __typename?: 'FirmNotificationsEntity', id: string, firmAccountId: string, notificationId: string, active: boolean, Notifications?: { __typename?: 'NotificationsEntity', name: string } | null }> };

export type CreateFirmNotificationReminderMutationVariables = Types.Exact<{
  createFirmNotificationReminderInput: Types.CreateFirmNotificationReminderInput;
}>;


export type CreateFirmNotificationReminderMutation = { __typename?: 'Mutation', createFirmNotificationReminder: { __typename?: 'FirmNotificationReminderEntity', id: string, firmNotificationId: string, frequency: number, maxReminders: number } };

export type UpdateFirmNotificationReminderMutationVariables = Types.Exact<{
  updateFirmNotificationReminderInput: Types.UpdateFirmNotificationReminderInput;
}>;


export type UpdateFirmNotificationReminderMutation = { __typename?: 'Mutation', updateFirmNotificationReminderWithFirmNotificationId: { __typename?: 'FirmNotificationReminderEntity', id: string, firmNotificationId: string, frequency: number, maxReminders: number } };

export type RemoveFirmNotificationReminderMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type RemoveFirmNotificationReminderMutation = { __typename?: 'Mutation', removeFirmNotificationReminder?: { __typename?: 'FirmNotificationReminderEntity', id: string, firmNotificationId: string, frequency: number, maxReminders: number } | null };

export type UpdatePixelsMutationVariables = Types.Exact<{
  updateCustomLayoutInput: Types.UpdateCustomLayoutInput;
}>;


export type UpdatePixelsMutation = { __typename?: 'Mutation', updatePixels: { __typename?: 'CustomLayoutEntity', id: string, pixelCode?: string | null } };

export type CreateClientMutationVariables = Types.Exact<{
  createClientInput: Types.CreateClientInput;
}>;


export type CreateClientMutation = { __typename?: 'Mutation', CreateClient: { __typename?: 'UserEntity', id: string, email?: string | null } };

export type SearchUnassignedClientsMutationVariables = Types.Exact<{
  searchClientInput: Types.SearchClientInput;
}>;


export type SearchUnassignedClientsMutation = { __typename?: 'Mutation', SearchUnassigned: Array<{ __typename?: 'UserFirmAccountEntity', firmAccountId: string, entityId?: string | null, Entity?: { __typename?: 'Entity', Users?: { __typename?: 'UserEntity', email?: string | null, id: string, UserInformation?: { __typename?: 'UserInformationEntity', id: string, firstName: string, lastName: string } | null } | null, TaxYear?: Array<{ __typename?: 'TaxYearEntity', year: number, TaxYearInterview?: Array<{ __typename?: 'TaxYearInterviewEntity', id: string, updateAt: any, PreparedUser?: { __typename?: 'PreparedUserEntity', id: string, email: string, UserInformation: { __typename?: 'UserInformationEntity', firstName: string, lastName: string } } | null, ReviewedUser?: { __typename?: 'PreparedUserEntity', id: string, email: string, UserInformation: { __typename?: 'UserInformationEntity', firstName: string, lastName: string } } | null, Interviews?: { __typename?: 'InterviewEntity', id: string, name: string } | null }> | null }> | null } | null }> };

export type SearchInProgressClientsMutationVariables = Types.Exact<{
  searchClientInput: Types.SearchClientInput;
}>;


export type SearchInProgressClientsMutation = { __typename?: 'Mutation', SearchInProgress: Array<{ __typename?: 'UserFirmAccountEntity', firmAccountId: string, entityId?: string | null, Entity?: { __typename?: 'Entity', Users?: { __typename?: 'UserEntity', id: string, email?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, firstName: string, lastName: string } | null } | null, TaxYear?: Array<{ __typename?: 'TaxYearEntity', id: string, year: number, TaxYearInterview?: Array<{ __typename?: 'TaxYearInterviewEntity', id: string, updateAt: any, ReviewedUser?: { __typename?: 'PreparedUserEntity', id: string, email: string, UserInformation: { __typename?: 'UserInformationEntity', firstName: string, lastName: string } } | null, PreparedUser?: { __typename?: 'PreparedUserEntity', id: string, email: string, UserInformation: { __typename?: 'UserInformationEntity', firstName: string, lastName: string } } | null, Interviews?: { __typename?: 'InterviewEntity', id: string, name: string } | null }> | null }> | null } | null }> };

export type SearchCompletedClientsMutationVariables = Types.Exact<{
  searchClientInput: Types.SearchClientInput;
}>;


export type SearchCompletedClientsMutation = { __typename?: 'Mutation', SearchCompleted: Array<{ __typename?: 'UserFirmAccountEntity', firmAccountId: string, entityId?: string | null, Entity?: { __typename?: 'Entity', Users?: { __typename?: 'UserEntity', id: string, email?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, firstName: string, lastName: string } | null } | null, TaxYear?: Array<{ __typename?: 'TaxYearEntity', year: number, TaxYearInterview?: Array<{ __typename?: 'TaxYearInterviewEntity', id: string, updateAt: any, Interviews?: { __typename?: 'InterviewEntity', id: string, name: string } | null, ReviewedUser?: { __typename?: 'PreparedUserEntity', id: string, email: string, UserInformation: { __typename?: 'UserInformationEntity', firstName: string, lastName: string } } | null, PreparedUser?: { __typename?: 'PreparedUserEntity', id: string, email: string, UserInformation: { __typename?: 'UserInformationEntity', firstName: string, lastName: string } } | null }> | null }> | null } | null }> };

export type CreateTaxYearInterviewMutationVariables = Types.Exact<{
  createTaxYearInterviewInput: Types.CreateTaxYearInterviewInput;
}>;


export type CreateTaxYearInterviewMutation = { __typename?: 'Mutation', createTaxYearInterview: { __typename?: 'TaxYearInterviewEntity', id: string, progressStatusId?: string | null, assignedAt: any, createAt: any, updateAt: any, preparedId?: string | null, reviewedId?: string | null } };

export type UpdateTaxYearInterviewMutationVariables = Types.Exact<{
  updateTaxYearInterviewInput: Types.UpdateTaxYearInterviewInput;
}>;


export type UpdateTaxYearInterviewMutation = { __typename?: 'Mutation', updateTaxYearInterview: { __typename?: 'TaxYearInterviewEntity', id: string, taxYearId: string, preparedId?: string | null, reviewedId?: string | null, interviewId: string, progressStatusId?: string | null, notes?: string | null, reviewStatus?: Types.ReviewStatus | null, createAt: any, updateAt: any, completedAt?: any | null, assignedAt: any, PreparedUser?: { __typename?: 'PreparedUserEntity', id: string, email: string, UserInformation: { __typename?: 'UserInformationEntity', id: string, userId?: string | null, birthday?: any | null, firstName: string, lastName: string, createAt?: any | null, updateAt?: any | null } } | null, ReviewedUser?: { __typename?: 'PreparedUserEntity', id: string, email: string, UserInformation: { __typename?: 'UserInformationEntity', id: string, userId?: string | null, birthday?: any | null, firstName: string, lastName: string, createAt?: any | null, updateAt?: any | null } } | null, TaxYear?: { __typename?: 'TaxYearEntity', id: string, entityId: string, year: number, createAt: any, Entity?: { __typename?: 'Entity', id: string, ownerId: string, Users?: { __typename?: 'UserEntity', id: string, email?: string | null, createAt: any, languageId?: string | null, updateAt: any, UserInformation?: { __typename?: 'UserInformationEntity', id: string, userId?: string | null, birthday?: any | null, firstName: string, lastName: string, createAt?: any | null, updateAt?: any | null } | null } | null } | null } | null, Interviews?: { __typename?: 'InterviewEntity', id: string, name: string, isLive: boolean, createAt: any, updateAt: any, isTemplate: boolean, firmAccountId: string } | null, ProgressStatuses?: { __typename?: 'ProgressStatusesEntity', id: string, status: string, underlyingStatus: Types.TaxYearStatus, index: number } | null } };

export type DeleteTaxYearInterviewMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteTaxYearInterviewMutation = { __typename?: 'Mutation', DeleteJob: { __typename?: 'TaxYearInterviewEntity', id: string, taxYearId: string, preparedId?: string | null, reviewedId?: string | null, interviewId: string, progressStatusId?: string | null, notes?: string | null, assignedAt: any, completedAt?: any | null, preparerAcceptedAt?: any | null, reviewStatus?: Types.ReviewStatus | null, createAt: any, updateAt: any, deletedAt?: any | null } };

export type AssignTaxYearInterviewMutationVariables = Types.Exact<{
  input: Types.UpdateTaxYearInterviewInput;
}>;


export type AssignTaxYearInterviewMutation = { __typename?: 'Mutation', AssignTaxYearInterviewJob: { __typename?: 'TaxYearInterviewEntity', id: string, preparedId?: string | null, reviewedId?: string | null, progressStatusId?: string | null } };

export type UpdateSubscriptionMutationVariables = Types.Exact<{
  updateStripeSubscriptionInput: Types.UpdateStripeSubscriptionInput;
  finishTrial?: Types.InputMaybe<Types.Scalars['Boolean']>;
  resume?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type UpdateSubscriptionMutation = { __typename?: 'Mutation', updateStripeSubscription: { __typename?: 'StripeSubscriptionEntity', id?: string | null, object?: string | null, created?: number | null, ended_at?: number | null, customer?: string | null, livemode?: boolean | null, cancel_at?: number | null, canceled_at?: number | null, days_until_due?: number | null, default_source?: string | null, current_period_end?: number | null, current_period_start?: number | null, billing_cycle_anchor?: number | null, cancel_at_period_end?: boolean | null, default_payment_method?: string | null, application_fee_percent?: number | null, status?: string | null, quantity?: number | null, schedule?: string | null, trial_end?: number | null, start_date?: number | null, trial_start?: number | null, pending_setup_intent?: string | null, next_pending_invoice_item_invoice?: number | null, items: { __typename?: 'StripeSubscriptionItemsEntity', object?: string | null, data?: Array<{ __typename?: 'StripeSubscriptionItemEntity', id?: string | null, object?: string | null, created?: number | null, billing_thresholds?: { __typename?: 'StripeBillingThresholdsEntity', usage_gte?: number | null } | null, plan?: { __typename?: 'StripePlansInformationEntity', id?: string | null, object?: string | null, active?: boolean | null, amount?: number | null, created?: number | null, currency?: string | null, interval?: string | null, livemode?: boolean | null, tiers_mode?: string | null, amount_decimal?: string | null, billing_scheme?: string | null, interval_count?: number | null, trial_period_days?: number | null, tiers?: Array<{ __typename?: 'StripeTiersEntity', flat_amount?: number | null, flat_amount_decimal?: string | null, unit_amount?: number | null, unit_amount_decimal?: string | null, up_to?: number | null }> | null } | null }> | null }, metadata?: { __typename?: 'StripeMetadataEntity', plan_name?: string | null } | null } };

export type ResumeSubscriptionMutationVariables = Types.Exact<{
  subscriptionId: Types.Scalars['String'];
}>;


export type ResumeSubscriptionMutation = { __typename?: 'Mutation', ResumeSubscription: { __typename?: 'StripeSubscriptionEntity', id?: string | null, current_period_end?: number | null, current_period_start?: number | null, billing_cycle_anchor?: number | null, pause_collection?: { __typename?: 'StripeSubscriptionPauseCollection', behavior: string, resumes_at?: number | null } | null } };

export type SubscribeToPlanMutationVariables = Types.Exact<{
  createStripeSubscriptionInput: Types.CreateStripeSubscriptionInput;
}>;


export type SubscribeToPlanMutation = { __typename?: 'Mutation', createSubscription: { __typename?: 'StripeSubscriptionEntity', id?: string | null } };

export type DeleteSubscriptionMutationVariables = Types.Exact<{
  subscriptionId: Types.Scalars['String'];
  deleteStripeSubscriptionInput?: Types.InputMaybe<Types.DeleteStripeSubscriptionInput>;
}>;


export type DeleteSubscriptionMutation = { __typename?: 'Mutation', deleteSubscription: { __typename?: 'StripeSubscriptionEntity', id?: string | null } };

export type UpdateJobNotesMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  notes: Types.Scalars['String'];
}>;


export type UpdateJobNotesMutation = { __typename?: 'Mutation', UpdateJobNotes: { __typename?: 'TaxYearInterviewEntity', id: string, notes?: string | null } };

export type GetSiteBrandingLogoPresignedUrlMutationVariables = Types.Exact<{
  mimeType: Types.Scalars['String'];
}>;


export type GetSiteBrandingLogoPresignedUrlMutation = { __typename?: 'Mutation', GetSiteBrandingLogoPresignedURL: string };

export type GetUserProfilePhotoPresignedUrlMutationVariables = Types.Exact<{
  updateUserInformationInput: Types.UpdateUserInformationInput;
}>;


export type GetUserProfilePhotoPresignedUrlMutation = { __typename?: 'Mutation', GetUserProfilePhotoPresignedURL: string };

export type CustomDomainResendEmailsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CustomDomainResendEmailsMutation = { __typename?: 'Mutation', CustomDomainResendEmails: boolean };

export type GetOnBoardingLinkMutationVariables = Types.Exact<{
  returnUrl: Types.Scalars['String'];
}>;


export type GetOnBoardingLinkMutation = { __typename?: 'Mutation', GetOnBoardingLink: string };

export type DeleteNylasAccountMutationVariables = Types.Exact<{
  accountId: Types.Scalars['String'];
}>;


export type DeleteNylasAccountMutation = { __typename?: 'Mutation', deleteNylasAccount: boolean };

export type GetNylasAuthUrlMutationVariables = Types.Exact<{
  input: Types.CreateNylasAuthDtoInput;
}>;


export type GetNylasAuthUrlMutation = { __typename?: 'Mutation', getNylasAuthUrl: { __typename?: 'NylasAuthUrlEntity', url: string } };

export type HandleNylasAuthCallbackMutationVariables = Types.Exact<{
  input: Types.CreateNylasAuthorizeDtoInput;
}>;


export type HandleNylasAuthCallbackMutation = { __typename?: 'Mutation', handleNylasAuthCallback: { __typename?: 'NylasApiAccountEntity', accountId?: string | null, provider?: string | null, syncState?: string | null, emailAddress?: string | null, accessToken?: string | null } };

export type CreateFirmSettingMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  value: Types.Scalars['String'];
}>;


export type CreateFirmSettingMutation = { __typename?: 'Mutation', createFirmSetting: { __typename?: 'FirmSettingsEntity', id?: string | null, name: string, description: string, type: Types.SettingsType, value: { __typename?: 'BooleanValue', boolValue: boolean } | { __typename?: 'DateValue', dateValue: any } | { __typename?: 'NumberValue', numValue: number } | { __typename?: 'StringValue', strValue: string } } };

export type UpdateFirmSettingMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  value: Types.Scalars['String'];
}>;


export type UpdateFirmSettingMutation = { __typename?: 'Mutation', updateFirmSetting: { __typename?: 'FirmSettingsEntity', id?: string | null, name: string, description: string, type: Types.SettingsType, value: { __typename?: 'BooleanValue', boolValue: boolean } | { __typename?: 'DateValue', dateValue: any } | { __typename?: 'NumberValue', numValue: number } | { __typename?: 'StringValue', strValue: string } } };

export type RecoverDeletedClientMutationVariables = Types.Exact<{
  userFirmAccountId: Types.Scalars['String'];
}>;


export type RecoverDeletedClientMutation = { __typename?: 'Mutation', RecoverDeletedClient: string };


export const UpdateUserProfilePhotoDocument = gql`
    mutation UpdateUserProfilePhoto($updateUserInformationInput: UpdateUserInformationInput!) {
  updateUserProfilePhoto(updateUserInformationInput: $updateUserInformationInput) {
    userId
    profilePhoto
  }
}
    `;
export type UpdateUserProfilePhotoMutationFn = Apollo.MutationFunction<UpdateUserProfilePhotoMutation, UpdateUserProfilePhotoMutationVariables>;

/**
 * __useUpdateUserProfilePhotoMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfilePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfilePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfilePhotoMutation, { data, loading, error }] = useUpdateUserProfilePhotoMutation({
 *   variables: {
 *      updateUserInformationInput: // value for 'updateUserInformationInput'
 *   },
 * });
 */
export function useUpdateUserProfilePhotoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfilePhotoMutation, UpdateUserProfilePhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfilePhotoMutation, UpdateUserProfilePhotoMutationVariables>(UpdateUserProfilePhotoDocument, options);
      }
export type UpdateUserProfilePhotoMutationHookResult = ReturnType<typeof useUpdateUserProfilePhotoMutation>;
export type UpdateUserProfilePhotoMutationResult = Apollo.MutationResult<UpdateUserProfilePhotoMutation>;
export type UpdateUserProfilePhotoMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfilePhotoMutation, UpdateUserProfilePhotoMutationVariables>;
export const CreateCustomDomainDocument = gql`
    mutation CreateCustomDomain($createCustomDomainsInput: CreateCustomDomainsInput!) {
  createCustomDomains(createCustomDomainsInput: $createCustomDomainsInput) {
    id
    name
    firmAccountId
  }
}
    `;
export type CreateCustomDomainMutationFn = Apollo.MutationFunction<CreateCustomDomainMutation, CreateCustomDomainMutationVariables>;

/**
 * __useCreateCustomDomainMutation__
 *
 * To run a mutation, you first call `useCreateCustomDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomDomainMutation, { data, loading, error }] = useCreateCustomDomainMutation({
 *   variables: {
 *      createCustomDomainsInput: // value for 'createCustomDomainsInput'
 *   },
 * });
 */
export function useCreateCustomDomainMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomDomainMutation, CreateCustomDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomDomainMutation, CreateCustomDomainMutationVariables>(CreateCustomDomainDocument, options);
      }
export type CreateCustomDomainMutationHookResult = ReturnType<typeof useCreateCustomDomainMutation>;
export type CreateCustomDomainMutationResult = Apollo.MutationResult<CreateCustomDomainMutation>;
export type CreateCustomDomainMutationOptions = Apollo.BaseMutationOptions<CreateCustomDomainMutation, CreateCustomDomainMutationVariables>;
export const RemoveCustomDomainDocument = gql`
    mutation RemoveCustomDomain($id: String!) {
  removeCustomDomain(id: $id) {
    id
    name
  }
}
    `;
export type RemoveCustomDomainMutationFn = Apollo.MutationFunction<RemoveCustomDomainMutation, RemoveCustomDomainMutationVariables>;

/**
 * __useRemoveCustomDomainMutation__
 *
 * To run a mutation, you first call `useRemoveCustomDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCustomDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCustomDomainMutation, { data, loading, error }] = useRemoveCustomDomainMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCustomDomainMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCustomDomainMutation, RemoveCustomDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCustomDomainMutation, RemoveCustomDomainMutationVariables>(RemoveCustomDomainDocument, options);
      }
export type RemoveCustomDomainMutationHookResult = ReturnType<typeof useRemoveCustomDomainMutation>;
export type RemoveCustomDomainMutationResult = Apollo.MutationResult<RemoveCustomDomainMutation>;
export type RemoveCustomDomainMutationOptions = Apollo.BaseMutationOptions<RemoveCustomDomainMutation, RemoveCustomDomainMutationVariables>;
export const CreateCustomLayoutDocument = gql`
    mutation CreateCustomLayout($createCustomLayoutInput: CreateCustomLayoutInput!) {
  createCustomLayout(createCustomLayoutInput: $createCustomLayoutInput) {
    id
    firmAccountId
    headerBackgroundColor
    headerFontColor
  }
}
    `;
export type CreateCustomLayoutMutationFn = Apollo.MutationFunction<CreateCustomLayoutMutation, CreateCustomLayoutMutationVariables>;

/**
 * __useCreateCustomLayoutMutation__
 *
 * To run a mutation, you first call `useCreateCustomLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomLayoutMutation, { data, loading, error }] = useCreateCustomLayoutMutation({
 *   variables: {
 *      createCustomLayoutInput: // value for 'createCustomLayoutInput'
 *   },
 * });
 */
export function useCreateCustomLayoutMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomLayoutMutation, CreateCustomLayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomLayoutMutation, CreateCustomLayoutMutationVariables>(CreateCustomLayoutDocument, options);
      }
export type CreateCustomLayoutMutationHookResult = ReturnType<typeof useCreateCustomLayoutMutation>;
export type CreateCustomLayoutMutationResult = Apollo.MutationResult<CreateCustomLayoutMutation>;
export type CreateCustomLayoutMutationOptions = Apollo.BaseMutationOptions<CreateCustomLayoutMutation, CreateCustomLayoutMutationVariables>;
export const UpdateCustomLayoutDocument = gql`
    mutation UpdateCustomLayout($updateCustomLayoutInput: UpdateCustomLayoutInput!) {
  updateCustomLayout(updateCustomLayoutInput: $updateCustomLayoutInput) {
    id
    firmAccountId
    headerBackgroundColor
    headerFontColor
  }
}
    `;
export type UpdateCustomLayoutMutationFn = Apollo.MutationFunction<UpdateCustomLayoutMutation, UpdateCustomLayoutMutationVariables>;

/**
 * __useUpdateCustomLayoutMutation__
 *
 * To run a mutation, you first call `useUpdateCustomLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomLayoutMutation, { data, loading, error }] = useUpdateCustomLayoutMutation({
 *   variables: {
 *      updateCustomLayoutInput: // value for 'updateCustomLayoutInput'
 *   },
 * });
 */
export function useUpdateCustomLayoutMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomLayoutMutation, UpdateCustomLayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomLayoutMutation, UpdateCustomLayoutMutationVariables>(UpdateCustomLayoutDocument, options);
      }
export type UpdateCustomLayoutMutationHookResult = ReturnType<typeof useUpdateCustomLayoutMutation>;
export type UpdateCustomLayoutMutationResult = Apollo.MutationResult<UpdateCustomLayoutMutation>;
export type UpdateCustomLayoutMutationOptions = Apollo.BaseMutationOptions<UpdateCustomLayoutMutation, UpdateCustomLayoutMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($updateUserInput: UpdateUserInput!) {
  updateUser(updateUserInput: $updateUserInput) {
    id
    email
    languageId
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      updateUserInput: // value for 'updateUserInput'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserInformationDocument = gql`
    mutation UpdateUserInformation($updateUserInformationInput: UpdateUserInformationInput!) {
  UpdateUserInformation(updateUserInformationInput: $updateUserInformationInput) {
    id
  }
}
    `;
export type UpdateUserInformationMutationFn = Apollo.MutationFunction<UpdateUserInformationMutation, UpdateUserInformationMutationVariables>;

/**
 * __useUpdateUserInformationMutation__
 *
 * To run a mutation, you first call `useUpdateUserInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInformationMutation, { data, loading, error }] = useUpdateUserInformationMutation({
 *   variables: {
 *      updateUserInformationInput: // value for 'updateUserInformationInput'
 *   },
 * });
 */
export function useUpdateUserInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserInformationMutation, UpdateUserInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserInformationMutation, UpdateUserInformationMutationVariables>(UpdateUserInformationDocument, options);
      }
export type UpdateUserInformationMutationHookResult = ReturnType<typeof useUpdateUserInformationMutation>;
export type UpdateUserInformationMutationResult = Apollo.MutationResult<UpdateUserInformationMutation>;
export type UpdateUserInformationMutationOptions = Apollo.BaseMutationOptions<UpdateUserInformationMutation, UpdateUserInformationMutationVariables>;
export const UpdateFirmUniqueLinkDocument = gql`
    mutation UpdateFirmUniqueLink($updateFirmAccountInput: UpdateFirmAccountInput!) {
  updateFirmUniqueLink(updateFirmAccountInput: $updateFirmAccountInput) {
    id
    uniqueLink
  }
}
    `;
export type UpdateFirmUniqueLinkMutationFn = Apollo.MutationFunction<UpdateFirmUniqueLinkMutation, UpdateFirmUniqueLinkMutationVariables>;

/**
 * __useUpdateFirmUniqueLinkMutation__
 *
 * To run a mutation, you first call `useUpdateFirmUniqueLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFirmUniqueLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFirmUniqueLinkMutation, { data, loading, error }] = useUpdateFirmUniqueLinkMutation({
 *   variables: {
 *      updateFirmAccountInput: // value for 'updateFirmAccountInput'
 *   },
 * });
 */
export function useUpdateFirmUniqueLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFirmUniqueLinkMutation, UpdateFirmUniqueLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFirmUniqueLinkMutation, UpdateFirmUniqueLinkMutationVariables>(UpdateFirmUniqueLinkDocument, options);
      }
export type UpdateFirmUniqueLinkMutationHookResult = ReturnType<typeof useUpdateFirmUniqueLinkMutation>;
export type UpdateFirmUniqueLinkMutationResult = Apollo.MutationResult<UpdateFirmUniqueLinkMutation>;
export type UpdateFirmUniqueLinkMutationOptions = Apollo.BaseMutationOptions<UpdateFirmUniqueLinkMutation, UpdateFirmUniqueLinkMutationVariables>;
export const UpdateUserUniqueLinkDocument = gql`
    mutation UpdateUserUniqueLink($updateUserInput: UpdateUserInput!) {
  updateUserUniqueLink(updateUserInput: $updateUserInput) {
    id
    uniqueLink
  }
}
    `;
export type UpdateUserUniqueLinkMutationFn = Apollo.MutationFunction<UpdateUserUniqueLinkMutation, UpdateUserUniqueLinkMutationVariables>;

/**
 * __useUpdateUserUniqueLinkMutation__
 *
 * To run a mutation, you first call `useUpdateUserUniqueLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserUniqueLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserUniqueLinkMutation, { data, loading, error }] = useUpdateUserUniqueLinkMutation({
 *   variables: {
 *      updateUserInput: // value for 'updateUserInput'
 *   },
 * });
 */
export function useUpdateUserUniqueLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserUniqueLinkMutation, UpdateUserUniqueLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserUniqueLinkMutation, UpdateUserUniqueLinkMutationVariables>(UpdateUserUniqueLinkDocument, options);
      }
export type UpdateUserUniqueLinkMutationHookResult = ReturnType<typeof useUpdateUserUniqueLinkMutation>;
export type UpdateUserUniqueLinkMutationResult = Apollo.MutationResult<UpdateUserUniqueLinkMutation>;
export type UpdateUserUniqueLinkMutationOptions = Apollo.BaseMutationOptions<UpdateUserUniqueLinkMutation, UpdateUserUniqueLinkMutationVariables>;
export const UpdateRandomUserUniqueLinkDocument = gql`
    mutation UpdateRandomUserUniqueLink($updateUserInput: UpdateUserInput!) {
  updateRandomUserUniqueLink(updateUserInput: $updateUserInput) {
    id
    uniqueLink
  }
}
    `;
export type UpdateRandomUserUniqueLinkMutationFn = Apollo.MutationFunction<UpdateRandomUserUniqueLinkMutation, UpdateRandomUserUniqueLinkMutationVariables>;

/**
 * __useUpdateRandomUserUniqueLinkMutation__
 *
 * To run a mutation, you first call `useUpdateRandomUserUniqueLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRandomUserUniqueLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRandomUserUniqueLinkMutation, { data, loading, error }] = useUpdateRandomUserUniqueLinkMutation({
 *   variables: {
 *      updateUserInput: // value for 'updateUserInput'
 *   },
 * });
 */
export function useUpdateRandomUserUniqueLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRandomUserUniqueLinkMutation, UpdateRandomUserUniqueLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRandomUserUniqueLinkMutation, UpdateRandomUserUniqueLinkMutationVariables>(UpdateRandomUserUniqueLinkDocument, options);
      }
export type UpdateRandomUserUniqueLinkMutationHookResult = ReturnType<typeof useUpdateRandomUserUniqueLinkMutation>;
export type UpdateRandomUserUniqueLinkMutationResult = Apollo.MutationResult<UpdateRandomUserUniqueLinkMutation>;
export type UpdateRandomUserUniqueLinkMutationOptions = Apollo.BaseMutationOptions<UpdateRandomUserUniqueLinkMutation, UpdateRandomUserUniqueLinkMutationVariables>;
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($createStripeSubscriptionInput: CreateStripeSubscriptionInput!) {
  createSubscription(
    createStripeSubscriptionInput: $createStripeSubscriptionInput
  ) {
    id
  }
}
    `;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      createStripeSubscriptionInput: // value for 'createStripeSubscriptionInput'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const CreateProgressStatusesDocument = gql`
    mutation CreateProgressStatuses($createProgressStatusesInput: CreateProgressStatusesInput!) {
  createProgressStatuses(
    createProgressStatusesInput: $createProgressStatusesInput
  ) {
    id
    firmAccountId
    index
  }
}
    `;
export type CreateProgressStatusesMutationFn = Apollo.MutationFunction<CreateProgressStatusesMutation, CreateProgressStatusesMutationVariables>;

/**
 * __useCreateProgressStatusesMutation__
 *
 * To run a mutation, you first call `useCreateProgressStatusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgressStatusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgressStatusesMutation, { data, loading, error }] = useCreateProgressStatusesMutation({
 *   variables: {
 *      createProgressStatusesInput: // value for 'createProgressStatusesInput'
 *   },
 * });
 */
export function useCreateProgressStatusesMutation(baseOptions?: Apollo.MutationHookOptions<CreateProgressStatusesMutation, CreateProgressStatusesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProgressStatusesMutation, CreateProgressStatusesMutationVariables>(CreateProgressStatusesDocument, options);
      }
export type CreateProgressStatusesMutationHookResult = ReturnType<typeof useCreateProgressStatusesMutation>;
export type CreateProgressStatusesMutationResult = Apollo.MutationResult<CreateProgressStatusesMutation>;
export type CreateProgressStatusesMutationOptions = Apollo.BaseMutationOptions<CreateProgressStatusesMutation, CreateProgressStatusesMutationVariables>;
export const UpdateProgressStatusDocument = gql`
    mutation UpdateProgressStatus($updateProgressStatusInput: UpdateProgressStatusesInput!) {
  updateProgressStatus(updateProgressStatusInput: $updateProgressStatusInput) {
    id
    firmAccountId
    status
    underlyingStatus
    index
  }
}
    `;
export type UpdateProgressStatusMutationFn = Apollo.MutationFunction<UpdateProgressStatusMutation, UpdateProgressStatusMutationVariables>;

/**
 * __useUpdateProgressStatusMutation__
 *
 * To run a mutation, you first call `useUpdateProgressStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgressStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgressStatusMutation, { data, loading, error }] = useUpdateProgressStatusMutation({
 *   variables: {
 *      updateProgressStatusInput: // value for 'updateProgressStatusInput'
 *   },
 * });
 */
export function useUpdateProgressStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProgressStatusMutation, UpdateProgressStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProgressStatusMutation, UpdateProgressStatusMutationVariables>(UpdateProgressStatusDocument, options);
      }
export type UpdateProgressStatusMutationHookResult = ReturnType<typeof useUpdateProgressStatusMutation>;
export type UpdateProgressStatusMutationResult = Apollo.MutationResult<UpdateProgressStatusMutation>;
export type UpdateProgressStatusMutationOptions = Apollo.BaseMutationOptions<UpdateProgressStatusMutation, UpdateProgressStatusMutationVariables>;
export const SwitchOrderOfProgressStatusesDocument = gql`
    mutation SwitchOrderOfProgressStatuses($switchOrderOfProgressStatusesInput: SwitchOrderOfProgressStatusesInput!) {
  switchOrderOfProgressStatuses(
    switchOrderOfProgressStatusesInput: $switchOrderOfProgressStatusesInput
  )
}
    `;
export type SwitchOrderOfProgressStatusesMutationFn = Apollo.MutationFunction<SwitchOrderOfProgressStatusesMutation, SwitchOrderOfProgressStatusesMutationVariables>;

/**
 * __useSwitchOrderOfProgressStatusesMutation__
 *
 * To run a mutation, you first call `useSwitchOrderOfProgressStatusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchOrderOfProgressStatusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchOrderOfProgressStatusesMutation, { data, loading, error }] = useSwitchOrderOfProgressStatusesMutation({
 *   variables: {
 *      switchOrderOfProgressStatusesInput: // value for 'switchOrderOfProgressStatusesInput'
 *   },
 * });
 */
export function useSwitchOrderOfProgressStatusesMutation(baseOptions?: Apollo.MutationHookOptions<SwitchOrderOfProgressStatusesMutation, SwitchOrderOfProgressStatusesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SwitchOrderOfProgressStatusesMutation, SwitchOrderOfProgressStatusesMutationVariables>(SwitchOrderOfProgressStatusesDocument, options);
      }
export type SwitchOrderOfProgressStatusesMutationHookResult = ReturnType<typeof useSwitchOrderOfProgressStatusesMutation>;
export type SwitchOrderOfProgressStatusesMutationResult = Apollo.MutationResult<SwitchOrderOfProgressStatusesMutation>;
export type SwitchOrderOfProgressStatusesMutationOptions = Apollo.BaseMutationOptions<SwitchOrderOfProgressStatusesMutation, SwitchOrderOfProgressStatusesMutationVariables>;
export const RemoveProgressStatusesDocument = gql`
    mutation RemoveProgressStatuses($id: String!) {
  removeProgressStatuses(id: $id) {
    id
    firmAccountId
    status
    underlyingStatus
    index
  }
}
    `;
export type RemoveProgressStatusesMutationFn = Apollo.MutationFunction<RemoveProgressStatusesMutation, RemoveProgressStatusesMutationVariables>;

/**
 * __useRemoveProgressStatusesMutation__
 *
 * To run a mutation, you first call `useRemoveProgressStatusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProgressStatusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProgressStatusesMutation, { data, loading, error }] = useRemoveProgressStatusesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveProgressStatusesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveProgressStatusesMutation, RemoveProgressStatusesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveProgressStatusesMutation, RemoveProgressStatusesMutationVariables>(RemoveProgressStatusesDocument, options);
      }
export type RemoveProgressStatusesMutationHookResult = ReturnType<typeof useRemoveProgressStatusesMutation>;
export type RemoveProgressStatusesMutationResult = Apollo.MutationResult<RemoveProgressStatusesMutation>;
export type RemoveProgressStatusesMutationOptions = Apollo.BaseMutationOptions<RemoveProgressStatusesMutation, RemoveProgressStatusesMutationVariables>;
export const CreateUserFirmAccountDocument = gql`
    mutation CreateUserFirmAccount($createUserFirmAccountInput: CreateUserFirmAccountInput!) {
  createUserFirmAccount(createUserFirmAccountInput: $createUserFirmAccountInput) {
    id
    userId
    roleId
    firmAccountId
    createAt
    updateAt
  }
}
    `;
export type CreateUserFirmAccountMutationFn = Apollo.MutationFunction<CreateUserFirmAccountMutation, CreateUserFirmAccountMutationVariables>;

/**
 * __useCreateUserFirmAccountMutation__
 *
 * To run a mutation, you first call `useCreateUserFirmAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserFirmAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserFirmAccountMutation, { data, loading, error }] = useCreateUserFirmAccountMutation({
 *   variables: {
 *      createUserFirmAccountInput: // value for 'createUserFirmAccountInput'
 *   },
 * });
 */
export function useCreateUserFirmAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserFirmAccountMutation, CreateUserFirmAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserFirmAccountMutation, CreateUserFirmAccountMutationVariables>(CreateUserFirmAccountDocument, options);
      }
export type CreateUserFirmAccountMutationHookResult = ReturnType<typeof useCreateUserFirmAccountMutation>;
export type CreateUserFirmAccountMutationResult = Apollo.MutationResult<CreateUserFirmAccountMutation>;
export type CreateUserFirmAccountMutationOptions = Apollo.BaseMutationOptions<CreateUserFirmAccountMutation, CreateUserFirmAccountMutationVariables>;
export const CanAddMemberToFirmDocument = gql`
    query CanAddMemberToFirm($firmAccountId: String!) {
  canAddMemberToFirm(firmAccountId: $firmAccountId) {
    success
  }
}
    `;

/**
 * __useCanAddMemberToFirmQuery__
 *
 * To run a query within a React component, call `useCanAddMemberToFirmQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanAddMemberToFirmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanAddMemberToFirmQuery({
 *   variables: {
 *      firmAccountId: // value for 'firmAccountId'
 *   },
 * });
 */
export function useCanAddMemberToFirmQuery(baseOptions: Apollo.QueryHookOptions<CanAddMemberToFirmQuery, CanAddMemberToFirmQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanAddMemberToFirmQuery, CanAddMemberToFirmQueryVariables>(CanAddMemberToFirmDocument, options);
      }
export function useCanAddMemberToFirmLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanAddMemberToFirmQuery, CanAddMemberToFirmQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanAddMemberToFirmQuery, CanAddMemberToFirmQueryVariables>(CanAddMemberToFirmDocument, options);
        }
export type CanAddMemberToFirmQueryHookResult = ReturnType<typeof useCanAddMemberToFirmQuery>;
export type CanAddMemberToFirmLazyQueryHookResult = ReturnType<typeof useCanAddMemberToFirmLazyQuery>;
export type CanAddMemberToFirmQueryResult = Apollo.QueryResult<CanAddMemberToFirmQuery, CanAddMemberToFirmQueryVariables>;
export const AddMemberToFirmDocument = gql`
    mutation AddMemberToFirm($firmAccountId: String!, $memberAddInput: MemberAddInput!) {
  addMemberToFirm(firmAccountId: $firmAccountId, memberAddInput: $memberAddInput) {
    id
    roleId
    firmAccountId
    Users {
      id
      email
      UserInformation {
        id
        firstName
        lastName
      }
    }
    Roles {
      id
      description
    }
  }
}
    `;
export type AddMemberToFirmMutationFn = Apollo.MutationFunction<AddMemberToFirmMutation, AddMemberToFirmMutationVariables>;

/**
 * __useAddMemberToFirmMutation__
 *
 * To run a mutation, you first call `useAddMemberToFirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMemberToFirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMemberToFirmMutation, { data, loading, error }] = useAddMemberToFirmMutation({
 *   variables: {
 *      firmAccountId: // value for 'firmAccountId'
 *      memberAddInput: // value for 'memberAddInput'
 *   },
 * });
 */
export function useAddMemberToFirmMutation(baseOptions?: Apollo.MutationHookOptions<AddMemberToFirmMutation, AddMemberToFirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMemberToFirmMutation, AddMemberToFirmMutationVariables>(AddMemberToFirmDocument, options);
      }
export type AddMemberToFirmMutationHookResult = ReturnType<typeof useAddMemberToFirmMutation>;
export type AddMemberToFirmMutationResult = Apollo.MutationResult<AddMemberToFirmMutation>;
export type AddMemberToFirmMutationOptions = Apollo.BaseMutationOptions<AddMemberToFirmMutation, AddMemberToFirmMutationVariables>;
export const UpdateMemberRoleDocument = gql`
    mutation UpdateMemberRole($firmAccountId: String!, $userId: String!, $newRoleId: String!, $newEmail: String!) {
  updateMemberRole(
    firmAccountId: $firmAccountId
    userId: $userId
    newRoleId: $newRoleId
    newEmail: $newEmail
  ) {
    id
    roleId
    firmAccountId
    Users {
      id
      email
      UserInformation {
        id
        firstName
        lastName
      }
    }
    Roles {
      id
      description
    }
  }
}
    `;
export type UpdateMemberRoleMutationFn = Apollo.MutationFunction<UpdateMemberRoleMutation, UpdateMemberRoleMutationVariables>;

/**
 * __useUpdateMemberRoleMutation__
 *
 * To run a mutation, you first call `useUpdateMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberRoleMutation, { data, loading, error }] = useUpdateMemberRoleMutation({
 *   variables: {
 *      firmAccountId: // value for 'firmAccountId'
 *      userId: // value for 'userId'
 *      newRoleId: // value for 'newRoleId'
 *      newEmail: // value for 'newEmail'
 *   },
 * });
 */
export function useUpdateMemberRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberRoleMutation, UpdateMemberRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMemberRoleMutation, UpdateMemberRoleMutationVariables>(UpdateMemberRoleDocument, options);
      }
export type UpdateMemberRoleMutationHookResult = ReturnType<typeof useUpdateMemberRoleMutation>;
export type UpdateMemberRoleMutationResult = Apollo.MutationResult<UpdateMemberRoleMutation>;
export type UpdateMemberRoleMutationOptions = Apollo.BaseMutationOptions<UpdateMemberRoleMutation, UpdateMemberRoleMutationVariables>;
export const DeleteTeamMemberDocument = gql`
    mutation DeleteTeamMember($firmAccountId: String!, $userId: String!) {
  deleteTeamMember(firmAccountId: $firmAccountId, userId: $userId) {
    id
  }
}
    `;
export type DeleteTeamMemberMutationFn = Apollo.MutationFunction<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>;

/**
 * __useDeleteTeamMemberMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMemberMutation, { data, loading, error }] = useDeleteTeamMemberMutation({
 *   variables: {
 *      firmAccountId: // value for 'firmAccountId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>(DeleteTeamMemberDocument, options);
      }
export type DeleteTeamMemberMutationHookResult = ReturnType<typeof useDeleteTeamMemberMutation>;
export type DeleteTeamMemberMutationResult = Apollo.MutationResult<DeleteTeamMemberMutation>;
export type DeleteTeamMemberMutationOptions = Apollo.BaseMutationOptions<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>;
export const ReactivateTeamMemberDocument = gql`
    mutation ReactivateTeamMember($firmAccountId: String!, $userId: String!) {
  reactivateTeamMember(firmAccountId: $firmAccountId, userId: $userId) {
    id
  }
}
    `;
export type ReactivateTeamMemberMutationFn = Apollo.MutationFunction<ReactivateTeamMemberMutation, ReactivateTeamMemberMutationVariables>;

/**
 * __useReactivateTeamMemberMutation__
 *
 * To run a mutation, you first call `useReactivateTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateTeamMemberMutation, { data, loading, error }] = useReactivateTeamMemberMutation({
 *   variables: {
 *      firmAccountId: // value for 'firmAccountId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useReactivateTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateTeamMemberMutation, ReactivateTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateTeamMemberMutation, ReactivateTeamMemberMutationVariables>(ReactivateTeamMemberDocument, options);
      }
export type ReactivateTeamMemberMutationHookResult = ReturnType<typeof useReactivateTeamMemberMutation>;
export type ReactivateTeamMemberMutationResult = Apollo.MutationResult<ReactivateTeamMemberMutation>;
export type ReactivateTeamMemberMutationOptions = Apollo.BaseMutationOptions<ReactivateTeamMemberMutation, ReactivateTeamMemberMutationVariables>;
export const ValidateOtpDocument = gql`
    mutation ValidateOTP($validateOtp: ValidateOtpInput!) {
  validateOTP(validateOtp: $validateOtp) {
    secretOTP
  }
}
    `;
export type ValidateOtpMutationFn = Apollo.MutationFunction<ValidateOtpMutation, ValidateOtpMutationVariables>;

/**
 * __useValidateOtpMutation__
 *
 * To run a mutation, you first call `useValidateOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateOtpMutation, { data, loading, error }] = useValidateOtpMutation({
 *   variables: {
 *      validateOtp: // value for 'validateOtp'
 *   },
 * });
 */
export function useValidateOtpMutation(baseOptions?: Apollo.MutationHookOptions<ValidateOtpMutation, ValidateOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateOtpMutation, ValidateOtpMutationVariables>(ValidateOtpDocument, options);
      }
export type ValidateOtpMutationHookResult = ReturnType<typeof useValidateOtpMutation>;
export type ValidateOtpMutationResult = Apollo.MutationResult<ValidateOtpMutation>;
export type ValidateOtpMutationOptions = Apollo.BaseMutationOptions<ValidateOtpMutation, ValidateOtpMutationVariables>;
export const DeleteTwoStepAuthFromUserDocument = gql`
    mutation DeleteTwoStepAuthFromUser($validateOtp: ValidateOtpInput!) {
  deleteTwoStepAuthFromUser(validateOtp: $validateOtp) {
    success
  }
}
    `;
export type DeleteTwoStepAuthFromUserMutationFn = Apollo.MutationFunction<DeleteTwoStepAuthFromUserMutation, DeleteTwoStepAuthFromUserMutationVariables>;

/**
 * __useDeleteTwoStepAuthFromUserMutation__
 *
 * To run a mutation, you first call `useDeleteTwoStepAuthFromUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTwoStepAuthFromUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTwoStepAuthFromUserMutation, { data, loading, error }] = useDeleteTwoStepAuthFromUserMutation({
 *   variables: {
 *      validateOtp: // value for 'validateOtp'
 *   },
 * });
 */
export function useDeleteTwoStepAuthFromUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTwoStepAuthFromUserMutation, DeleteTwoStepAuthFromUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTwoStepAuthFromUserMutation, DeleteTwoStepAuthFromUserMutationVariables>(DeleteTwoStepAuthFromUserDocument, options);
      }
export type DeleteTwoStepAuthFromUserMutationHookResult = ReturnType<typeof useDeleteTwoStepAuthFromUserMutation>;
export type DeleteTwoStepAuthFromUserMutationResult = Apollo.MutationResult<DeleteTwoStepAuthFromUserMutation>;
export type DeleteTwoStepAuthFromUserMutationOptions = Apollo.BaseMutationOptions<DeleteTwoStepAuthFromUserMutation, DeleteTwoStepAuthFromUserMutationVariables>;
export const UpdateFirmAccountDocument = gql`
    mutation UpdateFirmAccount($updateFirmAccountInput: UpdateFirmAccountInput!) {
  updateFirmAccount(updateFirmAccountInput: $updateFirmAccountInput) {
    id
    name
    type
    businessType
    uniqueLink
    createAt
    updateAt
    deleteAt
  }
}
    `;
export type UpdateFirmAccountMutationFn = Apollo.MutationFunction<UpdateFirmAccountMutation, UpdateFirmAccountMutationVariables>;

/**
 * __useUpdateFirmAccountMutation__
 *
 * To run a mutation, you first call `useUpdateFirmAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFirmAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFirmAccountMutation, { data, loading, error }] = useUpdateFirmAccountMutation({
 *   variables: {
 *      updateFirmAccountInput: // value for 'updateFirmAccountInput'
 *   },
 * });
 */
export function useUpdateFirmAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFirmAccountMutation, UpdateFirmAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFirmAccountMutation, UpdateFirmAccountMutationVariables>(UpdateFirmAccountDocument, options);
      }
export type UpdateFirmAccountMutationHookResult = ReturnType<typeof useUpdateFirmAccountMutation>;
export type UpdateFirmAccountMutationResult = Apollo.MutationResult<UpdateFirmAccountMutation>;
export type UpdateFirmAccountMutationOptions = Apollo.BaseMutationOptions<UpdateFirmAccountMutation, UpdateFirmAccountMutationVariables>;
export const CreateBusinessInformationDocument = gql`
    mutation CreateBusinessInformation($createBusinessInformationInput: CreateBusinessInformationInput!) {
  createBusinessInformation(
    createBusinessInformationInput: $createBusinessInformationInput
  ) {
    id
    taxReturns
    yearsInBusiness
    taxPercent
    accountingPercent
    bookKeepingPercent
    taxResolutionPercent
    payrollPercent
    other
    firmAccountId
  }
}
    `;
export type CreateBusinessInformationMutationFn = Apollo.MutationFunction<CreateBusinessInformationMutation, CreateBusinessInformationMutationVariables>;

/**
 * __useCreateBusinessInformationMutation__
 *
 * To run a mutation, you first call `useCreateBusinessInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessInformationMutation, { data, loading, error }] = useCreateBusinessInformationMutation({
 *   variables: {
 *      createBusinessInformationInput: // value for 'createBusinessInformationInput'
 *   },
 * });
 */
export function useCreateBusinessInformationMutation(baseOptions?: Apollo.MutationHookOptions<CreateBusinessInformationMutation, CreateBusinessInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBusinessInformationMutation, CreateBusinessInformationMutationVariables>(CreateBusinessInformationDocument, options);
      }
export type CreateBusinessInformationMutationHookResult = ReturnType<typeof useCreateBusinessInformationMutation>;
export type CreateBusinessInformationMutationResult = Apollo.MutationResult<CreateBusinessInformationMutation>;
export type CreateBusinessInformationMutationOptions = Apollo.BaseMutationOptions<CreateBusinessInformationMutation, CreateBusinessInformationMutationVariables>;
export const UpdateBusinessInformationDocument = gql`
    mutation UpdateBusinessInformation($updateBusinessInformationInput: UpdateBusinessInformationInput!) {
  updateBusinessInformation(
    updateBusinessInformationInput: $updateBusinessInformationInput
  ) {
    id
    taxReturns
    yearsInBusiness
    taxPercent
    accountingPercent
    bookKeepingPercent
    taxResolutionPercent
    payrollPercent
    other
    firmAccountId
  }
}
    `;
export type UpdateBusinessInformationMutationFn = Apollo.MutationFunction<UpdateBusinessInformationMutation, UpdateBusinessInformationMutationVariables>;

/**
 * __useUpdateBusinessInformationMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessInformationMutation, { data, loading, error }] = useUpdateBusinessInformationMutation({
 *   variables: {
 *      updateBusinessInformationInput: // value for 'updateBusinessInformationInput'
 *   },
 * });
 */
export function useUpdateBusinessInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessInformationMutation, UpdateBusinessInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessInformationMutation, UpdateBusinessInformationMutationVariables>(UpdateBusinessInformationDocument, options);
      }
export type UpdateBusinessInformationMutationHookResult = ReturnType<typeof useUpdateBusinessInformationMutation>;
export type UpdateBusinessInformationMutationResult = Apollo.MutationResult<UpdateBusinessInformationMutation>;
export type UpdateBusinessInformationMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessInformationMutation, UpdateBusinessInformationMutationVariables>;
export const RemoveBusinessInformationDocument = gql`
    mutation RemoveBusinessInformation($id: String!) {
  removeBusinessInformation(id: $id) {
    id
    taxReturns
    yearsInBusiness
    taxPercent
    accountingPercent
    bookKeepingPercent
    payrollPercent
    taxResolutionPercent
    other
    firmAccountId
  }
}
    `;
export type RemoveBusinessInformationMutationFn = Apollo.MutationFunction<RemoveBusinessInformationMutation, RemoveBusinessInformationMutationVariables>;

/**
 * __useRemoveBusinessInformationMutation__
 *
 * To run a mutation, you first call `useRemoveBusinessInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBusinessInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBusinessInformationMutation, { data, loading, error }] = useRemoveBusinessInformationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveBusinessInformationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveBusinessInformationMutation, RemoveBusinessInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveBusinessInformationMutation, RemoveBusinessInformationMutationVariables>(RemoveBusinessInformationDocument, options);
      }
export type RemoveBusinessInformationMutationHookResult = ReturnType<typeof useRemoveBusinessInformationMutation>;
export type RemoveBusinessInformationMutationResult = Apollo.MutationResult<RemoveBusinessInformationMutation>;
export type RemoveBusinessInformationMutationOptions = Apollo.BaseMutationOptions<RemoveBusinessInformationMutation, RemoveBusinessInformationMutationVariables>;
export const UpdateFirmNotificationsDocument = gql`
    mutation UpdateFirmNotifications($updateFirmNotificationsStatusInput: UpdateFirmNotificationsStatusInput!) {
  updateFirmNotificationsStatus(
    updateFirmNotificationsStatusInput: $updateFirmNotificationsStatusInput
  ) {
    id
    firmAccountId
    notificationId
    active
    Notifications {
      name
    }
  }
}
    `;
export type UpdateFirmNotificationsMutationFn = Apollo.MutationFunction<UpdateFirmNotificationsMutation, UpdateFirmNotificationsMutationVariables>;

/**
 * __useUpdateFirmNotificationsMutation__
 *
 * To run a mutation, you first call `useUpdateFirmNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFirmNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFirmNotificationsMutation, { data, loading, error }] = useUpdateFirmNotificationsMutation({
 *   variables: {
 *      updateFirmNotificationsStatusInput: // value for 'updateFirmNotificationsStatusInput'
 *   },
 * });
 */
export function useUpdateFirmNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFirmNotificationsMutation, UpdateFirmNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFirmNotificationsMutation, UpdateFirmNotificationsMutationVariables>(UpdateFirmNotificationsDocument, options);
      }
export type UpdateFirmNotificationsMutationHookResult = ReturnType<typeof useUpdateFirmNotificationsMutation>;
export type UpdateFirmNotificationsMutationResult = Apollo.MutationResult<UpdateFirmNotificationsMutation>;
export type UpdateFirmNotificationsMutationOptions = Apollo.BaseMutationOptions<UpdateFirmNotificationsMutation, UpdateFirmNotificationsMutationVariables>;
export const CreateFirmNotificationReminderDocument = gql`
    mutation CreateFirmNotificationReminder($createFirmNotificationReminderInput: CreateFirmNotificationReminderInput!) {
  createFirmNotificationReminder(
    createFirmNotificationReminderInput: $createFirmNotificationReminderInput
  ) {
    id
    firmNotificationId
    frequency
    maxReminders
  }
}
    `;
export type CreateFirmNotificationReminderMutationFn = Apollo.MutationFunction<CreateFirmNotificationReminderMutation, CreateFirmNotificationReminderMutationVariables>;

/**
 * __useCreateFirmNotificationReminderMutation__
 *
 * To run a mutation, you first call `useCreateFirmNotificationReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFirmNotificationReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFirmNotificationReminderMutation, { data, loading, error }] = useCreateFirmNotificationReminderMutation({
 *   variables: {
 *      createFirmNotificationReminderInput: // value for 'createFirmNotificationReminderInput'
 *   },
 * });
 */
export function useCreateFirmNotificationReminderMutation(baseOptions?: Apollo.MutationHookOptions<CreateFirmNotificationReminderMutation, CreateFirmNotificationReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFirmNotificationReminderMutation, CreateFirmNotificationReminderMutationVariables>(CreateFirmNotificationReminderDocument, options);
      }
export type CreateFirmNotificationReminderMutationHookResult = ReturnType<typeof useCreateFirmNotificationReminderMutation>;
export type CreateFirmNotificationReminderMutationResult = Apollo.MutationResult<CreateFirmNotificationReminderMutation>;
export type CreateFirmNotificationReminderMutationOptions = Apollo.BaseMutationOptions<CreateFirmNotificationReminderMutation, CreateFirmNotificationReminderMutationVariables>;
export const UpdateFirmNotificationReminderDocument = gql`
    mutation UpdateFirmNotificationReminder($updateFirmNotificationReminderInput: UpdateFirmNotificationReminderInput!) {
  updateFirmNotificationReminderWithFirmNotificationId(
    updateFirmNotificationReminderInput: $updateFirmNotificationReminderInput
  ) {
    id
    firmNotificationId
    frequency
    maxReminders
  }
}
    `;
export type UpdateFirmNotificationReminderMutationFn = Apollo.MutationFunction<UpdateFirmNotificationReminderMutation, UpdateFirmNotificationReminderMutationVariables>;

/**
 * __useUpdateFirmNotificationReminderMutation__
 *
 * To run a mutation, you first call `useUpdateFirmNotificationReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFirmNotificationReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFirmNotificationReminderMutation, { data, loading, error }] = useUpdateFirmNotificationReminderMutation({
 *   variables: {
 *      updateFirmNotificationReminderInput: // value for 'updateFirmNotificationReminderInput'
 *   },
 * });
 */
export function useUpdateFirmNotificationReminderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFirmNotificationReminderMutation, UpdateFirmNotificationReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFirmNotificationReminderMutation, UpdateFirmNotificationReminderMutationVariables>(UpdateFirmNotificationReminderDocument, options);
      }
export type UpdateFirmNotificationReminderMutationHookResult = ReturnType<typeof useUpdateFirmNotificationReminderMutation>;
export type UpdateFirmNotificationReminderMutationResult = Apollo.MutationResult<UpdateFirmNotificationReminderMutation>;
export type UpdateFirmNotificationReminderMutationOptions = Apollo.BaseMutationOptions<UpdateFirmNotificationReminderMutation, UpdateFirmNotificationReminderMutationVariables>;
export const RemoveFirmNotificationReminderDocument = gql`
    mutation RemoveFirmNotificationReminder($id: String!) {
  removeFirmNotificationReminder(id: $id) {
    id
    firmNotificationId
    frequency
    maxReminders
  }
}
    `;
export type RemoveFirmNotificationReminderMutationFn = Apollo.MutationFunction<RemoveFirmNotificationReminderMutation, RemoveFirmNotificationReminderMutationVariables>;

/**
 * __useRemoveFirmNotificationReminderMutation__
 *
 * To run a mutation, you first call `useRemoveFirmNotificationReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFirmNotificationReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFirmNotificationReminderMutation, { data, loading, error }] = useRemoveFirmNotificationReminderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFirmNotificationReminderMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFirmNotificationReminderMutation, RemoveFirmNotificationReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFirmNotificationReminderMutation, RemoveFirmNotificationReminderMutationVariables>(RemoveFirmNotificationReminderDocument, options);
      }
export type RemoveFirmNotificationReminderMutationHookResult = ReturnType<typeof useRemoveFirmNotificationReminderMutation>;
export type RemoveFirmNotificationReminderMutationResult = Apollo.MutationResult<RemoveFirmNotificationReminderMutation>;
export type RemoveFirmNotificationReminderMutationOptions = Apollo.BaseMutationOptions<RemoveFirmNotificationReminderMutation, RemoveFirmNotificationReminderMutationVariables>;
export const UpdatePixelsDocument = gql`
    mutation UpdatePixels($updateCustomLayoutInput: UpdateCustomLayoutInput!) {
  updatePixels(updateCustomLayoutInput: $updateCustomLayoutInput) {
    id
    pixelCode
  }
}
    `;
export type UpdatePixelsMutationFn = Apollo.MutationFunction<UpdatePixelsMutation, UpdatePixelsMutationVariables>;

/**
 * __useUpdatePixelsMutation__
 *
 * To run a mutation, you first call `useUpdatePixelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePixelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePixelsMutation, { data, loading, error }] = useUpdatePixelsMutation({
 *   variables: {
 *      updateCustomLayoutInput: // value for 'updateCustomLayoutInput'
 *   },
 * });
 */
export function useUpdatePixelsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePixelsMutation, UpdatePixelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePixelsMutation, UpdatePixelsMutationVariables>(UpdatePixelsDocument, options);
      }
export type UpdatePixelsMutationHookResult = ReturnType<typeof useUpdatePixelsMutation>;
export type UpdatePixelsMutationResult = Apollo.MutationResult<UpdatePixelsMutation>;
export type UpdatePixelsMutationOptions = Apollo.BaseMutationOptions<UpdatePixelsMutation, UpdatePixelsMutationVariables>;
export const CreateClientDocument = gql`
    mutation CreateClient($createClientInput: CreateClientInput!) {
  CreateClient(createClientInput: $createClientInput) {
    id
    email
  }
}
    `;
export type CreateClientMutationFn = Apollo.MutationFunction<CreateClientMutation, CreateClientMutationVariables>;

/**
 * __useCreateClientMutation__
 *
 * To run a mutation, you first call `useCreateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientMutation, { data, loading, error }] = useCreateClientMutation({
 *   variables: {
 *      createClientInput: // value for 'createClientInput'
 *   },
 * });
 */
export function useCreateClientMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientMutation, CreateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientMutation, CreateClientMutationVariables>(CreateClientDocument, options);
      }
export type CreateClientMutationHookResult = ReturnType<typeof useCreateClientMutation>;
export type CreateClientMutationResult = Apollo.MutationResult<CreateClientMutation>;
export type CreateClientMutationOptions = Apollo.BaseMutationOptions<CreateClientMutation, CreateClientMutationVariables>;
export const SearchUnassignedClientsDocument = gql`
    mutation SearchUnassignedClients($searchClientInput: SearchClientInput!) {
  SearchUnassigned(searchClientInput: $searchClientInput) {
    firmAccountId
    entityId
    Entity {
      Users {
        email
        id
        UserInformation {
          id
          firstName
          lastName
        }
      }
      TaxYear {
        year
        TaxYearInterview {
          id
          updateAt
          PreparedUser {
            id
            email
            UserInformation {
              firstName
              lastName
            }
          }
          ReviewedUser {
            id
            email
            UserInformation {
              firstName
              lastName
            }
          }
          Interviews {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export type SearchUnassignedClientsMutationFn = Apollo.MutationFunction<SearchUnassignedClientsMutation, SearchUnassignedClientsMutationVariables>;

/**
 * __useSearchUnassignedClientsMutation__
 *
 * To run a mutation, you first call `useSearchUnassignedClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchUnassignedClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchUnassignedClientsMutation, { data, loading, error }] = useSearchUnassignedClientsMutation({
 *   variables: {
 *      searchClientInput: // value for 'searchClientInput'
 *   },
 * });
 */
export function useSearchUnassignedClientsMutation(baseOptions?: Apollo.MutationHookOptions<SearchUnassignedClientsMutation, SearchUnassignedClientsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SearchUnassignedClientsMutation, SearchUnassignedClientsMutationVariables>(SearchUnassignedClientsDocument, options);
      }
export type SearchUnassignedClientsMutationHookResult = ReturnType<typeof useSearchUnassignedClientsMutation>;
export type SearchUnassignedClientsMutationResult = Apollo.MutationResult<SearchUnassignedClientsMutation>;
export type SearchUnassignedClientsMutationOptions = Apollo.BaseMutationOptions<SearchUnassignedClientsMutation, SearchUnassignedClientsMutationVariables>;
export const SearchInProgressClientsDocument = gql`
    mutation SearchInProgressClients($searchClientInput: SearchClientInput!) {
  SearchInProgress(searchClientInput: $searchClientInput) {
    firmAccountId
    entityId
    Entity {
      Users {
        id
        email
        UserInformation {
          id
          firstName
          lastName
        }
        email
      }
      TaxYear {
        id
        year
        TaxYearInterview {
          id
          updateAt
          ReviewedUser {
            id
            email
            UserInformation {
              firstName
              lastName
            }
          }
          PreparedUser {
            id
            email
            UserInformation {
              firstName
              lastName
            }
          }
          Interviews {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export type SearchInProgressClientsMutationFn = Apollo.MutationFunction<SearchInProgressClientsMutation, SearchInProgressClientsMutationVariables>;

/**
 * __useSearchInProgressClientsMutation__
 *
 * To run a mutation, you first call `useSearchInProgressClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchInProgressClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchInProgressClientsMutation, { data, loading, error }] = useSearchInProgressClientsMutation({
 *   variables: {
 *      searchClientInput: // value for 'searchClientInput'
 *   },
 * });
 */
export function useSearchInProgressClientsMutation(baseOptions?: Apollo.MutationHookOptions<SearchInProgressClientsMutation, SearchInProgressClientsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SearchInProgressClientsMutation, SearchInProgressClientsMutationVariables>(SearchInProgressClientsDocument, options);
      }
export type SearchInProgressClientsMutationHookResult = ReturnType<typeof useSearchInProgressClientsMutation>;
export type SearchInProgressClientsMutationResult = Apollo.MutationResult<SearchInProgressClientsMutation>;
export type SearchInProgressClientsMutationOptions = Apollo.BaseMutationOptions<SearchInProgressClientsMutation, SearchInProgressClientsMutationVariables>;
export const SearchCompletedClientsDocument = gql`
    mutation SearchCompletedClients($searchClientInput: SearchClientInput!) {
  SearchCompleted(searchClientInput: $searchClientInput) {
    firmAccountId
    entityId
    Entity {
      Users {
        id
        email
        UserInformation {
          id
          firstName
          lastName
        }
        email
      }
      TaxYear {
        year
        TaxYearInterview {
          id
          updateAt
          Interviews {
            id
            name
          }
          ReviewedUser {
            id
            email
            UserInformation {
              firstName
              lastName
            }
          }
          PreparedUser {
            id
            email
            UserInformation {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
}
    `;
export type SearchCompletedClientsMutationFn = Apollo.MutationFunction<SearchCompletedClientsMutation, SearchCompletedClientsMutationVariables>;

/**
 * __useSearchCompletedClientsMutation__
 *
 * To run a mutation, you first call `useSearchCompletedClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchCompletedClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchCompletedClientsMutation, { data, loading, error }] = useSearchCompletedClientsMutation({
 *   variables: {
 *      searchClientInput: // value for 'searchClientInput'
 *   },
 * });
 */
export function useSearchCompletedClientsMutation(baseOptions?: Apollo.MutationHookOptions<SearchCompletedClientsMutation, SearchCompletedClientsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SearchCompletedClientsMutation, SearchCompletedClientsMutationVariables>(SearchCompletedClientsDocument, options);
      }
export type SearchCompletedClientsMutationHookResult = ReturnType<typeof useSearchCompletedClientsMutation>;
export type SearchCompletedClientsMutationResult = Apollo.MutationResult<SearchCompletedClientsMutation>;
export type SearchCompletedClientsMutationOptions = Apollo.BaseMutationOptions<SearchCompletedClientsMutation, SearchCompletedClientsMutationVariables>;
export const CreateTaxYearInterviewDocument = gql`
    mutation CreateTaxYearInterview($createTaxYearInterviewInput: CreateTaxYearInterviewInput!) {
  createTaxYearInterview(
    createTaxYearInterviewInput: $createTaxYearInterviewInput
  ) {
    id
    progressStatusId
    assignedAt
    createAt
    updateAt
    preparedId
    reviewedId
  }
}
    `;
export type CreateTaxYearInterviewMutationFn = Apollo.MutationFunction<CreateTaxYearInterviewMutation, CreateTaxYearInterviewMutationVariables>;

/**
 * __useCreateTaxYearInterviewMutation__
 *
 * To run a mutation, you first call `useCreateTaxYearInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaxYearInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaxYearInterviewMutation, { data, loading, error }] = useCreateTaxYearInterviewMutation({
 *   variables: {
 *      createTaxYearInterviewInput: // value for 'createTaxYearInterviewInput'
 *   },
 * });
 */
export function useCreateTaxYearInterviewMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaxYearInterviewMutation, CreateTaxYearInterviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaxYearInterviewMutation, CreateTaxYearInterviewMutationVariables>(CreateTaxYearInterviewDocument, options);
      }
export type CreateTaxYearInterviewMutationHookResult = ReturnType<typeof useCreateTaxYearInterviewMutation>;
export type CreateTaxYearInterviewMutationResult = Apollo.MutationResult<CreateTaxYearInterviewMutation>;
export type CreateTaxYearInterviewMutationOptions = Apollo.BaseMutationOptions<CreateTaxYearInterviewMutation, CreateTaxYearInterviewMutationVariables>;
export const UpdateTaxYearInterviewDocument = gql`
    mutation UpdateTaxYearInterview($updateTaxYearInterviewInput: UpdateTaxYearInterviewInput!) {
  updateTaxYearInterview(
    updateTaxYearInterviewInput: $updateTaxYearInterviewInput
  ) {
    id
    taxYearId
    preparedId
    reviewedId
    interviewId
    progressStatusId
    notes
    reviewStatus
    createAt
    updateAt
    completedAt
    assignedAt
    PreparedUser {
      id
      email
      UserInformation {
        id
        userId
        userId
        birthday
        firstName
        lastName
        createAt
        updateAt
      }
    }
    ReviewedUser {
      id
      email
      UserInformation {
        id
        userId
        userId
        birthday
        firstName
        lastName
        createAt
        updateAt
      }
    }
    TaxYear {
      id
      entityId
      year
      createAt
      Entity {
        id
        ownerId
        Users {
          id
          email
          createAt
          languageId
          updateAt
          UserInformation {
            id
            userId
            birthday
            firstName
            lastName
            createAt
            updateAt
          }
        }
      }
    }
    Interviews {
      id
      name
      isLive
      createAt
      updateAt
      isTemplate
      firmAccountId
    }
    ProgressStatuses {
      id
      status
      underlyingStatus
      index
    }
  }
}
    `;
export type UpdateTaxYearInterviewMutationFn = Apollo.MutationFunction<UpdateTaxYearInterviewMutation, UpdateTaxYearInterviewMutationVariables>;

/**
 * __useUpdateTaxYearInterviewMutation__
 *
 * To run a mutation, you first call `useUpdateTaxYearInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxYearInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaxYearInterviewMutation, { data, loading, error }] = useUpdateTaxYearInterviewMutation({
 *   variables: {
 *      updateTaxYearInterviewInput: // value for 'updateTaxYearInterviewInput'
 *   },
 * });
 */
export function useUpdateTaxYearInterviewMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaxYearInterviewMutation, UpdateTaxYearInterviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaxYearInterviewMutation, UpdateTaxYearInterviewMutationVariables>(UpdateTaxYearInterviewDocument, options);
      }
export type UpdateTaxYearInterviewMutationHookResult = ReturnType<typeof useUpdateTaxYearInterviewMutation>;
export type UpdateTaxYearInterviewMutationResult = Apollo.MutationResult<UpdateTaxYearInterviewMutation>;
export type UpdateTaxYearInterviewMutationOptions = Apollo.BaseMutationOptions<UpdateTaxYearInterviewMutation, UpdateTaxYearInterviewMutationVariables>;
export const DeleteTaxYearInterviewDocument = gql`
    mutation DeleteTaxYearInterview($id: String!) {
  DeleteJob(id: $id) {
    id
    taxYearId
    preparedId
    reviewedId
    interviewId
    progressStatusId
    notes
    assignedAt
    completedAt
    preparerAcceptedAt
    reviewStatus
    createAt
    updateAt
    deletedAt
  }
}
    `;
export type DeleteTaxYearInterviewMutationFn = Apollo.MutationFunction<DeleteTaxYearInterviewMutation, DeleteTaxYearInterviewMutationVariables>;

/**
 * __useDeleteTaxYearInterviewMutation__
 *
 * To run a mutation, you first call `useDeleteTaxYearInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaxYearInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaxYearInterviewMutation, { data, loading, error }] = useDeleteTaxYearInterviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaxYearInterviewMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaxYearInterviewMutation, DeleteTaxYearInterviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaxYearInterviewMutation, DeleteTaxYearInterviewMutationVariables>(DeleteTaxYearInterviewDocument, options);
      }
export type DeleteTaxYearInterviewMutationHookResult = ReturnType<typeof useDeleteTaxYearInterviewMutation>;
export type DeleteTaxYearInterviewMutationResult = Apollo.MutationResult<DeleteTaxYearInterviewMutation>;
export type DeleteTaxYearInterviewMutationOptions = Apollo.BaseMutationOptions<DeleteTaxYearInterviewMutation, DeleteTaxYearInterviewMutationVariables>;
export const AssignTaxYearInterviewDocument = gql`
    mutation AssignTaxYearInterview($input: UpdateTaxYearInterviewInput!) {
  AssignTaxYearInterviewJob(updateTaxYearInterviewInput: $input) {
    id
    preparedId
    reviewedId
    progressStatusId
  }
}
    `;
export type AssignTaxYearInterviewMutationFn = Apollo.MutationFunction<AssignTaxYearInterviewMutation, AssignTaxYearInterviewMutationVariables>;

/**
 * __useAssignTaxYearInterviewMutation__
 *
 * To run a mutation, you first call `useAssignTaxYearInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTaxYearInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTaxYearInterviewMutation, { data, loading, error }] = useAssignTaxYearInterviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignTaxYearInterviewMutation(baseOptions?: Apollo.MutationHookOptions<AssignTaxYearInterviewMutation, AssignTaxYearInterviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignTaxYearInterviewMutation, AssignTaxYearInterviewMutationVariables>(AssignTaxYearInterviewDocument, options);
      }
export type AssignTaxYearInterviewMutationHookResult = ReturnType<typeof useAssignTaxYearInterviewMutation>;
export type AssignTaxYearInterviewMutationResult = Apollo.MutationResult<AssignTaxYearInterviewMutation>;
export type AssignTaxYearInterviewMutationOptions = Apollo.BaseMutationOptions<AssignTaxYearInterviewMutation, AssignTaxYearInterviewMutationVariables>;
export const UpdateSubscriptionDocument = gql`
    mutation UpdateSubscription($updateStripeSubscriptionInput: UpdateStripeSubscriptionInput!, $finishTrial: Boolean, $resume: Boolean) {
  updateStripeSubscription(
    updateStripSubscriptionInput: $updateStripeSubscriptionInput
    finishTrial: $finishTrial
    resume: $resume
  ) {
    id
    object
    created
    ended_at
    customer
    livemode
    cancel_at
    canceled_at
    days_until_due
    default_source
    current_period_end
    current_period_start
    billing_cycle_anchor
    cancel_at_period_end
    default_payment_method
    application_fee_percent
    items {
      object
      data {
        id
        object
        created
        billing_thresholds {
          usage_gte
        }
        plan {
          id
          object
          active
          amount
          created
          currency
          interval
          livemode
          tiers_mode
          amount_decimal
          billing_scheme
          interval_count
          trial_period_days
          tiers {
            flat_amount
            flat_amount_decimal
            unit_amount
            unit_amount_decimal
            up_to
          }
        }
      }
    }
    metadata {
      plan_name
    }
    status
    quantity
    schedule
    trial_end
    start_date
    trial_start
    pending_setup_intent
    next_pending_invoice_item_invoice
  }
}
    `;
export type UpdateSubscriptionMutationFn = Apollo.MutationFunction<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;

/**
 * __useUpdateSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionMutation, { data, loading, error }] = useUpdateSubscriptionMutation({
 *   variables: {
 *      updateStripeSubscriptionInput: // value for 'updateStripeSubscriptionInput'
 *      finishTrial: // value for 'finishTrial'
 *      resume: // value for 'resume'
 *   },
 * });
 */
export function useUpdateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>(UpdateSubscriptionDocument, options);
      }
export type UpdateSubscriptionMutationHookResult = ReturnType<typeof useUpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationResult = Apollo.MutationResult<UpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;
export const ResumeSubscriptionDocument = gql`
    mutation ResumeSubscription($subscriptionId: String!) {
  ResumeSubscription(subscriptionId: $subscriptionId) {
    id
    current_period_end
    current_period_start
    billing_cycle_anchor
    pause_collection {
      behavior
      resumes_at
    }
  }
}
    `;
export type ResumeSubscriptionMutationFn = Apollo.MutationFunction<ResumeSubscriptionMutation, ResumeSubscriptionMutationVariables>;

/**
 * __useResumeSubscriptionMutation__
 *
 * To run a mutation, you first call `useResumeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeSubscriptionMutation, { data, loading, error }] = useResumeSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useResumeSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ResumeSubscriptionMutation, ResumeSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResumeSubscriptionMutation, ResumeSubscriptionMutationVariables>(ResumeSubscriptionDocument, options);
      }
export type ResumeSubscriptionMutationHookResult = ReturnType<typeof useResumeSubscriptionMutation>;
export type ResumeSubscriptionMutationResult = Apollo.MutationResult<ResumeSubscriptionMutation>;
export type ResumeSubscriptionMutationOptions = Apollo.BaseMutationOptions<ResumeSubscriptionMutation, ResumeSubscriptionMutationVariables>;
export const SubscribeToPlanDocument = gql`
    mutation SubscribeToPlan($createStripeSubscriptionInput: CreateStripeSubscriptionInput!) {
  createSubscription(
    createStripeSubscriptionInput: $createStripeSubscriptionInput
  ) {
    id
  }
}
    `;
export type SubscribeToPlanMutationFn = Apollo.MutationFunction<SubscribeToPlanMutation, SubscribeToPlanMutationVariables>;

/**
 * __useSubscribeToPlanMutation__
 *
 * To run a mutation, you first call `useSubscribeToPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToPlanMutation, { data, loading, error }] = useSubscribeToPlanMutation({
 *   variables: {
 *      createStripeSubscriptionInput: // value for 'createStripeSubscriptionInput'
 *   },
 * });
 */
export function useSubscribeToPlanMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeToPlanMutation, SubscribeToPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeToPlanMutation, SubscribeToPlanMutationVariables>(SubscribeToPlanDocument, options);
      }
export type SubscribeToPlanMutationHookResult = ReturnType<typeof useSubscribeToPlanMutation>;
export type SubscribeToPlanMutationResult = Apollo.MutationResult<SubscribeToPlanMutation>;
export type SubscribeToPlanMutationOptions = Apollo.BaseMutationOptions<SubscribeToPlanMutation, SubscribeToPlanMutationVariables>;
export const DeleteSubscriptionDocument = gql`
    mutation DeleteSubscription($subscriptionId: String!, $deleteStripeSubscriptionInput: DeleteStripeSubscriptionInput) {
  deleteSubscription(
    subscriptionId: $subscriptionId
    deleteStripeSubscriptionInput: $deleteStripeSubscriptionInput
  ) {
    id
  }
}
    `;
export type DeleteSubscriptionMutationFn = Apollo.MutationFunction<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>;

/**
 * __useDeleteSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeleteSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubscriptionMutation, { data, loading, error }] = useDeleteSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      deleteStripeSubscriptionInput: // value for 'deleteStripeSubscriptionInput'
 *   },
 * });
 */
export function useDeleteSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>(DeleteSubscriptionDocument, options);
      }
export type DeleteSubscriptionMutationHookResult = ReturnType<typeof useDeleteSubscriptionMutation>;
export type DeleteSubscriptionMutationResult = Apollo.MutationResult<DeleteSubscriptionMutation>;
export type DeleteSubscriptionMutationOptions = Apollo.BaseMutationOptions<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>;
export const UpdateJobNotesDocument = gql`
    mutation UpdateJobNotes($id: String!, $notes: String!) {
  UpdateJobNotes(id: $id, notes: $notes) {
    id
    notes
  }
}
    `;
export type UpdateJobNotesMutationFn = Apollo.MutationFunction<UpdateJobNotesMutation, UpdateJobNotesMutationVariables>;

/**
 * __useUpdateJobNotesMutation__
 *
 * To run a mutation, you first call `useUpdateJobNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobNotesMutation, { data, loading, error }] = useUpdateJobNotesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateJobNotesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobNotesMutation, UpdateJobNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJobNotesMutation, UpdateJobNotesMutationVariables>(UpdateJobNotesDocument, options);
      }
export type UpdateJobNotesMutationHookResult = ReturnType<typeof useUpdateJobNotesMutation>;
export type UpdateJobNotesMutationResult = Apollo.MutationResult<UpdateJobNotesMutation>;
export type UpdateJobNotesMutationOptions = Apollo.BaseMutationOptions<UpdateJobNotesMutation, UpdateJobNotesMutationVariables>;
export const GetSiteBrandingLogoPresignedUrlDocument = gql`
    mutation GetSiteBrandingLogoPresignedURL($mimeType: String!) {
  GetSiteBrandingLogoPresignedURL(mimeType: $mimeType)
}
    `;
export type GetSiteBrandingLogoPresignedUrlMutationFn = Apollo.MutationFunction<GetSiteBrandingLogoPresignedUrlMutation, GetSiteBrandingLogoPresignedUrlMutationVariables>;

/**
 * __useGetSiteBrandingLogoPresignedUrlMutation__
 *
 * To run a mutation, you first call `useGetSiteBrandingLogoPresignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSiteBrandingLogoPresignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSiteBrandingLogoPresignedUrlMutation, { data, loading, error }] = useGetSiteBrandingLogoPresignedUrlMutation({
 *   variables: {
 *      mimeType: // value for 'mimeType'
 *   },
 * });
 */
export function useGetSiteBrandingLogoPresignedUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetSiteBrandingLogoPresignedUrlMutation, GetSiteBrandingLogoPresignedUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetSiteBrandingLogoPresignedUrlMutation, GetSiteBrandingLogoPresignedUrlMutationVariables>(GetSiteBrandingLogoPresignedUrlDocument, options);
      }
export type GetSiteBrandingLogoPresignedUrlMutationHookResult = ReturnType<typeof useGetSiteBrandingLogoPresignedUrlMutation>;
export type GetSiteBrandingLogoPresignedUrlMutationResult = Apollo.MutationResult<GetSiteBrandingLogoPresignedUrlMutation>;
export type GetSiteBrandingLogoPresignedUrlMutationOptions = Apollo.BaseMutationOptions<GetSiteBrandingLogoPresignedUrlMutation, GetSiteBrandingLogoPresignedUrlMutationVariables>;
export const GetUserProfilePhotoPresignedUrlDocument = gql`
    mutation GetUserProfilePhotoPresignedURL($updateUserInformationInput: UpdateUserInformationInput!) {
  GetUserProfilePhotoPresignedURL(
    updateUserInformationInput: $updateUserInformationInput
  )
}
    `;
export type GetUserProfilePhotoPresignedUrlMutationFn = Apollo.MutationFunction<GetUserProfilePhotoPresignedUrlMutation, GetUserProfilePhotoPresignedUrlMutationVariables>;

/**
 * __useGetUserProfilePhotoPresignedUrlMutation__
 *
 * To run a mutation, you first call `useGetUserProfilePhotoPresignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfilePhotoPresignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUserProfilePhotoPresignedUrlMutation, { data, loading, error }] = useGetUserProfilePhotoPresignedUrlMutation({
 *   variables: {
 *      updateUserInformationInput: // value for 'updateUserInformationInput'
 *   },
 * });
 */
export function useGetUserProfilePhotoPresignedUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetUserProfilePhotoPresignedUrlMutation, GetUserProfilePhotoPresignedUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetUserProfilePhotoPresignedUrlMutation, GetUserProfilePhotoPresignedUrlMutationVariables>(GetUserProfilePhotoPresignedUrlDocument, options);
      }
export type GetUserProfilePhotoPresignedUrlMutationHookResult = ReturnType<typeof useGetUserProfilePhotoPresignedUrlMutation>;
export type GetUserProfilePhotoPresignedUrlMutationResult = Apollo.MutationResult<GetUserProfilePhotoPresignedUrlMutation>;
export type GetUserProfilePhotoPresignedUrlMutationOptions = Apollo.BaseMutationOptions<GetUserProfilePhotoPresignedUrlMutation, GetUserProfilePhotoPresignedUrlMutationVariables>;
export const CustomDomainResendEmailsDocument = gql`
    mutation CustomDomainResendEmails {
  CustomDomainResendEmails
}
    `;
export type CustomDomainResendEmailsMutationFn = Apollo.MutationFunction<CustomDomainResendEmailsMutation, CustomDomainResendEmailsMutationVariables>;

/**
 * __useCustomDomainResendEmailsMutation__
 *
 * To run a mutation, you first call `useCustomDomainResendEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomDomainResendEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customDomainResendEmailsMutation, { data, loading, error }] = useCustomDomainResendEmailsMutation({
 *   variables: {
 *   },
 * });
 */
export function useCustomDomainResendEmailsMutation(baseOptions?: Apollo.MutationHookOptions<CustomDomainResendEmailsMutation, CustomDomainResendEmailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomDomainResendEmailsMutation, CustomDomainResendEmailsMutationVariables>(CustomDomainResendEmailsDocument, options);
      }
export type CustomDomainResendEmailsMutationHookResult = ReturnType<typeof useCustomDomainResendEmailsMutation>;
export type CustomDomainResendEmailsMutationResult = Apollo.MutationResult<CustomDomainResendEmailsMutation>;
export type CustomDomainResendEmailsMutationOptions = Apollo.BaseMutationOptions<CustomDomainResendEmailsMutation, CustomDomainResendEmailsMutationVariables>;
export const GetOnBoardingLinkDocument = gql`
    mutation GetOnBoardingLink($returnUrl: String!) {
  GetOnBoardingLink(returnUrl: $returnUrl)
}
    `;
export type GetOnBoardingLinkMutationFn = Apollo.MutationFunction<GetOnBoardingLinkMutation, GetOnBoardingLinkMutationVariables>;

/**
 * __useGetOnBoardingLinkMutation__
 *
 * To run a mutation, you first call `useGetOnBoardingLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetOnBoardingLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getOnBoardingLinkMutation, { data, loading, error }] = useGetOnBoardingLinkMutation({
 *   variables: {
 *      returnUrl: // value for 'returnUrl'
 *   },
 * });
 */
export function useGetOnBoardingLinkMutation(baseOptions?: Apollo.MutationHookOptions<GetOnBoardingLinkMutation, GetOnBoardingLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetOnBoardingLinkMutation, GetOnBoardingLinkMutationVariables>(GetOnBoardingLinkDocument, options);
      }
export type GetOnBoardingLinkMutationHookResult = ReturnType<typeof useGetOnBoardingLinkMutation>;
export type GetOnBoardingLinkMutationResult = Apollo.MutationResult<GetOnBoardingLinkMutation>;
export type GetOnBoardingLinkMutationOptions = Apollo.BaseMutationOptions<GetOnBoardingLinkMutation, GetOnBoardingLinkMutationVariables>;
export const DeleteNylasAccountDocument = gql`
    mutation deleteNylasAccount($accountId: String!) {
  deleteNylasAccount(accountId: $accountId)
}
    `;
export type DeleteNylasAccountMutationFn = Apollo.MutationFunction<DeleteNylasAccountMutation, DeleteNylasAccountMutationVariables>;

/**
 * __useDeleteNylasAccountMutation__
 *
 * To run a mutation, you first call `useDeleteNylasAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNylasAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNylasAccountMutation, { data, loading, error }] = useDeleteNylasAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDeleteNylasAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNylasAccountMutation, DeleteNylasAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNylasAccountMutation, DeleteNylasAccountMutationVariables>(DeleteNylasAccountDocument, options);
      }
export type DeleteNylasAccountMutationHookResult = ReturnType<typeof useDeleteNylasAccountMutation>;
export type DeleteNylasAccountMutationResult = Apollo.MutationResult<DeleteNylasAccountMutation>;
export type DeleteNylasAccountMutationOptions = Apollo.BaseMutationOptions<DeleteNylasAccountMutation, DeleteNylasAccountMutationVariables>;
export const GetNylasAuthUrlDocument = gql`
    mutation getNylasAuthUrl($input: CreateNylasAuthDtoInput!) {
  getNylasAuthUrl(createNylasAuthDtoInput: $input) {
    url
  }
}
    `;
export type GetNylasAuthUrlMutationFn = Apollo.MutationFunction<GetNylasAuthUrlMutation, GetNylasAuthUrlMutationVariables>;

/**
 * __useGetNylasAuthUrlMutation__
 *
 * To run a mutation, you first call `useGetNylasAuthUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetNylasAuthUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getNylasAuthUrlMutation, { data, loading, error }] = useGetNylasAuthUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNylasAuthUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetNylasAuthUrlMutation, GetNylasAuthUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetNylasAuthUrlMutation, GetNylasAuthUrlMutationVariables>(GetNylasAuthUrlDocument, options);
      }
export type GetNylasAuthUrlMutationHookResult = ReturnType<typeof useGetNylasAuthUrlMutation>;
export type GetNylasAuthUrlMutationResult = Apollo.MutationResult<GetNylasAuthUrlMutation>;
export type GetNylasAuthUrlMutationOptions = Apollo.BaseMutationOptions<GetNylasAuthUrlMutation, GetNylasAuthUrlMutationVariables>;
export const HandleNylasAuthCallbackDocument = gql`
    mutation handleNylasAuthCallback($input: CreateNylasAuthorizeDtoInput!) {
  handleNylasAuthCallback(createNylasAuthorizeDtoInput: $input) {
    accountId
    provider
    syncState
    emailAddress
    accessToken
  }
}
    `;
export type HandleNylasAuthCallbackMutationFn = Apollo.MutationFunction<HandleNylasAuthCallbackMutation, HandleNylasAuthCallbackMutationVariables>;

/**
 * __useHandleNylasAuthCallbackMutation__
 *
 * To run a mutation, you first call `useHandleNylasAuthCallbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandleNylasAuthCallbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handleNylasAuthCallbackMutation, { data, loading, error }] = useHandleNylasAuthCallbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHandleNylasAuthCallbackMutation(baseOptions?: Apollo.MutationHookOptions<HandleNylasAuthCallbackMutation, HandleNylasAuthCallbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HandleNylasAuthCallbackMutation, HandleNylasAuthCallbackMutationVariables>(HandleNylasAuthCallbackDocument, options);
      }
export type HandleNylasAuthCallbackMutationHookResult = ReturnType<typeof useHandleNylasAuthCallbackMutation>;
export type HandleNylasAuthCallbackMutationResult = Apollo.MutationResult<HandleNylasAuthCallbackMutation>;
export type HandleNylasAuthCallbackMutationOptions = Apollo.BaseMutationOptions<HandleNylasAuthCallbackMutation, HandleNylasAuthCallbackMutationVariables>;
export const CreateFirmSettingDocument = gql`
    mutation createFirmSetting($name: String!, $value: String!) {
  createFirmSetting(name: $name, value: $value) {
    id
    name
    description
    type
    value {
      ... on BooleanValue {
        boolValue: val
      }
      ... on NumberValue {
        numValue: val
      }
      ... on DateValue {
        dateValue: val
      }
      ... on StringValue {
        strValue: val
      }
    }
  }
}
    `;
export type CreateFirmSettingMutationFn = Apollo.MutationFunction<CreateFirmSettingMutation, CreateFirmSettingMutationVariables>;

/**
 * __useCreateFirmSettingMutation__
 *
 * To run a mutation, you first call `useCreateFirmSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFirmSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFirmSettingMutation, { data, loading, error }] = useCreateFirmSettingMutation({
 *   variables: {
 *      name: // value for 'name'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useCreateFirmSettingMutation(baseOptions?: Apollo.MutationHookOptions<CreateFirmSettingMutation, CreateFirmSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFirmSettingMutation, CreateFirmSettingMutationVariables>(CreateFirmSettingDocument, options);
      }
export type CreateFirmSettingMutationHookResult = ReturnType<typeof useCreateFirmSettingMutation>;
export type CreateFirmSettingMutationResult = Apollo.MutationResult<CreateFirmSettingMutation>;
export type CreateFirmSettingMutationOptions = Apollo.BaseMutationOptions<CreateFirmSettingMutation, CreateFirmSettingMutationVariables>;
export const UpdateFirmSettingDocument = gql`
    mutation updateFirmSetting($id: String!, $value: String!) {
  updateFirmSetting(id: $id, value: $value) {
    id
    name
    description
    type
    value {
      ... on BooleanValue {
        boolValue: val
      }
      ... on NumberValue {
        numValue: val
      }
      ... on DateValue {
        dateValue: val
      }
      ... on StringValue {
        strValue: val
      }
    }
  }
}
    `;
export type UpdateFirmSettingMutationFn = Apollo.MutationFunction<UpdateFirmSettingMutation, UpdateFirmSettingMutationVariables>;

/**
 * __useUpdateFirmSettingMutation__
 *
 * To run a mutation, you first call `useUpdateFirmSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFirmSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFirmSettingMutation, { data, loading, error }] = useUpdateFirmSettingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateFirmSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFirmSettingMutation, UpdateFirmSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFirmSettingMutation, UpdateFirmSettingMutationVariables>(UpdateFirmSettingDocument, options);
      }
export type UpdateFirmSettingMutationHookResult = ReturnType<typeof useUpdateFirmSettingMutation>;
export type UpdateFirmSettingMutationResult = Apollo.MutationResult<UpdateFirmSettingMutation>;
export type UpdateFirmSettingMutationOptions = Apollo.BaseMutationOptions<UpdateFirmSettingMutation, UpdateFirmSettingMutationVariables>;
export const RecoverDeletedClientDocument = gql`
    mutation RecoverDeletedClient($userFirmAccountId: String!) {
  RecoverDeletedClient(userFirmAccountId: $userFirmAccountId)
}
    `;
export type RecoverDeletedClientMutationFn = Apollo.MutationFunction<RecoverDeletedClientMutation, RecoverDeletedClientMutationVariables>;

/**
 * __useRecoverDeletedClientMutation__
 *
 * To run a mutation, you first call `useRecoverDeletedClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverDeletedClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverDeletedClientMutation, { data, loading, error }] = useRecoverDeletedClientMutation({
 *   variables: {
 *      userFirmAccountId: // value for 'userFirmAccountId'
 *   },
 * });
 */
export function useRecoverDeletedClientMutation(baseOptions?: Apollo.MutationHookOptions<RecoverDeletedClientMutation, RecoverDeletedClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecoverDeletedClientMutation, RecoverDeletedClientMutationVariables>(RecoverDeletedClientDocument, options);
      }
export type RecoverDeletedClientMutationHookResult = ReturnType<typeof useRecoverDeletedClientMutation>;
export type RecoverDeletedClientMutationResult = Apollo.MutationResult<RecoverDeletedClientMutation>;
export type RecoverDeletedClientMutationOptions = Apollo.BaseMutationOptions<RecoverDeletedClientMutation, RecoverDeletedClientMutationVariables>;
export const namedOperations = {
  Query: {
    CanAddMemberToFirm: 'CanAddMemberToFirm'
  },
  Mutation: {
    UpdateUserProfilePhoto: 'UpdateUserProfilePhoto',
    CreateCustomDomain: 'CreateCustomDomain',
    RemoveCustomDomain: 'RemoveCustomDomain',
    CreateCustomLayout: 'CreateCustomLayout',
    UpdateCustomLayout: 'UpdateCustomLayout',
    updateUser: 'updateUser',
    UpdateUserInformation: 'UpdateUserInformation',
    UpdateFirmUniqueLink: 'UpdateFirmUniqueLink',
    UpdateUserUniqueLink: 'UpdateUserUniqueLink',
    UpdateRandomUserUniqueLink: 'UpdateRandomUserUniqueLink',
    CreateSubscription: 'CreateSubscription',
    CreateProgressStatuses: 'CreateProgressStatuses',
    UpdateProgressStatus: 'UpdateProgressStatus',
    SwitchOrderOfProgressStatuses: 'SwitchOrderOfProgressStatuses',
    RemoveProgressStatuses: 'RemoveProgressStatuses',
    CreateUserFirmAccount: 'CreateUserFirmAccount',
    AddMemberToFirm: 'AddMemberToFirm',
    UpdateMemberRole: 'UpdateMemberRole',
    DeleteTeamMember: 'DeleteTeamMember',
    ReactivateTeamMember: 'ReactivateTeamMember',
    ValidateOTP: 'ValidateOTP',
    DeleteTwoStepAuthFromUser: 'DeleteTwoStepAuthFromUser',
    UpdateFirmAccount: 'UpdateFirmAccount',
    CreateBusinessInformation: 'CreateBusinessInformation',
    UpdateBusinessInformation: 'UpdateBusinessInformation',
    RemoveBusinessInformation: 'RemoveBusinessInformation',
    UpdateFirmNotifications: 'UpdateFirmNotifications',
    CreateFirmNotificationReminder: 'CreateFirmNotificationReminder',
    UpdateFirmNotificationReminder: 'UpdateFirmNotificationReminder',
    RemoveFirmNotificationReminder: 'RemoveFirmNotificationReminder',
    UpdatePixels: 'UpdatePixels',
    CreateClient: 'CreateClient',
    SearchUnassignedClients: 'SearchUnassignedClients',
    SearchInProgressClients: 'SearchInProgressClients',
    SearchCompletedClients: 'SearchCompletedClients',
    CreateTaxYearInterview: 'CreateTaxYearInterview',
    UpdateTaxYearInterview: 'UpdateTaxYearInterview',
    DeleteTaxYearInterview: 'DeleteTaxYearInterview',
    AssignTaxYearInterview: 'AssignTaxYearInterview',
    UpdateSubscription: 'UpdateSubscription',
    ResumeSubscription: 'ResumeSubscription',
    SubscribeToPlan: 'SubscribeToPlan',
    DeleteSubscription: 'DeleteSubscription',
    UpdateJobNotes: 'UpdateJobNotes',
    GetSiteBrandingLogoPresignedURL: 'GetSiteBrandingLogoPresignedURL',
    GetUserProfilePhotoPresignedURL: 'GetUserProfilePhotoPresignedURL',
    CustomDomainResendEmails: 'CustomDomainResendEmails',
    GetOnBoardingLink: 'GetOnBoardingLink',
    deleteNylasAccount: 'deleteNylasAccount',
    getNylasAuthUrl: 'getNylasAuthUrl',
    handleNylasAuthCallback: 'handleNylasAuthCallback',
    createFirmSetting: 'createFirmSetting',
    updateFirmSetting: 'updateFirmSetting',
    RecoverDeletedClient: 'RecoverDeletedClient'
  }
}