import {
  useMemo, useRef, useState, useEffect,
} from 'react';
import {
  Input,
  Select,
  Switch,
  Button,
  Popconfirm,
  Icons,
  Row,
  Col,
  Card,
  Tooltip,
} from 'taxaroo-ui';
import useDebouncedEffect from '~src/components/hooks/useDebouncedEffect';
import InterviewOptionList from '~src/components/organisms/InterviewOptionList';
import { Option, Question } from '~src/components/organisms/InterviewSectionList';
import { QuestionType } from '~src/graphql';
import { options as optionFieldTypes } from './options';
import * as styles from './styles.module.css';

const { DeleteOutlined, InfoCircleOutlined } = Icons;
const { Option } = Select;

interface InterviewFieldProps {
  id?: string;
  temporalId?: string;
  required: boolean;
  type?: QuestionType;
  onDelete: () => void;
  label: string;
  disabled: boolean;
  Options: Array<Option>;
  onFieldChange: (question: Partial<Question>) => void;
  onOptionsChange: (newOptions: Array<Option>) => void;
}
function InterviewField({
  id,
  type,
  label,
  Options,
  required,
  onDelete,
  disabled,
  temporalId,
  onFieldChange,
  onOptionsChange,
}: InterviewFieldProps) {
  const firstRender = useRef<boolean>(true);
  const [labelValue, setLabelValue] = useState<string>(label);
  const hasFieldOptions = useMemo(() => (type === QuestionType.Dropdown
  || type === QuestionType.Radio), [type]);

  const handleSelect = (value: QuestionType) => {
    // field options depending of the field type
    const fieldOptions = (value === 'DROPDOWN' || value === 'RADIO') && !Options
      ? []
      : Options;

    onFieldChange({ type: value, Options: fieldOptions });
  };

  const handleSwitch = () => {
    onFieldChange({ required: !required });
  };

  const handleInput = (ev: { target: { value: any; }; }) => {
    const labelText = ev.target.value;

    if (id) {
      onFieldChange({ operation: 'update' });
    }
    setLabelValue(labelText);
  };

  const onOptionsChangeHandler = (newOptions: Array<Option>) => {
    onOptionsChange(newOptions);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setLabelValue(label);
    }
  }, [label]);

  useDebouncedEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      onFieldChange({ label: labelValue });
    }
  }, 500, [labelValue]);

  return (
    <Card
      type="inner"
      style={{ marginBottom: '25px' }}
      title={(
        <Input
          disabled={!!disabled}
          addonBefore={(
            <span>
              Question:
              {' '}
              <Tooltip title="A question you want your client to answer. i.e. What is your first name?">
                <InfoCircleOutlined className={styles.info} />
              </Tooltip>
            </span>
        )}
          value={labelValue}
          onChange={handleInput}
        />
)}
      extra={!disabled ? (
        <div style={{ marginLeft: '10px' }}>
          <Tooltip title={required ? 'Mark Optional' : 'Mark Required'}>
            <Switch checked={required} onClick={handleSwitch} style={{ marginRight: '10px' }} />
          </Tooltip>
          <Popconfirm
            onConfirm={onDelete}
            onCancel={(ev: any) => ev.preventDefault}
            title="Are you sure you want to delete this?"
            okText="Yes"
            cancelText="No"
            okButtonProps={{ danger: true }}
            cancelButtonProps={{ type: 'default' }}
            placement="topRight"
          >
            <Tooltip title="Delete">
              <Button
                type="primary"
                danger
                className={styles.popconfirmDangerButton}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        </div>
      ) : (null)}
    >
      <Row gutter={[5, 0]}>
        <Col />
      </Row>
      <Row style={{ marginTop: '5px', alignItems: 'center' }} gutter={[35, 0]}>
        <Col>
          <div>
            Answer Field Type:
            {' '}
            <Tooltip title="Select the type of field that you would like to use for the answer of this question.">
              <InfoCircleOutlined className={styles.info} />
            </Tooltip>
          </div>
          <Select
            disabled={!!disabled}
            defaultValue={type}
            className={styles.select}
            onChange={handleSelect}
          >
            {optionFieldTypes.map((opt) => (
              <Option key={opt.value} value={opt.value}>
                {opt.name === 'float' ? 'number' : opt.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row style={{ marginTop: '15px' }}>
        {hasFieldOptions && (
        <InterviewOptionList
          questionId={id}
          options={Options}
          temporalQuestionId={temporalId}
          onOptionsChange={onOptionsChangeHandler}
        />
        )}
      </Row>
    </Card>
  );
}

InterviewField.defaultProps = {
  type: undefined,
  id: null,
  temporalId: null,
};

export default InterviewField;
