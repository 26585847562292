import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCreateChannelMutation } from '~src/graphql/mutations/messaging';
import { useAppDispatch, useAppSelector } from '~src/redux/hooks';
import { selectSession } from '~src/redux/slices/sessionSlice';
import { selectChannels } from '~src/redux/slices/chatSlice';
import UseChannels from '~src/components/hooks/useChannels';
import * as styles from './style.module.css';
import NewConversationModal from '../../molecules/NewConversationModal';
import ChatChannelsList from '../ChatChannelsList';
import ChatSelectorHeader from '../../molecules/ChatSelectorHeader';

interface ChatListProps {
  showModal: () => void;
  hideModal: () => void;
  modalVisible: boolean;
}

const ChatsList: FC<ChatListProps> = ({ showModal, hideModal, modalVisible }) => {
  const [channelSearch, setChannelSearch] = useState('');
  const [createChannel, { loading: createChannelLoading }] = useCreateChannelMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { firmAccountId, userId } = useAppSelector(selectSession);
  const currentChannels = useAppSelector(selectChannels);
  const { addChannel } = UseChannels(false);
  const navigate = useNavigate();
  const userToChatWith = searchParams.get('userToChatWith');
  const description = searchParams.get('description');

  const createChannelFromSearchParams = async (selectedUserId: string, channelDescription: string) => {
    const newChannel = (await createChannel({
      variables: {
        createChannelInput: {
          firmAccountId,
          currentUserId: userId,
          userIdToConnect: selectedUserId,
          description: channelDescription,
        },
      },
    })).data.createChannel;

    if (newChannel.id) {
      addChannel(newChannel, true);
    }
  };

  useEffect(() => {
    if (userToChatWith && description) {
      createChannelFromSearchParams(userToChatWith, description);
      navigate('/chat');
    }
  }, []);

  return (
    <div className={styles.chatListContainer}>
      {modalVisible && (
      <NewConversationModal
        visible={modalVisible}
        handleCancel={hideModal}
      />
      )}
      <ChatSelectorHeader
        showModal={showModal}
        channelSearch={channelSearch}
        setChannelSearch={setChannelSearch}
      />
      <ChatChannelsList />
    </div>
  );
};

export default ChatsList;
