import * as Yup from 'yup';

const isCompany = (x) => x === 'COMPANY';

const requiredStringForCompany = (requiredMessage) => Yup.string().when(['businessType'], {
  is: isCompany,
  then: Yup.string().required(requiredMessage),
});

const requiredNumberForCompany = (requiredMessage) => Yup.number().when(['businessType'], {
  is: isCompany,
  then: Yup.number().nullable().required(requiredMessage),
});

export const PREPARER_VALIDATION = Yup.object().shape({
  firstName: Yup.string().required('The first name is required'),
  lastName: Yup.string().required('The last name is required'),
  dateOfBirth: Yup.date().required('The date of birth is required'),
  phoneNumber: Yup.string().required('The phone number is required'),
  addressLine1: Yup.string().required('The address is required'),
  addressLine2: Yup.string(),
  city: Yup.string().required('The city is required'),
  state: Yup.string().required('The state is required'),
  zip: Yup.string().required('The ZIP is required'),

  businessType: Yup.string().required('The business type is required'),

  businessName: Yup.string(),
  businessPhone: requiredStringForCompany('The business phone number is required'),
  businessAddressLine1: requiredStringForCompany('The business address is required'),
  businessAddressLine2: Yup.string(),
  businessCity: requiredStringForCompany('The business city is required'),
  businessState: requiredStringForCompany('The business state is required'),
  businessZip: requiredStringForCompany('The business ZIP is required'),
  taxReturns: Yup.number(),
  taxPracticeYears: Yup.number(),
  tax: requiredNumberForCompany('The tax is required'),
  accounting: requiredNumberForCompany('The accounting is required'),
  bookKeeping: requiredNumberForCompany('The bookkeeping is required'),
  payroll: requiredNumberForCompany('The payroll is required'),
  taxResolution: requiredNumberForCompany('The tax resolution is required'),
  other: requiredNumberForCompany('The other is required'),
});

export const INDIVIDUAL_PREPARER_VALIDATION = Yup.object().shape({
  dateOfBirth: Yup.date(),
  addressLine2: Yup.string(),
  zip: Yup.string().required('The ZIP is required'),
  city: Yup.string().required('The city is required'),
  state: Yup.string().required('The state is required'),
  lastName: Yup.string().required('Last name is required'),
  firstName: Yup.string().required('First name is required'),
  addressLine1: Yup.string().required('The address is required'),
  businessType: Yup.string().required('Business type is required'),
  phoneNumber: Yup.string().required('The phone number is required'),
});

export const STAFF_VALIDATION = Yup.object().shape({
  dateOfBirth: Yup.date(),
  lastName: Yup.string().required('Last name is required'),
  firstName: Yup.string().required('First name is required'),
  phoneNumber: Yup.string().required('The phone number is required'),
});
