import { FC, useEffect, useState } from 'react';
import {
  Typography,
  Row,
  Col,
  Space,
  Popover,
  Input,
  Switch,
  TaxarooButton,
  message,
  Layout,
  Icons,
  Spin,
} from 'taxaroo-ui';
import { GetOtpBooleanDocument, useGenerateOtpQuery, useGetOtpBooleanQuery } from '~src/graphql/queries/settings';
import { useDeleteTwoStepAuthFromUserMutation, useValidateOtpMutation } from '~src/graphql/mutations/settings';

const { InfoCircleTwoTone, SaveOutlined } = Icons;
const { Title, Paragraph, Text } = Typography;

// interface SecurityProps {}

const Security: FC = () => {
  const [code, setCode] = useState('');
  const [switch2FAValue, setSwitch2FAValue] = useState<boolean>();
  const [twoStepAuthChanged, setTwoStepAuthChanged] = useState<boolean>(false);
  const [twoStepValueToChange, setTwoStepValueToChange] = useState<boolean>();
  const { data, loading } = useGenerateOtpQuery();

  // Get OTP existance
  const {
    data: OTPBooleanData,
    loading: loadingGetOTPBooleanData,
  } = useGetOtpBooleanQuery();

  const copyToClipboard = () => navigator.clipboard.writeText(data.generateOTP.key);

  const [validateOtpMutation, { loading: loadingValidateOTP }] = useValidateOtpMutation({
    onCompleted: () => {
      message.success('Enabled two step auth');
      setTwoStepAuthChanged(false);
      setTwoStepValueToChange(undefined);
    },
    onError: () => message.error('Please provide a valid code'),
    variables: {
      validateOtp: {
        code,
        secretOTP: data?.generateOTP?.key,
      },
    },
    refetchQueries: [
      {
        query: GetOtpBooleanDocument,
      },
    ],
    awaitRefetchQueries: true,
  });

  const [
    deleteTwoStepFromUser,
    { loading: loadingDeleteTwoStepFromUser },
  ] = useDeleteTwoStepAuthFromUserMutation({
    onCompleted: () => {
      message.success('Disabled two step auth');
      setTwoStepAuthChanged(false);
      setTwoStepValueToChange(undefined);
    },
    onError: () => message.error('Please provide a valid code'),
    variables: {
      validateOtp: {
        code,
      },
    },
    refetchQueries: [
      {
        query: GetOtpBooleanDocument,
      },
    ],
    awaitRefetchQueries: true,
  });

  // Set initial switch value and listen for mutations to update the original value
  useEffect(() => {
    if (OTPBooleanData) {
      setSwitch2FAValue(OTPBooleanData?.GetOTPBoolean.success);
    }
  }, [OTPBooleanData]);

  const getMoreInfoContent = () => (
    <Row>
      <Typography>
        <Paragraph>
          Two-Factor Authentication (2FA) works by
          adding an additional layer of security to your Taxaroo account.
        </Paragraph>
      </Typography>
    </Row>
  );

  const twoStepAuthChangedSwitchOnChange = (enabled: boolean) => {
    setSwitch2FAValue(enabled);
    setCode('');

    if (OTPBooleanData?.GetOTPBoolean.success !== enabled) {
      setTwoStepAuthChanged(true);
      setTwoStepValueToChange(!OTPBooleanData?.GetOTPBoolean.success);
    }
    if (OTPBooleanData?.GetOTPBoolean.success === enabled) {
      setTwoStepAuthChanged(false);
      setTwoStepValueToChange(undefined);
    }
  };

  const handleOTPOnSaveChanges = () => {
    if (twoStepValueToChange === true || twoStepValueToChange === false) {
      if (twoStepValueToChange === true) {
        validateOtpMutation();
      }
      if (twoStepValueToChange === false) {
        deleteTwoStepFromUser();
      }
    } else {
      message.info('No changes to save');
    }
  };

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <Row style={{ flexFlow: 'row', width: '100%' }}>
        <Col span={24} style={{ borderRight: '1px solid #EFEFEF' }}>
          <Space direction="vertical" style={{ width: '98%' }}>
            <Row gutter={16} style={{ borderBottom: '1px dashed #D6D6D6', marginRight: 15 }}>
              <Col>
                <Title level={3}>Two-Factor authentication</Title>
              </Col>
              <Col>
                <Popover
                  overlayStyle={{ width: '50%' }}
                  placement="right"
                  content={getMoreInfoContent}
                  title="How does this work?"
                  trigger="click"
                >
                  <InfoCircleTwoTone />
                </Popover>
              </Col>
            </Row>
            <Row gutter={2} style={{ marginTop: 18 }}>
              <Col span={8}>
                <Typography>
                  <Title level={5}>
                    <Text>Step 1</Text>
                  </Title>
                  <Paragraph>
                    <Text>
                      <b>Enable two-step authentication to add an additional layer of security.</b>
                    </Text>
                  </Paragraph>
                </Typography>
              </Col>
              <Col>
                <Switch
                  onChange={twoStepAuthChangedSwitchOnChange}
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  checked={switch2FAValue}
                  disabled={
                    loadingGetOTPBooleanData
                  }
                />
              </Col>
            </Row>
            {twoStepAuthChanged && twoStepValueToChange === true && (
              <>
                <Row gutter={2} style={{ marginTop: 18 }}>
                  <Col span={8}>
                    <Typography>
                      <Title level={5}>
                        <Text>Step 2</Text>
                      </Title>
                      <Paragraph>
                        <Text>
                          <b>Scan this barcode with your app.</b>
                        </Text>
                      </Paragraph>
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    {loading ? (
                      <Spin size="small" />
                    ) : (
                      <img
                        src={
                          `data:image/svg+xml;utf8,${data.generateOTP.codeQR}`
                        }
                        alt="qrCode"
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={10}>
                    <Typography>
                      <Paragraph>
                        <Text>
                          {"If you can't use barcode "}
                          <TaxarooButton
                            type="ghost"
                            onClick={() => {
                              copyToClipboard();
                              message.success('Copied to clipboard');
                            }}
                          >
                            Get a key
                          </TaxarooButton>
                          &nbsp; for manual entry.
                        </Text>
                      </Paragraph>
                    </Typography>
                  </Col>
                </Row>
                <Row gutter={2} style={{ marginTop: 18 }}>
                  <Col span={8}>
                    <Typography>
                      <Title level={5}>
                        <Text>Step 3</Text>
                      </Title>
                      <Paragraph>
                        <Text>
                          <b>Enter the six-digit code from your app.</b>
                        </Text>
                      </Paragraph>
                    </Typography>
                  </Col>
                  <Col>
                    <Input
                      size="large"
                      placeholder="Six Digit Code"
                      value={code}
                      onPressEnter={handleOTPOnSaveChanges}
                      maxLength={6}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setCode(e.target.value); }}
                      allowClear
                    />
                  </Col>
                </Row>
              </>
            )}
            {twoStepAuthChanged && twoStepValueToChange === false && (
            <Row gutter={2} style={{ marginTop: 18 }}>
              <Col span={8}>
                <Typography>
                  <Title level={5}>
                    <Text>Step 2</Text>
                  </Title>
                  <Paragraph>
                    <Text>
                      <b>
                        To disable the two-step authentication.
                        Please enter the six-digit code from your app
                      </b>
                    </Text>
                  </Paragraph>
                </Typography>
              </Col>
              <Col>
                <Input
                  size="large"
                  placeholder="Six Digit Code"
                  value={code}
                  maxLength={6}
                  onPressEnter={handleOTPOnSaveChanges}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setCode(e.target.value); }}
                  allowClear
                />
              </Col>
            </Row>
            )}
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                {loadingValidateOTP || loadingDeleteTwoStepFromUser ? (
                  <Spin size="small" />
                ) : (
                  <TaxarooButton
                    type="primary"
                    icon={<SaveOutlined />}
                    disabled={!twoStepAuthChanged}
                    onClick={() => {
                      handleOTPOnSaveChanges();
                    }}
                  >
                    Save changes
                  </TaxarooButton>
                )}
              </Col>
            </Row>
          </Space>
        </Col>
      </Row>
      {OTPBooleanData?.GetOTPBoolean.success && Boolean(OTPBooleanData?.GetOTPBoolean.apiToken) && (
        <div style={{ paddingTop: '30px' }}>
          <Row>
            <Title level={5}>API token for Zapier intergrations</Title>
          </Row>
          <Row>
            <Paragraph style={{ width: '50%' }} ellipsis copyable code>
              {OTPBooleanData?.GetOTPBoolean.apiToken}
            </Paragraph>
          </Row>
        </div>
      )}
    </Layout>
  );
};

export default Security;
