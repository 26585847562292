import { FC } from 'react';
import {
  Modal,
} from 'taxaroo-ui';
import RegisterSteps from './RegisterSteps';

interface RegisterFlowProps {
  isModal?: boolean;
  showRegisterFlow?: boolean;
  setShowRegisterFlow?: (value: boolean) => void;
  markBasicInfoStepComplete?: () => void;
}

const RegisterFlow: FC<RegisterFlowProps> = ({
  isModal,
  showRegisterFlow,
  setShowRegisterFlow,
  markBasicInfoStepComplete,
}) => (isModal
  ? (
    <Modal
      visible={showRegisterFlow}
      width="70%"
      footer={null}
      onCancel={() => setShowRegisterFlow(false)}
      centered
      style={{ top: '10px' }}
    >
      <RegisterSteps
        setModalVisible={setShowRegisterFlow}
        markBasicInfoStepComplete={markBasicInfoStepComplete}
      />
    </Modal>
  )
  : (
    <RegisterSteps markBasicInfoStepComplete={markBasicInfoStepComplete} />
  ));

RegisterFlow.defaultProps = {
  isModal: true,
  showRegisterFlow: false,
  setShowRegisterFlow: null,
  markBasicInfoStepComplete: null,
};

export default RegisterFlow;
