/* eslint-disable jsx-a11y/aria-role */
import { CSSProperties, FC, ReactNode } from 'react';
import { useDrop } from 'react-dnd';
import './index.css';

const style: CSSProperties = {
  textAlign: 'center',
  lineHeight: 'normal',
  float: 'left',
};

export interface DustbinProps {
    node: ReactNode
    name: string
    id: string
}

const DroppableTabPane: FC<DustbinProps> = ({ node, name, id }) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'box',
    drop: () => ({ name, id }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const animationName = canDrop ? 'beat' : '';
  const isOverStyle = isOver ? { zoom: 1.1 } : {};
  const animationStyleApply = canDrop ? { animation: 'beat .75s alternate' } : {};
  // const animationStyleApply = canDrop ? { textShadow: '2px' } : {};
  // console.log("Actual canDrop: ", canDrop, "isOver: ", isOver);
  return (
    <div
      style={{
        ...style, animationName, ...animationStyleApply, ...isOverStyle,
      }}
      ref={drop}
      role="Dustbin"
    >
      {node}
    </div>
  );
};

export default DroppableTabPane;
