.interviewsContainer {
  flex: 1;
  display: flex;
  height: 100%;
}

.interviewsMenu {
  height: 100% !important;
}

.interviewsContainer li {
  padding-left: 12px !important;
}

.interviewsLoadingContainer {
  flex: 1;
  display: flex;
  margin-bottom: 24px;
  justify-content: center;
}

.pageHeader {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.interviewsContentContainer {
  flex: 1;
}

.newInterviewButton {
  width: calc(100% - 8px);
}

.interviewContainer {
  padding-left: 24px;
}

.interviewNameInput {
  border: none;
  height: 30px;
  border-radius: 6px;
  width: 100% !important;
  background-color: #f4f4f4;
}

.interviewLanguageContainer {
  display: flex;
  margin-top: 24px;
  flex-direction: column;
}

.interviewButtonsContainer {
  display: flex;
  padding: 24px 0;
  align-items: center;
  justify-content: space-between;
}

.copyDeleteButtons :nth-child(1) {
  margin-right: 12px;
}

.interviewStatusContainer {
  display: flex;
  justify-content: center;
}

.interviewStatusAlert {
  flex-basis: 75%;
  margin: 24px 0 !important;
}

.sections {
  height: 100%;
  display: flex;
  margin-bottom: 12px;
  flex-direction: column;
  margin-top: 24px;
}

.mainSectionsOperationsContainer {
  display: flex;
  justify-content: space-between;
}

.addSpecialSectionButton {
  align-self: flex-end;
}

.skeletonContainer {
  padding: 24px;
}

.sectionsButtonContainer {
  display: flex;
  flex-direction: column;
}

.sections :nth-child(2) {
  width: fit-content;
}

.noInterviewsInfoContainer {
  display: flex;
  justify-content: center;
}

.noInterviewsInfo {
  flex-basis: 50% !important;
}
