const MAX_MOBILE_WIDTH = 700;

export const getScreenWidth = (): number => window.screen.width;

export const getScreenHeight = (): number => window.screen.height;

export const isMobileView = (): boolean => getScreenWidth() <= MAX_MOBILE_WIDTH;

export const getClientWidth = (): number => document.body.clientWidth;

export const getClientHeight = (): number => document.body.clientHeight;
