import { useState, FC, useEffect } from 'react';
import {
  Input, Row, Typography, Button,
} from 'taxaroo-ui';
import { useAiMessageSuggestionLazyQuery } from '~src/graphql/queries/messaging';
import { ChannelsState } from '~src/redux/slices/chatSlice';
import { useAppSelector } from '~src/redux/hooks';
import { selectSession } from '~src/redux/slices/sessionSlice';
import { Features, liteFeatures, useUpgradeModal } from '~src/components/atoms/UpgradeModal/ModalProvider';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import CopyButtonWithTooltip from '../CopyButtonWithTooltip';
import * as styles from './style.module.css';

const { Title, Text } = Typography;
const { TextArea } = Input;

const LoadingMessages = () => {
  const [text, setText] = useState('Roo is thinking…');

  useEffect(() => {
    const timeout1 = setTimeout(() => {
      setText('Roo is writing…');
    }, 2000);
    const timeout2 = setTimeout(() => {
      setText('Roo is sending…');
    }, 3500);
    const timeout3 = setTimeout(() => {
      setText('Here comes your response…');
    }, 5000);
    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      clearTimeout(timeout3);
    };
  }, []);

  return (
    <Text>
      {text}
    </Text>
  );
};

interface AIMessagingSuggestionProps {
  channelId: string
  channelInfo: ChannelsState
}

const AIMessagingSuggestion: FC<AIMessagingSuggestionProps> = ({
  channelId,
  channelInfo,
}) => {
  const { userId: currentUserId } = useAppSelector(selectSession);
  const [value, setValue] = useState('');

  const { showModal, accessObject } = useUpgradeModal();
  const needsToUpgrade = !accessObject?.[Features.aiMessaging];
  // graphql
  const [getAiMessageSuggestion, { loading: aiSuggestionLoading }] = useAiMessageSuggestionLazyQuery();

  useEffect(() => {
    // if channelInfo updated and the last message sent was not me, make a new request
    if (channelInfo?.Messages?.length) {
      if (channelInfo.Messages[0]?.userId !== currentUserId) {
        getAiMessageSuggestion({
          variables: {
            channelId,
          },
          fetchPolicy: 'network-only',
        })
          .then(({ data }) => {
            const suggestion = data?.AIMessageSuggestion?.choices?.[0]?.message?.content || 'Waiting for next client message.';
            setValue(suggestion);
          })
          .catch(() => {
            setValue('Waiting for next client message.');
          });
      } else {
        setValue('Waiting for next client message.');
      }
    }
  }, [channelInfo, currentUserId, channelId]);

  if (needsToUpgrade) {
    const modalType = liteFeatures.includes(Features.aiMessaging) ? 'Lite' : 'Pro';
    return (
      <div className={styles.container}>
        <Title level={4} style={{ textAlign: 'center' }}>
          Roo™ AI Tax Advisor
        </Title>
        <Row justify="center" style={{ marginTop: 30 }}>
          <Text style={{ textAlign: 'center' }}>
            {`Access to Roo requires a ${modalType} Plan.`}
          </Text>
          <Button
            type="primary"
            onClick={() => showModal(Features.aiMessaging)}
            style={{ marginTop: 14 }}
          >
            Access Roo™
          </Button>
        </Row>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Title level={4} style={{ textAlign: 'center' }}>
        Roo™ AI Tax Advisor
      </Title>
      <Text>
        Suggested Response:
      </Text>
      {aiSuggestionLoading
        ? (
          <Row justify="center" style={{ marginTop: 16 }}>
            <LoadingMessages />
          </Row>
        )
        : (
          <>
            <TextArea
              autoSize={{ minRows: 5, maxRows: 8 }}
              value={value}
              style={{ resize: 'none' }}
              onChange={(e) => setValue(e.target.value)}
              onFocus={(e) => e.target.select()}
              className="showScrollbar"
            />
            <div
              className={styles.copyButton}
              onClick={() => {
                trackCustomerIoEvent(CUSTOMER_IO_EVENTS.CLICK, {
                  Title: 'AI tax advisor copy response',
                  Source: 'Button click',
                  url: window.location.href,
                });
              }}
              aria-hidden="true"
            >
              <CopyButtonWithTooltip copyText={value} />
            </div>
          </>
        )}
    </div>
  );
};

export default AIMessagingSuggestion;
