.messagesListContainer {
  position: relative;
  flex: 1;
  width: 100%;
  display: flex;
  padding-left: 3%;
  padding-right: 3%;
  margin-bottom: 0 !important;
  max-height: 75%;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: 100px !important;
}

.messagesListContainer div:first-child {
  flex: 1 !important;
}

.container::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

@media only screen and (max-device-width: 700px) {
  .messagesListContainer {
    max-height: unset;
  }
}

.loadMoreMessagesBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 106%;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  color: #0076e2;
  margin-left: -3%;
  border-radius: 0 !important;
}
