p {
  user-select: none;
}

.planAmount {
  color: #0d76d1;
}

.paymentContainer {
  display: flex;
  flex-direction: column;
}
