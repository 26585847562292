.headerShrink .logo {
  height: 28px !important;
  margin: 10px 13px !important;
}

.header {
  transition: 0.3s;
  display: flex;
  justify-content: space-between;
}

.headerShrink {
  height: 45px !important;
  line-height: 44px !important;
}

#topHeader .logo {
  float: left;
  width: 150px;
  height: 35px;
  margin: 15px 13px;

  /* background: url("../../../assets/images/taxaroo_logo.svg"); */
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.3s;
}

.hamburgerWrapper {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

/* remove animations on avatar */
.ant-menu-horizontal:not(.ant-menu-dark) > .noAnimation::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .noAnimation:hover::after {
  /* border-bottom: 2px solid var(--primary-color); */
  border: none;
  animation: 0s !important;
}

/* dropdown under invite submenu */
.inviteLinkItem {
  position: relative;
  height: 100% !important;
  padding: 0 !important;
  margin: 4px 16px 15px !important;
}

/* fix dropdown width */
.inviteLinkItem .ant-list-item-meta-content {
  width: 100%;
}

/* color of "Invite" text & underline */

/* .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active {
  color: black;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after {
  border-color: black;
} */

/* .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open {
  color: var(--primary-dark) !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after {
  border-color: var(--primary-dark) !important;
} */

/* copy icons under invite submenu */
.inviteLinkItem .ant-list-item-action span {
  font-size: 20px;
}

/* .invite-link-item .ant-list-item-action {
  display: flex;
  justify-content: center;
  align-content: center;
} */

/* link item descritpion (invite url to copy ) */
.ant-menu-item-disabled a {
  color: black !important;
  pointer-events: all;
}

.ant-menu-item a:hover {
  color: black;
  text-decoration: underline;
}

/* .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open {
  color: var(--primary-dark) !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after {
  border-color: var(--primary-dark) !important;
} */
