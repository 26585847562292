import { FC } from 'react';
import { LegacyTaxYearInterviewAnswersEntity } from '~src/graphql';
import { Table, Typography } from 'taxaroo-ui';

const { Text, Paragraph } = Typography;

interface LegacyInterviewBackupProps {
  answers: Array<LegacyTaxYearInterviewAnswersEntity>;
}

type AnswerBackup = {
  answer: string;
  key: string;
  question: string | undefined;
  sections: Array<string> | undefined;
};

const LegacyInterviewBackup: FC<LegacyInterviewBackupProps> = ({ answers }) => {
  const data = answers.map((a) => a.interviewForm).flat();
  const columns = [
    {
      title: 'Section',
      dataIndex: 'sections',
      width: '25%',
      sorter: (a: AnswerBackup, b: AnswerBackup) => {
        const section1 = a.sections?.join(' -> ') || '';
        const section2 = b.sections?.join(' -> ') || '';
        if (section1 < section2) return -1;
        if (section1 > section2) return 1;
        return 0;
      },
      render: (item: Array<string> | undefined) => item?.join(' -> ') || '',
    },
    {
      title: 'Question',
      dataIndex: 'question',
      width: '25%',
      sorter: (a: AnswerBackup, b: AnswerBackup) => {
        if (a.question < b.question) return -1;
        if (a.question > b.question) return 1;
        return 0;
      },
    },
    {
      title: 'Field Name',
      dataIndex: 'key',
      width: '25%',
      sorter: (a: AnswerBackup, b: AnswerBackup) => {
        if (a.key < b.key) return -1;
        if (a.key > b.key) return 1;
        return 0;
      },
    },
    {
      title: 'Answer',
      dataIndex: 'answer',
      width: '25%',
      sorter: (a: AnswerBackup, b: AnswerBackup) => {
        if (a.answer < b.answer) return -1;
        if (a.answer > b.answer) return 1;
        return 0;
      },
    },
  ];

  return (
    <>
      <Paragraph>
        <Text>
          Not all interview data could be transferred into the main interviews fields above.
          Please see below for all of the data originally collected from this job.
        </Text>
      </Paragraph>

      <Table
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 100 }}
        bordered
      />
    </>
  );
};

export default LegacyInterviewBackup;
