import React, { SVGProps } from 'react';

const Circle: React.FC<SVGProps<SVGSVGElement> & { title?: string }> = (props) => {
  const { title } = props;
  return (
    <svg
      width="1em"
      height="1em"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      shapeRendering="geometricPrecision"
      viewBox="0 0 25 25"
      focusable="false"
      {...props}
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="3"
        fill="transparent"
      >
        <title>{title}</title>
      </circle>
    </svg>
  );
};

export default Circle;
