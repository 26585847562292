import {
  Row,
  Col,
  Typography,
  message,
  Spin,
  Popover,
  Icons,
} from 'taxaroo-ui';
import * as Highcharts from 'highcharts';
import { useEffect, useState } from 'react';
import { PaymentsEntity, TaxYearInterviewEntity } from '~src/graphql';
import { Moment } from 'moment-timezone';
import { useGetJobsAndPaymentsByTaxYearStatusLazyQuery } from '~src/graphql/queries/analytics';
import Chart from '../Chart';

const { Title, Text, Paragraph } = Typography;
const {
  InfoCircleTwoTone,
} = Icons;

interface LineChartSectionProps {
  title: string;
  dollarSubtitle: string;
  numberSubtitle: string;
  progressStatusIds: string[];
  startDate: Moment;
  endDate: Moment;
  dateFilterKey: string;
  tooltip?: string;
}
function LineChartSection({
  title,
  dollarSubtitle,
  numberSubtitle,
  progressStatusIds,
  startDate,
  endDate,
  dateFilterKey,
  tooltip,
}: LineChartSectionProps) {
  const [allJobs, setAllJobs] = useState(null);
  const [loadingAllJobs, setLoadingAllJobs] = useState(false);
  const [totalPayments, setTotalPayments] = useState(null);
  // graphql queries
  const [getJobsAndPayments] = useGetJobsAndPaymentsByTaxYearStatusLazyQuery({
    onError: (error) => {
      message.error(error.message);
    },
  });

  const fetchJobsAndPayments = async () => {
    setLoadingAllJobs(true);
    const { data } = await getJobsAndPayments({
      variables: {
        progressStatusIds,
        startDate,
        endDate,
        dateFilterKey,
      },
    });
    let totalPaymentsAmount = 0;
    data?.JobsAndPaymentsByTaxYearStatus.forEach((taxYearInterview: TaxYearInterviewEntity) => {
      taxYearInterview?.Payments.forEach((payment: PaymentsEntity) => {
        totalPaymentsAmount += Number(payment?.totalDollarAmount) || 0;
      });
    });
    setAllJobs(data?.JobsAndPaymentsByTaxYearStatus);
    setTotalPayments(totalPaymentsAmount);
    setLoadingAllJobs(false);
  };

  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    }
    fetchJobsAndPayments();
  }, [getJobsAndPayments, progressStatusIds, startDate, endDate]);

  const options: Highcharts.Options = {
    title: {
      text: 'Total Work in Progress Over Time',
    },
    yAxis: [{ // Primary yAxis
      title: {
        text: 'Job Count',
      },
      allowDecimals: false,
    }, { // Secondary yAxis
      title: {
        text: 'Dollars (s)',
      },
      // labels: {
      //   format: '${value}',
      // },
      opposite: true,
    }],
    xAxis: {
      title: {
        text: 'Time',
      },
    },
    legend: {
      verticalAlign: 'top',
    },
    series: [{
      type: 'line',
      name: 'Job Count In Progress',
      data: [1, 2, 3],
      yAxis: 0,
    }, {
      type: 'line',
      name: 'Dollars In Progress',
      data: [100, 200, 300],
      yAxis: 1,
    }],
  };
  return (
    <>
      <Row>
        <Title level={4}>
          {title}
          {tooltip && (
            <>
              &nbsp;
              <Popover
                placement="top"
                content={tooltip}
                trigger="hover"
              >
                <InfoCircleTwoTone style={{ fontSize: 14 }} />
              </Popover>
            </>
          )}
        </Title>
        {loadingAllJobs && (
          <Spin
            size="small"
            style={{ marginLeft: 10, marginTop: 3 }}
          />
        )}
      </Row>
      <Row style={{ marginTop: 40, marginBottom: 40 }}>
        <Col>
          <Text style={{ color: 'green', fontSize: '32px' }}>
            {Number(totalPayments).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          </Text>
          <Paragraph>{dollarSubtitle}</Paragraph>
          <div style={{ height: '15px' }} />
          <Text style={{ color: 'blue', fontSize: '32px' }}>{allJobs?.length}</Text>
          <Paragraph>{numberSubtitle}</Paragraph>
        </Col>
        <Col>
          {/* <Chart options={options} /> */}
        </Col>
      </Row>
    </>
  );
}

// default props
LineChartSection.defaultProps = {
  tooltip: '',
};

export default LineChartSection;
