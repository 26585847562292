import {
  Button, Icons, Tooltip, Alert,
} from 'taxaroo-ui';
import { v4 as uuid } from 'uuid';
import React, {
  FC,
} from 'react';
import { Option } from '~src/components/organisms/InterviewSectionList';
import style from './style.module.css';
import InterviewOption from '../../molecules/InterviewOption';

const { InfoCircleOutlined, PlusCircleFilled } = Icons;

interface InterviewOptionListProps {
  options: Array<Option>;
  questionId?: string;
  temporalQuestionId?: string;
  onOptionsChange: (newOptions: Array<Option>) => void;
}

const InterviewOptionList: FC<InterviewOptionListProps> = ({
  options,
  questionId,
  temporalQuestionId,
  onOptionsChange,
}) => {
  const handleAddOptionClick = () => {
    onOptionsChange([...options, {
      label: '',
      questionId,
      temporalId: uuid(),
      temporalQuestionId,
      operation: 'create',
    }]);
  };

  const handleDeleteOptionClick = (optionIndex: number) => {
    const currentOptions = [...options];

    if (currentOptions[optionIndex] && currentOptions[optionIndex].id) {
      currentOptions[optionIndex].operation = 'delete';
    } else {
      currentOptions.splice(optionIndex, 1);
    }

    onOptionsChange(currentOptions);
  };

  const handleOptionChange = (value: string, optionIndex: number) => {
    const currentOptions = [...options];

    currentOptions[optionIndex].label = value;

    if (questionId && questionId.length && currentOptions[optionIndex].id
      && !currentOptions[optionIndex].operation) {
      currentOptions[optionIndex].operation = 'update';
    }

    onOptionsChange(currentOptions);
  };

  return (
    <div className={style.optionListContainer}>
      <div className={style.optionListHeader}>
        <div>
          Options:
          {' '}
          <Tooltip title="The possible choices for your client to pick from. i.e. Selecting a dropdown/radio question type will require multiple options to choose from.">
            <InfoCircleOutlined className={style.info} />
          </Tooltip>
        </div>
        <Button onClick={handleAddOptionClick} icon={<PlusCircleFilled />} type="default">Add Option</Button>
      </div>
      {
        options.length ? options.map(({
          id,
          label,
          temporalId,
        }, index) => {
          const onOptionChangeHandler = (value: string) => {
            handleOptionChange(value, index);
          };

          const onDeleteOptionHandler = () => {
            handleDeleteOptionClick(index);
          };

          return (
            <InterviewOption
              id={id}
              label={label}
              temporalId={temporalId}
              onOptionChange={onOptionChangeHandler}
              onDeleteOptionClick={onDeleteOptionHandler}
            />
          );
        }) : (
          <div className={style.noOptionsAlertContainer}>
            <Alert
              showIcon
              type="warning"
              message="Hey!"
              description="A 'radio' or 'drop-down' question must contain at least one option for the taxpayer to choose from."
            />
          </div>
        )
      }
    </div>
  );
};

InterviewOptionList.defaultProps = {
  questionId: null,
  temporalQuestionId: null,
};

export default React.memo(InterviewOptionList);
