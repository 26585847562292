import React, {
  ChangeEvent, FC, useEffect, useRef, useState,
} from 'react';
import {
  Input, Tooltip, Button, Popconfirm, Icons,
} from 'taxaroo-ui';
import useDebouncedEffect from '~src/components/hooks/useDebouncedEffect';
import * as style from './style.module.css';

const { DeleteOutlined } = Icons;

interface InterviewOptionProps {
  id?: string;
  label: string;
  temporalId?: string;
  onDeleteOptionClick: () => void;
  onOptionChange: (value: string) => void;
}

const InterviewOption: FC<InterviewOptionProps> = ({
  id, temporalId, label, onOptionChange, onDeleteOptionClick,
}) => {
  const [labelValue, setLabelValue] = useState(label);
  const firstRender = useRef(true);

  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLabelValue(event.target.value);
  };

  const handleDeleteOptionClick = () => {
    onDeleteOptionClick();
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setLabelValue(label);
    }
  }, [label]);

  useDebouncedEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      onOptionChange(labelValue);
    }
  }, 500, [labelValue]);

  return (
    <div key={id ?? temporalId} className={style.optionElement}>
      <Input
        value={labelValue}
        onChange={handleOptionChange}
      />
      <Popconfirm
        okText="Yes"
        cancelText="No"
        placement="topRight"
        okButtonProps={{ danger: true }}
        onConfirm={handleDeleteOptionClick}
        cancelButtonProps={{ type: 'default' }}
        title="Are you sure you want to delete this?"
      >
        <Tooltip title="Delete">
          <Button type="danger" icon={<DeleteOutlined />} />
        </Tooltip>
      </Popconfirm>
    </div>
  );
};

InterviewOption.defaultProps = {
  id: null,
  temporalId: null,
};

export default InterviewOption;
