import { useContext, useEffect, useState } from 'react';
import {
  Col,
  Typography,
  Input,
  Form,
  Select,
  message,
  Button,
  Divider,
  Modal,
  Row,
  Icons,
  Skeleton,
  DatePicker,
  Popconfirm,
  Switch,
  Popover,
} from 'taxaroo-ui';

import { isPossiblePhoneNumber } from 'react-phone-number-input';
import moment from 'moment';
import { TaxPayerContext } from '~src/components/providers/taxpayer-provider';
import {
  LanguagesEntity, PhonesEntity, PhoneType, UpsertPhoneInput,
} from '~src/graphql';
import { useUpdateUserInformationMutation, useUpdateUserMutation } from '~src/graphql/mutations/settings';
import { useRemoveClientMutation } from '~src/graphql/mutations/userInformation';
import { useRemoveClient2FaMutation } from '~src/graphql/mutations/clients';
// import {  useLanguagesQuery } from '~src/graphql/queries/settings';
import { useAppSelector } from '~src/redux/hooks';
import { selectSession } from '~src/redux/slices/sessionSlice';
import PhoneInputField from '../../../../atoms/PhoneInputField';

const { DeleteOutlined } = Icons;
const { Text } = Typography;
const { Option } = Select;

interface EditClientInfoPanelProps {
  handleExitEditMode: () => void;
}

const EditClientInfoPanel = ({ handleExitEditMode }: EditClientInfoPanelProps) => {
  const taxPayerContext = useContext(TaxPayerContext);
  const {
    loading,
    ownerId,
    userInformationId,
    firstName,
    lastName,
    phones,
    email,
    languageId,
    birthday,
    entityId,
    secretOTP,
  } = taxPayerContext;
  const [form] = Form.useForm();
  const [deleteClientModalVisible, setDeleteClientModalVisible] = useState(false);
  const [phoneInputs, setPhoneInputs] = useState<any[]>([]);

  const [updateUserInformation, { loading: loadingUpdateUserInfo }] = useUpdateUserInformationMutation();
  const [updateUser, { loading: loadingUpdateUser }] = useUpdateUserMutation();
  const [removeClient, { loading: loadingRemoveClient }] = useRemoveClientMutation({
    onError: (error) => message.error(error.message),
    onCompleted: () => {
      handleExitEditMode();
      message.success('Client successfully deleted.');
      setTimeout(() => {
        // redirect back to the dasboard
        window.location.href = '/';
      }, 700);
    },
  });

  const [removeClient2FA, { loading: loadingRemoveClient2FA }] = useRemoveClient2FaMutation({
    onError: (error) => message.error(error.message),
    onCompleted: () => {
      message.success('Two-Factor Authentication successfully turned off.');
    },
  });

  const { languages, userRole } = useAppSelector(selectSession);

  const updatePhonesFormValues = (updatedPhones: any, deletedIndex = -1) => {
    const formValues = form.getFieldsValue(true);
    const phonesUpdate = Object.assign(
      {},
      ...updatedPhones.map((_: any, index: number) => ({
        [`phones[${index}].type`]: deletedIndex > index
          ? formValues[`phones[${index}].type`]
          : formValues[`phones[${index + 1}].type`],
        [`phones[${index}].value`]: deletedIndex > index
          ? formValues[`phones[${index}].value`]
          : formValues[`phones[${index + 1}].value`],
      })),
    );
    form.setFieldsValue(phonesUpdate);
  };

  useEffect(() => {
    setPhoneInputs(phones);
  }, [phones]);

  const handleDeleteClient = async () => {
    await removeClient({
      variables: {
        entityId,
      },
    });
    setDeleteClientModalVisible(false);
  };

  const addPhone = () => {
    const updatedValue = [...phoneInputs, { type: PhoneType.Mobile, value: '' }];
    setPhoneInputs(updatedValue);
    form.setFieldsValue({
      [`phones[${phoneInputs.length}].type`]: PhoneType.Mobile,
      [`phones[${phoneInputs.length}].value`]: '',
    });
  };

  const removePhone = (index: number) => {
    const updatedValue = phoneInputs.filter((_, i) => i !== index);
    form.setFieldsValue({
      [`phones[${index}].type`]: PhoneType.Mobile,
      [`phones[${index}].value`]: '',
    });
    setPhoneInputs(updatedValue);
    updatePhonesFormValues(updatedValue, index);
  };

  const handleTurnOff2FA = () => {
    removeClient2FA({
      variables: {
        userId: ownerId,
      },
    });
  };

  const onSubmit = async (values: any) => {
    // get phones into an array of PhonesEntity
    const submittedPhoneValues: UpsertPhoneInput[] = [];
    phoneInputs.forEach((phone: any, index: number) => {
      submittedPhoneValues.push({
        id: phone.id,
        type: values[`phones[${index}].type`],
        value: values[`phones[${index}].value`],
      });
    });
    // check if all submitted phone numbers are valid
    const allValid = submittedPhoneValues.every((phone) => isPossiblePhoneNumber(phone.value));
    if (!allValid) {
      message.error('Phone number is not valid');
      return;
    }
    // check to see if any inital phones were removed
    for (let i = 0; i < phones.length; i += 1) {
      // if the phone is not in the submitted phone values, it was removed
      if (!submittedPhoneValues.find((phone) => phone.id === phones[i].id)) {
        submittedPhoneValues.push({
          id: phones[i].id,
          type: phones[i].type,
          value: phones[i].value,
          deleted: true,
        });
      }
    }
    try {
      const promises = [];
      // first we update the user information
      promises.push(
        updateUserInformation({
          variables: {
            updateUserInformationInput: {
              id: userInformationId,
              firstName: values.firstName,
              lastName: values.lastName,
              birthday: values.dob,
              Phones: submittedPhoneValues,
            },
          },
          refetchQueries: [
            'GetAllEntityDataByEntityId', // Query name
          ],
        }),
      );
      // now we update the user
      promises.push(
        updateUser({
          variables: {
            updateUserInput: {
              id: ownerId,
              languageId: values.language,
              email: values.email,
            },
          },
          refetchQueries: [
            'GetAllEntityDataByEntityId', // Query name
          ],
        }),
      );
      await Promise.all(promises);
      message.success('Client Updated!');
      handleExitEditMode();
    } catch (error) {
      console.log('error:', error);
      if (error.message.includes('Unique constraint failed on the fields: (`email`)')) {
        message.error('Email is already being used. If this email does not already exist in your firm, you may contact customer support for further assistance.', 10);
        return;
      }
      if (error.message === 'Error code 1003') {
        message.error('Email is invalid.');
        return;
      }
      message.error(error.toString());
    }
  }; // end onSubmit

  if (loading) {
    return <Skeleton active paragraph={{ rows: 4 }} />;
  }

  return (
    <>
      <Col sm={23} md={20} lg={14}>
        <Form
          layout="vertical"
          form={form}
          onFinish={onSubmit}
          // eslint-disable-next-line no-template-curly-in-string
          validateMessages={{ required: '${label} is required' }}
        >
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true }]}
            initialValue={firstName}
          >
            <Input placeholder="Taxpayer First Name" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true }]}
            initialValue={lastName}
          >
            <Input placeholder="Taxpayer Last Name" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true }]}
            initialValue={email}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="dob"
            label="Date of Birth"
            rules={[{ required: false }]}
            initialValue={birthday ? moment.utc(birthday) : null}
            valuePropName="date"
          >
            <DatePicker
              placeholder="MM/DD/YYYY"
              format="MM/DD/YYYY"
              style={{ width: '100%' }}
              defaultValue={birthday ? moment.utc(birthday) : null}
            />
          </Form.Item>
          <Divider dashed />
          {phoneInputs?.map((phone: PhonesEntity, index) => (
            <Row gutter={5} align="middle" key={`phone-row-${phone?.id}`}>
              <Col span={13}>
                <Form.Item
                  key={`phone-value-${phone?.id}`}
                  name={`phones[${index}].value`}
                  label={`Phone number ${index + 1}`}
                  rules={[{ required: false }]}
                  initialValue={phone.value}
                >
                  <PhoneInputField />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  key={`phone-type-${phone?.id}`}
                  name={`phones[${index}].type`}
                  label="Type"
                  initialValue={phone.type}
                >
                  <Select>
                    <Option value={PhoneType.Landline}>Landline</Option>
                    <Option value={PhoneType.Work}>Work</Option>
                    <Option value={PhoneType.Mobile}>Mobile</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={1} style={{ marginTop: 5 }}>
                <DeleteOutlined
                  style={{ color: 'red' }}
                  onClick={() => removePhone(index)}
                />
              </Col>
            </Row>
          ))}
          <Button
            type="link"
            onClick={() => addPhone()}
            disabled={phoneInputs.length > 5}
          >
            Add Phone
          </Button>
          <Divider dashed />
          <Form.Item
            name="language"
            label="Language"
            rules={[{ required: false }]}
            initialValue={languageId}
          >
            <Select
              placeholder="Select language"
              onChange={(value: string) => form.setFieldsValue({ taxYear: value })}
            >
              {languages.map((language: LanguagesEntity) => (
                <Select.Option key={language.id} value={language.id}>
                  {language.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingUpdateUserInfo || loadingUpdateUser}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
        <Divider />
        <Row gutter={20} style={{ marginBottom: '20px' }}>
          <Col>
            <Text strong>Two-Factor Authentication</Text>
          </Col>
          <Col>
            <Popconfirm
              placement="right"
              title="Are you sure you want to turn off two-factor authentication for this client?"
              okText="Yes"
              cancelText="No"
              onConfirm={handleTurnOff2FA}
              disabled={!secretOTP}
            >
              <Popover
                content={!secretOTP ? 'Client hasn\'t enabled Two-Factor Authentication yet' : 'Turn off yout client\'s two-factor authentication'}
                placement="top"
              >
                <Switch
                  disabled={!secretOTP}
                  checked={Boolean(secretOTP)}
                  loading={loadingRemoveClient2FA}
                />
              </Popover>
            </Popconfirm>
          </Col>
        </Row>
        {/* Only admins should be allowed to delete client */}
        {(userRole === 'TAX_PREPARER' || userRole === 'ADMIN') && (
          <>
            <Divider />
            <Button
              type="default"
              danger
              onClick={() => setDeleteClientModalVisible(true)}
            >
              Delete Client
            </Button>
          </>
        )}
      </Col>
      <Col sm={1} md={4} lg={10} />
      <Modal
        visible={deleteClientModalVisible}
        title="Are you sure?"
        onCancel={() => setDeleteClientModalVisible(false)}
        onOk={handleDeleteClient}
        okText="Delete Client"
        okType="primary"
        okButtonProps={{ danger: true, loading: loadingRemoveClient }}
      >
        <Text>You are about to permanently delete this client.</Text>
      </Modal>
    </>
  );
};

export default EditClientInfoPanel;
