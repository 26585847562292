import { Moment } from 'moment-timezone';
import { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Typography,
  message,
  Spin,
  Popover,
  Icons,
} from 'taxaroo-ui';
import { TaxPreparersEntity, TaxYearInterviewEntity } from '~src/graphql';
import { useGetJobsAndPaymentsByTaxYearStatusLazyQuery } from '~src/graphql/queries/analytics';

const { Title, Text } = Typography;
const {
  InfoCircleTwoTone,
} = Icons;

interface JobsByTeamMemberProps {
  startDate: Moment;
  endDate: Moment;
  preparers: TaxPreparersEntity[];
  progressStatusIds: string[];
  dateFilterKey: string;
  title: string;
  tooltip?: string;
}
const JobsByTeamMember = ({
  startDate,
  endDate,
  preparers,
  progressStatusIds,
  dateFilterKey,
  title,
  tooltip,
}: JobsByTeamMemberProps) => {
  const [loadingAllJobs, setLoadingAllJobs] = useState(false);
  const [jobsByPreparer, setJobsByPreparer] = useState({});
  // graphql queries
  const [getJobsAndPayments] = useGetJobsAndPaymentsByTaxYearStatusLazyQuery({
    onError: (error) => {
      message.error(error.message);
    },
  });

  const fetchJobsAndPayments = async () => {
    setLoadingAllJobs(true);
    const { data } = await getJobsAndPayments({
      variables: {
        progressStatusIds,
        startDate,
        endDate,
        dateFilterKey,
      },
    });
    const byPreparer = {};
    data?.JobsAndPaymentsByTaxYearStatus.forEach((taxYearInterview: TaxYearInterviewEntity) => {
      if (taxYearInterview?.preparedId) {
        if (byPreparer[taxYearInterview?.preparedId]) {
          byPreparer[taxYearInterview?.preparedId].push(taxYearInterview);
        } else {
          byPreparer[taxYearInterview?.preparedId] = [{ ...taxYearInterview }];
        }
      }
    });
    setJobsByPreparer(byPreparer);
    setLoadingAllJobs(false);
  };

  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    }
    fetchJobsAndPayments();
  }, [getJobsAndPayments, progressStatusIds, startDate, endDate]);

  return (
    <>
      <Row>
        <Title level={4}>
          {title}
          {tooltip && (
            <>
              &nbsp;
              <Popover
                placement="top"
                content={tooltip}
                trigger="hover"
              >
                <InfoCircleTwoTone style={{ fontSize: 14 }} />
              </Popover>
            </>
          )}
        </Title>
        {loadingAllJobs && (
          <Spin
            size="small"
            style={{ marginLeft: 10, marginTop: 3 }}
          />
        )}
      </Row>
      <Row style={{ marginTop: 40, marginBottom: 40 }}>
        <Col>
          {/* foreach team member display their number of in progress jobs */}
          {preparers.map((preparer: TaxPreparersEntity) => (
            <Row style={{ fontSize: '18px', whiteSpace: 'nowrap' }} key={preparer.id}>
              <Text>
                <Text strong>
                  {preparer?.name}
                  :
                  {' '}
                </Text>
                <Text>{jobsByPreparer[preparer?.id]?.length || 0}</Text>
              </Text>
            </Row>
          ))}
        </Col>
        <Col>
          {/* <Chart options={options} /> */}
        </Col>
      </Row>
    </>
  );
};

// default props
JobsByTeamMember.defaultProps = {
  tooltip: '',
};

export default JobsByTeamMember;
