import { ValidateClientEntity } from '~src/graphql';
import ImportClientsModal from './ImportClientsModal';

export interface ClientEntity extends ValidateClientEntity {
  key: React.Key;
  sendInvitation: boolean;
  preparer?: string;
  jobType: string;
  taxYear: number;
}

export default ImportClientsModal;
