import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Interview, InterviewSection, Options, Questions, Translations,
} from '~src/components/templates/Interviews';
import { RootState } from '../store';

 type InterviewState = {
  currentInterview?: Interview;
  interviews: Array<Interview>;
};

const INITIAL_STATE: InterviewState = {
  interviews: [],
  currentInterview: null,
};

const interviewsSlice = createSlice({
  name: 'interviews',
  initialState: INITIAL_STATE,
  reducers: {
    resetInterviews: (state) => ({
      ...state,
      ...INITIAL_STATE,
    }),
    setCurrentInterview: (state, action: PayloadAction<Partial<Interview>>) => ({
      ...state,
      currentInterview: {
        ...state.currentInterview,
        ...action.payload,
      },
    }),
    setInterviews: (state, action: PayloadAction<Array<Interview>>) => ({
      ...state,
      interviews: action.payload,
    }),
    clearCurrentInterview: (state) => ({
      ...state,
      currentInterview: null,
    }),
  },
});

export const selectInterviews = (state: RootState) => state.interviews.interviews;

/*
  * !IMPORTANT
  This selector of state is used for representational use of the data in the UI, in this case the
  sections will have to be filtered by the element that has the property "operation" as "delete".
  Since that value we need it for its use in the mutation of "updateInterview" the state continues
  reserving that section with that value of the property "operation" while this selector makes that
  it does not show in the UI the sections that fulfill this condition.
*/
export const selectCurrentInterview = (state: RootState) => {
  const getTranslationsWithoutDeleteOperations = (translations: Array<Translations>) => translations.filter(
    ({ operation }) => (!operation || operation !== 'delete'),
  );

  const getOptionsWithoutDeleteOperations = (options: Array<Options>) => options.filter(
    ({ operation }) => (!operation || operation !== 'delete'),
  ).map((option) => ({
    ...option,
    Translations: getTranslationsWithoutDeleteOperations(option.Translations),
  }));

  const getQuestionsWithoutDeleteOperations = (questions: Array<Questions>) => questions.filter(
    ({ operation }) => (!operation || operation !== 'delete'),
  ).map((question) => ({
    ...question,
    Options: getOptionsWithoutDeleteOperations(question.Options ?? []),
    Translations: getTranslationsWithoutDeleteOperations(question.Translations ?? []),
  }));

  const getSectionsWithoutDeleteOperations = (
    sections: Array<InterviewSection>,
  ): Array<InterviewSection> => sections.filter(
    ({ operation }) => (!operation || operation !== 'delete'),
  ).map((section) => {
    const { Translations: SectionTranslations, SubSections, Questions: SectionQuestions } = section;

    return {
      ...section,
      Questions: getQuestionsWithoutDeleteOperations(SectionQuestions),
      SubSections: getSectionsWithoutDeleteOperations(SubSections ?? []),
      Translations: getTranslationsWithoutDeleteOperations(SectionTranslations),
    };
  });

  return {
    ...(state.interviews.currentInterview ?? {
      id: null,
      name: '',
      Sections: [],
      isLive: false,
      temporalId: null,
      isTemplate: false,
    }),
    Sections: getSectionsWithoutDeleteOperations(state.interviews.currentInterview?.Sections ?? [])
      .sort(({ order }, { order: orderB }) => order - orderB) ?? [],
  };
};

/*
 * IMPORTANT!
  This selector of state is used to collerect all intview data without filtered values,
  since all the data it will be needed for the use in the mutation "updateInterview"
*/
export const selectCurrentInterviewData = (state: RootState) => ({
  ...(state.interviews.currentInterview ?? {
    id: null,
    name: '',
    Sections: [],
    isLive: false,
    temporalId: null,
    isTemplate: false,
  }),
});

export const {
  setInterviews,
  resetInterviews,
  setCurrentInterview,
  clearCurrentInterview,
} = interviewsSlice.actions;

export default interviewsSlice.reducer;
