/* eslint-disable camelcase */
import { useNavigate } from 'react-router';
import { message } from 'taxaroo-ui';
import { BusinessInformationEntity, BusinessType, UserEntity } from '~src/graphql';
import { useLoginMutation } from '~src/graphql/mutations/login';
import { useAppDispatch, useAppSelector } from '~src/redux/hooks';
import { RegisterStep, resetRegisterState, selectRegisterState } from '~src/redux/slices/registerSlice';
import { setAllLanguages, setSession } from '~src/redux/slices/sessionSlice';
import { useGetLanguagesQueryLazyQuery } from '~src/graphql/queries/clients';
import CardNav from '../CardNav.tsx';

interface IntroVideoProps {
  setStep: (arg1: keyof typeof RegisterStep) => void;
  setModalVisible: (arg1: boolean) => void;
  signUpFlow: boolean;
}
function IntroVideo({
  setStep,
  setModalVisible,
  signUpFlow,
}: IntroVideoProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { personalInfo: { the_em, the_pass } } = useAppSelector(selectRegisterState);
  const [login, { loading: loadingLogin }] = useLoginMutation();
  const [getLanguages] = useGetLanguagesQueryLazyQuery();

  const getFirmAddress = (businessType: BusinessType, { User, BusinessInformation }: {
    User: UserEntity,
    BusinessInformation: BusinessInformationEntity
  }) => {
    if (businessType === 'INDIVIDUAL') {
      const [Address] = User.UserInformation.Address.filter(({ type }) => type === 'PERSONAL');

      if (Address) {
        const {
          lineOne, lineTwo, city, state, zip,
        } = Address;

        return `${lineOne ?? ''}, ${lineTwo ?? ''} ${city}, ${state} ${zip}`;
      }
    }

    const [Address] = BusinessInformation?.Address.filter(({ type }) => type === 'BUSINESS') ?? [];

    if (Address) {
      const {
        lineOne, lineTwo, city, state, zip,
      } = Address;

      return `${lineOne ?? ''}, ${lineTwo ?? ''} ${city}, ${state} ${zip}`;
    }

    return '';
  };

  const getFirmPhoneNumber = (businessType: BusinessType, { User, BusinessInformation }: {
    User: UserEntity,
    BusinessInformation: BusinessInformationEntity
  }) => {
    if (businessType === 'INDIVIDUAL') {
      const [Phone] = User.UserInformation.Phones.filter(({ type }) => type === 'PERSONAL');

      if (Phone) {
        const { value } = Phone;

        return value ?? '';
      }

      return '';
    }
    const [Phone] = BusinessInformation.Phones.filter(({ type }) => type === 'OFFICE');

    if (Phone) {
      const { value } = Phone;

      return value ?? '';
    }

    return '';
  };

  const onSubmit = async () => {
    try {
      if (signUpFlow) {
        const languagesResult = await getLanguages();
        const { languages } = languagesResult.data;

        const loginResult = (await login({
          variables: {
            loginInput: {
              email: the_em,
              password: the_pass,
            },
          },
        })).data?.Login ?? {};

        if (loginResult.accessToken?.length) {
          const { accessToken, UserFirmAccount } = loginResult;
          const { FirmAccount, Users, Roles } = UserFirmAccount;
          const { businessType, Users: FirmUser, BusinessInformation } = FirmAccount;
          const { UserInformation } = Users;

          dispatch(setSession({
            accessToken,
            id: UserInformation?.id ?? '',
            email: Users?.email ?? '',
            userId: UserInformation?.userId ?? '',
            userInformationId: UserInformation?.id ?? '',
            firstName: UserInformation?.firstName ?? '',
            lastName: UserInformation?.lastName ?? '',
            birthday: UserInformation?.birthday ?? '',
            ssn: UserInformation?.ssn ?? '',
            profilePhoto: UserInformation?.profilePhoto ?? '',
            firmAccountId: FirmAccount?.id ?? '',
            languageId: Users?.languageId ?? '',
            userRole: Roles?.description ?? '',
            progressStatuses: [...(FirmAccount.ProgressStatuses ?? [])].map(({
              id,
              status,
              underlyingStatus,
              index,
            }) => ({
              status, underlyingStatus, index, id,
            })),
            firmName: FirmAccount.name ?? '',
            firmAddress: getFirmAddress(businessType, {
              User: FirmUser,
              BusinessInformation,
            }),
            firmPhoneNumber: getFirmPhoneNumber(businessType, {
              User: FirmUser,
              BusinessInformation,
            }),
            firmCreatedAt: FirmAccount.createAt ?? '',
          }));
          dispatch(resetRegisterState());
          dispatch(setAllLanguages({ languages }));

          navigate('/onboarding');
        }
      } else {
        setModalVisible(false);
      }
    } catch (error) {
      message.error(error.message ?? 'Something went while trying to sign in into your account');
    }
  };

  return (
    <div style={{ width: '100%' }}>
      {/* <Title level={4}>Welcome video:</Title> */}
      <iframe
        width="560"
        height="315"
        src="https://www.youtube-nocookie.com/embed/YFM3UTcukXo?start=1"
        title="Welcome video"
        // frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ display: 'block', margin: '0 -25px' }}
      />
      <CardNav
        showSkip={false}
        showBack={false}
        onNext={() => onSubmit()}
        nextText="Finished"
        disabled={false}
        loading={loadingLogin}
      />
    </div>
  );
}

export default IntroVideo;
