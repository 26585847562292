/*
  * This is to get <limit> years back from the current year for example if the current
  * year is 2022 and limit=5 the result would be: [2022, 2021, 2020, 2019, 2018].
  * but the result is mapped for use as options in a select element/component
*/
const getTaxYearOptions = (limit: number) => {
  const currentYear = (new Date()).getFullYear();

  const options: Array<{ value: string; label: string; }> = [
    // previous year
    {
      value: (currentYear - 1).toString(),
      label: (currentYear - 1).toString(),
    },
    // current year
    {
      value: currentYear.toString(),
      label: currentYear.toString(),
    },
  ];
  for (let year = currentYear - 2; year > currentYear - limit; year -= 1) {
    options.push({
      value: year.toString(),
      label: year.toString(),
    });
  }
  return options;
};

export default getTaxYearOptions;
