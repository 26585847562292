import { FC, useState } from 'react';
import * as yup from 'yup';
import {
  Modal,
  Form,
  Alert,
  Input,
  Select,
  message,
  DatePicker,
} from 'taxaroo-ui';
import { useFormik, FormikProvider } from 'formik';
import { useAddMemberToFirmMutation } from '~src/graphql/mutations/settings';
import moment from 'moment';

const { Item } = Form;
const { Option } = Select;
interface ManageTeamModalProps {
  showModal: boolean;
  setModal: (e: boolean) => void;
  onAddMemberTeam: (e: object) => void;
  roles: Array<{
    id: string;
    description: string;
    __typename: string;
  }>;
  firmAccountId: string;
  languageId: string;
}

const isBirthdayValid = (date: Date) => {
  console.log(`isBirthdayValid - date: ${JSON.stringify(date)}`);

  if (!date) {
    return false;
  }
  const max = moment();
  const min = moment('1900-01-01', 'YYYY-MM-DD');
  const current = moment(date);
  const isValid = current.isAfter(min) && current.isBefore(max);
  console.log(`isValid: ${isValid}`);
  return isValid;
};

const ManageTeamModal: FC<ManageTeamModalProps> = ({
  showModal,
  onAddMemberTeam,
  roles,
  setModal,
  firmAccountId,
  languageId,
}) => {
  const [addMemberToFirmMutation, { loading }] = useAddMemberToFirmMutation();
  const [isNotCompanyEmail, setIsNotCompanyEmail] = useState(false);
  const formik = useFormik({
    validationSchema: yup.object().shape({
      email: yup.string().email().required('Email is required'),
      firstName: yup.string().required('First name is required'),
      lastName: yup.string().required('Last name is required'),
      birthday: yup.date().required('Date of birth is required').test(
        'is-birthday-valid',
        'Birthday is not valid',
        isBirthdayValid,
      ),
      roleId: yup.string().required('Role is required'),
    }),
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      birthday: undefined,
      roleId: '',
    },
    onSubmit: async ({
      firstName, lastName, email, roleId, birthday,
    }) => {
      try {
        const res = await addMemberToFirmMutation({
          variables: {
            firmAccountId,
            memberAddInput: {
              email,
              roleId,
              birthday,
              lastName,
              firstName,
              languageId,
            },
          },
        });
        const membersArr = res?.data?.addMemberToFirm;
        const newMember = membersArr[membersArr.length - 1];
        onAddMemberTeam(newMember);
        setModal(false);
        formik.resetForm();
      } catch (err) {
        if (err.message === 'Error code 2004') {
          message.error('Your account reached the maximum number of members');
          setModal(false);
        } else if (err.message === 'Error code 1014') {
          message.error('This email is already used for another account. Please use a different email to add as a team member.');
          setModal(false);
        } else if (err.message === 'Error code 1011') {
          message.error('You do not have permission to delete this user');
          setModal(false);
        } else {
          message.error(err.message || 'Could Not Add Member Team');
          setModal(false);
        }
      }
    },
  });

  const {
    touched,
    values,
    errors,
    handleSubmit,
    handleChange,
    resetForm,
  } = formik;

  const checkIsCompanyEmail = (e: string) => {
    const pattern = /@(gmail\.com|live\.com|comcast\.com|outlook\.com|icloud\.com|aol\.com|hotmail\.com|yahoo\..*)$/;
    setIsNotCompanyEmail(pattern.test(e));
  };

  const getEmailInputValidateStatus = () => {
    if (isNotCompanyEmail) {
      return 'warning';
    }
    if (touched.email && errors.email) {
      return 'error';
    }
    return 'success';
  };

  const getEmailInputHelpText = () => {
    if (isNotCompanyEmail) {
      return 'Using emails from Google, Yahoo, Aol, etc. will cause emails to go out from @taxaroo.com (instead of your custom domain). Consider using company emails instead.';
    }
    if (touched.email && errors.email) {
      return <Alert message={errors.email} type="error" />;
    }
    return null;
  };

  return (
    <Modal
      title="Add New Team Member"
      centered
      visible={showModal}
      onCancel={() => {
        setModal(false);
        resetForm();
      }}
      onOk={() => formik.submitForm()}
      okText="Add Member"
      confirmLoading={loading}
    >
      <FormikProvider value={formik}>
        <Form layout="vertical" name="basic" onFinish={handleSubmit}>
          <Item
            label="Email"
            validateStatus={getEmailInputValidateStatus()}
            help={getEmailInputHelpText()}
          >
            <Input
              name="email"
              onChange={handleChange}
              value={values.email}
              disabled={loading}
              placeholder="Email"
              onBlur={() => { checkIsCompanyEmail(values.email); }}
            />
          </Item>
          <Item
            label="First Name"
            validateStatus={touched.firstName && errors.firstName ? 'error' : 'success'}
            help={
              touched.firstName
              && errors.firstName && <Alert message={errors.firstName} type="error" />
            }
          >
            <Input
              name="firstName"
              onChange={handleChange}
              value={values.firstName}
              disabled={loading}
              placeholder="First Name"
            />
          </Item>
          <Item
            label="Last Name"
            validateStatus={touched.lastName && errors.lastName ? 'error' : 'success'}
            help={
              touched.lastName
              && errors.lastName && <Alert message={errors.lastName} type="error" />
            }
          >
            <Input
              name="lastName"
              onChange={handleChange}
              value={values.lastName}
              disabled={loading}
              placeholder="Last Name"
            />
          </Item>
          <Item
            label="Date of Birth"
            help={touched.birthday && errors.birthday && <Alert message={errors.birthday} type="error" />}
          >
            <DatePicker
              disabled={loading}
              value={values.birthday}
              onChange={(value: any) => {
                formik.setFieldValue('birthday', value);
              }}
              placeholder="MM/DD/YYYY"
              format="MM/DD/YYYY"
              style={{ width: '100%' }}
            />
          </Item>
          <Item
            label="Account Role"
            validateStatus={touched.roleId && errors.roleId ? 'error' : 'success'}
            help={touched.roleId && errors.roleId && <Alert message={errors.roleId} type="error" />}
          >
            {roles.length > 0 && (
              <Select
                style={{ width: '100%', display: 'flex' }}
                onChange={handleChange('roleId')}
                value={values.roleId}
                dropdownStyle={{ zIndex: 9999 }}
                disabled={loading}
              >
                {roles.map((role) => (
                  <Option key={role.id} value={role.id}>
                    {role.description === 'TAX_PREPARER' ? 'Admin' : 'Staff'}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
        </Form>
      </FormikProvider>
    </Modal>
  );
};

export default ManageTeamModal;
