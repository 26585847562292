import { useContext } from 'react';
import {
  Row,
  Col,
  Typography,
  Skeleton,
} from 'taxaroo-ui';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { TaxPayerContext } from '~src/components/providers/taxpayer-provider';
import { PhonesEntity, PhoneType } from '~src/graphql';
import moment from 'moment';

const { Text } = Typography;

function Divider() {
  return <div style={{ height: '15px' }} />;
}

const getPhoneLabel = (phoneType: PhoneType) => {
  switch (phoneType) {
    case 'LANDLINE':
      return 'LANDLINE';
    case 'WORK':
      return 'WORK';
    case 'MOBILE':
      return 'MOBILE';
    default:
      return 'Other';
  }
};

const ClientInfoPanel = () => {
  const taxPayerContext = useContext(TaxPayerContext);
  const {
    loading,
    firstName,
    lastName,
    phones,
    email,
    birthday,
    languageDescription,
  } = taxPayerContext;

  if (loading) {
    return <Skeleton active paragraph={{ rows: 4 }} />;
  }

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col lg={5} md={8} sm={8} xs={10}>
          <Text strong>First Name:</Text>
        </Col>
        <Col lg={19} md={16} sm={16} xs={14}>
          <Text>{firstName}</Text>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[10, 10]}>
        <Col lg={5} md={8} sm={8} xs={10}>
          <Text strong>Last Name:</Text>
        </Col>
        <Col lg={19} md={16} sm={16} xs={14}>
          <Text>{lastName}</Text>
        </Col>
      </Row>
      {phones.map((phone: PhonesEntity, index: number) => (
        <>
          <Divider />
          <Row gutter={[10, 10]}>
            <Col lg={5} md={8} sm={8} xs={10}>
              <Text strong>
                Phone
                {index + 1}
                {' '}
                (
                {getPhoneLabel(phone.type)}
                ):
              </Text>
            </Col>
            <Col lg={19} md={16} sm={16} xs={14}>
              <Text>{formatPhoneNumberIntl(phone.value)}</Text>
            </Col>
          </Row>
        </>
      ))}
      <Divider />
      <Row gutter={[10, 10]}>
        <Col lg={5} md={8} sm={8} xs={10}>
          <Text strong>Email:</Text>
        </Col>
        <Col lg={19} md={16} sm={16} xs={14}>
          <Text>{email}</Text>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[10, 10]}>
        <Col lg={5} md={8} sm={8} xs={10}>
          <Text strong>Birthday:</Text>
        </Col>
        <Col lg={19} md={16} sm={16} xs={14}>
          <Text>{birthday ? moment.utc(birthday).format('MM/DD/YYYY') : ''}</Text>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[10, 10]}>
        <Col lg={5} md={8} sm={8} xs={10}>
          <Text strong>Language:</Text>
        </Col>
        <Col lg={19} md={16} sm={16} xs={14}>
          <Text>{languageDescription}</Text>
        </Col>
      </Row>
    </>
  );
};

export default ClientInfoPanel;
