/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Menu, Icons, Typography } from 'taxaroo-ui';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '~src/redux/hooks';
import { selectSession } from '~src/redux/slices/sessionSlice';
import { Features, useUpgradeModal } from '~src/components/atoms/UpgradeModal/ModalProvider';

const { Text } = Typography;
const { Item } = Menu;
const {
  BulbOutlined,
  DashboardOutlined,
  CalendarOutlined,
  MessageOutlined,
  EditOutlined,
  BarChartOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  RobotOutlined,
} = Icons;

interface MyMenuProps {
  onClick?: () => void;
}

const MyMenu: React.FC<MyMenuProps> = ({
  onClick,
}) => {
  const { showModal, accessObject } = useUpgradeModal();

  const [selectedKeys, setSelectedKeys] = useState(['dashboard']);
  const location = useLocation();
  const { userRole } = useAppSelector(selectSession);

  useEffect(() => {
    const key = location.pathname.split('/')[1] || '/';
    setSelectedKeys([key]);
  }, [location.key]);

  return (
    <Menu
      defaultOpenKeys={['sub1']}
      defaultSelectedKeys={['1']}
      mode="inline"
      style={{
        borderRight: 0,
        height: '100%',
        marginTop: '12px',
      }}
      theme="dark"
      selectedKeys={selectedKeys}
      onClick={() => onClick?.()}
    >
      <Item key="onboarding" icon={<BulbOutlined />}>
        <Link to="/onboarding">Get Started</Link>
      </Item>
      <Item key="/" icon={<DashboardOutlined />}>
        <Link to="/">Dashboard</Link>
      </Item>
      {!accessObject?.[Features.appointments]
        ? (
          <Item
            key="appointments"
            icon={<CalendarOutlined />}
            onClick={() => showModal(Features.appointments)}
          >
            <Text style={{ color: 'white' }}>
              Appointments
            </Text>
          </Item>
        )
        : (
          <Item key="appointments" icon={<CalendarOutlined />}>
            <Link to="/appointments">Appointments</Link>
          </Item>
        )}
      <Item key="chat" icon={<MessageOutlined />}>
        <Link to="/chat">Messaging</Link>
      </Item>
      {userRole !== 'STAFF' && (
        <>
          <Item key="interviews" icon={<EditOutlined />}>
            <Link to="/interviews">Interviews</Link>
          </Item>
          <Item key="analytics" icon={<BarChartOutlined />}>
            <Link to="/analytics">Analytics</Link>
          </Item>
        </>
      )}
      <Item key="settings" icon={<SettingOutlined />}>
        <Link to="/settings">Settings</Link>
      </Item>
      {!accessObject?.[Features.aiResearch]
        ? (
          <Item
            key="AIResearch"
            icon={<CalendarOutlined />}
            onClick={() => showModal(Features.aiResearch)}
          >
            <Text style={{ color: 'white' }}>
              AI Research
            </Text>
          </Item>
        )
        : (
          <Item key="AIResearch" icon={<RobotOutlined />} className="openLandbot">
            AI Research
          </Item>
        )}
      <Item key="helpCenter" icon={<InfoCircleOutlined />}>
        <a href="https://help.taxaroo.com/" target="_blank" rel="noreferrer">Help Center</a>
      </Item>
    </Menu>
  );
};

export default MyMenu;
