import { message } from 'taxaroo-ui';

const onSuccessDeleteClientFile = () => {
  message.success('File deleted');
};

const handleOnErrorDownloadAllFiles = () => {
  message.error('An error has occurred while trying to download all files. Please try again');
};

export {
  onSuccessDeleteClientFile,
  handleOnErrorDownloadAllFiles,
};
