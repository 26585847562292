import { FC, useState } from 'react';
import {
  Form, Select, FormInstance, Row, Col, Typography, Input, Badge,
} from 'taxaroo-ui';
import { ParseResult } from 'papaparse';
import { PhoneType } from '~src/graphql';
import moment from 'moment';
import { badgeBgColors } from './TableForm';

const { Option } = Select;
const { Title } = Typography;
const { Item } = Form;

export const importClientProperties = {
  address1: 'Address Line 1',
  address2: 'Address Line 2',
  city: 'City',
  dob: 'Date of Birth',
  email: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  phones: 'Phone',
  zip: 'Postal Code',
  ssn: 'Social Security Number',
  state: 'State',
};

interface MapContactsProps {
  mapForm: FormInstance<any>;
  parseResult: ParseResult<any>;
  setStepOneNextDisabled: (value: boolean) => void;
}

const MapContacts: FC<MapContactsProps> = ({ mapForm, parseResult, setStepOneNextDisabled }) => {
  const values = mapForm.getFieldsValue(true);
  const initialPhoneTypesState = Object.assign(
    {},
    ...parseResult.meta.fields.map((fieldName: string) => ({
      [`phoneType_${fieldName}`]: values[`map_${fieldName}`] !== 'phones',
    })),
  );
  const [phoneTypesHidden, setPhoneTypesHidden] = useState(initialPhoneTypesState);

  const initialBadgeColorState = Object.assign(
    {},
    ...parseResult.meta.fields.map((fieldName: string) => ({
      [fieldName]: values[`map_${fieldName}`] ? badgeBgColors.green : badgeBgColors.orange,
    })),
  );
  const [badgeColor, setBadgeColor] = useState(initialBadgeColorState);

  // Filter empty columns
  const filteredFieldsToMap = parseResult.meta.fields.filter((fieldName: string) => parseResult.data.find((item) => item[fieldName]?.trim()));
  const getPreviewInitialValue = (fieldName: string) => {
    if (fieldName.toLowerCase().includes('date')) {
      return parseResult.data.find((item) => Boolean(item[fieldName]?.trim()) && moment(item[fieldName]).isValid())?.[fieldName].trim() || 'No date preview available';
    }
    return parseResult.data.find((item) => item[fieldName]?.trim())?.[fieldName].trim() || 'No preview available';
  };

  return (
    <>
      <Title level={4}>
        The left column title is the column header from your spreadsheet.
        <br />
        Select the matching Taxaroo field from the dropdown in the right column.
      </Title>
      <Form
        layout="vertical"
        form={mapForm}
        style={{ width: '100%' }}
        onFieldsChange={(changedFields: any, allFields: any) => {
          if (changedFields[0].name[0].indexOf('map_') === 0) {
            const fieldName = changedFields[0].name[0].replace('map_', '');
            const mapFields = allFields.filter((f: any) => f.name[0].indexOf('map_') === 0);
            // enable/disable "Next" button
            if (mapFields.some((mf: any) => mf.value === 'firstName')
                && !mapFields.some((mf: any) => mf.errors.length > 0)) {
              setStepOneNextDisabled(false);
            } else {
              setStepOneNextDisabled(true);
            }

            // remove duplicate prop selection
            if (changedFields[0].value !== 'phones') {
              const previousSelectedField = mapFields.find((mf: any) => mf.value === changedFields[0].value
                && mf.name[0] !== changedFields[0].name[0]);
              if (previousSelectedField) {
                mapForm.setFieldsValue({ [previousSelectedField.name]: '' });
                setBadgeColor((prevValues: any) => ({
                  ...prevValues,
                  [previousSelectedField.name[0].replace('map_', '')]: badgeBgColors.orange,
                }));
              }
            }

            // hide/show phone types update badge color
            setPhoneTypesHidden({
              ...phoneTypesHidden,
              [`phoneType_${fieldName}`]: changedFields[0].value !== 'phones',
            });
            setBadgeColor((prevValues: any) => ({
              ...prevValues,
              [fieldName]: changedFields[0].value ? badgeBgColors.green : badgeBgColors.orange,
            }));

            // check if dob has valid format
            if (changedFields[0].value === 'dob') {
              const dobVal = mapForm.getFieldsValue([`preview_${fieldName}`])?.[`preview_${fieldName}`];
              if (dobVal) {
                const val = moment(dobVal);
                if (!val.isValid()) {
                  mapForm.setFields([{
                    name: changedFields[0].name,
                    errors: ['Date format is not recognized!'],
                  }]);
                  setBadgeColor((prevValues: any) => ({
                    ...prevValues,
                    [fieldName]: badgeBgColors.red,
                  }));
                  setStepOneNextDisabled(true);
                }
              }
            } else {
              mapForm.setFields([{
                name: changedFields[0].name,
                errors: [],
              }]);
            }
          }
        }}
      >
        {filteredFieldsToMap.map((fieldName: string, key: number) => (
          <section key={key} style={key > 0 ? { paddingTop: '25px' } : {}}>
            <Row>
              <Title level={5}>
                <Badge dot style={{ backgroundColor: badgeColor[fieldName], margin: '0 10px 0 0' }} />
                {fieldName}
              </Title>
            </Row>
            <Row gutter={[20, 0]}>
              <Col style={{ width: '40%' }}>
                <Item
                  name={`preview_${fieldName}`}
                  label="Spreadsheet Record Preview"
                  initialValue={getPreviewInitialValue(fieldName)}
                >
                  <Input disabled />
                </Item>
              </Col>
              <Col style={{ width: '38%' }}>
                <Item
                  name={`map_${fieldName}`}
                  label="Taxaroo Contact Field"
                  initialValue=""
                >
                  <Select showSearch>
                    <Option value="">Do not import</Option>
                    {Object.keys(importClientProperties).map((field) => (
                      <Option value={field} key={field}>{importClientProperties[field]}</Option>
                    ))}
                  </Select>
                </Item>
              </Col>
              <Col style={{ width: '22%' }}>
                <Item
                  name={`phoneType_${fieldName}`}
                  label="Phone Type"
                  initialValue={PhoneType.Mobile}
                  hidden={phoneTypesHidden[`phoneType_${fieldName}`]}
                >
                  <Select>
                    {Object.keys(PhoneType).map((type) => (
                      <Option value={PhoneType[type]} key={type}>{type}</Option>
                    ))}
                  </Select>
                </Item>
              </Col>
            </Row>
          </section>
        ))}
      </Form>
    </>
  );
};

export default MapContacts;
