import { useContext } from 'react';
import {
  Button,
  Row,
  Col,
  Icons,
  message,
  Typography,
} from 'taxaroo-ui';
import { JobContext } from '~src/components/providers/job-provider';
import { useSendInterviewMutation } from '~src/graphql/mutations/clients';
import { useUpsertJobTrackingByTaxYearInterviewIdMutation } from '~src/graphql/mutations/jobTracking';
import { GetClientsByProgressStatusIdDocument, GetClientsDocument } from '~src/graphql/queries/settings';

const { SendOutlined, CheckCircleOutlined, UndoOutlined } = Icons;
const { Paragraph, Link } = Typography;

function Interview() {
  const {
    interviewCompletedAt,
    taxYearInterviewId,
    progressStatusId,
    dispatch,
  } = useContext(JobContext);
  // graphql
  const [upsertJobTracking, { loading: loadingUpsertJobTracking }] = useUpsertJobTrackingByTaxYearInterviewIdMutation({
    onError: (error) => {
      message.error(error.message);
    },
  });
  const [sendInterview, { loading: sendInterviewLoading }] = useSendInterviewMutation();

  const handleMarkComplete = async () => {
    const today = new Date();
    upsertJobTracking({
      variables: {
        createJobTrackingInput: {
          taxYearInterviewId,
          interviewCompletedAt: today,
        },
      },
      onCompleted: () => {
        dispatch({ type: 'UPDATE', payload: { interviewCompletedAt: today } });
        message.success('Interview marked as complete.');
      },
      refetchQueries: [
        {
          query: GetClientsDocument,
        },
        {
          query: GetClientsByProgressStatusIdDocument,
          variables: {
            progressStatusId,
          },
        },
      ],
    });
  };

  const handleMarkNotComplete = async () => {
    upsertJobTracking({
      variables: {
        createJobTrackingInput: {
          taxYearInterviewId,
          interviewCompletedAt: null,
        },
      },
      onCompleted: () => {
        dispatch({ type: 'UPDATE', payload: { interviewCompletedAt: null } });
        message.success('Interview marked as not complete.');
      },
      refetchQueries: [
        {
          query: GetClientsDocument,
        },
        {
          query: GetClientsByProgressStatusIdDocument,
          variables: {
            progressStatusId,
          },
        },
      ],
    });
  };

  const handleResend = async () => {
    try {
      const sent = await sendInterview({
        variables: {
          taxYearInterviewId,
        },
      });

      if (sent.data.SendInterview.success) {
        message.success('The Interview was resent successfully.');
      } else {
        message.error('The interview could not be sent');
      }
    } catch (error) {
      message.error('Something went wrong while trying to send the interview.');
    }
  };

  return (
    <>
      <Paragraph style={{ marginTop: 30 }}>
        Send a pre-defined set of interview questions
        to your client. Answers will be populated in the&nbsp;
        <Link href={`#account-client-interview-section-${taxYearInterviewId}`}>Client Interview</Link>
        {' '}
        section below.
      </Paragraph>
      <Row gutter={[20, 10]} style={{ marginTop: 10, marginBottom: 15 }}>
        <Col>
          <Button
            type="default"
            icon={<SendOutlined />}
            iconDirection="left"
            onClick={handleResend}
            loading={sendInterviewLoading}
          >
            Resend client interview
          </Button>
        </Col>
        <Col>
          {!interviewCompletedAt
            ? (
              <Button
                type="primary"
                icon={<CheckCircleOutlined />}
                iconDirection="left"
                onClick={handleMarkComplete}
                loading={loadingUpsertJobTracking}
                disabled={loadingUpsertJobTracking}
              >
                Mark interview complete
              </Button>
            )
            : (
              <Button
                type="default"
                icon={<UndoOutlined />}
                iconDirection="left"
                onClick={handleMarkNotComplete}
                loading={loadingUpsertJobTracking}
                disabled={loadingUpsertJobTracking}
              >
                Mark interview not complete
              </Button>
            )}
        </Col>
      </Row>
    </>
  );
}

export default Interview;
