import { FC, useEffect, useState } from 'react';
import {
  Button, Icons, message,
} from 'taxaroo-ui';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import { GetEncryptMyTokenMutation, useGetEncryptMyTokenMutation } from '~src/graphql/mutations/clients';

const { UserOutlined } = Icons;
interface LoginAsClientProps {
  userId: string
}

const LoginAsClientButton: FC<LoginAsClientProps> = ({
  userId,
}) => {
  const [encryptToken, setEncryptToken] = useState<GetEncryptMyTokenMutation>(null);
  const [encryptTokenError, setEncryptTokenError] = useState<string>(null);

  const [encryptMyToken, { loading: loadingEncryptMyToken }] = useGetEncryptMyTokenMutation({
    onError: (error) => {
      const errorMessage = error.graphQLErrors?.at(0)?.message;
      const isPasswordError = errorMessage && errorMessage.indexOf('password') > 0;
      if (isPasswordError) {
        setEncryptTokenError('Login is not possible because the client account has not been activated yet.');
      } else {
        setEncryptTokenError(errorMessage);
      }
    },
  });

  const fetchEncryptToken = async () => {
    const result = await encryptMyToken();
    setEncryptToken(result.data);
  };

  useEffect(() => {
    fetchEncryptToken();
  }, []);

  const url = encryptToken?.EncryptMyToken?.headToURL;
  const token = encodeURIComponent(encryptToken?.EncryptMyToken?.accessToken);

  const handleLoginAsClient = async () => {
    if (!url || !token) {
      message.error(encryptTokenError);
      return;
    }
    window.open(`${url}?token=${token}&userId=${userId}`, '_blank', 'noreferrer');

    trackCustomerIoEvent(CUSTOMER_IO_EVENTS.CLICK, {
      Title: 'Login as client',
      Source: 'Button click',
      url: window.location.href,
    });
  };

  return (
    <Button
      type="link"
      // size="small"
      icon={<UserOutlined />}
      iconDirection="left"
      onClick={() => handleLoginAsClient()}
    >
      Log in as client
    </Button>
  );
};

export default LoginAsClientButton;
