import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateConditionalsMutationVariables = Types.Exact<{
  createConditionalsInput: Array<Types.CreateConditionalInput> | Types.CreateConditionalInput;
}>;


export type CreateConditionalsMutation = { __typename?: 'Mutation', createConditionals: Array<{ __typename?: 'ConditionalEntity', id: string, sectionId: string, optionId: string, createAt: any, updateAt?: any | null }> };

export type UpdateConditionalsMutationVariables = Types.Exact<{
  updateConditionalInput: Array<Types.UpdateConditionalInput> | Types.UpdateConditionalInput;
}>;


export type UpdateConditionalsMutation = { __typename?: 'Mutation', updateConditionals: Array<{ __typename?: 'ConditionalEntity', id: string, sectionId: string, optionId: string, createAt: any, updateAt?: any | null }> };


export const CreateConditionalsDocument = gql`
    mutation CreateConditionals($createConditionalsInput: [CreateConditionalInput!]!) {
  createConditionals(createConditionalsInput: $createConditionalsInput) {
    id
    sectionId
    optionId
    createAt
    updateAt
  }
}
    `;
export type CreateConditionalsMutationFn = Apollo.MutationFunction<CreateConditionalsMutation, CreateConditionalsMutationVariables>;

/**
 * __useCreateConditionalsMutation__
 *
 * To run a mutation, you first call `useCreateConditionalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConditionalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConditionalsMutation, { data, loading, error }] = useCreateConditionalsMutation({
 *   variables: {
 *      createConditionalsInput: // value for 'createConditionalsInput'
 *   },
 * });
 */
export function useCreateConditionalsMutation(baseOptions?: Apollo.MutationHookOptions<CreateConditionalsMutation, CreateConditionalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConditionalsMutation, CreateConditionalsMutationVariables>(CreateConditionalsDocument, options);
      }
export type CreateConditionalsMutationHookResult = ReturnType<typeof useCreateConditionalsMutation>;
export type CreateConditionalsMutationResult = Apollo.MutationResult<CreateConditionalsMutation>;
export type CreateConditionalsMutationOptions = Apollo.BaseMutationOptions<CreateConditionalsMutation, CreateConditionalsMutationVariables>;
export const UpdateConditionalsDocument = gql`
    mutation UpdateConditionals($updateConditionalInput: [UpdateConditionalInput!]!) {
  updateConditionals(updateConditionalInput: $updateConditionalInput) {
    id
    sectionId
    optionId
    createAt
    updateAt
  }
}
    `;
export type UpdateConditionalsMutationFn = Apollo.MutationFunction<UpdateConditionalsMutation, UpdateConditionalsMutationVariables>;

/**
 * __useUpdateConditionalsMutation__
 *
 * To run a mutation, you first call `useUpdateConditionalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConditionalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConditionalsMutation, { data, loading, error }] = useUpdateConditionalsMutation({
 *   variables: {
 *      updateConditionalInput: // value for 'updateConditionalInput'
 *   },
 * });
 */
export function useUpdateConditionalsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConditionalsMutation, UpdateConditionalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConditionalsMutation, UpdateConditionalsMutationVariables>(UpdateConditionalsDocument, options);
      }
export type UpdateConditionalsMutationHookResult = ReturnType<typeof useUpdateConditionalsMutation>;
export type UpdateConditionalsMutationResult = Apollo.MutationResult<UpdateConditionalsMutation>;
export type UpdateConditionalsMutationOptions = Apollo.BaseMutationOptions<UpdateConditionalsMutation, UpdateConditionalsMutationVariables>;
export const namedOperations = {
  Mutation: {
    CreateConditionals: 'CreateConditionals',
    UpdateConditionals: 'UpdateConditionals'
  }
}