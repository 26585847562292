import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { ModalProvider } from '~src/components/atoms/UpgradeModal/ModalProvider';
import store from './src/redux/store';
import App from './src/App';
import client from './src/graphql/client';
import './src/internationalization/i18n';

const persistor = persistStore(store);

Sentry.init({
  dsn: process.env.SENTRY_DNS,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay({ maskAllText: false })],
  // Performance Monitoring
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in dev and then sample at a lower rate in prod.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.NODE_ENV,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ModalProvider>
            <App />
          </ModalProvider>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('app'),
);
