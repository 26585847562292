/* eslint-disable */
const notify = (title: string, body: string) => {
  const img = require('../../assets/image/taxaroo-logo.png');

  // Let's check if the browser supports notifications
  if (!('Notification' in window)) {
    console.log('This browser does not support desktop notification');
  } else if (Notification.permission === 'granted') {
    // If it's okay let's create a notification
    new Notification(title, {
      body,
      icon: img,
    });
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        new Notification(title, { body, icon: img });
      }
    });
  }
};

export default notify;
