import React from 'react';
import {
  ButtonProps,
  Typography as AntdTypo,
  Button as AntButton,
} from 'antd';
import { TextProps } from 'antd/lib/typography/Text';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';
import { TitleProps } from 'antd/lib/typography/Title';
import { LinkProps } from 'antd/lib/typography/Link';

interface TypographyProps {
  /**
   * Small size text
   */
  small?: boolean
}

const Text: React.FC<TypographyProps&TextProps> = ({
  className,
  small,
  ...rest
}) => (
  <AntdTypo.Text
    className={[
      className,
      'tax-typography',
      small && 'small',
    ].filter(Boolean).join(' ')}
    {...rest}
  />
);

const Paragraph: React.FC<TypographyProps&ParagraphProps> = ({
  className,
  small,
  ...rest
}) => (
  <AntdTypo.Paragraph
    className={[
      className,
      'tax-typography',
      small && 'small',
    ].filter(Boolean).join(' ')}
    {...rest}
  />
);

const Title: React.FC<TitleProps> = ({ className, ...rest }) => (
  <AntdTypo.Title
    className={[
      className,
      'tax-typography',
    ].filter(Boolean).join(' ')}
    {...rest}
  />
);

const Link: React.FC<LinkProps> = ({ className, ...rest }) => (
  <AntdTypo.Link
    className={[
      className,
      'tax-typography',
    ].filter(Boolean).join(' ')}
    {...rest}
  />
);

const Button: React.FC<ButtonProps> = ({
  className,
  icon,
  children,
  ...rest
}) => (
  <AntButton
    type="text"
    className={[
      className,
      'tax-typography',
    ].filter(Boolean).join(' ')}
    {...rest}
  >
    {children}
    {icon && (
    <span style={{ marginLeft: 10 }}>
      {icon}
    </span>
    )}
  </AntButton>
);

/**
 * Typography component for text display
 */
const Typography = ({
  className,
  small = false,
  ...rest
}: TypographyProps&ParagraphProps) => (
  <AntdTypo.Paragraph
    className={[
      className,
      'tax-typography',
      small && 'small',
    ].filter(Boolean).join(' ')}
    {...rest}
  />
);

Typography.Text = Text;
Typography.Paragraph = Paragraph;
Typography.Title = Title;
Typography.Button = Button;
Typography.Link = Link;

export default Typography;
