import { FC } from 'react';
import { Progress } from 'taxaroo-ui';
import * as style from './style.module.css';

const colors = {
  normal: '#1754a2',
  success: '#52c41a',
};

const getPercent = (total: number, completed: number) => Math.ceil((100 / total) * completed);

interface ProgressBarProps {
  total: number;
  completed: number;
}

const ProgressBar: FC<ProgressBarProps> = ({ completed, total }) => (
  <div className={style.onBoardingBar}>
    <span>{`${completed}/${total} complete`}</span>
    <Progress
      percent={getPercent(total, completed)}
      showInfo={false}
      strokeColor={getPercent(total, completed) >= 100 ? colors.success : colors.normal}
    />
  </div>
);

export default ProgressBar;
