import { useContext } from 'react';
import { Icons } from 'taxaroo-ui';
import ActionButton from '~src/components/molecules/ActionButton';
import { JobContext } from '~src/components/providers/job-provider';
import { useDeleteClientRequestedFileMutation } from '~src/graphql/mutations/clients';
import { GetAllFilesByClientDocument, GetClientFilesFromDbDocument } from '~src/graphql/queries/clients';
import { handleOnErrorDeleteClientFile, onSuccessDeleteClientFile } from './utils';

const { DeleteOutlined } = Icons;

interface DeleteClientRequestedFileButtonProps {
  id: string;
  taxYearInterviewId: string;
}

const DeleteClientRequestedFileButton = ({
  id, taxYearInterviewId,
}: DeleteClientRequestedFileButtonProps) => {
  const jobContext = useContext(JobContext);
  const [deleteClientRequestedFile] = useDeleteClientRequestedFileMutation({
    variables: {
      id,
    },
    refetchQueries: [
      {
        query: GetClientFilesFromDbDocument,
        variables: {
          taxYearInterviewId: taxYearInterviewId || jobContext?.taxYearInterviewId,
          userId: jobContext?.entityOwnerId,
        },
      },
      {
        query: GetAllFilesByClientDocument,
        variables: {
          userId: jobContext?.entityOwnerId,
        },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: onSuccessDeleteClientFile,
    onError: handleOnErrorDeleteClientFile,
  });

  const handleDeleteRequestedFile = async () => {
    await deleteClientRequestedFile();
  };

  return (
    <ActionButton
      tooltip="Delete file request"
      confirmAction
      confirmLabel="Are you sure?"
      onClick={() => handleDeleteRequestedFile()}
    >
      <DeleteOutlined style={{ color: 'red' }} />
    </ActionButton>
  );
};

export default DeleteClientRequestedFileButton;
