import { SelectProps, Select as AntSelect } from 'antd';
import { OptionProps } from 'antd/lib/mentions';
import { RefSelectProps } from 'antd/lib/select';
import React, { Ref } from 'react';

// const { Option } = AntSelect;

const Option: React.FC<SelectProps<any>&OptionProps> = ({
  className,
  ...rest
}) => (
  <AntSelect.Option
    className={[
      className,
      // 'tax-option',
    ].filter(Boolean).join(' ')}
    {...rest}
  />
);

export interface TaxSelectProps extends SelectProps<any> {
  ref?: Ref<RefSelectProps>;
}

const Select: React.FC<TaxSelectProps>&{
  Option: React.FC<OptionProps>
} = ({
  ref,
  className,
  ...rest
}) => (
  <AntSelect
    {...rest}
    ref={ref}
    className={[
      className,
    ].filter(Boolean).join(' ')}
  />
);

Select.Option = Option;
export default Select;
