import React, {
  FC, useCallback, useEffect, useRef, useState,
} from 'react';
import { QuestionType } from '~src/graphql';
import InterviewSection from '~src/components/molecules/InterviewSection';
import { Conditional as ConditionalType, Language, Operations } from '~src/components/templates/Interviews';

export interface Option {
  id?: string;
  label: string;
  temporalId?: string;
  questionId?: string;
  operation?: Operations;
  temporalQuestionId?: string;
}

export interface Question {
  id?: string;
  label: string;
  order: number;
  required: boolean;
  sectionId?: string;
  type: QuestionType;
  temporalId?: string;
  operation?: Operations;
  Options?: Array<Option>;
  temporalSectionId?: string;
}

export interface Section {
  id?: string;
  name: string;
  order: number;
  parentId?: string;
  isSpecial: boolean;
  temporalId?: string;
  operation?: Operations;
  temporalParentId?: string;
  Questions: Array<Question>;
  SubSections: Array<Section>;
  Conditionals?: Array<ConditionalType>;
}

interface InterviewSectionListProps {
  sections: Array<Section>;
  onAddConditional: (section: Section, index: number) => void;
  onSectionsChange: (newSections: Array<Section>) => void;
  interviewLanguage: Language;
}

const InterviewSectionList: FC<InterviewSectionListProps> = ({
  sections,
  onAddConditional,
  onSectionsChange,
  interviewLanguage,
}) => {
  const firstRender = useRef<boolean>(true);
  const [data, setData] = useState<Array<Section>>(sections);
  const onSectionsChangeRef = useRef<(newSections: Array<Section>) => void>();

  onSectionsChangeRef.current = onSectionsChange;

  const swapArrayElementPosition = (arr, fromIndex: number, toIndex: number) => {
    if (arr.length === 1) return arr;

    const element = arr.splice(fromIndex, 1)[0];
    arr.splice(toIndex, 0, element);

    return arr;
  };

  const moveListItem = useCallback(
    (dragIndex, hoverIndex) => {
      setData((prevData) => {
        const updatedData = swapArrayElementPosition(prevData, dragIndex, hoverIndex);

        return updatedData;
      });
    },
    [data],
  );

  const onDragEndHandler = ({ dragIndex, hoverIndex }:
    { dragIndex: number, hoverIndex: number }) => {
    moveListItem(dragIndex, hoverIndex);
  };

  const onQuestionDragEndHandler = ({ dragIndex, hoverIndex }:
    { dragIndex: number, hoverIndex: number }, sectionIndex: number) => {
    setData(data.map((section, secIndex) => (sectionIndex === secIndex ? {
      ...section,
      Questions: swapArrayElementPosition(section.Questions, dragIndex, hoverIndex),
    } : section)));
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setData(sections);
    }
  }, [sections]);

  return (
    <>
      {
        data.map(({
          id, name, Conditionals, Questions, SubSections, temporalId, parentId, temporalParentId,
          isSpecial,
        }, index) => {
          const handleSubSectionsChange = (newSubSections: Array<Section>) => {
            const currentData = [...data];

            currentData[index].SubSections = newSubSections;
            currentData[index].operation = currentData[index].operation || 'update';

            setData(currentData);
            onSectionsChangeRef.current(currentData);
          };

          const handleQuestionsChange = (newQuestions: Array<Question>) => {
            const currentData = [...data];

            currentData[index].Questions = newQuestions;
            currentData[index].operation = currentData[index].operation || 'update';

            onSectionsChangeRef.current(currentData);
            setData(currentData);
          };

          const handleDeleteSection = (indexTarget: number) => {
            const mapDeletesCascade = (section: Section): Section => ({
              ...section,
              operation: 'delete',
              Questions: section.Questions.map((question) => ({
                ...question,
                operation: 'delete',
                Options: question.Options?.map((option) => ({
                  ...option,
                  operation: 'delete',
                })) ?? [],
              })),
              SubSections: section.SubSections?.map(
                (subSection) => mapDeletesCascade(subSection),
              ) ?? [],
            });

            setData(
              (prevData) => {
                const newData = [...prevData];

                if (newData[indexTarget].id) {
                  newData[indexTarget] = mapDeletesCascade(newData[indexTarget]);
                } else {
                  newData.splice(indexTarget, 1);
                }

                onSectionsChangeRef.current(newData);
                return newData;
              },
            );
          };

          const handleSectionNameChange = (newName: string) => {
            const currentData = [...data];

            currentData[index].name = newName;
            if (currentData[index].id && !currentData[index].operation) {
              currentData[index].operation = 'update';
            }

            onSectionsChangeRef.current(currentData);
            setData(currentData);
          };

          const handleAddConditionalClick = (section) => {
            onAddConditional(section, index);
          };

          return (
            <InterviewSection
              id={id}
              index={index}
              sectionName={name}
              parentId={parentId}
              Questions={Questions}
              disabled={!!isSpecial}
              key={id ?? temporalId}
              temporalId={temporalId}
              Conditionals={Conditionals}
              SubSections={SubSections ?? []}
              temporalParentId={temporalParentId}
              onSectionDragEnd={onDragEndHandler}
              onDeleteSection={handleDeleteSection}
              onQuestionsChange={handleQuestionsChange}
              onQuestionDragEnd={onQuestionDragEndHandler}
              onSectionNameChange={handleSectionNameChange}
              onSubSectionsChange={handleSubSectionsChange}
              onAddConditionalClick={handleAddConditionalClick}
              interviewLanguage={interviewLanguage}
            />
          );
        })
      }
    </>
  );
};

export default React.memo(InterviewSectionList);
