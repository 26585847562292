.chatAdminContainer {
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 10px;
  padding: 0 15px;
  box-shadow: 0 5px 5px 5px #f2f2f2;
  min-width: 270px;
  position: relative;
}

.chatAdminHeader {
  display: flex;
  padding: 12px 12px 12px 0;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
}

.chatAdminBadge sup {
  width: 10px;
  height: 10px;
  top: 45px;
}

.infocont {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  border-bottom: 1px solid #d8d8d8;
  margin-top: 43px;
  padding-bottom: 16px;
}

.infotextcont {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.name {
  color: #0a64b2;
  margin: 0 !important;
  font-size: 20px;
  font-weight: 600;
}

.organization {
  color: #565656;
  font-size: 14px;
  margin: 0;
}

.active {
  color: #969696;
  font-size: 12px;
  margin: 0;
}

.contact {
  color: #0a64b2;
  margin: 0;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-device-width: 700px) {
  .chatAdminContainer {
    margin: 10px 0 0;
    min-height: 50vh;
    max-height: 50vh;
  }
}
