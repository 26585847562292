import { CustomDomainStatuses } from '~src/graphql';
import store from '../../redux/store';
import config from './config';

const getUserFromSession = () => {
  const user = store.getState().session;
  return {
    id: user?.userId,
    email: user?.email,
    FirstName: user?.firstName,
    LastName: user?.lastName,
    FullName: user?.firstName ? `${user?.firstName} ${user?.lastName}` : undefined,
    Role: user?.userRole,
    FirmAccountId: user?.firmAccountId,
  };
};

// identify, page, track, reset, on, off
const callCustomerIoMethod = (methodName: string, params: any, trackEventName?: string) => {
  if (config.env !== 'production') return undefined;

  try {
    // eslint-disable-next-line
    const _cio = window['_cio'];
    if (_cio) {
      if (trackEventName) {
        return _cio[methodName](trackEventName, { ...params, env: config.env });
      }
      return _cio[methodName]({ ...params, env: config.env });
    }
    console.warn('_cio is not defined');
  } catch (e) {
    console.log('_cio error', e);
  }

  return undefined;
};

enum CUSTOMER_IO_EVENTS {
  SING_IN = 'Sign In',
  SIGN_OUT = 'Sign Out',
  TAB_SELECT = 'Tab Select',
  JOB_DETAILS = 'Job Details',
  MODAL_SHOW = 'Modal Show',
  CLICK = 'Click Action',
  REGISTER = 'Register',
}

const trackCustomerIoEvent = (eventName: string, params: any) => callCustomerIoMethod('track', params, eventName);

const trackSignIn = () => {
  const identity = getUserFromSession();
  callCustomerIoMethod('identify', identity);
  trackCustomerIoEvent(CUSTOMER_IO_EVENTS.SING_IN, identity);
};

const trackSignOut = () => {
  const identity = getUserFromSession();
  callCustomerIoMethod('identify', identity);
  trackCustomerIoEvent(CUSTOMER_IO_EVENTS.SIGN_OUT, identity);
  callCustomerIoMethod('reset', identity);
};

const setIdentityFromStorage = () => {
  const identity = getUserFromSession();
  callCustomerIoMethod('identify', identity);
};

const trackPageView = () => {
  callCustomerIoMethod('page', {}, window.location.href);
};

const trackPlanType = (planType: string) => {
  const identity = getUserFromSession();
  callCustomerIoMethod('identify', { ...identity, PlanType: planType });
};

const trackFinishedOnboarding = (finishedOnboarding: boolean) => {
  const identity = getUserFromSession();
  callCustomerIoMethod('identify', { ...identity, StripeFinishedOnboarding: finishedOnboarding });
};

const trackCustomDomain = (name: string, status: CustomDomainStatuses) => {
  const identity = getUserFromSession();
  callCustomerIoMethod('identify', {
    ...identity,
    CustomDomainURL: name || null, // to track users without custom domains
    CustomDomainStatus: status,
  });
};

const trackRegister = (
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  role: string,
  firmAccountId: string,
) => {
  const identity = {
    id,
    email,
    FirstName: firstName,
    LastName: lastName,
    FullName: firstName ? `${firstName} ${lastName}` : undefined,
    Role: role,
    FirmAccountId: firmAccountId,
    created_at: Math.round(new Date().getTime() / 1e3),
  };

  callCustomerIoMethod('identify', identity);
  trackCustomerIoEvent(CUSTOMER_IO_EVENTS.REGISTER, identity);
};

export {
  CUSTOMER_IO_EVENTS, trackCustomerIoEvent, trackSignIn, trackSignOut, trackCustomDomain,
  setIdentityFromStorage, trackPageView, trackPlanType, trackFinishedOnboarding, trackRegister,
};
