import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRequestedFilesMutationVariables = Types.Exact<{
  createRequestedFilesInput: Array<Types.CreateRequestedFilesInput> | Types.CreateRequestedFilesInput;
}>;


export type CreateRequestedFilesMutation = { __typename?: 'Mutation', CreateManyRequestedFiles: string };


export const CreateRequestedFilesDocument = gql`
    mutation createRequestedFiles($createRequestedFilesInput: [CreateRequestedFilesInput!]!) {
  CreateManyRequestedFiles(createRequestedFilesInput: $createRequestedFilesInput)
}
    `;
export type CreateRequestedFilesMutationFn = Apollo.MutationFunction<CreateRequestedFilesMutation, CreateRequestedFilesMutationVariables>;

/**
 * __useCreateRequestedFilesMutation__
 *
 * To run a mutation, you first call `useCreateRequestedFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestedFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestedFilesMutation, { data, loading, error }] = useCreateRequestedFilesMutation({
 *   variables: {
 *      createRequestedFilesInput: // value for 'createRequestedFilesInput'
 *   },
 * });
 */
export function useCreateRequestedFilesMutation(baseOptions?: Apollo.MutationHookOptions<CreateRequestedFilesMutation, CreateRequestedFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRequestedFilesMutation, CreateRequestedFilesMutationVariables>(CreateRequestedFilesDocument, options);
      }
export type CreateRequestedFilesMutationHookResult = ReturnType<typeof useCreateRequestedFilesMutation>;
export type CreateRequestedFilesMutationResult = Apollo.MutationResult<CreateRequestedFilesMutation>;
export type CreateRequestedFilesMutationOptions = Apollo.BaseMutationOptions<CreateRequestedFilesMutation, CreateRequestedFilesMutationVariables>;
export const namedOperations = {
  Mutation: {
    createRequestedFiles: 'createRequestedFiles'
  }
}