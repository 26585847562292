import { FC } from 'react';
import { Button } from 'taxaroo-ui';
import * as styles from './style.module.css';

interface AvailabilityModalProps {
    startHour: string
    endHour: string
    del: any
}

const AvailabilityModal: FC<AvailabilityModalProps> = ({
  startHour,
  endHour,
  del,
}) => (
  <div style={{ width: '100%' }}>
    <p className={styles.text}>{`Start Hour: ${startHour}`}</p>
    <p className={styles.text}>{`End Hour: ${endHour}`}</p>
    <div className={styles.divisor} />
    <Button style={{ width: '100%' }} type="link" danger onClick={del}>
      Delete
    </Button>
  </div>
);

export default AvailabilityModal;
