.cardListContainer {
  padding-top: 24px;
  max-height: 400px;
  overflow-y: scroll;
  padding-left: 0;
}

.cardItem {
  margin-left: 12px !important;
  margin-right: 12px !important;
  margin-bottom: 24px !important;
  transition: opacity, box-shadow 150ms ease !important;
}

.cardItem:active {
  opacity: 0.5;
  transition: opacity 100ms ease-in-out !important;
}
