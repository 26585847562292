import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarkFileApprovalsCompleteMutationVariables = Types.Exact<{
  fileRequestId: Types.Scalars['String'];
}>;


export type MarkFileApprovalsCompleteMutation = { __typename?: 'Mutation', MarkFileApprovalsComplete: { __typename?: 'FileRequestEntity', id: string, userId?: string | null, notes?: string | null, title?: string | null, signatureRequest?: any | null, signatureComplete?: any | null, createAt: any, updateAt: any, taxYearInterviewId: string, FileApproval?: Array<{ __typename?: 'FileApprovalEntity', id: string, expiresAt: any, taskId?: string | null, completedAt?: any | null, createAt: any, updateAt: any }> | null, CompiledFile?: { __typename?: 'FileEntity', s3Key?: string | null } | null, SignedFile?: { __typename?: 'FileEntity', s3Key?: string | null } | null } };

export type ResendFileRequestMutationVariables = Types.Exact<{
  fileRequestId: Types.Scalars['String'];
}>;


export type ResendFileRequestMutation = { __typename?: 'Mutation', ResendFileRequest: { __typename?: 'FileRequestEntity', id: string } };

export type RemoveFileRequestMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type RemoveFileRequestMutation = { __typename?: 'Mutation', RemoveFileRequest: { __typename?: 'FileRequestEntity', id: string } };

export type UpdateApprovalSignatureRequestMutationVariables = Types.Exact<{
  input: Types.UpdateApprovalSignatureRequestInput;
}>;


export type UpdateApprovalSignatureRequestMutation = { __typename?: 'Mutation', updateApprovalSignatureRequest: { __typename?: 'FileRequestEntity', id: string, userId?: string | null, notes?: string | null, title?: string | null, signatureRequest?: any | null, signatureComplete?: any | null, createAt: any, updateAt: any, taxYearInterviewId: string } };


export const MarkFileApprovalsCompleteDocument = gql`
    mutation MarkFileApprovalsComplete($fileRequestId: String!) {
  MarkFileApprovalsComplete(fileRequestId: $fileRequestId) {
    id
    userId
    notes
    title
    signatureRequest
    signatureComplete
    createAt
    updateAt
    taxYearInterviewId
    FileApproval {
      id
      expiresAt
      taskId
      completedAt
      createAt
      updateAt
    }
    CompiledFile {
      s3Key
    }
    SignedFile {
      s3Key
    }
  }
}
    `;
export type MarkFileApprovalsCompleteMutationFn = Apollo.MutationFunction<MarkFileApprovalsCompleteMutation, MarkFileApprovalsCompleteMutationVariables>;

/**
 * __useMarkFileApprovalsCompleteMutation__
 *
 * To run a mutation, you first call `useMarkFileApprovalsCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkFileApprovalsCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markFileApprovalsCompleteMutation, { data, loading, error }] = useMarkFileApprovalsCompleteMutation({
 *   variables: {
 *      fileRequestId: // value for 'fileRequestId'
 *   },
 * });
 */
export function useMarkFileApprovalsCompleteMutation(baseOptions?: Apollo.MutationHookOptions<MarkFileApprovalsCompleteMutation, MarkFileApprovalsCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkFileApprovalsCompleteMutation, MarkFileApprovalsCompleteMutationVariables>(MarkFileApprovalsCompleteDocument, options);
      }
export type MarkFileApprovalsCompleteMutationHookResult = ReturnType<typeof useMarkFileApprovalsCompleteMutation>;
export type MarkFileApprovalsCompleteMutationResult = Apollo.MutationResult<MarkFileApprovalsCompleteMutation>;
export type MarkFileApprovalsCompleteMutationOptions = Apollo.BaseMutationOptions<MarkFileApprovalsCompleteMutation, MarkFileApprovalsCompleteMutationVariables>;
export const ResendFileRequestDocument = gql`
    mutation ResendFileRequest($fileRequestId: String!) {
  ResendFileRequest(fileRequestId: $fileRequestId) {
    id
  }
}
    `;
export type ResendFileRequestMutationFn = Apollo.MutationFunction<ResendFileRequestMutation, ResendFileRequestMutationVariables>;

/**
 * __useResendFileRequestMutation__
 *
 * To run a mutation, you first call `useResendFileRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendFileRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendFileRequestMutation, { data, loading, error }] = useResendFileRequestMutation({
 *   variables: {
 *      fileRequestId: // value for 'fileRequestId'
 *   },
 * });
 */
export function useResendFileRequestMutation(baseOptions?: Apollo.MutationHookOptions<ResendFileRequestMutation, ResendFileRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendFileRequestMutation, ResendFileRequestMutationVariables>(ResendFileRequestDocument, options);
      }
export type ResendFileRequestMutationHookResult = ReturnType<typeof useResendFileRequestMutation>;
export type ResendFileRequestMutationResult = Apollo.MutationResult<ResendFileRequestMutation>;
export type ResendFileRequestMutationOptions = Apollo.BaseMutationOptions<ResendFileRequestMutation, ResendFileRequestMutationVariables>;
export const RemoveFileRequestDocument = gql`
    mutation RemoveFileRequest($id: String!) {
  RemoveFileRequest(id: $id) {
    id
  }
}
    `;
export type RemoveFileRequestMutationFn = Apollo.MutationFunction<RemoveFileRequestMutation, RemoveFileRequestMutationVariables>;

/**
 * __useRemoveFileRequestMutation__
 *
 * To run a mutation, you first call `useRemoveFileRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFileRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFileRequestMutation, { data, loading, error }] = useRemoveFileRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFileRequestMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFileRequestMutation, RemoveFileRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFileRequestMutation, RemoveFileRequestMutationVariables>(RemoveFileRequestDocument, options);
      }
export type RemoveFileRequestMutationHookResult = ReturnType<typeof useRemoveFileRequestMutation>;
export type RemoveFileRequestMutationResult = Apollo.MutationResult<RemoveFileRequestMutation>;
export type RemoveFileRequestMutationOptions = Apollo.BaseMutationOptions<RemoveFileRequestMutation, RemoveFileRequestMutationVariables>;
export const UpdateApprovalSignatureRequestDocument = gql`
    mutation updateApprovalSignatureRequest($input: UpdateApprovalSignatureRequestInput!) {
  updateApprovalSignatureRequest(input: $input) {
    id
    userId
    notes
    title
    signatureRequest
    signatureComplete
    createAt
    updateAt
    taxYearInterviewId
  }
}
    `;
export type UpdateApprovalSignatureRequestMutationFn = Apollo.MutationFunction<UpdateApprovalSignatureRequestMutation, UpdateApprovalSignatureRequestMutationVariables>;

/**
 * __useUpdateApprovalSignatureRequestMutation__
 *
 * To run a mutation, you first call `useUpdateApprovalSignatureRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovalSignatureRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovalSignatureRequestMutation, { data, loading, error }] = useUpdateApprovalSignatureRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApprovalSignatureRequestMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApprovalSignatureRequestMutation, UpdateApprovalSignatureRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApprovalSignatureRequestMutation, UpdateApprovalSignatureRequestMutationVariables>(UpdateApprovalSignatureRequestDocument, options);
      }
export type UpdateApprovalSignatureRequestMutationHookResult = ReturnType<typeof useUpdateApprovalSignatureRequestMutation>;
export type UpdateApprovalSignatureRequestMutationResult = Apollo.MutationResult<UpdateApprovalSignatureRequestMutation>;
export type UpdateApprovalSignatureRequestMutationOptions = Apollo.BaseMutationOptions<UpdateApprovalSignatureRequestMutation, UpdateApprovalSignatureRequestMutationVariables>;
export const namedOperations = {
  Mutation: {
    MarkFileApprovalsComplete: 'MarkFileApprovalsComplete',
    ResendFileRequest: 'ResendFileRequest',
    RemoveFileRequest: 'RemoveFileRequest',
    updateApprovalSignatureRequest: 'updateApprovalSignatureRequest'
  }
}