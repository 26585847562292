import {
  Row,
  Col,
  Form,
  Button,
  Icons,
} from 'taxaroo-ui';

const { RightOutlined, LeftOutlined } = Icons;

interface CardNavProps {
  onBack?: () => void;
  onNext?: () => void;
  onSkip?: () => void;
  showBack?: boolean;
  showSkip?: boolean;
  disabled?: boolean;
  cancelSubtext?: () => React.ReactNode;
  nextText?: string;
  nextSubtext?: () => React.ReactNode;
  loading?: boolean;
}
function CardNav({
  onBack,
  onNext,
  onSkip,
  loading,
  showBack = true,
  showSkip = true,
  disabled = false,
  nextText = 'Next',
  nextSubtext = () => null,
  cancelSubtext = () => null,
}: CardNavProps) {
  return (
    <Row justify={!showBack ? 'start' : 'space-between'} style={{ marginTop: '5px' }}>
      {showBack
        ? (
          <Col>
            <Form.Item>
              <Button
                icon={<LeftOutlined />}
                iconDirection="left"
                type="default"
                disabled={false}
                onClick={onBack}
              >
                Back
              </Button>
            </Form.Item>
            {cancelSubtext()}
          </Col>
        )
        : <Col />}

      {showSkip && (
      <Col>
        <Form.Item>
          <Button
                // icon={<LeftOutlined />}
                // iconDirection="left"
            type="link"
            disabled={false}
            onClick={onSkip}
          >
            Skip
          </Button>
        </Form.Item>
      </Col>
      )}
      <Col>
        <Form.Item>
          <Button
            type="primary"
            loading={loading}
            htmlType="submit"
            icon={<RightOutlined />}
            disabled={disabled}
            onClick={onNext || undefined}
          >
            {nextText}
          </Button>
        </Form.Item>
        {nextSubtext()}
      </Col>
    </Row>
  );
}

CardNav.defaultProps = {
  showBack: true,
  showSkip: true,
  loading: false,
  disabled: false,
  nextText: 'Next',
  nextSubtext: () => null,
  cancelSubtext: () => null,
  onBack: () => undefined,
  onNext: () => undefined,
  onSkip: () => undefined,
};

export default CardNav;
