import { message } from 'taxaroo-ui';

const onSuccessDeleteClientFile = () => {
  message.success('File deleted');
};

const handleOnErrorDeleteClientFile = () => {
  message.error('An error has occurred while trying to delete the file. Please try again');
};

export {
  onSuccessDeleteClientFile,
  handleOnErrorDeleteClientFile,
};
