export const initialState = {
  headerBackgroundColor: '#ffffff',
  headerFontColor: '#000000',
};

export enum CustomLayoutCategory {
  HEADER_BACKGROUND_COLOR = 'headerBackgroundColor',
  HEADER_FONT_COLOR = 'headerFontColor',
  INITIAL_VALUES = 'initialValues'
}

export interface CustomLayoutState {
  headerBackgroundColor: string;
  headerFontColor: string;
}

export interface CustomLayoutPayload {
  headerBackgroundColor?: string;
  headerFontColor?: string;
}

export interface CustomLayoutAction {
  type: CustomLayoutCategory;
  payload: CustomLayoutPayload;
}

export const customLayoutReducer = (state: CustomLayoutState, action: CustomLayoutAction) => {
  switch (action.type) {
    case CustomLayoutCategory.HEADER_BACKGROUND_COLOR:
      return {
        ...state,
        headerBackgroundColor: action.payload.headerBackgroundColor,
      };
    case CustomLayoutCategory.HEADER_FONT_COLOR:
      return {
        ...state,
        headerFontColor: action.payload.headerFontColor,
      };
    case CustomLayoutCategory.INITIAL_VALUES:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
