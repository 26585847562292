import React, { useState, useRef, useEffect } from 'react';
import { Row, Alert } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import TuiCalendar, { Props } from '@toast-ui/react-calendar';
import moment from 'moment';
import Card from '../Card';
import Tabs from '../Tabs';
import Typography from '../Typography';

type CalendarType = 'month'|'week'|'day'

interface CalendarProps extends Omit<Props, 'height'> {
  // height?: string
}

const Calendar: React.FC<CalendarProps> = ({
  // height,
  style,
  // ...rest
}) => {
  const calendarRef = useRef<TuiCalendar>(null);

  const [view, setView] = useState<CalendarType>('month');
  const [date, setDate] = useState(moment());
  useEffect(() => {
    if (calendarRef.current) {
      const instance = calendarRef.current.getInstance();
      instance.setDate(date.toDate());
    }
  }, [date]);

  const prev = () => {
    const day = date.date();
    setDate(moment(date).set('date', day - (view === 'day'
      ? 1 : 7)));
  };

  const next = () => {
    const day = date.date();
    setDate(moment(date).set('date', day + (view === 'day'
      ? 1 : 7)));
  };

  // const pickerHandler = (v: Moment | null) => {
  //   if (v) {
  //     setDate(v);
  //   }
  // };

  return (
    <Card style={style}>
      <Tabs
        bordered={false}
        activeKey={view}
        onChange={(v: CalendarType) => setView(v)}
      >
        <Tabs.TabPane tab="Month" key="month" />
        <Tabs.TabPane tab="Week" key="week" />
        <Tabs.TabPane tab="Day" key="day" />
      </Tabs>
      {view === 'month' && (
      <div>
        <Row>
          {/* <DatePicker
            picker="year"
            value={date}
            onChange={pickerHandler}
            bordered={false}
            suffixIcon={<DownOutlined />}
            clearIcon={false}
            style={{ width: 80 }}
          /> */}
        </Row>
        <Row style={{ marginTop: -32 }} justify="center">
          {/* <DatePicker
            picker="month"
            value={date}
            format="MMMM"
            onChange={pickerHandler}
            bordered={false}
            suffixIcon={<DownOutlined />}
            clearIcon={false}
            style={{ width: 120 }}
          /> */}
        </Row>
      </div>
      )}
      {(view === 'week' || view === 'day') && (
        <div style={{ textAlign: 'center' }}>
          <Typography.Title level={3}>
            {moment(date).format('MMMM')}
          </Typography.Title>
          <div>
            <Typography.Text type="secondary">
              <LeftOutlined
                onClick={prev}
              />
              {' '}
              <span style={{ minWidth: 120, display: 'inline-block' }}>
                {view === 'week' && (
                  `${moment(date).startOf('week').date()}
                  - ${moment(date).endOf('week').date()}`
                )}
                {view === 'day' && moment(date).format('dddd D')}
              </span>
              {' '}
              <RightOutlined
                onClick={next}
              />
            </Typography.Text>
          </div>
        </div>
      )}
      <Alert
        message={`You selected date ${date.toDate().toDateString()}`}
        style={{ marginBottom: 16 }}
      />
      {/* <TuiCalendar
        {...rest}
        ref={calendarRef}
        height={height || '500'}
        taskView={false}
        view={view}
        month={{
          daynames: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        }}
        theme={{
          'common.border': 'none',
          'common.holiday.color': 'rgba(0, 0, 0, 0.5)',
          'common.saturday.color': 'rgba(0, 0, 0, 0.5)',
          'common.dayname.color': 'rgba(0, 0, 0, 0.5)',

          'month.holidayExceptThisMonth.color': 'rgba(51, 51, 51, 0.4)',
          'month.dayname.borderLeft': 'none',
          'month.dayname.textAlign': 'right',
          'month.dayname.fontSize': '16px',
          'month.day.fontSize': '14px',

          'week.dayname.textAlign': 'right',
        }}
        isReadOnly
      /> */}
    </Card>
  );
};

export default Calendar;
