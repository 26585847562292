.errorInput {
  border: 1px solid red;
  border-radius: 10px;
  overflow: hidden;
}

.extraPadding {
  padding: 10px;
}

.inputContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.input {
  padding: 3px 10px;
  background: #f5f4f5;
  border: none !important;
  outline: none;
  width: 100%;
  border-radius: 10px;
}

.selectInput {
  flex: 1;
}

.requiredInputField {
  color: red;
}

.inputAddon {
  border-radius: 10px 0 0 10px !important;
}

.innerContainer {
  display: flex;
}

.addon {
  background: #fbfafb;
  border: 1px solid #dcdddc;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  border-radius: 0 10px 10px 0;
}
