import moment from 'moment';
import { FC } from 'react';
import { Avatar, Badge, Typography } from 'taxaroo-ui';
import { useAppSelector } from '~src/redux/hooks';
import { ChannelsState } from '~src/redux/slices/chatSlice';
import { selectSession } from '~src/redux/slices/sessionSlice';
import UseChannels from '~src/components/hooks/useChannels';
import * as styles from './style.module.css';

const { Text } = Typography;

const getAcronim = (str: string) => {
  const matches = str.match(/\b(\w)/g);
  const acronym = matches?.join('');
  return acronym?.substring(0, 2).toUpperCase();
};

interface ChatChannelProps {
  selected: boolean,
  data: ChannelsState,
  setChannel: (channelId: string) => void,
}

const ChatChannel: FC<ChatChannelProps> = ({
  data, setChannel, selected,
}) => {
  const { userId: currentUserId } = useAppSelector(selectSession);
  const { ChannelMembers, status } = data;
  const { messages: messagesObj } = UseChannels(false);
  const messages = messagesObj[data.id] || [];

  const date = () => {
    if (messages.length <= 0) return '';
    if (!messages[messages.length - 1]) return '';

    const { createAt } = messages[messages.length - 1];
    const dateMoment = moment(createAt);
    return dateMoment.fromNow();
  };

  const lastMessage = () => {
    if (messages.length <= 0) return '';
    if (!messages[messages.length - 1]) return '';

    const { text } = messages[messages.length - 1];
    if (text === null) {
      return '';
    } if (text.includes('#{meetingId}=')) {
      return '#Incoming meeting';
    }
    return text.split('~#*')[0];
  };

  const getStatusConnection = () => (status ? 'green' : 'gray');

  const selectChannel = () => {
    setChannel(data.id);
  };

  const getChatName = () => {
    const otherMember = ChannelMembers.find(({ userId }) => userId !== currentUserId);

    if (otherMember) {
      const { firstName, lastName } = otherMember.Users.UserInformation || {};

      if (`${firstName} ${lastName}` !== data.description) {
        return `${firstName} ${lastName}`;
      }

      return data.description;
    }

    return '';
  };

  return (
    <div
      className={`${styles.chatChannelContainer} ${selected && styles.selected}`}
      onClick={selectChannel}
      onKeyDown={selectChannel}
      role="button"
      tabIndex={0}
    >
      <Badge dot className={styles.chatChannelBadge} color={getStatusConnection()}>
        <Avatar style={{ backgroundColor: '#22A7E7' }} size={50}>
          {getAcronim(getChatName())}
        </Avatar>
      </Badge>

      <div className={styles.containerData}>
        <Text className={styles.name}>{getChatName()}</Text>
        <div className={styles.last}>
          <Text
            ellipsis
            className={styles.lastMessage}
            style={{
              ...(messages[messages.length - 1]?.userId !== currentUserId
                && !messages[messages.length - 1]?.readDate ? {
                  fontWeight: 'bold',
                } : {}),
            }}
          >
            {lastMessage()}
          </Text>
        </div>
      </div>
      <Text className={styles.date}>{date()}</Text>
    </div>
  );
};

export default ChatChannel;
