import { useEffect } from 'react';
import {
  Row, Typography, message,
} from 'taxaroo-ui';
import { useSearchParams } from 'react-router-dom';
import { useCreateBillingPortalSessionMutation } from '~src/graphql/mutations/stripeInformation';
import LoadingIndicator from '~src/components/atoms/LoadingIndicator';
import { BillingPortalSessionFlowDataInput } from '~src/graphql';
import * as styles from './style.module.css';

const { Title } = Typography;

function BillingPortalRedirect() {
  // get customer id from url
  const [searchParams] = useSearchParams();
  const customer = searchParams.get('customer');
  const type = searchParams.get('type');
  const subscription = searchParams.get('subscription');

  let flowData: BillingPortalSessionFlowDataInput;
  switch (type) {
    case 'addFirstPaymentMethod':
      flowData = {
        type: 'payment_method_update',
        after_completion: {
          type: 'hosted_confirmation',
          // todo
          hosted_confirmation: {
            custom_message: 'Your payment method has been successfully added! Update to our yearly plan and save 16.7% per year.',
          },
        },
      };
      break;
    case 'updatePaymentMethod':
      flowData = {
        type: 'payment_method_update',
      };
      break;
    case 'updatePlan':
      if (subscription) {
        flowData = {
          type: 'subscription_update',
          subscription_update: {
            subscription,
          },
        };
      }
      break;
    case 'cancelPlan':
      if (!subscription) {
        message.error('Subscription id is required to cancel plan');
      } else {
        flowData = {
          type: 'subscription_cancel',
          subscription_cancel: {
            subscription,
          },
        };
      }
      break;
    default:
      break;
  }

  // graphql
  // Create billing portal session mutation
  const [createBillingPortalSession, { data: portalSessionData }] = useCreateBillingPortalSessionMutation({
    variables: {
      createBillingPortalInput: {
        customer,
        flow_data: flowData,
      },
    },
    onError: (err) => {
      message.error(err.message);
    },
  });

  useEffect(() => {
    createBillingPortalSession();
  }, [customer]);

  if (portalSessionData?.createBillingPortalSession) {
    window.location.href = portalSessionData.createBillingPortalSession;
  }

  return (
    <div className={styles['billing-redirect-page']}>
      <div className={styles['billing-redirect-wrapper']}>
        <Row justify="center">
          <LoadingIndicator
            size="calc(100vh)"
            type="full"
            id="billing_portal_redirect"
            style={{ marginTop: 'calc(50vh * -1)' }}
          />
        </Row>
        <Row
          justify="center"
          style={{ marginTop: 'calc(50vh * -1)', zIndex: 999 }}
        >
          <Title level={3}>
            You are being redirected to our secure billing portal...
          </Title>
        </Row>
      </div>
    </div>
  );
}

export default BillingPortalRedirect;
