import { message } from 'taxaroo-ui';

const onSuccessMutation = () => {
  message.success('Success!');
};

const onSuccessUploadFile = () => {
  message.success('File uploaded');
};

const handleOnError = () => {
  message.error('An error has occurred');
};

const handleOnUploadFileToDBError = () => {
  message.error('An error ocurred while uploading to our DB');
};

const handleOnLoadClientFilesError = () => {
  message.error('An error ocurred while loading client files');
};

const handleOnGetPresignedURLS3Error = () => {
  message.error('An error ocurred while trying to upload file to S3');
};

const handleOnFileTypeNotSupported = () => {
  message.error('The document of this type is not allowed. You can upload PDFs, texts and images only.');
};

const handleFileExceedsSize = () => {
  message.error('File size exceeds 25MB');
};

export {
  handleOnError,
  handleOnGetPresignedURLS3Error,
  handleOnUploadFileToDBError,
  handleOnLoadClientFilesError,
  handleOnFileTypeNotSupported,
  onSuccessMutation,
  onSuccessUploadFile,
  handleFileExceedsSize,
};
