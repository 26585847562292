import { FC, useEffect, useState } from 'react';
import {
  Typography, Row, Col, Tooltip, Input, Space, Icons, Grid, Spin, Button,
} from 'taxaroo-ui';
import * as styles from './style.module.css';

const { SaveOutlined, SyncOutlined, ApiOutlined } = Icons;
const { Title, Text } = Typography;

interface InviteRowProps {
  title: string;
  subtitle: string;
  baseLink: string;
  defaultValue: string;
  currentValue: string;
  updateLoad: boolean;
  onChange: (value: string) => void;
  onSave: () => void;
  onReset: () => void;
  onOpen: () => void;
}

const { useBreakpoint } = Grid;

const InviteLinkRow: FC<InviteRowProps> = ({
  title,
  subtitle,
  baseLink,
  defaultValue,
  currentValue,
  onChange,
  onSave,
  onReset,
  onOpen,
  updateLoad,
}) => {
  const screens = useBreakpoint();

  const [load, setLoad] = useState(updateLoad);

  useEffect(() => {
    setLoad(load);
  }, [updateLoad]);

  return (
    <>
      <Space className={styles.centeredText} direction="vertical">
        <Title level={4} style={{ marginBottom: 0 }}>
          {title}
        </Title>
        <Text small>{subtitle}</Text>
      </Space>
      <Row style={{ alignItems: 'center' }}>
        <Col className={styles.addon}>
          <Text>
            {baseLink}
            /
          </Text>
        </Col>
        <Col>
          <Input
            size="large"
            defaultValue={defaultValue}
            value={currentValue}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          />
        </Col>
        <Col style={{ marginTop: screens.lg ? 0 : 10 }}>
          {!load ? (
            <div
              className={styles.icons}
            >
              <Tooltip title="Save Changes">
                <div>
                  {' '}
                  {/* div and style required for tooltip https://github.com/react-component/tooltip/issues/18#issuecomment-411476678 */}
                  <Button
                    icon={<SaveOutlined />}
                    type="link"
                    style={{ pointerEvents: defaultValue === currentValue || currentValue === '' ? 'none' : 'auto' }}
                    disabled={defaultValue === currentValue || currentValue === ''}
                    onClick={() => {
                      onSave();
                    }}
                  />
                </div>
              </Tooltip>
              <Tooltip title="Reset Invite Link">
                <div>
                  {' '}
                  {/* div and style required for tooltip https://github.com/react-component/tooltip/issues/18#issuecomment-411476678 */}
                  <Button
                    icon={<SyncOutlined />}
                    type="link"
                    // required for tooltip https://github.com/react-component/tooltip/issues/18#issuecomment-411476678
                    // style={{ pointerEvents: defaultValue === currentValue ? 'none' : 'auto' }}
                    // disabled={defaultValue === currentValue}
                    onClick={() => {
                      onReset();
                    }}
                  />
                </div>
              </Tooltip>
              <Tooltip title="Open Invite Link">
                <Button
                  icon={<ApiOutlined />}
                  type="link"
                  // disabled={defaultValue !== currentValue}
                  onClick={() => {
                    onOpen();
                  }}
                />
              </Tooltip>
            </div>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
    </>
  );
};

export default InviteLinkRow;
