import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Row,
} from 'taxaroo-ui';
import AddClientModal from '~src/components/molecules/AddClientModal';
import { useGetInterviewNamesQuery } from '~src/graphql/queries/interview';

const NoClients = () => {
  const [isAddClientModalVisible, setIsAddClientModalVisible] = useState(false);
  const navigate = useNavigate();

  // graphql
  // check if the firm has created any interviews yet. if not, prompt to create one first
  const { data: jobTypes, loading: loadingGetInterviews } = useGetInterviewNamesQuery();

  if (loadingGetInterviews) {
    return null;
  }

  return (
    <>
      <Row justify="center" style={{ marginTop: 25 }}>
        {jobTypes?.interviewNames?.length === 0 ? (
          <Button type="primary" shape="round" onClick={() => navigate('interviews')}>
            Create your first interview
          </Button>
        ) : (
          <Button type="primary" shape="round" onClick={() => setIsAddClientModalVisible(true)}>
            Add client
          </Button>
        )}
      </Row>
      <AddClientModal
        isModalVisible={isAddClientModalVisible}
        setIsModalVisible={setIsAddClientModalVisible}
      />
    </>
  );
};

export default NoClients;
