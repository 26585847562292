import { FC } from 'react';
import { Select } from 'taxaroo-ui';
import { toTitleCase } from '~src/components/helpers/toTitleCase';
import * as styles from './style.module.css';

interface ManageTeamRoleSelectProps {
  accountRole: string;
  firm: any;
  updateMember: any;
  preparerDetailId: number;
}

const ManageTeamRoleSelect: FC<ManageTeamRoleSelectProps> = ({
  accountRole,
  firm,
  updateMember,
  preparerDetailId,
}) => (
  <div>
    <Select
      defaultValue={accountRole === 'TAX_PREPARER' ? 'Admin' : 'Staff'}
      className={styles.manageTeamRoleSelect}
      onChange={(value) => updateMember(preparerDetailId, value)}
    >
      {firm.map((role: any) => {
        if (!role.description.includes('owner')) {
          return (
            <Select.Option key={role.id} value={role.id}>
              {role.description === 'TAX_PREPARER' ? 'Admin' : 'Staff'}
            </Select.Option>
          );
        }
        return null;
      })}
    </Select>
  </div>
);

export default ManageTeamRoleSelect;
