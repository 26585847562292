import {
  Dispatch, SetStateAction, Key, RefObject, useState, useEffect,
} from 'react';
import { Row, Col } from 'taxaroo-ui';
import ActionButton from '~src/components/molecules/ActionButton';
import ExportButton from './ExportButton';
import SearchBar from './SearchBar';
import SelectNumJobsDropdown from './SelectNumJobsDropdown';
import SendTextMessageButton from './SendTextMessageButton';

interface RowAboveJobsTableProps {
  selectedRows: unknown[];
  setSelectedRows: Dispatch<SetStateAction<never[]>>;
  setSelectedKeys: Dispatch<SetStateAction<Key[]>>;
  tableData: unknown[];
  tableRef: RefObject<HTMLDivElement>;
  type?: 'clients' | 'jobs';
  displayClearbutton: boolean;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  setSearchedValue: (value: string) => void,
  searchRef: RefObject<HTMLInputElement>,
  loading: boolean;
  progressStatusName?: string;
}

const RowAboveJobsTable = ({
  selectedRows,
  setSelectedRows,
  setSelectedKeys,
  tableData,
  tableRef,
  type,
  displayClearbutton,
  pageSize,
  setPageSize,
  setSearchedValue,
  searchRef,
  loading,
  progressStatusName,
}: RowAboveJobsTableProps) => {
  const [searchBarValue, setSearchBarValue] = useState('');
  const [sendTextDisabled, setSendTextDisabled] = useState(true);

  useEffect(() => {
    if (selectedRows.length > 0) {
      const now = new Date().getHours();
      if (now >= 20 || now < 9) {
        setSendTextDisabled(true);
      } else {
        setSendTextDisabled(false);
      }
    } else {
      setSendTextDisabled(true);
    }
  }, [selectedRows?.length]);

  const clearTable = () => {
    setSearchedValue('');
    setSearchBarValue('');
  };

  return (
    <Row justify="start" gutter={[{ xs: 8, sm: 16, md: 26 }, 16]} style={{ marginTop: '15px' }}>
      <Col>
        <SendTextMessageButton
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setSelectedKeys={setSelectedKeys}
          sendTextDisabled={sendTextDisabled}
        />
      </Col>
      {tableData && (
        <Col>
          <ExportButton
            dataType={type}
            progressStatusName={progressStatusName}
            tableData={tableData}
            tableRef={tableRef}
          />
        </Col>
      )}
      <Col style={{ flexGrow: 1 }}>
        <SearchBar
          disabled={loading}
          searchRef={searchRef}
          setSearchedValue={setSearchedValue}
          searchBarValue={searchBarValue}
          setSearchBarValue={setSearchBarValue}
        />
      </Col>
      {displayClearbutton && (
        <Col>
          <ActionButton
            buttonProps={{ type: 'primary' }}
            tooltip="Clear results"
            onClick={clearTable}
          >
            clear
          </ActionButton>
        </Col>
      )}
      <Col>
        <SelectNumJobsDropdown
          type={type}
          onChange={(value) => setPageSize(value)}
          currentPageSize={pageSize}
        />
      </Col>
    </Row>
  );
};

RowAboveJobsTable.defaultProps = {
  type: 'clients',
  progressStatusName: '',
};

export default RowAboveJobsTable;
