import { Moment } from 'moment-timezone';
import { useState } from 'react';
import {
  Rate, Row, Col, Typography, Select, List, Spin,
} from 'taxaroo-ui';
import { TaxPreparersEntity } from '~src/graphql';
import { useGetFirmReviewsFilteredQuery } from '~src/graphql/queries/analytics';

const { Title, Text, Paragraph } = Typography;

interface AllReviewsProps {
  startDate: Moment;
  endDate: Moment;
  preparers: TaxPreparersEntity[];
}
function AllReviews({
  startDate,
  endDate,
  preparers,
}: AllReviewsProps) {
  const [sort, setSort] = useState('date-new-old');
  const [preparerId, setPreparerId] = useState(null);

  const onSortChange = (value: string) => {
    setSort(value);
  };

  const onMemberChange = (value: string) => {
    console.log(`selected ${value}`);
    if (value === 'All') {
      setPreparerId(null);
    } else {
      setPreparerId(value);
    }
  };

  const onSearchPreparer = (val: string) => {
    console.log('search:', val);
  };

  // graphql queries
  // get all reviews
  const { data: reviewsData, loading: loadingReviews } = useGetFirmReviewsFilteredQuery({
    variables: {
      startDate,
      endDate,
      sort,
      preparerId,
    },
  });
  const reviews = reviewsData?.FirmReviewsFiltered;

  if (loadingReviews) {
    return (
      <Row justify="center" align="middle" style={{ height: 150 }}>
        <Spin size="large" />
      </Row>
    );
  }
  return (
    <>
      <Row gutter={[25, 0]} style={{ margin: '30px 0px' }}>
        <Col>
          <Text>Show Reviews for: </Text>
          <Select
            showSearch
            style={{ width: 200 }}
            optionFilterProp="children"
            onChange={onMemberChange}
            onSearch={onSearchPreparer}
            filterOption={(input, option: any) => {
              if (option) {
                return (
                  option.children.toLowerCase().indexOf(input.toLowerCase())
                  >= 0
                );
              }
              return false;
            }}
            defaultValue="All"
            value={(preparerId) || 'All'}
          >
            <Select.Option value="All">All Team Members</Select.Option>
            {preparers && preparers.map((preparer) => (
              <Select.Option key={preparer.id} value={preparer.id}>
                {preparer.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Text>Sort By: </Text>
          <Select
            style={{ width: 230 }}
            onChange={onSortChange}
            defaultValue="date-new-old"
          >
            <Select.Option value="date-new-old">Date: Newest to Oldest</Select.Option>
            <Select.Option value="date-old-new">Date: Oldest to Newest</Select.Option>
            <Select.Option value="rating-high-low">Rating: Highest to Lowest</Select.Option>
            <Select.Option value="rating-low-high">Rating: Lowest to Highest</Select.Option>
          </Select>
        </Col>
      </Row>
      <List
        itemLayout="horizontal"
        dataSource={reviews}
        locale={{ emptyText: ' No Reviews Yet' }}
        renderItem={(item) => (
          <List.Item>
            <Row gutter={[50, 15]}>
              <Col>
                <Paragraph style={{ fontWeight: 'bold', marginBottom: 0 }}>
                  {item?.TaxYearInterview?.TaxYear?.Entity?.Users?.UserInformation?.firstName}
                  {' '}
                  {item?.TaxYearInterview?.TaxYear?.Entity?.Users?.UserInformation?.lastName}
                </Paragraph>
                <Text>
                  {item?.TaxYearInterview?.TaxYear?.year}
                  {' '}
                  -
                  {' '}
                  {item?.TaxYearInterview?.Interviews?.name}
                </Text>
              </Col>
              <Col style={{ marginTop: '-2px' }}>
                <span>
                  {item?.reviewValue !== null && (
                    <Rate
                      disabled
                      defaultValue={item?.reviewValue}
                      style={{ marginRight: 8 }}
                    />
                  )}
                  <span style={{ color: 'gray' }}>{new Date(item?.createAt).toLocaleDateString('en-US')}</span>
                </span>
                {item?.TaxYearInterview?.PreparedUser?.UserInformation?.firstName && (
                  <Title level={5} style={{ fontSize: 17, fontWeight: 'bold' }}>
                    Review of
                    {' '}
                    {' '}
                    {item?.TaxYearInterview?.PreparedUser?.UserInformation?.firstName}
                  </Title>
                )}
                <Paragraph>{item?.reviewContent}</Paragraph>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </>
  );
}

export default AllReviews;
