import PhoneInput, { DefaultInputComponentProps } from 'react-phone-number-input';
import * as style from './style.module.css';
import 'react-phone-number-input/style.css';

interface PhoneInputFieldProps extends DefaultInputComponentProps {
  color?: 'white' | 'default';
  size?: 'small' | 'medium' | 'large';
}
function PhoneInputField(props: PhoneInputFieldProps) {
  const {
    onChange,
    color = 'default',
    defaultCountry = 'US',
    size = 'medium',
    className = '',
    ...rest
  } = props;

  return (
    <div className={`${style.phoneContainer} ${color === 'white' ? style.countrySelectInsideInput : ''}`}>
      <PhoneInput
        onChange={onChange}
        defaultCountry={defaultCountry}
        className={`${style[color]} ${style[size]} ${className}`}
        numberInputProps={{
          className: `${color === 'white' ? style.whitePhoneInput : style.defaultPhoneInput}`,
        }}
        {...rest}
      />
    </div>
  );
}

PhoneInputField.defaultProps = {
  color: 'default',
  size: 'medium',
};

export default PhoneInputField;
