import { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Icons,
  message,
  Spin,
} from 'taxaroo-ui';
import { useSearchParams } from 'react-router-dom';
import { useCreateBillingPortalSessionMutation } from '~src/graphql/mutations/stripeInformation';
import { useGetSubscriptionQuery } from '~src/graphql/queries/settings';
import { useGetStripeCustomerQuery } from '~src/graphql/queries/stripeInformation';
import { useCreateSubscriptionMutation, useResumeSubscriptionMutation, useUpdateSubscriptionMutation } from '~src/graphql/mutations/settings';
import { useAppSelector } from '~src/redux/hooks';
import config from '~src/components/helpers/config';
import { getUrlWithAddedSearchParams } from '~src/components/helpers/searchParams';
import * as styles from './styles.module.css';

const { CloseOutlined } = Icons;

// a close button component because it's used so often
interface CloseButtonProps {
  setCloseBar: (value: boolean) => void;
}
const CloseButton = ({ setCloseBar }: CloseButtonProps) => (
  <CloseOutlined
    onClick={() => setCloseBar(true)}
    style={{
      color: 'white', position: 'absolute', right: 10, top: 10,
    }}
  />
);

interface AnnouncementBarProps {
  customerId: string;
}
function AnnouncementBar({
  customerId,
}: AnnouncementBarProps) {
  const [searchParams] = useSearchParams();
  const { firmAccountId, email } = useAppSelector((state) => state.session);
  // get subscribeToYearly flag from url
  const subscribeToYearly = searchParams.get('subscribeToYearly');
  const showSubscribeToYearlyBar = subscribeToYearly === '1';
  // get upgraded flag from url
  const upgraded = searchParams.get('upgraded');
  const successfullyUpgraded = upgraded === '1';
  // get startSubscription flag from url
  const startSubscription = searchParams.get('startSubscription');
  const [closeBar, setCloseBar] = useState<boolean>(false);
  const [startSubscriptionFlag, setStartSubscriptionFlag] = useState<boolean>(startSubscription === '1');
  // State for storing the countdown components
  const [freeTrialCountdown, setFreeTrialCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [cancelAtCountdown, setCancelAtCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // Create billing portal session mutation
  const [createBillingPortalSession] = useCreateBillingPortalSessionMutation();
  // Get subscription info query
  const { data: subscriptionData, loading: loadingSubscription } = useGetSubscriptionQuery({
    fetchPolicy: 'network-only',
  });
  const subscription = subscriptionData?.GetSubscriptionByContext;
  const isOnFreePlan = subscription?.items.data[0].plan.id === config.stripeFreePlanPriceId;
  // we also want to get the customer, because we need to check the customre for a default payment method
  const { data: customerData, loading: loadingCustomer } = useGetStripeCustomerQuery({
    variables: {
      getStripeCustomerInput: {
        customerId,
      },
    },
    skip: !customerId,
    fetchPolicy: 'network-only',
  });
  const customer = customerData?.getStripeCustomer;
  const defaultPaymentMethod = customer?.invoice_settings?.default_payment_method || customer?.default_source;

  // update subscription
  const [updateSubscritpion, { loading: loadingUpdateSubscription }] = useUpdateSubscriptionMutation();
  // create subscription
  const [createSubscritpion, { loading: loadingCreateSubscription }] = useCreateSubscriptionMutation();
  // resume subscription
  const [resumeSubscription, { loading: loadingResumeSubscription }] = useResumeSubscriptionMutation();

  // useEffect for after startSubscription flag is set
  useEffect(() => {
    if (startSubscriptionFlag && subscription?.id) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleUnpauseAccount();
    }
  }, [startSubscriptionFlag, subscription?.id]);

  // useEffect for after subscription is upgraded
  useEffect(() => {
    if (successfullyUpgraded) {
      message.success('Account successfully upgraded.');

      // Create a URL object from the current location
      const currentUrl = new URL(window.location.href);
      // Use URLSearchParams to work with the query string easily
      const tmpSearchParams = new URLSearchParams(currentUrl.search);
      // Remove the 'upgraded' parameter
      tmpSearchParams.delete('upgraded');
      // Update the search part of the URL
      currentUrl.search = tmpSearchParams.toString();

      // Use history.pushState to update the URL without reloading the page
      window.history.pushState({}, '', currentUrl.toString());
    }
  }, [successfullyUpgraded]);

  // useEffect for free trial countdown
  useEffect(() => {
    if (!subscription?.trial_end || isOnFreePlan) {
      return;
    }
    // Update the countdown every second
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = (subscription.trial_end * 1000) - now;

      // Calculations for days, hours, minutes, and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Update the state with the new countdown time
      setFreeTrialCountdown({
        days, hours, minutes, seconds,
      });

      // If the countdown is over, clear the interval
      if (distance < 0) {
        clearInterval(interval);
        // Here you could add any additional logic for when the trial has ended
      }
    }, 1000); // Every second

    // Clear interval on component unmount
    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [subscription?.trial_end, isOnFreePlan]);

  // useEffect for cancel at countdown
  useEffect(() => {
    if (!subscription?.cancel_at) {
      return;
    }
    // Update the countdown every second
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = (subscription.cancel_at * 1000) - now;

      // Calculations for days, hours, minutes, and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Update the state with the new countdown time
      setCancelAtCountdown({
        days, hours, minutes, seconds,
      });

      // If the countdown is over, clear the interval
      if (distance < 0) {
        clearInterval(interval);
        // Here you could add any additional logic for when the trial has ended
      }
    }, 1000); // Every second

    // Clear interval on component unmount
    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [subscription?.cancel_at]);

  const handleAddPaymentMethod = (unpauseOnComplete = false) => {
    createBillingPortalSession({
      variables: {
        createBillingPortalInput: {
          customer: customerId,
          flow_data: {
            type: 'payment_method_update',
            after_completion: unpauseOnComplete ? {
              type: 'redirect',
              redirect: {
                return_url: `${window.location.origin}${getUrlWithAddedSearchParams([
                  { key: 'startSubscription', value: '1' },
                  { key: 'subscribeToYearly', value: '1' },
                ])}`,
              },
            } : {
              type: 'hosted_confirmation',
              hosted_confirmation: {
                custom_message: 'Your payment method has been successfully added! Consider updating to our yearly plan and save 16.7% per year.',
              },
            },
          },
          return_url: `${window.location.origin}${getUrlWithAddedSearchParams([
            { key: 'subscribeToYearly', value: '1' },
          ])}`,
        },
      },
    }).then(({ data }) => {
      window.location.href = data?.createBillingPortalSession;
    });
  };

  const handleUnpauseAccount = () => {
    resumeSubscription({
      variables: {
        subscriptionId: subscription?.id,
      },
      refetchQueries: ['GetSubscriptionByContext'],
      onCompleted: () => {
        message.success('Subscription successfully reactivated.');
        setStartSubscriptionFlag(false);
      },
    });
  };

  const handleSubscribeToYearly = () => {
    createBillingPortalSession({
      variables: {
        createBillingPortalInput: {
          customer: customerId,
          // prompt the user to update their subscription to the yearly plan
          flow_data: {
            type: 'subscription_update_confirm',
            subscription_update_confirm: {
              subscription: subscription?.id,
              items: [
                {
                  id: subscription?.items.data[0].id,
                  quantity: subscription?.quantity,
                  price: config.stripeYearlyPlanPriceId,
                },
              ],
            },
          },
          return_url: window.location.href,
        },
      },
    }).then(({ data }) => {
      window.location.href = data?.createBillingPortalSession;
    });
  };

  const handleRenewPlan = async () => {
    if (loadingSubscription || loadingCustomer) {
      message.info('Loading subscription information. Please wait a couple seconds and try again.');
      return;
    }
    if ((subscriptionData.GetSubscriptionByContext?.status === 'active' || subscriptionData.GetSubscriptionByContext?.status === 'trialing') && subscriptionData.GetSubscriptionByContext?.cancel_at_period_end) {
      // update subscription to cancel at period end to false
      await updateSubscritpion({
        variables: {
          updateStripeSubscriptionInput: {
            cancel_at_period_end: false,
          },
        },
        refetchQueries: ['GetSubscriptionByContext'],
        onCompleted: () => {
          message.success('Subscription renewed successfully');
          setCloseBar(true);
        },
        onError: (error) => {
          message.error(`Subscription could not be renewed. ${error.message}`);
        },
      });
    } else if (subscriptionData.GetSubscriptionByContext?.status === 'canceled') {
      // we need to start a whole new subscription
      await createSubscritpion({
        variables: {
          createStripeSubscriptionInput: {
            customerId: subscriptionData.GetSubscriptionByContext?.customer,
            planId: subscriptionData.GetSubscriptionByContext?.items.data[0].plan.id,
            quantity: subscriptionData.GetSubscriptionByContext?.quantity,
            firmAccountId,
          },
        },
        refetchQueries: ['GetSubscriptionByContext'],
        onCompleted: () => {
          message.success('Subscription renewed successfully');
          setCloseBar(true);
        },
        onError: (error) => {
          message.error(`Subscription could not be renewed. ${error.message}`);
        },
      });
    } else {
      message.error('Subscription could not be renewed. Please contact support.');
    }
  };

  // if loading
  if (loadingSubscription || loadingCustomer || !subscription || !customer) {
    return null;
  }

  if (closeBar) {
    return null;
  }

  if (loadingResumeSubscription) {
    return (
      <div className={styles.announcementBar}>
        <Row justify="center">
          <p>
            <Spin size="small" style={{ marginRight: 10 }} />
            One moment while we reactivate your account...
          </p>
        </Row>
      </div>
    );
  }

  // showSubscribeToYearlyBar (this one takes precedence over the others because it's an unusual temporary state)
  if (
    showSubscribeToYearlyBar // showSubscribeToYearlyBar flag is true
    && defaultPaymentMethod // a default payment method exists
    && subscription?.items.data[0].plan?.id === config.stripeMonthlyPlanPriceId // the customer is on our monthly plan
  ) {
    return (
      <div className={styles.announcementBar}>
        <Row justify="center">
          <p>
            <Button
              type="link"
              onClick={handleSubscribeToYearly}
              style={{
                color: 'white',
                textDecoration: 'underline',
                padding: 0,
                textTransform: 'none',
                fontWeight: 'bolder',
                fontSize: '16px',
              }}
            >
              Subscribe to our yearly plan and save 16.7% per year.
            </Button>
          </p>
          <CloseButton setCloseBar={setCloseBar} />
        </Row>
      </div>
    );
  }

  // renew plan bar (already canceled)
  if (subscription?.canceled_at && !subscription?.cancel_at_period_end) {
    return (
      <div className={styles.announcementBar}>
        <p>
          Your subscription is canceled
          {' '}
          <Button
            type="link"
            onClick={handleRenewPlan}
            loading={loadingUpdateSubscription || loadingCreateSubscription}
            style={{
              color: 'white',
              textDecoration: 'underline',
              padding: 0,
              textTransform: 'none',
              fontWeight: 'bolder',
              fontSize: '16px',
            }}
          >
            click here to reactivate your account
          </Button>
          {' '}
          to continue using Taxaroo.
        </p>
      </div>
    );
  }

  // renew plan bar (will be canceled)
  if (
    subscription?.cancel_at
    && (cancelAtCountdown.days > 0 || cancelAtCountdown.hours > 0 || cancelAtCountdown.minutes > 0 || cancelAtCountdown.seconds > 0)
  ) {
    return (
      <div className={styles.announcementBar}>
        <Row justify="center">
          <p>
            Your will lose access to Taxaroo in
            {` ${cancelAtCountdown.days}d ${cancelAtCountdown.hours}h ${cancelAtCountdown.minutes}m ${cancelAtCountdown.seconds}s. `}
            {' '}
            <Button
              type="link"
              onClick={handleRenewPlan}
              loading={loadingUpdateSubscription || loadingCreateSubscription}
              style={{
                color: 'white',
                textDecoration: 'underline',
                padding: 0,
                textTransform: 'none',
                fontWeight: 'bolder',
                fontSize: '16px',
              }}
            >
              Reactivate your account
            </Button>
            {' '}
            to continue using Taxaroo.
          </p>
          <CloseButton setCloseBar={setCloseBar} />
        </Row>
      </div>
    );
  }

  // in free trial with no payment method
  if (
    !defaultPaymentMethod
    && !isOnFreePlan
    && (freeTrialCountdown.days > 0 || freeTrialCountdown.hours > 0 || freeTrialCountdown.minutes > 0 || freeTrialCountdown.seconds > 0)
  ) {
    return (
      <div className={styles.announcementBar}>
        <p>
          You free trial ends in
          {` ${freeTrialCountdown.days}d ${freeTrialCountdown.hours}h ${freeTrialCountdown.minutes}m ${freeTrialCountdown.seconds}s. Please `}
          {' '}
          <Button
            type="link"
            onClick={handleAddPaymentMethod}
            style={{
              color: 'white',
              textDecoration: 'underline',
              padding: 0,
              textTransform: 'none',
              fontWeight: 'bolder',
              fontSize: '16px',
            }}
          >
            add a payment method
          </Button>
          {' '}
          to continue using Taxaroo.
        </p>
      </div>
    );
  }

  // if subscription status is past_due or unpaid, we let them know why they are on the free plan
  if (!isOnFreePlan && (subscription?.status === 'past_due' || subscription?.status === 'unpaid')) {
    return (
      <div className={styles.announcementBar}>
        <Row justify="center">
          <p>
            Your subscription is currently past due. You&apos;ve been downgraded to our limited access free plan. Please
            {' '}
            <Button
              type="link"
              onClick={handleAddPaymentMethod}
              style={{
                color: 'white',
                textDecoration: 'underline',
                padding: 0,
                textTransform: 'none',
                fontWeight: 'bolder',
                fontSize: '16px',
              }}
            >
              add a payment method
            </Button>
            {' '}
            for full access to Taxaroo.
          </p>
          <CloseButton setCloseBar={setCloseBar} />
        </Row>
      </div>
    );
  }

  // if subscription status is paused, we let them know why they are on the free plan
  if (!isOnFreePlan && subscription?.status === 'paused') {
    return (
      <div className={styles.announcementBar}>
        <Row justify="center">
          <p>
            You&apos;ve been downgraded to our limited access free plan. Please
            {' '}
            <Button
              type="link"
              onClick={!defaultPaymentMethod ? () => handleAddPaymentMethod(true) : handleUnpauseAccount}
              style={{
                color: 'white',
                textDecoration: 'underline',
                padding: 0,
                textTransform: 'none',
                fontWeight: 'bolder',
                fontSize: '16px',
              }}
            >
              {!defaultPaymentMethod ? 'add a payment method' : 'unpause your account'}
            </Button>
            {' '}
            for full access to Taxaroo.
          </p>
          <CloseButton setCloseBar={setCloseBar} />
        </Row>
      </div>
    );
  }

  // date between thanksgiving and cyber monday
  if (new Date().getTime() > 1700783999 && new Date().getTime() < 1701129599) {
    return (
      <div className={styles.announcementBar}>
        <p>
          <a
            href={`${window.location.origin}/special-offer/${config.stripeBlackFridayPricingTableId}?email=${email}`}
            style={{
              color: 'white',
              textDecoration: 'underline',
              padding: 0,
              textTransform: 'none',
              fontWeight: 'bolder',
              fontSize: '17px',
            }}
          >
            Subscribe to our Black Friday deal and save 43% per year (sale ends 11/27/2023)
          </a>
        </p>
      </div>
    );
  }

  // if none of the above conditions are met, we don't show the bar
  return null;
}

export default AnnouncementBar;
