/* eslint-disable max-len */
import { FC, useEffect, useState } from 'react';
import {
  Typography,
  Row,
  Col,
  Checkbox,
  Divider,
  Select,
  InputNumber,
  Button,
  Spin,
  message,
  Tooltip,
} from 'taxaroo-ui';
import {
  GetFirmNotificationsDocument,
  useGetFirmNotificationsQuery,
} from '~src/graphql/queries/settings';
import {
  useCreateFirmNotificationReminderMutation,
  useRemoveFirmNotificationReminderMutation,
  useUpdateFirmNotificationReminderMutation,
  useUpdateFirmNotificationsMutation,
} from '~src/graphql/mutations/settings';
import { useUpgradeModal, Features } from '~src/components/atoms/UpgradeModal/ModalProvider';
import './styles.css';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import * as styles from './style.module.css';
import SettingsHeader from '../SettingsHeader';

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;

// interface NotificationsProps {}

const Notifications: FC = () => {
  const [emailToYouOrTeamToUpdate, setEmailToYouOrTeamToUpdate] = useState([]);
  const [emailToClientsToUpdate, setEmailToClientsToUpdate] = useState<{
    id: string;
    notificationId: string;
    active: boolean;
  }[]>([]);
  const [currentlyCheckedEmailToClients, setCurrentlyCheckedEmailToClients] = useState<string[]>(null);
  const [clientRemindersToUpdate, setClientRemindersToUpdate] = useState([]);
  const [currentlyCheckedReminders, setCurrentlyCheckedReminders] = useState<string[]>(null);
  const [clientReminderFrequencyToUpdate, setClientReminderFrequencyToUpdate] = useState([]);
  const [clientMaxRemindersToUpdate, setClientMaxRemindersToUpdate] = useState([]);
  const [maxReminders, setMaxReminders] = useState<number>();
  const [frequency, setFrequency] = useState<number>();
  const [isReminderEditorActive, setIsReminderEditorActive] = useState(false);

  const { showModal, accessObject } = useUpgradeModal();

  // Get firm notifications
  const {
    data: notificationsData,
    loading: loadingGetNotifications,
  } = useGetFirmNotificationsQuery();

  // Update firm notifications
  const [
    updateFirmNotificationsMutation,
    { loading: loadingUpdateNotifications }] = useUpdateFirmNotificationsMutation({
    onCompleted: () => {
      message.success('Notifications updated successfully');
      setEmailToClientsToUpdate([]);
      setEmailToYouOrTeamToUpdate([]);
    },
    onError: (error) => message.error(error.message),
  });

  const [
    createFirmNotificationReminderMutation,
    { loading: loadingCreateFirmNotificationsReminder },
  ] = useCreateFirmNotificationReminderMutation({
    onCompleted: () => {
      message.success('Client reminder created successfully');
      setClientRemindersToUpdate([]);
      setClientReminderFrequencyToUpdate([]);
      setClientMaxRemindersToUpdate([]);
    },
    onError: (error) => message.error(error.message, 10),
    refetchQueries: [
      {
        query: GetFirmNotificationsDocument,
      },
    ],
    awaitRefetchQueries: true,
  });

  const [
    updateFirmNotificationReminderMutation,
    { loading: loadingUpdateFirmNotificationsReminder },
  ] = useUpdateFirmNotificationReminderMutation({
    onCompleted: () => {
      message.success('Client reminder updated successfully');
      setClientRemindersToUpdate([]);
      setClientReminderFrequencyToUpdate([]);
      setClientMaxRemindersToUpdate([]);
    },
    onError: (error) => message.error(error.message, 10),
  });

  const [
    deleteFirmNotificationReminderMutation,
    { loading: loadingDeleteFirmNotificationsReminder },
  ] = useRemoveFirmNotificationReminderMutation({
    onCompleted: () => {
      message.success('Client reminder deleted successfully');
      setClientRemindersToUpdate([]);
      setClientReminderFrequencyToUpdate([]);
      setClientMaxRemindersToUpdate([]);
    },
    onError: (error) => message.error(error.message, 10),
    refetchQueries: [
      {
        query: GetFirmNotificationsDocument,
      },
    ],
    awaitRefetchQueries: true,
  });

  // Set new client data
  useEffect(() => {
    if (notificationsData) {
      const activeReminder = notificationsData.getFirmNotifications.REMINDERS.find((r) => r.active);
      if (activeReminder) {
        setMaxReminders(activeReminder.FirmNotificationReminder.maxReminders);
        setFrequency(activeReminder.FirmNotificationReminder.frequency);
      } else {
        setMaxReminders(undefined);
        setFrequency(undefined);
      }
    }
    setIsReminderEditorActive(notificationsData?.getFirmNotifications?.REMINDERS.filter((x) => x?.active)?.length > 0);
  }, [notificationsData]);

  // Groups default values
  const activetTeamIds = notificationsData?.getFirmNotifications?.TEAM.filter(
    (item) => item?.active === true,
  ).map((item) => item.id);

  const activeClientIds = notificationsData?.getFirmNotifications?.CLIENT.filter(
    (item) => item?.active === true && (item?.Notifications?.name !== 'client-birthday' || accessObject?.[Features.birthdayEmails]),
  ).map((item) => item.id);

  // useEffect to set CurrentlyCheckedEmailToClients to activeClientIds on page load
  useEffect(() => {
    if (activeClientIds && currentlyCheckedEmailToClients === null) {
      setCurrentlyCheckedEmailToClients(activeClientIds);
    }
  }, [activeClientIds, setCurrentlyCheckedEmailToClients]);

  const activeReminderIds = notificationsData?.getFirmNotifications?.REMINDERS.filter(
    (item) => item?.active === true && (item?.Notifications?.name !== 'firm-requests-document-client-hasnt-sent-it' || accessObject?.[Features.followUps]),
  ).map((item) => item.id);

  // useEffect to set CurrentlyCheckedReminders to activeReminderIds on page load
  useEffect(() => {
    if (activeReminderIds && currentlyCheckedReminders === null) {
      setCurrentlyCheckedReminders(activeReminderIds);
    }
  }, [activeReminderIds, setCurrentlyCheckedReminders]);

  // Groups options
  const emailToYouAndTeamOptions = notificationsData?.getFirmNotifications?.TEAM.map((item) => ({
    value: item.id,
    label: item.Notifications.description,
  }));

  const emailToClientOptions = notificationsData?.getFirmNotifications?.CLIENT.map((item) => ({
    value: item.id,
    label: item.Notifications.description,
    disabled: item.Notifications.name === 'client-birthday' && !accessObject?.[Features.birthdayEmails],
  }));

  const eventRemindersToClientOptions = notificationsData?.getFirmNotifications?.REMINDERS.map((item) => ({
    value: item.id,
    label: item.Notifications.description,
    disabled: item.Notifications.name === 'firm-requests-document-client-hasnt-sent-it' && !accessObject?.[Features.followUps],
  }));

  const days = [1, 2, 3, 4, 5, 6, 7];

  // Groups handle change
  const handleChangeTeamValues = (value: string[]) => {
    const updatedValues = notificationsData?.getFirmNotifications?.TEAM.map(
      ({ id, notificationId }) => ({
        id,
        notificationId,
        active: value.includes(id),
      }),
    );

    const results = updatedValues.filter(({
      id: id1, active: active1,
    }) => !notificationsData?.getFirmNotifications?.TEAM.some(({
      id: id2, active: active2,
    }) => id2 === id1 && active1 === active2));

    if (results) {
      setEmailToYouOrTeamToUpdate([...results]);
    } else {
      setEmailToYouOrTeamToUpdate([]);
    }
  };

  const handleChangeClientValues = (values: string[]) => {
    const updatedValues = notificationsData?.getFirmNotifications?.CLIENT.map(
      ({ id, notificationId }) => ({
        id,
        notificationId,
        active: values.includes(id),
      }),
    );

    const results = updatedValues.filter(({
      id: id1, active: active1,
    }) => !notificationsData?.getFirmNotifications?.CLIENT.some(({
      id: id2, active: active2,
    }) => id2 === id1 && active1 === active2));

    if (results) {
      setEmailToClientsToUpdate([...results]);
    } else {
      setEmailToClientsToUpdate([]);
    }
  };

  const handleChangeRemindersValues = (values: string[]) => {
    setIsReminderEditorActive(values?.length > 0);

    if (values?.length > 0) {
      if (!frequency && !maxReminders) {
        const activeReminder = notificationsData.getFirmNotifications?.REMINDERS?.find((r) => r.active);
        if (activeReminder) {
          setMaxReminders(activeReminder.FirmNotificationReminder.maxReminders);
          setFrequency(activeReminder.FirmNotificationReminder.frequency);
        } else {
          setFrequency(4);
          setMaxReminders(8);
        }
      }
    } else {
      setFrequency(null);
      setMaxReminders(null);
    }

    const updatedValues = notificationsData?.getFirmNotifications?.REMINDERS.map(
      ({ id, notificationId }) => ({
        id,
        notificationId,
        active: values.includes(id),
      }),
    );

    const results = updatedValues.filter(({
      id: id1, active: active1,
    }) => !notificationsData?.getFirmNotifications?.REMINDERS.some(({
      id: id2, active: active2,
    }) => id2 === id1 && active1 === active2));

    if (results) {
      setClientRemindersToUpdate([...results]);
    } else {
      setClientRemindersToUpdate([]);
    }
  };

  const handleFrequencySelectChange = (values, value) => {
    setFrequency(parseInt(value.key, 10));
    const updatedValues = notificationsData?.getFirmNotifications?.REMINDERS.map(
      (firmNotification) => ({
        id: firmNotification.id,
        FirmNotificationReminder: {
          frequency: parseInt(value.key, 10),
        },
      }),
    );

    setClientReminderFrequencyToUpdate([updatedValues]);

    if (parseInt(value.key, 10) === frequency) {
      setClientReminderFrequencyToUpdate([]);
    }
  };

  const handleMaxRemindersChange = (value: number | null) => {
    setMaxReminders(value);
    const updatedValues = notificationsData?.getFirmNotifications?.REMINDERS.map(
      (firmNotification) => ({
        id: firmNotification.id,
        FirmNotificationReminder: {
          maxReminders: value,
        },
      }),
    );
    setClientMaxRemindersToUpdate([updatedValues]);

    if (value === maxReminders) {
      setClientMaxRemindersToUpdate([]);
    }
  };

  const handleSaveChanges = () => {
    // if no access to birthday emails, strip them out of the array
    let emailToClientsToUpdateFiltered = null;
    if (!accessObject?.[Features.birthdayEmails]) {
      const birthdayNotificationId = notificationsData?.getFirmNotifications?.CLIENT.find((x) => x?.Notifications.name === 'client-birthday')?.id;
      emailToClientsToUpdateFiltered = emailToClientsToUpdate?.filter((x) => x?.id !== birthdayNotificationId);
    }
    // Update notifications
    if (emailToYouOrTeamToUpdate?.length || emailToClientsToUpdate?.length || clientRemindersToUpdate?.length) {
      updateFirmNotificationsMutation({
        variables: {
          updateFirmNotificationsStatusInput: {
            firmNotifications: emailToYouOrTeamToUpdate
              .concat(emailToClientsToUpdateFiltered || emailToClientsToUpdate)
              .concat(clientRemindersToUpdate),
          },
        },
      });
    }

    // Create new reminders
    const newReminderIds = clientRemindersToUpdate.filter((x) => x?.active).map((x) => x.id);
    const newReminders = newReminderIds.map((id) => ({
      id,
      frequency: frequency || 4,
      maxReminders: maxReminders || 8,
    }));
    // console.log(`newReminders: ${JSON.stringify(newReminders)}`)
    // eslint-disable-next-line no-restricted-syntax
    for (const r of newReminders) {
      createFirmNotificationReminderMutation({
        variables: {
          createFirmNotificationReminderInput: {
            firmNotificationId: r.id,
            frequency: r.frequency,
            maxReminders: r.maxReminders,
          },
        },
      });
    }

    // Delete old reminders
    const oldReminderIds = clientRemindersToUpdate.filter((x) => !x?.active).map((x) => x.id);
    // console.log(`oldReminderIds: ${JSON.stringify(oldReminderIds)}`)
    // eslint-disable-next-line no-restricted-syntax
    for (const id of oldReminderIds) {
      deleteFirmNotificationReminderMutation({
        variables: {
          id,
        },
      });
    }

    // Update existing reminders
    const frequencyUpdatedIds = (clientReminderFrequencyToUpdate[0] ?? []).map((x) => x.id);
    const maxRemindersUpdatedIds = (clientMaxRemindersToUpdate[0] ?? []).map((x) => x.id);
    const updatedReminderIds = frequencyUpdatedIds
      .concat(maxRemindersUpdatedIds.filter((x) => !frequencyUpdatedIds.includes(x)))
      .filter((x) => !newReminderIds.includes(x) && !oldReminderIds.includes(x))
      .filter(
        (id) => notificationsData.getFirmNotifications.REMINDERS.find(
          (r) => r.id === id,
        )?.FirmNotificationReminder?.id,
      );
    const updatedReminders = updatedReminderIds.map((id) => ({
      id,
      frequency: frequency || 4,
      maxReminders: maxReminders || 8,
    }));
    // console.log(`updatedReminders: ${JSON.stringify(updatedReminders)}`)
    // eslint-disable-next-line no-restricted-syntax
    for (const r of updatedReminders) {
      updateFirmNotificationReminderMutation({
        variables: {
          updateFirmNotificationReminderInput: r,
        },
      });
    }

    const preferences = [];
    notificationsData?.getFirmNotifications?.TEAM.forEach((n) => {
      const updated = emailToYouOrTeamToUpdate.find((toUpdate) => toUpdate.id === n.id);
      preferences.push({ [n.Notifications.description]: updated ? updated.active : n.active });
    });
    notificationsData?.getFirmNotifications?.CLIENT.forEach((n) => {
      const updated = emailToClientsToUpdate.find((toUpdate) => toUpdate.id === n.id);
      preferences.push({ [n.Notifications.description]: updated ? updated.active : n.active });
    });
    notificationsData?.getFirmNotifications?.REMINDERS.forEach((n) => {
      const updated = clientRemindersToUpdate.find((toUpdate) => toUpdate.id === n.id);
      preferences.push({ [n.Notifications.description]: updated ? updated.active : n.active });
    });

    trackCustomerIoEvent(CUSTOMER_IO_EVENTS.CLICK, {
      Title: 'Save notification settings',
      Source: 'Button click',
      value: preferences.reduce((obj, item) => ({ ...obj, ...item }), {}),
      url: window.location.href,
    });
  };

  if (loadingGetNotifications) {
    return <Spin size="large" />;
  }

  return (
    <>
      <SettingsHeader
        title="Notifications"
        tooltipTitle="Notification Settings"
        tooltipContent={(
          <Paragraph>
            {`You can configure whether or not to receive email notifications for
            certain actions taken by clients, and whether or not send clients automated emails based on your team's actions.`}
            <br />
            <br />
            Not all emails are configurable yet, but additional options continue to be added over
            time.
          </Paragraph>
        )}
      />
      {/* Group one */}
      <Title level={5} className={styles.sectionTitle}>
        Actions that generate an email to you or your team
      </Title>
      <Checkbox.Group
        defaultValue={activetTeamIds}
        className="checkbox-group"
        options={emailToYouAndTeamOptions}
        onChange={handleChangeTeamValues}
        disabled={
          loadingUpdateNotifications
          || loadingCreateFirmNotificationsReminder
          || loadingDeleteFirmNotificationsReminder
          || loadingUpdateFirmNotificationsReminder
        }
      />
      {/* Group two */}
      <Divider className={styles.divider} />
      <Title level={5} className={styles.sectionTitle}>
        Actions that generate notifications to your clients
      </Title>
      {/* convert this checkbox group to checkboxs */}
      <div className="checkbox-group">
        {emailToClientOptions.map((item) => (
          <Tooltip title={(item.label.includes('birthday') && item.disabled) ? 'Automated birthday emails are only available on the Pro Plan' : null} placement="topLeft" key={item.value}>
            <div style={{ display: 'inline-block' }}>
              <Checkbox
                disabled={item.disabled}
                style={{ marginLeft: 0 }}
                checked={currentlyCheckedEmailToClients?.includes(item.value)}
                onChange={(e: { target: { checked: boolean; }; }) => {
                  const checkedValues = [...currentlyCheckedEmailToClients];
                  if (e.target.checked) {
                    checkedValues.push(item.value);
                  } else {
                    checkedValues.splice(checkedValues.indexOf(item.value), 1);
                  }
                  setCurrentlyCheckedEmailToClients(checkedValues);
                  handleChangeClientValues(checkedValues);
                }}
              >
                {item.label}
              </Checkbox>
            </div>
          </Tooltip>
        ))}
      </div>
      <div style={{ height: '35px' }} />
      {/* Group three */}
      <SettingsHeader
        title="Automated Follow-ups"
        tooltipTitle="Automated Follow-up Settings"
        tooltipContent={(
          <Paragraph>
            You can configure frequency and maximum number of reminders sent periodically to your clients.
          </Paragraph>
        )}
      />

      {/* convert this checkbox group to checkboxs */}
      <div className="checkbox-group">
        {eventRemindersToClientOptions.map((item) => (
          <Tooltip title={(item.label.includes('requested') && item.disabled) ? 'Automated follow-up emails are only available on the Pro Plan' : null} placement="topLeft" key={item.value}>
            <div style={{ display: 'inline-block' }}>
              <Checkbox
                disabled={item.disabled}
                style={{ marginLeft: 0 }}
                checked={currentlyCheckedReminders?.includes(item.value)}
                onChange={(e: { target: { checked: boolean; }; }) => {
                  const checkedValues = [...currentlyCheckedReminders];
                  if (e.target.checked) {
                    checkedValues.push(item.value);
                  } else {
                    checkedValues.splice(checkedValues.indexOf(item.value), 1);
                  }
                  setCurrentlyCheckedReminders(checkedValues);
                  handleChangeRemindersValues(checkedValues);
                }}
              >
                {item.label}
              </Checkbox>
            </div>
          </Tooltip>
        ))}
      </div>
      {/* Reminders */}
      <Row gutter={[10, 0]} style={{ marginTop: 15, marginLeft: 45 }}>
        <Col span={7} style={{ maxWidth: '11em' }}>
          <Text style={{ fontFamily: 'sans-serif', zoom: 1.2 }}>
            Frequency:
          </Text>
        </Col>
        <Col>
          <Select
            value={
              `${
                frequency
                  ? `Every ${frequency} day(s)`
                  : ''
              }`
            }
            style={{ width: 175 }}
            onChange={(label, value) => handleFrequencySelectChange(label, value)}
            disabled={
              loadingUpdateNotifications
              || loadingCreateFirmNotificationsReminder
              || loadingDeleteFirmNotificationsReminder
              || loadingUpdateFirmNotificationsReminder
              || !isReminderEditorActive
              || !accessObject?.[Features.followUps]
            }
          >
            {days.map((item) => (
              <Option
                key={item}
                value={`Every ${item} day(s)`}
                label={`${notificationsData?.getFirmNotifications?.REMINDERS[0]?.id}`}
              >
                {`Every ${item} day(s)`}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={[10, 0]} style={{ marginTop: 10, marginLeft: 45 }}>
        <Col span={7} style={{ maxWidth: '11em' }}>
          <Text style={{ fontFamily: 'sans-serif', zoom: 1.2 }}>
            Maximum number of reminders:
          </Text>
        </Col>
        <Col>
          <InputNumber
            min={1}
            max={365}
            value={maxReminders}
            style={{ width: 175 }}
            onChange={handleMaxRemindersChange}
            disabled={
              loadingUpdateNotifications
              || loadingCreateFirmNotificationsReminder
              || loadingDeleteFirmNotificationsReminder
              || loadingUpdateFirmNotificationsReminder
              || !isReminderEditorActive
              || !accessObject?.[Features.followUps]
            }
          />
        </Col>
      </Row>
      <div style={{ height: '30px' }} />
      <Button
        type="primary"
        size="large"
        onClick={handleSaveChanges}
        disabled={
          emailToYouOrTeamToUpdate?.length === 0
          && emailToClientsToUpdate?.length === 0
          && clientRemindersToUpdate?.length === 0
          && clientReminderFrequencyToUpdate?.length === 0
          && clientMaxRemindersToUpdate?.length === 0
        }
        loading={
          loadingUpdateNotifications
          || loadingCreateFirmNotificationsReminder
          || loadingDeleteFirmNotificationsReminder
          || loadingUpdateFirmNotificationsReminder
        }
      >
        Save Updates
      </Button>
    </>
  );
};

export default Notifications;
