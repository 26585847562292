import {
  List, Spin, Empty, Row,
} from 'taxaroo-ui';
import { useAppDispatch } from '~src/redux/hooks';
import {
  setSelectedChannel,
} from '~src/redux/slices/chatSlice';
import UseChannels from '~src/components/hooks/useChannels';
import * as styles from './style.module.css';
import ChatChannel from '../../molecules/ChatChannel';

const ChatChannelsList = () => {
  const dispatch = useAppDispatch();
  const {
    loadingChannels,
    channels: currentChannels,
    currentChannel: currentSelectedChannel,
  } = UseChannels(true);

  const selectChannelHandler = (channelId: string) => {
    const selectedChannel = currentChannels
      .find(({ id }) => id === channelId);

    dispatch(setSelectedChannel(selectedChannel));
  };

  return (
    <div className={styles.channelListContainer}>
      {loadingChannels
        ? (
          <div className={styles.channelsLoadingContainer}>
            <Row justify="center">
              <Spin size="large" style={{ marginTop: 60 }} />
            </Row>
            <Row justify="center" style={{ marginTop: 40 }}>
              Taxaroo messages are loading.
            </Row>
            <Row style={{ maxWidth: '260px', padding: '20px 10px 0 10px' }}>
              Hang tight, this could take a couple of minutes the first time it loads in your session.
            </Row>
          </div>
        )
        : (
          <List
            size="small"
            header={<div />}
            footer={<div />}
            bordered={false}
            dataSource={currentChannels?.filter(
              (ch) => ch.Messages.length > 0 || ch.id === currentSelectedChannel?.id,
            ) ?? []}
            loading={loadingChannels}
            locale={{ emptyText: <Empty description="No conversations yet" /> }}
            renderItem={(item) => (
              <ChatChannel
                data={item}
                setChannel={selectChannelHandler}
                selected={currentSelectedChannel?.id === item.id}
              />
            )}
          />
        )}
    </div>
  );
};

export default ChatChannelsList;
