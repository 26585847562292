import React, { SVGProps } from 'react';

const ChevronDown: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    // width="20"
    // height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.30376 15.3007L1.55203 7.64881C0.849497 6.85356 0.775861 5.55638 1.57326 4.7113C2.30483 3.90831 3.53255 3.825 4.30771 4.7442L9.99485 11.1885L15.7087 4.64403C16.443 3.8228 17.6902 3.73735 18.4683 4.67671C19.1524 5.47383 19.2165 6.75543 18.4249 7.59214L11.7643 15.1446L11.7417 15.1667C11.7249 15.1831 11.7057 15.203 11.6736 15.2362C11.6648 15.2454 11.6549 15.2557 11.6438 15.2671C11.5989 15.3136 11.5409 15.373 11.4769 15.4337C11.3628 15.5419 11.168 15.7147 10.9269 15.8215C10.5578 15.9848 10.215 16 9.99992 16C9.30099 16 8.76678 15.6816 8.37881 15.3625L8.30376 15.3007Z"
    />
  </svg>

);

export default ChevronDown;
