import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient, createHttpLink, from, InMemoryCache,
} from '@apollo/client';
import axios from 'axios';

import { resetSession } from '~src/redux/slices/sessionSlice';
import { resetClientData } from '~src/redux/slices/clientSlice';
import { resetInterviews } from '~src/redux/slices/interviewsSlice';
import { resetChat } from '~src/redux/slices/chatSlice';
import store from '../redux/store';
import config from '../components/helpers/config';

const isTokenExpired = async (token: string) => {
  // fetch with axios
  const result = await axios({
    method: 'get',
    url: `${config.serverUrl}/account/isTokenExpired?token=${token}`,
  });
  return result;
};

const uri = process.env.REACT_APP_GRAPHQL_ENDPOINT;

const link = createHttpLink({
  uri,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
  },
  fetch,
});

const authLink = setContext((_, { headers }) => {
  const { accessToken } = store.getState().session;

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken ?? headers?.authorization ?? ''}`,
    },
  };
});

const logoutLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors && graphQLErrors.length) {
    /*
      * IMPORTANT: I am using any, because the typing of the variableGraphQLErrors did not detect
      * the status property, which is the one we need to handle the user's logout.
    */
    const error: any = graphQLErrors[0];

    if (error.status === 401) {
      // check if the token has expired or not
      const { accessToken } = store.getState().session;
      if (accessToken) {
        isTokenExpired(accessToken)
          .then((res) => {
            if (res.data) {
              localStorage.clear();
              store.dispatch(resetSession());
              store.dispatch(resetClientData());
              store.dispatch(resetInterviews());
              store.dispatch(resetChat());
              window.location.reload();
            }
          })
          .catch((err) => {
            console.error('err:', err);
            localStorage.clear();
            store.dispatch(resetSession());
            store.dispatch(resetClientData());
            store.dispatch(resetInterviews());
            store.dispatch(resetChat());
            window.location.reload();
          });
      }
    }
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      EventTypeEntity: {
        fields: {
          EventTypeAvailability: { // EventTypeAvailability would be replaced in EventTypeEntity on update
            // eslint-disable-next-line default-param-last
            merge(existing = [], incoming: any[]) {
              if (incoming) {
                return [...incoming];
              }
              return [...existing];
            },
          },
        },
      },
      UserStepEntity: {
        keyFields: ['userId', 'stepId'],
      },
    },
  }),
  // link: authLink.concat(link),
  link: from([authLink, logoutLink, link]),
});

export default client;
