import { Dispatch, SetStateAction } from 'react';
import {
  Alert, Button, Typography, Row,
} from 'taxaroo-ui';
import { CustomDomainStatuses, FailureReason } from '~src/graphql';
import { CustomDomainCertificateStatusQuery } from '~src/graphql/queries/settings';

const { Text, Paragraph } = Typography;

interface CustomDomainStatusAlertProps {
  status: CustomDomainStatuses | null;
  failureReason: FailureReason | null;
  cnameError: string | null;
  setIsCnameModalVisible: Dispatch<SetStateAction<boolean>>;
  certificateStatus: CustomDomainCertificateStatusQuery,
  loadingCertificateStatus: boolean;
}
const CustomDomainStatusAlert = ({
  status,
  failureReason,
  cnameError,
  setIsCnameModalVisible,
  certificateStatus,
  loadingCertificateStatus,
}: CustomDomainStatusAlertProps) => {
  if (!certificateStatus && loadingCertificateStatus) return null;
  if (status === CustomDomainStatuses.Issued && !cnameError) return null;
  if (status && status !== CustomDomainStatuses.Issued) {
    const domainValidationSplit = certificateStatus?.CustomDomainCertificateStatus?.DomainValidationOptions.name?.split('.');
    return (
      <Alert
        message={(
          <Text style={{ color: 'white', fontSize: 18 }}>
            {status === CustomDomainStatuses.PendingValidation && (
              'First Step: Your custom domain is currently pending verification.'
            )}
            {status !== CustomDomainStatuses.PendingValidation && (
              `The status of your custom domain is currently ${status}.`
            )}
          </Text>
        )}
        type="warning"
        showIcon
        description={(
          <>
            <Text style={{ color: 'white', fontSize: 17 }}>
              {status === CustomDomainStatuses.PendingValidation && (
                // 'Please check your email for a message from no-reply@certificates.amazon.com and follow the instructions to verify your domain.'
                'Please add CNAME records to your domain DNS settings database in order to finish the verification process:'
              )}
              {status !== CustomDomainStatuses.PendingValidation && (
                `Error code: ${failureReason}. If you need help with this matter, please contact customer support.`
              )}
            </Text>
            {status === CustomDomainStatuses.PendingValidation && (
              <Paragraph style={{ paddingTop: '15px' }}>
                {!certificateStatus?.CustomDomainCertificateStatus?.DomainValidationOptions?.name
                  ? (
                    <Paragraph>
                      <Text>
                        (Please wait a few seconds and refresh the page to see values)
                      </Text>
                    </Paragraph>
                  )
                  : (
                    <>
                      <Row>
                        <Text>
                          <Text strong underline>Name:</Text>
                          <Text copyable code>
                            {`${domainValidationSplit?.[0]}.${domainValidationSplit?.[1]}`}
                          </Text>
                        </Text>
                      </Row>
                      <Row style={{ marginTop: 10 }}>
                        <Text>
                          <Text strong underline>Value:</Text>
                          <Text copyable code>
                            {certificateStatus?.CustomDomainCertificateStatus?.DomainValidationOptions.value}
                          </Text>
                        </Text>
                      </Row>

                    </>
                  )}
                <Paragraph style={{ paddingTop: '15px' }}>
                  <Text style={{ color: '#fff' }}>Please note that it can take up to an hour to propagate this values. And after verification is finished you&apos;ll be able to move on to the last step.</Text>
                </Paragraph>
              </Paragraph>
            )}
          </>
          )}
        style={{
          marginBottom: 20,
          marginTop: 20,
          padding: 15,
        }}
      />
    );
  }
  if (cnameError) {
    return (
      <Alert
        message={(
          <Text style={{ color: 'white', fontSize: 18 }}>
            {/* {`Final step: ${cnameError}`} */}
            {!cnameError.includes('already has a CNAME record') && 'Final step: '}
            {cnameError}
          </Text>
        )}
        type="warning"
        showIcon
        description={cnameError.includes('already has a CNAME record')
          ? (
            <Text style={{ color: 'white', fontSize: 17 }}>
              Please contact our customer support if you would like help updating your DNS records.
            </Text>
          )
          : (
            <Text style={{ color: 'white', fontSize: 17 }}>
              Please update your DNS by creating a CNAME record for your domain.
              <Button
                type="link"
                onClick={() => setIsCnameModalVisible(true)}
                style={{ padding: 2, textTransform: 'none' }}
              >
                See Details
              </Button>
            </Text>
          )}
        style={{
          marginBottom: 20,
          marginTop: 20,
          padding: 15,
        }}
      />
    );
  }
  return null;
};

export default CustomDomainStatusAlert;
