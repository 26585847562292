import moment, { Moment } from 'moment';
import { useContext, useState } from 'react';
import {
  Typography,
  Row,
  Col,
  Button,
  DatePicker,
  Icons,
  message,
} from 'taxaroo-ui';
import { JobContext } from '~src/components/providers/job-provider';
import { useMarkInterviewAsCompletedMutation, useMarkInterviewAsNotCompletedMutation } from '~src/graphql/mutations/clients';
import { useUpsertJobTrackingByTaxYearInterviewIdMutation } from '~src/graphql/mutations/jobTracking';
import { GetClientsByProgressStatusIdDocument, GetClientsDocument } from '~src/graphql/queries/settings';

const {
  CheckCircleOutlined,
  UndoOutlined,
} = Icons;

const { Paragraph } = Typography;

// interface PreparationProps {}
function Preparation() {
  const {
    dispatch,
    completedAt,
    taxYearInterviewId,
    progressStatusId,
    expectedCompletion,
  } = useContext(JobContext);

  const [selectedDate, setSelectedDate] = useState<Moment | null>(expectedCompletion ? moment(expectedCompletion) : null);

  // graphql
  const [markCompleted, { loading: loadingMarkCompleted }] = useMarkInterviewAsCompletedMutation({
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: () => {
      message.success('Job marked as completed.');
      dispatch({ type: 'UPDATE', payload: { completedAt: new Date() } });
    },
  });
  const [markNotCompleted, {
    loading: loadingMarkNotCompleted,
  }] = useMarkInterviewAsNotCompletedMutation({
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: () => {
      message.success('Job marked as not completed.');
      dispatch({ type: 'UPDATE', payload: { completedAt: null } });
    },
  });
  const [upsertJobTracking, { loading: loadingUpsertJobTracking }] = useUpsertJobTrackingByTaxYearInterviewIdMutation({
    onCompleted: () => {
      message.success('Expected date of completion saved.');
      dispatch({ type: 'UPDATE', payload: { expectedCompletion: moment(selectedDate).toDate() || null } });
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const handleMarkPrepared = () => {
    markCompleted({
      variables: {
        id: taxYearInterviewId,
      },
    });
  };

  const handleMarkNotPrepared = () => {
    markNotCompleted({
      variables: {
        id: taxYearInterviewId,
      },
    });
  };

  const handleSaveNewDate = () => {
    upsertJobTracking({
      variables: {
        createJobTrackingInput: {
          taxYearInterviewId,
          expectedCompletion: selectedDate?.toISOString() || null,
        },
      },
      refetchQueries: [
        {
          query: GetClientsDocument,
        },
        {
          query: GetClientsByProgressStatusIdDocument,
          variables: {
            progressStatusId,
          },
        },
      ],
    });
  };

  const onChange = (date: any, dateString: any) => {
    console.log(date, dateString);
    setSelectedDate(date);
  };

  return (
    <div style={{ marginLeft: 5 }}>
      <Typography>
        <Paragraph style={{ marginTop: 30 }}>
          Expected date of completion. Currently, this information is not shared with your client.
        </Paragraph>
      </Typography>
      <Row gutter={[20, 10]} style={{ marginTop: 10, marginBottom: 15 }}>
        <Col>
          <DatePicker
            onChange={onChange}
            value={selectedDate}
          />
        </Col>
        {(((!moment(expectedCompletion).isSame(selectedDate, 'day')) && selectedDate) || (!selectedDate && expectedCompletion)) && (
          <Col>
            <Button
              type="primary"
              onClick={handleSaveNewDate}
              loading={loadingUpsertJobTracking}
            >
              Save
            </Button>
          </Col>
        )}
        <Col>
          {!completedAt
            ? (
              <Button
                type="primary"
                icon={<CheckCircleOutlined />}
                iconDirection="left"
                onClick={handleMarkPrepared}
                loading={loadingMarkCompleted}
                disabled={loadingMarkCompleted}
              >
                Mark as return completed
              </Button>
            )
            : (
              <Button
                type="default"
                icon={<UndoOutlined />}
                iconDirection="left"
                onClick={handleMarkNotPrepared}
                loading={loadingMarkNotCompleted}
                disabled={loadingMarkNotCompleted}
              >
                Mark as return not completed
              </Button>
            )}
        </Col>
      </Row>
    </div>
  );
}

export default Preparation;
