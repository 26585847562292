import React, { FC } from 'react';
import { SortableContainer } from 'react-sortable-hoc';

const MySortableContainer = SortableContainer((props: any) => <tbody {...props} />);

interface DraggableContainerProps {
  onSortEnd: (oldIndex: number, newIndex: number) => void;
}

const DraggableContainer: FC<DraggableContainerProps> = ({ onSortEnd, ...props }) => (
  <MySortableContainer onSortEnd={onSortEnd} {...props} />
);

export default DraggableContainer;
