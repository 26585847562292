import { useEffect, FC } from 'react';
import { Input } from 'taxaroo-ui';
import { CirclePicker } from 'react-color';
import { colors } from '../../const/eventTypes';
import * as styles from './style.module.css';
import './style.css';

const { TextArea } = Input;

interface EventTypeSettingsDescriptionProps {
  data: any
  itsOK: any
  setItsOK: any
  updateSettings: any
}

const EventTypeSettingsDescription: FC<EventTypeSettingsDescriptionProps> = ({
  data, itsOK, setItsOK, updateSettings,
}) => {
  const handleChange = ({
    target: { name, value },
  }: Record<string, any>) => updateSettings(name, value);
  const { name, description, color } = data;
  const { showErrors } = itsOK;

  useEffect(() => {
    if (name.trim() !== '' && color.trim() !== '') {
      setItsOK({ ...itsOK, descriptionTab: true, showErrors: false });
    } else {
      setItsOK({ ...itsOK, descriptionTab: false });
    }
  }, [name, description, color]);

  return (
    <div className={styles.container}>
      <p className={styles.title}>Appointment Type Name*</p>
      <Input
        className={`${styles.input} ${showErrors && name.trim() === '' && styles.error}`}
        name="name"
        placeholder=""
        value={name}
        onChange={handleChange}
      />
      {showErrors && name.trim() === '' && (
        <p className={styles.errorLabel}>Field needed to continue</p>
      )}
      <p className={styles.title}>Description</p>
      <TextArea
        placeholder=""
        name="description"
        className={`${styles.input}`}
        autoSize={{ minRows: 5, maxRows: 8 }}
        value={description}
        onChange={handleChange}
      />
      <p className={styles.title}>Color*</p>
      <CirclePicker
        width="100%"
        circleSize={20}
        color={color}
        onChange={(col) => {
          updateSettings('color', col.hex);
        }}
        colors={colors}
      />
      {showErrors && color.trim() === '' && (
        <p style={{ marginTop: 20 }} className={styles.errorLabel}>
          Field needed to continue
        </p>
      )}
    </div>
  );
};

export default EventTypeSettingsDescription;
