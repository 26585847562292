/* eslint-disable jsx-a11y/aria-role */
import {
  FC, useEffect, useRef, useState,
} from 'react';
import {
  Icons,
  Modal,
  Form,
  message,
  Row,
  Popconfirm,
  TaxarooButton,
  Typography,
  Col,
  Steps,
  Divider,
  Button,
  Tooltip,
  Progress,
  Spin,
} from 'taxaroo-ui';
import { useImportClientsFromFileMutation, useValidateClientsImportMutation } from '~src/graphql/mutations/import-clients';
import { ApolloError } from '@apollo/client';
import { GetClientsByProgressStatusIdDocument, GetClientsDocument } from '~src/graphql/queries/settings';
import { ParseResult } from 'papaparse';
import moment from 'moment';
import client from '~src/graphql/client';
import { ClientEntity } from '.';
import DropzoneForm from './DropzoneForm';
import MapContacts from './MapContacts';
import TableForm, { badgeBgColors } from './TableForm';

const { UsergroupAddOutlined } = Icons;
const { Text } = Typography;
const { Step } = Steps;

export interface ImportClientsModalProps {
  isModalVisible: boolean
  setIsModalVisible: (b: boolean) => void
}

const ImportClientsModal: FC<ImportClientsModalProps> = ({ isModalVisible, setIsModalVisible }) => {
  const [csvParseResult, setCsvParseResult] = useState<ParseResult<any>>();
  const [mapForm] = Form.useForm();
  const [tableForm] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedInvitations, setSelectedInvitations] = useState<React.Key[]>([]);
  const [selectedClients, setSelectedClients] = useState<ClientEntity[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepOneNextDisabled, setStepOneNextDisabled] = useState(true);
  const [uploaded, setUploaded] = useState<ClientEntity[]>([]);
  const [importInProgress, setImportInProgress] = useState(false);
  const [progressPercent, setProgressPercent] = useState('0');
  const [formSubmittend, setFormSubmittend] = useState(false);
  const abortController = useRef(new AbortController());

  const handleOnError = (error: ApolloError) => {
    message.error(error.message, 10);
  };

  const [validateClientsImport, { loading: validateClientsLoading }] = useValidateClientsImportMutation({
    onError: handleOnError,
  });

  const [importClientsMutation,
    { loading: importClientsLoading, reset: resetImportMutation }] = useImportClientsFromFileMutation({
    onError: handleOnError,
    context: {
      fetchOptions: {
        signal: abortController.current.signal,
      },
    },
  });

  const closeModal = async () => {
    if (importInProgress) {
      message.warn('Import process was interrupted', 20);

      abortController.current.abort();
      await client.refetchQueries({
        include: [GetClientsDocument, GetClientsByProgressStatusIdDocument],
      });
      resetImportMutation();
    }

    setIsModalVisible(false);
    setSelectedClients([]);
    setSelectedInvitations([]);
    setSelectedRowKeys([]);
    setCurrentStep(0);
    setCsvParseResult(undefined);
    setUploaded([]);
    mapForm.resetFields();
    tableForm.resetFields();
    setFormSubmittend(false);
    setProgressPercent('0');
    setImportInProgress(false);
  };

  const updateSelectedClients = () => {
    const selected = uploaded.filter(
      (c) => selectedRowKeys.some((key) => c.key === key),
    );
    setSelectedClients(
      selected.map((c) => ({
        ...c,
        sendInvitation: selectedInvitations.some((inv) => inv === c.key),
        preparer: tableForm.getFieldValue(`preparer_${c.key}`) || null, // allow null for preparer
        jobType: tableForm.getFieldValue(`jobType_${c.key}`),
        taxYear: Number(tableForm.getFieldValue('taxYear')),
      })),
    );
    // remove errors from unselected fields
    const unSelected = uploaded.filter(
      (c) => !selectedRowKeys.some((key) => c.key === key),
    );
    tableForm.setFields(
      unSelected.map((c) => ({
        name: `jobType_${c.key}`,
        errors: [],
      })),
    );
  };

  const handleOK = async () => {
    setFormSubmittend(true);

    if (selectedClients.some((c) => !c.taxYear) || selectedClients.some((c) => !c.jobType)) {
      try {
        // shows validation errors
        await tableForm.validateFields([
          'taxYear',
          ...selectedClients.map((c) => `jobType_${c.key}`),
        ]);
      } catch (err) {
        console.log(err);
      }
      setImportInProgress(false);
      message.error('Some required fields are missing', 10);
      return;
    }

    const clientsArray = selectedClients.map((c) => ({
      ...c,
      phones: c?.phones?.map((p) => ({
        type: p.type,
        value: p.value,
      })),
      __typename: undefined,
      key: undefined,
    }));

    const each = 10;
    const wait = 5000;
    setImportInProgress(true);

    for (let i = 0; i < clientsArray.length; i += each) {
      setProgressPercent(Number((i * 100) / clientsArray.length).toFixed(1));
      const chunk = clientsArray.slice(i, i + each);

      // eslint-disable-next-line no-await-in-loop
      await importClientsMutation({ variables: { clientsArray: chunk } });

      setProgressPercent(Number(((i + each) * 100) / clientsArray.length).toFixed(1));
      // eslint-disable-next-line
      await new Promise((resolve) => setTimeout(resolve, wait));
    }

    setImportInProgress(false);
    closeModal();
    message.success('Import Success', 15);

    await client.refetchQueries({
      include: [GetClientsDocument, GetClientsByProgressStatusIdDocument],
    });
  };

  const setUploadedFileObject = (result: ParseResult<any>) => {
    if (result.data.length) {
      setCsvParseResult(result);
      setCurrentStep(1);
    }
  };

  const validateMappedValues = async () => {
    const values = mapForm.getFieldsValue(true);
    const mapFields = Object.keys(values)
      .filter((key) => key.indexOf('map_') === 0)
      .filter((key) => values[key]);
    const clientsParsed = csvParseResult.data.map((c) => {
      const result: any = {};
      mapFields.forEach((field) => {
        const originalField = field.replace('map_', '');
        const value = c[originalField]?.trim();

        if (value) {
          if (values[field] === 'phones') {
            if (!Array.isArray(result.phones)) result.phones = [];
            const replacedValue = value.replace(/[^0-9]/g, '');
            if (replacedValue.length >= 10) {
              result.phones.push({
                value: replacedValue.length === 10 ? `+1${replacedValue}` : `+${replacedValue}`,
                type: values[`phoneType_${originalField}`],
              });
            }
          } else if (values[field] === 'dob') {
            result[values[field]] = moment.utc(value).toDate();
          } else if (values[field] === 'email') {
            result[values[field]] = value.toLowerCase();
          } else {
            result[values[field]] = value;
          }
        }
      });

      return result;
    });

    const clientsArray = [];
    clientsParsed
      .filter((c: any) => c.firstName)
      .forEach((c: any) => {
        if (!clientsArray.some((cli: any) => cli.email && cli.email === c.email)) {
          clientsArray.push(c);
        }
      });

    const validateResult = await validateClientsImport({
      variables: {
        clientsArray,
      },
    });

    if (validateResult.data?.ValidateClientsImport?.length) {
      setUploaded(validateResult.data.ValidateClientsImport.map((c, key) => ({
        ...c,
        key,
        sendInvitation: false,
        taxYear: c.taxYear || undefined,
        jobType: c.jobType || undefined,
        phones: c.phones?.map((p) => ({
          id: String(key),
          type: p.type,
          value: p.value,
          createAt: new Date(),
          updateAt: new Date(),
        })),
      })));
      setCurrentStep(2);
    } else {
      message.info('Validation result is empty. Please try to update your mapping.', 10);
    }
  };

  const onPaginationChange = (pagination: { current: number, pageSize: number, defaultPageSize: number }) => {
    if (formSubmittend) {
      // hack for validateFields to work
      setTimeout(async () => {
        try {
          await tableForm.validateFields([
            'taxYear',
            ...selectedClients
              .filter((c) => Number(c.key) >= (pagination.current - 1) * pagination.pageSize
                && Number(c.key) <= pagination.current * pagination.pageSize)
              .map((c) => `jobType_${c.key}`),
          ]);
        } catch (err) {
          console.log(err);
        }
      }, 5);
    }
  };

  useEffect(() => {
    if (uploaded.length) {
      setSelectedRowKeys(uploaded.map((c) => c.key)); // select all table rows
    }
    // should reset form on clients change to prevent form values from caching
    tableForm.resetFields();
  }, [uploaded]);

  useEffect(() => {
    updateSelectedClients();
  }, [selectedRowKeys, selectedInvitations]);

  return (
    <Modal
      title={(
        <>
          <Row>
            <Text>
              Import Clients
            </Text>
            {/*
            <a
              href="https://help.taxaroo.com/article/68-import-clients"
              target="_blank"
              rel="noreferrer"
            >
              <InfoCircleOutlined
                style={{
                  fontSize: '16px',
                  position: 'relative',
                  top: '-0.5em',
                  left: '0.5em',
                }}
              />
            </a>
            */}
          </Row>
          <Divider />
          <Row>
            <Steps current={currentStep} size="small">
              <Step title="Upload" status={currentStep === 0 ? 'current' : 'process'} />
              {/* eslint-disable-next-line no-nested-ternary */}
              <Step title="Map Contacts" status={currentStep === 1 ? 'current' : currentStep < 1 ? 'wait' : 'process'} />
              <Step title="Review" status={currentStep === 2 ? 'current' : 'wait'} />
            </Steps>
          </Row>
        </>
      )}
      visible={isModalVisible}
      footer={
        (Boolean(currentStep === 1) && (
          <Tooltip title={stepOneNextDisabled ? 'Client first name is required' : ''}>
            <Row gutter={20}>
              <Col>
                <Button
                  disabled={validateClientsLoading}
                  onClick={() => {
                    setCsvParseResult(undefined);
                    setCurrentStep(0);
                    mapForm.resetFields();
                    setFormSubmittend(false);
                    setProgressPercent('0');
                  }}
                >
                  Back
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  disabled={stepOneNextDisabled}
                  onClick={validateMappedValues}
                  loading={validateClientsLoading}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Tooltip>
        ))
        || (Boolean(currentStep === 2) && !importInProgress && (
          <Row justify="end" gutter={20}>
            <Col>
              <Button
                disabled={importClientsLoading}
                onClick={() => {
                  setUploaded([]);
                  setCurrentStep(1);
                  setSelectedClients([]);
                  setSelectedInvitations([]);
                  tableForm.resetFields();
                  setFormSubmittend(false);
                  setProgressPercent('0');
                }}
              >
                Back
              </Button>
            </Col>
            <Col>
              <Popconfirm
                title="Are you sure? This action is non reversible."
                onConfirm={handleOK}
                okText="Yes"
                cancelText="No"
                placement="topRight"
                disabled={!selectedClients.length}
              >
                <TaxarooButton
                  type="primary"
                  icon={<UsergroupAddOutlined />}
                  disabled={!selectedClients.length}
                  loading={importClientsLoading}
                >
                  <Text style={{ color: '#fff' }}>Import</Text>
                </TaxarooButton>
              </Popconfirm>
            </Col>
          </Row>
        ))
        || null
      }
      onCancel={closeModal}
      width="75%"
    >
      {Boolean(currentStep === 0) && (
        <DropzoneForm
          setUploaded={setUploadedFileObject}
          handleOnError={handleOnError}
        />
      )}

      {Boolean(currentStep === 1) && (
        <MapContacts
          mapForm={mapForm}
          parseResult={csvParseResult}
          setStepOneNextDisabled={setStepOneNextDisabled}
        />
      )}

      {Boolean(currentStep === 2) && (
        <>
          <TableForm
            tableForm={tableForm}
            clients={uploaded}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            selectedInvitations={selectedInvitations}
            setSelectedInvitations={setSelectedInvitations}
            selectedClients={selectedClients}
            updateSelectedClients={updateSelectedClients}
            onPaginationChange={onPaginationChange}
          />
          {importInProgress && (
          <>
            <Divider />
            <Row>
              <Text small>
                Clients import is in progress and this process may take a few minutes.
                Please do not close this window or your import process will be interrupted.
              </Text>
              <Spin size="small" style={{ paddingLeft: '20px' }} />
            </Row>
            <Row>
              <Progress
                percent={progressPercent}
                strokeColor={{ '0%': badgeBgColors.blue, '100%': badgeBgColors.green }}
              />
            </Row>
          </>
          )}
        </>
      )}
    </Modal>
  );
};

export default ImportClientsModal;
