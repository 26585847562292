/* eslint-disable camelcase */
import {
  useState, useEffect,
} from 'react';
import {
  Typography,
  Spin,
  message,
} from 'taxaroo-ui';
import { useGetAllPlansLazyQuery } from '~src/graphql/queries/settings';
import {
  RegisterStep, selectPlanId, selectRegisterState, setPaymentInfo,
} from '~src/redux/slices/registerSlice';
import { useAppDispatch, useAppSelector } from '~src/redux/hooks';
// import CreditCardInput from 'react-credit-card-input';
import { useRegisterMutation } from '~src/graphql/mutations/register';
import { AddressType, PhoneType } from '~src/graphql';
import PoweredByStripe from '~src/assets/icons/powered-by-stripe-logo.svg';
import CardNav from '../CardNav.tsx';
import CardList from '../../CardList';
import * as styles from './styles.module.css';

const { Title } = Typography;

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

interface SelectPlanProps {
  setStep: (arg1: keyof typeof RegisterStep) => void;
}
function SelectPlan({
  setStep,
}: SelectPlanProps) {
  const dispatch = useAppDispatch();
  const {
    selectedPlanId,
    personalInfo: {
      the_em,
      the_pass,
      the_first_name,
      the_last_name,
      the_operating_as,
      the_date_of_birth,
    },
    personalAddress,
    businessAddress,
    // paymentInfo: { cvc, cardNumber, expireDate },
    businessInfo: { the_business_name, the_business_phone, the_tax_returns },
  } = useAppSelector(selectRegisterState);
  const [getAllPlans, { data, loading }] = useGetAllPlansLazyQuery();
  const [isPlanSelected, setIsPlanSelected] = useState(!!selectedPlanId?.length);
  const [registUser, { loading: registerLoading }] = useRegisterMutation();
  const [nextDisabled, setNextDisabled] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  // save payment info in state then add to redux on submit
  const [cvc, setCvc] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expireDate, setExpireDate] = useState('');

  const onSubmit = async () => {
    try {
      // if (!selectedPlanId) {
      //   message.error('Please select a plan.');
      //   return;
      // }
      setLoadingSubmit(true);
      // add to redux in case it's needed elsewhere
      dispatch(setPaymentInfo({
        cardNumber,
        cvc,
        expireDate,
      }));
      const exp_month = Number(expireDate.split(' / ')[0]);
      const exp_year = Number(expireDate.split(' / ')[1]);
      const { id } = (await registUser({
        variables: {
          createUserInput: {
            email: the_em,
            password: the_pass,
            planId: selectedPlanId,
            firmName: the_business_name ?? '',
            // English languageId
            languageId: 'b06939c3-e2cb-4bc6-8f6e-d85dc8c0aef2', // todo: this shouldn't be hardcoded
            ...(the_operating_as === 'Company' ? {
              BusinessInformation: {
                taxReturns: the_tax_returns,
                Phones: [{
                  value: the_business_phone,
                  type: PhoneType.Office,
                }],
                Address: [{
                  type: AddressType.Business,
                  city: businessAddress.the_city,
                  state: businessAddress.the_state,
                  zip: businessAddress.the_zip_code,
                  lineOne: businessAddress.the_line1,
                  lineTwo: businessAddress.the_line2,
                }],
              },
            } : {}),
            UserInformation: {
              firstName: the_first_name,
              lastName: the_last_name,
              birthday: the_date_of_birth,
              ...(the_operating_as === 'Individual' ? {
                Address: [{
                  type: AddressType.Personal,
                  city: personalAddress.the_city,
                  state: personalAddress.the_state,
                  zip: personalAddress.the_zip_code,
                  lineOne: personalAddress.the_line1,
                  lineTwo: personalAddress.the_line2,
                }],
              } : {}),
            },
          },
          // stripeCardInput: {
          //   cvc,
          //   exp_year,
          //   exp_month,
          //   number: cardNumber,
          // },
        },
      })).data?.Register ?? {};

      if (id) {
        setStep('VIDEO');
        message.success('Your account was successfully created!');
      }
    } catch (error) {
      if (error.message && error.message === 'Error code 1002') {
        message.error('The user with this email is already registered. Please try another email.');
      } else {
        message.error(error.message ?? 'Something went wrong while trying to regist the user');
      }
    } finally {
      setLoadingSubmit(false);
    }
  };

  const handleCardNumberChange = (event) => {
    setCardNumber(event.target.value);
  };

  const handleCardExpireDateChange = (event) => {
    setExpireDate(event.target.value);
  };

  const handleCardCvcChange = (event) => {
    setCvc(event.target.value);
  };

  const handlePaymentMethodError = () => {
    setNextDisabled(true);
  };

  useEffect(() => {
    const controller = new AbortController();

    getAllPlans();

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (!isPlanSelected) {
      setNextDisabled(true);
    } else if (cardNumber.length === 19 && expireDate.length === 7 && cvc.length === 3) {
      setNextDisabled(false);
    }
  }, [isPlanSelected]);

  useEffect(() => {
    // add spaces into copy-pasted card number
    if (cardNumber.length === 16 && cardNumber.indexOf(' ') === -1) {
      setCardNumber(`${cardNumber.substring(0, 4)} ${cardNumber.substring(4, 8)} ${cardNumber.substring(8, 12)} ${cardNumber.substring(12, 16)}`);
    }
  }, [cardNumber]);

  useEffect(() => {
    if (cardNumber.length === 19 && expireDate.length === 7 && cvc.length === 3) {
      if (nextDisabled) {
        setNextDisabled(false);
      }
    } else {
      setNextDisabled(true);
    }
  }, [cardNumber, expireDate, cvc]);

  const rendercancelSubtext = (): React.ReactNode => (
    <p style={{ marginTop: -12 }}>
      Cancel anytime
    </p>
  );

  const renderNextSubtext = (): React.ReactNode => (
    <p style={{ marginTop: -12 }}>
      <a style={{ color: 'inherit' }} href="https://taxaroo.com/pricing" target="_blank" rel="noreferrer">
        100% Money-Back Guarantee
      </a>
    </p>
  );

  return (
    <div style={{ marginTop: '-25px' }}>
      <Title level={4}>Choose the plan that’s right for you</Title>
      {loading && (
      <div style={{
        flex: 1,
        height: 300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <Spin />
      </div>
      )}
      <CardList cards={[...(data?.GetAllPlans ?? [])]
        .map(({
          id,
          metadata: {
            description,
            plan_name,
          },
          tiers,
        }) => ({
          id,
          onClick() {
            setIsPlanSelected(true);
            dispatch(selectPlanId(id));
          },
          title: (
            <p>
              {plan_name}
              {' '}
              <span className={styles.planAmount}>
                {formatter.format((tiers?.[0]?.flat_amount || 0) / 100)}
              </span>
            </p>
          ),
          content: (
            <p style={{ marginTop: -40, marginBottom: -5 }}>{description ?? ''}</p>
          ),
        }))}
      />
      <div className={styles.paymentContainer}>
        <p>Your card will not be charged until after the 14-day free trial ends.</p>
        {/* <CreditCardInput
          containerStyle={{
            marginBottom: 24,
          }}
          fieldStyle={{
            border: '1px solid #D8D8D8',
          }}
          onError={handlePaymentMethodError}
          // eslint-disable-next-line react/no-unstable-nested-components
          cardCVCInputRenderer={({ handleCardCVCChange, props }) => (
            <input
              {...props}
              autoComplete="cc-csc"
              onChange={handleCardCVCChange()}
            />
          )}
          // eslint-disable-next-line react/no-unstable-nested-components
          cardExpiryInputRenderer={({ handleCardExpiryChange, props }) => (
            <input
              {...props}
              autoComplete="cc-exp"
              onChange={handleCardExpiryChange()}
            />
          )}
          // eslint-disable-next-line react/no-unstable-nested-components
          cardNumberInputRenderer={({ handleCardNumberChange: cardNumberChanged, props }) => (
            <input
              {...props}
              autoComplete="cc-number"
              onChange={cardNumberChanged()}
            />
          )}
          cardCVCInputProps={{
            value: cvc,
            onChange: handleCardCvcChange,
          }}
          cardNumberInputProps={{
            value: cardNumber,
            onChange: handleCardNumberChange,
          }}
          cardExpiryInputProps={{
            value: expireDate,
            onChange: handleCardExpireDateChange,
          }}
        /> */}
      </div>
      <CardNav
        loading={loadingSubmit}
        onBack={() => setStep(the_operating_as === 'Company' ? 'BUSINESS_ADDRESS' : 'PERSONAL_ADDRESS')}
        showSkip={false}
        onNext={() => onSubmit()}
        nextText="START SUBSCRIPTION"
        disabled={false}
        cancelSubtext={rendercancelSubtext}
        nextSubtext={renderNextSubtext}
      />
      {/* add message about: "Your payment information is secure with us." and an icon with cards logos */}
      <div style={{ marginTop: 20 }}>
        <p style={{ textAlign: 'center' }}>
          Your payment information is secure with us.
        </p>
        <p style={{ textAlign: 'center', margin: -50 }}>
          <PoweredByStripe />
        </p>
        <br />
        <p style={{ textAlign: 'center' }}>
          <a style={{ color: 'inherit' }} href="https://taxaroo.com/privacy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          {' '}
          |
          {' '}
          <a style={{ color: 'inherit' }} href="https://taxaroo.com//terms-of-use" target="_blank" rel="noreferrer">
            Terms of Service
          </a>
        </p>
      </div>
    </div>
  );
}

export default SelectPlan;
