import { useContext, useState } from 'react';
import {
  Typography,
  Row,
  Col,
  Button,
  Icons,
  message,
} from 'taxaroo-ui';
import { JobContext } from '~src/components/providers/job-provider';
import { useUpsertJobTrackingByTaxYearInterviewIdMutation } from '~src/graphql/mutations/jobTracking';
import { GetClientsByProgressStatusIdDocument, GetClientsDocument } from '~src/graphql/queries/settings';
import RequestFilesModal from '../../../../molecules/RequestFilesModal';

const {
  FileAddOutlined,
  CheckCircleOutlined,
  UndoOutlined,
} = Icons;

const { Paragraph, Link } = Typography;

function Files() {
  const {
    dispatch,
    taxYearInterviewId,
    progressStatusId,
    filesCompletedAt,
  } = useContext(JobContext);
  const [isRequestFilesModalVisible, setIsRequestFilesModalVisible] = useState(false);
  // graphql
  const [upsertJobTracking, { loading: loadingUpsertJobTracking }] = useUpsertJobTrackingByTaxYearInterviewIdMutation({
    onError: (error) => {
      message.error(error.message);
    },
  });

  const handleRequestFiles = () => {
    setIsRequestFilesModalVisible(true);
  };

  const handleAllFilesReceived = async () => {
    const today = new Date();
    upsertJobTracking({
      variables: {
        createJobTrackingInput: {
          taxYearInterviewId,
          filesCompletedAt: today,
        },
      },
      onCompleted: () => {
        dispatch({ type: 'UPDATE', payload: { filesCompletedAt: today } });
        message.success('Files marked as complete.');
      },
      refetchQueries: [
        {
          query: GetClientsDocument,
        },
        {
          query: GetClientsByProgressStatusIdDocument,
          variables: {
            progressStatusId,
          },
        },
      ],
    });
  };

  const handleAllFilesNotReceived = async () => {
    upsertJobTracking({
      variables: {
        createJobTrackingInput: {
          taxYearInterviewId,
          filesCompletedAt: null,
        },
      },
      onCompleted: () => {
        dispatch({ type: 'UPDATE', payload: { filesCompletedAt: null } });
        message.success('Files marked as not complete.');
      },
    });
  };

  return (
    <div style={{ marginLeft: 5 }}>
      <Typography>
        <Paragraph style={{ marginTop: 30 }}>
          Request files from your clients. Files will be visible in the&nbsp;
          <Link href={`#account-client-interview-files-section-${taxYearInterviewId}`}>
            Job Specific Client Files
          </Link>
          {' '}
          section below.
        </Paragraph>
      </Typography>
      <Row gutter={[20, 10]} style={{ marginTop: 10, marginBottom: 15 }}>
        <Col>
          <Button
            type="default"
            icon={<FileAddOutlined />}
            iconDirection="left"
            onClick={handleRequestFiles}
          >
            Request Files
          </Button>
        </Col>
        <Col>
          {!filesCompletedAt
            ? (
              <Button
                type="primary"
                icon={<CheckCircleOutlined />}
                iconDirection="left"
                onClick={handleAllFilesReceived}
                loading={loadingUpsertJobTracking}
              >
                Mark All Files Received
              </Button>
            )
            : (
              <Button
                type="default"
                icon={<UndoOutlined />}
                iconDirection="left"
                onClick={handleAllFilesNotReceived}
                loading={loadingUpsertJobTracking}
              >
                Mark all Files Not Received
              </Button>
            )}
        </Col>
      </Row>
      {/* <Divider style={{ margin: '10px 0' }} /> */}
      {/* <Text type="secondary">Last requested on January 28th 2021 at 3:26 pm</Text> */}
      <RequestFilesModal
        isModalVisible={isRequestFilesModalVisible}
        setIsModalVisible={setIsRequestFilesModalVisible}
      />
    </div>
  );
}

export default Files;
