import { PathRouteProps, Route } from 'react-router-dom';
import { TaxpayerProvider } from '~src/components/providers/taxpayer-provider';
import Analytics from '~src/pages/Analytics';
import Appointments from '~src/pages/Appointments';
import Chat from '~src/pages/Chat';
import ClientDetails from '~src/pages/ClientDetails';
import Dashboard from '~src/pages/Dashboard';
import Interviews from '~src/pages/Interviews';
import Onboarding from '~src/pages/Onboarding';
import Settings from '~src/pages/Settings';
import Clients from '~src/pages/Clients';

export const PRIVATE_ROUTES: PathRouteProps[] = [
  {
    path: '/',
    element: <Dashboard />,
  },
  {
    path: '/settings',
    element: <Settings />,
    children: <Route path=":tab" element={<Settings />} />,
  },
  {
    path: '/onboarding',
    element: <Onboarding />,
  },
  {
    path: '/appointments',
    element: <Appointments />,
  },
  {
    path: '/chat',
    element: (
      <TaxpayerProvider>
        <Chat />
      </TaxpayerProvider>
    ),
  },
  {
    path: '/interviews',
    element: <Interviews />,
  },
  {
    path: '/analytics',
    element: <Analytics />,
  },
  {
    path: '/clients',
    element: <Clients />,
  },
  {
    path: '/clients/details',
    element: <ClientDetails />,
  },
];
