import { FC } from 'react';
import { useAppSelector } from '~src/redux/hooks';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Icons,
  Modal,
  Form,
  Input,
  Select,
  Checkbox,
  Tooltip,
  message,
  Alert,
  DatePicker,
} from 'taxaroo-ui';
import PhoneInputField from '~src/components/atoms/PhoneInputField';
import { PhoneType, TaxYearStatus } from '~src/graphql';
import {
  useCreateClientMutation,
} from '~src/graphql/mutations/settings';
import SelectJobType from '~src/components/atoms/SelectJobType';
import style from './index.module.css';

const { Item } = Form;

const { InfoCircleOutlined, UserAddOutlined } = Icons;
const { Option } = Select;

// fill tax year list
const currentYear = (new Date()).getFullYear();
const taxYears: Array<string> = [(currentYear - 1).toString(), currentYear.toString()];

for (let year = currentYear - 2; year > currentYear - 10; year -= 1) {
  taxYears.push(year.toString());
}
//

export interface AddClientModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (b: boolean) => void;
}

const defaultProps = {};

const AddClientModal: FC<AddClientModalProps> = ({
  isModalVisible,
  setIsModalVisible,
}) => {
  const { progressStatuses } = useAppSelector((state) => state.session);
  const unassignedStatus = progressStatuses.find((status) => status.underlyingStatus === TaxYearStatus.Unassigned);

  // Create client mutation
  const [createClientMutation, { loading }] = useCreateClientMutation({
    onCompleted: () => {
      message.success('Client added successfully');
      setIsModalVisible(false);
    },
    onError: (error) => {
      if (error.message === 'Error code 1002') {
        message.error('This email address is being used by another user of our platform. Please use another email address in order to add a new client. If you need further assistance, please contact customer support.');
      } else if (error.message === 'Error code 2001') {
        message.error('Your tax firm is not currently active. Please contact your admin.');
      } else {
        message.error(error.message);
      }
    },
    refetchQueries: [
      'GetClientsByProgressStatusId',
      'GetClients',
    ],
  });

  // Assign preparer mutation
  // const [updateTaxYearInterviewMutation] = useUpdateTaxYearInterviewMutation({
  //   onCompleted: () => {
  //     message.success('Preparer assigned successfully');
  //   },
  //   onError: (error) => message.info(error.message),
  // });

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    birthday: yup.date().optional().nullable(),
    email: yup.string().email('Please enter a valid email').nullable(),
    phone: yup.string().optional().nullable(),
    taxYear: yup.number().required('Tax year is required'),
    interviewId: yup.string().required('Job type is required'),
    progressStatusId: yup.string().required('Progress status is required'),
    sendActivationEmail: yup.boolean().default(false),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      birthday: undefined,
      email: '',
      phone: '',
      taxYear: '',
      interviewId: '',
      progressStatusId: unassignedStatus.id,
      sendActivationEmail: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      const {
        firstName,
        lastName,
        birthday,
        email,
        phone,
        taxYear,
        interviewId,
        progressStatusId,
        sendActivationEmail,
      } = values;

      const res = await createClientMutation({
        variables: {
          createClientInput: {
            taxYear: +taxYear,
            email: email || undefined,
            interviewId,
            progressStatusId,
            sendActivationEmail,
            UserInformation: {
              firstName,
              lastName,
              birthday: birthday?.format('YYYY-MM-DD'),
              Phones: phone
                ? [{
                  type: PhoneType.Mobile,
                  value: phone,
                }]
                : undefined,
            },
          },
        },
      });
      if (res?.data) {
        formik.resetForm();
      }
    },
  });

  const handleCancel = () => {
    setIsModalVisible(false);
    formik.resetForm();
  };

  // todo: assign preparer to new client
  // Assign preparer to new client
  // useEffect(() => {
  //   if (newClient.taxYearId) {
  //     const assignPreparer = async () => {
  //       await updateTaxYearInterviewMutation({
  //         variables: {
  //           updateTaxYearInterviewInput: {
  //             id: newClient.id,
  //             taxYearId: newClient.taxYearId,
  //             preparedId: newClient.preparedId,
  //             interviewId: newClient.interviewId,
  //             status: newClient.status,
  //             assignedAt: newClient.assignedAt,
  //           },
  //         },
  //       });
  //     };
  //     assignPreparer();
  //   }
  // }, [newClient.taxYearId]);

  return (
    <Modal
      title="Add Client"
      visible={isModalVisible}
      okButtonProps={{ icon: <UserAddOutlined /> }}
      okText="Add Client"
      onCancel={handleCancel}
      onOk={() => formik.submitForm()}
      className={style.addClientForm}
      centered
      style={{ top: '15px' }}
      confirmLoading={loading}
      destroyOnClose
    >
      <Form
        style={{
          width: '100%',
        }}
        layout="vertical"
        name="basic"
      >
        <Item name="firstName" label="First Name" rules={[{ required: true }]}>
          <Input
            disabled={loading}
            placeholder="Taxpayer First Name"
            value={formik.values.firstName}
            onChange={formik.handleChange('firstName')}
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <Alert message={formik.errors.firstName} type="error" />
          )}
        </Item>
        <Item name="lastName" label="Last Name" rules={[{ required: true }]}>
          <Input
            disabled={loading}
            placeholder="Taxpayer Last Name"
            value={formik.values.lastName}
            onChange={formik.handleChange('lastName')}
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <Alert message={formik.errors.lastName} type="error" />
          )}
        </Item>
        <Item name="birthday" label="Date of Birth">
          <DatePicker
            disabled={loading}
            value={formik.values.birthday}
            onChange={(value: any) => {
              formik.setFieldValue('birthday', value);
            }}
            placeholder="MM/DD/YYYY"
            format="MM/DD/YYYY"
            style={{ width: '100%' }}
          />
          {formik.touched.birthday && formik.errors.birthday && (
            <Alert message={formik.errors.birthday} type="error" />
          )}
        </Item>
        <Item name="email" label="Email" rules={[{ required: false, type: 'email' }]}>
          <Input
            disabled={loading}
            placeholder="Taxpayer Email"
            value={formik.values.email}
            onChange={formik.handleChange('email')}
          />
          {formik.touched.email && formik.errors.email && (
            <Alert message={formik.errors.email} type="error" />
          )}
        </Item>
        <Item name="phone" label="Phone Number" rules={[{ required: false }]}>
          <PhoneInputField
            onChange={(value: string) => formik.setFieldValue('phone', value)}
          />
          {formik.touched.phone && formik.errors.phone && (
            <Alert message={formik.errors.phone} type="error" />
          )}
        </Item>
        <Item name="taxYear" label="Tax Year" rules={[{ required: true }]}>
          <Select
            className={style.taxYearSelect}
            disabled={loading}
            placeholder="Select tax year"
            onChange={formik.handleChange('taxYear')}
            value={formik.values.taxYear}
            allowClear
          >
            {taxYears.map((item) => (
              <Option value={item} key={item}>
                {item}
              </Option>
            ))}
          </Select>
          {formik.touched.taxYear && formik.errors.taxYear && (
            <Alert message={formik.errors.taxYear} type="error" />
          )}
        </Item>
        <Item name="jobType" label="Job Type" rules={[{ required: true }]}>
          <SelectJobType
            onChange={formik.handleChange('interviewId')}
            value={formik.values.interviewId}
          />
          {formik.touched.interviewId && formik.errors.interviewId && (
            <Alert message={formik.errors.interviewId} type="error" />
          )}
        </Item>
        <Item name="progressStatusId" label="Progress Status" rules={[{ required: true }]}>
          <Select
            className={style.progressStatusSelect}
            disabled={loading}
            placeholder="Select progress status"
            onChange={formik.handleChange('progressStatusId')}
            value={formik.values.progressStatusId}
            allowClear
          >
            {progressStatuses.map((item) => (
              <Option value={item.id} key={item.id}>
                {item.status}
              </Option>
            ))}
          </Select>
          {formik.touched.interviewId && formik.errors.interviewId && (
            <Alert message={formik.errors.interviewId} type="error" />
          )}
        </Item>
        <Item name="sendActivationEmail">
          <Checkbox
            disabled={loading}
            value={formik.values.sendActivationEmail}
            checked={formik.values.sendActivationEmail}
            onChange={(event) => {
              formik.setFieldValue('sendActivationEmail', event.target.checked);
            }}
          >
            Send email to request to ask client to complete on-boarding interview
            {' '}
            <Tooltip title="We will send an email on your behalf to request that the client provide their detailed tax situation.">
              <InfoCircleOutlined style={{ fontSize: '14px' }} />
            </Tooltip>
          </Checkbox>
        </Item>
      </Form>
    </Modal>
  );
};

AddClientModal.defaultProps = defaultProps;

export default AddClientModal;
