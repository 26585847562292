import { FC, useContext } from 'react';
import { Skeleton } from 'taxaroo-ui';
import { JobContext } from '~src/components/providers/job-provider';
import { AnswerEntity, InterviewEntity, SectionEntity } from '~src/graphql';
import InterviewSection from './sections';

interface InterviewProps {
  loading: boolean;
  sections: Array<SectionEntity>;
  answers: Array<AnswerEntity>;
  interview: InterviewEntity;
  refetchInterviews: () => void;
  expandInterviewSections: boolean;
}

const Interview: FC<InterviewProps> = ({
  loading,
  sections,
  answers,
  interview,
  refetchInterviews,
  expandInterviewSections,
}) => {
  const jobContext = useContext(JobContext);
  const { taxYearInterviewId } = jobContext;

  if (loading) {
    return <Skeleton />;
  }

  return (
    <div>
      {sections?.map((section) => (
        <InterviewSection
          {...{ jobId: taxYearInterviewId, Answers: answers }}
          {...section}
          interviewName={interview.name}
          refetchInterviews={refetchInterviews}
          key={section.id}
          expandInterviewSections={expandInterviewSections}
        />
      ))}
    </div>
  );
};

export default Interview;
