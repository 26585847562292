.add-client-form .ant-btn svg {
  height: 14px !important;
  width: 14px !important;
}

.tax-year-select {
  width: 100% !important;
}

.job-type-select {
  width: 100% !important;
}

.progress-status-select {
  width: 100% !important;
}
