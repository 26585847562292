import { ApolloError } from '@apollo/client';
import { FC } from 'react';
import {
  Card,
  Tag,
  Switch,
  Icons,
  TaxarooModal,
  TaxarooButton,
  Row,
  Col,
} from 'taxaroo-ui';
import { EventTypeEntity } from '~src/graphql';
import { useRemoveEventTypeMutation, useUpdateEventTypeMutation } from '~src/graphql/mutations/appointments';
import {
  MyEventTypesDocument,
} from '~src/graphql/queries/appointments';

const { confirm } = TaxarooModal;

const {
  CalendarOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} = Icons;

interface EventTypeProps {
  eventType: EventTypeEntity,
  onClick: (eventType: EventTypeEntity) => void,
  isLoading: boolean,
  handleOnError: (error: ApolloError) => void,
}

const EventType: FC<EventTypeProps> = ({
  eventType,
  onClick,
  isLoading,
  handleOnError,
}) => {
  const {
    name,
    duration,
    EventTypeAvailability,
    active: eventTypeActive,
  } = eventType;

  const [removeEventTypeMutation, { loading: removeEventTypeLoading }] = useRemoveEventTypeMutation({
    onError: handleOnError,
    refetchQueries: [MyEventTypesDocument],
    awaitRefetchQueries: true,
  });

  const [updateEventTypeMutation, { loading: updateEventTypeLoading }] = useUpdateEventTypeMutation({
    onError: handleOnError,
    refetchQueries: [MyEventTypesDocument],
    awaitRefetchQueries: true,
  });

  const daysAvailable = EventTypeAvailability.map((avail, index, array) => {
    if (index === array.length - 1) {
      return avail?.day.substring(0, 3).toLowerCase();
    }
    return `${avail?.day.substring(0, 3).toLowerCase()}, `;
  });

  const updateActive = async () => {
    const updateInput = {
      ...eventType,
      active: !eventTypeActive,
      EventTypeAvailability: undefined,
      __typename: undefined,
      userId: undefined,
    };

    await updateEventTypeMutation({
      variables: {
        input: updateInput,
      },
    });
  };

  const showConfirm = () => {
    confirm({
      title: 'Are you sure you want to delete this event type?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        await removeEventTypeMutation({
          variables: {
            id: eventType.id,
          },
        });
      },
    });
  };

  return (
    <Card
      loading={isLoading || removeEventTypeLoading || updateEventTypeLoading}
      title={(
        <TaxarooButton
          onClick={() => onClick(eventType)}
          type="link"
        >
          { name.length > 14 ? `${name.substring(0, 12)}...` : name }
        </TaxarooButton>
      )}
      extra={(
        <TaxarooButton
          danger
          type="link"
          onClick={() => showConfirm()}
          icon={<DeleteOutlined />}
        />
      )}
      style={{ width: '220px', height: '240px' }}
    >
      <Tag
        color="default"
        style={{
          display: 'flex',
          width: '170px',
          height: '80px',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Row align="middle">
          <Col style={{ width: '25%', textAlign: 'center' }}>
            <CalendarOutlined />
          </Col>
          <Col style={{
            textTransform: 'capitalize',
            whiteSpace: 'break-spaces',
            width: '75%',
          }}
          >
            Days:
            {' '}
            { daysAvailable }
          </Col>
        </Row>
        <Row align="middle">
          <Col style={{ width: '25%', textAlign: 'center' }}>
            <ClockCircleOutlined />
          </Col>
          <Col style={{ width: '75%' }}>
            {`Duration: ${duration} min`}
          </Col>
        </Row>
      </Tag>
      <Row align="middle" justify="end" style={{ paddingTop: '20px' }} gutter={15}>
        <Col>
          { eventTypeActive ? 'Active' : 'Inactive' }
        </Col>
        <Col>
          <Switch onChange={updateActive} checked={eventTypeActive} />
        </Col>
      </Row>
    </Card>
  );
};

export default EventType;
