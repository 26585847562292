.chatInputContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-top: 0;
  bottom: 0;
  position: relative;
}

.containerInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.clip {
  margin-right: 8px;
  border: none !important;
  width: 20px;
  height: 30px;
  background: transparent !important;
  box-shadow: none !important;
}

.clipsms {
  margin-right: 8px;
  border: none !important;
  width: 20px;
  height: 30px;
  background: transparent !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

.clip svg {
  color: #565656;
  width: 20px;
  height: 20px;
}

.clipsms svg {
  color: #565656;
  width: 20px;
  height: 20px;
}

.send {
  margin-right: 8px;
  border: none !important;
  width: 20px;
  height: 30px;
  background: transparent !important;
  box-shadow: none !important;
}

.send svg {
  color: #22a7e7;
  width: 20px;
  height: 20px;
}

.input {
  margin-right: 8px;
  border: 1px solid #aaa !important;
  width: 100%;
}

.uploader {
  max-width: 150px;
}

.containerInput::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.dropup {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: white;
  color: black;
  padding: 16px 16px 16px 0;
  font-size: 16px;
  border: none;
  outline: none;
}

.dropbtn:hover {
  background-color: black;
}

.dropbtn:focus {
  border: none;
}

.dropbtn:active {
  border: none;
}

.dropupcontent {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  bottom: 45px;
  z-index: 1;
}

.dropupcontent p {
  color: black;
  padding: 7px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  margin: 0;
}

.dropupcontent p:hover {
  background-color: #ccc;
}

.dropup:hover .dropupcontent {
  display: block;
}

.dropup:hover .dropbtn {
  background-color: white;
  border: none;
}
