/* type */
.whitePhoneInput {
  background: white;
  border-color: #c4cfd9;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #000000a6;
  font-family: "Open Sans", sans-serif;
}

.whitePhoneInput::placeholder {
  color: #a6a6a6 !important; /* transparent #000000a6 was not working for some reason  */
}

.defaultPhoneInput {
  background: #f4f4f4 !important;
  border-color: #f4f4f4 !important;
}

/* size */
.small input {
  height: 24px;
}

.medium input {
  height: 31px;
}

.large input {
  height: 40px;
}

.phoneContainer {
  width: 100%;
}

.phoneContainer input {
  background: transparent;
  border: 1px solid;
  border-color: inherit;
  border-radius: 5px;
  padding-left: 10px;
}

.countrySelectInsideInput {
  border: 1px solid #c4cfd9;
  border-radius: 10px;
  padding: 0 10px;
}

.countrySelectInsideInput input {
  border: none;
  border-left: 1px solid #c4cfd9;
  border-radius: 0;
  margin-left: 4px;
}

.countrySelectInsideInput input:focus {
  outline: 0;
  box-shadow: none;
  border: none;
  border-left: 1px solid #c4cfd9;
  border-radius: 0;
}

.phoneContainer input:focus {
  color: #000;
  border: 1px solid #40a9ff;
  border-right-width: 1px;
  outline: none;
  box-shadow: 0 0 0 2px #1890ff33;
}

.white input:focus {
  outline: 0;
  box-shadow: none;
  border-left: 1px solid #c4cfd9;
  border-top: none;
  border-right: none;
  border-bottom: none;
}
