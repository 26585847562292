import React, { useEffect, useMemo, useReducer } from 'react';
import { useLocation } from 'react-router';
import { PhonesEntity, TaxYearEntity, TaxYearInterviewEntity } from '~src/graphql';
import { useGetAllEntityDataByEntityIdQuery } from '~src/graphql/queries/entity';
import { setClientData } from '~src/redux/slices/clientSlice';
import { useAppDispatch } from '~src/redux/hooks';
import getPrimaryPhone from '../helpers/getPrimaryPhone';

const { createContext } = React;
const TaxPayerContext = createContext<TaxpayerTypes>(null);
interface TaxpayerTypes {
  value?: any;
  children?: any;
  dispatch?: ({ type, payload }) => void | undefined;
  loading: boolean;
  urlTaxYearInterviewId: string | undefined;
  entityId: string | undefined;
  ownerId: string | undefined;
  userInformationId: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  name: string | undefined;
  birthday: Date | undefined;
  notes: string | undefined;
  phone: PhonesEntity | undefined;
  phones: PhonesEntity[] | undefined;
  languageId: string | undefined;
  languageDescription: string | undefined;
  email: string | undefined;
  events: any[];
  jobs: TaxYearInterviewEntity[];
  deleteAt: Date | undefined;
  allFilesChanged: boolean;
  clientFilesChanged: boolean;
  firmFilesChanged: boolean;
  secretOTP: string | undefined
}
const initialTaxpayer: TaxpayerTypes = {
  loading: true,
  urlTaxYearInterviewId: undefined,
  entityId: undefined,
  ownerId: undefined,
  userInformationId: undefined,
  firstName: undefined,
  lastName: undefined,
  name: undefined,
  birthday: undefined,
  notes: undefined,
  phone: undefined,
  phones: undefined,
  languageId: undefined,
  languageDescription: undefined,
  email: undefined,
  events: [],
  jobs: [],
  deleteAt: undefined,
  allFilesChanged: false,
  clientFilesChanged: false,
  firmFilesChanged: false,
  secretOTP: undefined,
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'CREATE':
      return { ...action.payload };
    case 'UPDATE':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}

function TaxpayerProvider({
  children,
}: { children: any }) {
  const [state, dispatch] = useReducer(reducer, initialTaxpayer);
  const dispatchClientState = useAppDispatch();
  const valueProvider = useMemo(() => ({
    ...state,
    dispatch,
  }), [state, dispatch]);

  // get data from this entity
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const entityId = queryParams.get('entityId');
  const taxYearInterviewId = queryParams.get('taxYearInterviewId') ?? ''; // use this to open the correct taxYearInterview by default
  const { data: entityData, loading: loadingEntityData } = useGetAllEntityDataByEntityIdQuery({
    variables: {
      id: entityId,
    },
  });
  const entity = entityData?.GetAllEntityDataByEntityId ?? null;
  const jobs = entity?.TaxYear?.flatMap(
    (taxYear: TaxYearEntity) => taxYear.TaxYearInterview.map((taxYearInterview: TaxYearInterviewEntity) => ({ ...taxYearInterview, TaxYear: taxYear })),
  ) ?? [];

  useEffect(() => {
    if (!loadingEntityData) {
      const { GetAllEntityDataByEntityId } = entityData || {};
      const { TaxYear, Users, UserFirmAccount } = GetAllEntityDataByEntityId || {};

      dispatchClientState(setClientData({
        email: Users?.email,
        entityId,
        name: `${Users?.UserInformation?.firstName} ${Users?.UserInformation?.lastName}`,
        languageId: Users?.Languages?.id,
        id: Users?.id,
        workNumber: Users?.UserInformation?.Phones?.filter((item) => item.type === 'WORK')?.[0],
        landlineNumber: Users?.UserInformation?.Phones?.filter((item) => item.type === 'LANDLINE')?.[0],
        userInformationId: Users?.UserInformation?.id,
        phoneNumber: getPrimaryPhone(Users?.UserInformation?.Phones),
        job: jobs.filter((item) => item.id === taxYearInterviewId)?.[0] ?? undefined,
      }));

      dispatch({
        type: 'UPDATE',
        payload: {
          loading: false,
          urlTaxYearInterviewId: taxYearInterviewId,
          userInformationId: Users?.UserInformation?.id,
          userId: Users?.id,
          firstName: Users?.UserInformation?.firstName,
          lastName: Users?.UserInformation?.lastName,
          name: `${Users?.UserInformation?.firstName} ${Users?.UserInformation?.lastName}`,
          birthday: Users?.UserInformation?.birthday,
          notes: Users?.UserInformation?.notes,
          phone: getPrimaryPhone(Users?.UserInformation?.Phones),
          phones: Users?.UserInformation?.Phones,
          languageId: Users?.Languages?.id,
          languageDescription: Users?.Languages?.description,
          taxYears: TaxYear,
          email: Users?.email,
          events: [],
          lastConnection: undefined,
          clientData: undefined,
          entityId,
          ownerId: Users?.id,
          jobs,
          deleteAt: UserFirmAccount?.[0]?.deleteAt, // will only have one item, because the where clause includes the firmAccountId of the requester
          secretOTP: Users?.has2Fa,
        },
      });
    }
  }, [loadingEntityData, entityData]);

  return (
    <TaxPayerContext.Provider value={valueProvider}>
      {children}
    </TaxPayerContext.Provider>
  );
}

export { TaxPayerContext, TaxpayerProvider };
