function isAllowedFileType(type: string): boolean {
  return (
    [
      'application/msword', // .doc
      'application/pdf', // .pdf
      'application/rtf', // .rtf
      'application/vnd.ms-excel', // .xls
      'application/vnd.oasis.opendocument.spreadsheet', // .ods
      'application/vnd.oasis.opendocument.text', // .odt
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'application/xml', // .xml
    ].includes(type)
  ) || (
    [
      'image/', // .bmp, .gif, .jpeg, .png, .tiff, .webp
      'text/', // .csv, .html, .txt
    ].some(
      (prefix) => type.startsWith(prefix),
    )
  );
}

export { isAllowedFileType };
