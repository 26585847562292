import {
  Dispatch, FC, SetStateAction, useContext, useEffect, useState,
} from 'react';
import {
  Typography,
  Row,
  Col,
  Button,
  message,
  Divider,
  Form,
  Icons,
  Input,
  FileUploadDragger,
  Modal,
  Select,
  Avatar,
  Tooltip,
} from 'taxaroo-ui';
import PDFMerger from 'pdf-merger-js/browser';
import moment from 'moment';
import {
  AnswerEntity, DocumentApprovalUploadInput, UpdateApprovalSignatureRequestInput,
  KbaFieldEntity, QuestionType, SectionEntity, FileApprovalEntity,
  FileTemplateEntity,
} from '~src/graphql';
import { useAppSelector } from '~src/redux/hooks';
import { JobContext } from '~src/components/providers/job-provider';
import { useGetTaxYearInterviewIncludeInterviewsQuery } from '~src/graphql/queries/taxYearInterview';
import { GetFileTemplatesDocument, useGetFileTemplatesQuery } from '~src/graphql/queries/file';
import { isMobileView } from '~src/components/helpers/screen';
import { ApolloError } from '@apollo/client';
import { useUpdateApprovalSignatureRequestMutation } from '~src/graphql/mutations/fileRequests';
import {
  useCreateFileTemplateMutation, useDeleteFileTemplateMutation,
  useDocumentApprovalUploadMutation, useDocumentSignatureUploadMutation,
  useUpdateCompiledFilePreSignedUrlMutation, useUpdateFileTemplateMutation,
} from '~src/graphql/mutations/file';
import axios from 'axios';
import { useDownloadFileFromS3Mutation } from '~src/graphql/mutations/clients';
import actionCreators from '../NewESignatureRequestModal/action.creator';
import SignerKBASection from './SignerKBASection';
import './styles.css';
import styles from './styles.module.css';

const {
  PlusOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  EditOutlined,
} = Icons;
const { Text, Paragraph } = Typography;
const { Option } = Select;

interface DocumentSignApproveRequestModalContentProps {
  type: 'sign' | 'approve',
  form: any,
  editMode: boolean,
  dispatch: any,
  state: any,
  setSendButtonDisabled: Dispatch<SetStateAction<boolean>>,
  closeModal: () => void;
  setOkButtonLoading: Dispatch<SetStateAction<boolean>>,
  refetchFileRequests: () => void,
  isCompiledFileUpdated: boolean;
  setIsCompiledFileUpdated: (value: boolean) => void,
}

const DocumentSignApproveRequestModalContent: FC<DocumentSignApproveRequestModalContentProps> = ({
  type,
  form,
  editMode,
  dispatch,
  state,
  setSendButtonDisabled,
  closeModal,
  setOkButtonLoading,
  refetchFileRequests,
  isCompiledFileUpdated,
  setIsCompiledFileUpdated,
}) => {
  const [numApprovers, setNumApprovers] = useState(1);

  const {
    taxYearInterviewId,
    clientFirstName,
    clientLastName,
    clientEmail,
    clientMobilePhone,
    clientBirthday,
    entityOwnerId,
  } = useContext(JobContext);

  const { data: interviewData } = useGetTaxYearInterviewIncludeInterviewsQuery({
    variables: {
      id: taxYearInterviewId,
    },
  });
  const {
    data: fileTemplateData, refetch: refetchFileTemplateRequest,
  } = useGetFileTemplatesQuery();

  const [createFileTemplate] = useCreateFileTemplateMutation({
    onError: (err: ApolloError) => message.error(err.message, 10),
  });
  const [updateFileTemplate] = useUpdateFileTemplateMutation({
    onError: (err: ApolloError) => message.error(err.message, 10),
  });
  const [deleteFileTemplate] = useDeleteFileTemplateMutation();
  const [updateApprovalSignatureRequest] = useUpdateApprovalSignatureRequestMutation({
    onError: (err: ApolloError) => message.error(err.message, 10),
  });
  const [updateCompiledFilePreSignedUrl] = useUpdateCompiledFilePreSignedUrlMutation({
    onError: (err: ApolloError) => message.error(err.message, 10),
  });
  const [urlS3File] = useDownloadFileFromS3Mutation();
  const [documentApprovalUpload] = useDocumentApprovalUploadMutation({
    onError: (err: ApolloError) => message.error(err.message, 10),
  });
  const [documentSignatureUpload] = useDocumentSignatureUploadMutation({
    onError: (err: ApolloError) => message.error(err.message, 10),
  });

  const { accessToken, firmAccountId, userId } = useAppSelector((s) => s.session);
  const { compiledFile, newDraggableBoundarySignFields, fileList } = state;

  const [clientLastSsn, setClientLastSsn] = useState('');
  const [client2FirstName, setClient2FirstName] = useState('');
  const [client2LastName, setClient2LastName] = useState('');
  const [client2Email, setClient2Email] = useState('');
  const [client2LastSsn, setClient2LastSsn] = useState('');
  const [client2Birthday, setClient2Birthday] = useState(null);
  const [client2MobilePhone, setClient2MobilePhone] = useState('');
  const [deletedFileIndex, setDeletedFileIndex] = useState(-1);
  const [deleteFileModalVisible, setDeleteFileModalVisible] = useState(false);

  const [templates, setTemplates] = useState<Array<FileTemplateEntity>>([]);
  const [fileToTemplateId, setFileToTemplateId] = useState('');
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [createTemplateModalVisible, setCreateTemplateModalVisible] = useState(false);
  const [createTemplateName, setCreateTemplateName] = useState('');
  const [editTemplateModalVisible, setEditTemplateModalVisible] = useState(false);
  const [editTemplateName, setEditTemplateName] = useState('');
  const [deleteTemplateModalVisible, setDeleteTemplateModalVisible] = useState(false);

  const getInterviewAnswer = (section: SectionEntity, questionDescription: string, answers: AnswerEntity[]): string => {
    const question = section.Questions?.find((q) => q.Translations?.some((t) => t.description === questionDescription));
    const answer = question ? answers.find((a) => a.questionId === question.id) : null;
    return answer?.description ?? '';
  };

  useEffect(() => {
    // get initial data
    const content = interviewData?.GetTaxYearInterviewIncludeInterviews;
    const interview = content?.Interviews;
    const clientSection = interview?.Sections?.find((s) => s.Translations?.some((t) => t.description === 'Basic Personal Information'));
    if (clientSection) {
      const answers = content.Answers;

      const clientSsn = getInterviewAnswer(clientSection, 'Taxpayer SSN/Taxpayer ID Number', answers);
      setClientLastSsn(clientSsn.substring(clientSsn.length - 4));

      const client2Section = clientSection?.SubSections?.find((s) => s.Translations?.some((t) => t.description === 'Spouse Information'));
      if (client2Section) {
        const client2Names = getInterviewAnswer(client2Section, 'Spouse Name', answers).trim().split(' ');
        setClient2FirstName(client2Names[0] ?? '');
        setClient2LastName(client2Names[1] ?? '');
        setClient2Email(getInterviewAnswer(client2Section, 'Spouse Email Address', answers));
        const client2Ssn = getInterviewAnswer(client2Section, 'Spouse SSN/Taxpayer ID Number', answers);
        setClient2LastSsn(client2Ssn.substring(client2Ssn.length - 4));
        const client2BirthdayStr = getInterviewAnswer(client2Section, 'Spouse Date of Birth', answers);
        setClient2Birthday(client2BirthdayStr ? moment(client2BirthdayStr).toDate() : null);
        setClient2MobilePhone(getInterviewAnswer(client2Section, 'Spouse Phone Number', answers));
      }
    }
  }, [interviewData]);

  useEffect(() => {
    if (fileTemplateData?.FileTemplates) {
      setTemplates(fileTemplateData?.FileTemplates);
    }
  }, [fileTemplateData]);

  const validateSubmitButton = async () => {
    if (fileList.length > 0) {
      const fields = await form
        .getFieldsError()
        .filter((field) => field.errors.length > 0);
      setSendButtonDisabled(fields.length > 0);
    } else {
      setSendButtonDisabled(true);
    }
  };

  useEffect(() => {
    validateSubmitButton();
  }, [fileList.length, selectedTemplateId]);

  useEffect(() => {
    if (editMode) {
      form.setFieldsValue({ documentTitle: state?.fileRequest?.title || '' });
      form.setFieldsValue({ reviewerNotes: state?.fileRequest?.notes || '' });
      setNumApprovers(state?.fileRequest?.FileApproval?.length);
    } else {
      form.setFieldsValue({ documentTitle: '' });
      form.setFieldsValue({ reviewerNotes: '' });
      setNumApprovers(1);
    }
  }, [state?.fileRequest?.title, state?.fileRequest?.notes, editMode]);

  // merge files into one and save to compiledFile state
  useEffect(() => {
    const render = async () => {
      const merger = new PDFMerger();

      // eslint-disable-next-line no-restricted-syntax
      for (const file of state.fileList) {
        // kyle todo: can I remove this await?
        // eslint-disable-next-line no-await-in-loop
        await merger.add(file);
      }

      const mergedPdf = await merger.saveAsBlob();
      // const url = URL.createObjectURL(mergedPdf);
      // setCompiledFile(mergedPdf);
      dispatch(actionCreators.update({
        compiledFile: mergedPdf,
      }));

      return null;
    };

    render().catch((err) => {
      throw err;
    });

    // eslint-disable-next-line no-unused-expressions
    () => dispatch(actionCreators.update({ compiledFile: null }));
  }, [JSON.stringify(state.fileList)]);

  const onFinish = async (values: any) => {
    setOkButtonLoading(true);
    // This converts the { signerKBA[0]: {}, signerKBA[1]: {} } format into { signerKBAs: [{}, {}] }
    const finalValues = {} as DocumentApprovalUploadInput;
    const signerKBAArray = [];
    // loop through object
    // eslint-disable-next-line no-restricted-syntax
    for (const key in values) {
      // eslint-disable-next-line no-prototype-builtins
      if (values.hasOwnProperty(key)) {
        const element = values[key];
        if (key.substring(0, 9) === 'signerKBA') { // the substring of signerKBA[0] is signerKBA
          element.signerId = key;
          signerKBAArray.push(element);
        } else {
          finalValues[key] = element;
        }
      }
    }
    // loop through the signerKBAArray and change moment objects to dates without time
    for (let index = 0; index < signerKBAArray.length; index += 1) {
      const element = signerKBAArray[index];
      if (element.dateOfBirth) {
        signerKBAArray[index].dateOfBirth = element.dateOfBirth.format('MM-DD-YYYY'); // Using Moment.js to format the date
      }
    }
    finalValues.signerKBAs = signerKBAArray;

    // if only one signerKBA, set the newDraggableBoundarySignFields emails to the only available signerKBA
    if (finalValues.signerKBAs.length === 1) {
      for (let index = 0; index < newDraggableBoundarySignFields.length; index += 1) {
        newDraggableBoundarySignFields[index].key = 'signerKBA[0]'; // do we need this?
        newDraggableBoundarySignFields[index].email = values?.['signerKba[0]']?.signerEmail;
      }
    }
    const signatureFields = newDraggableBoundarySignFields.map((item: any, index: number) => (
      {
        signatureId: item.id,
        signerId: item.signerId,
        email: values[item?.signerId]?.signerEmail,
        page: item.page,
        fieldLeftPercentage: item.fieldLeftPercentage,
        fieldTopPercentage: item.fieldTopPercentage,
        fieldWidthPercentage: item.fieldWidthPercentage,
        fieldHeightPercentage: item.fieldHeightPercentage,
        // fieldId: what's this?: todo
        fieldType: item.fieldType, // FieldType Enum
        title: item.title,
      }
    ));

    if (signatureFields.some((item: any) => !item.email)) {
      message.error('Please select a signer for each field by hovering over the field and selecting a client.', 10);
      setOkButtonLoading(false);
      return;
    }

    const { documentTitle, reviewerNotes, signerKBAs } = finalValues;
    const documentExt = documentTitle.split('.').pop();

    try {
      const buffer = Buffer.from(await compiledFile.arrayBuffer());
      const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
      const input = {
        compiledHash: Buffer.from(hashBuffer).toString('base64'),
        fileName: documentExt === 'pdf' ? documentTitle : `${documentTitle}.pdf`,
        fileSize: buffer.toString().length,
        taxYearInterviewId,
        clientId: entityOwnerId,
        documentTitle,
        reviewerNotes,
        signerKBAs,
      };

      const result = type === 'sign'
        ? documentSignatureUpload({
          variables: {
            documentSignatureUploadInput: {
              ...input,
              signatureFields,
            },
          },
        })
          .then((res) => res.data?.DocumentSignatureUpload)
        : documentApprovalUpload({
          variables: {
            documentApprovalUploadInput: {
              ...input,
            },
          },
        })
          .then((res) => res.data?.DocumentApprovalUpload);

      const fileUrl = new URL(await result);
      await axios.put(
        fileUrl.toString(),
        buffer,
        {
          headers: {
            'Content-Type': 'application/pdf',
          },
        },
      );

      setOkButtonLoading(false);
      message.success('Success!');
      closeModal();
      // refetch the file requests table
      refetchFileRequests();
      // reset the form data and the draggableBoundarySignFields
      form.resetFields();
      dispatch(actionCreators.resetForm());
    } catch (error) {
      message.error(`An error has occured: ${error}`);
      setOkButtonLoading(false);
    }
  };

  const onUpdate = async (values: any) => {
    setOkButtonLoading(true);

    const signatureFields = newDraggableBoundarySignFields.map((item: any) => ({
      id: item.id,
      signerId: item.signerId,
      email: values[item?.signerId]?.signerEmail,
      page: item.page,
      fieldLeftPercentage: item.fieldLeftPercentage,
      fieldTopPercentage: item.fieldTopPercentage,
      fieldWidthPercentage: item.fieldWidthPercentage,
      fieldHeightPercentage: item.fieldHeightPercentage,
      fieldType: item.fieldType,
      title: item.title,
      fileApprovalId: item.fileApprovalId,
      signatureImage: item.signatureImage,
      completedAt: item.completedAt,
    }));

    const initialSignatureFields = [];
    state.fileRequest?.FileApproval?.forEach((fa: FileApprovalEntity) => {
      fa?.SignatureFields?.forEach((field) => initialSignatureFields.push(field));
    });

    const signerKBAArray = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in values) {
      // eslint-disable-next-line no-prototype-builtins
      if (values.hasOwnProperty(key)) {
        const element = values[key];
        if (key.substring(0, 9) === 'signerKBA') { // the substring of signerKBA[0] is signerKBA
          element.signerId = key;
          signerKBAArray.push(element);
        }
      }
    }

    const updateValues: UpdateApprovalSignatureRequestInput = {
      id: state.fileRequest.id,
      title: values.documentTitle,
      notes: values.reviewerNotes,
      type,
      isCompiledFileUpdated,
      taxYearInterviewId: state.fileRequest.taxYearInterviewId,
      fileApprovals: signerKBAArray.map((signer: any, index) => {
        const kBaFields = [];
        const fileApprovalId = state.fileRequest?.FileApproval?.[index]?.id;
        const createSignatureFields = signatureFields.filter((sf: any) => !sf.fileApprovalId
          && sf.email === signer.signerEmail && sf.signerId === signer.signerId);
        const updateSignatureFields = signatureFields.filter((sf: any) => sf.fileApprovalId
          && sf.fileApprovalId === fileApprovalId && !sf.completedAt);
        const deleteSignatureFields = isCompiledFileUpdated
          ? initialSignatureFields.filter((sf) => sf.fileApprovalId && sf.fileApprovalId === fileApprovalId
              && !updateSignatureFields.some((usf: any) => usf.id === sf.id))
          : initialSignatureFields.filter((sf) => sf.fileApprovalId
              && sf.fileApprovalId === fileApprovalId && !sf.completedAt
              && !updateSignatureFields.some((usf: any) => usf.id === sf.id));

        switch (signer.signatureKBAType) {
          case 'BASIC':
            kBaFields.push({
              type: QuestionType.Date,
              description: 'dateOfBirth',
              value: signer.dateOfBirth.format('MM/DD/YYYY'),
            });
            kBaFields.push({
              type: QuestionType.Text,
              description: 'lastFourSSN',
              value: signer.lastFourSSN.trim(),
            });
            kBaFields.push({
              type: QuestionType.Text,
              description: 'name',
              value: signer.name.trim(),
            });
            break;
          case 'ENHANCED':
            kBaFields.push({
              type: QuestionType.Phone,
              description: 'mobilePhone',
              value: signer.mobilePhone.trim(),
            });
            break;
          default: break;
        }

        return {
          id: fileApprovalId,
          name: signer.signerName.trim(),
          email: signer.signerEmail.trim().toLowerCase(),
          action: fileApprovalId ? 'update' : 'create',
          taskId: state.fileRequest?.FileApproval?.[index]?.Tasks?.id,
          kBA: state.fileRequest?.FileApproval?.[index]?.completedAt // do not send id for completed KBA (to not delete them)
            ? {
              type: signer.signatureKBAType,
              kBaFields,
            }
            : {
              id: state.fileRequest?.FileApproval?.[index]?.KBA?.[0]?.id,
              type: signer.signatureKBAType,
              kBaFields,
            },
          signatureFields: {
            create: createSignatureFields.map((sf: any) => ({
              id: sf.id,
              page: sf.page,
              fieldLeftPercentage: sf.fieldLeftPercentage,
              fieldTopPercentage: sf.fieldTopPercentage,
              fieldWidthPercentage: sf.fieldWidthPercentage,
              fieldHeightPercentage: sf.fieldHeightPercentage,
              fieldType: sf.fieldType,
              title: sf.title,
            })),
            update: updateSignatureFields.map((sf: any) => ({
              id: sf.id,
              page: sf.page,
              fieldLeftPercentage: sf.fieldLeftPercentage,
              fieldTopPercentage: sf.fieldTopPercentage,
              fieldWidthPercentage: sf.fieldWidthPercentage,
              fieldHeightPercentage: sf.fieldHeightPercentage,
              fieldType: sf.fieldType,
              title: sf.title,
            })),
            delete: deleteSignatureFields.map((sf: any) => ({
              id: sf.id,
              page: sf.page,
              fieldLeftPercentage: sf.fieldLeftPercentage,
              fieldTopPercentage: sf.fieldTopPercentage,
              fieldWidthPercentage: sf.fieldWidthPercentage,
              fieldHeightPercentage: sf.fieldHeightPercentage,
              fieldType: sf.fieldType,
              title: sf.title,
            })),
          },
        };
      }),
    };

    // remove file approvals KBA that were not updated from updateValues array
    state.fileRequest?.FileApproval.forEach((fa: FileApprovalEntity) => {
      const uFa = updateValues.fileApprovals.find((updateFileApproval) => updateFileApproval.id === fa.id);
      if (uFa) {
        const updatedIndex = updateValues.fileApprovals.findIndex((updateFileApproval) => updateFileApproval.id === fa.id);
        if (fa.name.trim() === uFa.name && fa.email.trim().toLowerCase() === uFa.email && fa.KBA?.[0].type === uFa.kBA.type) {
          if (uFa.kBA.type === 'BASIC'
            && fa.KBA?.[0].KBAFields.find((field) => field.description === 'dateOfBirth')?.value === uFa.kBA.kBaFields[0].value
            && fa.KBA?.[0].KBAFields.find((field) => field.description === 'lastFourSSN')?.value.trim() === uFa.kBA.kBaFields[1].value
            && fa.KBA?.[0].KBAFields.find((field) => field.description === 'name')?.value.trim() === uFa.kBA.kBaFields[2].value) {
            updateValues.fileApprovals[updatedIndex].kBA.id = undefined;
            updateValues.fileApprovals[updatedIndex].kBA.kBaFields = undefined;
          } else if (uFa.kBA.type === 'ENHANCED'
            && fa.KBA?.[0].KBAFields.find((field) => field.description === 'mobilePhone')?.value.trim() === uFa.kBA.kBaFields[0].value) {
            updateValues.fileApprovals[updatedIndex].kBA.id = undefined;
            updateValues.fileApprovals[updatedIndex].kBA.kBaFields = undefined;
          } else if (uFa.kBA.type === 'WITHOUT') {
            updateValues.fileApprovals[updatedIndex].kBA.id = undefined;
            updateValues.fileApprovals[updatedIndex].kBA.kBaFields = undefined;
          }
        }
      }
    });

    // if some of the approvals needs to be deleted
    const diff = state.fileRequest.FileApproval.length - signerKBAArray.length;
    if (diff > 0) {
      for (let i = 0; i < diff; i += 1) {
        const fa = state.fileRequest?.FileApproval?.[signerKBAArray.length + i];
        updateValues.fileApprovals.push({
          id: fa?.id,
          name: fa?.name,
          email: fa?.email,
          action: 'delete',
          taskId: fa?.Tasks?.id,
          kBA: {
            id: fa?.KBA?.[0]?.id,
            type: fa?.KBA?.[0]?.type,
            kBaFields: fa?.KBA?.[0]?.KBAFields?.map((field: KbaFieldEntity) => ({
              ...field,
              __typename: undefined,
            })),
          },
        });
      }
    }

    try {
      const result = await updateApprovalSignatureRequest({
        variables: {
          input: updateValues,
        },
      });

      if (result?.data?.updateApprovalSignatureRequest?.id) {
        if (isCompiledFileUpdated) {
          const buffer = Buffer.from(await compiledFile.arrayBuffer());
          const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
          const presignedUrlData = await updateCompiledFilePreSignedUrl({
            variables: {
              fileRequestId: state.fileRequest.id,
              compiledHash: Buffer.from(hashBuffer).toString('base64'),
              fileSize: buffer.toString().length,
            },
          });

          if (presignedUrlData?.data?.updateCompiledFilePreSignedUrl) {
            const fileUrl = new URL(presignedUrlData.data.updateCompiledFilePreSignedUrl);

            await axios.put(
              fileUrl.toString(),
              buffer,
              {
                headers: {
                  'Content-Type': 'application/pdf',
                },
              },
            );
          }
        }
        refetchFileRequests();
        dispatch(actionCreators.resetForm());
        message.success('Success!');
      }

      setOkButtonLoading(false);
      closeModal();
    } catch (err) {
      message.error(`An error has occured: ${err.message}`);
      setOkButtonLoading(false);
    }
  };

  const handleFileUpload = async (options: any) => {
    console.log('handleFileUpload - options: ', options);
    // Atleast one file is uploaded. Now call the dispatch and inform the parent
    // dispatch({ type: "FILE_UPLOADED_SUCCESS", payload: state.fileUploadedSuccessCount += 1 });
    return options.onSuccess('ok');
  };

  const fileUploadOnChange = (info: any) => {
    const { status } = info.file;

    if (status === 'uploading') {
      console.log('Uploading');
    } else if (status === 'done') {
      console.log('done uploading');
      // console.log('info.file', info.file.originFileObj);
      dispatch(actionCreators.update({
        fileList: [...state.fileList, info.file.originFileObj],
      }));
      message.success(`${info.file.name} file uploaded successfully.`);
      const documentTitle = form.getFieldValue('documentTitle');
      if (!documentTitle) {
        form.setFieldsValue({ documentTitle: info.file.name });
      }
      setIsCompiledFileUpdated(true);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onSelectedTemplateChange = (id) => {
    setSelectedTemplateId(id);
  };

  const onCreateTemplate = (id: string) => {
    setFileToTemplateId(id);
    setCreateTemplateName('');
    setCreateTemplateModalVisible(true);
  };

  const handleCreateTemplate = async () => {
    const file = fileList.find((x: any) => x.uid === fileToTemplateId);

    try {
      const result = await createFileTemplate({
        variables: {
          templateName: createTemplateName,
          fileName: file.name,
        },
      });

      if (result.data.CreateFileTemplate) {
        const fileUrl = new URL(result.data.CreateFileTemplate);
        const buffer = Buffer.from(await file.arrayBuffer());

        await axios.put(
          fileUrl.toString(),
          buffer,
          {
            headers: {
              'Content-Type': 'application/pdf',
            },
          },
        );

        message.success('Template is successfully created');
        refetchFileTemplateRequest();
      }
    } catch (error) {
      message.error(`An error has occured: ${error.message}`, 10);
    }
  };

  const onUseTemplate = async () => {
    try {
      const template = templates.find((x) => x.id === selectedTemplateId);
      const { data } = await urlS3File({ variables: { s3Key: template.s3Key } });
      const response = await fetch(data?.downloadFile?.signedUrl);
      if (response.ok) {
        const blob = await response.blob();
        const file = new File([blob], template.fileName, { type: 'application/pdf' });

        dispatch(actionCreators.update({
          fileList: [...state.fileList, file],
          eSignatureModalOkText: 'Add Signature Fields',
        }));

        const documentTitle = form.getFieldValue('documentTitle');
        if (!documentTitle) {
          form.setFieldsValue({ documentTitle: template.name });
        }

        message.success('Template was used');
        setIsCompiledFileUpdated(true);
      }
    } catch (error) {
      message.error('Error on downloading file template', 10);
    }
  };

  const onRenameTemplate = () => {
    const template = templates.find((x) => x.id === selectedTemplateId);
    setEditTemplateName(template.name);
    setEditTemplateModalVisible(true);
  };

  const handleRenameTemplate = () => {
    updateFileTemplate({
      variables: {
        id: selectedTemplateId,
        templateName: editTemplateName,
      },
      refetchQueries: [
        GetFileTemplatesDocument,
      ],
    });
  };

  const onDeleteTemplate = () => {
    setDeleteTemplateModalVisible(true);
  };

  const handleDeleteTemplate = () => {
    deleteFileTemplate({
      variables: {
        id: selectedTemplateId,
      },
      onCompleted: () => {
        refetchFileTemplateRequest();
        setSelectedTemplateId('');
        message.success('Template is successfully deleted.');
      },
      onError: () => {
        message.error('Cannot delete the template.');
      },
    });
  };

  const onDeleteFile = (uid: string, index: number) => {
    setDeletedFileIndex(index);
    setDeleteFileModalVisible(true);
  };

  const handleDeleteFile = () => {
    const deleteFileRemainingList = fileList.filter((file, index) => index !== deletedFileIndex);
    dispatch(actionCreators.update({
      // fileUploadedSuccessCount: state.fileUploadedSuccessCount += 1,
      fileList: deleteFileRemainingList,
      eSignatureModalOkText: 'Add Signature Fields',
      newDraggableBoundarySignFields: [], // reset the draggable boundary fields
    }));
    setDeletedFileIndex(-1);
    setIsCompiledFileUpdated(true);
  };

  const handleRemoveApprover = async (indexToRemove: number) => {
    setNumApprovers((prev) => prev - 1);

    // Get the current form values
    const allFormValues = await form.getFieldsValue();

    // Create a new signerKBA object without the removed key using destructuring
    const { [`signerKBA[${indexToRemove}]`]: valueToRemove, ...remainingSignerKBA } = allFormValues;
    valueToRemove.dateOfBirth = null;
    valueToRemove.lastFourSSN = null;
    valueToRemove.name = null;
    valueToRemove.signatureKBAType = null;
    valueToRemove.signerEmail = null;
    valueToRemove.signerName = null;
    valueToRemove.mobilePhone = null;

    // Set the updated values back into the form
    await form.setFieldsValue({
      [`signerKBA[${indexToRemove}]`]: valueToRemove,
      ...remainingSignerKBA,
    });
    // revalidate the form to enable the submit button (if button was disabled from invalid data related to deleted approver)
    validateSubmitButton();

    // remove signature fields related to the removed approver
    const tmpNewDraggableBoundarySignFields = state.newDraggableBoundarySignFields.filter((item) => item.signerId !== `signerKBA[${indexToRemove}]`);
    dispatch(actionCreators.update({ newDraggableBoundarySignFields: tmpNewDraggableBoundarySignFields }));
  };

  const beforeUpload = (file: File) => {
    const { size } = file;
    const maxSize = (1024 * 1024) * 25; // 25MB

    if (size > maxSize) {
      message.error('File size exceeds 25MB');
      return false;
    }

    return true;
  };

  return (
    <div
      className="document-sign-approve-request-modal-content"
      style={{
        height: type === 'sign' ? 510 : 420,
        overflowY: type === 'sign' ? 'scroll' : undefined,
        paddingRight: isMobileView() ? 0 : 30,
      }}
    >
      <Text><b>Add documents for review:</b></Text>
      <Paragraph style={{ fontSize: 'small' }}>
        Send to one or more
        {' '}
        {type === 'sign' ? 'signers' : 'approvers'}
        {' '}
        below, using basic or enhanced Knowledge-Based Authentication (KBA)
      </Paragraph>
      <div style={{ height: '10px' }} />

      {templates.length > 0 && (
        <div className={styles.templateContainer}>
          <span style={{ marginRight: 10 }}>
            Select Template:
          </span>
          <Select
            className={styles.templateSelect}
            value={selectedTemplateId}
            onChange={onSelectedTemplateChange}
          >
            <Option value="">
              Not selected
            </Option>
            {templates.map((template, index) => (
              <Option value={template.id} key={index}>
                {template.name}
              </Option>
            ))}
          </Select>

          {selectedTemplateId && (
            <>
              <Tooltip title="Use Template">
                <Avatar
                  icon={(
                    <CheckCircleOutlined
                      onClick={onUseTemplate}
                    />
                  )}
                />
              </Tooltip>
              <Tooltip title="Rename Template">
                <Avatar
                  icon={(
                    <EditOutlined
                      onClick={onRenameTemplate}
                    />
                  )}
                />
              </Tooltip>
              <Tooltip title="Delete Template">
                <Avatar
                  icon={(
                    <DeleteOutlined
                      onClick={onDeleteTemplate}
                    />
                  )}
                />
              </Tooltip>
            </>
          )}
        </div>
      )}

      <FileUploadDragger
        style={{ backgroundColor: 'aliceblue', height: '200px!important' }}
        showUploadList={false}
        accept=".pdf"
        customRequest={handleFileUpload}
        beforeUpload={beforeUpload}
        name="file"
        multiple
        onChange={fileUploadOnChange}
        fileList={state.fileList}
        createTemplate={onCreateTemplate}
        deleteFile={onDeleteFile}
      />

      <Divider />
      <Typography>
        <Paragraph>
          <Text>
            <b>
              Who needs to
              {' '}
              {type === 'sign' ? 'sign' : 'approve'}
              ?
            </b>
          </Text>
        </Paragraph>
      </Typography>
      <Form
        form={form}
        onFinish={(values: any) => {
          if (editMode) onUpdate(values);
          else onFinish(values);
        }}
        layout="vertical"
        onFieldsChange={validateSubmitButton}
      >
        {[...Array(numApprovers)].map((_, index) => (
          <div key={`signer-kba-section-${index}`}>
            <SignerKBASection
              numApprovers={numApprovers}
              index={index}
              handleClose={() => handleRemoveApprover(index)}
              type={type === 'sign' ? 'signer' : 'approver'}
              fileRequestId={state?.fileRequest?.id}
              initialApproverFullName={
                state?.fileRequest?.FileApproval?.[index]?.name
                || [`${clientFirstName} ${clientLastName}`, `${client2FirstName} ${client2LastName}`][index] || ''
              }
              initialApproverEmail={
                state?.fileRequest?.FileApproval?.[index]?.email
                || [clientEmail, client2Email][index] || ''
              }
              initialApproverLastName={
                state?.fileRequest?.FileApproval?.[index]?.KBA?.[0].KBAFields.find((f: KbaFieldEntity) => f.description === 'name')?.value
                || [clientLastName, client2LastName][index] || ''
              }
              initialApproverPhone={
                state?.fileRequest?.FileApproval?.[index]?.KBA?.[0].KBAFields.find((f: KbaFieldEntity) => f.description === 'mobilePhone')?.value
                || [clientMobilePhone?.value, client2MobilePhone][index] || ''
              }
              initialApproverBirthday={
                state?.fileRequest?.FileApproval?.[index]?.KBA?.[0].KBAFields.find((f) => f.description === 'dateOfBirth')?.value
                  ? new Date(state.fileRequest.FileApproval[index].KBA[0].KBAFields.find((f: KbaFieldEntity) => f.description === 'dateOfBirth').value)
                  : [clientBirthday, client2Birthday][index] || null
              }
              initialApproverLastSsn={
                state?.fileRequest?.FileApproval?.[index]?.KBA?.[0].KBAFields.find((f: KbaFieldEntity) => f.description === 'lastFourSSN')?.value
                || [clientLastSsn, client2LastSsn][index] || ''
              }
              form={form}
              initialKbaType={state?.fileRequest?.FileApproval?.[index]?.KBA?.[0]?.type}
            />
            {index + 1 !== numApprovers ? <Divider /> : <div style={{ height: '30px' }} />}
          </div>
        ))}
        <Button
          type="primary"
          onClick={() => setNumApprovers((prev) => prev + 1)}
          icon={<PlusOutlined />}
        >
          Add
          {' '}
          {type === 'sign' ? 'signer' : 'approver'}
        </Button>
        <Divider />
        <Row>
          <Col span={isMobileView() ? 8 : 4} offset={isMobileView() ? 0 : 4}>
            <Typography>
              <Paragraph>
                <Text><b>Document title</b></Text>
              </Paragraph>
            </Typography>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: 'Document title is required' }]}
              name="documentTitle"
              extra="The name that will be visible to the client"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={isMobileView() ? 8 : 4} offset={isMobileView() ? 0 : 4}>
            <Typography>
              <Paragraph>
                <Text><b>Note to Client</b></Text>
              </Paragraph>
            </Typography>
          </Col>
          <Col span={12}>
            <Form.Item
              name="reviewerNotes"
            >
              <Input.TextArea placeholder="Describe what is being sent (optional)" />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Modal
        visible={deleteFileModalVisible}
        title="Deleting this document is permanent. Are you sure?"
        onCancel={() => {
          setDeleteFileModalVisible(false);
          setDeletedFileIndex(-1);
        }}
        onOk={() => {
          setDeleteFileModalVisible(false);
          handleDeleteFile();
        }}
        okText="Delete File"
        okType="primary"
        okButtonProps={{ danger: true }}
      >
        <Text>You are about to permanently delete this file.</Text>
      </Modal>

      <Modal
        visible={createTemplateModalVisible}
        title="Do you really want to save this file as template?"
        onCancel={() => {
          setCreateTemplateModalVisible(false);
          setFileToTemplateId('');
        }}
        onOk={() => {
          setCreateTemplateModalVisible(false);
          handleCreateTemplate();
        }}
        okText="Create Template"
        okType="primary"
        okButtonProps={{
          disabled: !createTemplateName.trim().length,
        }}
      >
        <Text>
          You are about to save the file as template. Please input the name for the template:
        </Text>
        <Input
          value={createTemplateName}
          onChange={(e) => setCreateTemplateName(e.target.value)}
        />
      </Modal>

      <Modal
        visible={editTemplateModalVisible}
        title="Rename template"
        onCancel={() => {
          setEditTemplateModalVisible(false);
        }}
        onOk={() => {
          setEditTemplateModalVisible(false);
          handleRenameTemplate();
        }}
        okText="Rename"
        okType="primary"
        okButtonProps={{
          disabled: !editTemplateName.trim().length,
        }}
      >
        <Input
          value={editTemplateName}
          onChange={(e) => setEditTemplateName(e.target.value)}
        />
      </Modal>

      <Modal
        visible={deleteTemplateModalVisible}
        title="Deleting this template is permanent. Are you sure?"
        onCancel={() => {
          setDeleteTemplateModalVisible(false);
        }}
        onOk={() => {
          setDeleteTemplateModalVisible(false);
          handleDeleteTemplate();
        }}
        okText="Delete Template"
        okType="primary"
        okButtonProps={{ danger: true }}
      >
        <Text>You are about to permanently delete this template.</Text>
      </Modal>
    </div>
  );
};

export default DocumentSignApproveRequestModalContent;
