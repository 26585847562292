/* eslint-disable camelcase */
import { FC, useCallback, useState } from 'react';
import {
  Typography,
  Row,
  message,
} from 'taxaroo-ui';
import Logo from '~src/assets/images/taxaroo_logo.svg';
import { AddressType, BusinessType, PhoneType } from '~src/graphql';
import {
  useCreateBusinessInformationMutation,
  useRemoveBusinessInformationMutation,
  useUpdateBusinessInformationMutation,
  useUpdateFirmAccountMutation,
  useUpdateUserInformationMutation,
} from '~src/graphql/mutations/settings';
import { GetBusinessInformationDocument, GetUserInformationDocument, useGetBusinessInformationQuery } from '~src/graphql/queries/settings';
import { useAppDispatch, useAppSelector } from '~src/redux/hooks';
import { RegisterStep, selectRegisterState } from '~src/redux/slices/registerSlice';
import store from '~src/redux/store';
import BusinessAddress from '../BusinessAddress';
import BusinessInfo from '../BusinessInfo';
import IntroVideo from '../IntroVideo';
import PersonalAddress from '../PersonalAddress';
import PersonalInfo from '../PersonalInfo';
import SelectPlan from '../SelectPlan';
import * as styles from '../style.module.css';

const { Title } = Typography;

interface RegisterStepsProps {
  setModalVisible?: (value?: boolean) => void;
  markBasicInfoStepComplete?: () => void;
}

const RegisterSteps: FC<RegisterStepsProps> = ({ setModalVisible, markBasicInfoStepComplete }) => {
  const { userRole, firmAccountId, userInformationId } = useAppSelector((state) => state.session);
  const [step, setStep] = useState<keyof typeof RegisterStep>(RegisterStep.PERSONAL_INFO);
  const [isPreparer] = useState<boolean>(userRole === 'TAX_PREPARER');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // redux
  const {
    personalInfo: {
      the_first_name,
      the_last_name,
      the_operating_as,
      the_date_of_birth,
    },
    // personalAddress, // got the get these directly from store in onSave function
    // businessAddress, // got the get these directly from store in onSave function
    businessInfo,
    businessInfo: {
      the_business_name, the_business_phone, the_business_phone_id, the_tax_returns,
    },
  } = useAppSelector(selectRegisterState);

  // graphql
  // query to fetch existing business information
  const { data: firmData } = useGetBusinessInformationQuery();
  const currentBusinessInfo = firmData?.BusinessInformation || {};
  // Create business info
  const [
    createBusinessInformationMutation,
  ] = useCreateBusinessInformationMutation({
    refetchQueries: [{
      query: GetBusinessInformationDocument,
    }],
    awaitRefetchQueries: true,
  });
  // Update business info
  const [
    updateBusinessInformationMutation,
  ] = useUpdateBusinessInformationMutation({
    refetchQueries: [{
      query: GetBusinessInformationDocument,
    }],
    awaitRefetchQueries: true,
  });
  // Mutation to update user information
  const [updateUserInformation] = useUpdateUserInformationMutation({
    refetchQueries: [{
      query: GetUserInformationDocument,
      variables: { id: userInformationId },
    }, ...(isPreparer && businessInfo ? [
      {
        query: GetBusinessInformationDocument,
      },
    ] : [])],
    awaitRefetchQueries: true,
  });
  const [removeBusinessInfo] = useRemoveBusinessInformationMutation({
    refetchQueries: [{
      query: GetBusinessInformationDocument,
    }],
    awaitRefetchQueries: true,
  });
  const [updateFirmAccount] = useUpdateFirmAccountMutation({
    refetchQueries: [{
      query: GetBusinessInformationDocument,
    }],
    awaitRefetchQueries: true,
  });

  const onSave = useCallback(() => {
    // don't know why I need to get this directly from the store but I do
    const businessAddressFromStore = store.getState().register.businessAddress;
    const personalAddressFromStore = store.getState().register.personalAddress;
    if (isPreparer) {
      // setCustomError(null);
      setIsLoading(true);
      Promise.all([
        updateUserInformation({
          variables: {
            updateUserInformationInput: {
              lastName: the_last_name,
              firstName: the_first_name,
              id: userInformationId,
              birthday: the_date_of_birth,
              Address: personalAddressFromStore.the_line1 ? [
                {
                  zip: personalAddressFromStore.the_zip_code,
                  city: personalAddressFromStore.the_city,
                  state: personalAddressFromStore.the_state,
                  lineOne: personalAddressFromStore.the_line1,
                  lineTwo: personalAddressFromStore?.the_line2,
                  id: personalAddressFromStore?.the_id || undefined,
                  type: 'PERSONAL' as AddressType,
                },
              ] : undefined,
            },
          },
        }),
        businessInfo?.the_id
          ? [
            the_operating_as === 'Company' ? [
              updateBusinessInformationMutation({
                variables: {
                  updateBusinessInformationInput: {
                    taxReturns: the_tax_returns,
                    businessName: the_business_name,
                    businessType: the_operating_as === 'Company' ? BusinessType.Company : BusinessType.Individual,
                    ...(((businessAddressFromStore?.the_id?.length && currentBusinessInfo?.Phones?.[0]?.id?.length)
                    || (!businessAddressFromStore?.the_id?.length && currentBusinessInfo?.Phones?.[0]?.id?.length)
                    || (businessAddressFromStore?.the_id?.length && !currentBusinessInfo?.Phones?.[0]?.id?.length)) ? {
                        Address: [
                          {
                            zip: businessAddressFromStore?.the_zip_code,
                            city: businessAddressFromStore?.the_city,
                            state: businessAddressFromStore?.the_state,
                            id: businessAddressFromStore?.the_id,
                            lineOne: businessAddressFromStore?.the_line1,
                            lineTwo: businessAddressFromStore?.the_line2,
                          },
                        ],
                        ...(the_business_phone?.length) && {
                          Phones: [
                            {
                              id: currentBusinessInfo?.Phones?.[0]?.id || undefined,
                              value: the_business_phone,
                            },
                          ],
                        },
                      } : {}),
                    id: businessInfo?.the_id,
                  },
                },
              }),
            ] : [
              updateFirmAccount({
                variables: {
                  updateFirmAccountInput: {
                    name: null,
                    businessType: BusinessType.Individual,
                    id: firmAccountId,
                  },
                },
              }),
              removeBusinessInfo({
                variables: {
                  id: businessInfo?.the_id,
                },
              }),
            ],
          ]
          : [
            ...(the_operating_as ? [
              updateFirmAccount({
                variables: {
                  updateFirmAccountInput: {
                    businessType: the_operating_as === 'Company' ? BusinessType.Company : BusinessType.Individual,
                    id: firmAccountId,
                    name: the_business_name,
                  },
                },
              }),
            ] : []),
            ...(the_operating_as === 'Company') ? [
              createBusinessInformationMutation({
                variables: {
                  createBusinessInformationInput: {
                    taxReturns: the_tax_returns,
                    Phones: [
                      {
                        value: businessInfo?.the_business_phone,
                        type: 'OFFICE' as PhoneType,
                      },
                    ],
                    Address: [
                      {
                        zip: businessAddressFromStore?.the_zip_code,
                        city: businessAddressFromStore?.the_city,
                        state: businessAddressFromStore?.the_state,
                        lineOne: businessAddressFromStore?.the_line1,
                        lineTwo: businessAddressFromStore?.the_line2,
                        type: 'BUSINESS' as AddressType,
                      },
                    ],
                  },
                },
              }),
            ] : [],
          ],
      ])
        .then(() => {
          message.success('Information saved successfully');
          setModalVisible(false);
          markBasicInfoStepComplete();
        })
        .catch((error) => message.error(error.message))
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      updateUserInformation({
        variables: {
          updateUserInformationInput: {
            birthday: the_date_of_birth,
            lastName: the_last_name,
            firstName: the_first_name,
            id: userInformationId,
            ...(personalAddressFromStore?.the_line1 ? {
              Address: personalAddressFromStore?.the_line1 ? [
                {
                  zip: personalAddressFromStore?.the_zip_code,
                  city: personalAddressFromStore?.the_city,
                  state: personalAddressFromStore?.the_state,
                  lineOne: personalAddressFromStore?.the_line1,
                  lineTwo: personalAddressFromStore?.the_line2,
                  id: personalAddressFromStore?.the_id || undefined,
                  type: 'PERSONAL' as AddressType,
                },
              ] : undefined,
            } : {}),
          },
        },
      }).then(() => {
        message.success('Information saved successfully');
        setModalVisible(false);
        markBasicInfoStepComplete();
      })
        .catch((error) => message.error(error.message))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    isPreparer,
    the_date_of_birth,
    the_last_name,
    the_first_name,
    the_business_phone,
    the_business_phone_id,
    the_tax_returns,
    the_business_name,
    the_operating_as,
    businessInfo,
    firmAccountId,
    userInformationId,
    setIsLoading,
    updateUserInformation,
    createBusinessInformationMutation,
    updateBusinessInformationMutation,
    updateFirmAccount,
    removeBusinessInfo,
  ]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const CurrentStep = () => {
    switch (step) {
      case 'PERSONAL_INFO':
        return (<PersonalInfo setStep={setStep} signUpFlow={!setModalVisible} />);
      case 'PERSONAL_ADDRESS':
        return (<PersonalAddress setStep={setStep} signUpFlow={!setModalVisible} onSave={onSave} loadingSave={isLoading} />);
      case 'BUSINESS_INFO':
        return (<BusinessInfo setStep={setStep} signUpFlow={!setModalVisible} />);
      case 'BUSINESS_ADDRESS':
        return (<BusinessAddress setStep={setStep} signUpFlow={!setModalVisible} onSave={onSave} loadingSave={isLoading} />);
      case 'SELECT_PLAN':
        return (<SelectPlan setStep={setStep} />);
      case 'VIDEO':
        return (
          <IntroVideo
            setStep={setStep}
            signUpFlow={!setModalVisible}
            setModalVisible={setModalVisible}
          />
        );
      case 'DONE':
        setModalVisible(false);
        return null;
      default:
        return (<PersonalInfo signUpFlow={!setModalVisible} setStep={setStep} />);
    }
  };

  return (
    <div className={`showScrollbar ${styles.registerFlowContainer}`}>
      <Row justify="center">
        <Logo width="170" height="40" />
      </Row>
      <Row justify="center">
        <Title level={3} style={{ color: '#0d57a5', textAlign: 'center' }}>Let&apos;s set up your new Taxaroo account</Title>
      </Row>
      <Row justify="center" style={{ flex: 1, marginTop: '30px', marginRight: 12 }}>
        <CurrentStep />
      </Row>
    </div>
  );
};

RegisterSteps.defaultProps = {
  setModalVisible: null,
  markBasicInfoStepComplete: null,
};

export default RegisterSteps;
