import React from 'react';
import InfiniteScroll, { Props } from 'react-infinite-scroll-component';

/** DOCS { https://github.com/ankeetmaini/react-infinite-scroll-component#readme } */

interface MyProps {
  isInfiniteScrollOn: boolean,
  infiniteScrollProps: Props,
}

const InfiniteScrollWrapper: React.FC<MyProps> = ({
  isInfiniteScrollOn,
  infiniteScrollProps,
  children,
}) => {
  if (!isInfiniteScrollOn) return <div>{children}</div>;
  return (
    <div>
      <InfiniteScroll
        {...infiniteScrollProps}
        loader={<h4>Loading...</h4>}
        endMessage={(
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        )}
      >
        {children}
      </InfiniteScroll>
    </div>
  );
};

export default InfiniteScrollWrapper;
