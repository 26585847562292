import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Typography,
  Row,
  Col,
  Space,
  Popover,
  Input,
  Alert,
  message,
  Layout,
  Icons,
  Form,
  Spin,
  Button,
} from 'taxaroo-ui';
import { useGetCustomLayoutsQuery } from '~src/graphql/queries/settings';
import { useUpdatePixelsMutation } from '~src/graphql/mutations/settings';
import { useUpgradeModal, Features } from '~src/components/atoms/UpgradeModal/ModalProvider';

const { InfoCircleTwoTone } = Icons;
const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;

// interface PixelProps {}

const Pixels: FC = () => {
  const { data, loading: loadingGetCustomLayout } = useGetCustomLayoutsQuery();
  const [updatePixelsMutation, { loading }] = useUpdatePixelsMutation({
    onCompleted: () => message.success('Pixels updated successfully'),
    onError: (error) => message.error(error.message),
  });

  const { showModal, hideModal, accessObject } = useUpgradeModal();

  const validationSchema = yup.object().shape({
    pixelCode: yup.string().nullable(), // allow user to remove pixel code
  });

  const [savedPixelCode, setSavedPixelCode] = useState('');
  const [pixelCode, setPixelCode] = useState('');

  const formik = useFormik({
    initialValues: {
      pixelCode: '',
    },
    validationSchema,
    onSubmit: (values) => {
      updatePixelsMutation({
        variables: {
          updateCustomLayoutInput: {
            pixelCode: values.pixelCode,
          },
        },
        onCompleted: () => {
          setSavedPixelCode(pixelCode);
        },
      });
    },
  });

  const getMoreInfoContent = () => (
    <Row>
      <Typography>
        <Paragraph>
          Place your pixel code to be added to your client portal here.
          {' '}
          <a href="https://help.taxaroo.com/article/31-how-pixels-work" target="_blank" rel="noreferrer">
            See our help document here
          </a>
          .
        </Paragraph>
      </Typography>
    </Row>
  );

  useEffect(() => {
    const value = data?.CustomLayout?.pixelCode;
    setSavedPixelCode(value);
    setPixelCode(value);
  }, [data]);

  useEffect(() => {
    formik.setFieldValue('pixelCode', pixelCode);
  }, [pixelCode]);

  if (loadingGetCustomLayout) {
    return <Spin size="large" />;
  }

  // check if user has access to this feature
  if (!accessObject?.[Features.pixels]) {
    showModal(Features.pixels, false);
  } else {
    hideModal();
  }

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <Space direction="vertical" style={{ width: '98%' }}>
        <Row gutter={16} style={{ borderBottom: '1px dashed #D6D6D6', marginRight: 15 }}>
          <Col>
            <Title level={3}>Pixels Integration</Title>
          </Col>
          <Col>
            <Popover
              overlayStyle={{ width: '50%' }}
              placement="right"
              content={getMoreInfoContent}
              title="How does this work?"
              trigger="click"
            >
              <InfoCircleTwoTone />
            </Popover>
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography>
              <Paragraph>
                <Text>Place your pixel code here.</Text>
              </Paragraph>
            </Typography>
          </Col>
        </Row>
        <Form name="primary" onFinish={formik.handleSubmit}>
          <Row>
            <Col style={{ width: '80%' }}>
              <TextArea
                rows={6}
                name="pixelCode"
                value={pixelCode}
                onChange={(e) => setPixelCode(e.target.value)}
                disabled={loading}
              />
            </Col>
          </Row>
          {formik.touched.pixelCode && formik.errors.pixelCode && (
            <Alert message={formik.errors.pixelCode} type="error" style={{ width: '80%' }} />
          )}
          <Row gutter={16} style={{ marginTop: 20 }}>
            <Col className="gutter-row" span={6}>
              <Button
                disabled={pixelCode === savedPixelCode}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Save changes
              </Button>
            </Col>
          </Row>
        </Form>
      </Space>
    </Layout>
  );
};

export default Pixels;
