.chatChannelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.chatChannelContainer:hover {
  cursor: pointer;
  background-color: #e9eef5;
}

.selected {
  background-color: #dfe9f7;
}

.selected:hover {
  background-color: #dfe9f7 !important;
}

.containerData {
  flex: 1;
  height: 50px;
  display: flex;
  margin-left: 15px;
  flex-direction: column;
  align-items: flex-start;
}

.containerBadge {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
  margin-right: 20px;
}

.chatChannelBadge sup {
  width: 10px;
  height: 10px;
  top: 45px;
}

.name {
  font-size: 14px;
  font-weight: 600;
  color: #0a64b2;
}

.lastMessage {
  font-size: 12px;
  color: #565656;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px !important;
}

.date {
  font-size: 10px;
  color: #969696;
  align-self: flex-end;
  margin-right: 15px;
  position: absolute;
  right: 15px;
}

.count {
  margin-bottom: auto;
  font-size: 10px;
  font-weight: bold;
  width: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  height: 18px;
  background-color: #f00;
  border-radius: 100px;
  position: absolute;
  right: 15px;
}

.last {
  display: flex;
}
