import {
  Table,
  Icons,
  Empty,
} from 'taxaroo-ui';
import { ColumnsType } from 'taxaroo-ui/node_modules/antd/lib/table';
import FileNameClickableOpen from '~src/components/atoms/FileNameClickableOpen';
import { humanDate, humanFileSize } from '~src/components/helpers/humanFormat';
import { FC } from 'react';
import { DocumentsTableType } from '../../DocumentsTable';

const {
  Circle,
  CheckCircleOutlined,
} = Icons;

type dataType = {
  id: string;
  key: string;
  fileName: string;
  size: string;
  createAt: string;
  documentUsed: boolean;
  delete: any;
  s3Key: string;
  taxYearInterviewId: string;
  userId: string;
};

function MarkUsed({ used }: { used: boolean }) {
  if (used) {
    return (
      <CheckCircleOutlined style={{ color: 'green' }} />
    );
  }
  return (
    <Circle />
  );
}

const columns: ColumnsType<dataType> = [
  {
    title: 'File Name',
    dataIndex: 'fileName',
    key: 'fileName',
    sorter: (a, b) => a.fileName.localeCompare(b.fileName),
    render: (text: string, record) => (
      <FileNameClickableOpen
        id={record.id}
        name={record.fileName}
        s3Key={record.s3Key}
        source={DocumentsTableType.All}
      />
    ),
    width: '60%',
  },
  {
    title: 'Size',
    dataIndex: 'size',
    key: 'size',
    sorter: (a, b) => a.size.localeCompare(b.size),
  },
  {
    title: 'Date Uploaded',
    dataIndex: 'createAt',
    key: 'createAt',
    sorter: (a, b) => a.createAt.localeCompare(b.createAt),
  },
];

interface DocumentsTableProps {
  tableData: any[],
  loading: boolean,
}

const AllFilesTable: FC<DocumentsTableProps> = ({
  tableData,
  loading,
}) => {
  const parseData = tableData.map((item) => ({
    id: item.id,
    key: item.id,
    fileName: item.name,
    size: humanFileSize(item.size),
    createAt: humanDate(item.createAt),
    documentUsed: item.documentUsed,
    delete: 'Delete',
    s3Key: item.s3Key,
    taxYearInterviewId: item.taxYearInterviewId,
    userId: item.userId,
  }));

  if (tableData.length === 0) {
    return (
      <Empty
        description="No documents yet"
      />
    );
  }

  return (
    <Table
      dataSource={parseData}
      columns={columns}
      loading={loading}
      scroll={{ x: true }}
      noDataProps={{
        title: 'No Files Yet',
        extra: 'Please add files directly into specific jobs as Firm Files or Client Files.',
      }}
    />
  );
};

export default AllFilesTable;
