.page {
  padding: 60px;
}

.pageWrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-device-width: 700px) {
  .pageWrapper {
    margin: 0;
  }
}
