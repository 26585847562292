import * as Yup from 'yup';

const schema = Yup.object().shape({
  taxYear: Yup.string().required('Tax year is required'),
  jobType: Yup.string().required('Job type is required'),
  progressStatus: Yup.string(),
  sendEmail: Yup.boolean(),
});

export default schema;
