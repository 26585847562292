const config = {
  // eslint-disable-next-line
  env: process.env.NODE_ENV || "development",
  // eslint-disable-next-line
  serverUrl: process.env.NEST_SERVER || "http://localhost:3000",
  // eslint-disable-next-line
  clientUrl: process.env.REACT_APP_TAXAROO_CLIENT_URL || "http://localhost:3002",
  // eslint-disable-next-line
  CSRF: '',
  // eslint-disable-next-line
  pusherKey: process.env.REACT_APP_PUSHER_KEY ?? '08efc9f3f00e6821d6ff',
  // eslint-disable-next-line
  stripeAPIKey: process.env.STRIPE_API_PUBLISHABLE_KEY,
  // eslint-disable-next-line
  stripePlanMonthly: process.env.STRIPE_PLAN_MONTHLY,
  // eslint-disable-next-line
  stripePlanAnnual: process.env.STRIPE_PLAN_ANNUAL,
  // eslint-disable-next-line
  stripePlanDownSell: process.env.STRIPE_PLAN_DOWN_SELL,
  // eslint-disable-next-line
  stripeBlackFridayPricingTableId: process.env.STRIPE_BLACK_FRIDAY_PRICING_TABLE_ID,
  // eslint-disable-next-line
  stripePaywallPricingTableId: process.env.STRIPE_PAYWALL_PRICING_TABLE_ID,
  // eslint-disable-next-line
  stripeFreePlanPriceId: process.env.STRIPE_FREE_PLAN_PRICE_ID,
  // eslint-disable-next-line
  stripeLitePlanPriceId: process.env.STRIPE_LITE_PLAN_PRICE_ID,
  // eslint-disable-next-line
  stripeMonthlyPlanPriceId: process.env.STRIPE_MONTHLY_PRICE_ID,
  // eslint-disable-next-line
  stripeYearlyPlanPriceId: process.env.STRIPE_YEARLY_PRICE_ID,
};

export const updateCSRF = (csrf: string) => {
  config.CSRF = csrf;
};

export default config;
