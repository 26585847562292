import {
  Select,
  Tooltip,
} from 'taxaroo-ui';
import { SelectProps } from 'taxaroo-ui/node_modules/antd/lib/select';
import { useGetInterviewNamesQuery } from '~src/graphql/queries/interview';
import { useUpgradeModal, Features } from '~src/components/atoms/UpgradeModal/ModalProvider';

const { Option } = Select;

const SelectJobType = (SelectComponentProps: SelectProps) => {
  const { accessObject } = useUpgradeModal();

  // Get interviews query
  const { data: jobTypes, loading: loadingGetInterviews } = useGetInterviewNamesQuery();

  return (
    <Select
      disabled={loadingGetInterviews}
      placeholder="Select job type"
      allowClear
      {...SelectComponentProps}
    >
      {jobTypes?.interviewNames?.map((item) => {
        // if user doesn't have access to entity interviews, only show an individual interview
        let availableInterviewId: string;
        if (!accessObject?.[Features.entityInterviews]) {
          availableInterviewId = jobTypes?.interviewNames?.filter((i) => i?.name?.toLowerCase().includes('individual'))[0]?.id || jobTypes?.interviewNames?.[0]?.id;
        }
        return (
          <Option value={item.id} key={item.id} disabled={availableInterviewId && item.id !== availableInterviewId}>
            <Tooltip
              visible={(!(availableInterviewId && item.id !== availableInterviewId)) ? false : undefined} // if not disabled, don't show tooltip
              title="These job types are only available on Pro Plan"
              placement="bottomLeft"
            >
              <span style={{ width: '100%', display: 'block' }}>{item.name}</span>
            </Tooltip>
          </Option>
        );
      })}
    </Select>
  );
};

export default SelectJobType;
