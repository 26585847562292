import { v4 as uuid } from 'uuid';
import React, {
  ChangeEvent,
  createContext, useCallback, useContext, useMemo, useRef, useState, useEffect,
} from 'react';
import {
  Collapse, Icons, Input, Tooltip, Button, Row, Col, Alert, Popconfirm,
} from 'taxaroo-ui';
import {
  DropResult,
} from 'react-beautiful-dnd';
import { QuestionType } from '~src/graphql';
import useDebouncedEffect from '~src/components/hooks/useDebouncedEffect';
import { Option, Question, Section } from '~src/components/organisms/InterviewSectionList';
import { Conditional as ConditionalType, Language } from '~src/components/templates/Interviews';
import {
  QUESTION_DEFAULT_DESCRIPTION_EN, QUESTION_DEFAULT_DESCRIPTION_ES, SECTION_DEFAULT_DESCRIPTION_EN, SECTION_DEFAULT_DESCRIPTION_ES,
} from '~src/components/hooks/useInterviews';
import * as style from './style.module.css';
import InterviewField from '../../organisms/Interviews/Interview/InterviewField';
import DragDropIcon from '../../../assets/icons/drag-n-drop-icon.svg';

const {
  DeleteOutlined, PlusCircleOutlined, PlusCircleFilled, EditOutlined,
} = Icons;

const SUBSECTION_DEEP_LEVEL_LIMIT = 3;

const INITIAL_QUESTION = {
  id: null,
  label: '',
  required: false,
  sectionId: null,
  temporalId: null,
  type: QuestionType.Text,
};

export const INITIAL_SECTION = {
  id: null,
  name: '',
  isSpecial: false,
  order: 0,
  Questions: [
    INITIAL_QUESTION,
  ],
  SubSections: [],
};

export type QuestionChangeArg = {
  label?: string;
  required?: boolean;
  type?: QuestionType;
  Options?: Array<Option>;
};

export type QuestionChangeInput = {
  id: string,
  sectionId: string,
  temporalId?: string,
  parentSectionId?: string
};

export type SectionTarget = {
  id?: string;
  parentId?: string;
  temporalId?: string;
  temporalParentId?: string;
};

export type QuestionTarget = {
  id?: string;
  sectionId?: string;
  temporalId?: string;
  temporalSectionId?: string;
};

interface InterviewSectionProps {
  id?: string;
  index: number;
  parentId?: string;
  disabled?: boolean;
  temporalId?: string;
  sectionName: string;
  temporalParentId?: string;
  Questions: Array<Question>;
  SubSections: Array<Section>;
  Conditionals: Array<ConditionalType>;
  onDeleteSection: (index: number) => void;
  onSectionNameChange: (newName: string) => void;
  onQuestionsChange: (questions: Array<Question>) => void;
  onSubSectionsChange: (subSections: Array<Section>) => void;
  onAddConditionalClick: (section: Section, index: number) => void;
  onQuestionDragEnd: (target: DropResult, sectionIndex: number) => void;
  onSectionDragEnd: (target: { dragIndex: number, hoverIndex: number }) => void;
  interviewLanguage: Language;
}

const SectionContext = createContext(null);

const InterviewSection: React.FC<InterviewSectionProps> = ({
  id,
  index,
  disabled,
  parentId,
  Questions,
  temporalId,
  sectionName,
  SubSections,
  Conditionals,
  onDeleteSection,
  temporalParentId,
  onSectionDragEnd,
  onQuestionDragEnd,
  onQuestionsChange,
  onSectionNameChange,
  onSubSectionsChange,
  onAddConditionalClick,
  interviewLanguage,
}) => {
  // TODO: Add Drag & Drop Functionality
  const firstRender = useRef<boolean>(true);
  const [nameValue, setNameValue] = useState<string>(sectionName);
  const currentSectionContextValue = useContext(SectionContext);
  const hasSubSections = useMemo(() => !!SubSections?.length, [SubSections]);
  const isConditionalEditable = useMemo(() => (Conditionals && Conditionals.length), [Conditionals]);
  const hasSubsectionsWithQuestions = useMemo(() => SubSections.some((ss) => ss.Questions.length), [SubSections]);

  const newSectionContextValue = useMemo(() => ({
    level: currentSectionContextValue ? currentSectionContextValue.level + 1 : 1,
  }), [currentSectionContextValue]);

  const handleQuestionDragEnd = (
    target: { dragIndex: number, hoverIndex: number },
    sectionIndex: number,
  ) => {
    onQuestionDragEnd(target, sectionIndex);
  };

  const handleSectionDragEnd = (target: { dragIndex: number, hoverIndex: number }) => {
    onSectionDragEnd(target);
  };

  const handleAddConditionalClick = (event: MouseEvent) => {
    event.stopPropagation();
    onAddConditionalClick({
      id,
      parentId,
      Questions,
      temporalId,
      SubSections,
      order: index,
      temporalParentId,
      name: sectionName,
      isSpecial: disabled,
    }, index);
  };

  const onDeleteSectionClick = useCallback((event:
     React.MouseEvent<HTMLButtonElement | HTMLSpanElement>) => {
    event.stopPropagation();
    if (!event.currentTarget.id) {
      onDeleteSection(index);
    }
  }, [id, temporalId, index, SubSections]);

  const sectionExtra = (
    <>
      {/*
      id && id.length && parentId
      && !disabled && (
      <Tooltip title={!isConditionalEditable ? 'Condition this section' : 'Update Conditional'}>
        <Button
          type="primary"
          icon={!isConditionalEditable ? (<PlusCircleOutlined />) : (<EditOutlined />)}
          onClick={handleAddConditionalClick}
        />
      </Tooltip>
      )
      */}
      <Popconfirm
        okText="Yes"
        cancelText="No"
        placement="topRight"
        okButtonProps={{ danger: true }}
        onConfirm={onDeleteSectionClick}
        onCancel={(ev) => { ev.stopPropagation(); }}
        cancelButtonProps={{ type: 'default' }}
        title="Are you sure you want to delete this?"
      >
        <Tooltip title="Delete Section">
          <Button id={`delete-section-${id ?? temporalId}`} className={style.deleteSectionButton} danger type="primary" icon={<DeleteOutlined />} onClick={onDeleteSectionClick} />
        </Tooltip>
      </Popconfirm>
    </>
  );

  const handleQuestionChange = useCallback(({
    id: targetId,
    temporalId: targetTemporalId,
  }: QuestionChangeInput, changes: QuestionChangeArg) => {
    onQuestionsChange(Questions.map((question) => {
      if ((question.id && targetId && !targetTemporalId && !question.temporalId
      && question.id === targetId)
     || (question.temporalId && targetTemporalId && !question.id && !targetId
      && question.temporalId === targetTemporalId)) {
        const { ...questionChanges } = changes;

        return {
          ...question,
          ...questionChanges,
          ...(question.id ? { operation: 'update' } : {}),
        };
      }

      return question;
    }));
  }, [Questions]);

  const onAddSubSectionClick = useCallback(({
    id: targetId,
    temporalId: targetTemporalId,
  }: SectionTarget) => {
    onSubSectionsChange([...(SubSections ?? []), {
      ...INITIAL_SECTION,
      name: interviewLanguage.lang === 'EN' ? SECTION_DEFAULT_DESCRIPTION_EN : SECTION_DEFAULT_DESCRIPTION_ES,
      operation: 'create',
      order: SubSections.length,
      temporalId: uuid(),
      ...(targetId && !targetTemporalId
        ? { parentId: targetId }
        : { temporalParentId: targetTemporalId }
      ),
      Questions: INITIAL_SECTION.Questions.map((question) => ({
        ...question,
        label: interviewLanguage.lang === 'EN' ? QUESTION_DEFAULT_DESCRIPTION_EN : QUESTION_DEFAULT_DESCRIPTION_ES,
        order: 0,
        operation: 'create',
        temporalId: uuid(),
        ...(targetId && !targetTemporalId
          ? { sectionId: targetId }
          : { temporalSectionId: targetTemporalId }
        ),
      })),
    }]);
  }, [SubSections]);

  const onAddQuestionFieldClick = useCallback(({
    id: targetId,
    temporalId: targetTemporalId,
  }: SectionTarget) => {
    onQuestionsChange([...Questions, {
      ...INITIAL_QUESTION,
      label: interviewLanguage.lang === 'EN' ? QUESTION_DEFAULT_DESCRIPTION_EN : QUESTION_DEFAULT_DESCRIPTION_ES,
      temporalId: uuid(),
      operation: 'create',
      order: Questions.length,
      ...(targetId && !targetTemporalId
        ? { sectionId: targetId }
        : { temporalSectionId: targetTemporalId }
      ),
    }]);
  }, [Questions]);

  const onDeleteQuestionFieldClick = (questionIndex: number) => {
    const currentQuestions = [...Questions];
    const currentQuestionToDelete = currentQuestions[questionIndex];

    if (currentQuestionToDelete.id) {
      currentQuestions[questionIndex] = {
        ...currentQuestionToDelete,
        operation: 'delete',
        Options: currentQuestionToDelete.Options?.map((option) => ({
          ...option,
          operation: 'delete',
        })) ?? [],
      };
    } else {
      currentQuestions.splice(questionIndex, 1);
    }

    onQuestionsChange(currentQuestions);
  };

  const onSectionNameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setNameValue(event.target.value);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setNameValue(sectionName);
    }
  }, [sectionName]);

  useDebouncedEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      onSectionNameChange(nameValue);
    }
  }, 500, [nameValue]);

  return (
    <div
      key={id ?? temporalId}
      className={style.dragContainer}
    >
      <DragDropIcon
      // style={isDragging ? { visibility: 'visible' } : null}
        className={style.moveIcon}
      />
      <Collapse>
        <Collapse.Panel
          key={`panel-${id ?? temporalId}`}
          header={!disabled
            ? (
              <Input
                value={nameValue}
                className={style.sectionNameInput}
                onChange={onSectionNameChangeHandler}
                onClick={(e: any) => { e.stopPropagation(); }}
              />
            )
            : sectionName}
          extra={sectionExtra}
        >
          {disabled && (
          <Alert
            showIcon
            type="info"
            message="Hey!"
            className={style.noQuestionsAlert}
            description="This is a pre-built section, and the content cannot be customized. You can delete it if you like."
          />
          )}
          <div>
            {
              Questions?.length
                ? Questions.map(({
                  type,
                  label,
                  Options,
                  required,
                  sectionId,
                  id: questionId,
                  temporalSectionId,
                  temporalId: questionTemporalId,
                }, questionIndex) => {
                  const handleInterviewFieldDelete = () => {
                    onDeleteQuestionFieldClick(questionIndex);
                  };

                  const handleInterviewFieldChange = (changes: QuestionChangeArg) => {
                    handleQuestionChange({
                      sectionId: id,
                      id: questionId ?? '',
                      temporalId: questionTemporalId,
                    }, changes);
                  };

                  const handleOptionsChange = (newOptions: Array<Option>) => {
                    const currentQuestions = [...Questions];

                    currentQuestions[questionIndex].Options = newOptions;

                    onQuestionsChange(currentQuestions);
                  };

                  return (
                    <div
                      key={questionId ?? questionTemporalId}
                      className={style.questionDragContainer}
                    >
                      <DragDropIcon
                        className={style.moveIcon}
                      />
                      <InterviewField
                        type={type}
                        label={label}
                        id={questionId}
                        disabled={disabled}
                        required={required}
                        Options={Options ?? []}
                        temporalId={questionTemporalId}
                        onOptionsChange={handleOptionsChange}
                        onDelete={handleInterviewFieldDelete}
                        onFieldChange={handleInterviewFieldChange}
                      />
                    </div>
                  );
                })
                : !hasSubsectionsWithQuestions && (
                  <Alert
                    showIcon
                    message="Hey!"
                    type="warning"
                    className={style.noQuestionsAlert}
                    description="A Section should contain at least one question"
                  />
                )
            }
            {
              hasSubSections && (
                <section className={style.subSection}>
                  <SectionContext.Provider value={newSectionContextValue}>
                    { SubSections.map(({
                      name,
                      id: subSectionId,
                      SubSections: Sub,
                      parentId: subParentId,
                      Questions: SubQuestions,
                      temporalId: temporalSubId,
                      Conditionals: SubConditionals,
                      temporalParentId: subTemporalParentId,
                    }, subIndex) => {
                      const handleSubSectionSubSectionsChange = (newSubSections:
                        Array<Section>) => {
                        const currentSubSections = [...SubSections];

                        currentSubSections[subIndex].SubSections = newSubSections;
                        currentSubSections[subIndex].operation = currentSubSections[subIndex].operation || 'update';

                        onSubSectionsChange(currentSubSections);
                      };

                      const handleSubSectionQuestionsChange = (newQuestions: Array<Question>) => {
                        const currentSubSections = [...SubSections];

                        currentSubSections[subIndex].Questions = newQuestions;
                        currentSubSections[subIndex].operation = currentSubSections[subIndex].operation || 'update';

                        onSubSectionsChange(currentSubSections);
                      };

                      const handleSubSectionDelete = (subIndexTarget: number) => {
                        const currentSubSections = [...SubSections];

                        const mapDeletesCascade = (section: Section): Section => ({
                          ...section,
                          operation: 'delete',
                          Questions: section.Questions.map((question) => ({
                            ...question,
                            operation: 'delete',
                            Options: question.Options?.map((option) => ({
                              ...option,
                              operation: 'delete',
                            })) ?? [],
                          })),
                          SubSections: section.SubSections?.map(
                            (subSection) => mapDeletesCascade(subSection),
                          ) ?? [],
                        });

                        if (currentSubSections[subIndexTarget].id) {
                          currentSubSections[subIndexTarget] = mapDeletesCascade(
                            currentSubSections[subIndexTarget],
                          );
                        } else {
                          currentSubSections.splice(subIndexTarget, 1);
                        }

                        onSubSectionsChange(currentSubSections);
                      };

                      const handleSubSectionNameChange = (newName: string) => {
                        const currentSubSections = [...SubSections];

                        if (currentSubSections[subIndex].id) {
                          currentSubSections[subIndex].operation = 'update';
                        }
                        currentSubSections[subIndex].name = newName;

                        onSubSectionsChange(currentSubSections);
                      };

                      const handleSubSectionAddConditionalClick = (section: Section) => {
                        onAddConditionalClick(section, subIndex);
                      };

                      return (
                        <InterviewSection
                          index={subIndex}
                          id={subSectionId}
                          sectionName={name}
                          parentId={subParentId}
                          SubSections={Sub ?? []}
                          Questions={SubQuestions}
                          temporalId={temporalSubId}
                          Conditionals={SubConditionals}
                          key={`interview-section-${subSectionId ?? temporalSubId}`}
                          temporalParentId={subTemporalParentId}
                          onSectionDragEnd={handleSectionDragEnd}
                          onDeleteSection={handleSubSectionDelete}
                          onQuestionDragEnd={handleQuestionDragEnd}
                          onSectionNameChange={handleSubSectionNameChange}
                          onQuestionsChange={handleSubSectionQuestionsChange}
                          onSubSectionsChange={handleSubSectionSubSectionsChange}
                          onAddConditionalClick={handleSubSectionAddConditionalClick}
                          interviewLanguage={interviewLanguage}
                        />
                      );
                    })}
                  </SectionContext.Provider>
                </section>
              )
            }
            <Row gutter={[5, 0]}>
              <Col>
                <Button
                  type="default"
                  onClick={(event) => {
                    event.stopPropagation();
                    onAddQuestionFieldClick({
                      id, temporalId, parentId, temporalParentId,
                    });
                  }}
                >
                  <PlusCircleFilled />
                  Add Question
                </Button>
              </Col>
              {
                currentSectionContextValue?.level !== SUBSECTION_DEEP_LEVEL_LIMIT && (
                  <Col>
                    <Button
                      type="default"
                      onClick={() => onAddSubSectionClick({
                        id, parentId, temporalId, temporalParentId,
                      })}
                    >
                      <PlusCircleFilled />
                      Add Subsection
                    </Button>
                  </Col>
                )
              }
            </Row>
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

InterviewSection.defaultProps = {
  id: null,
  parentId: null,
  disabled: false,
  temporalId: null,
  temporalParentId: null,
};

export default React.memo(InterviewSection);
