import {
  Layout, Typography, Grid, Row, message, Spin, Col, Alert, Button,
} from 'taxaroo-ui';
import config from '~src/components/helpers/config';
import { useCreateSubscriptionMutation, useUpdateSubscriptionMutation } from '~src/graphql/mutations/settings';
import { useCreateBillingPortalSessionMutation, useCreateCheckoutSessionMutation } from '~src/graphql/mutations/stripeInformation';
import { useGetSubscriptionQuery } from '~src/graphql/queries/settings';
import { useGetStripeCustomerQuery } from '~src/graphql/queries/stripeInformation';
import { useAppDispatch, useAppSelector } from '~src/redux/hooks';
import { resetChat } from '~src/redux/slices/chatSlice';
import { resetClientData } from '~src/redux/slices/clientSlice';
import { resetInterviews } from '~src/redux/slices/interviewsSlice';
import { resetSession } from '~src/redux/slices/sessionSlice';
import PayWallClouds from '~src/assets/image/pay-wall-clouds.svg';
import PayWallPerson from '~src/assets/image/pay-wall-person.png';
import styles from './styles.module.css';

const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;
interface PayWallProps {
  customerId: string;
}
const PayWall = ({
  customerId,
}: PayWallProps) => {
  const dispatch = useAppDispatch();
  const {
    email,
    firstName,
    firmAccountId,
    userRole,
  } = useAppSelector((state) => state.session);
  const firstLetter = firstName?.charAt(0)?.toUpperCase() || '?';
  const { sm } = useBreakpoint();

  // graphql
  // Get subscription info query
  const { data: subscriptionData, loading: loadingSubscription } = useGetSubscriptionQuery();
  // we also want to get the customer, because we need to check the customre for a default payment method
  const { data: customerData, loading: loadingCustomer } = useGetStripeCustomerQuery({
    variables: {
      getStripeCustomerInput: {
        customerId,
      },
    },
    fetchPolicy: 'network-only',
  });
  // update subscription
  const [updateSubscritpion, { loading: loadingUpdateSubscription }] = useUpdateSubscriptionMutation();
  // create subscription
  const [createSubscritpion, { loading: loadingCreateSubscription }] = useCreateSubscriptionMutation();
  // Create billing portal session mutation
  const [createBillingPortalSession] = useCreateBillingPortalSessionMutation({
    onError: (err) => {
      message.error(err.message);
    },
  });
  // create checkout session mutation
  const [createCheckoutSession, { loading: loadingCreateCheckoutSession }] = useCreateCheckoutSessionMutation({
    onError: (err) => {
      message.error(err.message);
    },
  });

  const customer = customerData?.getStripeCustomer;
  const noDefaultPaymentMethod = !customer?.invoice_settings?.default_payment_method;

  const onClick = ({ key }: { key: string }) => {
    if (key === 'logout') {
      dispatch(resetSession());
      dispatch(resetClientData());
      dispatch(resetInterviews());
      dispatch(resetChat());
    } else if (key === 'help') {
      window.open('https://help.taxaroo.com/', '_blank', 'noreferrer');
    }
  };

  const handleCreateCheckoutSession = async (priceId: string, quantity: number) => {
    createCheckoutSession({
      variables: {
        createCheckoutSessionInput: {
          customerId,
          customerEmail: email,
          quantity,
          adjustableQuantity: quantity === 1
            ? {
              enabled: false,
            }
            : {
              enabled: true,
              maximum: 99,
              minimum: 2,
            },
          priceId,
          success_url: `${window.location.origin}`,
          cancel_url: `${window.location.origin}`,
        },
      },
    }).then(({ data }) => {
      if (data?.createCheckoutSession) {
        window.location.href = data?.createCheckoutSession;
      }
    }).catch((error) => {
      message.error(`Error creating checkout session - ${error.message}. Please contact customer support for assistance`, 10);
    });
  };

  const handleRenewPlan = async () => {
    if (loadingSubscription) {
      message.info('Loading subscription information. Please wait a couple seconds and try again.');
      return;
    }
    console.log('noDefaultPaymentMethod', noDefaultPaymentMethod);
    if (noDefaultPaymentMethod) {
      handleCreateCheckoutSession(
        subscriptionData?.GetSubscriptionByContext?.items?.data?.[0]?.plan?.id || config.stripeMonthlyPlanPriceId, // priceId
        subscriptionData?.GetSubscriptionByContext?.quantity || 2, // quantity
      );
      return;
    }
    if (subscriptionData?.GetSubscriptionByContext?.status === 'active' && subscriptionData?.GetSubscriptionByContext?.cancel_at_period_end) {
      // update subscription to cancel at period end to false
      await updateSubscritpion({
        variables: {
          updateStripeSubscriptionInput: {
            cancel_at_period_end: false,
          },
        },
        refetchQueries: ['GetSubscriptionByContext', 'isActiveFirm'],
        onCompleted: () => {
          message.success('Subscription reactivated successfully');
        },
        onError: (error) => {
          // message.error(`Subscription could not be reactivated. ${error.message}`);
          // on error, we need to create a checkout session
          handleCreateCheckoutSession(
            subscriptionData?.GetSubscriptionByContext?.items?.data?.[0]?.plan?.id || config.stripeMonthlyPlanPriceId, // priceId
            subscriptionData?.GetSubscriptionByContext?.quantity || 2, // quantity
          );
        },
      });
    } else if (subscriptionData?.GetSubscriptionByContext?.status === 'canceled') {
      // we need to start a whole new subscription
      await createSubscritpion({
        variables: {
          createStripeSubscriptionInput: {
            customerId: subscriptionData?.GetSubscriptionByContext?.customer,
            planId: subscriptionData?.GetSubscriptionByContext?.items.data[0].plan.id,
            quantity: subscriptionData?.GetSubscriptionByContext?.quantity,
            firmAccountId,
            paymentBehavior: 'error_if_incomplete',
          },
        },
        refetchQueries: ['GetSubscriptionByContext', 'isActiveFirm'],
        onCompleted: () => {
          message.success('Subscription reactivated successfully');
        },
        onError: (error) => {
          // message.error(`Subscription could not be reactivated. ${error.message}`);
          // on error, we need to create a checkout session
          handleCreateCheckoutSession(
            subscriptionData?.GetSubscriptionByContext?.items?.data?.[0]?.plan?.id || config.stripeMonthlyPlanPriceId, // priceId
            subscriptionData?.GetSubscriptionByContext?.quantity || 2, // quantity
          );
        },
      });
    }
  };

  const handleGoToBillingPortal = async () => {
    const { data } = await createBillingPortalSession({
      variables: {
        createBillingPortalInput: {
          customer: customerId,
          return_url: window.location.href,
        },
      },
    });
    window.location.href = data.createBillingPortalSession;
  };

  if (loadingCustomer || loadingSubscription) {
    return (
      <Row justify="center" align="middle" style={{ height: '100vh' }}>
        <Spin size="large" />
      </Row>
    );
  }

  const recordsShow = () => {
    // if the subscription is past due and there is a payment method on file
    if (subscriptionData?.GetSubscriptionByContext?.status === 'past_due' && (customer?.default_source || !noDefaultPaymentMethod)) {
      return 'Our records show that there was difficulty processing your payment. Please update your payment method below.';
    }
    // if there is no payment method on file
    if (noDefaultPaymentMethod) {
      return 'Our records show you do not have a default payment method on file. Please add a payment method below.';
    }
    // if the subscription is canceled
    if (subscriptionData?.GetSubscriptionByContext?.status === 'canceled') {
      return 'Our records show that your subscription may have been canceled, or there was difficulty processing your payment. Please reactivate your subscription below.';
    }
    return '';
  };

  return (
    <Layout style={{
      background: 'white', padding: 0, margin: '0 auto', maxWidth: 1500,
    }}
    >
      <p style={{ textAlign: 'right', padding: 10 }}>
        <Button
          onClick={() => onClick({ key: 'logout' })}
          type="link"
          style={{ textTransform: 'none' }}
        >
          Logout
        </Button>
      </p>
      {/* <Header style={{ display: 'flex', justifyContent: 'space-between', height: '70px' }}>
        <Link to="/">
          <Logo style={{ width: '150px', height: '35px', margin: '15px 13px' }} />
        </Link>
        <div style={{ width: '100%' }}>
          <Menu
            mode="horizontal"
            theme="light"
            onClick={onClick}
            selectable={false}
            style={{ display: 'flex' }}
          >
            <SubMenu
              icon={(
                <Avatar>
                  {firstLetter.toUpperCase()}
                </Avatar>
              )}
            >
              <Item key="help">
                Help Center
              </Item>
              <Item key="logout">
                Logout
              </Item>
            </SubMenu>
          </Menu>
        </div>
      </Header> */}
      <Layout
        className={styles.layout}
      >
        <Row>
          <Title level={2} className={styles.title}>
            {subscriptionData?.GetSubscriptionByContext ? 'Oops! Your subscription at Taxaroo is no longer active' : 'Oops! You do not currently have a subscription with Taxaroo'}
          </Title>
          <Paragraph className={styles.subText}>
            {recordsShow()}
          </Paragraph>
        </Row>
        {userRole !== 'STAFF'
          ? (
            <Row justify="space-between">
              <Col>
                {/* call to action gradient box */}
                <div className={styles.callToActionBox}>
                  <p className={styles.callToActionTitle}>
                    No need to worry! You can always reactivate your plan
                  </p>
                  <p className={styles.callToActionText}>
                    Click the button bellow to Reactivate your plan and keep enjoying all our most incredible features.
                  </p>
                  <Button
                    className={styles.callToActionButton}
                    loading={loadingUpdateSubscription || loadingCreateSubscription || loadingCreateCheckoutSession}
                    onClick={handleRenewPlan}
                    size="large"
                  >
                    Reactivate Plan
                  </Button>
                </div>
                {/* features list */}
                <Col>
                  <div className={styles.featuresList}>
                    {sm
                      ? (
                        <>
                          <ul style={{ width: '50%' }}>
                            <li>Everything in free plus:</li>
                            <li>Signatures</li>
                            <li>Approvals</li>
                            <li>Follow ups</li>
                            <li>AI Powered Messaging</li>
                            <li>Mapped Drive</li>
                            <li>Entity Interviews</li>
                            <li>Reviews</li>
                          </ul>
                          <ul style={{ width: '50%' }}>
                            <li>Appointments</li>
                            <li>AI Powered Research</li>
                            <li>Export to Drake</li>
                            <li>Video Calls</li>
                            <li>Birthday Emails</li>
                            <li>Pixels</li>
                            <li>Team Members</li>
                          </ul>
                        </>
                      )
                      : (
                        <ul style={{ marginTop: -25 }}>
                          <li>Everything in free plus:</li>
                          <li>Signatures</li>
                          <li>Approvals</li>
                          <li>Follow ups</li>
                          <li>AI Powered Messaging</li>
                          <li>Mapped Drive</li>
                          <li>Entity Interviews</li>
                          <li>Reviews</li>
                          <li>Appointments</li>
                          <li>AI Powered Research</li>
                          <li>Export to Drake</li>
                          <li>Video Calls</li>
                          <li>Birthday Emails</li>
                          <li>Pixels</li>
                          <li>Team Members</li>
                        </ul>
                      )}
                  </div>
                </Col>
              </Col>
              <Col style={{ maxWidth: 700 }}>
                <PayWallClouds className={styles.clouds} />
                <img src={PayWallPerson} alt="person" className={styles.person} />
              </Col>
            </Row>
          )
          : (
            <Col style={{ textAlign: 'center', marginTop: 20 }}>
              <Alert
                message="Your subscription is no longer active. Please contact your firm administrator to reactivate your subscription."
                type="warning"
                showIcon
              />
            </Col>
          )}
      </Layout>
    </Layout>
  );
};

export default PayWall;
