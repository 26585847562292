const settings = {
  active: true,
  afterBuffer: null,
  beforeBuffer: null,
  color: '#03a9f4',
  description: '',
  duration: 30,
  maxEvents: null,
  minNotice: null,
  name: '',
  timezone: null,
};

const times = [
  { value: 5, label: '5 min' },
  { value: 15, label: '15 min' },
  { value: 30, label: '30 min' },
  { value: 35, label: '35 min' },
  { value: 60, label: '1 hour' },
  { value: 90, label: '1 hours 30 min' },
  { value: 120, label: '2 hours' },
  { value: 150, label: '2 hours 30 min' },
  { value: 180, label: '3 hours' },
];

const timeCalendar = {
  lapse: 60,
  calendar: [
    { value: { hour: 0, minutes: 0 }, label: '0:00' },
    { value: { hour: 1, minutes: 0 }, label: '1:00' },
    { value: { hour: 2, minutes: 0 }, label: '2:00' },
    { value: { hour: 3, minutes: 0 }, label: '3:00' },
    { value: { hour: 4, minutes: 0 }, label: '4:00' },
    { value: { hour: 5, minutes: 0 }, label: '5:00' },
    { value: { hour: 6, minutes: 0 }, label: '6:00' },
    { value: { hour: 7, minutes: 0 }, label: '7:00' },
    { value: { hour: 8, minutes: 0 }, label: '8:00' },
    { value: { hour: 9, minutes: 0 }, label: '9:00' },
    { value: { hour: 10, minutes: 0 }, label: '10:00' },
    { value: { hour: 11, minutes: 0 }, label: '11:00' },
    { value: { hour: 12, minutes: 0 }, label: '12:00' },
    { value: { hour: 13, minutes: 0 }, label: '13:00' },
    { value: { hour: 14, minutes: 0 }, label: '14:00' },
    { value: { hour: 15, minutes: 0 }, label: '15:00' },
    { value: { hour: 16, minutes: 0 }, label: '16:00' },
    { value: { hour: 17, minutes: 0 }, label: '17:00' },
    { value: { hour: 18, minutes: 0 }, label: '18:00' },
    { value: { hour: 19, minutes: 0 }, label: '19:00' },
    { value: { hour: 20, minutes: 0 }, label: '20:00' },
    { value: { hour: 21, minutes: 0 }, label: '21:00' },
    { value: { hour: 22, minutes: 0 }, label: '22:00' },
    { value: { hour: 23, minutes: 0 }, label: '23:00' },
  ],
};

const colors = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
];

const days = [
  { value: 'SUNDAY', label: 'Sunday' },
  { value: 'MONDAY', label: 'Monday' },
  { value: 'TUESDAY', label: 'Tuesday' },
  { value: 'WEDNESDAY', label: 'Wednesday' },
  { value: 'THURSDAY', label: 'Thursday' },
  { value: 'FRIDAY', label: 'Friday' },
  { value: 'SATURDAY', label: 'Saturday' },
];

export {
  settings, times, days, timeCalendar, colors,
};
