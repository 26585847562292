import { useEffect, useState } from 'react';
import {
  Menu, Icons, List, Grid, Typography,
} from 'taxaroo-ui';
import {
  useGetCustomDomainUserQuery,
  useGetFirmUniqueLinkQuery,
  useGetUserUniqueLinkQuery,
  useIsDomainLiveQuery,
} from '~src/graphql/queries/settings';
import { useAppSelector } from '~src/redux/hooks';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import CopyButtonWithTooltip from '../../../molecules/CopyButtonWithTooltip';
import * as styles from '../styles.module.css';

const { LinkOutlined } = Icons;
const { useBreakpoint } = Grid;
const { SubMenu, Item } = Menu;
const { Text } = Typography;

function InviteSubMenu(props: any) {
  const screens = useBreakpoint();
  const [baseLink, setBaseLink] = useState(`${process.env.REACT_APP_TAXAROO_CLIENT_URL}/invite`);
  const [savedFirmLink, setSavedFirmLink] = useState(null);
  const [savedPersonalLink, setSavedPersonalLink] = useState(null);

  const { firmAccountId, userId } = useAppSelector((state) => state.session);

  // graphql to get the custom domain
  const { data: customDomainUserData, loading: loadingCustomDomainUser } = useGetCustomDomainUserQuery();
  const { CustomDomainUser } = customDomainUserData || {};

  // graphql query to get the firm invite link
  const {
    data: customUserUniqueLinkData,
    loading: loadingUserUniqueLink,
  } = useGetUserUniqueLinkQuery({
    variables: {
      id: userId,
    },
  });
  // graphql query to get the user invite link
  const {
    data: customFirmUniqueLinkData,
    loading: loadingFirmUniqueLink,
  } = useGetFirmUniqueLinkQuery({
    variables: {
      id: firmAccountId,
    },
  });

  // check if custom domain is live
  const { data: domainLiveData } = useIsDomainLiveQuery({
    skip: !CustomDomainUser?.name,
    variables: {
      url: CustomDomainUser?.name,
    },
  });
  const { IsDomainLive: isDomainLive } = domainLiveData || {};

  useEffect(() => {
    if (!isDomainLive) {
      // if custom domain is not working
      // we need to show default invite link
      setBaseLink(`${process.env.REACT_APP_TAXAROO_CLIENT_URL ?? 'https://clients.taxaroo.com'}/invite`);
    } else {
      setBaseLink(`https://${CustomDomainUser?.name}/invite`);
    }
  }, [isDomainLive]);

  useEffect(() => {
    if (customFirmUniqueLinkData) {
      if (customFirmUniqueLinkData.FirmAccountById) {
        const { id, uniqueLink } = customFirmUniqueLinkData.FirmAccountById;
        if (uniqueLink) {
          setSavedFirmLink(uniqueLink);
        } else {
          setSavedFirmLink(id);
        }
      }
    }
  }, [customFirmUniqueLinkData]);

  useEffect(() => {
    if (customUserUniqueLinkData) {
      if (customUserUniqueLinkData.UserById) {
        const { uniqueLink } = customUserUniqueLinkData.UserById;
        if (uniqueLink) {
          setSavedPersonalLink(uniqueLink);
        } else {
          setSavedPersonalLink(userId);
        }
      }
    }
  }, [customUserUniqueLinkData]);

  const inviteLinks = [
    {
      name: 'Firm Invite Link',
      value: `${baseLink}/${savedFirmLink}`,
    },
    {
      name: 'Personal Invite Link',
      value: `${baseLink}/${savedFirmLink}/${savedPersonalLink}`,
    },
  ];

  const handleClick = (key: string) => {
    // find which one clicked
    const theOneClicked = inviteLinks.filter((e) => e.name === key)[0];
    if (theOneClicked && window) {
      const newWindow = window.open(theOneClicked.value, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;

      trackCustomerIoEvent(CUSTOMER_IO_EVENTS.CLICK, {
        Title: 'Invite link top menu',
        Source: 'Link click',
        value: theOneClicked.name,
        url: window.location.href,
      });
    }
  };

  return (
    <SubMenu
      icon={<LinkOutlined />}
      title="Invite"
      // className={styles.inviteSubMenu}
      onClick={(e: any) => handleClick(e.key)}
      {...props}
    >
      {inviteLinks.map((item) => (
        <Item
          // className={styles.inviteLinkItem}
          key={item.name}
        >
          <List
            itemLayout={screens.xs ? 'vertical' : 'horizontal'}
            style={{ padding: '0 10px' }}
            key={`list-${item.name}`}
          >
            <List.Item
              actions={[
                <span
                  onMouseDown={() => { // onClick doesn't work
                    trackCustomerIoEvent(CUSTOMER_IO_EVENTS.CLICK, {
                      Title: 'Invite link top menu',
                      Source: 'Copy button click',
                      value: item.name,
                      url: window.location.href,
                    });
                  }}
                  aria-hidden="true"
                >
                  <CopyButtonWithTooltip copyText={item.value} />
                </span>,
              ]}
              key={`invite-list-item-${item.name}`}
            >
              <Text style={{ paddingRight: '10px' }}>{item.name}</Text>
            </List.Item>
          </List>
        </Item>
      ))}
    </SubMenu>
  );
}

export default InviteSubMenu;
