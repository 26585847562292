import {
  useState, useRef, useCallback, Key, useEffect, FC,
} from 'react';
import { Table, message } from 'taxaroo-ui';
import './styles.css';
import { TaxYearInterviewOrderByInput, TaxYearStatus } from '~src/graphql';
import { useGetClientsByProgressStatusIdQuery } from '~src/graphql/queries/settings';
import getPrimaryPhone from '~src/components/helpers/getPrimaryPhone';
import Users from '~src/components/helpers/users';
import DraggableBodyRow from '../../molecules/DraggableTableRow/DraggableBodyRow';
import RowAboveJobsTable from '../RowAboveJobsTable';
import NoClients from './NoClients';

interface JobsTableProps {
  setProgressStatusDnDConfirmModalVisible: (arg0: boolean) => void;
  setDndData: (arg0: { record: any, dropResult: any, prevProgressStatusId: string }) => void;
  columns: any[];
  progressStatusId: string;
  progressStatusName: string;
  underlyingStatus: TaxYearStatus;
  setTabCountMap: (arg0: any) => void;
  loadingProp: boolean;
}

const JobsTable: FC<JobsTableProps> = ({
  setProgressStatusDnDConfirmModalVisible,
  setDndData,
  columns,
  progressStatusId,
  progressStatusName,
  underlyingStatus,
  setTabCountMap,
  loadingProp,
}) => {
  const sortedInfo = {
    order: 'ascend',
    columnKey: 'clientInterview',
  };
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [displayClearbutton, setDisplayClearButton] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedValue, setSearchedValue] = useState('');
  const [sortObject, setSortObject] = useState<TaxYearInterviewOrderByInput>(() => {
    // Check if there is a sort order saved in localStorage
    const savedSort = localStorage.getItem(`${progressStatusId}SortOrder`);
    return savedSort ? JSON.parse(savedSort) : { updateAt: 'desc' };
  });

  const tableDivRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  // graphql
  const { data: jobsDataWithPagination, loading: loadingJobs } = useGetClientsByProgressStatusIdQuery({
    variables: {
      progressStatusId,
      skip: (currentPage - 1) * pageSize,
      take: pageSize,
      searchTerm: searchedValue,
      orderBy: sortObject,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (searchedValue.length > 0) {
        if (data?.ClientsByTaxYearStatus?.data?.length === 0) {
          message.error('No results found');
          searchRef.current?.focus();
        }
        setDisplayClearButton(true);
      } else {
        setDisplayClearButton(false);
      }
    },
    onError: (error) => {
      message.error(error.message);
      // reset local storage sort order to default
      setSortObject({ updateAt: 'desc' });
      localStorage.setItem(`${progressStatusId}SortOrder`, JSON.stringify({ updateAt: 'desc' }));
    },
  });
  const { data: jobsData, pagination } = jobsDataWithPagination?.ClientsByTaxYearStatus || {};

  useEffect(() => {
    if (pagination?.total !== undefined) {
      setTabCountMap((prevState: any) => ({
        ...prevState,
        [progressStatusId]: pagination.total,
      }));
    }
  }, [pagination?.total, progressStatusId]);

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const progressStatusOnChange = (record: any, dropResult: any) => {
    setDndData({
      record,
      dropResult,
      prevProgressStatusId: progressStatusId,
    });
    setProgressStatusDnDConfirmModalVisible(true);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], row: any) => {
      setSelectedKeys(selectedRowKeys);
      setSelectedRows(row);
    },
  };

  const moveRow = useCallback((record, notSure, dropResult) => {
    progressStatusOnChange(record, dropResult);
  }, []);

  const handleTableChange = (paginationInfo, filters, sorter) => {
    const orderByColumn = sorter.columnKey;
    const orderDirection = sorter.order === 'ascend' ? 'asc' : 'desc';
    let newSort: TaxYearInterviewOrderByInput = { updateAt: 'desc' };
    switch (orderByColumn) {
      case 'firstName':
        newSort = { TaxYear: { Entity: { Users: { UserInformation: { firstName: orderDirection } } } } };
        break;
      case 'lastName':
        newSort = { TaxYear: { Entity: { Users: { UserInformation: { lastName: orderDirection } } } } };
        break;
      case 'email':
        newSort = { TaxYear: { Entity: { Users: { email: orderDirection } } } };
        break;
      case 'taxYear':
        newSort = { TaxYear: { year: orderDirection } };
        break;
      case 'jobType':
        newSort = { Interviews: { name: orderDirection } };
        break;
      case 'timeInQueue':
        newSort = { createAt: orderDirection };
        break;
      case 'inProgressTime':
        newSort = { assignedAt: orderDirection };
        break;
      case 'currentPreparer':
        newSort = { PreparedUser: { UserInformation: { lastName: orderDirection } } };
        break;
      case 'currentReviewer':
        newSort = { ReviewedUser: { UserInformation: { lastName: orderDirection } } };
        break;
      case 'files':
        newSort = { JobTracking: { filesCompletedAt: orderDirection } };
        break;
      case 'clientInterview':
        newSort = { JobTracking: { interviewCompletedAt: orderDirection } };
        break;
      case 'clientInvoice':
        newSort = { JobTracking: { paymentsCompletedAt: orderDirection } };
        break;
      case 'reviewStatus':
        newSort = { reviewStatus: orderDirection };
        break;
      case 'clientCopyUploaded':
        newSort = { JobTracking: { approvalsCompletedAt: orderDirection } };
        break;
      case 'completedDate':
        newSort = { completedAt: orderDirection };
        break;
      default:
        newSort = { updateAt: 'desc' };
        break;
    }
    setSortObject(newSort);
    localStorage.setItem(`${progressStatusId}SortOrder`, JSON.stringify(newSort));
  };

  // ! assignment to property of function parameter 'columns'
  // columns[0].fixed = screens.sm ? 'left' : false;
  // columns.forEach((item: any) => {
  //  item.sortOrder = sortedInfo.columnKey === 'referredBy' && sortedInfo.order;
  // });

  const today = new Date();
  const timeElapsed = (date: string) => {
    const prevDate = new Date(date);
    const difference = today.getTime() - prevDate.getTime();
    return difference;
  };

  const jobs = jobsData?.map((item) => {
    const taxYear = item?.TaxYear;
    const entity = taxYear?.Entity;
    const user = entity?.Users;
    const userInfo = user?.UserInformation;
    const interview = item?.Interviews;
    const jobTracking = item?.JobTracking;
    const preparedUser = item?.PreparedUser;
    const preparedUserInfo = preparedUser?.UserInformation;
    const preparedName = preparedUserInfo ? `${preparedUserInfo.firstName} ${preparedUserInfo.lastName}` : undefined;
    const reviewedUser = item?.ReviewedUser;
    const reviewedUserInfo = reviewedUser?.UserInformation;
    const reviewedName = reviewedUserInfo ? `${reviewedUserInfo.firstName} ${reviewedUserInfo.lastName}` : undefined;
    return {
      entityId: entity?.id,
      taxYearInterviewId: item?.id,
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      name: `${userInfo?.firstName} ${userInfo?.lastName}`,
      email: user?.email,
      phone: getPrimaryPhone(userInfo?.Phones),
      taxYear: taxYear?.year,
      jobType: interview?.name,
      inProgressTime: (item?.assignedAt ? timeElapsed(item?.assignedAt) : undefined),
      userId: user?.id,
      referredBy: Users.getUserName(user?.ReferredByUser),
      languageId: user?.languageId,
      progressStatusId: item?.progressStatusId,
      invitationEmailSent: user?.invitationEmailSent,
      password: user?.hasPassword,
      preparedId: preparedUser?.id,
      preparedName,
      reviewedId: reviewedUser?.id,
      reviewedName,
      completedDate: item?.completedAt,
      timeInQueue: timeElapsed(item?.createAt),
      expectedCompletion: jobTracking?.expectedCompletion,
      paymentsCompletedAt: jobTracking?.paymentsCompletedAt,
      approvalsCompletedAt: jobTracking?.approvalsCompletedAt,
      interviewCompletedAt: jobTracking?.interviewCompletedAt,
      filesCompletedAt: jobTracking?.filesCompletedAt,
      assign: underlyingStatus !== 'COMPLETED' ? {
        taxYearId: taxYear?.id,
        preparedId: item?.PreparedUser?.id,
        reviewedId: item?.ReviewedUser?.id || undefined,
        interviewId: interview?.id,
        // status: TaxYearStatus.InProgress, // TODO: do I need this?
        assignedAt: item?.assignedAt,
        id: item?.id,
      } : undefined,
      tasks: item?.Tasks,
      notes: item?.notes,
      reviewStatus: item?.reviewStatus,
    };
  });

  return (
    <>
      <RowAboveJobsTable
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setSelectedKeys={setSelectedKeys}
        tableData={jobs}
        tableRef={tableDivRef}
        type="jobs"
        displayClearbutton={displayClearbutton}
        progressStatusName={progressStatusName}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setSearchedValue={setSearchedValue}
        searchRef={searchRef}
        loading={loadingJobs || loadingProp}
      />
      <div style={{ height: '15px' }} />
      <div className="tableDivRef" ref={tableDivRef}>
        <Table
          loading={loadingJobs || loadingProp}
          sticky={{ offsetHeader: 45 }}
          tableLayout="fixed"
          rowSelection={{
            type: 'checkbox',
            fixed: true,
            columnWidth: 35,
            selectedRowKeys: selectedKeys,
            ...rowSelection,
          }}
          scroll={{ x: 1000 }}
          showSorterTooltip={false}
          components={components}
          // eslint-disable-next-line arrow-body-style
          onRow={(record, rowIndex) => {
            return {
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onClick: () => {}, // click row
              moveRow: (event, p) => {
                moveRow(record, event, p);
              }, // move row
            };
          }}
          columns={columns}
          dataSource={jobs}
          rowKey={(record: any) => record.taxYearInterviewId}
          onChange={handleTableChange} // sorting when a column header is pressed
          noDataProps={{
            title: 'No jobs yet',
            extra: <NoClients />,
          }}
          pagination={{
            hideOnSinglePage: true,
            pageSize,
            total: pagination?.total,
            current: currentPage,
            showTotal: (total) => `Total ${total} clients`,
            onChange: (page) => {
              setCurrentPage(page);
            },
            onShowSizeChange: (current, size) => {
              setPageSize(size);
            },
            pageSizeOptions: ['25', '100', '250', '500'],
          }}
        />
      </div>
    </>
  );
};

export default JobsTable;
