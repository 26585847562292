import { Icons, Tooltip } from 'taxaroo-ui';
import { ReviewStatus } from '~src/graphql';

const {
  Circle,
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
} = Icons;

function Review({ reviewStatus }: { reviewStatus: ReviewStatus }) {
  if (reviewStatus === ReviewStatus?.Accepted) {
    return (
      <Tooltip title="Review completed!">
        <CheckCircleOutlined className="status-icon" style={{ color: 'green' }} />
      </Tooltip>
    );
  }
  if (reviewStatus === ReviewStatus?.In) {
    return (
      <Tooltip title="In Review">
        <ClockCircleOutlined className="status-icon" style={{ color: 'orange' }} />
      </Tooltip>
    );
  }
  if (reviewStatus === ReviewStatus?.Rejected) {
    return (
      <Tooltip title="Review rejected">
        <ExclamationCircleOutlined className="status-icon" style={{ color: 'red' }} />
      </Tooltip>
    );
  }
  return (
    <Tooltip title="No review">
      <span role="img" tabIndex={-1} className="anticon status-icon">
        <Circle width="1em" height="1em" className="status-icon" style={{ color: 'gray' }} />
      </span>
    </Tooltip>
  );
}

export default Review;
