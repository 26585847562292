import { RefObject } from 'react';
import { Input } from 'taxaroo-ui';

const { Search } = Input;

interface SearchBarProps {
  disabled: boolean;
  searchRef: RefObject<HTMLInputElement>;
  setSearchedValue: (value: string) => void;
  searchBarValue: string;
  setSearchBarValue: (value: string) => void;
}

const SearchBar = ({
  disabled,
  searchRef,
  setSearchedValue,
  searchBarValue,
  setSearchBarValue,
}: SearchBarProps) => {
  const handleSearch = (v: string) => {
    setSearchedValue(v);
  };
  return (
    <Search
      ref={searchRef}
      placeholder="Search by name, email, phone (numbers only), or tax year"
      onSearch={handleSearch}
      disabled={disabled}
      value={searchBarValue}
      onChange={(e) => setSearchBarValue(e.target.value)}
    />
  );
};

export default SearchBar;
