import React, { useState, useEffect, FC } from 'react';
import {
  Modal,
  Select,
  Tooltip,
  Icons,
} from 'taxaroo-ui';
import * as styles from './style.module.css';
import { days } from '../../const/eventTypes';
import { getOptions } from '../../helpers/forms';
import { getSchedules, getTime } from '../../helpers/time';

const { InfoCircleOutlined } = Icons;

interface TimeAvailabilitySettingsModalProps {
  handleCancel: any;
  visible: any;
  duration: number;
  addAvailabilities: any;
}

const TimeAvailabilitySettingsModal: FC<TimeAvailabilitySettingsModalProps> = ({
  visible,
  handleCancel,
  duration,
  addAvailabilities,
}) => {
  const [timesTo, setTimesTo] = useState<any>([]);
  const [timesFrom, setTimesFrom] = useState<any>([]);
  const [errors, setErrors] = useState<any>({
    day: false,
    from: false,
    to: false,
  });
  const [bracket, setBracket] = useState<any>({
    day: '',
    from: '',
    to: '',
  });

  useEffect(() => {
    if (!visible) setBracket({ day: '', from: '', to: '' });
  }, [visible]);

  const updateBracket = (value: any, name: string) => {
    let toUpdate = { [name]: value };
    if (name === 'day') setTimesFrom(getSchedules(duration));
    else if (name === 'from') {
      const time = value.split(':');
      const momentTime = getTime(time[0], time[1]);
      setTimesTo(
        getSchedules(duration, momentTime.add(duration, 'm').format('HH:mm')),
      );
      toUpdate = {
        ...toUpdate,
        to: '',
      };
    }
    setBracket({
      ...bracket,
      ...toUpdate,
    });
  };

  const handleOk = () => {
    const validationErrors: Record<string, any> = {};
    Object.keys(bracket).forEach((field) => {
      if (bracket[field].trim() === '') validationErrors[field] = true;
    });
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    addAvailabilities(bracket);
  };

  const { day, from, to } = bracket;

  return (
    <Modal
      title={(
        <>
          New Time Availability
          <Tooltip
            title="Add sections of time, one day at a time or multiple times per day."
          >
            <InfoCircleOutlined
              style={{
                fontSize: 14,
                position: 'relative',
                top: '-0.5em',
                left: '0.5em',
                cursor: 'pointer',
              }}
            />
          </Tooltip>
        </>
      )}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ className: styles.btnok }}
      cancelButtonProps={{ className: styles.btncancel }}
      okText="Save"
    >
      <p className={styles.note}>
        All times are set on your timezone based on your computer system time
        and under Time Availability* on appointment type.
      </p>
      <div className={styles.container}>
        <p className={styles.label}>Day*</p>
        <Select
          className={`${styles.input} ${errors.day && styles.error}`}
          placeholder="Day"
          onSelect={(value) => updateBracket(value, 'day')}
          value={day}
        >
          {getOptions(days)}
        </Select>
        {errors.day && (
          <p className={styles.errorLabel}>Field needed to continue</p>
        )}
        <p className={styles.label}>Availability start time*</p>
        <Select
          disabled={!day}
          className={`${styles.input} ${errors.from && styles.error}`}
          placeholder="From"
          onSelect={(value) => updateBracket(value, 'from')}
          value={from}
        >
          {getOptions(timesFrom)}
        </Select>
        {errors.from && (
          <p className={styles.errorLabel}>Field needed to continue</p>
        )}
        <p className={styles.label}>Availability end time*</p>
        <Select
          disabled={!day && !from}
          className={`${styles.input} ${errors.to && styles.error}`}
          placeholder="To"
          onSelect={(value) => updateBracket(value, 'to')}
          value={to}
        >
          {getOptions(timesTo)}
        </Select>
        {errors.to && (
          <p className={styles.errorLabel}>Field needed to continue</p>
        )}
      </div>
    </Modal>
  );
};
export default TimeAvailabilitySettingsModal;
