import {
  Row,
  Col,
  Spin,
  Icons,
  Modal,
  message,
  Typography,
} from 'taxaroo-ui';
import { useFormik } from 'formik';
import { useCallback } from 'react';
import FormikInput from '~src/components/Formik/FormikInput';
import getTaxYearOptions from '~src/components/helpers/getTaxYearOptions';
import { useAddTaxYearInterviewJobMutation } from '~src/graphql/mutations/clients';
import { useAppSelector } from '~src/redux/hooks';
import { TaxYearStatus } from '~src/graphql';
import { GetClientsByProgressStatusIdDocument } from '~src/graphql/queries/settings';
import style from './add-job.module.css';
import getFormFields from './formik/fields';
import validationSchema from './formik/schema';
import { FormValues, AddJobModalProps } from './types';

const { PlusOutlined } = Icons;
const { Text } = Typography;

const TAX_YEAR_OPTIONS = getTaxYearOptions(10);

function AddJobModal({
  email,
  entityId,
  lastName,
  firstName,
  interviews,
  isModalVisible,
  setIsModalVisible,
}: AddJobModalProps) {
  // get all the progressStatuses from the session
  const { progressStatuses } = useAppSelector((state) => state.session);
  // find the inProgress and unassigned statuses
  const unassignedStatus = progressStatuses.find((status) => status.underlyingStatus === TaxYearStatus.Unassigned);
  const progressStatusOptions = progressStatuses
    .map((x) => ({ value: x.id, label: x.status, index: x.index }))
    .sort((a, b) => (a && b ? a.index - b.index : 0));
  // graphql
  const [addJob, { loading }] = useAddTaxYearInterviewJobMutation();

  const onSubmit = useCallback(async ({
    taxYear: year,
    jobType: interviewId,
    progressStatusId,
    sendEmail,
  }: FormValues) => {
    if (entityId) {
      try {
        await addJob({
          variables: {
            addJobInput: {
              year,
              entityId,
              interviewId,
              progressStatusId: progressStatusId ?? unassignedStatus.id,
              ...(sendEmail ? { sendEmail, email } : { sendEmail }),
            },
          },
          refetchQueries: [
            'GetAllEntityDataByEntityId',
            'GetClients',
            {
              query: GetClientsByProgressStatusIdDocument,
              variables: {
                progressStatusId: progressStatusId ?? unassignedStatus.id,
              },
            },
          ],
        });
        setIsModalVisible(false);
      } catch (err) {
        if (err.message.includes('Unique constraint failed') || err.message.includes('Error code 1002')) {
          message.error(`Job (${year} - ${interviews.find((i) => i.value === interviewId).label}) is already registered.`);
        } else {
          message.error('Something went wrong while trying to add a new job.');
        }
      }
    }
  }, [entityId]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    submitForm,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormik({
    onSubmit,
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      taxYear: '',
      jobType: '',
      progressStatusId: unassignedStatus.id,
      sendEmail: !!email,
    },
  });

  const handleCancel = () => {
    resetForm();
    setIsModalVisible(false);
  };

  return (
    <Modal
      visible={isModalVisible}
      title="Add Job"
      okButtonProps={{ icon: <PlusOutlined /> }}
      okText="Add Job"
      onCancel={handleCancel}
      onOk={() => { submitForm(); }}
    >

      <Row gutter={[10, 10]}>
        <Col lg={7}>
          <Text>Client Name:</Text>
        </Col>
        <Col lg={17}>
          <Text>{`${firstName} ${lastName}`}</Text>
        </Col>
      </Row>
      <div style={{ height: 20 }} />
      <Row gutter={[10, 10]}>
        <Col lg={7}>
          <Text>Client Email:</Text>
        </Col>
        <Col lg={17}>
          <Text>{email || 'Client email is not set'}</Text>
        </Col>
      </Row>
      <div style={{ height: 30 }} />
      <form
        onSubmit={handleSubmit}
        className={style.form}
      >
        <Row gutter={[16, 16]}>

          {loading
            ? (
              <Spin />
            )
            : getFormFields(values, TAX_YEAR_OPTIONS, progressStatusOptions).map((item, index) => (
              <Col {...item.span} key={index}>
                <FormikInput
                  key={item.key}
                  item={item}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  value={values[item.key]}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  disabled={!!(item.key === 'sendEmail' && !email)}
                />
              </Col>
            ))}
        </Row>
      </form>
    </Modal>
  );
}

export default AddJobModal;
