import { useContext } from 'react';
import { Icons } from 'taxaroo-ui';
import ActionButton from '~src/components/molecules/ActionButton';
import { JobContext } from '~src/components/providers/job-provider';
import { useDeleteClientFileMutation } from '~src/graphql/mutations/clients';
import { GetAllFilesByClientDocument, GetFirmFilesFromDbDocument } from '~src/graphql/queries/clients';
import { handleOnErrorDeleteClientFile, onSuccessDeleteClientFile } from './utils';

const { DeleteOutlined } = Icons;

interface DeleteFirmFileButtonProps {
  s3Key: string;
  // eslint-disable-next-line react/require-default-props
  taxYearInterviewIdFromParent?: string;
  // eslint-disable-next-line react/require-default-props
  userId?: string;
}

const DeleteFirmFileButton = ({
  s3Key, taxYearInterviewIdFromParent, userId,
}: DeleteFirmFileButtonProps) => {
  const jobContext = useContext(JobContext);
  const [deleteFirmFile] = useDeleteClientFileMutation({
    variables: {
      s3Key,
    },
    refetchQueries: [
      {
        query: GetFirmFilesFromDbDocument,
        variables: {
          taxYearInterviewId: taxYearInterviewIdFromParent || jobContext?.taxYearInterviewId,
        },
      },
      {
        query: GetAllFilesByClientDocument,
        variables: {
          userId: userId ?? jobContext?.entityOwnerId,
        },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: onSuccessDeleteClientFile,
    onError: handleOnErrorDeleteClientFile,
  });

  const handleDeletefile = async () => {
    await deleteFirmFile();
  };

  return (
    <ActionButton
      tooltip="Delete file"
      confirmAction
      confirmLabel="Are you sure?"
      onClick={() => handleDeletefile()}
    >
      <DeleteOutlined style={{ color: 'red' }} />
    </ActionButton>
  );
};

export default DeleteFirmFileButton;
