import React from 'react';
import { TabPaneProps, Tabs as AntTabs, TabsProps } from 'antd';

export interface TaxTabsProps extends TabsProps {
	/**
	 * Adds/Removes underline
	 */
	bordered?: boolean
}

const Tabs: React.FC<TaxTabsProps>&{
  TabPane: React.FC<TabPaneProps>
} = ({
  className,
  bordered = true,
  ...rest
}) => (
  <AntTabs
    {...rest}
    className={[
      !bordered && 'unbordered',
    ].filter(Boolean).join(' ')}
  />
);

Tabs.TabPane = AntTabs.TabPane;

export default Tabs;
