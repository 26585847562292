import Empty from './empty.png';
import * as styles from './style.module.css';

function EmptyState() {
  return (
    <div className={styles.emptyChatContainer}>
      <div className={styles.emptyChatContent}>
        <img alt="No Conversations Yet" src={Empty} />
        <p className={styles.title}>No Conversations Yet</p>
      </div>
    </div>
  );
}

export default EmptyState;
