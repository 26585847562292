/* eslint-disable react/no-unstable-nested-components */
import { useEffect, FC, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import {
  Form,
  Row,
  Spin,
  TaxarooButton,
  Typography,
  Icons,
  message,
} from 'taxaroo-ui';
import { GetClientsQuery, useGetFirmSettingQuery } from '~src/graphql/queries/settings';
import { MyEventsDocument, MyEventsQuery, MyEventTypesQuery } from '~src/graphql/queries/appointments';
import {
  EventEntity, EventStatuses, EventType, NylasEventEntitiy,
} from '~src/graphql';
import './style.css';
import { useGetNylasCalendarEventsMutation } from '~src/graphql/mutations/appointments';
import { ApolloError } from '@apollo/client';
import client from '~src/graphql/client';
import { useSearchParams } from 'react-router-dom';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import { useAppSelector } from '~src/redux/hooks';
import CustomCalendarToolbar from './CustomCalendarToolbar';
import NewAppointmentModal from '../NewAppointmentModal';
import { MS_PER_MINUTE, getTimeZone } from '../../helpers/time';
import Appointment from '../Appointment';
import { UpdateAppointmentProps } from '../NewAppointmentModal/NewAppointmentModal';

const localizer = momentLocalizer(moment);
const { Text, Title, Link } = Typography;
const { ReloadOutlined, ExclamationCircleOutlined } = Icons;

export type NewAppointmentFormValues = {
  client: string;
  day: string;
  eventType: string;
  meetingType: string;
  notes: string;
  time: string;
};

export type FormatedEvent = {
  id: string;
  title: string;
  date: string;
  duration: number;
  participants: string[];
  start: Date;
  end: Date;
  startDate: any;
  endDate: any;
  meetingId: string;
  status: EventStatuses;
  color: string;
  afterBuffer: number;
  beforeBuffer: number;
  type: EventType;
  isExternal: boolean;
  clientId?: string;
  eventTypeId?: string;
  notes?: string;
}

const formatEvents = (events: Array<EventEntity>, userId: string): Array<FormatedEvent> => events.map((event) => {
  const starDate = new Date(event.startDate).getTime();
  const endDate = new Date(event.endDate).getTime();
  const afterBuffer = event.EventTypes.afterBuffer || 0;
  const beforeBuffer = event.EventTypes.beforeBuffer || 0;
  const clientId = event.Meetings.MeetingAttendees.find((attendee) => attendee.userId !== userId)?.userId;

  return {
    id: event.id,
    title: event.title,
    date: moment(event.startDate).format('ddd MMM DD, YYYY'),
    duration: (endDate - starDate) / 1e3 / 60,
    participants:
        event.Meetings.MeetingAttendees.map(
          (attendee) => `${attendee.firstName} ${attendee.lastName}`,
        ),
    start: new Date(starDate - beforeBuffer * MS_PER_MINUTE),
    end: new Date(endDate + afterBuffer * MS_PER_MINUTE),
    startDate: event.startDate,
    endDate: event.endDate,
    meetingId: event.Meetings.id,
    status: event.status,
    color: event.EventTypes.color,
    afterBuffer,
    beforeBuffer,
    type: event.type,
    isExternal: false,
    clientId,
    eventTypeId: event.eventTypeId,
    notes: event.notes,
  };
});

const formatNylasEvents = (events: Array<NylasEventEntitiy>): Array<FormatedEvent> => events.map((event) => ({
  id: event.id,
  title: event.title,
  date: moment(event.startDate).format('ddd MMM DD, YYYY'),
  duration: event.duration,
  participants:
      event.attendees.map(
        (attendee) => (attendee.name && attendee.name !== attendee.email
          ? `${attendee.name} ${attendee.email}`
          : attendee.email),
      ),
  start: new Date(event.startDate),
  end: new Date(event.endDate),
  startDate: event.startDate,
  endDate: event.endDate,
  meetingId: null,
  status: event.status,
  color: event.color,
  afterBuffer: 0,
  beforeBuffer: 0,
  type: event.type,
  isExternal: true,
}));

const mergeCalendarEvents = (events: FormatedEvent[], nylasEvents: NylasEventEntitiy[]) => {
  const filteredNylasEvents = nylasEvents.filter(
    (nylasEvt) => !events.some(
      (evt) => evt.startDate === nylasEvt.startDate && evt.endDate === nylasEvt.endDate,
    ),
  );

  return events.concat(formatNylasEvents(filteredNylasEvents));
};

interface AppointmentsTableProps {
  appointments: MyEventsQuery,
  loadingAppointments: boolean,
  taxPayers: GetClientsQuery,
  isTaxPayersLoading: boolean,
  eventTypes: MyEventTypesQuery,
  loadingEventTypes: boolean,
  handleOnError: (error: ApolloError) => void,
}

const AppointmentsTable: FC<AppointmentsTableProps> = ({
  appointments,
  loadingAppointments,
  taxPayers,
  isTaxPayersLoading,
  eventTypes,
  loadingEventTypes,
  handleOnError,
}) => {
  const { userId } = useAppSelector((state) => state.session);
  const [newAppointmentModalVisible, setNewAppointmentModalVisible] = useState(false);
  const [newAppointmentForm] = Form.useForm<NewAppointmentFormValues>();
  const [selectedDate, setSelectedDate] = useState<Date>(undefined);
  const [isEventsReloading, setIsEventsReloading] = useState<boolean>(false);
  const [formattedEvents, setFormattedEvents] = useState<Array<FormatedEvent>>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [appointment2Update, setAppointment2Update] = useState<UpdateAppointmentProps>(undefined);

  const [getNylasEventsMutation, { loading: loadingNylasEvents }] = useGetNylasCalendarEventsMutation({ onError: handleOnError });

  const handleOnSelectSlot = (selected: any) => {
    setSelectedDate(new Date(selected.start));
    setNewAppointmentModalVisible(true);
    trackCustomerIoEvent(CUSTOMER_IO_EVENTS.MODAL_SHOW, {
      'Modal Title': 'Schedule an Appointment',
      Source: 'Click on the calendar',
      url: window.location.href,
    });
  };

  const { data: firmSettingsData } = useGetFirmSettingQuery({ variables: { name: 'disable-taxaroo-calendar' } });
  const [disableTaxarooCalendarValue, setDisableTaxarooCalendarValue] = useState<boolean>(false);
  useEffect(() => {
    if (firmSettingsData?.getFirmSetting?.name) {
      // eslint-disable-next-line no-underscore-dangle
      if (firmSettingsData.getFirmSetting.value.__typename === 'BooleanValue') {
        setDisableTaxarooCalendarValue(firmSettingsData.getFirmSetting.value.boolValue);
      }
    }
  }, [firmSettingsData?.getFirmSetting]);

  useEffect(() => {
    if (loadingAppointments === false) {
      getNylasEventsMutation({
        variables: {
          input: {
            from: moment().subtract(1, 'month').startOf('day'),
            to: moment().add(1, 'month').startOf('day'),
          },
        },
      }).then((events) => {
        setFormattedEvents(
          mergeCalendarEvents(
            formatEvents(appointments?.MyEvents as EventEntity[] || [], userId),
            events.data.getNylasCalendarEvents,
          ),
        );
      });
    }
  }, [appointments]);

  useEffect(() => {
    if (window.location.hash.includes('#scheduleAppointment')) {
      setNewAppointmentModalVisible(true);
    }
    const messageString = searchParams.get('message');
    if (messageString) {
      message.success(messageString);
    }
  }, []);

  const handleCancelModal = () => {
    newAppointmentForm.setFieldsValue({
      client: undefined,
      day: undefined,
      time: undefined,
      notes: undefined,
    });

    setAppointment2Update(undefined);
    setNewAppointmentModalVisible(false);
  };

  const reloadEvents = async () => {
    setIsEventsReloading(true);

    const events = await client.refetchQueries({
      include: [MyEventsDocument],
    });

    setFormattedEvents(formatEvents(events[0]?.data?.MyEvents || [], userId));

    setIsEventsReloading(false);

    const nylasEvents = await getNylasEventsMutation({
      variables: {
        input: {
          from: moment().subtract(1, 'month').startOf('day'),
          to: moment().add(1, 'month').startOf('day'),
        },
      },
    });

    setFormattedEvents(
      mergeCalendarEvents(
        formatEvents(events[0]?.data?.MyEvents || [], userId),
        nylasEvents?.data?.getNylasCalendarEvents,
      ),
    );
  };

  const handleUpdateEvent = (initialUpdateValues: UpdateAppointmentProps) => {
    setAppointment2Update(initialUpdateValues);
    setNewAppointmentModalVisible(true);
  };

  const timezone = eventTypes?.MyEventTypes?.[0].timezone || 'UTC';
  const tz = getTimeZone().short;
  const resultTimeZone = tz.indexOf('+') === 0 ? `UTC${tz}` : tz;

  return (
    <>
      {disableTaxarooCalendarValue
        && (
        <Row style={{ padding: '10px 0' }}>
          <Text>
            <ExclamationCircleOutlined style={{ padding: '0 10px 0 0' }} />
            Your clients are not currently able to book appointments with you.
            Please
            {' '}
            <Link href="/settings/calendars">click here</Link>
            {' '}
            to turn on appointments.
          </Text>
        </Row>
        )}
      <Row
        justify="space-between"
        align="middle"
      >
        <Title level={3} style={{ margin: 0 }}>
          Appointments
          {' '}
          <Text small>
            (all appointments shown in&nbsp;
            {resultTimeZone}
            )
          </Text>
          <TaxarooButton
            type="link"
            title="Reload Appointments"
            size="large"
            onClick={reloadEvents}
          >
            <ReloadOutlined />
          </TaxarooButton>
        </Title>
        <TaxarooButton
          type="primary"
          onClick={() => {
            setNewAppointmentModalVisible(true);
            trackCustomerIoEvent(CUSTOMER_IO_EVENTS.MODAL_SHOW, {
              'Modal Title': 'Schedule an Appointment',
              Source: 'Click on button',
              url: window.location.href,
            });
          }}
        >
          <Text style={{ color: '#fff' }}>
            Schedule Appointment
          </Text>
        </TaxarooButton>
      </Row>
      {loadingNylasEvents && (
        <Row>
          Connected calendar events are still loading...&nbsp;&nbsp;&nbsp;
          <Spin size="small" />
        </Row>
      )}
      {loadingAppointments || isEventsReloading
        ? (
          <Row align="middle" justify="center">
            <Spin size="large" />
          </Row>
        )
        : (
          <Calendar
            selectable
            events={formattedEvents}
            localizer={localizer}
            defaultDate={searchParams.get('date') || new Date()}
            defaultView={Views.WEEK}
            onSelectSlot={handleOnSelectSlot}
            components={{
              event: ({ event }) => <Appointment {...event} timezone={timezone} handleUpdateEvent={handleUpdateEvent} position="right" reloadEvents={reloadEvents} />,
              day: {
                event: ({ event }) => <Appointment {...event} timezone={timezone} handleUpdateEvent={handleUpdateEvent} position="bottom" reloadEvents={reloadEvents} />,
              },
              agenda: {
                event: ({ event }) => <Appointment {...event} timezone={timezone} handleUpdateEvent={handleUpdateEvent} position="bottom" reloadEvents={reloadEvents} />,
              },
              toolbar: CustomCalendarToolbar,
            }}
            style={{ width: '100%', marginTop: 25, minHeight: '100vh' }}
            className="appointments-calendar"
          />
        )}

      <NewAppointmentModal
        visible={newAppointmentModalVisible}
        clients={taxPayers?.Clients.data.slice().sort(
          (a, b) => {
            const aName = `${a.Entity.Users.UserInformation?.firstName} ${a.Entity.Users.UserInformation?.lastName}`;
            const bName = `${b.Entity.Users.UserInformation?.firstName} ${b.Entity.Users.UserInformation?.lastName}`;
            if (aName > bName) return 1;
            if (aName < bName) return -1;
            return 0;
          },
        ) || []}
        handleCancel={handleCancelModal}
        isTaxPayersLoading={isTaxPayersLoading}
        eventTypes={eventTypes?.MyEventTypes.filter((et) => et.active) || []}
        loadingEventTypes={loadingEventTypes}
        newAppointmentForm={newAppointmentForm}
        selectedDate={selectedDate}
        events={formattedEvents}
        appointment2Update={appointment2Update}
        reloadEvents={reloadEvents}
      />
    </>
  );
};

export default AppointmentsTable;
