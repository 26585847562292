import React from 'react';
import { Button } from 'taxaroo-ui';
import style from './style.module.css';
import { InviteHeaderInterface } from './interface';

const InviteHeader: React.FC<InviteHeaderInterface> = ({
  buttonType,
  buttonHref,
  buttonLabel,
  imageAddress,
  marginBottom,
  onButtonClick,
}) => (
  <header
    style={{
      marginBottom,
    }}
    className={style.inviteHeader}
  >
    <div
      className={style.inviteHeaderWrapper}
      style={{
        ...(imageAddress ? {
          justifyContent: 'space-between',
        } : {
          justifyContent: 'flex-end',
        }),
      }}
    >
      { imageAddress && (
        <img src={imageAddress} className={style.inviteHeaderLogo} alt="Business logo" />
      ) }
      <Button
        type={buttonType}
        {...buttonHref ? { href: buttonHref } : {}}
        {...onButtonClick ? { onClick: onButtonClick } : {}}
      >
        {buttonLabel}

      </Button>
    </div>
  </header>
);

export default React.memo(InviteHeader);
