import {
  Spin,
  Modal,
  Row as UiRow,
  Col as UiCol,
  RadioChangeEvent,
  Button as UiButton,
  Checkbox as UiCheckbox,
  Typography as UiTypography,
} from 'taxaroo-ui';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  memo, useState, useEffect, useCallback, ChangeEvent,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Message } from '~src/components/helpers/forms';
import FormikInput from '~src/components/Formik/FormikInput';
import { useUpdateClientMutation } from '~src/graphql/mutations/clients';
import { useGetInterviewNamesLazyQuery } from '~src/graphql/queries/interview';
import { LanguagesEntity, OnboardingClientDataEntity, PhoneType } from '~src/graphql';
import * as style from './style.module.css';
import { formFields } from './formik/fields';
import { FormValues, ItemValue } from './types';
import InviteHeader from '../../molecules/InviteHeader';
import { clientOnboardingSchema, registeredClientOnboardingSchema } from './formik/schema';

const Col = memo(UiCol);
const Row = memo(UiRow);
const Button = memo(UiButton);
const Checkbox = memo(UiCheckbox);
const Typography = memo(UiTypography);

type LocationState = {
  accessToken: string;
  data: OnboardingClientDataEntity;
  languages: Array<LanguagesEntity>;
}

const INITIAL_VALUES: FormValues = {
  job: '',
  email: '',
  taxYear: '',
  landline: '',
  birthday: '',
  lastName: '',
  language: '',
  firstName: '',
  workNumber: '',
  mobileNumber: '',
  createPassword: '',
  confirmPassword: '',
};

const InviteClient = () => {
  const navigate = useNavigate();
  /*
    * Use type any because the type of this value had to be of a type that exists in the taxaro-ui
    * repo and is not within the scope of the project (Type: Gutter).
  */
  const [rowGutter] = useState<any>([16, 16]);
  const locationState: LocationState = useLocation().state as LocationState;
  const { t, i18n } = useTranslation();
  const {
    data,
    languages,
    accessToken,
  } = locationState;
  const updateClientInitialValues = { ...INITIAL_VALUES };
  delete updateClientInitialValues.job;
  delete updateClientInitialValues.taxYear;
  const [clientValues, setClientValues] = useState<FormValues>(INITIAL_VALUES);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [areTermsAccepted, setAreTermsAccepted] = useState<boolean>(false);
  const [getJobs, { data: interviewsData, loading }] = useGetInterviewNamesLazyQuery();

  const [updateClient, {
    loading: updateClientLoading,
  }] = useUpdateClientMutation({
    context: {
      headers: {
        authorization: accessToken,
      },
    },
  });

  const handleSubmitForm = ({
    email,
    landline,
    lastName,
    birthday,
    firstName,
    workNumber,
    mobileNumber,
    language: languageId,
    createPassword: password,

  }: FormValues) => {
    const Phones = [
      {
        id: undefined,
        value: mobileNumber,
        type: PhoneType.Personal,
      },
      ...(landline.length ? [
        {
          id: undefined,
          value: landline,
          type: PhoneType.Home,
        },
      ] : []),
      ...(workNumber.length ? [
        {
          id: undefined,
          value: workNumber,
          type: PhoneType.Office,
        },
      ] : []),
    ];

    if (data && data.email) {
      updateClient({
        variables: {
          updateClientInput: {
            email,
            password,
            languageId,
            userInformation: {
              Phones,
              lastName,
              birthday,
              firstName,
              id: data.UserInformation.id,
            },
          },
        },
      }).then((response) => {
        if (response.data.UpdateClient.success) {
          setIsModalVisible(true);
        } else {
          Message(t('client.onboarding.failure'), 'warning');
        }
      }).catch(() => {
        Message(t('client.onboarding.failure'), 'error');
      });
    }
  };

  // * FORMIK CONFIG
  const {
    errors, values, touched, handleChange, handleBlur, setFieldValue, handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: clientValues,
    validationSchema: (data && data.email)
      ? registeredClientOnboardingSchema
      : clientOnboardingSchema(t),
    onSubmit: handleSubmitForm,
  });

  const handleFormikBlur = useCallback((event: ChangeEvent<HTMLInputElement |
     HTMLTextAreaElement>) => {
    handleBlur(event);
  }, []);

  const handleFormikChange = useCallback((event: ChangeEvent<HTMLInputElement> |
    ChangeEvent<HTMLTextAreaElement> | RadioChangeEvent) => { handleChange(event); }, []);

  const handleSetFieldValue = useCallback(
    (
      field: string,
      value: string,
      shouldValidate: boolean,
    ) => {
      if (field === 'language') {
        const language = languages
          .filter(({ id }) => id === value)[0].lang.toLowerCase();
        i18n.changeLanguage(language);
        localStorage.setItem('lang', language);
      }
      setFieldValue(field, value, shouldValidate);
    },
    [],
  );

  const formatErrorMessages = useCallback((formikErrors) => {
    const currentErrors = { ...formikErrors };
    Object.keys(formikErrors).forEach((key) => {
      currentErrors[key] = t(currentErrors[key]);
    });

    return currentErrors;
  }, []);

  const handleSignInNavigate = useCallback(() => {
    navigate('/login');
  }, []);

  /*
    * Use type any because the type of this value had to be of a type that exists in the taxaro-ui
    * repo and is not within the scope of the project (Type: CheckboxChange).
  */
  const handleCheckboxChange = useCallback((event:
    any) => setAreTermsAccepted(event.target.checked), []);

  const handleCloseModal = () => {
    setIsModalVisible(false);
    handleSignInNavigate();
  };

  const getFormFields = useCallback(
    (
      fields: FormValues,
      jobs: Array<{ label: string, value: string }>,
      languagesOptions: Array<{ label: string, value: string }>,
    ): ItemValue[] => formFields(fields, jobs, languagesOptions),
    [],
  );

  useEffect(() => {
    if (data) {
      const { email, languageId: language, UserInformation } = data;
      const { birthday, firstName, lastName } = UserInformation;
      const lang = localStorage.getItem('lang');

      if (lang) {
        const userLanguage = languages.filter(({ id }) => id === language)[0].lang.toLowerCase();

        if (userLanguage !== lang) {
          i18n.changeLanguage(userLanguage);
          localStorage.setItem('lang', userLanguage);
        }
      }

      setClientValues({
        ...clientValues,
        email,
        language,
        birthday,
        lastName,
        firstName,
        job: undefined,
        taxYear: undefined,
      });
    }
  }, []);

  useEffect(() => {
    if (!data) {
      getJobs();
    }
  }, []);

  return (
    <div
      className={style.inviteActivateContainer}
      style={{
        ...(loading || updateClientLoading ? {
          height: '100vh',
        } : {}),
      }}
    >
      <InviteHeader buttonHref="/login" buttonType="link" buttonLabel={t('client.onboarding.signin')} />
      <div className={style.inviteActivateContent}>
        <form
          onSubmit={handleSubmit}
        >
          <div
            className={style.inviteActivateFormCard}
          >
            {
                  loading && !interviewsData ? (
                    <div className={style.inviteActivateSpinner}>
                      <Spin />
                      <strong>Loading ...</strong>
                    </div>
                  )
                    : (
                      <>
                        <div className={style.inviteActivateFormHeader}>
                          <strong>{t('client.onboarding.title', { name: data?.ownerName ?? '' }).toUpperCase()}</strong>
                        </div>
                        <div className={style.inviteInnerContentContainer}>
                          <div className={style.inviteLeftSideContainer}>
                            <Typography>
                              {t('client.onboarding.subtitle')}
                            </Typography>
                            <Row gutter={rowGutter}>
                              { getFormFields(
                                clientValues,
                                interviewsData?.interviewNames.map(({ id, name }) => ({
                                  label: name,
                                  value: id,
                                })),
                                languages?.map(({ description, id }) => ({
                                  value: id,
                                  label: description,
                                })),
                              ).map((item) => (
                                <Col {...item.span} key={item.key}>
                                  <FormikInput
                                    item={item}
                                    touched={touched}
                                    value={values[item.key]}
                                    handleBlur={handleFormikBlur}
                                    handleChange={handleFormikChange}
                                    setFieldValue={handleSetFieldValue}
                                    errors={formatErrorMessages(errors)}
                                    {...(item.key === 'email'
                                      && data && data.email ? { disabled: true } : {})}
                                  />
                                </Col>
                              ))}
                            </Row>
                            <div>
                              <div className={style.inviteActivateTerms}>
                                <Checkbox
                                  onChange={handleCheckboxChange}
                                  className={style.inviteActivateCheckbox}
                                />
                                <Typography>
                                  {t('client.onboarding.agreeTerms')}
                                </Typography>
                                <a target="_blank" href="https://taxaroo.com/terms-of-use/" rel="noreferrer">
                                  {t('client.onboarding.termsOfUse')}
                                  .
                                </a>
                              </div>
                              <Button disabled={!!Object.keys(errors).length || (!areTermsAccepted)} type="primary" htmlType="submit">{t('client.onboarding.getStarted')}</Button>
                            </div>
                          </div>
                          <div className={style.inviteRightSideContainer}>
                            <Typography className={style.inviteWelcomeMessage}>{t('client.onboarding.welcome')}</Typography>
                            <div className={style.inviteAlreadyClientContainer}>
                              <Typography className={style.inviteAlreadyClient}>{t('client.onboarding.alreadyClient')}</Typography>
                              <Button type="primary" onClick={handleSignInNavigate}>{t('client.onboarding.signin')}</Button>
                            </div>
                            <p>{t('client.onboarding.ours')}</p>
                            <p>{t('client.onboarding.instructions')}</p>
                          </div>
                        </div>
                      </>
                    )
                }
          </div>
        </form>
        <div />
      </div>
      <Modal visible={isModalVisible} onCancel={handleCloseModal} type="success" title="Client on-boarding" footer={[<Button type="primary" onClick={handleCloseModal}>Okay</Button>]}>
        <p>
          {t('client.onboarding.success')}
        </p>
      </Modal>
    </div>
  );
};

export default InviteClient;
