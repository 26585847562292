.fileUploadCol > div > div > input[type="file"] {
  width: 100% !important;
  height: 100% !important;
  left: 0;
  z-index: 0 !important;
}

.descriptionRow {
  padding-top: 9px;
}

.uploadProfilePhotoBtn {
  margin-top: 20px;
}
