interface URLSearchParamKeyValue {
  key: string;
  value: string;
}
export const getUrlWithAddedSearchParams = (params: URLSearchParamKeyValue[]) => {
  const searchParams = new URLSearchParams(window.location.search);
  params.forEach(({ key, value }) => {
    searchParams.set(key, value);
  });
  const result = `${window.location.pathname}?${searchParams.toString()}`;
  return result;
};
