import React, { FC } from 'react';
import {
  Row, Icons, Button, ButtonProps,
} from 'taxaroo-ui';

const { PlusOutlined } = Icons;

type NoTeamMembersProps = ButtonProps

const NoTeamMembers: FC<NoTeamMembersProps> = ({ onClick }) => (
  <Row justify="center" style={{ marginTop: 25 }}>
    <Button
      type="primary"
      shape="round"
      icon={<PlusOutlined />}
      iconDirection="left"
      onClick={onClick}
    >
      Add your first team member
    </Button>
  </Row>
);

export default NoTeamMembers;
