import React, {
  ChangeEvent, ChangeEventHandler, FC, useCallback,
} from 'react';
import {
  Typography, Checkbox, Radio, DatePicker as UiDatePicker, RadioChangeEvent, Select as UiSelect,
} from 'taxaroo-ui';
import moment, { Moment } from 'moment';
import { CustomErrorProps } from '~src/components/templates/FirmInfo/typesDefinition';
// eslint-disable-next-line import/no-relative-packages, import/no-unresolved
// import {
//   FormikErrors,
//   FormikTouched,
//   FormikValues,
// } from '../../../../node_modules/Taxaroo-UI/node_modules/formik/dist';
import PhoneInputField from '~src/components/atoms/PhoneInputField';
import { ItemValue } from '~src/components/organisms/InviteClient/types';
import SelectJobType from '~src/components/atoms/SelectJobType';
import * as styles from './FormikInput.module.css';

const { Option: UiOption } = UiSelect;
const { Text: UiText } = Typography;

const Option = React.memo(UiOption);
const Select = React.memo(UiSelect);
const DatePicker = React.memo(UiDatePicker);
const Text = React.memo(UiText);

const allWidth = {
  width: '100%',
};

interface ForkmikInputProps {
  item: ItemValue;
  // errors: FormikErrors<FormikValues>;
  errors: any;
  // touched: FormikTouched<FormikValues>;
  touched: any;
  value: string | number | Moment | boolean;
  handleChange: (event:
    RadioChangeEvent
    | ChangeEvent<HTMLInputElement>
    | ChangeEvent<HTMLTextAreaElement>
  ) => void;
  setFieldValue?: (field: string, value: string | boolean, shouldValidate?: boolean) => void;
  handleBlur: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  addonAfter?: React.ReactNode;
  customError?: CustomErrorProps;
  disabled?: boolean;
}

interface InputTypeProps {
  item: ItemValue;
  value: any;
  handleChange: (event: RadioChangeEvent
    | ChangeEvent<HTMLInputElement>
    | ChangeEvent<HTMLTextAreaElement>
  ) => void;
  setFieldValue: (field: string, value: string | boolean, shouldValidate?: boolean) => void;
  handleBlur: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  addonAfter?: React.ReactNode;
  disabled?: boolean;
}

const InputType: FC<InputTypeProps> = ({
  item,
  handleBlur,
  handleChange,
  value,
  addonAfter,
  setFieldValue,
  disabled,
}) => {
  const handleDateChange = useCallback((e: moment.Moment) => setFieldValue(
    item.key,
    e?.toDate()?.toString() || '',
  ), []);

  const handleInputBlur = useCallback((event:
    ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleBlur(event);
  }, []);

  const handleInputChange = useCallback((
    event: RadioChangeEvent |
    ChangeEvent<HTMLInputElement> |
    ChangeEvent<HTMLTextAreaElement>,
  ) => {
    handleChange(event);
  }, []);

  const handlePhoneInputChange = useCallback((phoneNumber: string) => {
    setFieldValue(item.key, phoneNumber);
  }, [setFieldValue]);

  const handleSelectChange = useCallback(
    (selected: string) => setFieldValue(item.key, selected),
    [],
  );

  const handleCheckboxChange = (checked: boolean) => {
    setFieldValue(item.key, checked);
  };

  if (typeof value !== 'boolean' && item.inputType !== 'checkbox') {
    if (item.inputType === 'radioButton') {
      return (
        <Radio.Group
          name={item.key}
          value={value}
          onChange={handleInputChange}
        >
          {item.options
            && item.options.map((option) => (
              <Radio
                key={option.label}
                value={option.value}
              >
                {option.label}
              </Radio>
            ))}
        </Radio.Group>
      );
    } if (item.inputType === 'date') {
      return (
        <DatePicker
          name={item.key}
          style={allWidth}
          onChange={handleDateChange}
          placeholder={item.placeHolder}
          value={value ? moment(value) : null}
          format="MM/DD/YYYY"
          autoComplete={item.autoComplete || 'off'}
        />
      );
    } if (item.inputType === 'textArea') {
      return (
        <textarea
          value={value}
          name={item.key}
          disabled={!!disabled}
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          placeholder={item.placeHolder}
          className={[styles.input, addonAfter && styles.inputAddon].join(' ')}
          autoComplete={item.autoComplete || 'off'}
        />
      );
    } if (item.inputType === 'phoneNumber') {
      return (
        <PhoneInputField
          value={value}
          name={item.key}
          disabled={!!disabled}
          onBlur={handleInputBlur}
          onChange={handlePhoneInputChange}
          placeholder={item.placeHolder}
          className={[styles.input, addonAfter && styles.inputAddon].join(' ')}
          defaultCountry="US"
        />
      );
    } if (item.inputType === 'select' && item.options) {
      return (
        <Select
          id={item.key}
          disabled={disabled}
          onChange={handleSelectChange}
          className={styles.selectInput}
          placeholder={item.placeHolder}
          value={value.length ? value : undefined}
          autoComplete={item.autoComplete || 'off'}
        >
          {item.options.map(({ label, value: optionValue }) => (
            <Option value={optionValue} key={optionValue}>
              {label}
            </Option>
          ))}
        </Select>
      );
    } if (item.inputType === 'selectJobType') {
      return (
        <SelectJobType
          id={item.key}
          disabled={disabled}
          onChange={handleSelectChange}
          className={styles.selectInput}
          value={value.length ? value : undefined}
        />
      );
    }

    return (
      <input
        value={value}
        name={item.key}
        disabled={disabled}
        type={item.inputType}
        onBlur={handleInputBlur}
        style={{
          ...(disabled ? {
            opacity: 0.5,
            userSelect: 'none',
            cursor: 'not-allowed',
          } : {}),
        }}
        autoComplete={item.autoComplete || 'off'}
        onChange={handleInputChange}
        placeholder={item.placeHolder}
        className={`${styles.input}${addonAfter ? ` ${styles.inputAddon}` : ''}`}
      />
    );
  }

  return (
    <Checkbox
      disabled={disabled}
      checked={item.value}
      value={item.value}
      onChange={(event) => {
        handleCheckboxChange(event.target.checked);
      }}
    >
      {typeof item.checkLabel !== 'function' ? item.checkLabel : item.checkLabel()}
    </Checkbox>
  );
};

InputType.defaultProps = {
  addonAfter: null,
  disabled: undefined,
};

const Input = React.memo(InputType);

const redColor = { color: 'red' };

const FormikInput: FC<ForkmikInputProps> = ({
  item,
  handleBlur,
  handleChange,
  errors,
  touched,
  value,
  addonAfter,
  setFieldValue,
  customError,
  disabled,
}) => (
  <div className={styles.inputContainer}>
    <div>
      <Text strong>
        {item.label}
      </Text>
      {item.required && <Text style={redColor}> *</Text>}
    </div>
    <div
      className={[
        styles.innerContainer,
        ((errors[item.key] && touched[item.key])
            || (customError && customError.keys.find((obj: string) => obj === item.key)))
            && styles.errorInput,
        item.inputType === 'radioButton'
            && errors[item.key]
            && touched[item.key],
      ].join(' ')}
    >
      <Input
        item={item}
        value={value}
        disabled={disabled}
        handleBlur={handleBlur}
        addonAfter={addonAfter}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
      />
      {addonAfter && <div className={styles.addon}>{addonAfter}</div>}
    </div>
    {errors[item.key] && touched[item.key] && (
    <Text style={{ color: 'red' }}>{errors[item.key]}</Text>
    )}
    {customError && customError.keys.find((obj: string) => obj === item.key) && (
    <Text style={{ color: 'red' }}>{customError.error}</Text>
    )}
  </div>
);

FormikInput.defaultProps = {
  setFieldValue: null,
  addonAfter: null,
  customError: null,
  disabled: undefined,
};

export default React.memo(FormikInput);
