import {
  Row, Col, Tabs, Collapse, Icons,
} from 'taxaroo-ui';
import styled from 'styled-components';
import { JobContext } from '~src/components/providers/job-provider';
import { useContext } from 'react';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import Files from './Files';
import InvoicesStatusTracking from './Invoices';
import ESignatureAndApproval from './ESignatureAndApproval';
import Interview from './Interview';
import * as styles from './styles.module.css';
import Preparation from './Preparation';

const {
  CheckCircleFilled,
  FileOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  LockFilled,
  FormOutlined,
} = Icons;
const { TabPane } = Tabs;
const { Panel } = Collapse;

interface ClientStatusTrackingProps {
  jobId: string;
}

const SubJobCollapse = styled(Collapse)`
  &.ant-collapse-borderless {
    background-color: white;
    border: 1px solid #d9d9d9;
  }
`;

function ClientStatusTracking({
  jobId,
}: ClientStatusTrackingProps) {
  const jobContext = useContext(JobContext);
  const {
    completedAt,
    interviewCompletedAt,
    paymentsCompletedAt,
    filesCompletedAt,
    approvalsCompletedAt,
  } = jobContext;

  const tabOnChange = (value: string) => {
    trackCustomerIoEvent(CUSTOMER_IO_EVENTS.TAB_SELECT, {
      'Tab Description': 'Job Specific Status Tracking',
      'Tab Selected': value,
      url: window.location.href,
    });
  };

  return (
    <SubJobCollapse bordered={false} defaultActiveKey={['tracking']}>
      <Panel header="Job Specific Status Tracking" key="tracking">
        <Row gutter={[0, 10]}>
          <Col span={24}>
            <Tabs
              type="card"
              centered
              onChange={tabOnChange}
            >
              <TabPane
                tab={(
                  <span>
                    {interviewCompletedAt
                      ? <CheckCircleFilled className={styles.completed} />
                      : <FormOutlined className={styles.notCompleted} />}
                    &nbsp; Interview
                  </span>
                )}
                key="Interview"
              >
                <Interview />
              </TabPane>
              <TabPane
                tab={(
                  <span>
                    {filesCompletedAt
                      ? <CheckCircleFilled className={styles.completed} />
                      : <FileOutlined className={styles.notCompleted} />}
                    &nbsp; Files
                  </span>
                )}
                key="Files"
              >
                <Files />
              </TabPane>
              <TabPane
                tab={(
                  <span>
                    {completedAt
                      ? <CheckCircleFilled className={styles.completed} />
                      : <ClockCircleOutlined className={styles.notCompleted} />}
                    &nbsp; Preparation
                  </span>
                )}
                key="Preparation"
              >
                <Preparation />
              </TabPane>
              <TabPane
                tab={(
                  <span>
                    {paymentsCompletedAt
                      ? <CheckCircleFilled className={styles.completed} />
                      : <DollarOutlined className={styles.notCompleted} />}
                    &nbsp; Payments
                  </span>
                )}
                key="Payments"
              >
                <InvoicesStatusTracking />
              </TabPane>
              <TabPane
                tab={(
                  <span>
                    {approvalsCompletedAt
                      ? <CheckCircleFilled className={styles.completed} />
                      : <LockFilled className={styles.notCompleted} />}
                    &nbsp; E-Signatures & Approvals
                  </span>
                )}
                key="E-Signatures & Approvals"
              >
                <ESignatureAndApproval />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Panel>
    </SubJobCollapse>
  );
}

export default ClientStatusTracking;
