import {
  Row,
  Col,
  Typography,
  Popover,
  Icons,
} from 'taxaroo-ui';

const { Title } = Typography;
const { InfoCircleTwoTone } = Icons;

interface SettingsHeaderProps {
  title: string;
  tooltipTitle?: string;
  tooltipContent?: any;
}
function SettingsHeader({
  title,
  tooltipTitle,
  tooltipContent,
}: SettingsHeaderProps) {
  return (
    <Row gutter={16} style={{ borderBottom: '1px dashed #D6D6D6' }}>
      <Col>
        <Title level={3}>{title}</Title>
      </Col>
      {Boolean(tooltipTitle) && Boolean(tooltipContent) && (
        <Col style={{ marginTop: '5px' }}>
          <Popover overlayStyle={{ width: '50%' }} placement="right" content={tooltipContent} title={tooltipTitle} trigger="click">
            <InfoCircleTwoTone />
          </Popover>
        </Col>
      )}
    </Row>
  );
}

SettingsHeader.defaultProps = {
  tooltipTitle: null,
  tooltipContent: null,
};

export default SettingsHeader;
