.cont:hover {
  cursor: pointer;
}

.container {
  width: 100%;
  padding: 0 15px 15px;
  background-color: #f4f6f9;
  border-radius: 3px;
  padding-left: 20px;
  padding-bottom: 20px;
}

.title {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  margin-top: 15px;
  color: #565656;
  margin-bottom: 10px;
}

.containerAvailabilities {
  max-height: 725px;
  overflow-y: auto;
}

.continputs {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contTitleTime {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
}

.btn {
  margin-left: 20px;
  color: #22a7e7;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.btn:focus {
  background-color: #22a7e7;
  color: white;
}

.input {
  width: 70%;
  margin-top: 10px;
  border: none !important;
  background-color: transparent !important;
}

.inputTop {
  width: 90% !important;
  margin-top: 10px !important;
  border: 1px solid #aaa !important;
  background-color: transparent !important;
}

.desc {
  font-size: 12px;
  color: #565656;
  margin: 0;
  margin-top: 10px;
}

.error {
  border-color: #f54343 !important;
}

.error-label {
  color: #f54343;
  font-size: 10px;
  margin: 0;
  margin-top: 5px;
}

.btnok {
  background-color: #22a7e7 !important;
  font-size: 14px !important;
  color: white !important;
  width: 92px !important;
  height: 40px !important;
}

.btnok:hover {
  background-color: #22a7e7 !important;
  font-size: 14px !important;
  color: white !important;
  width: 92px !important;
  height: 40px !important;
}

.btnok:focus {
  background-color: #22a7e7 !important;
  font-size: 14px !important;
  color: white !important;
  width: 92px !important;
  height: 40px !important;
}

.btncancel {
  border: 1px solid #22a7e7 !important;
  font-size: 14px !important;
  color: #22a7e7 !important;
  width: 92px !important;
  height: 40px !important;
}
