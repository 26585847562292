import { FC } from 'react';
import {
  Row,
  Col,
  Card,
} from 'taxaroo-ui';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FieldType } from '~src/graphql';
import SignatureFieldDraggable from '../SignatureFieldDraggable';
import SignatureDocSignAreaDroppable from '../SignatureDocSignAreaDroppable';
import * as style from './style.module.css';

interface SignatureFieldsProps {
  dispatch: any;
  state: any;
  form: any;
  editMode: boolean;
  setSignatureFieldsCompleted: (val: boolean) => void;
  isCompiledFileUpdated: boolean;
}

const SignatureFields: FC<SignatureFieldsProps> = ({
  dispatch,
  state,
  form,
  editMode,
  setSignatureFieldsCompleted,
  isCompiledFileUpdated,
}) => (
  <DndProvider backend={HTML5Backend}>
    <Row>
      <Col span={4} style={{ paddingRight: 10, paddingTop: 10 }}>
        <Card
          style={{ backgroundColor: 'aliceblue' }}
          title="Fields"
          bordered={false}
        >
          <SignatureFieldDraggable
            name="Signature"
            fieldType={FieldType.Signature}
          />
          <SignatureFieldDraggable
            name="Date"
            fieldType={FieldType.Date}
          />
          <SignatureFieldDraggable
            name="Text"
            fieldType={FieldType.Text}
          />
        </Card>
      </Col>
      <Col span={20} className={style.signatureDocSignArea}>
        <SignatureDocSignAreaDroppable
          state={state}
          dispatch={dispatch}
          form={form}
          editMode={editMode}
          setSignatureFieldsCompleted={setSignatureFieldsCompleted}
          isCompiledFileUpdated={isCompiledFileUpdated}
        />
      </Col>
    </Row>
  </DndProvider>

);

export default SignatureFields;
