import { FC, useState } from 'react';
import { Tooltip, Icons } from 'taxaroo-ui';

const { CopyOutlined, CheckOutlined } = Icons;

export interface CopyButtonWithTooltipProps {
  copyText: string;
}

const CopyButtonWithTooltip: FC<CopyButtonWithTooltipProps> = ({ copyText }) => {
  const [tooltipTitle, setTooltipTitle] = useState('Copy');

  const onCopy = (event: any) => {
    event.stopPropagation();
    navigator.clipboard.writeText(copyText);
    setTooltipTitle('Copied!');
    setTimeout(() => {
      setTooltipTitle('Copy');
    }, 1000);
  };

  return (
    <Tooltip title={tooltipTitle}>
      {tooltipTitle === 'Copy'
        ? <CopyOutlined onClick={onCopy} />
        : <CheckOutlined style={{ color: 'green' }} />}
    </Tooltip>
  );
};

export default CopyButtonWithTooltip;
