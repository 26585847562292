import { FC, useEffect, useState } from 'react';
import { ToolbarProps, View } from 'react-big-calendar';
import {
  Row, TaxarooButton, Typography, Radio,
} from 'taxaroo-ui';

const { Title } = Typography;
const { Group, Button } = Radio;

const CustomCalendarToolbar: FC<ToolbarProps> = (toolbar) => {
  const {
    view: toolbarView,
    onView,
    onNavigate,
    label,
  } = toolbar;
  const [view, setView] = useState<View>(toolbarView);

  useEffect(() => {
    onView(view);
  }, [view]);

  useEffect(() => {
    setView(toolbarView);
  }, [toolbarView]);

  return (
    <>
      <Row justify="start">
        <Group value={view} onChange={(e) => setView(e.target.value)}>
          <Button value="month">Month</Button>
          <Button value="week">Week</Button>
          <Button value="day">Day</Button>
          <Button value="agenda">Agenda</Button>
        </Group>
      </Row>

      <Row
        justify="space-between"
        style={{ paddingTop: '20px' }}
      >
        <TaxarooButton
          type="link"
          onClick={() => onNavigate('PREV')}
        >
          Back
        </TaxarooButton>
        <Title level={5}>{label}</Title>
        <TaxarooButton
          type="link"
          onClick={() => onNavigate('NEXT')}
        >
          Next
        </TaxarooButton>
      </Row>
    </>
  );
};

export default CustomCalendarToolbar;
