import { Suspense, useEffect } from 'react';
import {
  useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';
import {
  Grid, Layout, message, Spin,
} from 'taxaroo-ui';
import { useAppDispatch, useAppSelector } from '~src/redux/hooks';
import { hamburgerMenuOpen } from '~src/cache';
import LeftSideMenu from '~src/components/organisms/LeftSideMenu';
import TopHeader from '~src/components/organisms/TopHeader';
import LoadingIndicator from '~src/components/atoms/LoadingIndicator';
import { selectClient, setClientData } from '~src/redux/slices/clientSlice';
import { useLoginAsFirmMutation } from '~src/graphql/mutations/login';
import { loginAsPreparer } from '~src/pages/Login/Login';
import { UserFirmAccountEntity } from '~src/graphql';
import { setPreviousAccessToken } from '~src/redux/slices/sessionSlice';
import { useCurrentStripeInformationQuery, useIsActiveFirmQuery } from '~src/graphql/queries/settings';
import PayWall from '~src/pages/PayWall';
import { isMobileView } from '~src/components/helpers/screen';
import UpgradeModal from '~src/components/atoms/UpgradeModal';
import { useUpgradeModal, Features } from '~src/components/atoms/UpgradeModal/ModalProvider';
import { trackFinishedOnboarding } from '~src/components/helpers/customer.io';
import AnnouncementBar from '../AnnouncementBar';

const { useBreakpoint } = Grid;
const { Content } = Layout;

interface Upvoty {
  init: (action: string, config: any) => void;
  // You can add more methods or properties of upvoty if needed
}
declare global {
  interface Window {
    upvoty: Upvoty;
  }
}

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const {
    accessToken,
    firmCreatedAt,
    email,
    firstName,
    lastName,
    userId,
  } = useAppSelector((state) => state.session);
  const screens = useBreakpoint();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const clientState = useAppSelector(selectClient);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const userIdParam = searchParams.get('userId');
  const tokenParam = searchParams.get('token');
  const firmIdParam = searchParams.get('firmId');
  const { data: isActiveFirm, loading: isActiveFirmLoading } = useIsActiveFirmQuery();
  const { data: currentStripeInformation } = useCurrentStripeInformationQuery();
  const { customerId, finishedOnboarding } = currentStripeInformation?.CurrentStripeInformation ?? {};

  const { showModal, accessObject } = useUpgradeModal();

  const [loginAsFirm] = useLoginAsFirmMutation({
    onError: (err) => message.error(`Login as Firm error: ${err.message}`, 5),
  });
  const handleLoginAsFirm = async () => {
    const result = await loginAsFirm({
      variables: {
        token: tokenParam,
        userId: userIdParam,
        firmId: firmIdParam,
      },
    });

    if (result.data?.LoginAsFirm?.accessToken) {
      const { accessToken: accessTokenLoginAs, UserFirmAccount } = result.data.LoginAsFirm;
      loginAsPreparer(dispatch, accessTokenLoginAs, UserFirmAccount as UserFirmAccountEntity);
      dispatch(setPreviousAccessToken({ accessToken }));
      navigate('/', { replace: true });
    }
  };

  useEffect(() => {
    hamburgerMenuOpen(false);
  }, [location.key]);

  useEffect(() => {
    if (Object.values(clientState).some((val) => val) && location.pathname !== '/clients/details') {
      dispatch(setClientData({
        id: undefined,
        email: undefined,
        lastName: undefined,
        entityId: undefined,
        firstName: undefined,
        phoneNumber: undefined,
        jobs: [],
        job: undefined,
      }));
    }

    /* if (((storedSelectedChannel && storedChannels.length)
    || (!storedSelectedChannel && storedChannels.length)) && location.pathname !== '/chat') {
      dispatch(resetChat());
    } */
  }, [location.pathname]);

  // login as firm
  useEffect(() => {
    if (tokenParam && userIdParam) {
      handleLoginAsFirm();
    }
  }, []);

  const setOverlayButtonHints = () => {
    let aiDiv;
    let taxarooDiv;
    let count = 0;

    const interval = setInterval(() => {
      count += 1;

      if (!aiDiv) {
        const aiDivs = document.getElementsByClassName('LandbotLivechat');
        if (aiDivs.length > 0) {
          const iframe = aiDivs[0].querySelector('iframe');
          aiDivs[0].className += ' not-printable';
          const innerDocument = iframe?.contentDocument ?? iframe?.contentWindow?.document;
          if (innerDocument) {
            const divs = innerDocument.getElementsByClassName('LivechatLauncher');
            if (divs.length > 0) {
              [aiDiv] = divs;
              aiDiv.setAttribute('title', 'AI Research Bot');
            }
          }
        }
      }

      if (!taxarooDiv) {
        const taxarooDivs = document.getElementsByClassName('BeaconFabButtonFrame');
        if (taxarooDivs.length > 0) {
          const iframe = taxarooDivs[0].querySelector('iframe'); // as HTMLIFrameElement;
          const innerDocument = iframe?.contentDocument ?? iframe?.contentWindow?.document;
          if (innerDocument) {
            const divs = innerDocument.getElementsByClassName('frame-content');
            if (divs.length > 0) {
              [taxarooDiv] = divs;
              taxarooDiv.setAttribute('title', 'Get Support');
            }
          }
        }
      }

      if ((aiDiv && taxarooDiv) || count > 10) {
        clearInterval(interval);
      }
    }, 500);
  };

  // load landbot
  useEffect(() => {
    if (!accessObject?.[Features.aiResearch]) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    window.addEventListener('mouseover', initLandbot, { once: true });
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    window.addEventListener('touchstart', initLandbot, { once: true });
    let myLandbot: { open: () => void; };
    function initLandbot() {
      if (!myLandbot) {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        // eslint-disable-next-line prefer-arrow-callback
        s.addEventListener('load', function () {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          myLandbot = new Landbot.Livechat({
            configUrl: 'https://storage.googleapis.com/landbot.pro/v3/H-1474688-MNKT6WIOHSPF0OOC/index.json',
          });
          function handleOpenLandbot(event) {
            event.preventDefault();
            myLandbot.open();
          }
          const buttonOpens = document.getElementsByClassName('openLandbot');
          for (let i = 0; i < buttonOpens.length; i += 1) {
            const buttonOpen = buttonOpens[i] as HTMLElement;
            buttonOpen.addEventListener('click', handleOpenLandbot);
          }
        });
        s.src = 'https://cdn.landbot.io/landbot-3/landbot-3.0.0.js';

        const x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);

        setOverlayButtonHints();
      }
    }
  }, [accessObject?.[Features.aiResearch]]);

  // load upvoty
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://taxaroo.upvoty.com/javascript/upvoty.embed.js';
    script.async = true;

    script.onload = () => {
      // eslint-disable-next-line
      if (typeof window.upvoty !== 'undefined') {
        window.upvoty.init('identify', {
          user: {
            id: userId,
            name: `${firstName} ${lastName}`,
            email,
          },
          baseUrl: 'taxaroo.upvoty.com',
          publicKey: '4d2a655bc7f2672b0b731a56a8f2a2ca',
        });
      }
    };

    document.head.appendChild(script);

    // Cleanup: remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // load Stunning
  useEffect(() => {
    const loadStunning = () => {
      // Check if script is already loaded to prevent duplicates
      if (document.getElementById('stunning-bar')) return;

      const script = document.createElement('script');
      script.id = 'stunning-bar';
      script.src = 'https://d1gqkepxkcxgvm.cloudfront.net/stunning-bar.js';
      script.defer = true;
      script.setAttribute('data-app-ckey', '4461kfruqgwcvoyjpsninppsp');
      script.setAttribute('data-stripe-id', customerId);

      const s = document.getElementsByTagName('script')[0];
      s.parentNode?.insertBefore(script, s);
    };

    loadStunning();
    trackFinishedOnboarding(finishedOnboarding);

    // Cleanup: remove the script when the component unmounts or accessToken changes
    return () => {
      const script = document.getElementById('stunning-bar');
      if (script) {
        script.parentNode?.removeChild(script);
      }
    };
  }, [accessToken]);

  if (!accessToken) {
    navigate('/login', { state: { from: location } });
    return null;
  }

  if (isActiveFirmLoading) {
    return (
      <div style={{
        height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}
      >
        <div style={{
          height: '100px', width: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <Spin size="large" />
        </div>
      </div>
    );
  }

  if (!isActiveFirmLoading && !isActiveFirm.isActiveFirm) {
    return (
      <PayWall customerId={customerId} />
    );
  }

  return (
    <Layout>
      {customerId && <AnnouncementBar customerId={customerId} />}
      <TopHeader />
      <Layout style={{ minHeight: 'calc(100vh - 100px)' }}>
        {screens.md && <LeftSideMenu />}
        <Layout style={{ padding: 0 }}>
          <Content
            style={{
              background: '#fff',
              margin: 0,
              minHeight: 280,
              padding: isMobileView() ? 16 : 24,
            }}
          >
            <Suspense
              fallback={(
                <div
                  style={{ display: 'flex', justifyContent: 'center', alignContent: 'flex-start' }}
                >
                  <LoadingIndicator size="calc(100vh - 100px)" type="full" id="loading_jobs" />
                </div>
                )}
            >
              {children}
            </Suspense>
          </Content>
        </Layout>
      </Layout>
      <a
        href="https://taxaroo.upvoty.com/b/feature-requests/"
        target="_blank"
        rel="noreferrer"
        style={{
          position: 'fixed',
          bottom: 22,
          right: 6,
          zIndex: 9999,
          color: 'gray',
          fontSize: 14,
        }}
      >
        Feature request?
      </a>
      <UpgradeModal />
    </Layout>
  );
};

export default PrivateRoute;
