import React, { useState, useMemo } from 'react';
import {
  Menu, PageHeader, Icons, Layout, Badge, Button, Input, Alert, Tooltip, Spin,
  Select,
  Popconfirm,
  Dropdown,
  Skeleton,
} from 'taxaroo-ui';
import {
  QuestionType, SpecialSectionsType,
} from '~src/graphql';
import { useAppSelector } from '~src/redux/hooks';
import { selectSession } from '~src/redux/slices/sessionSlice';
import ConditionalModal from '~src/components/organisms/ConditionalModal';
import useInterviews from '~src/components/hooks/useInterviews';
import { useUpgradeModal, Features } from '~src/components/atoms/UpgradeModal/ModalProvider';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import * as style from './style.module.css';

const { Sider } = Layout;

const {
  PlusOutlined, MenuFoldOutlined, MenuUnfoldOutlined,
} = Icons;

export type Operations = 'create' | 'update' | 'delete';

export type Language = {
  id: string;
  lang: string;
  description?: string;
};

export type Translations = {
  id?: string;
  optionId?: string;
  sectionId?: string;
  Language?: Language;
  questionId?: string;
  description: string;
  operation?: Operations;
};

export type Options = {
  id?: string;
  temporalId?: string;
  questionId?: string;
  temporalQuestionId?: string;
  operation?: Operations;
  Translations?: Array<Translations>;
};

export type Questions = {
  id?: string;
  order: number;
  required: boolean;
  sectionId: string;
  type: QuestionType;
  temporalId?: string;
  operation?: Operations;
  Options?: Array<Options>;
  temporalSectionId?: string;
  Translations?: Array<Translations>;
};

export type Conditional = {
  id: string;
  createAt: Date;
  updateAt?: Date;
  optionId: string;
  sectionId: string;
  operation?: Operations;
};

export type InterviewSection = {
  id?: string;
  order: number;
  parentId?: string;
  isSpecial: boolean;
  temporalId?: string;
  interviewId: string;
  operation?: Operations;
  temporalParentId?: string;
  Questions?: Array<Questions>;
  Conditionals?: Array<Conditional>;
  Translations?: Array<Translations>;
  SubSections?: Array<InterviewSection>;
};

export type Interview = {
  id?: string;
  name: string;
  isLive: boolean;
  temporalId?: string;
  isTemplate: boolean;
  operation?: Operations;
  Sections?: Array<InterviewSection>;
};

const InterviewsTemplate = () => {
  const { languages } = useAppSelector(selectSession);
  const {
    interviews,
    SectionList,
    interviewName,
    hasTranslations,
    currentInterview,
    onAddSectionClick,
    onSaveChangesClick,
    interviewLanguageId,
    handleConditionalOk,
    currentConditionals,
    getInterviewsLoading,
    onCopyInterviewClick,
    handleInterviewClick,
    selectedInterviewKey,
    copyInterviewLoading,
    changeInterviewStatus,
    onInterviewNameChange,
    deleteInterviewLoading,
    createInterviewLoading,
    updateInterviewLoading,
    onDeleteInterviewClick,
    handleConditionalCancel,
    createConditionalLoading,
    updateConditionalLoading,
    isConditionalModalVisible,
    currentOptionsToCondition,
    handleSpecialSectionClick,
    handleCreateInterviewClick,
    createSpecialSectionLoading,
    handleInterviewLanguageChange,
    loadingInterviewById,
    loadingUpdateInterviewState,
  } = useInterviews();
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const { accessObject } = useUpgradeModal();

  const languagesToSelect: Array<{ value: string, label: string }> = useMemo(() => (
    languages
      .filter(({ lang }): boolean => ['EN', 'ES'].includes(lang))
      .map(({ id, description }): { label: string; value: string; } => ({
        value: id,
        label: description ?? '',
      })) ?? []), [languages]);

  const conditionalOptions = useMemo(() => currentOptionsToCondition.map((option) => {
    const isOptionAlreadyConditioned = !!currentConditionals
      ?.some(({ optionId }) => optionId === option.value);

    if (isOptionAlreadyConditioned) {
      return {
        ...option,
        checked: true,
      };
    }

    return {
      ...option,
      checked: false,
    };
  }), [currentOptionsToCondition]);

  const toggleMenu = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={style.interviewsContainer}>
      <Sider collapsible collapsed={collapsed} trigger={null}>
        <Menu mode="inline" defaultSelectedKeys={[selectedInterviewKey]} selectedKeys={[selectedInterviewKey]} className={style.interviewsMenu}>
          {getInterviewsLoading
            ? (
              <div className={style.interviewsLoadingContainer}>
                <Spin />
              </div>
            )
            : (interviews
              .slice()
              .sort((a, b) => (a.isLive && !b.isLive ? -1 : 0))
              .map(({
                id, name, isLive, temporalId,
              }) => (
                <Menu.Item
                  key={id ?? temporalId}
                  icon={(
                    <Badge
                      style={{ backgroundColor: isLive ? '#52c41a' : '#ff4d4f' }}
                      count={`${isLive ? 'Live' : 'Off'}`}
                    />
                  )}
                  onClick={handleInterviewClick}
                >
                  {name}
                </Menu.Item>
              )))}
          <Tooltip title="Create Interview">
            <Button
              icon={<PlusOutlined />}
              className={style.newInterviewButton}
              onClick={handleCreateInterviewClick}
            />
          </Tooltip>
        </Menu>
      </Sider>
      <div className={style.interviewsContentContainer}>
        <PageHeader
          title="Interviews"
          onBack={toggleMenu}
          className={style.pageHeader}
          backIcon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        />
        {currentInterview && (currentInterview.id || currentInterview.temporalId) && !loadingInterviewById && !loadingUpdateInterviewState
          ? (
            <div className={style.interviewContainer}>
              <div className={style.interviewStatusContainer}>
                <Alert
                  showIcon
                  className={style.interviewStatusAlert}
                  message={!currentInterview.isLive ? 'This interview is not live yet' : ''}
                  type={currentInterview.isLive ? 'success' : 'warning'}
                  description={currentInterview.isLive ? (
                    <div>
                      <h3 style={{ color: 'white', marginTop: '-5px' }}>This interview is currently live.</h3>
                      <Button type="default" onClick={changeInterviewStatus}>
                        Hide this interview
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <p>
                        While you configure this interview, it will not
                        be made available for staff
                        or clients. Once you are done editing and
                        ready for it to go live, select
                        &quot;Make it available!&quot; below.
                      </p>
                      <Button type="default" onClick={changeInterviewStatus}>
                        Make it available!
                      </Button>
                    </div>
                  )}
                />
              </div>
              <strong>Interview Name</strong>
              <Input
                value={interviewName}
                onChange={onInterviewNameChange}
                className={style.interviewNameInput}
                disabled={!accessObject?.[Features.entityInterviews]}
              />
              <div className={style.interviewLanguageContainer}>
                <strong>Show In Language</strong>
                <Select
                  value={interviewLanguageId}
                  onChange={(langId: string) => {
                    handleInterviewLanguageChange(langId);

                    trackCustomerIoEvent(CUSTOMER_IO_EVENTS.CLICK, {
                      Title: 'Show In Language',
                      Source: 'Select option',
                      value: languagesToSelect.find((l) => l.value === langId)?.label,
                      url: window.location.href,
                    });
                  }}
                >
                  {
                  languagesToSelect.map(({ value, label }) => (
                    <Select.Option key={value} value={value}>
                      {label}
                    </Select.Option>
                  ))
                }
                </Select>
              </div>

              {
              !!currentInterview.Sections.length && !hasTranslations && (
                <div className={style.interviewStatusContainer}>
                  <Alert
                    showIcon
                    type="info"
                    message="Interview Translations"
                    className={style.interviewStatusAlert}
                    description={(
                      <div>
                        <h3 style={{ color: 'white', marginTop: '-5px' }}>Complete all the translations for the selected language</h3>
                      </div>
                    )}
                  />
                </div>
              )
            }
              <div className={style.sections}>
                <div className={style.mainSectionsOperationsContainer}>
                  <div className={style.sectionsButtonContainer}>
                    <strong>Sections</strong>
                    <Button
                      icon={<PlusOutlined />}
                      onClick={onAddSectionClick}
                    >
                      Add Section
                    </Button>
                  </div>
                  {
                  currentInterview && currentInterview.id && (
                    <Dropdown
                      trigger={['click']}
                      overlay={(
                        <Menu>
                          {Object.values(SpecialSectionsType)
                            .map((key) => (
                              <Menu.Item
                                onClick={handleSpecialSectionClick}
                                key={key}
                              >
                                {key.split('_').map((s) => s[0].toUpperCase() + s.slice(1).toLowerCase()).join(' ')}
                              </Menu.Item>
                            ))}
                        </Menu>
                      )}
                    >
                      <Button loading={createSpecialSectionLoading} className={style.addSpecialSectionButton} type="primary" icon={<PlusOutlined />} onClick={(event) => { event.preventDefault(); }}>Add Special Section</Button>
                    </Dropdown>
                  )
                }
                </div>
              </div>

              {
              !currentInterview?.Sections?.length && (currentInterview.id
                || currentInterview.temporalId) && (
                <div className={style.interviewStatusContainer}>
                  <Alert
                    showIcon
                    type="info"
                    message="Interview Sections"
                    className={style.interviewStatusAlert}
                    description={(
                      <div>
                        <h3 style={{ color: 'white', marginTop: '-5px' }}>An interview should have at least one section</h3>
                      </div>
                    )}
                  />
                </div>
              )
            }
              {SectionList}

              <div className={style.interviewButtonsContainer}>
                <Button
                  onClick={onSaveChangesClick}
                  loading={createInterviewLoading || updateInterviewLoading}
                  disabled={(!currentInterview?.Sections?.length) || !hasTranslations}
                >
                  Save Changes
                </Button>
                <div className={style.copyDeleteButtons}>
                  {currentInterview.id && (
                    <Button loading={copyInterviewLoading} onClick={onCopyInterviewClick} type="primary">Copy Interview</Button>
                  )}
                  <Popconfirm
                    okText="Yes"
                    cancelText="No"
                    placement="topRight"
                    okButtonProps={{ danger: true }}
                    onConfirm={onDeleteInterviewClick}
                    cancelButtonProps={{ type: 'default' }}
                    title="Are you sure you want to delete this?"
                  >
                    <Button loading={deleteInterviewLoading} danger>Delete Interview</Button>
                  </Popconfirm>
                </div>
              </div>

              <ConditionalModal
                onOk={handleConditionalOk}
                options={conditionalOptions}
                onCancel={handleConditionalCancel}
                visible={isConditionalModalVisible}
                isEditing={!!currentConditionals?.length}
                loading={createConditionalLoading || updateConditionalLoading}
              />
            </div>
          )
          : (
            <div className={style.skeletonContainer}>
              {[...Array(3)]
                .map(() => (<Skeleton active={getInterviewsLoading} />))}
            </div>
          )}
        {
          !getInterviewsLoading && !interviews.length && (
            <div className={style.noInterviewsInfoContainer}>
              <Alert className={style.noInterviewsInfo} showIcon type="info" description="Your team has not yet registered any interviews." />
            </div>
          )
        }
      </div>
    </div>
  );
};

export default React.memo(InterviewsTemplate);
