import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import {
  PhonesEntity, TaskEntity, TaxYearInterviewEntity, UpdateTaxYearInterviewInput,
} from '~src/graphql';
import { RootState } from '../store';

type ClientState = {
  id: string;
  email: string;
  entityId: string;
  lastName: string;
  firstName: string;
  languageId: string;
  userInformationId: string;
  job?: TaxYearInterviewEntity;
  jobs?: Array<TaxYearInterviewEntity>;
  workNumber?: Omit<PhonesEntity, '__typename'>;
  phoneNumber?: Omit<PhonesEntity, '__typename'>;
  landlineNumber?: Omit<PhonesEntity, '__typename'>;
  birthday?: Date;
};

type ClientDataPayload = ClientState;

type AddJobsPayload = {
  jobs: Array<TaxYearInterviewEntity>;
};

const INITIAL_STATE: ClientState = {
  jobs: [],
  id: undefined,
  job: undefined,
  email: undefined,
  entityId: undefined,
  lastName: undefined,
  firstName: undefined,
  languageId: undefined,
  workNumber: undefined,
  phoneNumber: undefined,
  landlineNumber: undefined,
  userInformationId: undefined,
  birthday: undefined,
};

export const NO_ANSWER_FOUND_STATE = {
  id: undefined,
  createAt: undefined,
  updateAt: undefined,
  requestAt: undefined,
  completedAt: undefined,
  description: undefined,
  taxYearInterviewId: undefined,
};

const clientSlice = createSlice({
  name: 'client',
  initialState: INITIAL_STATE,
  reducers: {
    resetClientData: (state) => ({
      ...state,
      ...INITIAL_STATE,
    }),
    setClientData: (state, action: PayloadAction<Partial<ClientDataPayload>>) => {
      const {
        workNumber, phoneNumber, landlineNumber, ...restPayload
      } = action.payload;
      return {
        ...state,
        ...restPayload,
        ...(workNumber ? { workNumber: omit(workNumber, '__typename') } : {}),
        ...(phoneNumber ? { phoneNumber: omit(phoneNumber, '__typename') } : {}),
        ...(landlineNumber ? { landlineNumber: omit(landlineNumber, '__typename') } : {}),
      };
    },
    updateJobInList: (state, action: PayloadAction<UpdateTaxYearInterviewInput>) => ({
      ...state,
      jobs: [...state.jobs.map((job) => {
        if (job.id === action.payload.id) {
          return {
            ...job,
            ...action.payload,
          };
        }
        return job;
      })],
    }),
    removeJobInlist: (state, action: PayloadAction<{ id }>) => ({
      ...state,
      jobs: state.jobs.filter(({ id }) => {
        if (id === action.payload.id) return false;

        return true;
      }),
    }),
    updateJobInListAndSelectedJob: (state, action: PayloadAction<UpdateTaxYearInterviewInput>) => ({
      ...state,
      job: state?.job?.id === action.payload.id ? {
        ...state.job,
        ...action.payload,
      } : {
        ...state.job,
      },
      jobs: state.jobs.map((job) => {
        if (job.id === action.payload.id) {
          return {
            ...job,
            ...action.payload,
          };
        }

        return job;
      }),
    }),
    setTaskInJobList(state, action: PayloadAction<{ jobId: string, task: TaskEntity }>) {
      return {
        ...state,
        jobs: state.jobs.map((job) => {
          if (job.id === action.payload.jobId) {
            return {
              ...job,
              Tasks: [...job.Tasks, action.payload.task],
            };
          }

          return job;
        }),
      };
    },
  },
});

export const {
  setClientData,
  updateJobInList,
  removeJobInlist,
  resetClientData,
  updateJobInListAndSelectedJob,
  setTaskInJobList,
} = clientSlice.actions;

export const selectClient = (state: RootState) => state.client;

export const selectClientJobs = (state: RootState) => state.client.jobs
  .filter(({ TaxYear }) => TaxYear.Entity.Users.id === state.client.id);

export const selectSpecificJob = (jobId: string) => (state: RootState) => state.client.jobs
  .filter(({ id }) => id === jobId)[0];

export const selectClientLanguage = ({
  session,
  client,
}: RootState) => session.languages.filter(({ id }) => id === client.languageId)[0];

export const selectTaxYearInterviewAnswerInList = ({
  taxYearInterviewId,
  questionId,
} : { taxYearInterviewId: string, questionId: string }) => ({ client }: RootState) => {
  const job = client.jobs.filter(({ id }) => id === taxYearInterviewId)[0];

  if (job) {
    const { Answers } = job;

    if (Answers?.length) {
      return Answers.filter(({ id }) => id === questionId)[0] ?? NO_ANSWER_FOUND_STATE;
    }
  }

  return NO_ANSWER_FOUND_STATE;
};

// * Select (Job / Jobs > specific job) Payment Task
export const selectPaymentTask = (
  jobId: string,
  paymentId: string,
  inList: boolean,
) => (store: RootState) => (inList ? (
  store.client.jobs.filter(({
    id,
  }) => jobId === id)[0]?.Tasks?.filter(({ Payments }) => Payments[0]?.id === paymentId)[0])
  : (store.client.job.Tasks?.filter(({ Payments }) => Payments[0]?.id === paymentId)[0]));

export default clientSlice.reducer;
