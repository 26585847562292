import { FC } from 'react';
import { useDrag } from 'react-dnd';
import './index.css';

export interface BoxProps {
    // name: string
    dragDropOnSuccess: any
    className: string
    style: any,
    index: number,
    moveRow: any,
}

interface DropResult {
    name: string
}

const DraggableBodyRow: FC<BoxProps> = ({
  index, moveRow, className, style, ...props
}) => {
  const restProps = { ...props };
  const { children } = restProps;
  delete restProps.children;
  const tableRowKeyJson = JSON.parse(JSON.stringify(restProps));
  // console.log("tableRowKeyJson: ", tableRowKeyJson);
  restProps.children = children;
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'box',
    item: { index },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      console.log('item: ', item, 'monitor: ', monitor, 'dropResult: ', dropResult);
      if (item && dropResult) {
        moveRow(tableRowKeyJson['data-row-key'], dropResult);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const opacity = isDragging ? 0.4 : 1;
  // const animationName = isDragging ? 'beat': '';
  // const animationStyle = isDragging ? {animation: 'beat .25s infinite alternate'} : {};
  // const color = isDragging ? "var(--primary-dark)": '';
  if (className === 'ant-table-expanded-row ant-table-expanded-row-level-1 expandedSection') {
    console.log('found subsection');
    return (
      <tr
          // role="Box"
        className={`${className}`}
        style={{ cursor: 'default', backgroundColor: 'aliceblue', ...style }}
        {...restProps}
      />
    );
  }
  if (className === 'ant-table-placeholder') {
    return (
      <tr
          // role="Box"
        className={`${className}`}
        style={{ cursor: 'default', backgroundColor: 'aliceblue', ...style }}
        {...restProps}
      />
    );
  }
  return (
    <tr
      ref={drag}
      className={`${className}`}
      style={{
        opacity, cursor: 'move', backgroundColor: 'aliceblue', ...style,
      }}
      {...restProps}
    />
  );
};

export default DraggableBodyRow;
