import {
  Icons, Tooltip, Menu, Dropdown, message,
} from 'taxaroo-ui';
import { TaskEntity, TaskType } from '~src/graphql';
import { useUpsertJobTrackingByTaxYearInterviewIdMutation } from '~src/graphql/mutations/jobTracking';

const {
  Circle,
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} = Icons;

function Invoice(props: any) {
  const { record } = props;
  const { paymentsCompletedAt, tasks, taxYearInterviewId } = record;
  const invoiceTasks = (tasks ?? []).filter((task: TaskEntity) => task.taskType === TaskType.Payments);
  const inCompleteInvoiceTasks = invoiceTasks.filter((task: TaskEntity) => !task.completedAt);

  // graphql
  const [upsertJobTracking, { loading: loadingUpsertJobTracking }] = useUpsertJobTrackingByTaxYearInterviewIdMutation({
    onError: (error) => {
      message.error(error.message);
    },
  });

  const markComplete = async () => {
    upsertJobTracking({
      variables: {
        createJobTrackingInput: {
          taxYearInterviewId,
          paymentsCompletedAt: new Date(),
        },
      },
      onCompleted: () => {
        message.success('Marked as complete');
      },
    });
  };

  const markNotComplete = async () => {
    upsertJobTracking({
      variables: {
        createJobTrackingInput: {
          taxYearInterviewId,
          paymentsCompletedAt: null,
        },
      },
      onCompleted: () => {
        message.success('Marked as not complete');
      },
    });
  };

  const markReadMenu = (
    <Menu>
      <Menu.Item key="mark-read" onClick={markNotComplete}>
        Mark as not complete
      </Menu.Item>
    </Menu>
  );
  const markUnreadMenu = (
    <Menu>
      <Menu.Item key="mark-unread" onClick={markComplete}>
        Mark as complete
      </Menu.Item>
    </Menu>
  );

  if (loadingUpsertJobTracking) {
    return <LoadingOutlined className="status-icon" />;
  }
  if (paymentsCompletedAt) {
    return (
      <Dropdown overlay={markReadMenu} trigger={['click']}>
        <Tooltip title="Completed!">
          <CheckCircleOutlined className="status-icon" style={{ color: 'green' }} />
        </Tooltip>
      </Dropdown>
    );
  }
  if (invoiceTasks.length > 0 && inCompleteInvoiceTasks.length === 0) {
    return (
      <Dropdown overlay={markUnreadMenu} trigger={['click']}>
        <Tooltip title="Client Updated Info">
          <ExclamationCircleOutlined className="status-icon" style={{ color: 'orange' }} />
        </Tooltip>
      </Dropdown>
    );
  }
  if (invoiceTasks.length > 0 && inCompleteInvoiceTasks.length > 0) {
    return (
      <Dropdown overlay={markUnreadMenu} trigger={['click']}>
        <Tooltip title="Waiting for client payment">
          <ClockCircleOutlined className="status-icon" style={{ color: 'orange' }} />
        </Tooltip>
      </Dropdown>
    );
  }
  return (
    <Dropdown overlay={markUnreadMenu} trigger={['click']}>
      <Tooltip title="Not started">
        <span role="img" tabIndex={-1} className="anticon status-icon ant-dropdown-trigger">
          <Circle width="1em" height="1em" className="status-icon" style={{ color: 'gray' }} />
        </span>
      </Tooltip>
    </Dropdown>
  );
}

export default Invoice;
