import { useState } from 'react';
import {
  Divider,
  Row,
  Col,
  Radio,
  RadioChangeEvent,
  TaxarooDatePicker,
  Typography,
  Spin,
} from 'taxaroo-ui';
import moment from 'moment';
import { ProgressStatusesEntity, TaxYearStatus } from '~src/graphql';
import { useGetProgressStatusesQuery } from '~src/graphql/queries/settings';
import { useFirmTaxPreparersQuery } from '~src/graphql/queries/import-clients';
import JobsByTeamMember from '~src/components/organisms/Analytics/JobsByTeamMember';
import AllReviews from '../../components/organisms/Analytics/AllReviews';
import FirmCompiledReviewsSection from '../../components/organisms/Analytics/FirmCompiledReviewsSection';
import LineChartSection from '../../components/organisms/Analytics/LineChartSection';
// import * as styles from './style.module.css';

const { RangePicker } = TaxarooDatePicker;
const { Title } = Typography;

// interface AnalyticsProps {}
function Analytics() {
  const [timeFrame, setTimeFrame] = useState('1 wk');
  const [startDate, setStartDate] = useState(moment().subtract(1, 'week'));
  const [endDate, setEndDate] = useState(moment());

  // graphql queries
  // get all progress statuses
  const { data: progressStatusesData, loading: loadingProgressStatuses } = useGetProgressStatusesQuery();
  const progressStatuses = progressStatusesData?.ProgressStatuses;
  // get all preparers
  const { data: preparersData, loading: loadingPreparers } = useFirmTaxPreparersQuery();
  const preparers = preparersData?.FirmTaxPreparers;

  const handleTimeFrameChange = (e: RadioChangeEvent) => {
    setTimeFrame(e.target.value);
    switch (e.target.value) {
      case '1 wk':
        setStartDate(moment().subtract(1, 'week'));
        setEndDate(moment());
        break;
      case '4 wk':
        setStartDate(moment().subtract(4, 'week'));
        setEndDate(moment());
        break;
      case '1 yr':
        setStartDate(moment().subtract(1, 'year'));
        setEndDate(moment());
        break;
      case 'MTD':
        setStartDate(moment().startOf('month'));
        setEndDate(moment());
        break;
      case 'YTD':
        setStartDate(moment().startOf('year'));
        setEndDate(moment());
        break;
      default:
        break;
    }
  };

  const onCalendarChange = (dates) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
    setTimeFrame(null);
  };

  if (loadingProgressStatuses) {
    return (
      <Row justify="center" align="middle" style={{ height: 'calc(90% - 60px)' }}>
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <div>
      <Title level={3} style={{ margin: 0 }}>
        Your Firm Analytics
      </Title>
      <Row
        justify="end"
        gutter={[15, 0]}
        style={{
          paddingTop: '10px', position: 'sticky', top: 45, zIndex: 1, background: 'white',
        }}
      >
        <Col>
          <Radio.Group value={timeFrame} onChange={handleTimeFrameChange}>
            <Radio.Button value="1 wk">1 wk</Radio.Button>
            <Radio.Button value="4 wk">4 wk</Radio.Button>
            <Radio.Button value="1 yr">1 yr</Radio.Button>
            <Radio.Button value="MTD">MTD</Radio.Button>
            <Radio.Button value="YTD">YTD</Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <RangePicker
            onCalendarChange={onCalendarChange}
            value={[startDate, endDate]}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '2em' }}>
        <Title level={3}>Firm Statistics</Title>
      </Row>
      <Row>
        <Col span={12}>
          <LineChartSection
            title="Jobs In Progress"
            tooltip="In Progress jobs include our default In Progress status and all your custom statuses."
            dollarSubtitle="In progress during selected period"
            numberSubtitle="Jobs in progress during selected period"
            progressStatusIds={
              progressStatuses?.filter((status: ProgressStatusesEntity) => status.underlyingStatus === TaxYearStatus.InProgress
                || status.underlyingStatus === TaxYearStatus.Custom) // filter by underlyingStatus
                .map((status: ProgressStatusesEntity) => status.id) // return just the ids
            }
            startDate={startDate}
            endDate={endDate}
            dateFilterKey="assignedAt"
          />
        </Col>
        <Col span={12}>
          <LineChartSection
            title="Jobs Completed"
            dollarSubtitle="Completed during selected period"
            numberSubtitle="Jobs completed during selected period"
            progressStatusIds={
              progressStatuses?.filter((status: ProgressStatusesEntity) => status.underlyingStatus === TaxYearStatus.Completed) // filter by underlyingStatus
                .map((status: ProgressStatusesEntity) => status.id) // return just the ids
            }
            startDate={startDate}
            endDate={endDate}
            dateFilterKey="completedAt"
          />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={12}>
          {/* In progress by team member */}
          <JobsByTeamMember
            title="Jobs In Progress by Team Member"
            tooltip="In Progress jobs include our default In Progress status and all your custom statuses."
            preparers={preparers || []}
            progressStatusIds={
              progressStatuses?.filter((status: ProgressStatusesEntity) => status.underlyingStatus === TaxYearStatus.InProgress) // filter by underlyingStatus
                .map((status: ProgressStatusesEntity) => status.id) // return just the ids
            }
            startDate={startDate}
            endDate={endDate}
            dateFilterKey="assignedAt"
          />
        </Col>
        <Col span={12}>
          {/* Completed by team member */}
          <JobsByTeamMember
            title="Jobs Completed by Team Member"
            preparers={preparers || []}
            progressStatusIds={
              progressStatuses?.filter((status: ProgressStatusesEntity) => status.underlyingStatus === TaxYearStatus.Completed) // filter by underlyingStatus
                .map((status: ProgressStatusesEntity) => status.id) // return just the ids
            }
            startDate={startDate}
            endDate={endDate}
            dateFilterKey="completedAt"
          />
        </Col>
      </Row>
      <Divider />
      <Title level={3}>All Firm Reviews</Title>
      <FirmCompiledReviewsSection
        preparers={preparers || []}
      />
      <Divider />
      <Title level={3}>
        Reviews
        <span
          style={{ fontWeight: 'lighter', fontSize: 16, marginLeft: 15 }}
        >
          (
          {' '}
          {startDate?.format('MMM Do, YYYY')}
          {' '}
          -
          {' '}
          {endDate?.format('MMM Do, YYYY')}
          {' '}
          )
        </span>
      </Title>
      <AllReviews
        startDate={startDate}
        endDate={endDate}
        preparers={preparers || []}
      />
    </div>
  );
}

export default Analytics;
