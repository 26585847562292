import { useContext, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  Icons,
  Grid,
  Breadcrumb,
  Alert,
} from 'taxaroo-ui';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import styled from 'styled-components';
import ResendActivationEmailButton from '~src/components/atoms/ResendActivationMailButton';
import LoginAsClientButton from '~src/components/atoms/LoginAsClientButton';
import { InterviewEntity } from '~src/graphql';
import { TaxPayerContext } from '~src/components/providers/taxpayer-provider';
import getPhoneIcon from '~src/components/helpers/getPhoneIcon';
import AddJobModal from '../AddJobModal';
import UpdateClientInfoModal from '../UpdateClientInfoModal';
import * as style from './index.module.css';

const { useBreakpoint } = Grid;
const {
  PlusCircleOutlined,
  MobileOutlined,
  MailOutlined,
  MessageOutlined,
} = Icons;

const UnderlineLink = styled.a`
  &:hover {
    text-decoration: underline;
    text-decoration-color: '#0076e2';
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
  }
`;

interface ProfileCardProps {
  interviews: Array<InterviewEntity>;
}

function ProfileCard({ interviews }: ProfileCardProps) {
  const taxPayerContext = useContext(TaxPayerContext);
  const {
    email,
    phone: phoneNumber,
    firstName,
    lastName,
    entityId,
    ownerId,
    deleteAt,
  } = taxPayerContext;
  const [clientInfoModalVisible, setClientInfoModalVisible] = useState(false);
  const [addJobModalVisible, setAddJobModalVisible] = useState(false);
  const screens = useBreakpoint();

  return (
    <>
      {deleteAt && (
        <Alert
          type="error"
          message="This client has been deleted."
          style={{ marginBottom: 10 }}
        />
      )}
      <Breadcrumb
        className={style.breadcrumb}
        items={[
          { label: 'Client Dashboard', href: '/' },
          { label: `${firstName ?? ''} ${lastName ?? ''}` },
        ]}
      />

      <Card
        headStyle={{ display: 'none' }}
        bodyStyle={{ paddingTop: 8, paddingBottom: 8 }} // CANDO: change based on sticking or not
        style={screens.xl ? {
          position: 'sticky', top: '50px', zIndex: 2, margin: '0 auto',
        } : {}}
      >
        <Row gutter={[0, 10]} justify="space-between">
          {Boolean(phoneNumber) && (
          <Col>
            <Button
              type="link"
            >
              {getPhoneIcon(phoneNumber)}
              {' '}
              <UnderlineLink href="tel:">
                {formatPhoneNumberIntl(phoneNumber.value)}
              </UnderlineLink>
            </Button>
          </Col>
          )}
          {Boolean(email) && (
          <Col>
            <Button
              type="link"
            >
              <MailOutlined />
              {' '}
              <UnderlineLink href={`mailto:${email}`} target="_blank" rel="noreferrer">{email}</UnderlineLink>
            </Button>
          </Col>
          )}
          <Col>
            <Button
              type="link"
            >
              <MessageOutlined />
              {' '}
              <UnderlineLink href={`/chat?userToChatWith=${ownerId}&description=${encodeURIComponent(`${firstName} ${lastName}`)}`}>Messaging</UnderlineLink>
            </Button>
          </Col>
          <Col>
            <Button
              type="link"
                // size="small"
              icon={<PlusCircleOutlined />}
              iconDirection="left"
              onClick={() => setAddJobModalVisible(true)}
            >
              Add Job
            </Button>
          </Col>
          <Col>
            <ResendActivationEmailButton userId={ownerId} />
          </Col>
          <Col>
            <LoginAsClientButton userId={ownerId} />
          </Col>
        </Row>
      </Card>
      <UpdateClientInfoModal
        isModalVisible={clientInfoModalVisible}
        setIsModalVisible={setClientInfoModalVisible}
      />
      <AddJobModal
        entityId={entityId}
        isModalVisible={addJobModalVisible}
        setIsModalVisible={setAddJobModalVisible}
        email={email}
        lastName={lastName}
        firstName={firstName}
        interviews={interviews?.map(({ name, id }) => ({ label: name, value: id }))}
      />
    </>
  );
}

export default ProfileCard;
