import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFileTemplatesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetFileTemplatesQuery = { __typename?: 'Query', FileTemplates: Array<{ __typename?: 'FileTemplateEntity', id: string, name?: string | null, fileName?: string | null, s3Key?: string | null }> };


export const GetFileTemplatesDocument = gql`
    query GetFileTemplates {
  FileTemplates {
    id
    name
    fileName
    s3Key
  }
}
    `;

/**
 * __useGetFileTemplatesQuery__
 *
 * To run a query within a React component, call `useGetFileTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFileTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetFileTemplatesQuery, GetFileTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFileTemplatesQuery, GetFileTemplatesQueryVariables>(GetFileTemplatesDocument, options);
      }
export function useGetFileTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFileTemplatesQuery, GetFileTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFileTemplatesQuery, GetFileTemplatesQueryVariables>(GetFileTemplatesDocument, options);
        }
export type GetFileTemplatesQueryHookResult = ReturnType<typeof useGetFileTemplatesQuery>;
export type GetFileTemplatesLazyQueryHookResult = ReturnType<typeof useGetFileTemplatesLazyQuery>;
export type GetFileTemplatesQueryResult = Apollo.QueryResult<GetFileTemplatesQuery, GetFileTemplatesQueryVariables>;
export const namedOperations = {
  Query: {
    GetFileTemplates: 'GetFileTemplates'
  }
}