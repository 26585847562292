import { ApolloError } from '@apollo/client';
import {
  FC, useContext, useEffect, useState,
} from 'react';
import {
  Button, Row, Icons, Input, message,
} from 'taxaroo-ui';
import { badgeBgColors } from '~src/components/molecules/ImportClientsModal/TableForm';
import { DocumentsTableType } from '~src/components/organisms/Client/DocumentsTable';
import { TaxPayerContext } from '~src/components/providers/taxpayer-provider';
import { useUpdateFileNameMutation } from '~src/graphql/mutations/file';
import { useGetSignedFileByS3KeyLazyQuery } from '~src/graphql/queries/clients';

const { EditOutlined, CloseOutlined, CheckOutlined } = Icons;

interface FileNameClickableOpenProps {
  id: string;
  name: string;
  s3Key: string;
  source: DocumentsTableType,
}

const FileNameClickableOpen: FC<FileNameClickableOpenProps> = ({
  id, s3Key, name, source,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [fileName, setFileName] = useState(name);
  /*
    From Docs about useState: The value you want the state to be initially. It can be a value of any type,
    but there is a special behavior for functions. This argument is ignored after the initial render.
  */
  useEffect(() => { setFileName(name); }, [name]);

  const { dispatch } = useContext(TaxPayerContext);
  const [updateFileNameMutation, { loading }] = useUpdateFileNameMutation({
    onError: (err: ApolloError) => message.error(err.message, 10),
  });

  const [getSignedFile] = useGetSignedFileByS3KeyLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const handleOpen = async () => {
    try {
      const result = await getSignedFile({
        variables: {
          s3Key,
        },
      });
      const url = result?.data?.SignedFileByS3Key;
      window.open(url, '_blank');
    } catch (e) {
      console.log('caught error... ');
      console.warn(e);
    }
  };

  const updateFileName = async () => {
    const result = await updateFileNameMutation({
      variables: {
        id,
        name: fileName,
      },
    });

    if (source === DocumentsTableType.Client) {
      dispatch({
        type: 'UPDATE',
        payload: {
          clientFilesChanged: true,
        },
      });
    }
    if (source === DocumentsTableType.Firm) {
      dispatch({
        type: 'UPDATE',
        payload: {
          firmFilesChanged: true,
        },
      });
    }
    if (source === DocumentsTableType.All) {
      dispatch({
        type: 'UPDATE',
        payload: {
          allFilesChanged: true,
        },
      });
    }

    setIsEdit(false);
    setFileName(result.data?.updateFileName?.name || name);
    if (result.data?.updateFileName?.name) {
      message.success('FIle name changed', 10);
    }
  };

  return (
    <Row>
      {isEdit
        ? (
          <Input
            value={fileName}
            disabled={loading}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFileName(e.target.value)}
            suffix={(
              <Row>
                <CloseOutlined
                  style={{ color: badgeBgColors.red }}
                  onClick={() => { setIsEdit(false); setFileName(name); }}
                />
                <CheckOutlined
                  style={{ color: badgeBgColors.green }}
                  onClick={() => { updateFileName(); }}
                />
              </Row>
            )}
          />
        )
        : (
          <Button
            type="link"
            onClick={() => handleOpen()}
          >
            {fileName}
          </Button>
        )}

      {!isEdit && (
        <Button
          type="link"
          icon={<EditOutlined style={{ fontSize: '16px' }} />}
          onClick={() => { setIsEdit(true); }}
          title="Change file name"
        />
      )}
    </Row>
  );
};

export default FileNameClickableOpen;
