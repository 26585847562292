import { useContext } from 'react';
import { Icons } from 'taxaroo-ui';
import ActionButton from '~src/components/molecules/ActionButton';
import { JobContext } from '~src/components/providers/job-provider';
import { useDeleteClientFileMutation } from '~src/graphql/mutations/clients';
import { GetAllFilesByClientDocument, GetClientFilesFromDbDocument } from '~src/graphql/queries/clients';
import { handleOnErrorDeleteClientFile, onSuccessDeleteClientFile } from './utils';

const { DeleteOutlined } = Icons;

interface DeleteClientFileButtonProps {
  s3Key: string;
  // eslint-disable-next-line react/require-default-props
  taxYearInterviewIdFromParent?: string;
  // eslint-disable-next-line react/require-default-props
  userId?: string;
}

const DeleteClientFileButton = ({
  s3Key, taxYearInterviewIdFromParent, userId,
}: DeleteClientFileButtonProps) => {
  const jobContext = useContext(JobContext);
  const [deleteClientFile] = useDeleteClientFileMutation({
    variables: {
      s3Key,
    },
    refetchQueries: [
      {
        query: GetClientFilesFromDbDocument,
        variables: {
          taxYearInterviewId: taxYearInterviewIdFromParent || jobContext?.taxYearInterviewId,
          userId: userId ?? jobContext?.entityOwnerId,
        },
      },
      {
        query: GetAllFilesByClientDocument,
        variables: {
          userId: userId ?? jobContext?.entityOwnerId,
        },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: onSuccessDeleteClientFile,
    onError: handleOnErrorDeleteClientFile,
  });

  const handleDeleteFile = async () => {
    await deleteClientFile();
  };

  return (
    <ActionButton
      tooltip="Delete file"
      confirmAction
      confirmLabel="Are you sure?"
      onClick={() => handleDeleteFile()}
    >
      <DeleteOutlined style={{ color: 'red' }} />
    </ActionButton>
  );
};

export default DeleteClientFileButton;
