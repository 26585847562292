.input {
  width: 100%;
  margin-top: 10px;
  border: 1px solid #aaa !important;
}

.label {
  font-size: 14px;
  font-weight: 500;
  color: #565656;
  margin: 0;
  margin-top: 15px;
  margin-bottom: 10px;
}

.container {
  width: 100%;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #f4f6f9;
  border-radius: 3px;
  padding-bottom: 100px;
}

.btnok {
  background-color: #22a7e7 !important;
  font-size: 14px !important;
  color: white !important;
  width: 92px !important;
  height: 40px !important;
}

.btnok:hover {
  background-color: #22a7e7 !important;
  font-size: 14px !important;
  color: white !important;
  width: 92px !important;
  height: 40px !important;
}

.btncancel {
  border: 1px solid #22a7e7 !important;
  font-size: 14px !important;
  color: #22a7e7 !important;
  width: 92px !important;
  height: 40px !important;
}

.error {
  border-color: #f54343 !important;
}

.errorLabel {
  color: #f54343;
  font-size: 10px;
  margin: 0;
  margin-top: 5px;
}

.note {
  font-size: 14px;
}
