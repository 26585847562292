.backgroundOrange {
  background-color: rgb(235 145 58);
}

.backgroundGreen {
  background-color: rgb(114 194 64);
}

.backgroundBlue {
  background-color: rgb(50 73 196);
}

.backgroundLightBlue {
  background-color: rgb(45 183 245);
}

.updateDetailsMark {
  width: 12px;
  height: 12px;
  margin: 0 10px 0 0;
}

.badgeClientsCount {
  margin: 0 5px !important;
}

.importTypeText {
  color: #fff !important;
  white-space: nowrap;
  padding: 5px;
  border-radius: 5px;
}

.updateCell {
  color: #fff !important;
}
