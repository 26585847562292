import React from 'react';
import { Breadcrumb as AntdBreadcrumb, BreadcrumbProps as AntdBreadcrumbProps } from 'antd';

interface BreadcrumbProps {
  items: Array<{ icon?: React.ReactNode; href?: string; label: string }>
}

const Breadcrumb: React.FC<BreadcrumbProps & AntdBreadcrumbProps> = ({ items, ...rest }) => (
  <div style={{ width: '100%' }}>
    <AntdBreadcrumb separator="" {...rest}>
      {items.map(({ href, label, icon }, index) => (
        <>
          <AntdBreadcrumb.Item {...(href ? { href } : {})}>
            {label}
            {' '}
          </AntdBreadcrumb.Item>
          {icon && icon}
          {index !== items.length - 1 && <AntdBreadcrumb.Separator>/</AntdBreadcrumb.Separator>}
        </>
      ))}
    </AntdBreadcrumb>
  </div>
);

export default Breadcrumb;
