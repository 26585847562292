import { FC } from 'react';
import {
  Typography,
  Col,
  Layout,
  List,
} from 'taxaroo-ui';
import { Features, useUpgradeModal } from '~src/components/atoms/UpgradeModal/ModalProvider';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import SettingsHeader from '../SettingsHeader';

const {
  Paragraph, Text, Title, Link,
} = Typography;

const TaxarooDrive: FC = () => {
  const { showModal, hideModal, accessObject } = useUpgradeModal();

  const needsToUpgrade = !accessObject?.[Features.mappedDrive];
  if (needsToUpgrade) {
    showModal(Features.mappedDrive, false);
  } else {
    hideModal();
  }

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <SettingsHeader title="Taxaroo Drive" />
      <Col style={{ paddingTop: '10px' }}>
        <Typography>
          <Paragraph>
            <Text>
              Welcome to the Taxaroo Mapped Drive Settings page.
              Here, you can download the necessary installation files to connect your local computer to your Taxaroo firm portal.
              This feature allows you to seamlessly drag and drop files between your computer and your cloud-based dashboard,
              making document management easier and more efficient.
            </Text>
          </Paragraph>
          <Paragraph>
            <Text>
              To get started, simply download the installation file for your type of computer below.
              Once installed, you’ll be able to access your Taxaroo client files directly from your computer, just like any other local drive.
            </Text>
          </Paragraph>
          <Paragraph>
            <Text>
              Taxaroo Drive is supported on Windows 10 and 11 at this time.
            </Text>
          </Paragraph>
          <Paragraph>
            <Text>
              If you encounter any issues or have any questions, don’t hesitate to reach out to our support team.
              We’re here to help!
            </Text>
          </Paragraph>

          <Title level={5}>
            Taxaroo Drive Installer File
          </Title>
          <List>
            {/* <List.Item>
            <Link href="https://taxaroo1.s3.amazonaws.com/taxaroo-drive/distrib/darwin_amd64/TaxarooDrive.pkg">
              MacOS, Intel (darwin_amd64)
            </Link>
          </List.Item>
          <List.Item>
            <Link href="https://taxaroo1.s3.amazonaws.com/taxaroo-drive/distrib/darwin_arm64/TaxarooDrive.pkg">
              MacOS, Apple Silicon (darwin_arm64)
            </Link>
          </List.Item> */}
            <List.Item>
              <Link
                href="https://taxaroo1.s3.amazonaws.com/taxaroo-drive/distrib/windows_amd64/TaxarooDriveInstall.exe"
                onClick={() => {
                  trackCustomerIoEvent(CUSTOMER_IO_EVENTS.CLICK, {
                    Title: 'Taxaroo drive installer',
                    Source: 'Link click',
                    url: window.location.href,
                  });
                }}
              >
                Windows, Intel (windows_amd64)
              </Link>
            </List.Item>
          </List>
        </Typography>
      </Col>
    </Layout>
  );
};

export default TaxarooDrive;
