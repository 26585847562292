.registerFormItem {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.registerFormInput {
  border: 1px solid #c4cfd9 !important;
  border-radius: 10px !important;
  background: #fff !important;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px !important;
}

.registerFormInput input {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px !important;
}

.registerButton {
  font-family: "Open Sans", sans-serif;
  font-size: 14px !important;
  font-weight: 400 !important;
  width: 162px;
  height: 50px !important;
  border-radius: 30px !important;
  text-transform: none !important;
}

@keyframes fade-out {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fadeOut {
  animation: fade-out 1s forwards;
}

.fadeIn {
  animation: fade-in 1s forwards;
}
