import { Icons } from 'taxaroo-ui';
import moment from 'moment';
import { PhonesEntity, UserFirmAccountOrderByInput } from '~src/graphql';
import getPhoneIcon from '~src/components/helpers/getPhoneIcon';
import SelectTeamMember from '../../../atoms/SelectTeamMember';
import Documents from '../../JobsTable/statusIcons/Documents';
import Activation from '../../JobsTable/statusIcons/Activation';
import Notes from '../../JobsTable/statusIcons/Notes';
import Invoice from '../../JobsTable/statusIcons/Invoice';
import NameColumn from './NameColumn/index';
import { RecordTarget } from '..';
import '../styles.css';

const {
  MailOutlined,
  FileOutlined,
  FormOutlined,
  DollarOutlined,
} = Icons;

export const propertySortArray = (a: any, b: any, prop: string) => {
  if (!a[prop]) return -1;
  if (!b[prop]) return 1;
  if (a[prop] > b[prop]) return 1;
  if (a[prop] < b[prop]) return -1;
  return 0;
};

export const propertyNameSortArray = (a: any, b: any) => {
  if (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}`) return 1;
  if (`${a.firstName} ${a.lastName}` < `${b.firstName} ${b.lastName}`) return -1;
  return 0;
};

export const propertyPhoneSortArray = (a: any, b: any) => {
  if (a.phone?.value || b.phone?.value < '' || '') return 1;
  if (a.phone?.value || b.phone?.value > '' || '') return -1;
  return 0;
};

export const propertyTaskSortArray = (a: any, b: any) => {
  if (a.tasks?.find((task: any) => task.taskType === 'DOCUMENTS')?.completedAt || b.tasks?.find((task: any) => task.taskType === 'DOCUMENTS')?.completedAt
    < '' || '') return 1;
  if (a.tasks?.find((task: any) => task.taskType === 'DOCUMENTS')?.completedAt || b.tasks?.find((task: any) => task.taskType === 'DOCUMENTS')?.completedAt
    > '' || '') return -1;
  return 0;
};

const convertShortFormSortOrderToLongForm = (sortOrder: string) => {
  if (sortOrder === 'asc') return 'ascend';
  if (sortOrder === 'desc') return 'descend';
  return null;
};

const getClientList = (userRole: string) => {
  const savedSort: UserFirmAccountOrderByInput = JSON.parse(localStorage.getItem('clientsListSortOrder'));
  return [
    {
      key: 'firstName',
      title: 'First Name',
      dataIndex: '',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.Entity?.Users?.UserInformation?.firstName),
      render: ({
        phone,
        email,
        userId,
        entityId,
        firstName,
        languageId,
        workNumber,
        landlineNumber,
        userInformationId,
        taxYearInterviewId,
      }: RecordTarget) => (
        <NameColumn
          email={email}
          userId={userId}
          entityId={entityId}
          name={firstName}
          phone={phone?.value}
          languageId={languageId}
          workNumber={workNumber?.value}
          userInformationId={userInformationId}
          landlineNumber={landlineNumber?.value}
          taxYearInterviewId={taxYearInterviewId}
          allTaxYearInterview
        />
      ),
      width: 126,
      className: 'column-name-overflow-hidden',
    },
    {
      key: 'lastName',
      title: 'Last Name',
      dataIndex: '',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.Entity?.Users?.UserInformation?.lastName),
      render: ({
        phone,
        email,
        userId,
        entityId,
        lastName,
        languageId,
        workNumber,
        landlineNumber,
        userInformationId,
        taxYearInterviewId,
      }: RecordTarget) => (
        <NameColumn
          email={email}
          userId={userId}
          entityId={entityId}
          name={lastName}
          phone={phone?.value}
          languageId={languageId}
          workNumber={workNumber?.value}
          userInformationId={userInformationId}
          landlineNumber={landlineNumber?.value}
          taxYearInterviewId={taxYearInterviewId}
          allTaxYearInterview
        />
      ),
      width: 126,
      className: 'column-name-overflow-hidden',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.Entity?.Users?.email),
      key: 'email',
      width: 200,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      sorter: false,
      render: (record: PhonesEntity) => (
        <span>
          {record?.value && (
          <>
            {(getPhoneIcon(record))}
            {' '}
            {record?.value ?? ''}
          </>
          )}
        </span>
      ),
      key: 'phone',
      width: 200,
    },
    {
      title: 'Recent Tax Year',
      dataIndex: 'taxYear',
      sorter: false,
      key: 'taxYear',
      width: 115,
    },
    {
      title: 'Job Type',
      dataIndex: 'jobType',
      sorter: false,
      key: 'jobType',
      width: 140,
    },
    {
      title: () => <MailOutlined title="Activation Sent" className="column-icon" />,
      label: 'Envelop',
      dataIndex: 'invitationEmailSent',
      key: 'invitationEmailSent',
      sorter: false,
      // eslint-disable-next-line react/display-name
      render: (text: boolean, record: RecordTarget) => <Activation invitationEmailSent={text} password={record?.password} />,
      width: 55,
    },
    {
      title: <FileOutlined title="Documents" className="column-icon" />,
      label: 'Documents',
      key: 'files',
      sorter: false,
      // eslint-disable-next-line react/display-name
      render: (text, record: RecordTarget) => <Documents record={record} />,
      renderText: (docStatus: any) => {
        if (!docStatus) return 'Documents not found';
        return null;
      },
      width: 55,
    },
    {
      title: <FormOutlined title="Notes" className="column-icon" />,
      label: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      sorter: false,
      // eslint-disable-next-line react/display-name
      render: (text, record: RecordTarget) => <Notes record={record} />,
      renderText: (comments: { createdAt: string | number | Date }) => {
        if (!comments) return 'Notes not found';
        return moment(new Date(comments.createdAt)).format();
      },
      width: 55,
    },
    {
      title: <DollarOutlined title="Client Payment Received" className="column-icon" />,
      label: 'Client Payment Status',
      dataIndex: '',
      key: 'clientInvoice',
      sorter: false,
      render: (record: RecordTarget) => <Invoice record={record} />,
      // renderText: (data, row) => statusIndicators(row.taxYearStatus.clientInvoice, row, true),
      width: 55,
    },
    {
      title: 'Referred By',
      dataIndex: 'referredBy',
      sorter: false,
      key: 'referredBy',
      width: 150,
    },
    {
      title: 'Preparer',
      dataIndex: '',
      align: 'center',
      sorter: false,
      key: 'currentPreparer',
      // eslint-disable-next-line react/display-name
      render: ({ taxYearInterviewId, preparedId, progressStatusId }: RecordTarget) => (
        taxYearInterviewId
          ? (
            <SelectTeamMember
              type="Preparer"
              taxYearInterviewId={taxYearInterviewId}
              assignedMemberId={preparedId}
              progressStatusId={progressStatusId}
              userRole={userRole}
            />
          )
          : null
      ),
      width: 170,
    },
    {
      title: 'Reviewer',
      dataIndex: '',
      align: 'center',
      sorter: false,
      key: 'currentReviewer',
      // eslint-disable-next-line react/display-name
      render: ({ taxYearInterviewId, reviewedId, progressStatusId }: RecordTarget) => (
        taxYearInterviewId
          ? (
            <SelectTeamMember
              type="Reviewer"
              taxYearInterviewId={taxYearInterviewId}
              assignedMemberId={reviewedId}
              progressStatusId={progressStatusId}
              userRole={userRole}
            />
          )
          : null
      ),
      width: 170,
    },
  ];
};

export default getClientList;
