import addTeamembers from '../../assets/icons/addTeamembers.svg';
import connectCalendar from '../../assets/icons/connectCalendar.svg';
import connectingStripe from '../../assets/icons/connectingStripe.svg';
import customizeYourOwnWorkflow from '../../assets/icons/customizeYourOwnWorkflow.svg';
import doATestReturn from '../../assets/icons/doATestReturn.svg';
import importClients from '../../assets/icons/importClients.svg';
import scheduleOneWelcomeCall from '../../assets/icons/scheduleOneWelcomeCall.svg';
import sendEmai from '../../assets/icons/sendEmai.svg';
import setupBranding from '../../assets/icons/setupBranding.svg';
import testVideoCapabilityWithStaff from '../../assets/icons/testVideoCapabilityWithStaff.svg';
import idcardTwoTone from '../../assets/icons/idcardTwoTone.svg';
import userOutlined from '../../assets/icons/userOutlined.svg';
import userFilled from '../../assets/icons/userFilled.svg';

const Icon = {
  addTeamembers,
  connectCalendar,
  connectingStripe,
  customizeYourOwnWorkflow,
  doATestReturn,
  importClients,
  scheduleOneWelcomeCall,
  sendEmai,
  setupBranding,
  testVideoCapabilityWithStaff,
  idcardTwoTone,
  userOutlined,
  userFilled,
};

const iconOptionsKeys = {
  addTeamembers: true,
  connectCalendar: true,
  connectingStripe: true,
  customizeYourOwnWorkflow: true,
  doATestReturn: true,
  importClients: true,
  scheduleOneWelcomeCall: true,
  sendEmai: true,
  setupBranding: true,
  testVideoCapabilityWithStaff: true,
};

export { Icon, iconOptionsKeys };
// export defualt Icons;
