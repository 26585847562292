import React, { useContext } from 'react';
import {
  Modal, Typography, message,
} from 'taxaroo-ui';
import { JobContext } from '~src/components/providers/job-provider';
import { useDeleteTaxYearInterviewMutation } from '~src/graphql/mutations/settings';
import { GetClientsByProgressStatusIdDocument } from '~src/graphql/queries/settings';

const { Text } = Typography;

interface DeleteJobModalProps {
  visible: boolean;
  setDeleteJobModalVisible: (value: boolean) => void;
}

const DeleteJobModal: React.FC<DeleteJobModalProps> = ({
  visible,
  setDeleteJobModalVisible,
}) => {
  // context
  const jobContext = useContext(JobContext);
  const { taxYearInterviewId, progressStatusId } = jobContext;
  // graphql
  const [deleteTaxYearInterview, { loading: loadingDeleteJob }] = useDeleteTaxYearInterviewMutation({
    onError: (error) => {
      message.error(error.message);
    },
  });

  const handleDeleteJob = async () => {
    try {
      deleteTaxYearInterview({
        variables: {
          id: taxYearInterviewId,
        },
        refetchQueries: [
          'GetAllEntityDataByEntityId',
          {
            query: GetClientsByProgressStatusIdDocument,
            variables: {
              progressStatusId,
            },
          },
        ],
      }).then((res) => {
        if (res.data.DeleteJob.id) {
          message.success('Job deleted.');

          // if (taxYearInterviewId) {
          //   navigate('/');
          // }
        }
      });
    } catch (error) {
      message.error('Something went wrong while trying to delete the job.');
    } finally {
      setDeleteJobModalVisible(false);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Modal
        visible={visible}
        title="Are you sure?"
        onCancel={() => setDeleteJobModalVisible(false)}
        onOk={handleDeleteJob}
        okText="Delete Job"
        okType="primary"
        okButtonProps={{ danger: true, loading: loadingDeleteJob, disabled: loadingDeleteJob }}
      >
        <Text>You are about to permanently delete this job.</Text>
      </Modal>
    </div>
  );
};

export default DeleteJobModal;
