import { Icons } from 'taxaroo-ui';
import { PhonesEntity, PhoneType } from '~src/graphql';

const {
  MobileOutlined,
  BankOutlined,
  PhoneOutlined,
  HomeOutlined,
} = Icons;

const getPhoneIcon = (phone: PhonesEntity) => {
  if (!phone) {
    return undefined;
  }
  if (phone.type === PhoneType.Mobile) {
    return <MobileOutlined title="Mobile Number" />;
  }
  if (phone.type === PhoneType.Work) {
    return <PhoneOutlined title="Work Number" />;
  }
  if (phone.type === PhoneType.Landline) {
    return <HomeOutlined title="Landline Number" />;
  }

  return undefined;
};

export default getPhoneIcon;
