import React, { SVGProps } from 'react';

const HandleBar: React.FC<SVGProps<SVGSVGElement> & { title?: string }> = (props) => {
  const { title } = props;
  return (
    <svg
      viewBox="64 64 896 896"
      focusable="false"
      width="20"
      height="20"
      fill="currentColor"
      aria-hidden="true"
      {...props}
    >
      <path d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z">
        <title>{title}</title>
      </path>
    </svg>
  );
};

export default HandleBar;
