import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetJobsAndPaymentsByTaxYearStatusQueryVariables = Types.Exact<{
  progressStatusIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
  startDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
  dateFilterKey?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetJobsAndPaymentsByTaxYearStatusQuery = { __typename?: 'Query', JobsAndPaymentsByTaxYearStatus: Array<{ __typename?: 'TaxYearInterviewEntity', id: string, progressStatusId?: string | null, completedAt?: any | null, assignedAt: any, preparedId?: string | null, Payments?: Array<{ __typename?: 'PaymentsEntity', id: string, totalDollarAmount?: number | null }> | null }> };

export type GetFirmReviewsFilteredQueryVariables = Types.Exact<{
  startDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
  sort?: Types.InputMaybe<Types.Scalars['String']>;
  preparerId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetFirmReviewsFilteredQuery = { __typename?: 'Query', FirmReviewsFiltered: Array<{ __typename?: 'ReviewsEntity', id: string, reviewValue?: number | null, reviewContent?: string | null, createAt?: any | null, TaxYearInterview?: { __typename?: 'TaxYearInterviewEntity', id: string, TaxYear?: { __typename?: 'TaxYearEntity', id: string, year: number, Entity?: { __typename?: 'Entity', Users?: { __typename?: 'UserEntity', UserInformation?: { __typename?: 'UserInformationEntity', firstName: string, lastName: string } | null } | null } | null } | null, Interviews?: { __typename?: 'InterviewEntity', id: string, name: string } | null, PreparedUser?: { __typename?: 'PreparedUserEntity', id: string, UserInformation: { __typename?: 'UserInformationEntity', id: string, firstName: string, lastName: string } } | null } | null }> };

export type GetFirmsAverageRatingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetFirmsAverageRatingQuery = { __typename?: 'Query', firmsAverageRating: string };

export type GetPreparerAverageRatingQueryVariables = Types.Exact<{
  preparerId: Types.Scalars['String'];
}>;


export type GetPreparerAverageRatingQuery = { __typename?: 'Query', preparerAverageRating: { __typename?: 'AvgRatingEntity', id: string, preparerName: string, numberOfReviews: number, avgRating: string } };


export const GetJobsAndPaymentsByTaxYearStatusDocument = gql`
    query GetJobsAndPaymentsByTaxYearStatus($progressStatusIds: [String!]!, $startDate: DateTime, $endDate: DateTime, $dateFilterKey: String) {
  JobsAndPaymentsByTaxYearStatus(
    progressStatusIds: $progressStatusIds
    startDate: $startDate
    endDate: $endDate
    dateFilterKey: $dateFilterKey
  ) {
    id
    progressStatusId
    completedAt
    assignedAt
    preparedId
    Payments {
      id
      totalDollarAmount
    }
  }
}
    `;

/**
 * __useGetJobsAndPaymentsByTaxYearStatusQuery__
 *
 * To run a query within a React component, call `useGetJobsAndPaymentsByTaxYearStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsAndPaymentsByTaxYearStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsAndPaymentsByTaxYearStatusQuery({
 *   variables: {
 *      progressStatusIds: // value for 'progressStatusIds'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      dateFilterKey: // value for 'dateFilterKey'
 *   },
 * });
 */
export function useGetJobsAndPaymentsByTaxYearStatusQuery(baseOptions: Apollo.QueryHookOptions<GetJobsAndPaymentsByTaxYearStatusQuery, GetJobsAndPaymentsByTaxYearStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobsAndPaymentsByTaxYearStatusQuery, GetJobsAndPaymentsByTaxYearStatusQueryVariables>(GetJobsAndPaymentsByTaxYearStatusDocument, options);
      }
export function useGetJobsAndPaymentsByTaxYearStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobsAndPaymentsByTaxYearStatusQuery, GetJobsAndPaymentsByTaxYearStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobsAndPaymentsByTaxYearStatusQuery, GetJobsAndPaymentsByTaxYearStatusQueryVariables>(GetJobsAndPaymentsByTaxYearStatusDocument, options);
        }
export type GetJobsAndPaymentsByTaxYearStatusQueryHookResult = ReturnType<typeof useGetJobsAndPaymentsByTaxYearStatusQuery>;
export type GetJobsAndPaymentsByTaxYearStatusLazyQueryHookResult = ReturnType<typeof useGetJobsAndPaymentsByTaxYearStatusLazyQuery>;
export type GetJobsAndPaymentsByTaxYearStatusQueryResult = Apollo.QueryResult<GetJobsAndPaymentsByTaxYearStatusQuery, GetJobsAndPaymentsByTaxYearStatusQueryVariables>;
export const GetFirmReviewsFilteredDocument = gql`
    query getFirmReviewsFiltered($startDate: DateTime, $endDate: DateTime, $sort: String, $preparerId: String) {
  FirmReviewsFiltered(
    startDate: $startDate
    endDate: $endDate
    sort: $sort
    preparerId: $preparerId
  ) {
    id
    reviewValue
    reviewContent
    createAt
    TaxYearInterview {
      id
      TaxYear {
        id
        year
        Entity {
          Users {
            UserInformation {
              firstName
              lastName
            }
          }
        }
      }
      Interviews {
        id
        name
      }
      PreparedUser {
        id
        UserInformation {
          id
          firstName
          lastName
        }
      }
    }
  }
}
    `;

/**
 * __useGetFirmReviewsFilteredQuery__
 *
 * To run a query within a React component, call `useGetFirmReviewsFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirmReviewsFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirmReviewsFilteredQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      sort: // value for 'sort'
 *      preparerId: // value for 'preparerId'
 *   },
 * });
 */
export function useGetFirmReviewsFilteredQuery(baseOptions?: Apollo.QueryHookOptions<GetFirmReviewsFilteredQuery, GetFirmReviewsFilteredQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFirmReviewsFilteredQuery, GetFirmReviewsFilteredQueryVariables>(GetFirmReviewsFilteredDocument, options);
      }
export function useGetFirmReviewsFilteredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFirmReviewsFilteredQuery, GetFirmReviewsFilteredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFirmReviewsFilteredQuery, GetFirmReviewsFilteredQueryVariables>(GetFirmReviewsFilteredDocument, options);
        }
export type GetFirmReviewsFilteredQueryHookResult = ReturnType<typeof useGetFirmReviewsFilteredQuery>;
export type GetFirmReviewsFilteredLazyQueryHookResult = ReturnType<typeof useGetFirmReviewsFilteredLazyQuery>;
export type GetFirmReviewsFilteredQueryResult = Apollo.QueryResult<GetFirmReviewsFilteredQuery, GetFirmReviewsFilteredQueryVariables>;
export const GetFirmsAverageRatingDocument = gql`
    query GetFirmsAverageRating {
  firmsAverageRating
}
    `;

/**
 * __useGetFirmsAverageRatingQuery__
 *
 * To run a query within a React component, call `useGetFirmsAverageRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirmsAverageRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirmsAverageRatingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFirmsAverageRatingQuery(baseOptions?: Apollo.QueryHookOptions<GetFirmsAverageRatingQuery, GetFirmsAverageRatingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFirmsAverageRatingQuery, GetFirmsAverageRatingQueryVariables>(GetFirmsAverageRatingDocument, options);
      }
export function useGetFirmsAverageRatingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFirmsAverageRatingQuery, GetFirmsAverageRatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFirmsAverageRatingQuery, GetFirmsAverageRatingQueryVariables>(GetFirmsAverageRatingDocument, options);
        }
export type GetFirmsAverageRatingQueryHookResult = ReturnType<typeof useGetFirmsAverageRatingQuery>;
export type GetFirmsAverageRatingLazyQueryHookResult = ReturnType<typeof useGetFirmsAverageRatingLazyQuery>;
export type GetFirmsAverageRatingQueryResult = Apollo.QueryResult<GetFirmsAverageRatingQuery, GetFirmsAverageRatingQueryVariables>;
export const GetPreparerAverageRatingDocument = gql`
    query GetPreparerAverageRating($preparerId: String!) {
  preparerAverageRating(preparerId: $preparerId) {
    id
    preparerName
    numberOfReviews
    avgRating
  }
}
    `;

/**
 * __useGetPreparerAverageRatingQuery__
 *
 * To run a query within a React component, call `useGetPreparerAverageRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreparerAverageRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreparerAverageRatingQuery({
 *   variables: {
 *      preparerId: // value for 'preparerId'
 *   },
 * });
 */
export function useGetPreparerAverageRatingQuery(baseOptions: Apollo.QueryHookOptions<GetPreparerAverageRatingQuery, GetPreparerAverageRatingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPreparerAverageRatingQuery, GetPreparerAverageRatingQueryVariables>(GetPreparerAverageRatingDocument, options);
      }
export function useGetPreparerAverageRatingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPreparerAverageRatingQuery, GetPreparerAverageRatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPreparerAverageRatingQuery, GetPreparerAverageRatingQueryVariables>(GetPreparerAverageRatingDocument, options);
        }
export type GetPreparerAverageRatingQueryHookResult = ReturnType<typeof useGetPreparerAverageRatingQuery>;
export type GetPreparerAverageRatingLazyQueryHookResult = ReturnType<typeof useGetPreparerAverageRatingLazyQuery>;
export type GetPreparerAverageRatingQueryResult = Apollo.QueryResult<GetPreparerAverageRatingQuery, GetPreparerAverageRatingQueryVariables>;
export const namedOperations = {
  Query: {
    GetJobsAndPaymentsByTaxYearStatus: 'GetJobsAndPaymentsByTaxYearStatus',
    getFirmReviewsFiltered: 'getFirmReviewsFiltered',
    GetFirmsAverageRating: 'GetFirmsAverageRating',
    GetPreparerAverageRating: 'GetPreparerAverageRating'
  }
}