import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

/**
 * TODO -> use both languages until user language is available from database
 */

import en from './en';
import es from './es';

const lang = localStorage.getItem('lang') || 'en';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en,
      es,
    },
    fallbackLng: ['en', 'es'],
    lng: lang,
    keySeparator: false,
    interpolation: {
      escapeValue: true,
    },
  });

export default i18n;
