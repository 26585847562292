import { FC } from 'react';
import {
  Card,
  Form,
  Input,
  DatePicker,
  FormInstance,
} from 'taxaroo-ui';
import PhoneInputField from '~src/components/atoms/PhoneInputField';
import { KbaType } from '~src/graphql';

interface KbaFormInputsProps {
  kbaTypeValue: KbaType;
  index: number;
  form: FormInstance;
}

const KbaFormInputs: FC<KbaFormInputsProps> = ({
  kbaTypeValue,
  index,
  form,
}) => {
  if (kbaTypeValue === KbaType.Without) {
    return null;
  }

  if (kbaTypeValue === KbaType.Basic) {
    return (
      <Card
        style={{ boxShadow: 'none', border: '2px solid #f4f4f4' }}
        bodyStyle={{ paddingBottom: '0px' }}
      >
        <Form.Item
          rules={[{ required: true, message: 'Last name is required' }]}
          name={[`signerKBA[${index}]`, 'name']}
          extra="Last Name"
          style={{ maxWidth: 300 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: 'Last four of SSN is required' },
            { pattern: '[0-9]{4}', message: 'Must be 4 numbers' },
          ]}
          name={[`signerKBA[${index}]`, 'lastFourSSN']}
          extra="Last four SSN or EIN"
          style={{ maxWidth: 155 }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            form.setFields([{
              name: [`signerKBA[${index}]`, 'lastFourSSN'],
              value: e.target.value.replace(/[^0-9]/g, ''),
            }]);
          }}
        >
          <Input type="text" maxLength="4" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Date of birth is required' }]}
          name={[`signerKBA[${index}]`, 'dateOfBirth']}
          extra="Date of Birth"
        >
          <DatePicker />
        </Form.Item>
      </Card>
    );
  }

  if (kbaTypeValue === KbaType.Enhanced) {
    return (
      <Card
        style={{ boxShadow: 'none', border: '2px solid #f4f4f4' }}
        bodyStyle={{ paddingBottom: '0px' }}
      >
        <Form.Item
          rules={[{ required: true, message: 'Mobile phone number is required' }]}
          name={[`signerKBA[${index}]`, 'mobilePhone']}
          extra="Mobile Phone"
          style={{ maxWidth: 200 }}
        >
          <PhoneInputField />
        </Form.Item>
      </Card>
    );
  }

  return null;
};

export default KbaFormInputs;
