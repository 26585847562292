import { useEffect, createElement } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Row } from 'taxaroo-ui';
import config from '~src/components/helpers/config';
import Logo from '~src/assets/images/taxaroo_logo.svg';
import * as styles from './style.module.css';

const StripePricingPage = () => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // useEffect to hide the beacon
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    const hideBeacon = () => {
      const beacon = document.querySelector('.BeaconFabButtonFrame');
      if (beacon) {
        beacon.setAttribute('style', 'display: none !important');
        clearInterval(intervalId); // clear the interval once the beacon is found and hidden
      }
    };
    intervalId = setInterval(hideBeacon, 100); // poll every 100ms
    return () => clearInterval(intervalId); // cleanup on component unmount
  }, []);

  const renderStripePricingTable = () => createElement('stripe-pricing-table', {
    'pricing-table-id': id,
    'publishable-key': config.stripeAPIKey,
    'customer-email': email,
  });

  return (
    <div className={styles.page}>
      <Row justify="center" style={{ marginBottom: 70, marginLeft: -12 }}>
        <Logo width="250" />
      </Row>
      <div className={styles.pageWrapper}>
        <Row style={{ scale: '1.1' }}>
          {renderStripePricingTable()}
        </Row>
      </div>
    </div>
  );
};

export default StripePricingPage;
