import { FC, useContext, useState } from 'react';
import {
  Collapse, message, Row, Typography, Icons, Button,
} from 'taxaroo-ui';
import styled from 'styled-components';
import { JobContext } from '~src/components/providers/job-provider';
import { useUpdateJobNotesMutation } from '~src/graphql/mutations/settings';
import { useGetTaxYearInterviewIncludeInterviewsQuery } from '~src/graphql/queries/taxYearInterview';
import NotesPanel from '../../NotesPanel';
import ClientFiles from './ClientFiles';
import FirmFiles from './FirmFiles';
import Interview from './Interview';
import ClientStatusTracking from '../../ClientStatusTracking';
import './style.css';
import LegacyInterviewBackup from './LegacyInterviewBackup';

const { Panel } = Collapse;
const { Text } = Typography;
const { PrinterOutlined } = Icons;

function MyDivider() {
  return <div style={{ height: '20px' }} />;
}

const SubJobCollapse = styled(Collapse)`
  &.ant-collapse-borderless {
    background-color: white;
    border: 1px solid #d9d9d9;
  }
`;

interface JobProps {
  jobId: string;
  printInterview: (taxYearInterviewId: string) => void;
  jobName: string;
}

const Job: FC<JobProps> = ({ jobId, printInterview, jobName }) => {
  const [expandInterviewSections, setExpandInterviewSections] = useState<boolean>(false);
  const { notes, taxYearInterviewId } = useContext(JobContext);
  const [updateNotes, { loading }] = useUpdateJobNotesMutation();
  const { data, loading: interviewLoading, refetch: refetchInterviews } = useGetTaxYearInterviewIncludeInterviewsQuery({
    variables: {
      id: taxYearInterviewId,
    },
  });
  const { Answers, Interviews, LegacyTaxYearInterviewAnswers } = data?.GetTaxYearInterviewIncludeInterviews || {};
  const { Sections } = Interviews || {};

  const handleSaveNotes = (notesValue: string) => {
    try {
      updateNotes({
        variables: {
          id: taxYearInterviewId,
          notes: notesValue,
        },
        onCompleted: () => {
          message.success('The job notes have been successfully saved.');
        },
      });
    } catch (err) {
      message.error('Something went wrong while trying to update the job notes.');
    }
  };

  // action after preview closed
  window.addEventListener('afterprint', () => setExpandInterviewSections(false));

  return (
    <div className="project-container">
      {/* Job status tracking */}
      <div className="not-printable">
        <ClientStatusTracking jobId={jobId} />
      </div>
      {/* notes */}
      <div className="not-printable">
        <MyDivider />
        <SubJobCollapse bordered={false} defaultActiveKey={['preparerNotes']}>
          <Panel header="Job Specific Notes - Visible only to you and your team" key="preparerNotes">
            <NotesPanel
              initialNotes={notes}
              onSaveNotes={handleSaveNotes}
              loading={loading}
            />
          </Panel>
        </SubJobCollapse>
      </div>
      {/* Firm Files */}
      <div className="not-printable">
        <MyDivider />
        <SubJobCollapse bordered={false} defaultActiveKey={['firmFiles']}>
          <Panel header="Job Specific Firm Files - Visible only to you and your team" key="firmFiles">
            <FirmFiles jobName={jobName} />
          </Panel>
        </SubJobCollapse>
      </div>
      {/* Client Files */}
      <div className="not-printable">
        <MyDivider />
        <SubJobCollapse bordered={false} defaultActiveKey={['clientFiles']}>
          <Panel id={`account-client-interview-files-section-${jobId}`} header="Job Specific Client Files - Files uploaded by your client" key="clientFiles">
            <ClientFiles jobName={jobName} />
          </Panel>
        </SubJobCollapse>
      </div>
      {/* Client Interview */}
      <MyDivider />
      <SubJobCollapse bordered={false} defaultActiveKey={['clientInterview']}>
        <Panel
          id={`account-client-interview-section-${jobId}`}
          header={(
            <Row justify="space-between">
              <Text>Job Specific Client Interview</Text>
              <Button
                type="link"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setExpandInterviewSections(true);
                  printInterview(jobId);
                }}
              >
                <PrinterOutlined />
                Print
              </Button>
            </Row>
          )}
          key="clientInterview"
        >
          <Interview
            loading={interviewLoading}
            sections={Sections}
            answers={Answers}
            interview={Interviews}
            refetchInterviews={refetchInterviews}
            expandInterviewSections={expandInterviewSections}
          />
        </Panel>
      </SubJobCollapse>
      {/* Legacy interview Answers */}
      {Boolean(LegacyTaxYearInterviewAnswers?.length) && (
        <div className="not-printable">
          <MyDivider />
          <Collapse bordered={false} defaultActiveKey={['legacyInterviewAnswers']}>
            <Panel id={`account-client-interview-legacy-answers-${jobId}`} header="Job Specific Legacy Interview" key="legacyInterviewAnswers">
              <LegacyInterviewBackup answers={LegacyTaxYearInterviewAnswers} />
            </Panel>
          </Collapse>
        </div>
      )}
    </div>
  );
};

export default Job;
