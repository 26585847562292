import React, { FC, ReactNode } from 'react';
import { Card } from 'taxaroo-ui';
import { useAppSelector } from '~src/redux/hooks';
import { selectRegisterState } from '~src/redux/slices/registerSlice';
import * as styles from './styles.module.css';

interface CardProps {
  id: string;
  title: ReactNode;
  content: ReactNode;
  onClick: () => void;
}

interface CardListProps {
  cards: Array<CardProps>;
}

const CardList: FC<CardListProps> = ({ cards }) => {
  const { selectedPlanId } = useAppSelector(selectRegisterState);

  return (
    <ul className={styles.cardListContainer}>
      {cards.map(({
        id, title, content, onClick,
      }) => (
        <Card
          key={id}
          hoverable
          title={title}
          onClick={onClick}
          style={{
            transition: 'border 300ms ease',
            ...(selectedPlanId && selectedPlanId === id ? {
              border: 'solid 1px #0D76D1',
            } : {}),
          }}
          className={styles.cardItem}
        >
          {content}
        </Card>
      ))}
    </ul>
  );
};

export default CardList;
