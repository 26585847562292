import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserStepMutationVariables = Types.Exact<{
  updateUserStepInput: Types.UpdateUserStepInput;
}>;


export type UpdateUserStepMutation = { __typename?: 'Mutation', updateUserStep: { __typename?: 'UserStepEntity', stepId: string, userId: string, completed: boolean } };

export type SendWelcomeEmailToAllClientsMutationVariables = Types.Exact<{
  sendWelcomeEmailInput: Types.SendWelcomeEmailInput;
}>;


export type SendWelcomeEmailToAllClientsMutation = { __typename?: 'Mutation', sendWelcomeEmailToAllClients: string };


export const UpdateUserStepDocument = gql`
    mutation UpdateUserStep($updateUserStepInput: UpdateUserStepInput!) {
  updateUserStep(updateUserStepInput: $updateUserStepInput) {
    stepId
    userId
    completed
  }
}
    `;
export type UpdateUserStepMutationFn = Apollo.MutationFunction<UpdateUserStepMutation, UpdateUserStepMutationVariables>;

/**
 * __useUpdateUserStepMutation__
 *
 * To run a mutation, you first call `useUpdateUserStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserStepMutation, { data, loading, error }] = useUpdateUserStepMutation({
 *   variables: {
 *      updateUserStepInput: // value for 'updateUserStepInput'
 *   },
 * });
 */
export function useUpdateUserStepMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserStepMutation, UpdateUserStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserStepMutation, UpdateUserStepMutationVariables>(UpdateUserStepDocument, options);
      }
export type UpdateUserStepMutationHookResult = ReturnType<typeof useUpdateUserStepMutation>;
export type UpdateUserStepMutationResult = Apollo.MutationResult<UpdateUserStepMutation>;
export type UpdateUserStepMutationOptions = Apollo.BaseMutationOptions<UpdateUserStepMutation, UpdateUserStepMutationVariables>;
export const SendWelcomeEmailToAllClientsDocument = gql`
    mutation SendWelcomeEmailToAllClients($sendWelcomeEmailInput: SendWelcomeEmailInput!) {
  sendWelcomeEmailToAllClients(sendWelcomeEmailInput: $sendWelcomeEmailInput)
}
    `;
export type SendWelcomeEmailToAllClientsMutationFn = Apollo.MutationFunction<SendWelcomeEmailToAllClientsMutation, SendWelcomeEmailToAllClientsMutationVariables>;

/**
 * __useSendWelcomeEmailToAllClientsMutation__
 *
 * To run a mutation, you first call `useSendWelcomeEmailToAllClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendWelcomeEmailToAllClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendWelcomeEmailToAllClientsMutation, { data, loading, error }] = useSendWelcomeEmailToAllClientsMutation({
 *   variables: {
 *      sendWelcomeEmailInput: // value for 'sendWelcomeEmailInput'
 *   },
 * });
 */
export function useSendWelcomeEmailToAllClientsMutation(baseOptions?: Apollo.MutationHookOptions<SendWelcomeEmailToAllClientsMutation, SendWelcomeEmailToAllClientsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendWelcomeEmailToAllClientsMutation, SendWelcomeEmailToAllClientsMutationVariables>(SendWelcomeEmailToAllClientsDocument, options);
      }
export type SendWelcomeEmailToAllClientsMutationHookResult = ReturnType<typeof useSendWelcomeEmailToAllClientsMutation>;
export type SendWelcomeEmailToAllClientsMutationResult = Apollo.MutationResult<SendWelcomeEmailToAllClientsMutation>;
export type SendWelcomeEmailToAllClientsMutationOptions = Apollo.BaseMutationOptions<SendWelcomeEmailToAllClientsMutation, SendWelcomeEmailToAllClientsMutationVariables>;
export const namedOperations = {
  Mutation: {
    UpdateUserStep: 'UpdateUserStep',
    SendWelcomeEmailToAllClients: 'SendWelcomeEmailToAllClients'
  }
}