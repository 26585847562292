/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import {
  Form,
  Avatar,
  List,
  Button,
  Popconfirm,
  Input,
  Row,
  Col,
  Icons,
  Tooltip,
  message,
  Space,
} from 'taxaroo-ui';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './style.css';
import { ButtonsEntity, UserStepEntity } from '~src/graphql';
import { useSendWelcomeEmailToAllClientsMutation } from '~src/graphql/mutations/getStarted';
import { useAppSelector } from '~src/redux/hooks';
import { InfoCircleOutlined } from 'taxaroo-ui/src/Icons';
import { Icon } from '../../../../components/helpers/icons';
import * as styles from './style.module.css';

const { CheckCircleTwoTone, CheckCircleFilled } = Icons;
const { TextArea } = Input;

const initialBody = `Dear Client,

First and foremost, we would like to express our gratitude for your continued trust in us. As part of our commitment to providing you with the best possible service, we are excited to announce the launch of our new secure, user-friendly client portal.

The first step in getting access is that you will receive an email containing a link to activate your new secure client portal. We encourage you to complete this process promptly. Here are some of the key benefits you can look forward to:

1. Secure Document Portal: We safeguard your sensitive information with bank-grade 256-bit AES encryption. Share and access your documents with us with confidence.

2. Appointment Scheduling: Schedule your appointments with us at your convenience directly through your portal.

3. Messaging: Send us quick updates, ask us questions and even have video calls with us right within the portal.

4. Mobile Access: Our mobile-friendly portal allows you to upload documents right from the camera on your phone.

5. Payments: Conveniently pay your invoices through the portal using credit/debit cards or ACH.

6. Electronic Signatures: Save time by signing your documents electronically right in the portal, ensuring a fast and seamless process.

With all these features in one place and a single login, working with us has never been easier. 

Please don't hesitate to reach out to us with any questions you may have.

Warm regards,

[Your Name], [Your Title] 
[Firm Name]
[Phone Number]
[Email Address]
`;

interface OnBoardingListProps {
  userSteps: UserStepEntity[];
  updateUserStepStatus: (userId: string, stepId: string, completed: boolean) => void;
  setShowRegisterFlow: (show: boolean) => void;
}

function OnBoardingList({
  userSteps,
  updateUserStepStatus,
  setShowRegisterFlow,
}: OnBoardingListProps) {
  const [editEmail, setEditEmail] = useState(false);
  const [editEmailIndex, setEditEmailIndex] = useState(null);
  const [sendEmailButtonIndex, setSendEmailButtonIndex] = useState(null);
  const [editEmailButtonIndex, setEditEmailButtonIndex] = useState(null);

  const { firmAccountId } = useAppSelector((state) => state.session);

  // useEffect to find index of email step
  useEffect(() => {
    setEditEmailIndex(userSteps.findIndex((userStep: UserStepEntity) => userStep?.Steps?.name === 'templated-email'));
  }, [userSteps, setEditEmailIndex]);

  // useEffect to find index of send email button
  useEffect(() => {
    if (editEmailIndex) {
      setSendEmailButtonIndex(userSteps[editEmailIndex]?.Steps?.Buttons?.findIndex((button: ButtonsEntity) => button.action === 'handleSendEmail'));
      setEditEmailButtonIndex(userSteps[editEmailIndex]?.Steps?.Buttons?.findIndex((button: ButtonsEntity) => button.action === 'handleEditEmail'));
    }
  }, [editEmailIndex, userSteps, setEditEmailIndex, setEditEmailButtonIndex]);

  // graphql
  // send welcome emails mutation
  const [sendWelcomeEmails, { loading: loadingSendWelcomeEmails }] = useSendWelcomeEmailToAllClientsMutation({
    onCompleted: (data) => {
      message.success(data?.sendWelcomeEmailToAllClients, 10);
      updateUserStepStatus(userSteps[editEmailIndex].userId, userSteps[editEmailIndex].stepId, true);
    },
    onError: (error) => {
      message.error(error.toString().replace('Error: ', ''));
    },
  });

  const formik = useFormik({
    validationSchema: yup.object().shape({
      subject: yup.string().required('Subject is required.'),
      body: yup.string().required('Body is required.'),
    }),
    initialValues: {
      subject: 'Exciting Update: New Secure Client Portal and Enhanced Communication Tools',
      body: initialBody,
    },
    onSubmit: async (values) => {
      if (values.body === initialBody) {
        message.error('Please edit the email body before sending by adding your contact information at the end of the email.', 8);
        return;
      }
      // send welcome emails
      await sendWelcomeEmails({
        variables: {
          sendWelcomeEmailInput: {
            firmAccountId,
            subject: values.subject,
            body: values.body,
          },
        },
      });
    },
  });

  const {
    values, errors, handleSubmit, handleChange, validateForm,
  } = formik;
  const execAction = async (
    ev: any,
    stepId: string,
    userId: string,
    button: ButtonsEntity,
  ) => {
    if (button.link) {
      window.open(button.link, '_blank');
      // mark as completed
      // updateUserStepStatus(userId, stepId, true); // moved to manual only
      return;
    }
    switch (button.action) {
      case 'handleBasicInfo': {
        setShowRegisterFlow(true);
        break;
      }
      case 'handleSendEmail': {
        ev.preventDefault();
        // if already completed return and do nothing
        if (userSteps[editEmailIndex].completed) {
          message.info('This step is already complete. Click the green check mark, to mark this step incomplete and send again.', 8);
          return;
        }
        const formErrors = await validateForm();
        if (Object.keys(formErrors)?.length === 0) { // no errors
          handleSubmit();
        } else { // errors
          setEditEmail(true); // open up edit section if not already open
          Object.keys(formErrors).forEach((key) => {
            message.error(formErrors[key]);
          });
        }
        break;
      }
      case 'handleEditEmail': {
        setEditEmail((prev) => !prev);
        break;
      }
      default:
        break;
    }
  };

  const popconfirmButton = (button: ButtonsEntity, stepId: string, userId: string) => (
    <Popconfirm
      title={button.popConfirm}
      okText="Yes"
      cancelText="No"
      onConfirm={(ev) => execAction(
        ev,
        stepId,
        userId,
        button,
      )}
      okButtonProps={{ danger: true }}
      cancelButtonProps={{ type: 'default' }}
    >
      <Button
        type={(button.action === 'handleSendEmail' && loadingSendWelcomeEmails) ? 'primary' : 'default'}
        disabled={(button.action === 'handleSendEmail' && loadingSendWelcomeEmails)}
        className={styles.btn}
        loading={button.action === 'handleSendEmail' && loadingSendWelcomeEmails}
      >
        {button.label}
      </Button>
    </Popconfirm>
  );

  const normalButton = (button: ButtonsEntity, stepId: string, userId: string, completed: boolean) => (
    <Button
      type="default"
      className={!completed ? styles.btn : styles.btnComplete}
      onClick={(ev: React.MouseEvent<HTMLElement>) => execAction(
        ev,
        stepId,
        userId,
        button,
      )}
      // disabled={completed}
    >
      {button.label}
    </Button>
  );

  return (
    <>
      {/* {title && <p className={styles.titleList}>{title}</p>} */}
      <div className={styles.onBoardingListContainer}>
        <List
          className="on-boarding-list"
          // loading={loading}
          itemLayout="horizontal"
          dataSource={userSteps}
          renderItem={({ userId, completed, Steps: step }: UserStepEntity) => (
            <List.Item style={{ alignItems: 'flex-start' }}>
              {/* <Skeleton avatar title={false} loading={loading} active> */}
              <List.Item.Meta
                avatar={(
                  <div className={styles.leftIcons}>
                    {completed ? (
                      <Tooltip
                        title={completed ? 'Mark as not complete' : 'Mark as complete'}
                      >
                        <CheckCircleTwoTone
                          style={{ fontSize: 20 }}
                          twoToneColor="#52c41a"
                          onClick={() => updateUserStepStatus(userId, step?.id, false)}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={completed ? 'Mark as not complete' : 'Mark as complete'}
                      >
                        <CheckCircleFilled
                          style={{ fontSize: 20, color: '#e9eced' }}
                          onClick={() => updateUserStepStatus(userId, step.id, true)}
                        />
                      </Tooltip>
                    )}
                    {step?.icon && (
                    <Avatar
                      style={{ background: 'white', marginLeft: '0px' }}
                      shape="square"
                      src={Icon[step.icon]}
                      size={30}
                    />
                    )}
                  </div>
                    )}
                title={(
                  <>
                    {step?.description}
                    {step?.tooltip && (
                      <Tooltip
                        title={step.tooltip}
                      >
                        <InfoCircleOutlined
                          style={{
                            fontSize: 10,
                            position: 'relative',
                            top: '-0.5em',
                            left: '0.4em',
                            cursor: 'pointer',
                          }}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              />
              {/* buttons */}
              <Row gutter={[7, 0]}>
                {step?.Buttons.map((button) => (
                  <Col>
                    {/* this item has popconfirm */}
                    {!!button.popConfirm && (
                    <>
                      {!completed && <>{popconfirmButton(button, step?.id, userId)}</>}
                      {completed && <>{normalButton(button, step?.id, userId, completed)}</>}
                    </>
                    )}
                    {/* this item doesn't have popconfirm */}
                    {!button.popConfirm && <>{normalButton(button, step?.id, userId, completed)}</>}
                  </Col>
                ))}
              </Row>
              {/* </Skeleton> */}
            </List.Item>
          )}
        />
        {' '}
        {/* end list */}
        {editEmail && (
          <List.Item style={{ flexDirection: 'column' }}>
            <Form
              layout="vertical"
              labelAlign="left"
              style={{ width: '100%' }}
            >
              <label htmlFor="subject">
                Subject:
                {' '}
              </label>
              <div className={styles.rowEmail}>
                <Input
                  name="subject"
                  // style={{ flex: 1 }}
                  value={values.subject}
                  onChange={handleChange}
                  className={errors.subject ? styles.hasError : ''}
                />
              </div>
              <br />
              <label htmlFor="body">
                Body:
                {' '}
              </label>
              <div className={styles.rowEmail}>
                <TextArea
                  name="body"
                  style={{ flex: 1 }}
                  value={values.body}
                  onChange={handleChange}
                  rows={20}
                  className={errors.body ? styles.hasError : ''}
                />
              </div>
            </Form>
            <div className={styles.secondSendButton}>
              {/* this item has popconfirm */}
              {(editEmailIndex !== null && sendEmailButtonIndex !== null && !!userSteps[editEmailIndex]?.Steps?.Buttons[sendEmailButtonIndex]?.popConfirm) ? (
                <Space>
                  {/* The stop editing button */}
                  {normalButton(
                    userSteps[editEmailIndex]?.Steps?.Buttons[editEmailButtonIndex],
                    userSteps[editEmailIndex]?.userId,
                    userSteps[editEmailIndex]?.Steps.id,
                    userSteps[editEmailIndex]?.completed,
                  )}
                  {!userSteps[editEmailIndex]?.completed && (
                  <>
                    {popconfirmButton(
                      userSteps[editEmailIndex]?.Steps?.Buttons[sendEmailButtonIndex],
                      userSteps[editEmailIndex]?.Steps?.id,
                      userSteps[editEmailIndex]?.userId,
                    )}
                  </>
                  )}
                  {userSteps[editEmailIndex]?.completed && (
                  <>
                    {normalButton(
                      userSteps[editEmailIndex]?.Steps?.Buttons[sendEmailButtonIndex],
                      userSteps[editEmailIndex]?.userId,
                      userSteps[editEmailIndex]?.Steps.id,
                      userSteps[editEmailIndex]?.completed,
                    )}
                  </>
                  )}
                </Space>
              ) : null}
              {/* this step doesn't have popconfirm */}
              {(editEmailIndex !== null && sendEmailButtonIndex !== null && !userSteps[editEmailIndex]?.Steps?.Buttons[sendEmailButtonIndex]?.popConfirm) ? (
                <>
                  {normalButton(
                    userSteps[editEmailIndex]?.Steps.Buttons[sendEmailButtonIndex],
                    userSteps[editEmailIndex]?.Steps.id,
                    userSteps[editEmailIndex]?.userId,
                    userSteps[editEmailIndex]?.completed,
                  )}
                </>
              ) : null}
            </div>
          </List.Item>
        )}
      </div>
    </>
  );
}

// OnBoardingList.defaultProps = defaultProps;

export default OnBoardingList;
