import React, { FC, useRef } from 'react';
import { Input, Button } from 'taxaroo-ui';
import useDebouncedEffect from '~src/components/hooks/useDebouncedEffect';
import { useGetUserChannelsLazyQuery } from '~src/graphql/queries/messaging';
import { useAppDispatch } from '~src/redux/hooks';
import { setChannels, setSelectedChannel } from '~src/redux/slices/chatSlice';
import * as styles from './style.module.css';

const { Search } = Input;
// const {Title} = Typography;

interface ChatSelectorHeaderProps {
    showModal: () => void;
    setChannelSearch: any
    channelSearch: string
}

const ChatSelectorHeader: FC<ChatSelectorHeaderProps> = ({
  showModal, setChannelSearch, channelSearch,
}) => {
  const dispatch = useAppDispatch();
  const firstRender = useRef(true);
  const [getChannels, { loading: channelsLoading }] = useGetUserChannelsLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const handleNewConversationClick = () => {
    showModal();
  };

  useDebouncedEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      getChannels({
        variables: {
          searchWord: channelSearch,
        },
      }).then(({ data, error }) => {
        if (error) {
          console.warn('Error on getChannels.', error);
          return;
        }

        const { userChannels } = data;
        dispatch(setSelectedChannel(undefined));
        dispatch(setChannels(userChannels));
      });
    }
  }, 500, [channelSearch]);

  return (
    <div className={styles.chatSelectorContainer}>
      <Button style={{ fontSize: '12px' }} className={styles.addbtn} onClick={handleNewConversationClick} type="link">
        + New Conversation
      </Button>
      <Search
        value={channelSearch}
        className={styles.input}
        placeholder="Search"
        onChange={(value) => {
          setChannelSearch(value.currentTarget.value);
        }}
        loading={channelsLoading}
        autoComplete="off"
        type="search"
      />
    </div>
  );
};
export default ChatSelectorHeader;
