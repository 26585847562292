.channelChatContainer {
  width: 100%;
  display: flex;
  padding-left: 16px;
  padding-top: 10px;
  align-items: center;
  flex-direction: row;
  padding-bottom: 10px;
  border-bottom: 1px solid #f2f2f2;
  transition: background-color, opacity 300ms ease-in-out;
}

.channelChatContainer:hover {
  cursor: pointer;
  background-color: #d3eaff;
}

.containerSelect {
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #d3eaff;
  transition: background-color opacity 300ms ease-in-out;
}

.containerData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
}

.containeLastSeen {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: auto;
  margin-right: 15px;
}

.badge {
  margin-left: 10px;
}

.badge sup {
  margin-top: 37px;
  margin-right: 10px;
  width: 7px;
  height: 7px;
}

.name {
  font-size: 12px;
  font-weight: bold;
}

.message {
  font-size: 12px;
}

.date {
  font-size: 10px;
  font-weight: lighter;
}

.count {
  margin: 5px 10px auto auto;
}
