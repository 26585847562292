import {
  useEffect, useRef, FC, useState,
} from 'react';
import {
  Spin, Row, Button,
} from 'taxaroo-ui';
import ReactPullToRefresh from 'react-pull-to-refresh';
import { useTranslation } from 'react-i18next';
import { MessageEntity } from '~src/graphql';
import { useAppSelector } from '~src/redux/hooks';
import { selectSession } from '~src/redux/slices/sessionSlice';
import useChannels from '~src/components/hooks/useChannels';
import * as styles from './style.module.css';
import MessageAdmin from '../../molecules/MessageAdmin';
import MissedCall from '../../atoms/MissedCall';
import './style.css';

const LoadingIndicator = () => (
  <Row justify="center">
    <Spin />
  </Row>
);

interface MessagesListProps {
  channelId: string;
  data: Array<MessageEntity>;
}

const MessagesList: FC<MessagesListProps> = ({ channelId, data }) => {
  const { userId } = useAppSelector(selectSession);
  const { t: translation } = useTranslation();
  const messagesListContainerRef = useRef<HTMLDivElement>(null);
  const messagesEndRef = useRef<any>(null);
  const { getMoreMessages, loadingMoreMessages, currentChannel } = useChannels(false);
  const [lastMessage, setLastMessage] = useState<MessageEntity | null>(null);

  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, 200);
  };

  const loadMoreData = async () => {
    if (loadingMoreMessages) {
      return;
    }
    // Record the current scroll position and the height of the messages container before loading new messages
    const currentScrollPosition = messagesListContainerRef.current?.scrollTop ?? 0;
    const currentContainerHeight = messagesListContainerRef.current?.scrollHeight ?? 0;

    await getMoreMessages(channelId, 10);

    // After new messages have been loaded and rendered, adjust the scroll position
    requestAnimationFrame(() => {
      const newContainerHeight = messagesListContainerRef.current?.scrollHeight ?? 0;
      const heightDifference = newContainerHeight - currentContainerHeight;
      if (messagesListContainerRef.current) {
        messagesListContainerRef.current.scrollTop = currentScrollPosition + heightDifference;
      }
    });
  };

  const getInitialMessagesAndScroll = async () => {
    await loadMoreData();
    scrollToBottom();
  };

  useEffect(() => {
    scrollToBottom();
    if (data.length < 10) {
      getInitialMessagesAndScroll();
    }
  }, [currentChannel.id]);

  // if data increments up by one, scroll to bottom (most likly a new message came in or a message was sent)
  useEffect(() => {
    if (data && data?.length > 0) {
      // if the previous last message is now the second to last message, scroll to bottom
      if (data[data.length - 2]?.id === lastMessage?.id) {
        scrollToBottom();
      }
      setLastMessage(data[data.length - 1]);
    }
  }, [data?.length]);

  return (
    <div className={styles.messagesListContainer} ref={messagesListContainerRef}>
      <ReactPullToRefresh
        loading={loadingMoreMessages}
        onRefresh={loadMoreData}
        style={{ textAlign: 'center' }}
        icon={<LoadingIndicator />}
      >
        {loadingMoreMessages
          ? (
            <LoadingIndicator />
          )
          : (
            <Button
              className={styles.loadMoreMessagesBanner}
              onClick={loadMoreData}
            >
              <span>
                Load more messages
              </span>
            </Button>
          )}
        {data?.map((item: MessageEntity, index) => {
          const { firstName, lastName } = item.Users?.UserInformation ?? { firstName: '', lastName: '' };

          return (
            <MessageAdmin
              key={`message-${item.id}`}
              message={item.text}
              readDate={item.readDate}
              attachment={item.attachment}
              mine={userId === item.userId}
              last={data.length === index + 1}
              name={`${firstName} ${lastName}`}
              createdAt={new Date(item.createAt)}
              isSms={item.isSms}
            />
          );
        })}
        <div ref={messagesEndRef} />
      </ReactPullToRefresh>
    </div>
  );
};

export default MessagesList;
