.container {
  background-color: #f4f6f9;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
}

.title {
  color: #0a64b2;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0.5em;
  text-align: center;
}

.containerStep1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.containerStep1 button {
  border: 1px solid #22a7e7;
  width: 80%;
  background-color: transparent;
  color: #22a7e7;
  margin: 10px;
}

.containerStep2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerStep2 button {
  border-radius: 2px;
  background-color: transparent;
  color: #fff;
  margin: 15px;
}

.textArea {
  border-radius: 0.5rem;
  border-color: #aeaeae;
  background: none;
  width: 80%;
}

.skip {
  cursor: pointer;
  margin: 0.5em 0;
  color: #22a7e7;
  font-size: 16px;
  font-weight: 500;
}

.skipDisabled {
  margin: 0.5em 0;
  color: rgb(0 0 0 / 25%);
  background-color: #f5f5f5;
  cursor: not-allowed;
  font-size: 16px;
  font-weight: 500;
}

.containerStep3 {
  display: flex;
  flex-direction: column;
}

.containerStep4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}

.containerStep5 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerStep5 button {
  border-radius: 2px;
  color: #fff;
  margin: 5px;
  margin-bottom: 15px;
}

.error svg {
  fill: #c41a1a !important;
  width: 60px !important;
  height: 60px !important;
  margin-top: 14px !important;
}

.success svg {
  fill: #52c41a !important;
  width: 60px !important;
  height: 60px !important;
  margin-top: 14px !important;
}

.successtxt {
  color: #565656 !important;
  font-size: 14px !important;
  font-weight: 500px !important;
  margin-top: 8px !important;
}

.contLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.contLabel p {
  color: #565656;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  margin-left: 10px;
}

.contLabel svg {
  fill: #565656;
  width: 16px;
  height: 16px;
}

.left {
  width: 16px;
  height: 16px;
  fill: #565656;
  position: absolute;
  top: 15px;
  left: 15px;
}

.right {
  width: 16px;
  height: 16px;
  fill: #565656;
  position: absolute;
  top: 15px;
  right: 15px;
}
