.cc_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 330px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 4px;
}

.cc_container:hover {
  cursor: pointer;
  background-color: #e9eef5;
}

.cc_selected {
  background-color: #dfe9f7;
}

.cc_selected:hover {
  background-color: #dfe9f7 !important;
}

.cc_containerData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
}

.containerBadge {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
  margin-right: 20px;
}

.cc_badge {
  margin-left: 10px;
}

.cc_badge sup {
  margin-top: 47px;
  margin-right: 3px;
  width: 10px;
  height: 10px;
}

.cc_name {
  font-size: 14px;
  font-weight: 600;
  color: #0a64b2;
}

.cc_message {
  font-size: 12px;
  color: #565656;
}

.cc_date {
  font-size: 10px;
  color: #969696;
  position: absolute;
  right: 15px;
  margin-top: 4px;
}

.cc_count {
  margin-bottom: auto;
  font-size: 10px;
  font-weight: bold;
  width: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  height: 18px;
  background-color: #f00;
  border-radius: 100px;
  position: absolute;
  right: 15px;
}

.cc_last {
  display: flex;
}

/* :local(.containers) {
  background-color: #0a64b2;
} */
