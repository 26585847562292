import {
  useState, useContext, FC,
} from 'react';
import {
  Typography,
  Collapse,
  message,
  Icons,
  Row,
  Col,
  Button,
  Skeleton,
} from 'taxaroo-ui';
import styled from 'styled-components';
import JobExtra from '~src/components/molecules/JobExtra';
import { GetClientsByProgressStatusIdDocument } from '~src/graphql/queries/settings';
import { useUpdateTaxYearInterviewProgressStatusMutation } from '~src/graphql/mutations/entity';
import { JobProvider } from '~src/components/providers/job-provider';
import { TaxPayerContext } from '~src/components/providers/taxpayer-provider';
import { InterviewEntity, TaxYearInterviewEntity } from '../../../../graphql';
import Job from './Job';
import AddJobModal from '../../../molecules/AddJobModal';

const { PlusOutlined } = Icons;
const { Title } = Typography;
const { Panel } = Collapse;

const JobPanel = styled(Panel)`
  .ant-collapse-content {
    background-color: #fafafa;
  }
`;

interface JobsProps {
  interviews: Array<InterviewEntity>;
  printInterview: (taxYearInterviewId: string) => void;
  printableJob: string;
}

const Jobs: FC<JobsProps> = ({ interviews, printInterview, printableJob }) => {
  const taxPayerContext = useContext(TaxPayerContext);
  const {
    loading,
    entityId,
    ownerId,
    jobs,
    urlTaxYearInterviewId,
    firstName,
    lastName,
    email,
    phone: phoneNumber,
    birthday,
  } = taxPayerContext;
  const [addJobModalVisible, setAddJobModalVisible] = useState(false);
  // graphql
  const [updateTaxYearInterviewProgressStatus, { loading: loadingUpdateTaxYearInterviewProgressStatus }] = useUpdateTaxYearInterviewProgressStatusMutation({
    onCompleted: () => {
      message.success('Job status updated successfully!');
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const onStatusChange = (progressStatusId: string, prevProgressStatusId: string, jobId: string) => {
    try {
      updateTaxYearInterviewProgressStatus({
        variables: {
          id: jobId,
          progressStatusId,
        },
        refetchQueries: [
          {
            query: GetClientsByProgressStatusIdDocument,
            variables: {
              progressStatusId,
            },
          },
          {
            query: GetClientsByProgressStatusIdDocument,
            variables: {
              progressStatusId: prevProgressStatusId,
            },
          },
        ],
      });
    } catch (error) {
      message.error('Something went wrong while trying to update the job status');
    }
  };

  return (
    <>
      <Row gutter={12}>
        <Col>
          <Title level={3}>
            Job
            {(urlTaxYearInterviewId === '') ? 's' : ''}
          </Title>
        </Col>
        <Col>
          {(urlTaxYearInterviewId === '')
            && (
            <Button
              type="primary"
              size="small"
              shape="round"
              icon={<PlusOutlined />}
              iconDirection="left"
              style={{ marginTop: 6 }}
              disabled={(!entityId)
                || (!email) || (!firstName) || (!lastName) || (!firstName && !lastName)}
              onClick={() => setAddJobModalVisible(true)}
            >
              Add Job
            </Button>
            )}
        </Col>
      </Row>
      <div>
        {loading && (
          <Skeleton active />
        )}
        {!loading && jobs && (jobs.map((taxYearInterview: TaxYearInterviewEntity, index, array) => (
          <JobProvider
            key={taxYearInterview.id}
            entityId={entityId}
            entityOwnerId={ownerId}
            taxYearInterviewId={taxYearInterview.id}
            progressStatusId={taxYearInterview.progressStatusId}
            completedAt={taxYearInterview.completedAt}
            filesCompletedAt={taxYearInterview?.JobTracking?.filesCompletedAt}
            expectedCompletion={taxYearInterview?.JobTracking?.expectedCompletion}
            paymentsCompletedAt={taxYearInterview?.JobTracking?.paymentsCompletedAt}
            approvalsCompletedAt={taxYearInterview?.JobTracking?.approvalsCompletedAt}
            interviewCompletedAt={taxYearInterview?.JobTracking?.interviewCompletedAt}
            notes={taxYearInterview.notes}
            tasks={taxYearInterview.Tasks}
            clientFirstName={firstName}
            clientLastName={lastName}
            clientEmail={email}
            clientMobilePhone={phoneNumber}
            clientBirthday={birthday}
          >
            <div className={printableJob && printableJob !== taxYearInterview.id ? 'not-printable' : ''}>
              <Collapse
                style={{
                  ...(index !== array.length - 1 ? {
                    marginBottom: 20,
                  } : {

                  }),
                }}
                key={`job-collapse-${taxYearInterview.id}`}
                defaultActiveKey={`job-panel-${urlTaxYearInterviewId}` || `job-panel-${array[0].id}`}
              >
                <JobPanel
                  header={(taxYearInterview.TaxYear?.year && taxYearInterview.Interviews?.name)
                    ? `${taxYearInterview.TaxYear?.year} - ${taxYearInterview.Interviews?.name}`
                    : <Skeleton.Button size="small" shape="round" style={{ width: 200 }} />}
                  key={`job-panel-${taxYearInterview.id}`}
                  extra={(
                    <JobExtra
                      progressStatusId={taxYearInterview.progressStatusId}
                      taxYear={taxYearInterview.TaxYear?.year}
                      onChange={(progressStatusId: string) => {
                        onStatusChange(progressStatusId, taxYearInterview.progressStatusId, taxYearInterview.id);
                      }}
                      interviewId={taxYearInterview.Interviews?.id}
                      interviewName={taxYearInterview.Interviews?.name}
                      loading={loadingUpdateTaxYearInterviewProgressStatus}
                    />
                  )}
                >
                  <Job
                    key={taxYearInterview.id}
                    jobId={taxYearInterview.id}
                    printInterview={printInterview}
                    jobName={`${taxYearInterview.TaxYear?.year}-${taxYearInterview.Interviews?.name}`}
                  />
                </JobPanel>
              </Collapse>
            </div>
          </JobProvider>
        )))}
      </div>
      <AddJobModal
        entityId={entityId ?? ''}
        interviews={interviews
          ?.map(({ id: interview, name }) => ({ value: interview, label: name })) ?? []}
        email={email}
        firstName={firstName ?? ''}
        lastName={lastName ?? ''}
        isModalVisible={addJobModalVisible}
        setIsModalVisible={setAddJobModalVisible}
      />
    </>
  );
};

export default Jobs;
