import { useContext, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Icons,
  Modal,
  Form,
  Input,
  message,
  Typography,
  Button,
  Tooltip,
} from 'taxaroo-ui';
import { InfoCircleOutlined } from 'taxaroo-ui/src/Icons';
import { JobContext } from '~src/components/providers/job-provider';
import { useCreateRequestedFilesMutation } from '~src/graphql/mutations/requestedFiles';
import { GetClientFilesFromDbDocument } from '~src/graphql/queries/clients';

const { FileAddOutlined, PlusOutlined, DeleteOutlined } = Icons;
const { Text } = Typography;

interface RequestFilesModalProps {
  isModalVisible: boolean
  setIsModalVisible: (b: boolean) => void
}
function RequestFilesModal({
  isModalVisible,
  setIsModalVisible,
}: RequestFilesModalProps) {
  // state
  const [filesToRequest, setFilesToRequest] = useState(['']);
  const [additionalInstructions, setAdditionalInstructions] = useState('');
  const [wrongIndex, setWrongIndex] = useState(0);
  const [tooltip, setTooltip] = useState('');

  // context
  const jobContext = useContext(JobContext);
  const { taxYearInterviewId } = jobContext;

  // graphql
  const [requestFiles, { loading: loadingRequestedFiles }] = useCreateRequestedFilesMutation({
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: () => {
      message.success('Successfully requested files.');
      setFilesToRequest(['']);
      setAdditionalInstructions('');
      setIsModalVisible(false);
    },
    refetchQueries: [{
      query: GetClientFilesFromDbDocument,
      variables: {
        taxYearInterviewId: jobContext?.taxYearInterviewId,
        userId: jobContext?.entityOwnerId,
      },
    }],
  });

  const handleAddFile = () => {
    const tempFilesToRequest = filesToRequest;
    tempFilesToRequest.push('');
    setFilesToRequest([...tempFilesToRequest]);
  };

  const handleRemoveFileRequset = (index: number) => {
    const tempFilesToRequest = filesToRequest;
    tempFilesToRequest.splice(index, 1);
    setFilesToRequest([...tempFilesToRequest]);
  };

  const handleSubmit = () => {
    setTooltip('');
    requestFiles({
      variables: {
        createRequestedFilesInput: [
          ...filesToRequest.map((file) => ({
            taxYearInterviewId,
            fileName: file,
            notes: additionalInstructions,
          })),
        ],
      },
    });
  };

  const handleType = (index: number, value: string) => {
    const tempFilesToRequest = filesToRequest;
    tempFilesToRequest[index] = value;
    const files = [...tempFilesToRequest];
    setFilesToRequest(files);
  };

  useEffect(() => {
    // validate file names
    setWrongIndex(filesToRequest.findIndex((x) => x.length === 0));
  }, [filesToRequest]);

  useEffect(() => {
    setTooltip(isModalVisible && wrongIndex === -1 ? 'With Automated Follow-ups' : '');
  }, [isModalVisible, wrongIndex]);

  return (
    <Modal
      visible={isModalVisible}
      title="Request Files with Automated Follow-Ups"
      onCancel={() => setIsModalVisible(false)}
      onOk={handleSubmit}
      footer={[
        <Button
          onClick={() => setIsModalVisible(false)}
        >
          Cancel
        </Button>,
        <Tooltip title={tooltip}>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={loadingRequestedFiles || wrongIndex !== -1}
            loading={loadingRequestedFiles}
          >
            <FileAddOutlined />
            Request Files
          </Button>
        </Tooltip>,
      ]}
    >
      {filesToRequest.map((item, index) => (
        <Form.Item
          name="fileName"
          label={(
            <>
              {'File Name '}
              <Tooltip
                title="For example: Your 2022 W2"
              >
                <InfoCircleOutlined
                  style={{
                    fontSize: 12,
                    position: 'relative',
                    top: '-0.5em',
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </>
          )}
          key={`request-files-${index}`}
        >
          <Row gutter={4}>
            <Col span={22}>
              <Input
                value={item}
                onChange={(e) => handleType(index, e.target.value)}
              />
            </Col>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              {index > 0
                && (
                <DeleteOutlined
                  style={{ color: 'red' }}
                  onClick={() => handleRemoveFileRequset(index)}
                />
                )}
            </Col>
          </Row>
        </Form.Item>
      ))}
      <Button
        icon={<PlusOutlined />}
        onClick={handleAddFile}
      >
        Add Another File
      </Button>
      <div style={{ height: '1em' }} />
      <Text>Additional Instructions to Client (optional):</Text>
      <Input.TextArea
        value={additionalInstructions}
        onChange={(e) => setAdditionalInstructions(e.target.value)}
      />
      <div style={{ marginTop: '1em' }}>
        Files will be requested according to cadence sent in Notifications Section of Settings.
      </div>
    </Modal>
  );
}

export default RequestFilesModal;
