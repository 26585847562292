/* .btn {
  z-index: 9000;
} */

.btnContainer {
  margin: 0 !important;
  background: white !important;
  justify-content: center;
}

.btnContainer button {
  margin: 2px;
}

.row-dragging {
  background: #999;
  border: 1px solid #ccc;
  z-index: 1000;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.row-dragging .drag-invisible {
  visibility: hidden;
}
