import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoute from '~src/components/organisms/PrivateRoute';
import { PUBLIC_ROUTES, PRIVATE_ROUTES } from './routes';
import NotFound from './pages/NotFound';
import 'taxaroo-ui/dist/index.css';
import { PageViewProvider } from './components/providers/pageview-provider';

const App: FC = () => (
  <BrowserRouter>
    <Routes>
      {PUBLIC_ROUTES.map((route) => (
        <Route key={route.path} {...route} />
      ))}
      {PRIVATE_ROUTES.map(({ element, ...route }) => (
        <Route key={route.path} element={<PrivateRoute>{element}</PrivateRoute>} {...route} />
      ))}
      <Route
        path="*"
        element={(
          <PageViewProvider>
            <NotFound />
          </PageViewProvider>
        )}
      />
    </Routes>
  </BrowserRouter>
);

export default App;
