import { Menu, Avatar } from 'taxaroo-ui';
import tinycolor from 'tinycolor2';
import { useGetCustomLayoutsQuery } from '~src/graphql/queries/settings';
import { useAppSelector } from '~src/redux/hooks';
import * as styles from '../styles.module.css';

const { SubMenu, Item } = Menu;

function AvatarSubMenu(props: any) {
  const { firstName, previousAccessToken } = useAppSelector((state) => state.session);
  const firstLetter = firstName.charAt(0);

  const { data: customLayoutData, loading: loadingCustomLayoutQuery } = useGetCustomLayoutsQuery();
  const { CustomLayout } = customLayoutData || {};
  const headerBackgroundColor = CustomLayout?.headerBackgroundColor || '#f5f5f5';
  // lets set a color for the firstLetter
  // first try white
  let color = 'white';
  const readability = tinycolor.readability(headerBackgroundColor, color);
  // if readability is poor
  if (readability < 2) {
    // if it's not readable, try darker color
    color = tinycolor.mostReadable(headerBackgroundColor, [
      tinycolor(headerBackgroundColor).darken(25).toString(),
      tinycolor(headerBackgroundColor).darken(37).toString(),
      tinycolor(headerBackgroundColor).darken(50).toString(),
    ]).toHexString();
  }
  // if background color is is white we need to use black
  if (tinycolor(headerBackgroundColor).toHexString() === '#ffffff') {
    color = 'black';
  }

  return (
    <SubMenu
      className={styles.noAnimation}
      icon={(
        <Avatar
          // style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
          style={{
            color,
            backgroundColor: headerBackgroundColor === '#ffffff' ? '#f5f5f5' : headerBackgroundColor, // don't set backgroundColor to white
          }}
        >
          {firstLetter.toUpperCase()}
        </Avatar>
      )}
      {...props}
    >
      {previousAccessToken && (
        <Item key="return-to-account">
          ← Return to your account
        </Item>
      )}
      <Item key="logout">
        Logout
      </Item>
    </SubMenu>
  );
}

export default AvatarSubMenu;
