import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEventTypeMutationVariables = Types.Exact<{
  input: Types.CreateEventTypeInput;
}>;


export type CreateEventTypeMutation = { __typename?: 'Mutation', createEventType: { __typename?: 'EventTypeEntity', id: string, userId: string, name: string, description?: string | null, color: string, duration: number, maxEvents?: number | null, minNotice?: number | null, beforeBuffer?: number | null, afterBuffer?: number | null, timezone?: string | null, active: boolean, createAt: any, updateAt: any, EventTypeAvailability?: Array<{ __typename?: 'EventTypeAvailabilityEntity', id: string, day: string, EventTypeAvailabilityBracket?: Array<{ __typename?: 'EventTypeAvailabilityBracketEntity', id: string, startHour: number, startMinute: number, endHour: number, endMinute: number }> | null }> | null } };

export type RemoveEventTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type RemoveEventTypeMutation = { __typename?: 'Mutation', removeEventType: { __typename?: 'EventTypeEntity', id: string, userId: string, name: string, description?: string | null, color: string, duration: number, maxEvents?: number | null, minNotice?: number | null, beforeBuffer?: number | null, afterBuffer?: number | null, timezone?: string | null, active: boolean, createAt: any, updateAt: any, EventTypeAvailability?: Array<{ __typename?: 'EventTypeAvailabilityEntity', id: string, day: string, EventTypeAvailabilityBracket?: Array<{ __typename?: 'EventTypeAvailabilityBracketEntity', id: string, startHour: number, startMinute: number, endHour: number, endMinute: number }> | null }> | null } };

export type UpdateEventTypeMutationVariables = Types.Exact<{
  input: Types.UpdateEventTypeInput;
}>;


export type UpdateEventTypeMutation = { __typename?: 'Mutation', updateEventType: { __typename?: 'EventTypeEntity', id: string, userId: string, name: string, description?: string | null, color: string, duration: number, maxEvents?: number | null, minNotice?: number | null, beforeBuffer?: number | null, afterBuffer?: number | null, timezone?: string | null, active: boolean, createAt: any, updateAt: any, EventTypeAvailability?: Array<{ __typename?: 'EventTypeAvailabilityEntity', day: string, EventTypeAvailabilityBracket?: Array<{ __typename?: 'EventTypeAvailabilityBracketEntity', startHour: number, startMinute: number, endHour: number, endMinute: number }> | null }> | null } };

export type CreateEventMutationVariables = Types.Exact<{
  input: Types.CreateEventInput;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent: { __typename?: 'EventEntity', id: string, eventTypeId: string, organizerId: string, meetingId: string, status: Types.EventStatuses, startDate: any, endDate: any, title: string, notes?: string | null, type: Types.EventType, createAt: any, updateAt: any, Meetings: { __typename?: 'MeetingEntity', id: string, channelId: string, createAt: any, updateAt: any, MeetingAttendees: Array<{ __typename?: 'MeetingAttendeesEntity', id: string, userId?: string | null, unregisteredUserId?: string | null, firstName?: string | null, lastName?: string | null, createAt: any, updateAt: any }> }, EventTypes: { __typename?: 'EventTypeEntity', id: string, userId: string, name: string, description?: string | null, color: string, duration: number, maxEvents?: number | null, minNotice?: number | null, beforeBuffer?: number | null, afterBuffer?: number | null, timezone?: string | null, active: boolean, createAt: any, updateAt: any } } };

export type GetNylasCalendarEventsMutationVariables = Types.Exact<{
  input: Types.FetchNylasEventsDto;
}>;


export type GetNylasCalendarEventsMutation = { __typename?: 'Mutation', getNylasCalendarEvents: Array<{ __typename?: 'NylasEventEntitiy', id: string, organizerId: string, status: Types.EventStatuses, startDate: any, endDate: any, title: string, notes: string, type: Types.EventType, color: string, duration: number, iCalUID: string, attendees: Array<{ __typename?: 'NylasEventAttendees', name: string, email: string, status: string }> }> };

export type CancelEventMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type CancelEventMutation = { __typename?: 'Mutation', cancelEvent: { __typename?: 'EventEntity', id: string, eventTypeId: string, organizerId: string, meetingId: string, status: Types.EventStatuses, startDate: any, endDate: any, title: string, notes?: string | null, type: Types.EventType } };

export type PreparerAvailabilityMutationVariables = Types.Exact<{
  input: Types.PreparerAvailabilityInput;
}>;


export type PreparerAvailabilityMutation = { __typename?: 'Mutation', PreparerAvailability: { __typename?: 'PreparerAvailabilityEntity', eventsInDay: number, brackets: Array<{ __typename?: 'PreparerEventTypeAvailabilityBracketEntity', id: string, startHour: number, startMinute: number, endHour: number, endMinute: number }> } };

export type UpdateEventTypeTimezoneMutationVariables = Types.Exact<{
  timezone: Types.Scalars['String'];
}>;


export type UpdateEventTypeTimezoneMutation = { __typename?: 'Mutation', UpdateEventTypeTimezone: boolean };

export type UpdateEventMutationVariables = Types.Exact<{
  input: Types.UpdateEventInput;
}>;


export type UpdateEventMutation = { __typename?: 'Mutation', updateEvent: { __typename?: 'EventEntity', id: string, eventTypeId: string, organizerId: string, meetingId: string, status: Types.EventStatuses, startDate: any, endDate: any, title: string, notes?: string | null, type: Types.EventType, createAt: any, updateAt: any, Meetings: { __typename?: 'MeetingEntity', id: string, channelId: string, createAt: any, updateAt: any, MeetingAttendees: Array<{ __typename?: 'MeetingAttendeesEntity', id: string, userId?: string | null, unregisteredUserId?: string | null, firstName?: string | null, lastName?: string | null, createAt: any, updateAt: any }> }, EventTypes: { __typename?: 'EventTypeEntity', id: string, userId: string, name: string, description?: string | null, color: string, duration: number, maxEvents?: number | null, minNotice?: number | null, beforeBuffer?: number | null, afterBuffer?: number | null, timezone?: string | null, active: boolean, createAt: any, updateAt: any } } };

export type RemoveEventMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type RemoveEventMutation = { __typename?: 'Mutation', removeEvent: { __typename?: 'EventEntity', id: string, eventTypeId: string, organizerId: string, meetingId: string, status: Types.EventStatuses, startDate: any, endDate: any, title: string, notes?: string | null, type: Types.EventType, createAt: any, updateAt: any } };


export const CreateEventTypeDocument = gql`
    mutation createEventType($input: CreateEventTypeInput!) {
  createEventType(createEventTypeInput: $input) {
    id
    userId
    name
    description
    color
    duration
    maxEvents
    minNotice
    beforeBuffer
    afterBuffer
    timezone
    active
    createAt
    updateAt
    EventTypeAvailability {
      id
      day
      EventTypeAvailabilityBracket {
        id
        startHour
        startMinute
        endHour
        endMinute
      }
    }
  }
}
    `;
export type CreateEventTypeMutationFn = Apollo.MutationFunction<CreateEventTypeMutation, CreateEventTypeMutationVariables>;

/**
 * __useCreateEventTypeMutation__
 *
 * To run a mutation, you first call `useCreateEventTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventTypeMutation, { data, loading, error }] = useCreateEventTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventTypeMutation, CreateEventTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventTypeMutation, CreateEventTypeMutationVariables>(CreateEventTypeDocument, options);
      }
export type CreateEventTypeMutationHookResult = ReturnType<typeof useCreateEventTypeMutation>;
export type CreateEventTypeMutationResult = Apollo.MutationResult<CreateEventTypeMutation>;
export type CreateEventTypeMutationOptions = Apollo.BaseMutationOptions<CreateEventTypeMutation, CreateEventTypeMutationVariables>;
export const RemoveEventTypeDocument = gql`
    mutation removeEventType($id: String!) {
  removeEventType(id: $id) {
    id
    userId
    name
    description
    color
    duration
    maxEvents
    minNotice
    beforeBuffer
    afterBuffer
    timezone
    active
    createAt
    updateAt
    EventTypeAvailability {
      id
      day
      EventTypeAvailabilityBracket {
        id
        startHour
        startMinute
        endHour
        endMinute
      }
    }
  }
}
    `;
export type RemoveEventTypeMutationFn = Apollo.MutationFunction<RemoveEventTypeMutation, RemoveEventTypeMutationVariables>;

/**
 * __useRemoveEventTypeMutation__
 *
 * To run a mutation, you first call `useRemoveEventTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEventTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEventTypeMutation, { data, loading, error }] = useRemoveEventTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveEventTypeMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEventTypeMutation, RemoveEventTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEventTypeMutation, RemoveEventTypeMutationVariables>(RemoveEventTypeDocument, options);
      }
export type RemoveEventTypeMutationHookResult = ReturnType<typeof useRemoveEventTypeMutation>;
export type RemoveEventTypeMutationResult = Apollo.MutationResult<RemoveEventTypeMutation>;
export type RemoveEventTypeMutationOptions = Apollo.BaseMutationOptions<RemoveEventTypeMutation, RemoveEventTypeMutationVariables>;
export const UpdateEventTypeDocument = gql`
    mutation updateEventType($input: UpdateEventTypeInput!) {
  updateEventType(updateEventTypeInput: $input) {
    id
    userId
    name
    description
    color
    duration
    maxEvents
    minNotice
    beforeBuffer
    afterBuffer
    timezone
    active
    createAt
    updateAt
    EventTypeAvailability {
      day
      EventTypeAvailabilityBracket {
        startHour
        startMinute
        endHour
        endMinute
      }
    }
  }
}
    `;
export type UpdateEventTypeMutationFn = Apollo.MutationFunction<UpdateEventTypeMutation, UpdateEventTypeMutationVariables>;

/**
 * __useUpdateEventTypeMutation__
 *
 * To run a mutation, you first call `useUpdateEventTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventTypeMutation, { data, loading, error }] = useUpdateEventTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventTypeMutation, UpdateEventTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventTypeMutation, UpdateEventTypeMutationVariables>(UpdateEventTypeDocument, options);
      }
export type UpdateEventTypeMutationHookResult = ReturnType<typeof useUpdateEventTypeMutation>;
export type UpdateEventTypeMutationResult = Apollo.MutationResult<UpdateEventTypeMutation>;
export type UpdateEventTypeMutationOptions = Apollo.BaseMutationOptions<UpdateEventTypeMutation, UpdateEventTypeMutationVariables>;
export const CreateEventDocument = gql`
    mutation createEvent($input: CreateEventInput!) {
  createEvent(createEventInput: $input) {
    id
    eventTypeId
    organizerId
    meetingId
    status
    startDate
    endDate
    title
    notes
    type
    createAt
    updateAt
    Meetings {
      id
      channelId
      createAt
      updateAt
      MeetingAttendees {
        id
        userId
        unregisteredUserId
        firstName
        lastName
        createAt
        updateAt
      }
    }
    EventTypes {
      id
      userId
      name
      description
      color
      duration
      maxEvents
      minNotice
      beforeBuffer
      afterBuffer
      timezone
      active
      createAt
      updateAt
    }
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const GetNylasCalendarEventsDocument = gql`
    mutation getNylasCalendarEvents($input: FetchNylasEventsDto!) {
  getNylasCalendarEvents(fetchNylasEventsDto: $input) {
    id
    organizerId
    status
    startDate
    endDate
    title
    notes
    type
    color
    duration
    iCalUID
    attendees {
      name
      email
      status
    }
  }
}
    `;
export type GetNylasCalendarEventsMutationFn = Apollo.MutationFunction<GetNylasCalendarEventsMutation, GetNylasCalendarEventsMutationVariables>;

/**
 * __useGetNylasCalendarEventsMutation__
 *
 * To run a mutation, you first call `useGetNylasCalendarEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetNylasCalendarEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getNylasCalendarEventsMutation, { data, loading, error }] = useGetNylasCalendarEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNylasCalendarEventsMutation(baseOptions?: Apollo.MutationHookOptions<GetNylasCalendarEventsMutation, GetNylasCalendarEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetNylasCalendarEventsMutation, GetNylasCalendarEventsMutationVariables>(GetNylasCalendarEventsDocument, options);
      }
export type GetNylasCalendarEventsMutationHookResult = ReturnType<typeof useGetNylasCalendarEventsMutation>;
export type GetNylasCalendarEventsMutationResult = Apollo.MutationResult<GetNylasCalendarEventsMutation>;
export type GetNylasCalendarEventsMutationOptions = Apollo.BaseMutationOptions<GetNylasCalendarEventsMutation, GetNylasCalendarEventsMutationVariables>;
export const CancelEventDocument = gql`
    mutation cancelEvent($id: String!) {
  cancelEvent(id: $id) {
    id
    eventTypeId
    organizerId
    meetingId
    status
    startDate
    endDate
    title
    notes
    type
  }
}
    `;
export type CancelEventMutationFn = Apollo.MutationFunction<CancelEventMutation, CancelEventMutationVariables>;

/**
 * __useCancelEventMutation__
 *
 * To run a mutation, you first call `useCancelEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelEventMutation, { data, loading, error }] = useCancelEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelEventMutation(baseOptions?: Apollo.MutationHookOptions<CancelEventMutation, CancelEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelEventMutation, CancelEventMutationVariables>(CancelEventDocument, options);
      }
export type CancelEventMutationHookResult = ReturnType<typeof useCancelEventMutation>;
export type CancelEventMutationResult = Apollo.MutationResult<CancelEventMutation>;
export type CancelEventMutationOptions = Apollo.BaseMutationOptions<CancelEventMutation, CancelEventMutationVariables>;
export const PreparerAvailabilityDocument = gql`
    mutation PreparerAvailability($input: PreparerAvailabilityInput!) {
  PreparerAvailability(preparerAvailabilityInput: $input) {
    eventsInDay
    brackets {
      id
      startHour
      startMinute
      endHour
      endMinute
    }
  }
}
    `;
export type PreparerAvailabilityMutationFn = Apollo.MutationFunction<PreparerAvailabilityMutation, PreparerAvailabilityMutationVariables>;

/**
 * __usePreparerAvailabilityMutation__
 *
 * To run a mutation, you first call `usePreparerAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreparerAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preparerAvailabilityMutation, { data, loading, error }] = usePreparerAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePreparerAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<PreparerAvailabilityMutation, PreparerAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PreparerAvailabilityMutation, PreparerAvailabilityMutationVariables>(PreparerAvailabilityDocument, options);
      }
export type PreparerAvailabilityMutationHookResult = ReturnType<typeof usePreparerAvailabilityMutation>;
export type PreparerAvailabilityMutationResult = Apollo.MutationResult<PreparerAvailabilityMutation>;
export type PreparerAvailabilityMutationOptions = Apollo.BaseMutationOptions<PreparerAvailabilityMutation, PreparerAvailabilityMutationVariables>;
export const UpdateEventTypeTimezoneDocument = gql`
    mutation UpdateEventTypeTimezone($timezone: String!) {
  UpdateEventTypeTimezone(timezone: $timezone)
}
    `;
export type UpdateEventTypeTimezoneMutationFn = Apollo.MutationFunction<UpdateEventTypeTimezoneMutation, UpdateEventTypeTimezoneMutationVariables>;

/**
 * __useUpdateEventTypeTimezoneMutation__
 *
 * To run a mutation, you first call `useUpdateEventTypeTimezoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventTypeTimezoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventTypeTimezoneMutation, { data, loading, error }] = useUpdateEventTypeTimezoneMutation({
 *   variables: {
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useUpdateEventTypeTimezoneMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventTypeTimezoneMutation, UpdateEventTypeTimezoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventTypeTimezoneMutation, UpdateEventTypeTimezoneMutationVariables>(UpdateEventTypeTimezoneDocument, options);
      }
export type UpdateEventTypeTimezoneMutationHookResult = ReturnType<typeof useUpdateEventTypeTimezoneMutation>;
export type UpdateEventTypeTimezoneMutationResult = Apollo.MutationResult<UpdateEventTypeTimezoneMutation>;
export type UpdateEventTypeTimezoneMutationOptions = Apollo.BaseMutationOptions<UpdateEventTypeTimezoneMutation, UpdateEventTypeTimezoneMutationVariables>;
export const UpdateEventDocument = gql`
    mutation updateEvent($input: UpdateEventInput!) {
  updateEvent(updateEventInput: $input) {
    id
    eventTypeId
    organizerId
    meetingId
    status
    startDate
    endDate
    title
    notes
    type
    createAt
    updateAt
    Meetings {
      id
      channelId
      createAt
      updateAt
      MeetingAttendees {
        id
        userId
        unregisteredUserId
        firstName
        lastName
        createAt
        updateAt
      }
    }
    EventTypes {
      id
      userId
      name
      description
      color
      duration
      maxEvents
      minNotice
      beforeBuffer
      afterBuffer
      timezone
      active
      createAt
      updateAt
    }
  }
}
    `;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const RemoveEventDocument = gql`
    mutation removeEvent($id: String!) {
  removeEvent(id: $id) {
    id
    eventTypeId
    organizerId
    meetingId
    status
    startDate
    endDate
    title
    notes
    type
    createAt
    updateAt
  }
}
    `;
export type RemoveEventMutationFn = Apollo.MutationFunction<RemoveEventMutation, RemoveEventMutationVariables>;

/**
 * __useRemoveEventMutation__
 *
 * To run a mutation, you first call `useRemoveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEventMutation, { data, loading, error }] = useRemoveEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveEventMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEventMutation, RemoveEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEventMutation, RemoveEventMutationVariables>(RemoveEventDocument, options);
      }
export type RemoveEventMutationHookResult = ReturnType<typeof useRemoveEventMutation>;
export type RemoveEventMutationResult = Apollo.MutationResult<RemoveEventMutation>;
export type RemoveEventMutationOptions = Apollo.BaseMutationOptions<RemoveEventMutation, RemoveEventMutationVariables>;
export const namedOperations = {
  Mutation: {
    createEventType: 'createEventType',
    removeEventType: 'removeEventType',
    updateEventType: 'updateEventType',
    createEvent: 'createEvent',
    getNylasCalendarEvents: 'getNylasCalendarEvents',
    cancelEvent: 'cancelEvent',
    PreparerAvailability: 'PreparerAvailability',
    UpdateEventTypeTimezone: 'UpdateEventTypeTimezone',
    updateEvent: 'updateEvent',
    removeEvent: 'removeEvent'
  }
}