import React from 'react';
import { Card as AntCard, CardProps } from 'antd';
import { CardGridProps, CardMetaProps } from 'antd/lib/card';

export interface TaxCardProps extends CardProps {
	elevated?: boolean
}

const Card: React.FC<TaxCardProps>&{
  Meta: React.FC<CardMetaProps>
  Grid: React.FC<CardGridProps>
} = (props) => {
  const { elevated, className } = props;

  return (
    <AntCard
      {...props}
      className={[
        className,
        elevated && 'elevated',
      ].filter(Boolean).join(' ')}
      bordered={false}
    />
  );
};

Card.Meta = AntCard.Meta;
Card.Grid = AntCard.Grid;

export default Card;
