import React from 'react';
import {
  Table as AntTable,
  TableProps,
  Row,
} from 'antd';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Typography from '../Typography';
import Icons from '../Icons';

const { Text } = Typography;
const { FolderOpenOutlined } = Icons;

interface NoDataPropsType {
  title?: string;
  icon?: IconComponentProps;
  extra?: any;
}
export interface TaxTableProps<RecordType extends object = any> extends TableProps<RecordType> {
	/**
	 * If table has no data
	 */
	noDataProps?: NoDataPropsType;
}

const EmptyTable = ({
  title = 'No Data',
  icon = <FolderOpenOutlined style={{ fontSize: 60 }} />,
  extra,
}: NoDataPropsType) => (
  <div style={{ padding: '15px 0' }}>
    <Row justify="center">
      {icon}
    </Row>
    <Row justify="center">
      <Text style={{ color: 'gray' }}>{title}</Text>
    </Row>
    {extra}
  </div>
);

const Table: React.FC<TaxTableProps> = ({
  className,
  noDataProps,
  ...rest
}) => (
  <AntTable
    locale={{ emptyText: <EmptyTable {...noDataProps} /> }}
    {...rest}
    // className={[
    //   !bordered && 'unbordered',
    // ].filter(Boolean).join(' ')}
  />
);

export default Table;
