import {
  FC,
  useContext, useReducer, useState,
} from 'react';
import {
  Typography,
  Button,
  Space,
  Menu,
  Tag,
  Dropdown,
  Table,
  message,
  Row,
  Col,
  Icons,
  Tooltip,
  Popover,
  Empty,
} from 'taxaroo-ui';
import { JobContext } from '~src/components/providers/job-provider';
import { FileApprovalEntity, FileRequestEntity, TaskType } from '~src/graphql';
import { useGetFileRequestByTaxYearInterviewIdQuery } from '~src/graphql/queries/fileRequests';
import { useMarkFileApprovalsCompleteMutation, useRemoveFileRequestMutation, useResendFileRequestMutation } from '~src/graphql/mutations/fileRequests';
import { useDownloadFileFromS3Mutation } from '~src/graphql/mutations/clients';
import { GetClientsByProgressStatusIdDocument, GetClientsDocument } from '~src/graphql/queries/settings';
import { useUpsertJobTrackingByTaxYearInterviewIdMutation } from '~src/graphql/mutations/jobTracking';
import { Features, useUpgradeModal } from '~src/components/atoms/UpgradeModal/ModalProvider';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import NewESignatureRequestModal from '../../../../molecules/NewESignatureRequestModal';
import DocumentApprovalRequestModal from '../../../../molecules/DocumentApprovalRequestModal';
import ActionType from './action.type.enum';
import actionCreators from './action.creator';

const { Paragraph } = Typography;
const {
  SendOutlined,
  PlusOutlined,
  CheckCircleOutlined,
  ArrowDownOutlined,
  UndoOutlined,
  DeleteOutlined,
  CheckOutlined,
  DownloadOutlined,
  EyeOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
} = Icons;

const ESignatureAndApproval: FC = () => {
  const [isESignatureRequestModalVisible, setIsESignatureRequestModalVisible] = useState<boolean>(false);
  const [isDocApprovalRequestModalVisible, setIsDocApprovalRequestModalVisible] = useState<boolean>(false);
  const [eSignatureApprovalMode, setESignatureApprovalMode] = useState<'create' | 'edit'>('create');

  const { showModal, accessObject } = useUpgradeModal();

  // context
  const {
    taxYearInterviewId,
    progressStatusId,
    approvalsCompletedAt,
    dispatch,
    clientEmail,
  } = useContext(JobContext);

  // graphql
  // fetch all eSignature requests
  const {
    data: fileRequestsData, loading: loadingFileRequests, refetch: refetchFileRequests,
  } = useGetFileRequestByTaxYearInterviewIdQuery({
    variables: {
      taxYearInterviewId,
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const fileRequests = fileRequestsData?.fileRequestsByTaxYearInterviewId;
  // getSignedFile
  const [urlS3File] = useDownloadFileFromS3Mutation();
  // mutations
  // mark approved
  const [markApprovalsComplete, { loading: loadingMarkComplete }] = useMarkFileApprovalsCompleteMutation({
    onCompleted: () => {
      message.success('File approvals marked as complete.');
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  // resend file request
  const [resendRequest, { loading: loadingResendRequest }] = useResendFileRequestMutation({
    onCompleted: () => {
      message.success('Request re-sent.');
    },
    onError: (error) => {
      message.error(error.message);
    },
    refetchQueries: [
      'GetFileRequetsByTaxYearInterviewId',
    ],
  });
  // remove file request
  const [removeFileRequest, { loading: loadingRemoveFileRequest }] = useRemoveFileRequestMutation({
    onCompleted: () => {
      refetchFileRequests();
      message.success('File request deleted.');
    },
    onError: (error) => {
      message.error(error.message);
    },
  });
  const [upsertJobTracking, { loading: loadingUpsertJobTracking }] = useUpsertJobTrackingByTaxYearInterviewIdMutation({
    onError: (error) => {
      message.error(error.message);
    },
  });
  // end graphql

  const eSignatureAndApprovalReducer = (state: any, action: any) => {
    switch (action.type) {
      case ActionType.INITIAL_STATE:
        return action.payload;
      case ActionType.E_SIGN_MODAL_OK_BUTTON_CLICK:
        // console.log('ActionType.E_SIGN_MODAL_OK_BUTTON_CLICK ', state, action);
        return { ...state, ...action.payload };
      case ActionType.E_SIGN_MODAL_CONTENT_TAB_ONCHANGE:
        return { ...state, ...action.payload };
      case ActionType.E_SIGN_MODAL_CANCEL_BUTTON_CLICK:
        return { ...state, ...action.payload };
      case ActionType.UPDATE:
        return { ...state, ...action.payload };
      case ActionType.RESET_FORM:
        return {
          ...state,
          currentActiveTab: '1',
          fileList: [],
          eSignatureModalOkText: 'Add Signature Fields',
          eSignatureModalCacelText: 'Cancel',
          newDraggableBoundarySignFields: [],
          compiledFile: null,
        };
      default:
        throw new Error();
    }
  };

  const [
    eSignatureAndApprovalState, eSignatureAndApprovalDispatch,
  ] = useReducer(eSignatureAndApprovalReducer, {
    currentActiveTab: '1',
    fileList: [],
    eSignatureModalOkText: 'Add Signature Fields',
    eSignatureModalCacelText: 'Cancel',
    newDraggableBoundarySignFields: [],
    compiledFile: null,
  });

  const newESignatureRequestButtonOnClick = () => {
    if (!accessObject?.[Features.signatures]) {
      showModal(Features.signatures);
      return;
    }
    setESignatureApprovalMode('create');
    eSignatureAndApprovalDispatch(actionCreators.update({
      fileRequest: undefined,
      fileList: [],
    }));
    setIsESignatureRequestModalVisible(true);

    trackCustomerIoEvent(CUSTOMER_IO_EVENTS.MODAL_SHOW, {
      'Modal Title': 'Create new signature request',
      Source: 'Click on button',
      url: window.location.href,
    });
  };

  const documentApprovalRequestButtonOnClick = () => {
    if (!accessObject?.[Features.approvals]) {
      showModal(Features.approvals);
      return;
    }
    setESignatureApprovalMode('create');
    eSignatureAndApprovalDispatch(actionCreators.update({
      fileRequest: undefined,
      fileList: [],
    }));
    setIsDocApprovalRequestModalVisible(true);

    trackCustomerIoEvent(CUSTOMER_IO_EVENTS.MODAL_SHOW, {
      'Modal Title': 'Create new document approval request',
      Source: 'Click on button',
      url: window.location.href,
    });
  };

  const openLink = async (key: string, download = false) => {
    try {
      const { data } = await urlS3File({ variables: { s3Key: key } });
      // todo: remove this once confirmed I don't need it
      // const result = await getSignedFile({
      //   variables: {
      //     s3Key: key,
      //     download,
      //   },
      // });
      // const url = result?.data?.SignedFileByS3Key;
      const url = data?.downloadFile?.signedUrl;
      window.open(url, '_blank');
    } catch (e) {
      console.log('caught error... ');
      console.warn(e);
    }
  };

  const updatefileListWithEditableFile = async (s3Key: string, fileName: string) => {
    const { data } = await urlS3File({ variables: { s3Key } });
    const response = await fetch(data?.downloadFile?.signedUrl);
    if (response.ok) {
      const docBlob = await response.blob();
      eSignatureAndApprovalDispatch(actionCreators.update({
        fileList: [new File([docBlob], fileName, { type: 'application/pdf' })],
      }));
    }
  };

  const menuItemOnClick = (menuItem: any, tableRow: FileRequestEntity) => {
    // console.log('tableRow: ', tableRow);
    switch (menuItem.key) {
      case 'viewOriginal':
        openLink(tableRow?.CompiledFile?.s3Key);
        break;

      case 'editFields':
        if (tableRow?.FileApproval?.[0]?.Tasks?.taskType === TaskType.Signatures) {
          eSignatureAndApprovalDispatch(actionCreators.update({
            fileRequest: tableRow,
            eSignatureModalOkText: 'Update Signatures',
          }));
          updatefileListWithEditableFile(tableRow?.CompiledFile?.s3Key, tableRow?.CompiledFile?.name);
          setESignatureApprovalMode('edit');
          setIsESignatureRequestModalVisible(true);

          trackCustomerIoEvent(CUSTOMER_IO_EVENTS.MODAL_SHOW, {
            'Modal Title': 'Update signature request',
            Source: 'Click from menu',
            url: window.location.href,
          });
        }
        if (tableRow?.FileApproval?.[0]?.Tasks?.taskType === TaskType.ApproveDocuments) {
          eSignatureAndApprovalDispatch(actionCreators.update({
            fileRequest: tableRow,
            eSignatureModalOkText: 'Update Signatures',
          }));
          updatefileListWithEditableFile(tableRow?.CompiledFile?.s3Key, tableRow?.CompiledFile?.name);
          setESignatureApprovalMode('edit');
          setIsDocApprovalRequestModalVisible(true);

          trackCustomerIoEvent(CUSTOMER_IO_EVENTS.MODAL_SHOW, {
            'Modal Title': 'Update document approval request',
            Source: 'Click from menu',
            url: window.location.href,
          });
        }
        break;

      case 'resendRequest':
      case 'resetKBA':
        resendRequest({
          variables: {
            fileRequestId: tableRow.id,
          },
        });
        break;

      case 'markApprovalsComplete':
        markApprovalsComplete({
          variables: {
            fileRequestId: tableRow.id,
          },
        });
        break;

      case 'deleteFile':
        removeFileRequest({
          variables: {
            id: tableRow.id,
          },
        });
        break;

      case 'downloadFinalCopy':
        openLink(tableRow?.SignedFile?.s3Key, true);
        break;

      default:
        break;
    }
  };

  const getMenuItemList = (fileRequest: FileRequestEntity) => {
    const allComplete = fileRequest?.FileApproval?.every((fileApproval: FileApprovalEntity) => fileApproval.completedAt);
    const menuItems = new Array<{key: string; title: string; icon: any;}>();

    if (allComplete && fileRequest.SignedFile) {
      menuItems.push({
        key: 'downloadFinalCopy',
        title: 'Download Final Copy',
        icon: <DownloadOutlined />,
      });
    }

    menuItems.push({
      key: 'viewOriginal',
      title: 'View Original',
      icon: <EyeOutlined />,
    });

    if (!allComplete) {
      menuItems.push(
        {
          key: 'editFields',
          title: 'Edit Fields',
          icon: <EditOutlined />,
        },
        {
          key: 'resendRequest',
          title: 'Resend Request',
          icon: <SendOutlined />,
        },
        {
          key: 'resetKBA',
          title: 'Reset KBA Attempts',
          icon: <UndoOutlined />,
        },
        {
          key: 'markApprovalsComplete',
          title: 'Mark as Completed',
          icon: <CheckOutlined />,
        },
        {
          key: 'deleteFile',
          title: 'Delete File',
          icon: <DeleteOutlined />,
        },
      );
    }

    return menuItems;
  };

  const getStatusTag = (fileRequest: FileRequestEntity) => {
    let color = 'processing';
    let status = 'SENT';
    let icon = <SendOutlined />;
    let title = null;
    const completedFileApprovals = fileRequest?.FileApproval?.filter((fileApproval: FileApprovalEntity) => fileApproval.completedAt);
    // I want to show kba status failed if any of the kba attempts are greater than 2
    const failedKbaApprovals = fileRequest?.FileApproval?.filter((fileApproval: FileApprovalEntity) => {
      const failedKbaAttempts = fileApproval?.KBA?.filter((kba) => kba.numKbaFailedAttempts > 2);
      return failedKbaAttempts?.length;
    });
    if (completedFileApprovals?.length === fileRequest?.FileApproval?.length) { // all approvals are completed
      color = 'success';
      status = 'COMPLETED';
      icon = <CheckCircleOutlined />;
    } else if (failedKbaApprovals?.length) { // kba failed
      color = 'error';
      status = 'KBA FAILED';
      icon = <ExclamationCircleOutlined />;
      title = `${failedKbaApprovals?.[0]?.name || failedKbaApprovals?.[0]?.email} failed KBA`;
    } else if (completedFileApprovals?.length === 0) { // no approvals completed
      color = 'processing';
      status = 'SENT';
      icon = <SendOutlined />;
    } else { // some approvals completed
      color = 'processing';
      status = 'IN PROGRESS';
      icon = <SendOutlined />;
      const completedNames = new Array<string>();
      const notCompletedNames = new Array<string>();
      fileRequest?.FileApproval.forEach((fa) => (fa.completedAt ? completedNames.push(fa.name) : notCompletedNames.push(fa.name)));
      title = (
        <div style={{ textAlign: 'center' }}>
          <Row gutter={16}>
            <Col span={12}><b>Completed</b></Col>
            <Col span={12}><b>Pending</b></Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              {completedNames.map((n) => (
                <>
                  {n}
                  <br />
                </>
              ))}
            </Col>
            <Col span={12}>
              {notCompletedNames.map((n) => (
                <>
                  {n}
                  <br />
                </>
              ))}
            </Col>
          </Row>
        </div>
      );
    }
    return (
      <Tooltip
        title={title}
        overlayInnerStyle={!failedKbaApprovals?.length ? { width: '280px' } : {}}
      >
        <Tag icon={icon} color={color} key={fileRequest.id}>
          {status}
        </Tag>
      </Tooltip>
    );
  };

  const getCompletedDate = (fileRequest: FileRequestEntity) => {
    const completedFileApprovals = fileRequest?.FileApproval?.filter((fileApproval: FileApprovalEntity) => fileApproval.completedAt);
    if (completedFileApprovals?.length === fileRequest?.FileApproval?.length) { // all approvals are completed
      // find the most recent completedAt date
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const mostRecentCompletedAt = completedFileApprovals?.reduce((mostRecentCompletedAt: Date, fileApproval: FileApprovalEntity) => {
        if (fileApproval?.completedAt > mostRecentCompletedAt) {
          return fileApproval?.completedAt;
        }
        return mostRecentCompletedAt;
      }, completedFileApprovals[0]?.completedAt);
      return (
        new Date(mostRecentCompletedAt).toDateString()
      );
    }
    return null;
  };

  const columns = [
    {
      title: 'Document',
      dataIndex: 'title',
      key: 'title',
      sorter: (a: FileRequestEntity, b: FileRequestEntity) => a.title.localeCompare(b.title),
      // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      render: (text: string, row: FileRequestEntity) => <a onClick={() => openLink(row?.SignedFile?.s3Key || row?.CompiledFile?.s3Key)}>{text}</a>,
      // fixed: true,
      width: 250,
    },
    {
      title: 'Status',
      // dataIndex: 'signaturesComplete',
      key: 'signaturesComplete',
      sorter: (a: FileRequestEntity, b: FileRequestEntity) => {
        const getStatusValue = (fileRequest: FileRequestEntity) => {
          const completedFileApprovalsLength = fileRequest?.FileApproval?.filter((fileApproval: FileApprovalEntity) => fileApproval.completedAt).length;
          if (completedFileApprovalsLength === fileRequest?.FileApproval?.length) {
            return 3; // All approvals completed
          }
          if (completedFileApprovalsLength === 0) {
            return 1; // No approvals completed
          }
          return 2; // Some approvals completed
        };
        return getStatusValue(a) - getStatusValue(b);
      },
      render: (text: any, row: FileRequestEntity) => getStatusTag(row),
      width: 100,
    },
    {
      title: 'Date Requested',
      dataIndex: 'signatureRequest',
      key: 'signatureRequest',
      sorter: (a: FileRequestEntity, b: FileRequestEntity) => a.signatureRequest.localeCompare(b.signatureRequest),
      render: (text: any) => (
        <div style={{ minWidth: 100 }}>
          {text ? new Date(text).toDateString() : ''}
        </div>
      ),
      width: 250,
    },
    {
      title: 'Date Completed',
      key: 'completedAt',
      // dataIndex: 'signaturesComplete',
      sorter: (a: FileRequestEntity, b: FileRequestEntity) => {
        const getMostRecentCompletedAt = (fileRequest: FileRequestEntity) => {
          const completedFileApprovals = fileRequest?.FileApproval?.filter((fileApproval: FileApprovalEntity) => fileApproval.completedAt);
          if (completedFileApprovals?.length === fileRequest?.FileApproval?.length) {
            // find the most recent completedAt date
            return completedFileApprovals.reduce((mostRecent, fileApproval) => {
              const currentCompletedAt = new Date(fileApproval.completedAt);
              if (currentCompletedAt > mostRecent) {
                return currentCompletedAt;
              }
              return mostRecent;
            }, new Date(completedFileApprovals[0].completedAt));
          }
          return new Date(0); // Return epoch date if no completions, so it will sort to the bottom
        };
        const dateA = getMostRecentCompletedAt(a);
        const dateB = getMostRecentCompletedAt(b);
        return dateA > dateB ? 1 : -1;
      },
      render: (text: any, row: FileRequestEntity) => (
        <div style={{ minWidth: 100 }}>
          {getCompletedDate(row)}
        </div>
      ),
      width: 250,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, row: FileRequestEntity) => {
        const menuItems = getMenuItemList(row);
        return (
          menuItems.length === 1
            ? (
              <Button
                size="small"
                onClick={() => menuItemOnClick(menuItems[0], row)}
              >
                {menuItems[0].title}
              </Button>
            )
            : (
              <Dropdown.Button
                icon={<ArrowDownOutlined />}
                trigger={['click']}
                size="small"
                onClick={() => menuItemOnClick(menuItems[0], row)}
                overlay={(
                  <Menu onClick={(menuItem: any) => { menuItemOnClick(menuItem, row); }}>
                    {menuItems.slice(1).map((menuItem) => (
                      <Menu.Item
                        key={menuItem.key}
                        danger={menuItem.key === 'deleteFile'}
                      >
                        <span>
                          <Space>
                            {menuItem.icon}
                            {menuItem.title}
                          </Space>
                        </span>
                      </Menu.Item>
                    ))}
                  </Menu>
            )}
              >
                {menuItems[0].title}
              </Dropdown.Button>
            )
        );
      },
      width: 100,
    },
  ];

  // eslint-disable-next-line react/no-unstable-nested-components
  function NoDataExtra() {
    return (
      <>
        <Row justify="center" style={{ marginTop: 25 }}>
          <Popover
            placement="top"
            content={clientEmail ? null : 'Client email is required to send E-Signature requests'}
          >
            <span>

              <Button
                type="primary"
                shape="round"
                icon={<PlusOutlined />}
                onClick={newESignatureRequestButtonOnClick}
                disabled={!clientEmail}
              >
                Create your first E-Signature
              </Button>
            </span>
          </Popover>
        </Row>
        <Row justify="center" style={{ marginTop: 10 }}>
          <Popover
            placement="top"
            content={clientEmail ? null : 'Client email is required to send document approvals'}
          >
            <span>
              <Button
                type="primary"
                shape="round"
                icon={<PlusOutlined />}
                onClick={documentApprovalRequestButtonOnClick}
                disabled={!clientEmail}
              >
                Create your first document approval
              </Button>
            </span>
          </Popover>
        </Row>
      </>
    );
  }

  const handleApprovalsComplete = () => {
    const today = new Date();
    upsertJobTracking({
      variables: {
        createJobTrackingInput: {
          taxYearInterviewId,
          approvalsCompletedAt: today,
        },
      },
      onCompleted: () => {
        message.success('Approvals marked completed.');
        dispatch({ type: 'UPDATE', payload: { approvalsCompletedAt: today } });
      },
      refetchQueries: [
        {
          query: GetClientsDocument,
        },
        {
          query: GetClientsByProgressStatusIdDocument,
          variables: {
            progressStatusId,
          },
        },
      ],
    });
  };

  const handleApprovalsNotComplete = () => {
    upsertJobTracking({
      variables: {
        createJobTrackingInput: {
          taxYearInterviewId,
          approvalsCompletedAt: null,
        },
      },
      onCompleted: () => {
        message.success('Approvals marked not completed.');
        dispatch({ type: 'UPDATE', payload: { approvalsCompletedAt: null } });
      },
    });
  };

  return (
    <div style={{ marginLeft: 5 }}>
      <Typography>
        <Paragraph style={{ marginTop: 30 }}>
          Upload any documents, such as E-File authorizations
          or final returns, that you would like your client to electronically review or sign.
        </Paragraph>
      </Typography>
      <Row gutter={[20, 10]} style={{ marginTop: 10, marginBottom: 15 }}>
        <Col>
          <Popover
            placement="top"
            content={clientEmail ? null : 'Client email is required to send E-Signature requests'}
          >
            <span>
              <Button
                type="default"
                icon={<PlusOutlined />}
                iconDirection="left"
                onClick={newESignatureRequestButtonOnClick}
                disabled={!clientEmail}
              >
                New E-Signature
              </Button>
            </span>
          </Popover>
        </Col>
        <Col>
          <Popover
            placement="top"
            content={clientEmail ? null : 'Client email is required to send document approvals'}
          >
            <span>
              <Button
                type="default"
                icon={<PlusOutlined />}
                iconDirection="left"
                onClick={documentApprovalRequestButtonOnClick}
                disabled={!clientEmail}
              >
                New Document Approval
              </Button>
            </span>
          </Popover>
        </Col>
        <Col>
          {!approvalsCompletedAt
            ? (
              <Button
                type="primary"
                onClick={handleApprovalsComplete}
                icon={<CheckCircleOutlined />}
                iconDirection="left"
                style={{
                  whiteSpace: 'normal',
                  height: 'auto',
                  textAlign: 'left',
                }}
              >
                Mark E-Signatures & Approvals Complete
              </Button>
            )
            : (
              <Button
                type="default"
                onClick={handleApprovalsNotComplete}
                icon={<UndoOutlined />}
                iconDirection="left"
                style={{
                  whiteSpace: 'normal',
                  height: 'auto',
                  textAlign: 'left',
                }}
              >
                Mark E-Signatures & Approvals Not Complete
              </Button>
            )}
        </Col>
      </Row>

      {
        fileRequests?.length > 0
          ? (
            <Table
              columns={columns}
              rowKey={(record: any) => record.id}
                    // locale={{emptyText: eSignatureTableCustomEmpty() }}
              noDataProps={{
                title: 'No documents sent yet',
                extra: <NoDataExtra />,
              }}
              dataSource={fileRequests || []}
              pagination={false}
              scroll={{ x: true, y: 320 }}
              loading={loadingFileRequests || loadingMarkComplete || loadingResendRequest || loadingRemoveFileRequest || loadingUpsertJobTracking}
            />
          )
          : (
            <Empty
              description="No documents sent yet"
            >
              {/* <NoDataExtra /> */}
            </Empty>
          )
      }

      <NewESignatureRequestModal
        isModalVisible={isESignatureRequestModalVisible}
        setIsModalVisible={setIsESignatureRequestModalVisible}
        mode={eSignatureApprovalMode}
        eSignatureAndApprovalState={eSignatureAndApprovalState}
        eSignatureAndApprovalDispatch={eSignatureAndApprovalDispatch}
        refetchFileRequests={refetchFileRequests}
      />

      <DocumentApprovalRequestModal
        isModalVisible={isDocApprovalRequestModalVisible}
        setIsModalVisible={setIsDocApprovalRequestModalVisible}
        mode={eSignatureApprovalMode}
        eSignatureAndApprovalState={eSignatureAndApprovalState}
        eSignatureAndApprovalDispatch={eSignatureAndApprovalDispatch}
        refetchFileRequests={refetchFileRequests}
      />
    </div>
  );
};

export default ESignatureAndApproval;
