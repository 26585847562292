import { FC, useCallback, useRef } from 'react';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';
import { useDrop, XYCoord } from 'react-dnd';
import SignatureFieldPostDropped from '../SignatureFieldPostDropped';
import actionCreators from '../action.creator';

interface DroppableContainerProps {
  children: any;
  className: string;
  thisPage: number;
  state: any,
  dispatch: any;
  signersArray: any[];
}

const DroppableContainer: FC<DroppableContainerProps> = ({
  children,
  className,
  thisPage,
  state,
  dispatch,
  signersArray,
}) => {
  const ref = useRef<HTMLDivElement>();

  const addDraggableSignatureField = (signatureField: any) => {
    // eslint-disable-next-line no-param-reassign
    signatureField.id = uuidv4();
    dispatch(actionCreators.update({
      newDraggableBoundarySignFields: [...state.newDraggableBoundarySignFields, signatureField],
    }));
  };

  const moveBox = useCallback(
    (signatureField) => {
      const index = signatureField.index ?? 0;
      const newCollection = update(state.newDraggableBoundarySignFields, {
        [index]: {
          $set: { ...signatureField },
        },
      });

      dispatch(actionCreators.update({
        newDraggableBoundarySignFields: newCollection,
      }));
    },
    [state.newDraggableBoundarySignFields, dispatch],
  );

  const updateSigner = (signatureFieldId: string, signerId: string) => {
    const copyOfNewDraggableBoundarySignFields = [...state.newDraggableBoundarySignFields];
    const index = copyOfNewDraggableBoundarySignFields.findIndex((v) => v.id === signatureFieldId);
    copyOfNewDraggableBoundarySignFields[index].signerId = signerId;

    dispatch(actionCreators.update({
      newDraggableBoundarySignFields: [...copyOfNewDraggableBoundarySignFields],
    }));
  };

  const updateTitle = (signatureFieldId: string, title: string) => {
    const copyOfNewDraggableBoundarySignFields = [...state.newDraggableBoundarySignFields];
    const index = copyOfNewDraggableBoundarySignFields.findIndex((v) => v.id === signatureFieldId);
    copyOfNewDraggableBoundarySignFields[index].title = title;

    // console.log('updateTitle - copyOfNewDraggableBoundarySignFields:', copyOfNewDraggableBoundarySignFields);
    dispatch(actionCreators.update({
      newDraggableBoundarySignFields: [...copyOfNewDraggableBoundarySignFields],
    }));
  };

  const [, drop] = useDrop(() => ({
    accept: ['SIGNATURE_FIELD', 'SIGNATURE_BOUNDARY_FIELD'],
    drop(item: any, monitor) {
      const {
        index, id, email, fieldType, fileApprovalId, signatureImage, completedAt, signerId,
      } = item;
      // console.log('fieldType:', fieldType);
      const offset = monitor.getSourceClientOffset() as XYCoord;
      const dropTargetXy = ref.current.getBoundingClientRect();
      const left = offset.x - dropTargetXy.left;
      const top = offset.y - dropTargetXy.top;
      const signatureField = {
        id,
        email,
        index,
        left,
        top,
        page: thisPage,
        fieldLeftPercentage: (left / dropTargetXy.width) * 100,
        fieldTopPercentage: (top / dropTargetXy.height) * 100,
        fieldWidthPercentage: (250 / dropTargetXy.width) * 100, // 250 is the width of the signature field (in pixels)
        fieldHeightPercentage: (32 / dropTargetXy.height) * 100, // 32 is the height of the signature field (in pixels)
        fieldType,
        fileApprovalId,
        signatureImage,
        completedAt,
        signerId,
      };
      if (id) {
        moveBox(signatureField);
      } else if (offset && ref.current) {
        addDraggableSignatureField(signatureField);
      }

      return {
        fieldType, left, top, index, id, fileApprovalId, signatureImage, completedAt, signerId,
      };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }), [moveBox, addDraggableSignatureField, thisPage]);

  const deleteSignatureFieldButtonOnClick = (index: number) => {
    // const removedUpdatedSignatureFileds = newDraggableBoundarySignFields.splice(index + 1, 1);
    const removedUpdatedSignatureFileds = state.newDraggableBoundarySignFields.filter(
      (_, i) => i !== index,
    );
    // console.log('removedUpdatedSignatureFileds: ', removedUpdatedSignatureFileds, 'index: ', index);
    dispatch(actionCreators.update({
      newDraggableBoundarySignFields: removedUpdatedSignatureFileds,
    }));
  };

  // console.log('state.newDraggableBoundarySignFields, fileApprovals', state.newDraggableBoundarySignFields, fileApprovals);

  return (
    <div ref={ref} style={{ position: 'relative', display: 'block' }}>
      <div
        ref={drop}
        className={className}
        style={{
          position: 'relative',
          display: 'block',
          border: '1px solid black',
          marginRight: 30,
        }}
      >
        {state.newDraggableBoundarySignFields.map((field: any, index: number) => {
          const {
            id, fieldType, left, top, page, title, signerId, signatureImage, fileApprovalId, completedAt, description,
          } = field;
          if (thisPage === page) {
            return (
              <SignatureFieldPostDropped
                id={id}
                deleteSignatureFieldButtonOnClick={deleteSignatureFieldButtonOnClick}
                updateSigner={updateSigner}
                updateTitle={updateTitle}
                index={index}
                componentName="SignatureFieldPostDropped"
                left={left}
                top={top}
                fieldType={fieldType}
                signersArray={signersArray}
                title={title}
                description={description}
                signerId={signerId}
                signatureImage={signatureImage}
                fileApprovalId={fileApprovalId}
                completedAt={completedAt}
                key={`${signerId}-${index}`}
              />
            );
          }
          return null;
        })}
        {children}
      </div>
    </div>
  );
};

export default DroppableContainer;
