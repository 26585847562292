/* eslint-disable quote-props */
/* eslint-disable max-len */
const esInternationalization = {
  translation: {
    'scheduleCall.error': 'No hay más fechas disponibles para este día.',
    'scheduleCall.callScheduled': 'Llamada programada',
    'scheduleCall.schedule': 'Programar',
    'scheduleCall.selectOption': 'Seleccione una opción',
    'scheduleCall.duration': 'Duración',
    'scheduleCall.date': 'Fecha',
    'scheduleCall.next': 'Siguiente',
    'scheduleCall.skipNotes': 'Saltar notas',
    'scheduleCall.notes': 'Notas',
    'scheduleCall.scheduleCall': 'Programar llamada',
    'chat.downloadFile.error': 'Este archivo está dañado o se ha eliminado',
    'chat.incomingCall.accept': 'Aceptar',
    'chat.incomingCall.decline': 'Rechazar',
    'chat.incomingCall.title': 'Llamada Entrante',
    'chat.chatInput.uploadLabel': 'Adjuntar Archivo',
    'chat.chatInput.emoji': 'Emoji',
    'chat.chatInput.fileUploaded': 'cargado exitosamente.',
    'chat.chatInput.fileNotUploaded': 'falló al cargar.',
    'chat.chatInput.selectProject': 'Seleccione un proyecto para cargar un archivo.',
    'chat.chatInput.sendMessage': 'Enviar Mensaje',
    'chat.userActive': 'Activo Ahora',
    'chat.messagesList.missedCall': 'Llamada rechazada',
    'videocall.call.meetingWith': 'Reunión con',
    'videocall.call.callScheduledFor': 'Esta llamada está programada para {{date}} a las {{timeH}}:{{timeM}} puedes acceder hasta ese momento.',
    'call.callErrors.description.cleanMeeting':
      'Por favor, elija su configuración de audio y video.',
    'call.callErrors.title.userNotInvited': 'Usuario no invitado a hablar',
    'call.callErrors.description.userNotInvited': 'No estás invitado a esta llamada.',
    'call.callErrors.title.mustLogin': 'El usuario no ha iniciado sesión',
    'call.callErrors.description.mustLogin': 'Inicie sesión para ingresar a esta llamada.',
    'call.callErrors.button.mustLogin': 'Iniciar sesión.',
    'call.callErrors.title.earlierAccess': 'Esta llamada aún no está disponible',
    'call.callErrors.title.laterAccess': 'Esta llamada ha terminado',
    'call.callErrors.description.laterAccess': 'Aún puedes acceder a los mensajes de chat.',
    'call.callErrors.title.callCancelled': 'Esta videollamada fue cancelada',
    'call.callErrors.description.callCancelled':
      'Esta videollamada fue cancelada por uno o más de los participantes.',
    'call.callErrors.title.noCam': 'Permiso de cámara denegado',
    'call.callErrors.description.noCam':
      'No ha concedido ni bloqueado el acceso a la cámara. Actualice la configuración de su navegador para permitir el acceso.',
    'call.callErrors.title.noMic': 'Permiso de micrófono denegado',
    'call.callErrors.description.noMic':
      'No ha concedido ni bloqueado el acceso al micrófono. Actualice la configuración de su navegador para permitir el acceso.',
    'call.callErrors.title.callExpired': 'Esta llamada ya venció',
    'call.callErrors.description.callExpired':
      'Si cree que se trata de un error, comuníquese con su preparador de impuestos.',
    'call.join': 'Únete ahora',
    'videocall.me': 'Yo',
    'videocall.callMenu.recordSession': 'Grabar Sesión',
    'videocall.callMenu.stopRecording': 'Dejar de Grabar',
    'client.onboarding.signin': 'Iniciar Sesion',
    'client.onboarding.title': 'Registrese para que {{name}} prepare sus impuestos',
    'client.onboarding.subtitle': 'Proporciona información básica para empezar',
    'client.onboarding.welcome': 'Bienvenido a nuestro portal seguro',
    'client.onboarding.alreadyClient': '¿Ya es cliente?',
    'client.onboarding.ours': 'La nuestra es una plataforma en línea que se utiliza para compartir fácilmente y de forma segura la información con su preparador de impuestos.',
    'client.onboarding.instructions': 'Está a un paso de trabajar con su preparador de impuestos completamente en línea, pero primero necesita establecer una contraseña para su cuenta. Esto le permitirá volver a su cuenta más tarde accediendo a nuestro portal con su dirección de correo electrónico.',
    'client.onboarding.agreeTerms': 'Estoy de acuerdo con las ',
    'client.onboarding.termsOfUse': 'Condiciones de uso',
    'client.onboarding.getStarted': 'Empezar a trabajar',
    'client.onboarding.success': 'Su cuenta ha sido activada con éxito. Ahora puede utilizar nuestra plataforma iniciando sesión',
    'client.onboarding.failure': 'Algo ha ido mal durante el proceso de activación de la cuenta. Por favor, asegúrese de que los datos que ha introducido son correctos. Si el problema persiste, póngase en contacto con el preparador fiscal que le envió la invitación.',
    'client.onboarding.fields.email': 'Correo electrónico',
    'client.onboarding.fields.firstName': 'Nombre',
    'client.onboarding.fields.lastName': 'Apellido',
    'client.onboarding.fields.birthday': 'Fecha de Nacimiento',
    'client.onboarding.fields.landline': 'Telefono Fijo',
    'client.onboarding.fields.mobileNumber': 'Telefono Móvil',
    'client.onboarding.fields.workNumber': 'Telefono De Trabajo',
    'client.onboarding.fields.createPassword': 'Crea Una Contraseña',
    'client.onboarding.fields.confirmPassword': 'Confirma Tu Contraseña',
    'client.onboarding.fields.taxYear': '¿Para qué año fiscal es esto?',
    'client.onboarding.fields.job': '¿Para qué sera la declaración de impuestos?',
    'client.onboarding.fields.language': 'Idioma',
    'client.onboarding.fields.validate.email': 'El correo electrónico es requerido',
    'client.onboarding.fields.validate.invalidEmail': 'Correo electrónico invalido',
    'client.onboarding.fields.validate.firstName': 'El Nombre es requerido',
    'client.onboarding.fields.validate.lastName': 'El Apellido es requerido',
    'client.onboarding.fields.validate.birthday': 'La Fecha de Nacimiento es requerida',
    'client.onboarding.fields.validate.mobileNumber': 'El Telefono Móvil es requerido',
    'client.onboarding.fields.validate.createPassword': 'La Contraseña es requerida',
    'client.onboarding.fields.validate.createPassword2': 'La contraseña debe tener al menos 8 caracteres',
    'client.onboarding.fields.validate.createPassword3': 'La Contraseña debe contener al menos una letra minúscula, una mayúscula, un número del 0 al 9 y uno de estos caracteres: !@#$%^&*',
    'client.onboarding.fields.validate.confirmPassword': 'Confirmar la Contraseña es requerido',
    'client.onboarding.fields.validate.confirmPassword2': 'La Contraseña debe coincidir',
    'client.onboarding.fields.validate.taxYear': 'El año fiscal es requerido',
    'client.onboarding.fields.validate.job': 'Tipo de declaracion de impuestos es requerido',
  },
  emojiMart: {
    search: 'Buscar',
    clear: 'Limpiar', // Accessible label on "clear" button
    notfound: 'No se encontró ningún emoji',
    skintext: 'Elija su tono de piel predeterminado',
    categories: {
      search: 'Resultados de la búsqueda',
      recent: 'Usados frecuentemente',
      smileys: 'Emoticonos y emoción',
      people: 'Personas y cuerpo',
      nature: 'Animales y naturaleza',
      foods: 'Comida y bebida',
      activity: 'Actividad',
      places: 'Viajes y lugares',
      objects: 'Objectos',
      symbols: 'Simbolos',
      flags: 'Banderas',
      custom: 'Personalizado',
    },
    categorieslabel: 'Categorías de emoji', // Accessible title for the list of categories
    skintones: {
      1: 'Default Skin Tone',
      2: 'Light Skin Tone',
      3: 'Medium-Light Skin Tone',
      4: 'Medium Skin Tone',
      5: 'Medium-Dark Skin Tone',
      6: 'Dark Skin Tone',
    },
  },
};
export default esInternationalization;
