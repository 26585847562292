import { renderTime } from '~src/components/helpers/time';
import { ReviewStatus, TaskEntity, TaskType } from '~src/graphql';

const getDocumentStatus = (item: any) => {
  if (item.filesCompletedAt) {
    return 'Completed!';
  }

  const documentTasks = (item.tasks ?? []).filter((task: TaskEntity) => task.taskType === TaskType?.Documents
    || task.taskType === TaskType?.RequestDocuments);
  const inCompleteDocumentTasks = documentTasks.filter((task: TaskEntity) => !task.completedAt);

  if (documentTasks?.length > 0 && inCompleteDocumentTasks?.length === 0) {
    return 'Client updated info';
  }

  if (documentTasks?.length > 0 && inCompleteDocumentTasks?.length > 0) {
    return 'Waiting for client response';
  }

  return 'Documents not found';
};

const getPaymentStatus = (item: any) => {
  if (item.paymentsCompletedAt) {
    return 'Completed!';
  }

  const invoiceTasks = (item.tasks ?? []).filter((task: TaskEntity) => task.taskType === TaskType.Payments);
  const inCompleteInvoiceTasks = invoiceTasks.filter((task: TaskEntity) => !task.completedAt);

  if (invoiceTasks.length > 0 && inCompleteInvoiceTasks.length === 0) {
    return 'Client updated info';
  }

  if (invoiceTasks.length > 0 && inCompleteInvoiceTasks.length > 0) {
    return 'Waiting for client payment';
  }

  return 'Not started';
};

const getInterviewStatus = (item: any) => {
  if (item.interviewCompletedAt) {
    return 'Completed!';
  }

  const interviewTasks = (item.tasks ?? []).filter((task: TaskEntity) => task.taskType === TaskType.Interview);
  const inCompleteInterviewTasks = interviewTasks?.filter((task: TaskEntity) => !task.completedAt);
  if (interviewTasks?.length > 0 && inCompleteInterviewTasks?.length === 0) {
    return 'Client updated info';
  }

  if (interviewTasks?.length > 0 && inCompleteInterviewTasks?.length > 0) {
    return 'Waiting for client response';
  }

  return 'Not started';
};

export const getReviewStatus = (item) => {
  if (item.reviewStatus === ReviewStatus?.Accepted) {
    return 'Completed!';
  }

  if (item.reviewStatus === ReviewStatus?.In) {
    return 'In review';
  }

  if (item.reviewStatus === ReviewStatus?.Rejected) {
    return 'Rejected';
  }

  return 'No review';
};

export const getApprovalStatus = (item) => {
  const approvalTasks = (item.tasks ?? []).filter((task: TaskEntity) => task.taskType === TaskType?.Signatures);
  const inCompleteApprovalTasks = approvalTasks?.filter((task: TaskEntity) => !task.completedAt);

  if (item.approvalsCompletedAt) {
    return 'Completed!';
  }

  if (approvalTasks?.length > 0 && inCompleteApprovalTasks?.length === 0) {
    return 'Client updated info';
  }

  if (approvalTasks?.length > 0 && inCompleteApprovalTasks?.length > 0) {
    return 'Waiting for client response';
  }

  return 'Not started';
};

export const clientDataToCsvData = (data: any) => {
  const titles = [[
    'Name',
    'Email',
    'Phone',
    'Recent Tax Year',
    'Job Type',
    'Activation Sent',
    'Documents',
    'Notes',
    'Client Payment Status',
    'Referred By',
    'Preparer',
    'Reviewer',
  ]];
  return titles.concat(data.map((item) => {
    const result = [
      `${item.firstName} ${item.lastName}`,
      item.email,
      item.phone?.value,
      item.taxYear,
      item.jobType,
      item.invitationEmailSent,
      getDocumentStatus(item),
      item.notes,
      getPaymentStatus(item),
      item.referredBy,
      item.preparedName ?? 'Not assigned',
      item.reviewedName ?? 'Not assigned',
    ];
    return result;
  }));
};

export const jobDataToCsvData = (data: any) => {
  const titles = [[
    'Name',
    'TaxYear',
    'Job Type',
    'Email',
    'Time in Progress',
    'Activation Sent',
    'Documents',
    'Notes',
    'Client Interview Status',
    'Client Payment Status',
    'Review Status',
    'Client Document Approval Status',
    'Referred By',
    'Preparer',
    'Reviewer',
  ]];
  return titles.concat(data.map((item) => [
    `${item.firstName} ${item.lastName}`,
    item.taxYear,
    item.jobType,
    item.email,
    renderTime(item.inProgressTime),
    item.invitationEmailSent,
    getDocumentStatus(item),
    item.notes,
    getInterviewStatus(item),
    getPaymentStatus(item),
    getReviewStatus(item),
    getApprovalStatus(item),
    item.referredBy,
    item.preparedName,
    item.reviewedName,
  ]));
};

export const csvDataToTextData = (csvData) => csvData.map(
  (item) => item.join('   '),
).join('\n');
