/* eslint-disable camelcase */
import moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Form,
  Input,
  DatePicker,
  Radio,
  Typography,
  Skeleton,
} from 'taxaroo-ui';
import { BusinessType } from '~src/graphql';
import { useGetFirmOperatingAsQuery } from '~src/graphql/queries/getStarted';
import { useGetUserInformationQuery } from '~src/graphql/queries/settings';
import { useAppDispatch, useAppSelector } from '~src/redux/hooks';
import {
  setPersonalInfo,
  INITIAL_BUSINESS_INFO,
  INITIAL_BUSINESS_ADDRESS,
  INITIAL_PERSONAL_ADDRESS,
  RegisterStep, selectRegisterState, setPersonalAddress, setBusinessAddress, setBusinessInfo,
} from '~src/redux/slices/registerSlice';
import CardNav from '../CardNav.tsx';

const { Title } = Typography;

interface PersonalInfoProps {
  setStep: (arg1: keyof typeof RegisterStep) => void;
  signUpFlow: boolean;
}
function PersonalInfo({
  setStep,
  signUpFlow,
}: PersonalInfoProps) {
  const dispatch = useAppDispatch();
  const {
    personalInfo: {
      the_em,
      the_pass,
      the_first_name,
      the_last_name,
      the_confirm_pass,
      the_operating_as,
      the_date_of_birth,
    },
  } = useAppSelector(selectRegisterState);
  const { firmAccountId, userInformationId, userRole } = useAppSelector((state) => state.session);
  const [form] = Form.useForm();
  const [disabledNext, setDisabledNext] = useState<boolean>(true);
  const [loadingUserInfo, setLoadingUserInfo] = useState<boolean>(!signUpFlow);
  const [loadingOperatingAs, setLoadingOperatingAs] = useState<boolean>(!signUpFlow);

  const [searchParams, setSearchParams] = useSearchParams();
  const initialEmail = searchParams.get('email');

  // graphql
  // query to fetch existing user information
  const { data: userData } = useGetUserInformationQuery({
    variables: {
      id: userInformationId,
    },
  });
  const userInformation = userData?.UserInformation;
  const { data: firmData } = useGetFirmOperatingAsQuery({
    variables: {
      id: firmAccountId,
    },
  });
  const operatingAs = firmData?.FirmAccountById?.businessType;

  // useEffect(() => {
  //   setLoadingUserInfo(false);
  //   setLoadingOperatingAs(false);
  // }, []);

  // set the userInformation initial values if any are present
  useEffect(() => {
    console.log('in useEffect');
    if (userInformation && form && dispatch) {
      console.log('userinformation:', userInformation);
      const {
        firstName,
        lastName,
        birthday: dateOfBirth,
        Address,
      } = userInformation;
      // if one of these is present, then the user has already filled out this step
      if (!the_first_name && !the_last_name && !the_date_of_birth) {
        dispatch(setPersonalInfo({
          the_first_name: firstName,
          the_last_name: lastName,
          the_date_of_birth: moment(dateOfBirth).toISOString(),
        }));
        dispatch(setPersonalAddress({
          the_id: Address?.[0]?.id,
          the_line1: Address?.[0]?.lineOne,
          the_line2: Address?.[0]?.lineTwo,
          the_city: Address?.[0]?.city,
          the_state: Address?.[0]?.state,
          the_zip_code: Address?.[0]?.zip,
        }));
      }
      setLoadingUserInfo(false);
      if (firstName && lastName && dateOfBirth) {
        setDisabledNext(false);
      }
    }
  }, [userInformation, form, dispatch]);

  // set the operatingAs initial values if any are present
  useEffect(() => {
    if (operatingAs && form && dispatch) {
      // if one of these is present, then the user has already filled out this step
      if (!the_operating_as) {
        const operatingAsValue = operatingAs === BusinessType.Individual ? 'Individual' : 'Company';
        dispatch(setPersonalInfo({
          the_operating_as: operatingAsValue,
        }));
        setLoadingOperatingAs(false);
      } else {
        setLoadingOperatingAs(false);
      }
    }
  }, [operatingAs, form, dispatch]);

  const onSubmit = useCallback((values) => {
    // first dispatch the values to the redux store
    dispatch(setPersonalInfo({
      the_em: values.the_em,
      the_pass: values.the_pass,
      the_first_name: values.the_first_name,
      the_last_name: values.the_last_name,
      the_confirm_pass: values.the_confirm_pass,
      the_operating_as: values.the_operating_as,
      the_date_of_birth: values.the_date_of_birth.format('MM/DD/YYYY'),
    }));
    // then move to the next step
    if ((userRole !== 'TAX_PREPARER' && !signUpFlow)) { // it's staff
      setStep('PERSONAL_ADDRESS');
    } else if (values.the_operating_as === 'Individual') {
      setStep('PERSONAL_ADDRESS');
    } else if (values.the_operating_as === 'Company') {
      setStep('BUSINESS_INFO');
    }
  }, [setStep, dispatch, setPersonalInfo]);

  // const handleCancel = useCallback(() => {
  //   form.resetFields();
  // }, [form]);

  const handleFormChange = (target) => {
    const [{ name: [name], value }] = target;
    const hasErrors = form.getFieldsError().some(({ errors }) => errors?.length);
    const currentFirstName = form.getFieldValue('the_first_name');
    const currentLastName = form.getFieldValue('the_last_name');
    const currentDateOfBirth = form.getFieldValue('the_date_of_birth');
    const currentOperatingAs = form.getFieldValue('the_operating_as');
    const currentEmail = form.getFieldValue('the_em');
    const currentPassword = form.getFieldValue('the_pass');
    const currentConfirmPassword = form.getFieldValue('the_confirm_pass');
    const noValues = [
      currentFirstName,
      currentLastName,
      currentDateOfBirth,
      currentOperatingAs,
    ].concat(signUpFlow ? [
      currentEmail,
      currentPassword,
      currentConfirmPassword,
    ] : []).filter(
      (fieldValue) => (typeof fieldValue === 'string' && !fieldValue?.length) || (typeof fieldValue === 'object' && !fieldValue),
    );
    setDisabledNext(hasErrors || noValues?.length > 0);
  };

  useEffect(() => {
    if (the_first_name?.length
       && the_last_name?.length
       && the_date_of_birth?.length
       && the_operating_as?.length
       && (the_em?.length || !signUpFlow)
       && (the_pass?.length || !signUpFlow)
       && (the_confirm_pass?.length || !signUpFlow)
    ) {
      setDisabledNext(false);
    }

    return () => {
      form.resetFields();
    };
  }, [the_first_name, the_last_name, the_date_of_birth, the_operating_as, the_em, the_pass, the_confirm_pass, signUpFlow, form]);

  if (loadingUserInfo || loadingOperatingAs) {
    return <Skeleton paragraph={{ rows: 8 }} />;
  }

  return (
    <Form
      style={{ width: '100%' }}
      layout="vertical"
      form={form}
      initialValues={{
        the_em: initialEmail || the_em,
        the_pass,
        the_last_name,
        the_first_name,
        the_operating_as,
        the_confirm_pass,
        the_date_of_birth: the_date_of_birth?.length ? moment(the_date_of_birth) : null,
      }}
      onFinish={onSubmit}
      onFieldsChange={handleFormChange}
        // eslint-disable-next-line no-template-curly-in-string
      validateMessages={{ required: '${label} is required' }}
      key="personal-info"
    >
      {/* <Title level={4}>Personal Information</Title> */}
      <Form.Item
        name="the_first_name"
        label="First Name"
        rules={[{ required: true }]}
        key="first-name"
      >
        <Input key="first-name-input" autoComplete="given-name" placeholder="First Name" />
      </Form.Item>
      <Form.Item
        name="the_last_name"
        label="Last Name"
        rules={[{ required: true }]}
        key="last-name"
      >
        <Input autoComplete="family-name" placeholder="Last Name" />
      </Form.Item>
      <Form.Item
        style={{
          flex: 1,
        }}
        name="the_date_of_birth"
        label="Date of birth"
        rules={[{ required: true }]}
        key="date-of-birth"
      >
        <DatePicker
          style={{
            width: '100%',
          }}
          onChange={(value: any) => {
            form.setFieldsValue({ dateOfBirth: value });
          }}
          format="MM/DD/YYYY"
          autoComplete="bday"
        />
      </Form.Item>
      {signUpFlow && (
        <>
          <Form.Item
            name="the_em"
            label="Email"
            rules={[
              { required: true, message: 'Email is required' },
              { type: 'email', message: 'Invalid email' },
            ]}
            style={{ display: initialEmail ? 'none' : 'block' }}
          >
            <Input autoComplete="email" placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="the_pass"
            label="Password"
            rules={[
              {
                required: true,
              },
              {
                min: 6,
                message: 'The password must be at least 6 characters of length',
              },
              {
                pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                message: 'The password should include at least one lowercase letter,\n one uppercase letter \n and one number and one of these symbols: !@#$%^&*.',
              }]}
          >
            <Input.Password autoComplete="new-password" placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="the_confirm_pass"
            label="Confirm Password"
            dependencies={['the_pass']}
            rules={[{ required: true }, ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('the_pass') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            })]}
          >
            <Input.Password autoComplete="new-password" placeholder="Confirm Password" />
          </Form.Item>
        </>
      )}
      {(userRole === 'TAX_PREPARER' || signUpFlow) && (
        <Form.Item
          name="the_operating_as"
          label="I am operating as"
          rules={[{ required: true }]}
          style={{
            paddingLeft: 5,
          }}
          key="operating-as"
        >
          <Radio.Group>
            <Radio value="Individual">An individual tax preparer</Radio>
            <Radio value="Company">A business</Radio>
          </Radio.Group>
        </Form.Item>
      )}
      <CardNav
        showBack={false}
        showSkip={false}
        disabled={disabledNext}
      />
    </Form>
  // </Card>
  );
}

export default PersonalInfo;
