import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePaymentsMutationVariables = Types.Exact<{
  createPaymentsInput: Types.CreatePaymentsInput;
}>;


export type CreatePaymentsMutation = { __typename?: 'Mutation', CreatePayments: { __typename?: 'PaymentsEntity', id: string } };

export type SendInvoiceReminderMutationVariables = Types.Exact<{
  paymentId: Types.Scalars['String'];
}>;


export type SendInvoiceReminderMutation = { __typename?: 'Mutation', SendInvoiceReminder: string };

export type UpdatePaymentMutationVariables = Types.Exact<{
  updatePaymentsInput: Types.UpdatePaymentsInput;
}>;


export type UpdatePaymentMutation = { __typename?: 'Mutation', updatePayments: { __typename?: 'PaymentsEntity', id: string, referralFee?: number | null, preparerFee?: number | null, discountApplied?: number | null, totalDollarAmount?: number | null, paymentProcessorFee?: number | null, entityId?: string | null, taskId?: string | null, taxYearInterviewId?: string | null, paymentProcessorId?: string | null, paidOffline?: boolean | null, completedAt?: any | null, deletedAt?: any | null, status?: string | null, requestAt?: any | null, promisedAt?: any | null, paymentIntentId?: string | null, createAt?: any | null, updateAt?: any | null, Invoices?: Array<{ __typename?: 'InvoicesEntity', id: string, description: string, priceEach: number, quantity: number, paymentId: string, createAt: any, updateAt: any }> | null, Entity?: { __typename?: 'Entity', Users?: { __typename?: 'UserEntity', UserInformation?: { __typename?: 'UserInformationEntity', firstName: string, lastName: string, Address?: Array<{ __typename?: 'AddressEntity', id: string, lineOne: string, lineTwo?: string | null, city: string, state: string, zip: string, type: Types.AddressType }> | null, Phones?: Array<{ __typename?: 'PhonesEntity', id: string, value: string, type: Types.PhoneType }> | null } | null } | null } | null } };

export type CreatePaymentMethodMutationVariables = Types.Exact<{
  customerId: Types.Scalars['String'];
  createStripePaymentMethodInput: Types.CreateStripePaymentMethodInput;
}>;


export type CreatePaymentMethodMutation = { __typename?: 'Mutation', createPaymentMethod: { __typename?: 'StripeCardPaymentMethodEntity', id?: string | null } };

export type UpdatePaymentWithInvoicesMutationVariables = Types.Exact<{
  updatePaymentsInput: Types.UpdatePaymentWithInvoicesInput;
}>;


export type UpdatePaymentWithInvoicesMutation = { __typename?: 'Mutation', updatePaymentWithInvoices: { __typename?: 'PaymentsEntity', id: string, referralFee?: number | null, preparerFee?: number | null, discountApplied?: number | null, totalDollarAmount?: number | null, paymentProcessorFee?: number | null, entityId?: string | null, taskId?: string | null, taxYearInterviewId?: string | null, paymentProcessorId?: string | null, paidOffline?: boolean | null, completedAt?: any | null, deletedAt?: any | null, status?: string | null, requestAt?: any | null, promisedAt?: any | null, paymentIntentId?: string | null, createAt?: any | null, updateAt?: any | null } };

export type DeletePaymentWithInvoicesMutationVariables = Types.Exact<{
  paymentId: Types.Scalars['String'];
}>;


export type DeletePaymentWithInvoicesMutation = { __typename?: 'Mutation', removePayment: string };


export const CreatePaymentsDocument = gql`
    mutation CreatePayments($createPaymentsInput: CreatePaymentsInput!) {
  CreatePayments(createPaymentsInput: $createPaymentsInput) {
    id
  }
}
    `;
export type CreatePaymentsMutationFn = Apollo.MutationFunction<CreatePaymentsMutation, CreatePaymentsMutationVariables>;

/**
 * __useCreatePaymentsMutation__
 *
 * To run a mutation, you first call `useCreatePaymentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentsMutation, { data, loading, error }] = useCreatePaymentsMutation({
 *   variables: {
 *      createPaymentsInput: // value for 'createPaymentsInput'
 *   },
 * });
 */
export function useCreatePaymentsMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentsMutation, CreatePaymentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentsMutation, CreatePaymentsMutationVariables>(CreatePaymentsDocument, options);
      }
export type CreatePaymentsMutationHookResult = ReturnType<typeof useCreatePaymentsMutation>;
export type CreatePaymentsMutationResult = Apollo.MutationResult<CreatePaymentsMutation>;
export type CreatePaymentsMutationOptions = Apollo.BaseMutationOptions<CreatePaymentsMutation, CreatePaymentsMutationVariables>;
export const SendInvoiceReminderDocument = gql`
    mutation SendInvoiceReminder($paymentId: String!) {
  SendInvoiceReminder(paymentId: $paymentId)
}
    `;
export type SendInvoiceReminderMutationFn = Apollo.MutationFunction<SendInvoiceReminderMutation, SendInvoiceReminderMutationVariables>;

/**
 * __useSendInvoiceReminderMutation__
 *
 * To run a mutation, you first call `useSendInvoiceReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvoiceReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvoiceReminderMutation, { data, loading, error }] = useSendInvoiceReminderMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useSendInvoiceReminderMutation(baseOptions?: Apollo.MutationHookOptions<SendInvoiceReminderMutation, SendInvoiceReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInvoiceReminderMutation, SendInvoiceReminderMutationVariables>(SendInvoiceReminderDocument, options);
      }
export type SendInvoiceReminderMutationHookResult = ReturnType<typeof useSendInvoiceReminderMutation>;
export type SendInvoiceReminderMutationResult = Apollo.MutationResult<SendInvoiceReminderMutation>;
export type SendInvoiceReminderMutationOptions = Apollo.BaseMutationOptions<SendInvoiceReminderMutation, SendInvoiceReminderMutationVariables>;
export const UpdatePaymentDocument = gql`
    mutation UpdatePayment($updatePaymentsInput: UpdatePaymentsInput!) {
  updatePayments(updatePaymentsInput: $updatePaymentsInput) {
    id
    referralFee
    preparerFee
    discountApplied
    totalDollarAmount
    paymentProcessorFee
    entityId
    taskId
    taxYearInterviewId
    paymentProcessorId
    paidOffline
    completedAt
    deletedAt
    status
    requestAt
    promisedAt
    paymentIntentId
    createAt
    updateAt
    Invoices {
      id
      description
      priceEach
      quantity
      paymentId
      createAt
      updateAt
    }
    Entity {
      Users {
        UserInformation {
          firstName
          lastName
          Address {
            id
            lineOne
            lineTwo
            city
            state
            zip
            type
          }
          Phones {
            id
            value
            type
          }
        }
      }
    }
  }
}
    `;
export type UpdatePaymentMutationFn = Apollo.MutationFunction<UpdatePaymentMutation, UpdatePaymentMutationVariables>;

/**
 * __useUpdatePaymentMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMutation, { data, loading, error }] = useUpdatePaymentMutation({
 *   variables: {
 *      updatePaymentsInput: // value for 'updatePaymentsInput'
 *   },
 * });
 */
export function useUpdatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentMutation, UpdatePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentMutation, UpdatePaymentMutationVariables>(UpdatePaymentDocument, options);
      }
export type UpdatePaymentMutationHookResult = ReturnType<typeof useUpdatePaymentMutation>;
export type UpdatePaymentMutationResult = Apollo.MutationResult<UpdatePaymentMutation>;
export type UpdatePaymentMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentMutation, UpdatePaymentMutationVariables>;
export const CreatePaymentMethodDocument = gql`
    mutation createPaymentMethod($customerId: String!, $createStripePaymentMethodInput: CreateStripePaymentMethodInput!) {
  createPaymentMethod(
    customerId: $customerId
    createStripePaymentMethodInput: $createStripePaymentMethodInput
  ) {
    id
  }
}
    `;
export type CreatePaymentMethodMutationFn = Apollo.MutationFunction<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>;

/**
 * __useCreatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMethodMutation, { data, loading, error }] = useCreatePaymentMethodMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      createStripePaymentMethodInput: // value for 'createStripePaymentMethodInput'
 *   },
 * });
 */
export function useCreatePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>(CreatePaymentMethodDocument, options);
      }
export type CreatePaymentMethodMutationHookResult = ReturnType<typeof useCreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationResult = Apollo.MutationResult<CreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>;
export const UpdatePaymentWithInvoicesDocument = gql`
    mutation updatePaymentWithInvoices($updatePaymentsInput: UpdatePaymentWithInvoicesInput!) {
  updatePaymentWithInvoices(updatePaymentsInput: $updatePaymentsInput) {
    id
    referralFee
    preparerFee
    discountApplied
    totalDollarAmount
    paymentProcessorFee
    entityId
    taskId
    taxYearInterviewId
    paymentProcessorId
    paidOffline
    completedAt
    deletedAt
    status
    requestAt
    promisedAt
    paymentIntentId
    createAt
    updateAt
  }
}
    `;
export type UpdatePaymentWithInvoicesMutationFn = Apollo.MutationFunction<UpdatePaymentWithInvoicesMutation, UpdatePaymentWithInvoicesMutationVariables>;

/**
 * __useUpdatePaymentWithInvoicesMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentWithInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentWithInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentWithInvoicesMutation, { data, loading, error }] = useUpdatePaymentWithInvoicesMutation({
 *   variables: {
 *      updatePaymentsInput: // value for 'updatePaymentsInput'
 *   },
 * });
 */
export function useUpdatePaymentWithInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentWithInvoicesMutation, UpdatePaymentWithInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentWithInvoicesMutation, UpdatePaymentWithInvoicesMutationVariables>(UpdatePaymentWithInvoicesDocument, options);
      }
export type UpdatePaymentWithInvoicesMutationHookResult = ReturnType<typeof useUpdatePaymentWithInvoicesMutation>;
export type UpdatePaymentWithInvoicesMutationResult = Apollo.MutationResult<UpdatePaymentWithInvoicesMutation>;
export type UpdatePaymentWithInvoicesMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentWithInvoicesMutation, UpdatePaymentWithInvoicesMutationVariables>;
export const DeletePaymentWithInvoicesDocument = gql`
    mutation deletePaymentWithInvoices($paymentId: String!) {
  removePayment(id: $paymentId)
}
    `;
export type DeletePaymentWithInvoicesMutationFn = Apollo.MutationFunction<DeletePaymentWithInvoicesMutation, DeletePaymentWithInvoicesMutationVariables>;

/**
 * __useDeletePaymentWithInvoicesMutation__
 *
 * To run a mutation, you first call `useDeletePaymentWithInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentWithInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentWithInvoicesMutation, { data, loading, error }] = useDeletePaymentWithInvoicesMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useDeletePaymentWithInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<DeletePaymentWithInvoicesMutation, DeletePaymentWithInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePaymentWithInvoicesMutation, DeletePaymentWithInvoicesMutationVariables>(DeletePaymentWithInvoicesDocument, options);
      }
export type DeletePaymentWithInvoicesMutationHookResult = ReturnType<typeof useDeletePaymentWithInvoicesMutation>;
export type DeletePaymentWithInvoicesMutationResult = Apollo.MutationResult<DeletePaymentWithInvoicesMutation>;
export type DeletePaymentWithInvoicesMutationOptions = Apollo.BaseMutationOptions<DeletePaymentWithInvoicesMutation, DeletePaymentWithInvoicesMutationVariables>;
export const namedOperations = {
  Mutation: {
    CreatePayments: 'CreatePayments',
    SendInvoiceReminder: 'SendInvoiceReminder',
    UpdatePayment: 'UpdatePayment',
    createPaymentMethod: 'createPaymentMethod',
    updatePaymentWithInvoices: 'updatePaymentWithInvoices',
    deletePaymentWithInvoices: 'deletePaymentWithInvoices'
  }
}