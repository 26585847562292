import { useEffect, useState } from 'react';
import {
  Menu, Layout, Icons, Button, Grid, message,
} from 'taxaroo-ui';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PusherClient } from '~src/modules/PusherClient';
import { useAppDispatch, useAppSelector } from '~src/redux/hooks';
import { resetChat } from '~src/redux/slices/chatSlice';
import { resetClientData } from '~src/redux/slices/clientSlice';
import { resetInterviews } from '~src/redux/slices/interviewsSlice';
import Logo from '~src/assets/images/taxaroo_logo.svg';
import { resetSession, selectSession, setPreviousAccessToken } from '~src/redux/slices/sessionSlice';
import { useReturnToAccountMutation } from '~src/graphql/mutations/clients';
import { UserFirmAccountEntity } from '~src/graphql';
import { loginAsPreparer } from '~src/pages/Login/Login';
import {
  setIdentityFromStorage, trackSignOut, trackCustomerIoEvent,
  trackPageView,
  CUSTOMER_IO_EVENTS,
} from '~src/components/helpers/customer.io';
import { hamburgerMenuOpen } from '../../../cache';
import AddClientModal from '../../molecules/AddClientModal';
import ImportClientsModal from '../../molecules/ImportClientsModal';
import HamburgerMenu from '../HamburgerMenu';
import AddSubMenu from './AddSubMenu';
import AvatarSubMenu from './AvatarSubMenu';
import InviteSubMenu from './InviteSubMenu';
import * as styles from './styles.module.css';
import AnnouncementBar from '../AnnouncementBar';

const { useBreakpoint } = Grid;
const { MenuOutlined } = Icons;
const { Header } = Layout;

function TopHeader() {
  const { accessToken } = useAppSelector(selectSession);
  const pusher = new PusherClient(accessToken);
  const screens = useBreakpoint();
  // const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
  const [shrink, setShrink] = useState(false);
  const [isAddClientModalVisible, setIsAddClientModalVisible] = useState(false);
  const [isImportClientsModalVisible, setIsImportClientsModalVisible] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  if (accessToken) {
    setIdentityFromStorage();
  }

  const [returnToAccount] = useReturnToAccountMutation({
    onError: (err) => message.error(`Return to account error: ${err.message}`, 5),
  });

  const location = useLocation();
  let { search } = location;
  search = search.replace('?', '');
  let action = null;
  try {
    const paramsObj = JSON.parse(`{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`);
    action = paramsObj.action;
  } catch (error) { /* Pass */ }

  useEffect(() => {
    trackPageView();
  }, [location.pathname]);

  useEffect(() => {
    if (action === 'addClient') {
      setIsAddClientModalVisible(true);
    }
    if (action === 'importClients') {
      setIsImportClientsModalVisible(true);
    }
  }, [action, setIsAddClientModalVisible, setIsImportClientsModalVisible]);

  // const uniqueId = location?.state?.params?.uniqueId

  const onScroll = (e: any) => {
    if (window.scrollY > 20) {
      setShrink(true);
    }
    if (window.scrollY < 2) {
      setShrink(false);
    }
  };

  // Detect Scroll Past Point
  useEffect(() => {
    window.addEventListener('scroll', onScroll, false);
    return () => window.removeEventListener('scroll', onScroll, false);
  }, []);

  const handleReturnToAccount = async () => {
    const result = await returnToAccount();
    if (result.data?.ReturnToAccount.accessToken) {
      const { accessToken: accessTokenReturnToAccount, UserFirmAccount } = result.data.ReturnToAccount;
      loginAsPreparer(dispatch, accessTokenReturnToAccount, UserFirmAccount as UserFirmAccountEntity);
      dispatch(setPreviousAccessToken({ accessToken: undefined }));
      navigate('/', { replace: true });
    }
  };

  const onClick = ({ key }: { key: string }) => {
    if (key === 'add-client') {
      setIsAddClientModalVisible(true);
      trackCustomerIoEvent(CUSTOMER_IO_EVENTS.MODAL_SHOW, {
        'Modal Title': 'Add client modal',
        Source: 'Click from top menu',
        url: window.location.href,
      });
    } else if (key === 'import-clients') {
      setIsImportClientsModalVisible(true);
      trackCustomerIoEvent(CUSTOMER_IO_EVENTS.MODAL_SHOW, {
        'Modal Title': 'Import clients modal',
        Source: 'Click from top menu',
        url: window.location.href,
      });
    } else if (key === 'logout') {
      if (pusher.connection.state === 'connected') {
        pusher.disconnect();
      }
      trackSignOut();
      dispatch(resetSession());
      dispatch(resetClientData());
      dispatch(resetInterviews());
      dispatch(resetChat());
    } else if (key === 'return-to-account') {
      handleReturnToAccount();
    }
  };

  return (
    <Header id={styles.topHeader} className={`${styles.header} ${!shrink && 'elevated'} ${shrink && styles.headerShrink}`}>
      {!screens.md
        ? (
          <>
            <div className={styles.hamburgerWrapper}>
              <Button
                icon={<MenuOutlined />}
                onClick={() => hamburgerMenuOpen(true)}
              />
              <Link to="/"><Logo className={styles.logo} /></Link>
            </div>
            <HamburgerMenu />
          </>
        )
        : (
          <Link to="/"><Logo className={styles.logo} /></Link>
        )}
      <div style={{ width: '100%' }}>
        <Menu
          mode="horizontal"
          theme="light"
          onClick={onClick}
          selectable={false}
          style={{ display: 'flex' }}
        >
          <AddSubMenu key="AddSubMenu" />
          <InviteSubMenu key="InviteSubMenu" />
          <AvatarSubMenu key="AvatarSubMenu" />
        </Menu>
      </div>
      <AddClientModal
        isModalVisible={isAddClientModalVisible}
        setIsModalVisible={setIsAddClientModalVisible}
      />
      <ImportClientsModal
        isModalVisible={isImportClientsModalVisible}
        setIsModalVisible={setIsImportClientsModalVisible}
      />
    </Header>
  );
}

export default TopHeader;
