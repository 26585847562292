.saveOutlined {
  font-size: 20px;
  cursor: pointer;
  margin-right: 2px;
}

.centeredText {
  text-align: center;
  margin-bottom: 10px;
}

.addon {
  background: #fafafa;
  padding: 7px;
  border: 1px solid #e1e1e1;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: not-allowed;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
