import './index.css';
import {
  Switch,
  Upload,
  Button as TaxarooButton,
  DatePicker as TaxarooDatePicker,
} from 'antd';
import AllIcons from '@ant-design/icons';
import * as FormikAntd from 'formik-antd';
import Button from './Button';
import Card from './Card';
import Modal, { TaxarooModal } from './Modal';
import Typography from './Typography';
import Tabs from './Tabs';
import Table from './Table';
import InfiniteScrollTable from './InfiniteScrollTable';
import Layout from './Layout';
import Menu from './Menu';
import Calendar from './Calendar';
import Icons from './Icons';
import ChatChannel from './Chat/molecules/ChatChannel';
import Select from './Select';
import FileUploadDragger from './FileUploadDragger';
import Popover from './Popover';
import Breadcrumb from './Breadcrumb';
import Rate from './Rate';
import DatePicker from './DatePicker';
import Input from './Input';

const { Dragger } = Upload;

export {
  Row,
  Col,
  message,
  Divider,
  Form,
  FormInstance,
  Tooltip,
  Grid,
  Spin,
  Popconfirm,
  Badge,
  Space,
  Tag,
  Dropdown,
  Checkbox,
  Alert,
  InputNumber,
  Radio,
  Avatar,
  PageHeader,
  Skeleton,
  Upload,
  Image,
  Collapse,
  Drawer,
  List,
  Progress,
  Switch,
  Empty,
  Pagination,
  Steps,
} from 'antd';

export {
  Typography,
  Button,
  Modal,
  Card,
  Switch as Toggle,
  Dragger as Dropzone,
  Tabs,
  Table,
  InfiniteScrollTable,
  Layout,
  Menu,
  Calendar,
  Icons,
  TaxarooModal,
  AllIcons,
  ChatChannel,
  TaxarooButton,
  TaxarooDatePicker,
  FormikAntd,
  Select,
  FileUploadDragger,
  Popover,
  Breadcrumb,
  Rate,
  DatePicker,
  Input,
};
