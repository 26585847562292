import USStates from '~src/components/helpers/USStatesList';
import {
  IndividualPreparerFormValues,
  ItemValue, PreparerFormValues, StaffFormValues,
} from './typesDefinition';

const stateOptions = USStates.map((state) => ({
  label: state.name,
  value: state.abbreviation,
}));

export const PreparerInformation = ({
  tax,
  zip,
  city,
  other,
  state,
  payroll,
  lastName,
  firstName,
  accounting,
  taxReturns,
  dateOfBirth,
  phoneNumber,
  bookKeeping,
  businessZip,
  addressLine1,
  addressLine2,
  businessType,
  businessName,
  businessCity,
  taxResolution,
  businessPhone,
  businessState,
  taxPracticeYears,
  businessAddressLine1,
  businessAddressLine2,
}: PreparerFormValues): ItemValue[] => ([
  {
    span: {
      xs: 12,
    },
    required: true,
    key: 'firstName',
    value: firstName,
    inputType: 'text',
    label: 'First name',
    placeHolder: 'First name',
  },
  {
    span: {
      xs: 12,
    },
    required: true,
    key: 'lastName',
    value: lastName,
    inputType: 'text',
    label: 'Last Name',
    placeHolder: 'Last Name',
  },
  {
    span: {
      xs: 12,
    },
    required: true,
    inputType: 'date',
    value: dateOfBirth,
    key: 'dateOfBirth',
    label: 'Date Of Birth',
    placeHolder: 'Date Of Birth',
  },
  {
    span: {
      xs: 12,
    },
    required: true,
    inputType: 'phoneNumber',
    value: phoneNumber,
    key: 'phoneNumber',
    label: 'Phone Number',
    placeHolder: '(XXX) XXX-XXXX',
  },
  {
    span: {
      xs: 12,
    },
    required: true,
    key: 'addressLine1',
    value: addressLine1,
    inputType: 'textArea',
    label: 'Address Line 1',
    placeHolder: 'Address Line 1',
  },
  {
    span: {
      xs: 12,
    },
    required: false,
    key: 'addressLine2',
    value: addressLine2,
    inputType: 'textArea',
    label: 'Address Line 2',
    placeHolder: 'Address Line 2',
  },
  {
    span: {
      xl: 8,
      md: 12,
      xs: 24,
    },
    key: 'city',
    value: city,
    label: 'City',
    required: true,
    inputType: 'text',
    placeHolder: 'City',
  },
  {
    span: {
      xl: 8,
      md: 12,
      xs: 24,
    },
    key: 'state',
    value: state,
    label: 'State',
    required: true,
    inputType: 'select',
    options: stateOptions,
    placeHolder: 'State',
  },
  {
    span: {
      xl: 8,
      md: 12,
      xs: 24,
    },
    key: 'zip',
    value: zip,
    required: true,
    inputType: 'text',
    label: 'ZIP/Postal Code',
    placeHolder: 'ZIP/Postal Code',
  },
  {
    span: {
      xs: 24,
    },
    required: true,
    key: 'businessType',
    value: businessType,
    inputType: 'radioButton',
    label: 'I am operating as',
    placeHolder: 'I am operating as',
    options: [
      { label: 'An individual tax preparer', value: 'INDIVIDUAL' },
      { label: 'A business', value: 'COMPANY' },
    ],
  },
  {
    span: {
      xs: 12,
    },
    required: false,
    inputType: 'text',
    key: 'businessName',
    value: businessName,
    label: 'Your Business Name',
    placeHolder: 'Your Business Name',
  },
  {
    span: {
      xs: 12,
    },
    required: true,
    inputType: 'phoneNumber',
    key: 'businessPhone',
    value: businessPhone,
    label: 'Your Business Phone Number',
    placeHolder: 'Your Business Phone Number',
  },
  {
    span: {
      xs: 12,
    },
    required: true,
    inputType: 'textArea',
    label: 'Address Line 1',
    key: 'businessAddressLine1',
    value: businessAddressLine1,
    placeHolder: 'Address Line 1',
  },
  {
    span: {
      xs: 12,
    },
    required: false,
    inputType: 'textArea',
    label: 'Address Line 2',
    key: 'businessAddressLine2',
    value: businessAddressLine2,
    placeHolder: 'Address Line 2',
  },
  {
    span: {
      xl: 8,
      md: 12,
      xs: 24,
    },
    label: 'City',
    required: true,
    inputType: 'text',
    placeHolder: 'City',
    value: businessCity,
    key: 'businessCity',
  },
  {
    span: {
      xl: 8,
      md: 12,
      xs: 24,
    },
    label: 'State',
    required: true,
    inputType: 'select',
    options: stateOptions,
    value: businessState,
    key: 'businessState',
    placeHolder: 'State',
  },
  {
    span: {
      xl: 8,
      md: 12,
      xs: 24,
    },
    required: true,
    inputType: 'text',
    key: 'businessZip',
    value: businessZip,
    label: 'ZIP/Postal Code',
    placeHolder: 'ZIP/Postal Code',
  },
  {
    span: {
      xs: 24,
    },
    required: false,
    key: 'taxReturns',
    value: taxReturns,
    inputType: 'number',
    label: 'Number of Tax Returns in the Past Season',
    placeHolder: 'Number of Tax Returns in the Past Season',
  },
  {
    span: {
      xs: 24,
    },
    required: false,
    inputType: 'number',
    key: 'taxPracticeYears',
    value: taxPracticeYears,
    label: 'Number of Years in Business',
    placeHolder: 'Number of Years in Business',
  },
  {
    span: {
      xl: 8,
      md: 12,
      xs: 24,
    },
    key: 'tax',
    label: 'Tax',
    required: true,
    value: tax ?? 0,
    placeHolder: 'Tax',
    inputType: 'number',
  },
  {
    span: {
      xl: 8,
      md: 12,
      xs: 24,
    },
    required: true,
    key: 'accounting',
    inputType: 'number',
    label: 'Accounting',
    value: accounting ?? 0,
    placeHolder: 'Accounting',
  },
  {
    span: {
      xl: 8,
      md: 12,
      xs: 24,
    },
    required: true,
    key: 'bookKeeping',
    inputType: 'number',
    label: 'Bookkeeping',
    value: bookKeeping ?? 0,
    placeHolder: 'Bookkeeping',
  },
  {
    span: {
      xl: 8,
      md: 12,
      xs: 24,
    },
    key: 'payroll',
    required: true,
    label: 'Payroll',
    inputType: 'number',
    value: payroll ?? 0,
    placeHolder: 'Payroll',
  },
  {
    span: {
      xl: 8,
      md: 12,
      xs: 24,
    },
    required: true,
    inputType: 'number',
    key: 'taxResolution',
    label: 'Tax Resolution',
    value: taxResolution ?? 0,
    placeHolder: 'Tax Resolution',
  },
  {
    span: {
      xl: 8,
      md: 12,
      xs: 24,
    },
    key: 'other',
    label: 'Other',
    required: true,
    value: other ?? 0,
    inputType: 'number',
    placeHolder: 'Other',
  },
]);

// INDIVIDUAL TAX PREPARER
export const IndividualPreparerInformation = ({
  zip,
  city,
  state,
  lastName,
  firstName,
  dateOfBirth,
  phoneNumber,
  addressLine1,
  addressLine2,
  businessType,
}: IndividualPreparerFormValues): ItemValue[] => ([
  {
    span: {
      xs: 12,
    },
    required: true,
    key: 'firstName',
    value: firstName,
    inputType: 'text',
    label: 'First name',
    placeHolder: 'First name',
  },
  {
    span: {
      xs: 12,
    },
    required: true,
    key: 'lastName',
    value: lastName,
    inputType: 'text',
    label: 'Last Name',
    placeHolder: 'Last Name',
  },
  {
    span: {
      xs: 12,
    },
    required: false,
    inputType: 'date',
    value: dateOfBirth,
    key: 'dateOfBirth',
    label: 'Date Of Birth',
    placeHolder: 'Date Of Birth',
  },
  {
    span: {
      xs: 12,
    },
    required: true,
    inputType: 'phoneNumber',
    value: phoneNumber,
    key: 'phoneNumber',
    label: 'Phone Number',
    placeHolder: '(XXX) XXX-XXXX',
  },
  {
    span: {
      xs: 12,
    },
    required: true,
    key: 'addressLine1',
    value: addressLine1,
    inputType: 'textArea',
    label: 'Address Line 1',
    placeHolder: 'Address Line 1',
  },
  {
    span: {
      xs: 12,
    },
    required: false,
    key: 'addressLine2',
    value: addressLine2,
    inputType: 'textArea',
    label: 'Address Line 2',
    placeHolder: 'Address Line 2',
  },
  {
    span: {
      xl: 8,
      md: 12,
      xs: 24,
    },
    key: 'city',
    value: city,
    label: 'City',
    required: true,
    inputType: 'text',
    placeHolder: 'City',
  },
  {
    span: {
      xl: 8,
      md: 12,
      xs: 24,
    },
    key: 'state',
    value: state,
    label: 'State',
    required: true,
    inputType: 'text',
    placeHolder: 'State',
  },
  {
    span: {
      xl: 8,
      md: 12,
      xs: 24,
    },
    key: 'zip',
    value: zip,
    required: true,
    inputType: 'text',
    label: 'ZIP/Postal Code',
    placeHolder: 'ZIP/Postal Code',
  },
  {
    span: {
      xs: 24,
    },
    required: true,
    key: 'businessType',
    value: businessType,
    inputType: 'radioButton',
    label: 'I am operating as',
    placeHolder: 'I am operating as',
    options: [
      { label: 'An individual tax preparer', value: 'INDIVIDUAL' },
      { label: 'A business', value: 'COMPANY' },
    ],
  },
]);

// STAFF
export const staffInformation = ({
  firstName, lastName, phoneNumber, dateOfBirth, addressLine1, addressLine2, city, state, zip,
}: StaffFormValues): ItemValue[] => ([
  {
    span: {
      xs: 12,
    },
    required: true,
    key: 'firstName',
    value: firstName,
    inputType: 'text',
    label: 'First name',
    placeHolder: 'First name',
  },
  {
    span: {
      xs: 12,
    },
    required: true,
    key: 'lastName',
    value: lastName,
    inputType: 'text',
    label: 'Last Name',
    placeHolder: 'Last Name',
  },
  {
    span: {
      xs: 12,
    },
    required: false,
    inputType: 'date',
    value: dateOfBirth,
    key: 'dateOfBirth',
    label: 'Date Of Birth',
    placeHolder: 'Date Of Birth',
  },
  {
    span: {
      xs: 12,
    },
    required: true,
    inputType: 'phoneNumber',
    value: phoneNumber,
    key: 'phoneNumber',
    label: 'Phone Number',
    placeHolder: '(XXX) XXX-XXXX',
  },
  {
    span: {
      xs: 12,
    },
    required: true,
    key: 'addressLine1',
    value: addressLine1,
    inputType: 'textArea',
    label: 'Address Line 1',
    placeHolder: 'Address Line 1',
  },
  {
    span: {
      xs: 12,
    },
    required: false,
    key: 'addressLine2',
    value: addressLine2,
    inputType: 'textArea',
    label: 'Address Line 2',
    placeHolder: 'Address Line 2',
  },
  {
    span: {
      xl: 8,
      md: 12,
      xs: 24,
    },
    key: 'city',
    value: city,
    label: 'City',
    required: true,
    inputType: 'text',
    placeHolder: 'City',
  },
  {
    span: {
      xl: 8,
      md: 12,
      xs: 24,
    },
    key: 'state',
    value: state,
    label: 'State',
    required: true,
    inputType: 'text',
    placeHolder: 'State',
  },
  {
    span: {
      xl: 8,
      md: 12,
      xs: 24,
    },
    key: 'zip',
    value: zip,
    required: true,
    inputType: 'text',
    label: 'ZIP/Postal Code',
    placeHolder: 'ZIP/Postal Code',
  },
]);
