import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const clientOnboardingSchema = (
  t: TFunction,
) => Yup.object().shape({
  landline: Yup.string(),
  language: Yup.string(),
  workNumber: Yup.string(),
  job: Yup.string().required('client.onboarding.fields.validate.job'),
  birthday: Yup.date().required(t('client.onboarding.fields.validate.birthday')),
  taxYear: Yup.string().required(t('client.onboarding.fields.validate.taxYear')),
  lastName: Yup.string().required(t('client.onboarding.fields.validate.lastName')),
  firstName: Yup.string().required(t('client.onboarding.fields.validate.firstName')),
  mobileNumber: Yup.string().required(t('client.onboarding.fields.validate.mobileNumber')),
  createPassword: Yup.string().min(8, t('client.onboarding.fields.validate.createPassword2')).matches(
    /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
    t('client.onboarding.fields.validate.createPassword3'),
  ).required('client.onboarding.fields.validate.createPassword'),
  email: Yup.string().email(t('client.onboarding.validate.invalidEmail')).required(t('client.onboarding.fields.validate.email')),
  confirmPassword: Yup.string().required(t('client.onboarding.fields.validate.confirmPassword')).oneOf([Yup.ref('createPassword'), null], t('client.onboarding.fields.validate.confirmPassword2')),
});

export const registeredClientOnboardingSchema = Yup.object().shape({
  landline: Yup.string(),
  language: Yup.string(),
  workNumber: Yup.string(),
  birthday: Yup.date().required('client.onboarding.fields.validate.birthday'),
  lastName: Yup.string().required('client.onboarding.fields.validate.lastName'),
  firstName: Yup.string().required('client.onboarding.fields.validate.firstName'),
  mobileNumber: Yup.string().required('client.onboarding.fields.validate.mobileNumber'),
  createPassword: Yup.string().min(8, 'client.onboarding.fields.validate.createPassword2').matches(
    /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
    'client.onboarding.fields.validate.createPassword3',
  ).required('client.onboarding.fields.validate.createPassword'),
  email: Yup.string().email('client.onboarding.fields.validate.invalidEmail').required('client.onboarding.fields.validate.email'),
  confirmPassword: Yup.string().required('client.onboarding.fields.validate.confirmPassword').oneOf([Yup.ref('createPassword'), null], 'client.onboarding.fields.validate.confirmPassword2'),
});
