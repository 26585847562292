import { Icons, message } from 'taxaroo-ui';
import moment from 'moment';
import { TaxYearInterviewOrderByInput, TaxYearStatus } from '~src/graphql';
import { GetClientsByProgressStatusIdDocument } from '~src/graphql/queries/settings';
import { useUpdateTaxYearInterviewMutation } from '~src/graphql/mutations/settings';
import ActionButton from '~src/components/molecules/ActionButton';
import SelectTeamMember from '~src/components/atoms/SelectTeamMember';
import { useAppSelector } from '~src/redux/hooks';
import getPhoneIcon from '~src/components/helpers/getPhoneIcon';
import { renderTime } from '../../../helpers/time';
import Documents from '../../JobsTable/statusIcons/Documents';
import Activation from '../../JobsTable/statusIcons/Activation';
import Notes from '../../JobsTable/statusIcons/Notes';
import Invoice from '../../JobsTable/statusIcons/Invoice';
import NameColumn from './NameColumn';
import { RecordTarget } from '..';
import '../styles.css';
import {
  propertyNameSortArray, propertyPhoneSortArray, propertySortArray, propertyTaskSortArray,
} from './clientListColumns';

const {
  MailOutlined,
  FileOutlined,
  FormOutlined,
  DollarOutlined,
  RightOutlined,
} = Icons;

const convertShortFormSortOrderToLongForm = (sortOrder: string) => {
  if (sortOrder === 'asc') return 'ascend';
  if (sortOrder === 'desc') return 'descend';
  return null;
};

const StartButton = ({ record: { taxYearInterviewId } }: { record: RecordTarget }) => {
  // get all the progressStatuses from the session
  const { progressStatuses } = useAppSelector((state) => state.session);
  // find the inProgress and unassigned statuses
  const inProgressStatus = progressStatuses.find((status) => status.underlyingStatus === TaxYearStatus.InProgress);
  const unassignedStatus = progressStatuses.find((status) => status.underlyingStatus === TaxYearStatus.Unassigned);
  const [updateTaxYearInterviewMutation, { loading }] = useUpdateTaxYearInterviewMutation({
    onCompleted: () => message.success('Changes saved successfully'),
    onError: (error) => message.error(error.name),
    refetchQueries: [
      {
        query: GetClientsByProgressStatusIdDocument,
        variables: {
          progressStatusId: inProgressStatus.id,
        },
      },
      {
        query: GetClientsByProgressStatusIdDocument,
        variables: {
          progressStatusId: unassignedStatus.id,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  return (
    <div>
      <ActionButton
        tooltip="Start Job"
        confirmAction
        confirmLabel="Are you sure?"
        buttonProps={{ type: 'primary' }}
        loading={loading}
        onClick={() => (
          updateTaxYearInterviewMutation({
            variables: {
              updateTaxYearInterviewInput: {
                id: taxYearInterviewId,
                progressStatusId: inProgressStatus.id,
              },
            },
          })
        )}
      >
        <RightOutlined />
        Start
      </ActionButton>
    </div>
  );
};

const getUnassignedColumns = (userRole: string, progressStatusId: string) => {
  const savedSort: TaxYearInterviewOrderByInput = JSON.parse(localStorage.getItem(`${progressStatusId}SortOrder`));
  return [
    {
      title: 'First Name',
      dataIndex: '',
      key: 'firstName',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.TaxYear?.Entity?.Users?.UserInformation?.firstName),
      // sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      // eslint-disable-next-line react/display-name
      render: ({
        email,
        phone,
        userId,
        entityId,
        firstName,
        workNumber,
        languageId,
        landlineNumber,
        userInformationId,
        taxYearInterviewId,
      }: RecordTarget) => (
        <NameColumn
          email={email}
          userId={userId}
          entityId={entityId}
          phone={phone?.value}
          name={firstName}
          languageId={languageId}
          workNumber={workNumber?.value}
          userInformationId={userInformationId}
          landlineNumber={landlineNumber?.value}
          taxYearInterviewId={taxYearInterviewId}
        />
      ),
      // fixed: screens.sm ? 'left' : false,
      width: 126,
      className: 'column-name-overflow-hidden',
    },
    {
      title: 'Last Name',
      dataIndex: '',
      key: 'lastName',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.TaxYear?.Entity?.Users?.UserInformation?.lastName),
      // sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      // eslint-disable-next-line react/display-name
      render: ({
        email,
        phone,
        userId,
        lastName,
        entityId,
        workNumber,
        languageId,
        landlineNumber,
        userInformationId,
        taxYearInterviewId,
      }: RecordTarget) => (
        <NameColumn
          email={email}
          userId={userId}
          name={lastName}
          entityId={entityId}
          phone={phone?.value}
          languageId={languageId}
          workNumber={workNumber?.value}
          userInformationId={userInformationId}
          landlineNumber={landlineNumber?.value}
          taxYearInterviewId={taxYearInterviewId}
        />
      ),
      // fixed: screens.sm ? 'left' : false,
      width: 126,
      className: 'column-name-overflow-hidden',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.TaxYear?.Entity?.Users?.email),
      // sortOrder: sortedInfo.columnKey === "email" && sortedInfo.order,
      key: 'email',
      width: 200,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      sorter: false,
      // sortOrder: sortedInfo.columnKey === "email" && sortedInfo.order,
      render: (record: any) => (
        <span>
          {record?.value && (
          <>
            {(getPhoneIcon(record))}
            {' '}
            {record?.value ?? ''}
          </>
          )}
        </span>
      ),
      key: 'phone',
      width: 200,
    },
    {
      title: 'Tax Year',
      dataIndex: 'taxYear',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.TaxYear?.year),
      // sortOrder: sortedInfo.columnKey === "taxYear" && sortedInfo.order,
      key: 'taxYear',
      width: 85,
    },
    {
      title: 'Job Type',
      dataIndex: 'jobType',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.Interviews?.name),
      // sortOrder: sortedInfo.columnKey === "jobType" && sortedInfo.order,
      key: 'jobType',
      width: 140,
    },
    {
      title: 'Time in Queue',
      dataIndex: 'timeInQueue',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.createAt),
      // sortOrder: sortedInfo.columnKey === "inProgressTime" && sortedInfo.order,
      key: 'timeInQueue',
      render: (text: number) => renderTime(text),
      renderText: (text: number) => renderTime(text),
      width: 115,
    },
    {
      title: <FileOutlined title="Documents" className="column-icon" />,
      label: 'Documents',
      key: 'files',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.JobTracking?.filesCompletedAt),
      // sortOrder: sortedInfo.columnKey === "files" && sortedInfo.order,
      // eslint-disable-next-line react/display-name
      render: (text, record: any) => <Documents record={record} />,
      renderText: (docStatus: any) => {
        if (!docStatus) return 'Documents not found';
        return null;
      },
      width: 55,
    },
    {
      title: () => <MailOutlined title="Activation Sent" className="column-icon" />,
      label: 'Envelop',
      dataIndex: 'invitationEmailSent',
      key: 'invitationEmailSent',
      sorter: false,
      // sortOrder: sortedInfo.columnKey === "invitationEmailSent" && sortedInfo.order,
      // eslint-disable-next-line react/display-name
      render: (text: boolean, record: RecordTarget) => <Activation invitationEmailSent={text} password={record?.password} />,
      width: 55,
    },
    {
      title: <FormOutlined title="Notes" className="column-icon" />,
      label: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      sorter: false,
      // sortOrder: sortedInfo.columnKey === "comments" && sortedInfo.order,
      // eslint-disable-next-line react/display-name
      render: (text, record: any) => <Notes record={record} />,
      renderText: (comments: { createdAt: string | number | Date }) => {
        if (!comments) return 'Notes not found';
        return moment(new Date(comments.createdAt)).format();
      },
      width: 55,
    },
    {
      title: <DollarOutlined title="Client Payment Received" className="column-icon" />,
      label: 'Client Payment Status',
      dataIndex: '',
      key: 'clientInvoice',
      sorter: false,
      // sortOrder: sortedInfo.columnKey === "clientInvoice" && sortedInfo.order,
      render: (record: RecordTarget) => <Invoice record={record} />,
      // renderText: (data, row) => statusIndicators(row.taxYearStatus.clientInvoice, row, true),
      width: 55,
    },
    {
      title: 'Referred By',
      dataIndex: 'referredBy',
      sorter: false,
      // sortOrder: sortedInfo.columnKey === "referredBy" && sortedInfo.order,
      key: 'referredBy',
      width: 150,
    },
    {
      title: 'Preparer',
      dataIndex: '',
      align: 'center',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.PreparedUser?.UserInformation?.firstName),
      key: 'currentPreparer',
      // sortOrder: sortedInfo.columnKey === "currentPreparer" && sortedInfo.order,
      // eslint-disable-next-line react/display-name
      render: ({
        taxYearInterviewId, preparedId, progressStatusId: progStatusId,
      }: RecordTarget) => (
        <SelectTeamMember
          type="Preparer"
          taxYearInterviewId={taxYearInterviewId}
          assignedMemberId={preparedId}
          progressStatusId={progStatusId}
          userRole={userRole}
        />
      ),
      width: 170,
    // renderText: (text) =>
    //   text && text.PreparerDetails
    //     ? `${text && text.PreparerDetails.firstName} ${text && text.PreparerDetails.lastName}`
    //     : "--- Not Assigned ---",
    },
    {
      title: 'Reviewer',
      dataIndex: '',
      align: 'center',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.ReviewedUser?.UserInformation?.firstName),
      key: 'currentReviewer',
      // sortOrder: sortedInfo.columnKey === "currentReviewer" && sortedInfo.order,
      render: ({ taxYearInterviewId, reviewedId, progressStatusId: progStatusId }: RecordTarget) => (
        <SelectTeamMember
          type="Reviewer"
          taxYearInterviewId={taxYearInterviewId}
          assignedMemberId={reviewedId}
          progressStatusId={progStatusId}
          userRole={userRole}
        />
      ),
      width: 170,
    // renderText: (text) =>
    //   text && text.PreparerDetails
    //     ? `${text && text.PreparerDetails.firstName} ${text && text.PreparerDetails.lastName}`
    //     : "--- Not Assigned ---",
    },
    // {
    //   title: 'Assign',
    //   dataIndex: '',
    //   align: 'center',
    //   sorter: () => undefined,
    //   key: 'assign',
    //   // sortOrder: sortedInfo.columnKey === "currentReviewer" && sortedInfo.order,
    //   // eslint-disable-next-line react/display-name
    //   render: (record: any) => <AssignButton record={record} />,
    //   width: 170,
    // },
    {
      title: 'Start Job',
      dataIndex: '',
      align: 'center',
      sorter: false,
      key: 'assign',
      // sortOrder: sortedInfo.columnKey === "currentReviewer" && sortedInfo.order,
      // eslint-disable-next-line react/display-name
      render: (record: RecordTarget) => <StartButton record={record} />,
      width: 150,
    },
  ]; // end columns
};

export default getUnassignedColumns;
