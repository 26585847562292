import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import PersonalInfo from '~src/components/organisms/RegisterFlow/PersonalInfo';
import { BusinessType } from '~src/graphql';
import { RootState } from '../store';

export enum RegisterStep {
  DONE = 'DONE',
  VIDEO = 'VIDEO',
  SELECT_PLAN = 'SELECT_PLAN',
  PERSONAL_INFO = 'PERSONAL_INFO',
  BUSINESS_INFO = 'BUSINESS_INFO',
  PERSONAL_ADDRESS = 'PERSONAL_ADDRESS',
  BUSINESS_ADDRESS = 'BUSINESS_ADDRESS',
}

type PersonalInfo = {
  the_em: string,
  the_pass: string,
  the_last_name: string;
  the_first_name: string;
  the_confirm_pass: string;
  the_date_of_birth: string;
  the_operating_as?: keyof typeof BusinessType;
};

type Address = {
  the_id?: string;
  the_city: string;
  the_line1: string;
  the_state: string;
  the_line2?: string;
  the_zip_code: string;
};

type BusinessInfo = {
  the_id: string;
  the_tax_returns?: number;
  the_business_name: string;
  the_business_phone: string;
  the_business_phone_id?: string;
};

type PaymentInfo = {
  cvc: string;
  cardNumber: string;
  expireDate: string;
};

type RegisterState = {
  selectedPlanId?: string;
  paymentInfo: PaymentInfo;
  personalAddress: Address;
  currentStep: RegisterStep;
  businessAddress?: Address;
  personalInfo: PersonalInfo;
  businessInfo: BusinessInfo;
}

export const INITIAL_PERSONAL_INFO: PersonalInfo = {
  the_em: '',
  the_pass: '',
  the_last_name: '',
  the_first_name: '',
  the_confirm_pass: '',
  the_date_of_birth: '',
  the_operating_as: null,
};

export const INITIAL_BUSINESS_INFO: BusinessInfo = {
  the_id: '',
  the_tax_returns: null,
  the_business_name: '',
  the_business_phone: '',
  the_business_phone_id: '',
};

export const INITIAL_PERSONAL_ADDRESS: Address = {
  the_id: '',
  the_city: '',
  the_line1: '',
  the_line2: '',
  the_state: '',
  the_zip_code: '',
};

export const INITIAL_BUSINESS_ADDRESS: Address = {
  the_id: '',
  the_city: '',
  the_line1: '',
  the_line2: '',
  the_state: '',
  the_zip_code: '',
};

export const INITIAL_PAYMENT_INFO: PaymentInfo = {
  cvc: '',
  cardNumber: '',
  expireDate: '',
};

const INITIAL_STATE: RegisterState = {
  selectedPlanId: null,
  paymentInfo: INITIAL_PAYMENT_INFO,
  personalInfo: INITIAL_PERSONAL_INFO,
  businessInfo: INITIAL_BUSINESS_INFO,
  currentStep: RegisterStep.PERSONAL_INFO,
  personalAddress: INITIAL_PERSONAL_ADDRESS,
  businessAddress: INITIAL_BUSINESS_ADDRESS,

};

const registerSlice = createSlice({
  initialState: { ...INITIAL_STATE },
  name: 'register',
  reducers: {
    changeStep: (state, { payload }: PayloadAction<RegisterStep>) => ({
      ...state,
      currentStep: payload,
    }),
    setPersonalInfo: (state, { payload } : PayloadAction<Partial<PersonalInfo>>) => ({
      ...state,
      personalInfo: {
        ...state.personalInfo,
        ...payload,
      },
    }),
    setBusinessInfo: (state, { payload } : PayloadAction<Partial<BusinessInfo>>) => ({
      ...state,
      businessInfo: {
        ...state.businessInfo,
        ...payload,
      },
    }),
    setPersonalAddress: (state, { payload } : PayloadAction<Partial<Address>>) => ({
      ...state,
      personalAddress: {
        ...state.personalAddress,
        ...payload,
      },
    }),
    setBusinessAddress: (state, { payload } : PayloadAction<Partial<Address>>) => ({
      ...state,
      businessAddress: {
        ...state.businessAddress,
        ...payload,
      },
    }),
    setPaymentInfo: (state, { payload }: PayloadAction<Partial<PaymentInfo>>) => ({
      ...state,
      paymentInfo: {
        ...state.paymentInfo,
        ...payload,
      },
    }),
    selectPlanId: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      selectedPlanId: payload,
    }),
    resetRegisterState: (state) => ({
      ...INITIAL_STATE,
    }),
  },
});

export const selectRegisterState = (state: RootState) => state.register;

export const {
  selectPlanId,
  setPaymentInfo,
  setBusinessInfo,
  setPersonalInfo,
  setPersonalAddress,
  setBusinessAddress,
  resetRegisterState,
} = registerSlice.actions;

export default registerSlice.reducer;
