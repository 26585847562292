import { FC, useEffect, useState } from 'react';
import {
  Modal,
  Form,
  message,
  Alert,
  Typography,
  Button,
} from 'taxaroo-ui';
import config from '~src/components/helpers/config';
import { useGetCancellationPriceQuery } from '~src/graphql/queries/stripeInformation';
import { useDeleteSubscriptionMutation, useUpdateSubscriptionMutation } from '~src/graphql/mutations/settings';
import { StripeSubscriptionEntity } from '~src/graphql';
import { ReasonForm } from './ReasonForm';
import OfferCard from './OfferCard';

const { Title } = Typography;

export interface CancelSubscriptionModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (b: boolean) => void;
  subscription: StripeSubscriptionEntity;
}

const CancelSubscriptionModal: FC<CancelSubscriptionModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  subscription,
}) => {
  const [saveAmount, setSaveAmount] = useState<number>(0);
  const [newAmount, setNewAmount] = useState<number>(0);
  const [step, setStep] = useState<number>(1); // 1 = collect reason, 2 = downsell offer, 3 = lite plan, // 4 = free plan, otherwise cancel subscritpion
  const paused = !!subscription?.pause_collection?.behavior || subscription?.status === 'paused';
  const subscriptionInterval = subscription?.items?.data[0]?.plan?.interval;

  const [reasonForm] = Form.useForm();

  // graphql
  // get cancellation price data
  const { data: cancellationPriceData } = useGetCancellationPriceQuery();
  const cancellationPrice = cancellationPriceData?.GetCancellationPrice;
  // update subscription
  const [updateSubscritpion, { loading: loadingUpdateSubscription }] = useUpdateSubscriptionMutation();
  // cancel subscription
  const [deleteSubscription, { loading: loadingDeleteSubscription }] = useDeleteSubscriptionMutation();

  const isOnLitePlan = subscription?.items?.data[0]?.plan?.id === config.stripeLitePlanPriceId;
  const isOnFreePlan = subscription?.items?.data[0]?.plan?.id === config.stripeFreePlanPriceId;
  const isOnDownSellOffer = cancellationPrice?.id === subscription?.items?.data[0]?.plan.id;
  const isOnLastStep = step === 3 || (step === 2 && isOnLitePlan);

  // saveAmount for the new subscription
  useEffect(() => {
    if (cancellationPrice?.tiers?.length) {
      // find the tier with flat_amount
      const cancellationFlatAmountTier = cancellationPrice.tiers.find((tier) => tier.flat_amount);
      const currentSubscriptionFlatAmountTier = subscription?.items?.data[0]?.plan?.tiers?.find((tier) => tier.flat_amount);
      if (cancellationFlatAmountTier?.flat_amount && currentSubscriptionFlatAmountTier?.flat_amount) {
        const newSaveAmount = subscriptionInterval === 'month'
          ? (currentSubscriptionFlatAmountTier.flat_amount * 12) - (cancellationFlatAmountTier.flat_amount)
          : currentSubscriptionFlatAmountTier.flat_amount - cancellationFlatAmountTier.flat_amount;
        setSaveAmount(newSaveAmount / 100);
        setNewAmount(cancellationFlatAmountTier.flat_amount / 100);
      }
    }
  }, [cancellationPrice, subscription]);

  // additional logic for step changes
  useEffect(() => {
    // if already on downsell offer, skip to step 3
    if (step === 2 && isOnDownSellOffer) {
      setStep(3);
    }
    // if already on lite plan, skip to step 4
    // if (step === 3 && isOnLitePlan) {
    //   setStep(4);
    // }
  }, [step, paused, cancellationPrice, subscription]);

  const closeModal = () => {
    setIsModalVisible(false);
    setStep(1);
    reasonForm.resetFields();
  };

  const handleCancelSubscription = async () => {
    // cancel subscription at end of billing period (function also sends emails and triggers zap)
    deleteSubscription({
      variables: {
        subscriptionId: subscription?.id,
        deleteStripeSubscriptionInput: {
          cancellation_details: {
            comment: reasonForm.getFieldValue('reason'),
            feedback: reasonForm.getFieldValue('reasonSelection'),
          },
        },
      },
      refetchQueries: ['GetSubscription', 'GetPlanAccessLevel'],
      onCompleted: () => {
        message.success('Subscription canceled.');
        closeModal();
      },
      onError: (error) => {
        message.error(error.message);
      },
    });
  };

  const handleSwitchToPlan = (priceId: string, quantity: number) => {
    updateSubscritpion({
      variables: {
        resume: paused ? true : undefined,
        updateStripeSubscriptionInput: {
          items: [{
            id: subscription?.items?.data[0]?.id,
            price: priceId,
            quantity,
          }],
        },
      },
      refetchQueries: ['GetSubscription', 'GetPlanAccessLevel'],
      onCompleted: () => {
        message.success('Subscription updated!');
        closeModal();
      },
      onError: (error) => {
        message.error(error.message);
      },
    });
  };

  // const handlePauseSubscription = async () => {
  //   await updateSubscritpion({
  //     variables: {
  //       updateStripeSubscriptionInput: {
  //         pause_collection: {
  //           behavior: 'void',
  //           resumes_at: resumesAt.getTime() / 1000,
  //         },
  //       },
  //     },
  //     refetchQueries: ['GetSubscription'],
  //     onCompleted: () => {
  //       message.success('Subscription paused.');
  //       closeModal();
  //     },
  //   });
  // };

  const continueToNextStep = () => {
    if (step === 1) {
      // validate form
      reasonForm.validateFields().then(() => {
        setStep((prev) => prev + 1);
      });
    } else {
      setStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setStep((prev) => prev - 1);
  };

  return (
    <Modal
      title="Cancel Your Plan"
      visible={isModalVisible}
      centered
      style={{ top: 15, marginBottom: 100 }}
      destroyOnClose
      onCancel={closeModal}
      width={800}
      footer={[
        <div style={{ display: 'flex', justifyContent: step === 1 ? 'flex-end' : 'space-between', width: '100%' }}>
          {step > 1 && (
            <Button
              key="back"
              onClick={handleBack}
            >
              Back
            </Button>
          )}
          <div>
            <Button
              key="Cancel"
              onClick={closeModal}
              style={{ marginRight: '8px' }}
            >
              Cancel
            </Button>
            <Button
              key="next"
              type="primary"
              onClick={isOnLastStep ? () => handleCancelSubscription() : () => continueToNextStep()}
              danger={isOnLastStep}
              loading={loadingDeleteSubscription}
            >
              {isOnLastStep ? 'No, Delete my data' : 'Next'}
            </Button>
          </div>
        </div>,
      ]}
    >
      <Alert
        message="Are you sure you want to cancel your subscription?"
        description="You will lose access to your account after your current billing cycle ends."
        type="warning"
        showIcon
        style={{ marginBottom: '20px' }}
      />
      <Title level={4}>Before you go...</Title>
      {step === 1 && ( // reason
        <ReasonForm form={reasonForm} />
      )}
      {step === 2 && ( // downsell offer
        <OfferCard
          offerName={cancellationPrice?.nickname}
          saveAmount={saveAmount}
          body={`By clicking redeem discount you agree to pay $${newAmount.toFixed(2)} per year. You can still cancel at any time.`}
          handleRedeemOffer={() => handleSwitchToPlan(cancellationPrice?.id, 2)}
          loading={loadingUpdateSubscription}
          buttonText="Redeem Discount"
        />
      )}
      {step === 3 && ( // lite plan
        <OfferCard
          offerName="Or, would you like to try our Lite Plan"
          body="One team member with limited features for only $395 per year. You can still cancel at any time."
          handleRedeemOffer={() => handleSwitchToPlan(config.stripeLitePlanPriceId, 1)}
          loading={loadingUpdateSubscription}
          buttonText="Choose Lite Plan"
          featuresTitle="Includes:"
          featuresColumn1={['Interviews', 'Document uploads', 'Messaging', 'White Labeling', 'Appointments', 'Video Calls']}
          featuresColumn2={['Analytics', 'Spanish Workflows', 'Referral Program', 'Invoicing', 'Pixels', 'Birthday Emails']}
        />
      )}
      {step === 4 && ( // free plan
        <OfferCard
          offerName="How about using our Free Plan?"
          body="By clicking Downgrade to Free Plan you will be moved to our free plan which includes these basic features."
          handleRedeemOffer={() => handleSwitchToPlan(config.stripeFreePlanPriceId, 1)}
          loading={loadingUpdateSubscription}
          buttonText="Downgrade to Free Plan"
          featuresTitle="Includes:"
          featuresColumn1={['Interviews', 'Document uploads', 'Messaging', 'White Labeling']}
          featuresColumn2={['Analytics', 'Spanish Workflows', 'Referral Program', 'Invoicing ($10 processing fee)']}
          bottomBullets={[
            'Get a revenue share for all products sold to your customers',
          ]}
        />
      )}
    </Modal>
  );
};

export default CancelSubscriptionModal;
