import {
  Dispatch, SetStateAction, Key, useState,
} from 'react';
import {
  Button, message, Modal, Row, Input, Tooltip,
} from 'taxaroo-ui';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import { useCreateBulkMessageMutation } from '~src/graphql/mutations/messaging';

const { TextArea } = Input;

interface SendTextMessageButtonProps {
  selectedRows: any[];
  setSelectedRows: Dispatch<SetStateAction<never[]>>;
  setSelectedKeys: Dispatch<SetStateAction<Key[]>>;
  sendTextDisabled: boolean;
}
const SendTextMessageButton: React.FC<SendTextMessageButtonProps> = ({
  selectedRows,
  setSelectedRows,
  setSelectedKeys,
  sendTextDisabled,
}: SendTextMessageButtonProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [createBulkMessage, { loading: loadingBulkMessage }] = useCreateBulkMessageMutation({
    onError: (err) => message.error(err.message),
  });

  const handleSendTextMessages = () => {
    setIsModalVisible(true);

    trackCustomerIoEvent(CUSTOMER_IO_EVENTS.MODAL_SHOW, {
      'Modal Title': 'Send Text Message',
      Source: 'Click on button',
      url: window.location.href,
    });
  };

  const handleOK = async () => {
    const phoneIds = selectedRows.map((row) => row.phone?.id).filter((p) => p);
    if (phoneIds.length === 0) {
      message.error('No phone input');
      return;
    }

    const result = await createBulkMessage({
      variables: {
        createBulkMessage: {
          phoneIds,
          text: textAreaValue,
        },
      },
    });

    if (result.data?.createBulkMessage) {
      message.success(result.data?.createBulkMessage?.message);
      setIsModalVisible(false);
      setTextAreaValue('');
      setSelectedRows([]);
      setSelectedKeys([]);
    }
  };

  return (
    <>
      <Tooltip placement="top" title={selectedRows.length > 0 && sendTextDisabled ? 'Cannot send multiple messages before 9 AM or after 8 PM' : ''}>
        <span>
          <Button
            type="primary"
            disabled={sendTextDisabled}
            onClick={handleSendTextMessages}
          >
            Send Text Message
          </Button>
        </span>
      </Tooltip>
      <Modal
        title="Send Text Message"
        visible={isModalVisible}
        onOk={handleOK}
        onCancel={() => setIsModalVisible(false)}
        okText="Send Text Message"
        okButtonProps={{ loading: loadingBulkMessage, disabled: textAreaValue.length < 1 }}
        cancelText="Cancel"
      >
        <Row>
          <TextArea
            rows={4}
            value={textAreaValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTextAreaValue(e.target.value)}
            showCount
            maxLength={160}
            style={{ width: '100%', paddingBottom: '10px' }}
          />
        </Row>
      </Modal>
    </>
  );
};

export default SendTextMessageButton;
