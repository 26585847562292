.containerAppointment {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.itemAppointment {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 7px;
}

.itemAppointment svg {
  width: 12px;
  height: 12px;
}

.itemAppointment p {
  margin-left: 10px;
  margin-bottom: 0;
  color: #565656;
  font-size: 14px;
}

.subtitle {
  font-size: 16px !important;
  font-weight: 600;
  color: #565656 !important;
}

.active {
  color: #0a64b2 !important;
}
