.container {
  border: 1px solid #0076e2;
  border-radius: 12px;
  width: calc(100% - 24px);
  height: 100%;
  margin: 10px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.copyButton {
  position: relative;
  margin-top: -40px;
  margin-right: 10px;
  align-self: end;
  background-color: white;
  padding: 5px 6px;
  border-radius: 35px;
}
