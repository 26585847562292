import { Dispatch, FC } from 'react';
import {
  Typography,
  Row,
  Col,
  Tooltip,
  Popover,
  Icons,
} from 'taxaroo-ui';
import { TwitterPicker } from 'react-color';
import { CustomLayoutAction, CustomLayoutCategory } from '~src/components/templates/SiteBranding/reducer';
import { categoryTitle } from '~src/components/templates/SiteBranding/utils';

const {
  InfoCircleTwoTone,
  BgColorsOutlined,
} = Icons;

const { Title, Paragraph, Text } = Typography;

interface CustomColorProps {
  category: CustomLayoutCategory;
  chooseMoreColors: (
    currentColorSelected: string,
    category: string,
  ) => void;
  colors: string[];
  customColor: string;
  dispatch: Dispatch<CustomLayoutAction>;
  title: string;
}

const CustomColor: FC<CustomColorProps> = (props) => {
  const {
    category,
    chooseMoreColors,
    customColor,
    colors,
    dispatch,
    title,
  } = props;

  return (
    <>
      <Row>
        <Col span={24}>
          <Typography>
            <Title level={5}>
              {title}
              &nbsp;
              <Popover
                overlayStyle={{ width: '50%' }}
                placement="right"
                content="Select a color or manually add a color hex right after #"
                title="How does this work?"
                trigger="hover"
              >
                <InfoCircleTwoTone style={{ fontSize: 14 }} />
              </Popover>
            </Title>
            <Paragraph style={{ marginBottom: 0 }}>
              <Text><b>{categoryTitle[category]}</b></Text>
            </Paragraph>
          </Typography>
        </Col>
      </Row>
      <Row gutter={2} align="middle">
        <Col sm={24} md={8} lg={4}>
          <div
            style={{
              borderRadius: 5,
              borderStyle: 'solid',
              borderColor: customColor === '#ffffff' ? 'black' : customColor,
              borderWidth: 2,
              height: 90,
              width: 90,
              marginLeft: 5,
              backgroundColor: customColor,
            }}
          />
        </Col>
        <Col sm={24} md={15} lg={20}>
          <Row>
            <Col sm={19} md={21} lg={23}>
              <TwitterPicker
                colors={colors}
                color={customColor}
                width="100%"
                onChange={(color) => dispatch({
                  type: category,
                  payload: { [category]: color.hex },
                })}
                triangle="hide"
              />
            </Col>
            <Col sm={5} md={3} lg={1}>
              <Tooltip title="Choose more colors">
                <BgColorsOutlined
                  onClick={() => chooseMoreColors(category, customColor)}
                  style={{
                    cursor: 'pointer',
                    fontSize: 32,
                    marginLeft: 8,
                    marginTop: 12,
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CustomColor;
