import {
  Typography, Icons,
} from 'taxaroo-ui';
import { Link } from 'react-router-dom';
import * as styles from './styles.module.css';

const { Title, Paragraph } = Typography;
const { CheckCircleFilled } = Icons;

const features = [
  'Effortless client onboarding',
  'Hassle-free invoicing & payments',
  'Secure IRS-compliant e-signatures',
  'Secure messaging (Chat, SMS & Video)',
  'Secure document portal with 256-bit encryption',
  'Seamless appointment scheduling',
  'Track performance and growth with analytics',
];

const freeFeatures = [
  'Client Portal / Interviews',
  'Document Management',
  'Secure Communications',
  'Easy Payments ($10 per payment)',
  'Built-in Client Referral Program',
  'Save 47 minutes per return!',
];

interface FeaturesSectionProps {
  free?: boolean;
}
const FeaturesSection = ({ free = false }: FeaturesSectionProps) => {
  const featuresList = free ? freeFeatures : features;
  return (
    <>
      <Title level={2} className={styles.registerTitle}>
        {free ? 'Boost Efficiency & Client Satisfaction – Free!' : 'REGISTRATION'}
      </Title>
      {free
        ? (
          <p style={{ fontSize: 31 }}>
            Unleash your tax firm&apos;s potential and grow your business without spending a dime!
          </p>
        )
        : (
          <p>
            Save
            {' '}
            <span style={{ color: '#0057A5' }}>47 minutes</span>
            {' '}
            per return,
            increase productivity & delight your clients!
          </p>
        )}
      <ul>
        {featuresList.map((feature) => (
          <li key={feature}>
            <CheckCircleFilled className={styles.checkboxIcon} />
            {' '}
            {feature}
          </li>
        ))}
      </ul>
      <Paragraph style={{ marginTop: 33 }}>
        Already Registered?
        {' '}
        <Link to="/login" style={{ color: '#0057A5', textDecoration: 'underline' }}>Login</Link>
      </Paragraph>
    </>
  );
};

FeaturesSection.defaultProps = {
  free: false,
};

export default FeaturesSection;
