import { Icons, Tooltip } from 'taxaroo-ui';

const {
  Circle,
  CheckCircleOutlined,
  ClockCircleOutlined,
} = Icons;

interface ActivationProps {
  invitationEmailSent: boolean;
  password: string;
}
function Activation({ invitationEmailSent, password }: ActivationProps) {
  if (invitationEmailSent && password) {
    return (
      <Tooltip title="Activation sent & accepted!">
        <CheckCircleOutlined className="status-icon" style={{ color: 'green' }} />
      </Tooltip>
    );
  }
  if (invitationEmailSent && !password) {
    return (
      <Tooltip title="Activation sent, not yet accepted">
        <ClockCircleOutlined className="status-icon" style={{ color: 'orange' }} />
      </Tooltip>
    );
  }
  if (!invitationEmailSent && password) {
    return (
      <Tooltip title="Client is active!">
        <CheckCircleOutlined className="status-icon" style={{ color: 'green' }} />
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Activation not sent">
      <span role="img" tabIndex={-1} className="anticon status-icon">
        <Circle width="1em" height="1em" className="status-icon" style={{ color: 'gray' }} />
      </span>
    </Tooltip>
  );
}

export default Activation;
