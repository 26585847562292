import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFileNameMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  name: Types.Scalars['String'];
}>;


export type UpdateFileNameMutation = { __typename?: 'Mutation', updateFileName: { __typename?: 'FileEntity', id: string, userId?: string | null, firmAccountId?: string | null, taxYearInterviewId?: string | null, s3Key?: string | null, name?: string | null, size?: number | null, folder?: string | null, pages?: number | null, documentUsed?: boolean | null, createAt: any, updateAt: any } };

export type UpdateCompiledFilePreSignedUrlMutationVariables = Types.Exact<{
  fileRequestId: Types.Scalars['String'];
  compiledHash: Types.Scalars['String'];
  fileSize: Types.Scalars['Float'];
}>;


export type UpdateCompiledFilePreSignedUrlMutation = { __typename?: 'Mutation', updateCompiledFilePreSignedUrl: string };

export type CreateFileTemplateMutationVariables = Types.Exact<{
  templateName: Types.Scalars['String'];
  fileName: Types.Scalars['String'];
}>;


export type CreateFileTemplateMutation = { __typename?: 'Mutation', CreateFileTemplate: string };

export type UpdateFileTemplateMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  templateName: Types.Scalars['String'];
}>;


export type UpdateFileTemplateMutation = { __typename?: 'Mutation', UpdateFileTemplate: { __typename?: 'FileTemplateEntity', id: string, name?: string | null, fileName?: string | null } };

export type DeleteFileTemplateMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteFileTemplateMutation = { __typename?: 'Mutation', DeleteFileTemplate: { __typename?: 'FileTemplateEntity', id: string } };

export type DocumentApprovalUploadMutationVariables = Types.Exact<{
  documentApprovalUploadInput: Types.DocumentApprovalUploadInput;
}>;


export type DocumentApprovalUploadMutation = { __typename?: 'Mutation', DocumentApprovalUpload: string };

export type DocumentSignatureUploadMutationVariables = Types.Exact<{
  documentSignatureUploadInput: Types.DocumentSignatureUploadInput;
}>;


export type DocumentSignatureUploadMutation = { __typename?: 'Mutation', DocumentSignatureUpload: string };


export const UpdateFileNameDocument = gql`
    mutation updateFileName($id: String!, $name: String!) {
  updateFileName(id: $id, name: $name) {
    id
    userId
    firmAccountId
    taxYearInterviewId
    s3Key
    name
    size
    folder
    pages
    documentUsed
    createAt
    updateAt
  }
}
    `;
export type UpdateFileNameMutationFn = Apollo.MutationFunction<UpdateFileNameMutation, UpdateFileNameMutationVariables>;

/**
 * __useUpdateFileNameMutation__
 *
 * To run a mutation, you first call `useUpdateFileNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileNameMutation, { data, loading, error }] = useUpdateFileNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateFileNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFileNameMutation, UpdateFileNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFileNameMutation, UpdateFileNameMutationVariables>(UpdateFileNameDocument, options);
      }
export type UpdateFileNameMutationHookResult = ReturnType<typeof useUpdateFileNameMutation>;
export type UpdateFileNameMutationResult = Apollo.MutationResult<UpdateFileNameMutation>;
export type UpdateFileNameMutationOptions = Apollo.BaseMutationOptions<UpdateFileNameMutation, UpdateFileNameMutationVariables>;
export const UpdateCompiledFilePreSignedUrlDocument = gql`
    mutation updateCompiledFilePreSignedUrl($fileRequestId: String!, $compiledHash: String!, $fileSize: Float!) {
  updateCompiledFilePreSignedUrl(
    fileRequestId: $fileRequestId
    compiledHash: $compiledHash
    fileSize: $fileSize
  )
}
    `;
export type UpdateCompiledFilePreSignedUrlMutationFn = Apollo.MutationFunction<UpdateCompiledFilePreSignedUrlMutation, UpdateCompiledFilePreSignedUrlMutationVariables>;

/**
 * __useUpdateCompiledFilePreSignedUrlMutation__
 *
 * To run a mutation, you first call `useUpdateCompiledFilePreSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompiledFilePreSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompiledFilePreSignedUrlMutation, { data, loading, error }] = useUpdateCompiledFilePreSignedUrlMutation({
 *   variables: {
 *      fileRequestId: // value for 'fileRequestId'
 *      compiledHash: // value for 'compiledHash'
 *      fileSize: // value for 'fileSize'
 *   },
 * });
 */
export function useUpdateCompiledFilePreSignedUrlMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompiledFilePreSignedUrlMutation, UpdateCompiledFilePreSignedUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompiledFilePreSignedUrlMutation, UpdateCompiledFilePreSignedUrlMutationVariables>(UpdateCompiledFilePreSignedUrlDocument, options);
      }
export type UpdateCompiledFilePreSignedUrlMutationHookResult = ReturnType<typeof useUpdateCompiledFilePreSignedUrlMutation>;
export type UpdateCompiledFilePreSignedUrlMutationResult = Apollo.MutationResult<UpdateCompiledFilePreSignedUrlMutation>;
export type UpdateCompiledFilePreSignedUrlMutationOptions = Apollo.BaseMutationOptions<UpdateCompiledFilePreSignedUrlMutation, UpdateCompiledFilePreSignedUrlMutationVariables>;
export const CreateFileTemplateDocument = gql`
    mutation createFileTemplate($templateName: String!, $fileName: String!) {
  CreateFileTemplate(templateName: $templateName, fileName: $fileName)
}
    `;
export type CreateFileTemplateMutationFn = Apollo.MutationFunction<CreateFileTemplateMutation, CreateFileTemplateMutationVariables>;

/**
 * __useCreateFileTemplateMutation__
 *
 * To run a mutation, you first call `useCreateFileTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileTemplateMutation, { data, loading, error }] = useCreateFileTemplateMutation({
 *   variables: {
 *      templateName: // value for 'templateName'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useCreateFileTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateFileTemplateMutation, CreateFileTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFileTemplateMutation, CreateFileTemplateMutationVariables>(CreateFileTemplateDocument, options);
      }
export type CreateFileTemplateMutationHookResult = ReturnType<typeof useCreateFileTemplateMutation>;
export type CreateFileTemplateMutationResult = Apollo.MutationResult<CreateFileTemplateMutation>;
export type CreateFileTemplateMutationOptions = Apollo.BaseMutationOptions<CreateFileTemplateMutation, CreateFileTemplateMutationVariables>;
export const UpdateFileTemplateDocument = gql`
    mutation UpdateFileTemplate($id: String!, $templateName: String!) {
  UpdateFileTemplate(id: $id, templateName: $templateName) {
    id
    name
    fileName
  }
}
    `;
export type UpdateFileTemplateMutationFn = Apollo.MutationFunction<UpdateFileTemplateMutation, UpdateFileTemplateMutationVariables>;

/**
 * __useUpdateFileTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateFileTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileTemplateMutation, { data, loading, error }] = useUpdateFileTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      templateName: // value for 'templateName'
 *   },
 * });
 */
export function useUpdateFileTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFileTemplateMutation, UpdateFileTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFileTemplateMutation, UpdateFileTemplateMutationVariables>(UpdateFileTemplateDocument, options);
      }
export type UpdateFileTemplateMutationHookResult = ReturnType<typeof useUpdateFileTemplateMutation>;
export type UpdateFileTemplateMutationResult = Apollo.MutationResult<UpdateFileTemplateMutation>;
export type UpdateFileTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateFileTemplateMutation, UpdateFileTemplateMutationVariables>;
export const DeleteFileTemplateDocument = gql`
    mutation DeleteFileTemplate($id: String!) {
  DeleteFileTemplate(id: $id) {
    id
  }
}
    `;
export type DeleteFileTemplateMutationFn = Apollo.MutationFunction<DeleteFileTemplateMutation, DeleteFileTemplateMutationVariables>;

/**
 * __useDeleteFileTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteFileTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileTemplateMutation, { data, loading, error }] = useDeleteFileTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFileTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileTemplateMutation, DeleteFileTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileTemplateMutation, DeleteFileTemplateMutationVariables>(DeleteFileTemplateDocument, options);
      }
export type DeleteFileTemplateMutationHookResult = ReturnType<typeof useDeleteFileTemplateMutation>;
export type DeleteFileTemplateMutationResult = Apollo.MutationResult<DeleteFileTemplateMutation>;
export type DeleteFileTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteFileTemplateMutation, DeleteFileTemplateMutationVariables>;
export const DocumentApprovalUploadDocument = gql`
    mutation DocumentApprovalUpload($documentApprovalUploadInput: DocumentApprovalUploadInput!) {
  DocumentApprovalUpload(
    documentApprovalUploadInput: $documentApprovalUploadInput
  )
}
    `;
export type DocumentApprovalUploadMutationFn = Apollo.MutationFunction<DocumentApprovalUploadMutation, DocumentApprovalUploadMutationVariables>;

/**
 * __useDocumentApprovalUploadMutation__
 *
 * To run a mutation, you first call `useDocumentApprovalUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentApprovalUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentApprovalUploadMutation, { data, loading, error }] = useDocumentApprovalUploadMutation({
 *   variables: {
 *      documentApprovalUploadInput: // value for 'documentApprovalUploadInput'
 *   },
 * });
 */
export function useDocumentApprovalUploadMutation(baseOptions?: Apollo.MutationHookOptions<DocumentApprovalUploadMutation, DocumentApprovalUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DocumentApprovalUploadMutation, DocumentApprovalUploadMutationVariables>(DocumentApprovalUploadDocument, options);
      }
export type DocumentApprovalUploadMutationHookResult = ReturnType<typeof useDocumentApprovalUploadMutation>;
export type DocumentApprovalUploadMutationResult = Apollo.MutationResult<DocumentApprovalUploadMutation>;
export type DocumentApprovalUploadMutationOptions = Apollo.BaseMutationOptions<DocumentApprovalUploadMutation, DocumentApprovalUploadMutationVariables>;
export const DocumentSignatureUploadDocument = gql`
    mutation DocumentSignatureUpload($documentSignatureUploadInput: DocumentSignatureUploadInput!) {
  DocumentSignatureUpload(
    documentSignatureUploadInput: $documentSignatureUploadInput
  )
}
    `;
export type DocumentSignatureUploadMutationFn = Apollo.MutationFunction<DocumentSignatureUploadMutation, DocumentSignatureUploadMutationVariables>;

/**
 * __useDocumentSignatureUploadMutation__
 *
 * To run a mutation, you first call `useDocumentSignatureUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentSignatureUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentSignatureUploadMutation, { data, loading, error }] = useDocumentSignatureUploadMutation({
 *   variables: {
 *      documentSignatureUploadInput: // value for 'documentSignatureUploadInput'
 *   },
 * });
 */
export function useDocumentSignatureUploadMutation(baseOptions?: Apollo.MutationHookOptions<DocumentSignatureUploadMutation, DocumentSignatureUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DocumentSignatureUploadMutation, DocumentSignatureUploadMutationVariables>(DocumentSignatureUploadDocument, options);
      }
export type DocumentSignatureUploadMutationHookResult = ReturnType<typeof useDocumentSignatureUploadMutation>;
export type DocumentSignatureUploadMutationResult = Apollo.MutationResult<DocumentSignatureUploadMutation>;
export type DocumentSignatureUploadMutationOptions = Apollo.BaseMutationOptions<DocumentSignatureUploadMutation, DocumentSignatureUploadMutationVariables>;
export const namedOperations = {
  Mutation: {
    updateFileName: 'updateFileName',
    updateCompiledFilePreSignedUrl: 'updateCompiledFilePreSignedUrl',
    createFileTemplate: 'createFileTemplate',
    UpdateFileTemplate: 'UpdateFileTemplate',
    DeleteFileTemplate: 'DeleteFileTemplate',
    DocumentApprovalUpload: 'DocumentApprovalUpload',
    DocumentSignatureUpload: 'DocumentSignatureUpload'
  }
}