import { FC } from 'react';
import moment from 'moment';
import {
  Icons, Popconfirm, Row, Col, message, Typography, TaxarooButton, Divider, Spin,
} from 'taxaroo-ui';
import { ApolloError } from '@apollo/client';
import { useCancelEventMutation, useRemoveEventMutation } from '~src/graphql/mutations/appointments';
import { MyEventsDocument } from '~src/graphql/queries/appointments';
import { EventStatuses } from '~src/graphql';
import { useGetEncryptMyTokenMutation } from '~src/graphql/mutations/clients';
import { useAppSelector } from '~src/redux/hooks';
import momentTz from 'moment-timezone';
import { UpdateAppointmentProps } from '~src/components/molecules/NewAppointmentModal/NewAppointmentModal';

const { Text } = Typography;

const {
  CalendarOutlined,
  ClockCircleOutlined,
  HourglassOutlined,
  LinkOutlined,
  PushpinOutlined,
  TeamOutlined,
  PhoneOutlined,
  HomeOutlined,
  DesktopOutlined,
  CoffeeOutlined,
  UsergroupDeleteOutlined,
  EditOutlined,
  DeleteOutlined,
} = Icons;

const getIcon = (type: string) => {
  switch (type) {
    case 'VIDEO':
      return <DesktopOutlined />;
    case 'CALL':
      return <PhoneOutlined />;
    case 'HOME':
      return <HomeOutlined />;
    case 'OFFICE':
      return <CoffeeOutlined />;
    default:
      return null;
  }
};

interface AppointmentModalProps {
  id: string,
  date: string;
  duration: number;
  participants: string[];
  start: Date;
  end: Date;
  meeting: string;
  status: EventStatuses;
  type: string;
  isExternal: boolean;
  timezone: string;
  clientId?: string;
  eventTypeId?: string;
  notes?: string;
  handleUpdateEvent: (initialUpdateValues: UpdateAppointmentProps) => void,
  reloadEvents: () => void;
}

const AppointmentModal: FC<AppointmentModalProps> = ({
  id,
  date,
  duration,
  participants,
  start,
  end,
  meeting,
  status,
  type,
  isExternal,
  timezone,
  clientId,
  eventTypeId,
  notes,
  handleUpdateEvent,
  reloadEvents,
}) => {
  const { userId } = useAppSelector((state) => state.session);
  const [cancelEventMutation, { loading: loadingCancelEvent }] = useCancelEventMutation({
    onError: (err: ApolloError) => {
      if (err.message === 'Email notification was not sent. Client email is not set.') {
        reloadEvents();
      }
      message.error(err.message, 10);
    },
    onCompleted: () => message.success('Appointment Cancelled'),
    refetchQueries: [MyEventsDocument],
    awaitRefetchQueries: true,
  });
  const [encryptMyToken, { loading: loadingEncryptMyToken }] = useGetEncryptMyTokenMutation({
    onError: (err: ApolloError) => message.error(err.message),
  });
  const [removeEventMutation, { loading: loadingRemoveEvent }] = useRemoveEventMutation({
    onError: (err: ApolloError) => message.error(err.message),
    onCompleted: () => message.success('Appointment Removed'),
    refetchQueries: [MyEventsDocument],
    awaitRefetchQueries: true,
  });

  const cancelAppointment = async () => {
    await cancelEventMutation({ variables: { id } });
  };

  const joinVideoCallProc = async () => {
    const result = await encryptMyToken();
    const url = `${result.data?.EncryptMyToken.headToURL}/videocall/${meeting}`;
    const token = encodeURIComponent(result.data?.EncryptMyToken.accessToken);
    window.open(`${url}?token=${token}&userId=${userId}`, '_blank', 'noreferrer');
  };

  const editAppointmentProc = () => {
    const initialUpdateValues = {
      id,
      clientId,
      meetingType: type,
      eventTypeId,
      start,
      end,
      notes,
    };

    handleUpdateEvent(initialUpdateValues);
  };

  const removeAppointment = async () => {
    await removeEventMutation({ variables: { id } });
  };

  const tz = momentTz().tz(timezone).format('z');
  const resultTimeZone = tz.indexOf('+') === 0 ? `UTC${tz}` : tz;

  return (
    <>
      <Row gutter={15}>
        <Col>
          <CalendarOutlined />
        </Col>
        <Col>
          <Text>{date}</Text>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col>
          <ClockCircleOutlined />
        </Col>
        <Col>
          <Text>{duration === 0 ? 'Whole day event' : `${duration} Minutes`}</Text>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col>
          <HourglassOutlined />
        </Col>
        <Col style={{ whiteSpace: 'nowrap', width: '85%' }}>
          <Text>{`${moment(start).format('LT')} - ${moment(end).format('LT')} ${resultTimeZone}`}</Text>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col>
          <PushpinOutlined />
        </Col>
        <Col style={{ textTransform: 'capitalize' }}>
          <Text>{status.toLowerCase()}</Text>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col>
          {getIcon(type)}
        </Col>
        <Col style={{ textTransform: 'capitalize' }}>
          <Text>{type.toLowerCase()}</Text>
        </Col>
      </Row>

      <Row gutter={15}>
        <Col>
          <TeamOutlined />
        </Col>
        <Col>
          <Text>Participants:</Text>
        </Col>
      </Row>
      {participants.map((element, index) => (
        <Row key={index}>
          {` - ${element}`}
        </Row>
      ))}

      {type === 'VIDEO' && status !== 'CANCELED' && (
        <Row align="middle">
          <Col>
            <LinkOutlined />
          </Col>
          <Col>
            <TaxarooButton
              type="link"
              onClick={joinVideoCallProc}
              loading={loadingEncryptMyToken}
            >
              Join Video Call
            </TaxarooButton>
          </Col>
        </Row>
      )}

      {!isExternal && status !== 'CANCELED' && !loadingCancelEvent && !loadingRemoveEvent && (
        <>
          <Divider style={{ margin: '5px', padding: 0 }} />
          <Row align="middle">
            <Col>
              <EditOutlined />
            </Col>
            <Col>
              <TaxarooButton
                type="link"
                onClick={editAppointmentProc}
              >
                Edit Appointment
              </TaxarooButton>
            </Col>
          </Row>
        </>
      )}

      {!isExternal && (loadingCancelEvent || loadingRemoveEvent) && (
        <Row align="middle" justify="center">
          <Spin size="small" />
        </Row>
      )}

      {!isExternal && status !== 'CANCELED' && !loadingCancelEvent && !loadingRemoveEvent && (
        <Popconfirm
          placement="left"
          title="Are you sure you want to cancel the Appointment?"
          okText="Yes"
          cancelText="No"
          onConfirm={cancelAppointment}
        >
          <Row align="middle">
            <Col>
              <UsergroupDeleteOutlined />
            </Col>
            <Col>
              <TaxarooButton type="link">
                Cancel Appointment
              </TaxarooButton>
            </Col>
          </Row>
        </Popconfirm>
      )}

      {!isExternal && status === 'CANCELED' && !loadingRemoveEvent && (
        <>
          <Divider style={{ margin: '5px', padding: 0 }} />
          <Row align="middle">
            <Col>
              <DeleteOutlined />
            </Col>
            <Col>
              <TaxarooButton
                type="link"
                onClick={removeAppointment}
              >
                Remove Appointment
              </TaxarooButton>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

AppointmentModal.defaultProps = {
  clientId: '',
  eventTypeId: '',
  notes: '',
};

export default AppointmentModal;
