.subtitle {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  margin-top: 11px;
}

.participant {
  font-size: 11px;
  margin: 0;
  margin-top: 4px;
}

.text {
  font-size: 12px;
  margin: 0;
  font-weight: bold;
  margin-top: 4px;
}

.divisor {
  width: 100%;
  height: 1px;
  background-color: #c2c2c2;
  margin-top: 10px;
  margin-bottom: 10px;
}
