import {
  Icons,
  Avatar,
  Badge,
  message,
  Typography,
} from 'taxaroo-ui';
import {
  FC, useEffect,
} from 'react';
import { useAppDispatch, useAppSelector } from '~src/redux/hooks';
import { selectSession } from '~src/redux/slices/sessionSlice';
import { ChannelsState, selectChannels, setChannels } from '~src/redux/slices/chatSlice';
import { useTranslation } from 'react-i18next';
import { useSendChatMessageMutation, useUpdateMessagesMutation } from '~src/graphql/mutations/messaging';
import { ApolloError } from '@apollo/client';
import UseChannels from '~src/components/hooks/useChannels';
import { PhoneType } from '~src/graphql';
import EmptyState from './EmptyState';
import ChatInputAdmin from '../../molecules/ChatInputAdmin';
import MessagesListAdmin from '../MessagesListAdmin';
import * as styles from './style.module.css';

const { MailOutlined, MobileTwoTone } = Icons;
const { Link } = Typography;

const getAcronim = (str: string) => {
  if (!str || str === '') return '';

  const matches = str.match(/\b(\w)/g);
  const acronym = matches?.join('');
  return acronym?.substring(0, 2).toUpperCase();
};

interface ChatProps {
  channelInfo: ChannelsState,
}

const Chat: FC<ChatProps> = ({
  channelInfo,
}) => {
  const dispatch = useAppDispatch();
  const { userId: currentUserId, accessToken: token } = useAppSelector(selectSession);
  const currentChannels = useAppSelector(selectChannels);
  const { t: translation } = useTranslation();
  const [updateMessages] = useUpdateMessagesMutation();

  const { addChannel, messages } = UseChannels(false);
  useEffect(() => {
    addChannel(channelInfo);
  }, [channelInfo]);

  const [sendChatMesage, { loading: loadingSendMessage }] = useSendChatMessageMutation({
    onError: (err: ApolloError) => message.error(err.message, 10),
  });

  const onSendMessage = async (sendMessageObj: {text: string; phone: string; attachment: string}) => {
    // const val = sendMessageObj.text?.trim();
    if (sendMessageObj?.text || sendMessageObj.attachment) {
      const messageRes = await sendChatMesage({
        variables: {
          createMessageInput: {
            channelId: channelInfo.id,
            text: sendMessageObj?.text,
            attachment: sendMessageObj.attachment,
            phone: sendMessageObj.phone,
          },
        },
      });

      // console.log("onSendMessage", messageRes);
    }
  };

  if (!channelInfo) return null;

  const numberRender = () => {
    const channelMember = channelInfo.ChannelMembers
      ?.find(({ userId }) => userId !== currentUserId);
    let phone = '';
    let email = '';

    if (channelMember) {
      const { email: userEmail } = channelMember.Users;
      const { Phones } = channelMember.Users.UserInformation;

      phone = Phones?.find((p) => p.type === PhoneType.Mobile)?.value || Phones?.[0]?.value || '';
      email = userEmail;
    }

    return (
      <p className={styles.contact}>
        {Boolean(phone) && (
          <a href={`tel:${phone}`}>
            <MobileTwoTone style={{ margin: '5px' }} />
            {phone}
          </a>
        )}
        {Boolean(email) && (
          <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
            <MailOutlined style={{ margin: '5px' }} />
            {email}
          </a>
        )}
      </p>
    );
  };

  const getChatName = () => {
    const otherMember = channelInfo.ChannelMembers
      ?.find(({ userId }) => userId !== currentUserId);

    if (otherMember) {
      const { firstName, lastName } = otherMember.Users.UserInformation || {};

      if (`${firstName} ${lastName}` !== channelInfo.description) {
        return `${firstName} ${lastName}`;
      }

      return channelInfo.description;
    }

    return '';
  };

  const getUserEntityId = () => {
    const otherMember = channelInfo.ChannelMembers
      ?.find(({ userId }) => userId !== currentUserId);

    if (otherMember) {
      return otherMember.Users.Entity?.id;
    }
    return null;
  };

  const getUserDetailTopCenter = () => {
    const channelMember = channelInfo.ChannelMembers
      ?.find(({ userId }) => userId !== currentUserId);

    return (
      <div className={styles.chatAdminHeader}>
        <span>
          <Badge dot className={styles.chatAdminBadge} color={channelInfo.status ? 'green' : 'gray'}>
            <Avatar style={{ backgroundColor: '#22A7E7' }} size={50}>
              {getAcronim(getChatName())}
            </Avatar>
          </Badge>
        </span>
        <div className={styles.infotextcont}>
          <Link className={styles.name} href={`/clients/details?entityId=${getUserEntityId()}`}>{getChatName()}</Link>
          {numberRender()}
          <p className={styles.active}>
            {/* {taxpayer.status ? translation('chat.userActive') : taxpayer.lastConnection} */}
            {/* {channelInfo.status ? translation('chat.userActive') : moment(channelMember?.lastConnection).fromNow()} */}
          </p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const updateMessagesInput = [...(channelInfo.Messages ?? [])]
      .filter(({ userId, readDate }) => userId !== currentUserId && !readDate)
      .map(({ id }) => ({ id, readDate: new Date() }));

    if (updateMessagesInput.length) {
      updateMessages({
        variables: {
          updateMessagesInput,
        },
      }).then(({ data }) => {
        dispatch(setChannels(currentChannels.map((channel) => {
          if (channel.id === channelInfo?.id) {
            return {
              ...channel,
              Messages: channelInfo.Messages.map((m) => {
                const currentMessage = data.updateMessages
                  .find(({ id: currentId }) => m.id === currentId);

                if (currentMessage) {
                  return currentMessage;
                }

                return currentMessage;
              }),
            };
          }

          return channel;
        })));
      }).catch((error) => console.log({ error }));
    }
  }, []);

  return (
    <div className={styles.chatAdminContainer}>
      { getUserDetailTopCenter() }
      {channelInfo.Messages?.length === 0
        ? (
          <EmptyState />
        )
        : (
          <MessagesListAdmin
            data={messages[channelInfo.id]}
            channelId={channelInfo.id}
          />
        )}

      <ChatInputAdmin
        // channelId={channelInfo.id}
        onSendMessage={onSendMessage}
        clientData={channelInfo.ChannelMembers?.find(
          ({ userId }) => userId !== currentUserId,
        )?.Users.UserInformation}
        loadingSendMessage={loadingSendMessage}
      />
    </div>
  );
};

export default Chat;
