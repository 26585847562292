/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import { FC, useState } from 'react';
import { TaxarooButton, Typography } from 'taxaroo-ui';
import moment from 'moment';
import { Calendar, momentLocalizer, View } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Availability from '../Availability';
import './style.css';

const localizer = momentLocalizer(moment);
const { Text } = Typography;

interface AvailabilityBracketsProps {
    availabilities: Array<any>
    color: string
    deleteBracket: any
}

const AvailabilityBrackets: FC<AvailabilityBracketsProps> = ({
  availabilities,
  color,
  deleteBracket,
}) => {
  const [currentView, setCurrentView] = useState<View>('week');

  const getBrackets = () => {
    const brackets: any[] = [];
    availabilities.forEach((availaby) => {
      const date = moment().day(availaby.day);
      availaby.EventTypeAvailabilityBracket.forEach((bracket: any) => {
        const {
          startHour, startMinute, endHour, endMinute,
        } = bracket;
        const start = moment(date).set({ hour: startHour, minute: startMinute });
        const end = moment(date).set({ hour: endHour, minute: endMinute });
        brackets.push({
          start: start.toDate(),
          end: end.toDate(),
          day: availaby.day,
          bracket,
        });
      });
    });
    return brackets;
  };

  return (
    <div className="brackets-container">
      <Calendar
        localizer={localizer}
        events={getBrackets()}
        defaultView={currentView}
        view={currentView}
        startAccessor="start"
        endAccessor="end"
        toolbar={false}
        components={{
          event: ({ event }) => <Availability event={event} color={color} deleteBracket={deleteBracket} />,
          header: ({ date, localizer: l }) => <Text>{l.format(date, 'dddd')}</Text>,
          timeGutterHeader: () => {
            if (currentView === 'day') {
              return (
                <TaxarooButton
                  type="link"
                  onClick={() => setCurrentView('week')}
                >
                  Back
                </TaxarooButton>
              );
            }
            return null;
          },
        }}
        onView={(view) => setCurrentView(view)}
        className="brackets-container"
      />
    </div>
  );
};

export default AvailabilityBrackets;
