import { useEffect, useRef, FC } from 'react';

function useOutsideAlerter(ref: any, onOutside: any) {
  useEffect(() => {
    /**
         * Alert if clicked on outside of element
         */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutside();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

interface OutsideAlerterProps {
    onOutside: any
    children: any
}

/**
 * Component that alerts if you click outside of it
 */
const OutsideAlerter: FC<OutsideAlerterProps> = ({ onOutside, children }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onOutside);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideAlerter;
