import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChannelEntity, MessageEntity } from '~src/graphql';
import { RootState } from '../store';

export type ChannelsState = ChannelEntity & { status?: boolean };

export type MessagesState = {
  [key: string]: Array<MessageEntity>; // key is channelId
};

export type ChatState = {
  channels: Array<ChannelsState>;
  selectedChannel?: ChannelsState;
  messages: MessagesState;
}

const CHAT_INITIAL_STATE: ChatState = {
  channels: [],
  selectedChannel: undefined,
  messages: {},
};

const chatSlice = createSlice({
  name: 'chat',
  initialState: CHAT_INITIAL_STATE,
  reducers: {
    setSelectedChannel: (state, action: PayloadAction<ChannelsState>) => ({
      ...state,
      selectedChannel: action.payload,
    }),
    setChannels: (state, action: PayloadAction<Array<ChannelsState>>) => ({
      ...state,
      channels: action.payload,
    }),
    addMessages: (state, action: PayloadAction<{ channelId: string, messages: Array<MessageEntity> }>) => {
      const newMessages = [
        ...(state.messages[action.payload.channelId] ?? []),
        ...action.payload.messages,
      ];
      const sortedMessages = newMessages?.slice().sort((a, b) => {
        if (new Date(a.createAt) > new Date(b.createAt)) return 1;
        if (new Date(a.createAt) < new Date(b.createAt)) return -1;
        return 0;
      });
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.payload.channelId]: sortedMessages,
        },
      };
    },
    setChannelStatusInList: (state, action: PayloadAction<{ id: string, status: boolean }>) => ({
      ...state,
      channels: state.channels.map((channel) => {
        if (channel.id === action.payload.id) {
          return {
            ...channel,
            status: action.payload.status,
          };
        }

        return channel;
      }),
    }),
    resetChat: () => ({
      channels: [],
      selectedChannel: undefined,
      messages: {},
    }),
  },
});

export const {
  resetChat,
  setChannels,
  setSelectedChannel,
  setChannelStatusInList,
  addMessages,
} = chatSlice.actions;

export const selectCurrentChannel = (state: RootState) => state.chat.selectedChannel;

export const selectChannels = (state: RootState) => state.chat.channels;

export const selectMessages = (state: RootState) => state.chat.messages;

export default chatSlice.reducer;
