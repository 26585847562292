import { useState, useEffect } from 'react';
import { Button, Tooltip, Popconfirm } from 'taxaroo-ui';
import { TooltipPlacement } from 'taxaroo-ui/node_modules/antd/lib/tooltip';
import { TaxButtonProps } from 'taxaroo-ui/src/Button/Button';
import './style.css';

interface ActionButtonProps {
  tooltip: string;
  tooltipPosition?: TooltipPlacement;
  confirmAction?: boolean;
  confirmLabel?: string;
  onClick: () => void;
  children?: any;
  loading?: boolean;
  useLoading?: boolean;
  buttonProps?: TaxButtonProps;
}

const defaultProps = {
  tooltipPosition: 'bottom',
  confirmAction: false,
  confirmLabel: '',
  children: null,
  loading: false,
  useLoading: true,
  buttonProps: {},
};

function ActionButton({
  tooltip,
  tooltipPosition = 'bottom',
  confirmAction = false,
  confirmLabel = '',
  onClick = () => undefined,
  children = null,
  loading: initLoad = false,
  useLoading = true,
  buttonProps = {
    type: 'primary',
    disabled: false,
    danger: false,
  },
}: ActionButtonProps) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(initLoad);
  const showPopconfirm = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleClick = () => {
    setLoading(true);
    onClick();
    setLoading(false);
    setVisible(false);
  };

  const handleConfirm = async () => {
    await handleClick();
  };

  useEffect(() => {
    setLoading(initLoad);
  }, [initLoad]);

  return (
    <Tooltip placement={tooltipPosition} title={tooltip}>
      {confirmAction ? (
        <Popconfirm
          title={confirmLabel}
          visible={visible}
          onConfirm={handleConfirm}
          okButtonProps={{ loading }}
          onCancel={handleCancel}
        >
          <Button
            onClick={showPopconfirm}
            loading={useLoading ? loading : false}
            {...buttonProps}
            className={`${buttonProps.className} popconfirmActionButton`}
          >
            {!loading && children}
          </Button>
        </Popconfirm>
      ) : (
        <Button
          onClick={handleClick}
          loading={useLoading ? loading : false}
          {...buttonProps}
          className={`${buttonProps.className} popconfirmActionButton`}
        >
          {!loading && children}
        </Button>
      )}
    </Tooltip>
  );
}

ActionButton.defaultProps = defaultProps;

export default ActionButton;
