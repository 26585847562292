import { Select } from 'taxaroo-ui';

const { Option } = Select;

interface SelectNumJobsDropdownProps {
  type?: 'clients' | 'jobs';
  onChange: (arg0: number) => void;
  currentPageSize: number;
}
function SelectNumJobsDropdown({ type, onChange, currentPageSize }: SelectNumJobsDropdownProps) {
  const handleChange = (value) => {
    onChange(parseInt(value, 10));
  };

  const typeStr = type === 'clients' ? 'clients' : 'jobs';
  return (
    <Select
      defaultValue={currentPageSize.toString()}
      style={{ marginRight: '5px' }}
      onChange={handleChange}
      value={currentPageSize.toString()}
    >
      <Option value="25">
        {`Show 25 ${typeStr}`}
      </Option>
      <Option value="100">
        {`Show 100 ${typeStr}`}
      </Option>
      <Option value="250">
        {`Show 250 ${typeStr}`}
      </Option>
      <Option value="500">
        {`Show 500 ${typeStr}`}
      </Option>
    </Select>
  );
}

SelectNumJobsDropdown.defaultProps = {
  type: 'clients',
};

export default SelectNumJobsDropdown;
