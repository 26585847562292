import { ApolloError } from '@apollo/client';
import { message } from 'taxaroo-ui';

const handleOnError = () => {
  message.error('An error has occurred, please try again.');
};

const handleOnErrorFirmLink = (error?: ApolloError) => {
  let text;
  if (error?.graphQLErrors?.at(0)?.name === 'RecordAlreadyInDb') {
    text = 'That link is already taken. Please choose another.';
  } else {
    text = 'An error has occurred, please try again.';
  }
  message.error(text);
};

const handleOnErrorUserLink = (error?: ApolloError) => {
  let text;
  if (error?.graphQLErrors?.at(0)?.name === 'RecordAlreadyInDb') {
    text = 'That link is already taken. Please choose another.';
  } else {
    text = 'An error has occurred, please try again.';
  }
  message.error(text);
};

const handleOnSuccessFirmLink = () => {
  message.success('Firm Invite Link saved successfully!');
};

const handleOnSuccessUserLink = () => {
  message.success('Personal Invite Link saved successfully!');
};

const handleOnSuccessLink = () => {
  message.success('Link saved successfully!');
};

export {
  handleOnError,
  handleOnErrorFirmLink,
  handleOnErrorUserLink,
  handleOnSuccessFirmLink,
  handleOnSuccessUserLink,
  handleOnSuccessLink,
};
