import { Menu, Icons } from 'taxaroo-ui';
import * as styles from '../styles.module.css';

const {
  PlusOutlined,
  UserAddOutlined,
  CloudUploadOutlined,
} = Icons;
const { SubMenu, Item } = Menu;

function AddSubMenu(props: any) {
  return (
    <SubMenu
      icon={<PlusOutlined />}
      title="Add"
      // className={styles.addSubMenu}
      {...props}
    >
      <Item
        icon={<UserAddOutlined />}
        // className={styles.addLinkItem}
        key="add-client"
      >
        Add Client
      </Item>
      <Item
        icon={<CloudUploadOutlined />}
        // className={styles.addLinkItem}
        key="import-clients"
      >
        Import Clients
      </Item>
    </SubMenu>
  );
}

export default AddSubMenu;
