import {
  FC, CSSProperties, useEffect, useState,
} from 'react';
import {
  Row,
  Col,
  Popover,
  Tag,
  Avatar,
  Select,
  Icons,
  Input,
  Typography,
} from 'taxaroo-ui';
import moment from 'moment';
import { useDrag } from 'react-dnd';
import { FieldType } from '~src/graphql';
import { useDownloadFileFromS3Mutation } from '~src/graphql/mutations/clients';
import SignatureIcon from '../../../../assets/icons/signature.svg';

const { Text } = Typography;
const {
  DeleteTwoTone, CalendarTwoTone, DragOutlined, EditTwoTone,
} = Icons;
const { Option } = Select;

interface SignatureFieldPostDroppedProps {
  id: string;
  fieldType: FieldType;
  left: number;
  top: number;
  componentName: string;
  index?: number;
  deleteSignatureFieldButtonOnClick: any;
  updateSigner: any;
  updateTitle: any;
  signersArray: any[];
  title: string;
  description: string;
  signerId: string;
  signatureImage: string;
  fileApprovalId: string;
  completedAt: string;
}

interface DragItem {
  name: string
}

const SignatureFieldPostDropped: FC<SignatureFieldPostDroppedProps> = ({
  id,
  fieldType,
  left,
  top,
  componentName,
  index = 0,
  deleteSignatureFieldButtonOnClick,
  updateSigner,
  updateTitle,
  signersArray,
  title,
  description,
  signerId,
  signatureImage,
  fileApprovalId,
  completedAt,
}) => {
  const [signatureURL, setSignatureURL] = useState('');
  const [urlS3File] = useDownloadFileFromS3Mutation();
  const getSignatureURL = async (s3Key: string) => {
    const { data } = await urlS3File({ variables: { s3Key } });
    setSignatureURL(data?.downloadFile?.signedUrl);
  };

  useEffect(() => {
    if (signatureImage) {
      getSignatureURL(signatureImage);
    }
  }, [signatureImage]);

  useEffect(() => {
    // if only one file approver (signer), set them as the selected value right away
    if (signersArray?.length === 1 && signerId !== signersArray[0].key) {
      updateSigner(id, signersArray[0].key);
    }
  }, [signersArray]);

  // this is for dragging an already placed field
  const [, drag] = useDrag(
    () => ({
      type: 'SIGNATURE_BOUNDARY_FIELD',
      item: {
        fieldType, componentName, index, left, top, id, signerId, signatureImage, fileApprovalId, completedAt,
      },
      end: (item: any, monitor) => {
        const dropResult = monitor.getDropResult<DragItem>();
        if (item && dropResult) {
          // console.log('SignatureFieldPostDropped: You dropped: ', 'item: ', item, 'dropResult: ', dropResult);
          // alert(`You dropped ${item.type} into ${dropResult.name}!`)
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [fieldType, componentName, index, left, top, id],
  );

  const getPopoverContent = () => (
    <div>
      <Row justify="space-between" align="middle">
        <Col style={{ width: '100px' }}>
          Select Client:
        </Col>
        <Col>
          <Select
            size="small"
            defaultValue={signerId}
            onChange={(sid: string) => updateSigner(id, sid)}
            onSelect={(sid: string) => updateSigner(id, sid)} // Sometimes the value is already selected but not saved, this helps recall updateSigner
            style={{ width: '200px', marginLeft: '5px' }}
            disabled={completedAt}
          >
            {signersArray.map((x) => (
              <Option
                key={`${id}-${x.key}`}
                value={x.key}
              >
                {x.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          {completedAt
            ? null
            : (
              <Avatar
                style={{ marginLeft: '5px' }}
                icon={<DeleteTwoTone onClick={() => { deleteSignatureFieldButtonOnClick(index); }} />}
                title="Remove field"
              />
            )}
        </Col>
      </Row>

      {fieldType === FieldType.Text && (
        <Row justify="start" align="middle" style={{ marginTop: '5px' }}>
          <Col style={{ width: '100px' }}>
            Field Label:
          </Col>
          <Col>
            <Input
              size="small"
              style={{ width: '200px', marginLeft: '5px' }}
              defaultValue={title}
              disabled={completedAt}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateTitle(id, e.target.value)}
            />
          </Col>
        </Row>
      )}
    </div>
  );

  const currentSignerName = signersArray.find((s) => s.key === signerId)?.name || '';
  const style: CSSProperties = {
    border: currentSignerName ? '1px dashed gray' : '1px dotted #f34',
    backgroundColor: completedAt ? '#ddd' : 'gold',
    width: '250px',
    height: '32px',
    position: 'absolute',
    zIndex: 1,
    fontSize: 'medium',
    padding: signatureImage ? 0 : '6px 0 0 12px',
  };

  return (
    <Popover placement="top" content={getPopoverContent}>
      <Tag
        ref={completedAt ? null : drag}
        style={{ ...style, left, top }}
      >
        {completedAt
          ? null
          : (
            <DragOutlined
              style={{
                position: 'absolute',
                left: -6,
                top: -6,
                background: 'white',
                borderRadius: '35%',
                border: '1px dotted gray',
                cursor: 'move',
              }}
            />
          )}
        {signatureImage && (<img src={signatureURL} width="250" height="32" alt="signature" />)}

        {!signatureImage && fieldType === FieldType.Signature
          && (
            <Row style={{ flexFlow: 'nowrap' }}>
              <SignatureIcon style={{
                height: '20px', width: '20px', minWidth: '20px', marginRight: '7px', fill: '#1890ff',
              }}
              />
              <Text ellipsis style={{ color: '#1890ff' }}>
                {
                currentSignerName ? `SIGNATURE - ${currentSignerName}` : 'SIGNATURE'
              }
              </Text>
            </Row>
          )}

        {fieldType === FieldType.Date && (
        <Row style={{ flexFlow: 'nowrap' }}>
          <CalendarTwoTone style={{
            height: '16px', width: '16px', minWidth: '16px', marginRight: '7px', fill: '#1890ff',
          }}
          />
          <Text ellipsis style={{ color: '#1890ff' }}>
            {
              completedAt
                ? moment.utc(completedAt).format('MM/DD/YYYY')
                : `DATE ${currentSignerName ? ` - ${currentSignerName}` : ''}`
            }
          </Text>
        </Row>
        )}

        {fieldType === FieldType.Text && (
        <Row style={{ flexFlow: 'nowrap' }}>
          <EditTwoTone style={{
            height: '16px', width: '16px', minWidth: '16px', marginRight: '7px', fill: '#1890ff',
          }}
          />
          <Text ellipsis style={{ color: '#1890ff' }}>
            {
              // eslint-disable-next-line no-nested-ternary
              completedAt
                ? description
                : title
                  ? `${title} ${currentSignerName ? ` - ${currentSignerName}` : ''}`
                  : `TEXT ${currentSignerName ? ` - ${currentSignerName}` : ''}`
            }
          </Text>
        </Row>
        )}
      </Tag>
    </Popover>
  );
};

SignatureFieldPostDropped.defaultProps = {
  index: 0,
};

export default SignatureFieldPostDropped;
