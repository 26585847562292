import {
  Card,
  Typography,
  Tag,
  Button,
  Icons,
  Row,
  Col,
} from 'taxaroo-ui';
import * as styles from './styles.module.css';

const { Title, Paragraph } = Typography;
const { TagOutlined } = Icons;

interface OfferCardProps {
  offerName: string;
  saveAmount?: number;
  body: string;
  handleRedeemOffer: () => void;
  loading: boolean;
  buttonText: string;
  featuresTitle?: string;
  featuresColumn1?: string[];
  featuresColumn2?: string[];
  bottomBullets?: string[];
}

const OfferCard = ({
  offerName,
  saveAmount,
  body,
  handleRedeemOffer,
  loading,
  buttonText,
  featuresTitle,
  featuresColumn1,
  featuresColumn2,
  bottomBullets,
}: OfferCardProps) => (
  <Card
    title={(
      <Title level={3} style={{ margin: 0, color: 'white', textWrap: 'wrap' }}>
        {offerName}
      </Title>
    )}
    style={{
      marginBottom: '20px',
      background: 'var(--primary-color)',
    }}
  >
    <Row style={{ marginTop: -30 }}>
      {saveAmount > 0 && (
        <Tag icon={<TagOutlined />} color="var(--primary-dark)">
          {`Save $${saveAmount.toFixed(2)} per year`}
        </Tag>
      )}
      <Paragraph style={{ color: 'white', marginTop: 15, marginBottom: -10 }}>
        {body}
      </Paragraph>
      {featuresTitle && (
        <Title level={4} style={{ color: 'white' }}>
          {featuresTitle}
        </Title>
      )}
      <Row gutter={24} style={{ width: '100%', fontSize: 18 }}>
        {featuresColumn1 && (
        <Col sm={24} md={24} lg={12} xl={12}>
          <ul className={styles.featuresList} style={{ marginBottom: 0, listStyle: 'outside' }}>
            {featuresColumn1.map((feature) => (
              <li key={feature}>{feature}</li>
            ))}
          </ul>
        </Col>
        )}
        {featuresColumn2 && (
          <Col sm={24} md={24} lg={12} xl={12}>
            <ul className={styles.featuresList} style={{ listStyle: 'outside' }}>
              {featuresColumn2.map((feature) => (
                <li key={feature}>{feature}</li>
              ))}
            </ul>
          </Col>
        )}
      </Row>
      {bottomBullets && (
        <Row style={{ width: '100%', fontSize: 18, marginTop: -18 }}>
          <Col>
            <ul className={styles.featuresList} style={{ listStyle: 'outside' }}>
              {bottomBullets.map((feature) => (
                <li key={feature}>{feature}</li>
              ))}
            </ul>
          </Col>
        </Row>
      )}
      <Button
        type="default"
        style={{ marginTop: '10px', width: '100%' }}
        size="large"
        onClick={handleRedeemOffer}
        loading={loading}
      >
        {buttonText}
      </Button>
    </Row>
  </Card>
);

OfferCard.defaultProps = {
  saveAmount: 0,
  featuresTitle: '',
  featuresColumn1: [],
  featuresColumn2: [],
  bottomBullets: [],
};

export default OfferCard;
