.announcementBar {
  background: var(--primary-dark);
  text-align: center;
}

.announcementBar p {
  color: white;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: bolder;
  padding: 0 10px;
  margin-top: 4px;
  margin-bottom: 4px;
}
