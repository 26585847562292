import { Formik } from 'formik';
import moment from 'moment-timezone';
import React, { useEffect, useState, createRef } from 'react';
import {
  Typography, Row, Col, Layout, Space, Card, message, Spin, Alert, Button,
} from 'taxaroo-ui';
import {
  useFirmAccountByIdQuery,
  GetUserInformationDocument,
  useGetUserInformationQuery,
  useGetBusinessInformationQuery,
  GetBusinessInformationDocument,
} from '~src/graphql/queries/settings';
import {
  useUpdateFirmAccountMutation,
  useUpdateUserInformationMutation,
  useUpdateBusinessInformationMutation,
  useCreateBusinessInformationMutation,
  useRemoveBusinessInformationMutation,
} from '~src/graphql/mutations/settings';
import { useAppSelector } from '~src/redux/hooks';
import { AddressType, PhoneType } from '~src/graphql';
import FormikInput from '~src/components/Formik/FormikInput';
import SettingsHeader from '~src/components/organisms/Settings/SettingsHeader';
import { PREPARER_VALIDATION, STAFF_VALIDATION } from '~src/components/Formik/ValidationSchemas/Settings/firmInfo';
import { PreparerInformation, staffInformation } from './formattedFields';
import { CustomErrorProps, PreparerFormValues, StaffFormValues } from './typesDefinition';

const { Paragraph, Text } = Typography;

// Initialize all values with zero or an empty string
const PREPARER_INITIAL_VALUES: PreparerFormValues = {
  tax: 0,
  zip: '',
  city: '',
  other: 0,
  state: '',
  payroll: 0,
  lastName: '',
  firstName: '',
  taxReturns: 0,
  accounting: 0,
  bookKeeping: 0,
  dateOfBirth: '',
  phoneNumber: '',
  businessZip: '',
  addressLine1: '',
  businessType: '',
  addressLine2: '',
  taxResolution: 0,
  businessCity: '',
  businessName: '',
  businessState: '',
  businessPhone: '',
  taxPracticeYears: 0,
  businessAddressLine1: '',
  businessAddressLine2: '',
};

const STAFF_INITIAL_VALUES: StaffFormValues = {
  zip: '',
  city: '',
  state: '',
  lastName: '',
  firstName: '',
  dateOfBirth: '',
  phoneNumber: '',
  addressLine1: '',
  addressLine2: '',
};

const formikRef = createRef<any>();

const FirmInfo = () => {
  // Get user information from Redux store
  const { userRole, userInformationId, firmAccountId } = useAppSelector((state) => state.session);
  // Form Values State
  const [isStaff] = useState<boolean>(userRole === 'STAFF');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasWarning, setHasWarning] = useState<boolean>(false);
  const [personalPhoneId, setPersonalPhoneId] = useState<string>('');
  const [businessPhoneId, setBusinessPhoneId] = useState<string>('');
  const [isPreparer] = useState<boolean>(userRole === 'TAX_PREPARER');
  const [personalAddressId, setPersonalAddressId] = useState<string>('');
  const [businessAddressId, setBusinessAddressId] = useState<string>('');
  const [staffValues, setStaffValues] = useState<StaffFormValues>(STAFF_INITIAL_VALUES);
  const [preparerValues, setPreparerValues] = useState<PreparerFormValues>(PREPARER_INITIAL_VALUES);
  // State to show custom errors
  const [customError, setCustomError] = useState<CustomErrorProps>(null);
  // Get business info
  const {
    data: businessInfoData,
    loading: loadingBusinessInfo,
  } = useGetBusinessInformationQuery();
  // Create business info
  const [
    createBusinessInformationMutation,
  ] = useCreateBusinessInformationMutation();
  // Update business info
  const [
    updateBusinessInformationMutation,
  ] = useUpdateBusinessInformationMutation();
  // Query for getting custom domain
  const { data, loading: loadingQuery } = useGetUserInformationQuery({
    variables: {
      id: userInformationId,
    },
  });
  // Mutation to update user information
  const [updateUserInformation] = useUpdateUserInformationMutation({
    onCompleted: () => {
      window.scrollTo(0, 0);
    },
    refetchQueries: [{
      query: GetUserInformationDocument,
      variables: { id: userInformationId },
    }, ...(isPreparer && businessInfoData ? [
      {
        query: GetBusinessInformationDocument,
      },
    ] : [])],
    awaitRefetchQueries: true,
  });
  // eslint-disable-next-line
  const [removeBusinessInfo] = useRemoveBusinessInformationMutation();
  const [updateFirmAccount] = useUpdateFirmAccountMutation();
  const { data: firmAccountData } = useFirmAccountByIdQuery({
    variables: {
      id: firmAccountId,
    },
  });

  useEffect(() => {
    if (isPreparer && data && businessInfoData && firmAccountData) {
      const {
        firstName, lastName, birthday: dateOfBirth, Phones, Address: Addresses,
      } = data.UserInformation;

      const {
        other,
        taxReturns,
        taxPercent: tax,
        Phones: BusinessPhones,
        payrollPercent: payroll,
        Address: BusinessAddresses,
        accountingPercent: accounting,
        bookKeepingPercent: bookKeeping,
        yearsInBusiness: taxPracticeYears,
        taxResolutionPercent: taxResolution,
      } = businessInfoData.BusinessInformation;
      const phone = Phones?.filter(({ type }) => type !== 'OFFICE')[0] ?? null;
      const { name: businessName, businessType } = firmAccountData.FirmAccountById;
      const address = Addresses?.filter(({ type }) => type !== 'BUSINESS')[0] ?? null;
      const businessPhone = BusinessPhones?.filter(({ type }) => type === 'OFFICE')[0] ?? null;
      const businessAddress = BusinessAddresses?.filter(({ type }) => type === 'BUSINESS')[0] ?? null;

      setPreparerValues({
        ...preparerValues,
        tax,
        other,
        payroll,
        lastName,
        firstName,
        taxReturns,
        accounting,
        bookKeeping,
        businessType,
        businessName: businessName ?? '',
        taxResolution: taxResolution ?? 0,
        taxPracticeYears,
        ...(phone ? {
          phoneNumber: phone.value,
        } : {}),
        dateOfBirth: moment(dateOfBirth),
        ...(businessPhone ? {
          businessPhone: businessPhone.value,
        } : {}),
        ...(address ? {
          zip: address.zip,
          city: address.city,
          state: address.state,
          addressLine1: address.lineOne,
          addressLine2: address.lineTwo ?? '',
        } : {}),
        ...(businessAddress ? {
          businessZip: businessAddress.zip,
          businessCity: businessAddress.city,
          businessState: businessAddress.state,
          businessAddressLine1: businessAddress.lineOne,
          businessAddressLine2: businessAddress.lineTwo,
        } : {}),
      });

      if (phone) {
        setPersonalPhoneId(phone.id);
      }

      if (address) {
        setPersonalAddressId(address.id);
      }
      if (businessPhone) {
        setBusinessPhoneId(businessPhone.id);
      }
      if (businessAddress) {
        setBusinessAddressId(businessAddress.id);
      }
    } else if (isPreparer && data && firmAccountData) {
      const {
        firstName, lastName, birthday: dateOfBirth, Phones, Address: Addresses,
      } = data.UserInformation;
      const { businessType } = firmAccountData.FirmAccountById;
      const phone = Phones.filter(({ type }) => type !== 'OFFICE')[0] ?? null;
      const address = Addresses.filter(({ type }) => type !== 'BUSINESS')[0] ?? null;

      setPreparerValues({
        ...preparerValues,
        lastName,
        firstName,
        businessType,
        ...(phone ? {
          phoneNumber: phone.value,
        } : {}),
        ...(address ? {
          zip: address.zip,
          city: address.city,
          state: address.state,
          addressLine1: address.lineOne,
          dateOfBirth: moment(dateOfBirth),
          addressLine2: address.lineTwo ?? '',
        } : {}),
      });

      if (phone) {
        setPersonalPhoneId(phone.id);
      }

      if (address) {
        setPersonalAddressId(address.id);
      }
    }

    if (isStaff && data) {
      const {
        firstName, lastName, birthday: dateOfBirth, Phones, Address: Addresses,
      } = data.UserInformation;
      const phone = Phones.filter(({ type }) => type !== 'OFFICE')[0] ?? null;
      const address = Addresses.filter(({ type }) => type !== 'BUSINESS')[0] ?? null;

      setStaffValues({
        ...staffValues,
        firstName,
        lastName,
        dateOfBirth,
        ...(phone ? {
          phoneNumber: phone.value,
        } : {}),
        ...(address ? {
          zip: address.zip,
          city: address.city,
          state: address.state,
          addressLine1: address.lineOne,
          addressLine2: address.lineTwo,
        } : {}),
      });

      if (phone) {
        setPersonalPhoneId(phone.id);
      }

      if (address) {
        setPersonalAddressId(address.id);
      }
    }
  }, [data, businessInfoData, firmAccountData]);

  const setPercentage = (
    tax: number,
    accounting: number,
    book: number,
    payroll: number,
    taxR: number,
    other: number,
  ) => {
    const total = (tax || 0)
      + (accounting || 0)
      + (book || 0)
      + (payroll || 0)
      + (taxR || 0)
      + (other || 0);
    return total;
  };

  // eslint-disable-next-line
  const submitForm = (values: PreparerFormValues | StaffFormValues, resetForm?: (nextState: any) => void) => {
    if (isPreparer && 'tax' in values) {
      const {
        tax,
        zip,
        city,
        other,
        state,
        payroll,
        lastName,
        firstName,
        accounting,
        taxReturns,
        businessZip,
        bookKeeping,
        phoneNumber,
        dateOfBirth,
        businessType,
        businessName,
        businessCity,
        addressLine1,
        addressLine2,
        businessState,
        taxResolution,
        businessPhone,
        taxPracticeYears,
        businessAddressLine1,
        businessAddressLine2,
      } = values;

      const totalPercent = tax
      + accounting
      + bookKeeping
      + payroll
      + taxResolution
      + other;

      if (businessType === 'COMPANY' && totalPercent !== 100) {
        setCustomError({
          keys: [
            'tax',
            'other',
            'payroll',
            'accounting',
            'bookKeeping',
            'taxResolution',
          ],
          error: 'Values must add to 100%',
        });
      } else {
        // Format business address to use in mutation
        const businessAddress = {
          zip: businessZip,
          city: businessCity,
          state: businessState,
          id: businessAddressId,
          lineOne: businessAddressLine1,
          lineTwo: businessAddressLine2,
        };
        // Format business phone to use in mutation
        const businessPhoneNumber = {
          id: businessPhoneId,
          value: businessPhone,
        };
        // Format personal address to use in mutation
        const personalAddress = {
          zip,
          city,
          state,
          lineOne: addressLine1,
          lineTwo: addressLine2,
          id: personalAddressId,
          type: 'PERSONAL' as AddressType,
        };
        // Format personal phone number to use in mutation
        const personalPhoneNumber = {
          id: personalPhoneId,
          value: phoneNumber,
          type: 'PERSONAL' as PhoneType,
        };
        setCustomError(null);

        setIsLoading(true);
        Promise.all([
          updateUserInformation({
            variables: {
              updateUserInformationInput: {
                lastName,
                firstName,
                id: userInformationId,
                birthday: dateOfBirth,
                Phones: [
                  personalPhoneNumber,
                ],
                Address: [
                  {
                    ...personalAddress,
                  },
                ],
              },
            },
          }),
          businessInfoData && businessInfoData.BusinessInformation.id
            ? [
              ...(
                businessType !== '' ? [
                  businessType === 'COMPANY' ? [
                    updateBusinessInformationMutation({
                      variables: {
                        updateBusinessInformationInput: {
                          other,
                          taxReturns,
                          businessName,
                          businessType,
                          taxPercent: tax,
                          ...(((businessAddressId.length && businessPhoneId.length)
                          || (!businessAddressId.length && businessPhoneId.length)
                          || (businessAddressId.length && !businessPhoneId.length)) ? {
                              Address: [
                                businessAddress,
                              ],
                              Phones: [
                                businessPhoneNumber,
                              ],
                            } : {}),
                          payrollPercent: payroll,
                          accountingPercent: accounting,
                          bookKeepingPercent: bookKeeping,
                          yearsInBusiness: taxPracticeYears,
                          taxResolutionPercent: taxResolution,
                          id: businessInfoData.BusinessInformation.id,
                        },
                      },
                    }),
                  ] : [
                    updateFirmAccount({
                      variables: {
                        updateFirmAccountInput: {
                          name: null,
                          businessType,
                          id: firmAccountId,
                        },
                      },
                    }),
                    removeBusinessInfo({
                      variables: {
                        id: businessInfoData.BusinessInformation.id,
                      },
                    }),
                  ],
                ] : []
              ),
            ]
            : [
              ...(businessType ? [
                updateFirmAccount({
                  variables: {
                    updateFirmAccountInput: {
                      businessType,
                      id: firmAccountId,
                      name: businessName,
                    },
                  },
                }),
              ] : []),
              ...(businessType === 'COMPANY') ? [
                createBusinessInformationMutation({
                  variables: {
                    createBusinessInformationInput: {
                      other,
                      taxReturns,
                      taxPercent: tax,
                      payrollPercent: payroll,
                      accountingPercent: accounting,
                      bookKeepingPercent: bookKeeping,
                      yearsInBusiness: taxPracticeYears,
                      taxResolutionPercent: taxResolution,
                      Phones: [
                        {
                          value: businessPhone,
                          type: 'OFFICE' as PhoneType,
                        },
                      ],
                      Address: [
                        {
                          zip: businessZip,
                          city: businessCity,
                          state: businessState,
                          lineOne: businessAddressLine1,
                          lineTwo: businessAddressLine2,
                          type: 'BUSINESS' as AddressType,
                        },
                      ],
                    },
                  },
                }),
              ] : [],
            ],
        ])
          .then(() => {
            message.success('Information saved successfully');
            if (typeof resetForm === 'function' && values.businessType === 'INDIVIDUAL') {
              resetForm({
                ...values,
                tax: PREPARER_INITIAL_VALUES.tax,
                other: PREPARER_INITIAL_VALUES.other,
                payroll: PREPARER_INITIAL_VALUES.payroll,
                taxReturns: PREPARER_INITIAL_VALUES.taxReturns,
                accounting: PREPARER_INITIAL_VALUES.accounting,
                businessZip: PREPARER_INITIAL_VALUES.businessZip,
                bookKeeping: PREPARER_INITIAL_VALUES.bookKeeping,
                businessName: PREPARER_INITIAL_VALUES.businessName,
                businessCity: PREPARER_INITIAL_VALUES.businessCity,
                businessPhone: PREPARER_INITIAL_VALUES.businessPhone,
                taxResolution: PREPARER_INITIAL_VALUES.taxResolution,
                businessState: PREPARER_INITIAL_VALUES.businessState,
                taxPracticeYears: PREPARER_INITIAL_VALUES.taxPracticeYears,
                businessAddressLine1: PREPARER_INITIAL_VALUES.businessAddressLine1,
                businessAddressLine2: PREPARER_INITIAL_VALUES.businessAddressLine2,
              });
              setPreparerValues({
                ...values,
                tax: PREPARER_INITIAL_VALUES.tax,
                other: PREPARER_INITIAL_VALUES.other,
                payroll: PREPARER_INITIAL_VALUES.payroll,
                taxReturns: PREPARER_INITIAL_VALUES.taxReturns,
                accounting: PREPARER_INITIAL_VALUES.accounting,
                businessZip: PREPARER_INITIAL_VALUES.businessZip,
                bookKeeping: PREPARER_INITIAL_VALUES.bookKeeping,
                businessName: PREPARER_INITIAL_VALUES.businessName,
                businessCity: PREPARER_INITIAL_VALUES.businessCity,
                businessPhone: PREPARER_INITIAL_VALUES.businessPhone,
                taxResolution: PREPARER_INITIAL_VALUES.taxResolution,
                businessState: PREPARER_INITIAL_VALUES.businessState,
                taxPracticeYears: PREPARER_INITIAL_VALUES.taxPracticeYears,
                businessAddressLine1: PREPARER_INITIAL_VALUES.businessAddressLine1,
                businessAddressLine2: PREPARER_INITIAL_VALUES.businessAddressLine2,
              });
            } else if (typeof resetForm === 'function') {
              resetForm(values);
            }
          })
          .catch((error) => message.error(error.message))
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else {
      const {
        zip,
        city,
        state,
        lastName,
        firstName,
        phoneNumber,
        addressLine1,
        addressLine2,
        dateOfBirth: birthday,
      } = values;

      // format personal addres to be used in mutation
      const personalAddress = {
        zip,
        city,
        state,
        lineOne: addressLine1,
        lineTwo: addressLine2,
        id: personalAddressId,
        type: 'PERSONAL' as AddressType,
      };
      // Format personal phone number to use in mutation
      const personalPhoneNumber = {
        id: personalPhoneId,
        value: phoneNumber,
        type: 'PERSONAL' as PhoneType,
      };

      setIsLoading(true);
      updateUserInformation({
        variables: {
          updateUserInformationInput: {
            birthday,
            lastName,
            firstName,
            id: userInformationId,
            ...(personalAddressId ? {
              Address: [personalAddress],
            } : {}),
            Phones: [
              personalPhoneNumber,
            ],
          },
        },
      }).then(() => {
        message.success('Information saved successfully');
      })
        .catch((error) => message.error(error.message))
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // eslint-disable-next-line
  const handleSubmitForm = (values: PreparerFormValues | StaffFormValues, resetForm: (nextState?: any) => void) => {
    if ('businessType' in values && isPreparer) {
      // Related business information
      const {
        tax,
        other,
        payroll,
        taxReturns,
        accounting,
        businessZip,
        bookKeeping,
        businessName,
        businessCity,
        businessPhone,
        taxResolution,
        businessState,
        taxPracticeYears,
        businessAddressLine1,
        businessAddressLine2,
      } = values;
      const relatedBusinessValues = {
        tax,
        other,
        payroll,
        taxReturns,
        accounting,
        businessZip,
        bookKeeping,
        businessName,
        businessCity,
        businessPhone,
        taxResolution,
        businessState,
        taxPracticeYears,
        businessAddressLine1,
        businessAddressLine2,
      };

      if (!hasWarning && values.businessType === 'INDIVIDUAL' && Object.values(relatedBusinessValues).some((value) => value)) {
        setHasWarning(true);
      } else if (resetForm) {
        submitForm(values, resetForm);
      } else {
        submitForm(values);
      }
    } else {
      submitForm(values);
    }
  };

  if (loadingQuery || loadingBusinessInfo) {
    return <Spin size="large" />;
  }

  return (
    <Layout style={{ backgroundColor: 'white', paddingRight: '10px' }}>
      <SettingsHeader
        title={isStaff ? 'Personal Info' : 'Firm Info'}
        tooltipTitle="What do we do with this information?"
        tooltipContent={(
          <Paragraph>
            {`We brand your client's home page with your business name, email,
          and phone number. This allows them to easily stay in touch.`}
          </Paragraph>
        )}
      />
      <Row>
        <Formik
          enableReinitialize
          innerRef={formikRef}
          initialValues={!isPreparer && isStaff ? staffValues : preparerValues}
          onSubmit={(values) => handleSubmitForm(values, formikRef?.current?.resetForm)}
          validationSchema={!isPreparer && isStaff ? STAFF_VALIDATION : PREPARER_VALIDATION}
        >
          {({
            values,
            errors,
            touched,
            resetForm,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Space size="large" direction="vertical" style={{ width: '100%' }}>
                <Card size="small" title={<b>Your Personal Information:</b>} bordered={false}>
                  <Row gutter={[16, 16]}>
                    {isPreparer ? PreparerInformation(preparerValues).map((item) => {
                      if (item.key === 'tax' && 'businessType' in values && values.businessType === 'COMPANY') {
                        return (
                          <>
                            <Col span={24}>
                              <Text>Tell us about your practice so we can better serve you:</Text>
                            </Col>
                            <Col {...item.span} key={item.key}>
                              <FormikInput
                                item={item}
                                addonAfter="%"
                                errors={errors}
                                touched={touched}
                                handleBlur={handleBlur}
                                value={values[item.key]}
                                customError={customError}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                              />
                            </Col>
                          </>
                        );
                      }
                      if (item.key === 'businessName' && 'businessType' in values && values.businessType === 'COMPANY') {
                        return (
                          <>
                            <Col span={24}>
                              <b>Your Business Information:</b>
                            </Col>
                            <Col {...item.span} key={item.key}>
                              <FormikInput
                                item={item}
                                errors={errors}
                                touched={touched}
                                handleBlur={handleBlur}
                                value={values[item.key]}
                                customError={customError}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                              />
                            </Col>
                          </>
                        );
                      }

                      if ('businessType' in values && (!values.businessType || values.businessType === 'INDIVIDUAL')) {
                        switch (item.key) {
                          case 'businessName':
                          case 'businessPhone':
                          case 'businessAddressLine1':
                          case 'businessAddressLine2':
                          case 'businessCity':
                          case 'businessState':
                          case 'businessZip':
                          case 'taxReturns':
                          case 'taxPracticeYears':
                          case 'tax':
                          case 'accounting':
                          case 'bookKeeping':
                          case 'payroll':
                          case 'taxResolution':
                          case 'other':
                            return false;
                          default:
                        }
                      }

                      return (
                        <Col {...item.span} key={item.key}>
                          <FormikInput
                            item={item}
                            errors={errors}
                            touched={touched}
                            handleBlur={handleBlur}
                            value={values[item.key]}
                            customError={customError}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            addonAfter={(
                              item.key === 'accounting'
                              || item.key === 'bookKeeping'
                              || item.key === 'payroll'
                              || item.key === 'taxResolution'
                              || item.key === 'other')
                              ? '%' : null}
                          />
                        </Col>
                      );
                    }) : staffInformation(staffValues).map((item) => (
                      <Col {...item.span} key={item.key}>
                        <FormikInput
                          item={item}
                          errors={errors}
                          touched={touched}
                          handleBlur={handleBlur}
                          value={values[item.key]}
                          customError={customError}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          addonAfter={(
                            item.key === 'accounting'
                            || item.key === 'bookKeeping'
                            || item.key === 'payroll'
                            || item.key === 'taxResolution'
                            || item.key === 'other'
                          ) ? '%' : null}
                        />
                      </Col>
                    ))}
                    {
                      'tax' in values && values.businessType && values.businessType === 'COMPANY' && (
                      <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Text strong>
                          Total Percentage:
                          {' '}
                          {setPercentage(
                            values.tax,
                            values.accounting,
                            values.bookKeeping,
                            values.payroll,
                            values.taxResolution,
                            values.other,
                          )}
                          %
                        </Text>
                      </Col>
                      )
                    }
                  </Row>
                </Card>
                {isLoading ? (
                  <Spin size="small" />
                ) : (
                  <Button disabled={hasWarning} type="primary" htmlType="submit">
                    Save Information
                  </Button>
                )}
              </Space>
              {
                hasWarning && (
                  <Alert
                    style={{
                      marginTop: 20,
                    }}
                    type="warning"
                    message="You are changing the way you operate, it is possible that information about your business may be lost. Are you sure?"
                    action={(
                      <Space direction="vertical">
                        <Button
                          size="small"
                          type="primary"
                          onClick={() => {
                            setHasWarning(false);
                            handleSubmitForm(values, resetForm);
                          }}
                        >
                          Accept
                        </Button>
                        <Button
                          size="small"
                          danger
                          onClick={() => {
                            setHasWarning(false);
                          }}
                        >
                          Decline
                        </Button>
                      </Space>
                    )}
                  />
                )
              }
            </form>
          )}
        </Formik>
      </Row>
    </Layout>
  );
};

export default FirmInfo;
