import { Spin } from 'taxaroo-ui';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InviteClientForm from '~src/components/organisms/InviteClient';
import { useAppDispatch } from '~src/redux/hooks';
import { resetSession } from '~src/redux/slices/sessionSlice';
import { resetClientData } from '~src/redux/slices/clientSlice';
import style from './style.module.css';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useGetClientOnboardingDataLazyQuery, useGetLanguagesQueryLazyQuery } from '../../../graphql/queries/clients';

type ClientParams = {
  accessToken: string;
};

const InviteClientParams = () => {
  const query = useQueryParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const access = query.get('access');
  const [getClientData, { loading, data: clientData }] = useGetClientOnboardingDataLazyQuery();
  const [getLanguages, {
    data: languagesData,
  }] = useGetLanguagesQueryLazyQuery();

  useEffect(() => {
    dispatch(resetSession());
    dispatch(resetClientData());

    getClientData({
      context: {
        headers: {
          authorization: access,
        },
      },
    });

    getLanguages({
      context: {
        headers: {
          authorization: access,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (clientData && languagesData && clientData.ClientOnboardingData.allowed && !loading) {
      navigate('/invite/activate', {
        state: {
          accessToken: access,
          data: clientData.ClientOnboardingData,
          ...(languagesData ? {
            languages: languagesData.languages,
          } : {}),
        },
      });
    } else if (clientData && !clientData.ClientOnboardingData.allowed) {
      navigate('/login');
    }
  }, [clientData, languagesData]);

  return (
    <div
      className={style.spinerContainer}
    >
      <Spin
        size="large"
      />
      <strong>Processing data ...</strong>
    </div>
  );
};

const InviteClient = () => (
  <InviteClientForm />
);

export { InviteClient, InviteClientParams, ClientParams };
