import { useState } from 'react';
import {
  Form,
  Card,
  Input,
  Button,
  Row,
  Grid,
  Typography,
  message,
  Space,
  Skeleton,
  Alert,
} from 'taxaroo-ui';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { BusinessInformationEntity, BusinessType, UserEntity } from '~src/graphql';
import { useGetPasswordResetIncludeFirmQuery } from '~src/graphql/queries/passwordReset';
import { useActivateClientAccountMutation } from '~src/graphql/mutations/passwordReset';
import { useGetLanguagesQueryLazyQuery } from '~src/graphql/queries/clients';
import { useLoginMutation } from '~src/graphql/mutations/login';
import { setAllLanguages, setSession } from '~src/redux/slices/sessionSlice';
import { useAppDispatch } from '~src/redux/hooks';
import Logo from '~src/assets/images/taxaroo_logo.svg';
import PasswordInputWithTooltip from '~src/components/molecules/PasswordInputWithTooltip';
import * as styles from './style.module.css';

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

const getFirmAddress = (businessType: BusinessType, { User, BusinessInformation }: {
  User: UserEntity,
  BusinessInformation: BusinessInformationEntity
}) => {
  if (businessType === 'INDIVIDUAL') {
    const [Address] = User.UserInformation.Address.filter(({ type }) => type === 'PERSONAL');

    if (Address) {
      const {
        lineOne, lineTwo, city, state, zip,
      } = Address;

      return `${lineOne ?? ''}, ${lineTwo ?? ''} ${city}, ${state} ${zip}`;
    }
  }

  const [Address] = BusinessInformation?.Address.filter(({ type }) => type === 'BUSINESS') ?? [];

  if (Address) {
    const {
      lineOne, lineTwo, city, state, zip,
    } = Address;

    return `${lineOne ?? ''}, ${lineTwo ?? ''} ${city}, ${state} ${zip}`;
  }

  return '';
};

const getFirmPhoneNumber = (businessType: BusinessType, { User, BusinessInformation }: {
  User: UserEntity,
  BusinessInformation: BusinessInformationEntity
}) => {
  if (businessType === 'INDIVIDUAL') {
    const [Phone] = User.UserInformation.Phones.filter(({ type }) => type === 'PERSONAL');

    if (Phone) {
      const { value } = Phone;

      return value ?? '';
    }

    return '';
  }
  const [Phone] = BusinessInformation.Phones.filter(({ type }) => type === 'OFFICE');

  if (Phone) {
    const { value } = Phone;

    return value ?? '';
  }

  return '';
};

function ActivateAccount() {
  const [error, setError] = useState(null);
  const [finalPassword, setFinalPassword] = useState('');
  const screens = useBreakpoint();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const baseUrl = window.location.origin;

  // graphql
  const [getLanguages] = useGetLanguagesQueryLazyQuery();
  const [login, { loading: loadingLogin }] = useLoginMutation();
  // fetch user and firm name by PasswordResetId
  const { data, loading: loadingPasswordReset } = useGetPasswordResetIncludeFirmQuery({
    variables: { id },
    onError: (err) => {
      if (err.message.includes('1001')) {
        setError('CannotFindRecordException');
      } else if (err.message.includes('4002')) {
        setError('PasswordResetExpiredException');
      } else {
        message.error(err.message);
      }
    },
    onCompleted: () => {
      setError(null);
    },
  });
  const { Users } = data?.passwordResetIncludeFirm || {};
  const { email, UserFirmAccount } = Users || {};
  const firmName = UserFirmAccount?.length === 1 ? UserFirmAccount[0].FirmAccount.name : null;
  // mutation to reset password
  const [resetPassword, { loading: loadingActivatingAccount }] = useActivateClientAccountMutation({
    onError: (err) => {
      if (err.message.includes('1005')) {
        message.error('Password doesn\'t meet the requirements.');
      } else {
        message.error(err.message);
      }
    },
    onCompleted: async () => {
      message.success('Account activated.', 10);
      const languagesResult = await getLanguages();
      const { languages } = languagesResult.data;

      const loginResult = (await login({
        variables: {
          loginInput: {
            email,
            password: finalPassword,
          },
        },
      })).data?.Login ?? {};

      if (loginResult.accessToken?.length) {
        const { accessToken, UserFirmAccount: loginResultUserFirmAccount } = loginResult;
        const { FirmAccount, Users: loginResultUsers, Roles } = loginResultUserFirmAccount;
        const { businessType, Users: FirmUser, BusinessInformation } = FirmAccount;
        const { UserInformation } = loginResultUsers;

        dispatch(setSession({
          accessToken,
          id: UserInformation?.id ?? '',
          email: loginResultUsers?.email ?? '',
          userId: UserInformation?.userId ?? '',
          userInformationId: UserInformation?.id ?? '',
          firstName: UserInformation?.firstName ?? '',
          lastName: UserInformation?.lastName ?? '',
          birthday: UserInformation?.birthday ?? '',
          ssn: UserInformation?.ssn ?? '',
          profilePhoto: UserInformation?.profilePhoto ?? '',
          firmAccountId: FirmAccount?.id ?? '',
          languageId: loginResultUsers?.languageId ?? '',
          userRole: Roles?.description ?? '',
          progressStatuses: [...(FirmAccount.ProgressStatuses ?? [])].map(({
            id: statusId,
            status,
            underlyingStatus,
            index,
          }) => ({
            status, underlyingStatus, index, id: statusId,
          })),
          firmName: FirmAccount.name ?? '',
          firmAddress: getFirmAddress(businessType, {
            User: FirmUser,
            BusinessInformation,
          }),
          firmPhoneNumber: getFirmPhoneNumber(businessType, {
            User: FirmUser,
            BusinessInformation,
          }),
          firmCreatedAt: FirmAccount.createAt ?? '',
        }));
        dispatch(setAllLanguages({ languages }));

        navigate('/onboarding');
      }
    },
  });

  const onFinish = (values: any) => {
    resetPassword({
      variables: {
        activateClientInput: {
          uuid: id,
          password: values.the_pass,
          passwordConfirmation: values.the_confirm_pass,
        },
      },
    });
    setFinalPassword(values.the_pass);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
    message.error('Account activation failed.');
  };

  if (error) {
    return (
      <div className={styles['activate-account-page']}>
        <div className={styles['activate-account-wrapper']}>
          <Card
            style={{
              padding: screens.md ? '10px 80px' : '5px',
              maxWidth: '900px',
            }}
          >
            <Row justify="center" style={{ margin: '0 0 60px 0' }}>
              <Logo width="170" height="40" />
            </Row>
            <Row justify="center">
              <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <Title level={3}>Activate Your Account</Title>
                {error === 'CannotFindRecordException' && (
                  <Alert
                    type="error"
                    showIcon
                    message="Record could not be found."
                    description="This could be becuase you have already activated your account. To update your password please select forgot password from the login screen."
                    style={{ marginTop: '40px', maxWidth: '500px' }}
                  />
                )}
                {error === 'PasswordResetExpiredException' && (
                  <Alert
                    type="error"
                    showIcon
                    message="This link has expired."
                    description="Please request a new link from your firm admin."
                    style={{ marginTop: '40px' }}
                  />
                )}
              </div>
            </Row>
            <div style={{ textAlign: 'center' }}>
              <Link to="/login">Go to login</Link>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div className={styles['activate-account-page']}>
      <div className={styles['activate-account-wrapper']}>
        <Card
          style={{
            padding: screens.md ? '10px 80px' : '5px',
            maxWidth: '900px',
          }}
        >
          <Row justify="center" style={{ margin: '0 0 60px 0' }}>
            <Logo width="170" height="40" />
          </Row>
          <Row justify="center">
            <Form
              name="Activate Account"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              // style={{ width: '300px' }}
            >
              <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <Title level={3}>Activate Your Account</Title>
                {loadingPasswordReset
                  ? <Skeleton paragraph={{ rows: 2, width: 500 }} style={{ marginTop: 40 }} />
                  : (
                    <Space direction="vertical">
                      {firmName && (
                        <Text>
                          <b>{firmName}</b>
                        </Text>
                      )}
                      <Text>
                        To get started, please create a password below.
                        <br />
                        In the future you can sign in directly at
                        <Link to={`${baseUrl}/login`}>
                          {' '}
                          {baseUrl}
                          /login
                        </Link>
                        {' '}
                        with the email address
                        {' '}
                        <b>{email}</b>
                        .
                      </Text>
                    </Space>
                  )}
              </div>
              <Form.Item
                name="the_pass"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'The password is required',
                  },
                ]}
              >
                {/* div around PasswordInputWithTooltip is required for form to work for some reason */}
                <div>
                  <PasswordInputWithTooltip
                    tooltipProps={{
                      placement: 'topLeft',
                    }}
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="the_confirm_pass"
                label="Confirm Password"
                dependencies={['the_pass']}
                rules={[{ required: true }, ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('the_pass') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                })]}
              >
                <Input.Password autoComplete="disabled" placeholder="Confirm Password" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingActivatingAccount}
                  style={{ width: '100%', justifyContent: 'center' }}
                  size="large"
                >
                  Activate Your Account
                </Button>
              </Form.Item>
              <div style={{ textAlign: 'center' }}>
                <Link to="/login">Go to login</Link>
              </div>
            </Form>
          </Row>
        </Card>
      </div>
    </div>
  );
}

export default ActivateAccount;
