.templateContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.templateSelect {
  width: 50%;
  margin-right: 0.5em;
}
