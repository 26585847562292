import React from 'react';
import {
  Layout as AntLayout,
  LayoutProps,
} from 'antd';
import { SiderProps } from 'antd/lib/layout';

export interface TaxLayoutProps extends LayoutProps {
  elevated?: boolean;
}

const Layout: React.FC<TaxLayoutProps>&{
  Header: React.FC<LayoutProps>
  Sider: React.FC<SiderProps>
  Content: React.FC<LayoutProps>
  Footer: React.FC<LayoutProps>
} = (props) => (
  <AntLayout
    {...props}
  />
);

Layout.Header = AntLayout.Header;
Layout.Sider = AntLayout.Sider;
Layout.Content = AntLayout.Content;
Layout.Footer = AntLayout.Footer;

export default Layout;
