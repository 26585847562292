import { useEffect, useState } from 'react';
import {
  Tabs, Icons, Modal, Badge, message, Collapse,
} from 'taxaroo-ui';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  useCustomDomainUserQuery,
  useGetProgressStatusesQuery,
} from '~src/graphql/queries/settings';
import {
  InterviewEntity, PhonesEntity, ReviewStatus, TaskEntity, TaxYearEntity, TaxYearStatus,
} from '~src/graphql';
import AddClientModal from '~src/components/molecules/AddClientModal';
import { useAppDispatch, useAppSelector } from '~src/redux/hooks';
import { setAllLanguages } from '~src/redux/slices/sessionSlice';
import { useGetLanguagesQueryLazyQuery } from '~src/graphql/queries/clients';
import { useUpdateTaxYearInterviewProgressStatusMutation } from '~src/graphql/mutations/entity';
import { CUSTOMER_IO_EVENTS, trackCustomDomain, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import DroppableTabPane from '../../molecules/DroppableTabPane';
import JobsTable from '../JobsTable';
import getInProgressColumns from './columns/inProgressColumns';
import getUnassignedColumns from './columns/unassignedColumns';
import getCompletedColumns from './columns/completedColumns';
import LoadingIndicator from '../../atoms/LoadingIndicator';
import './styles.css';
import getClientList from './columns/clientListColumns';
import ClientsTable from '../JobsTable/ClientsTable';
import DeletedClientsTable from '../JobsTable/DeletedClientsTable';

const {
  UnorderedListOutlined, InboxOutlined, CheckOutlined, FolderOpenOutlined,
} = Icons;

const { TabPane } = Tabs;

export interface RecordTarget {
  id: string;
  email: string;
  userId: string;
  jobType: string;
  lastName: string;
  entityId: string;
  taxYear?: number;
  TaxYear?: TaxYearEntity;
  firstName: string;
  taxYearId: string;
  assignedAt: Date;
  languageId: string;
  invitationEmailSent: boolean;
  password?: string;
  phone: PhonesEntity;
  reviewedId?: string;
  preparedId?: string;
  interviewId: string;
  interview: InterviewEntity;
  timeInQueue?: number;
  progressStatusId: string;
  completedDate?: number;
  inProgressTime?: number;
  workNumber: PhonesEntity;
  userInformationId: string;
  taxYearInterviewId: string;
  landlineNumber: PhonesEntity;
  createAt: Date | undefined | null;
  updateAt: Date | undefined | null;
  Tasks: Array<TaskEntity>;
  notes: string | null;
  reviewStatus: ReviewStatus | null;
}

const JobProgressTabs = () => {
  const dispatch = useAppDispatch();
  const { userRole } = useAppSelector((state) => state.session);
  const clientListColumns = getClientList(userRole);
  const today = new Date();
  const [isAddClientModalVisible, setIsAddClientModalVisible] = useState(false);

  // graphql
  const { data: progressStatusesData, loading: loadingProgressStatuses } = useGetProgressStatusesQuery();
  const progressStatuses = progressStatusesData?.ProgressStatuses || [];
  const [
    updateTaxYearInterviewProgressStatus,
    { loading: loadingUpdateTaxYearInterviewProgressStatus },
  ] = useUpdateTaxYearInterviewProgressStatusMutation({
    onCompleted: () => {
      message.success('Job status updated successfully!');
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  // add property for identity tracking
  useCustomDomainUserQuery({
    onCompleted: (result) => {
      trackCustomDomain(result.CustomDomainUser?.name, result.CustomDomainUser?.status);
    },
  });

  const [progressStatusDnDConfirmModalVisible, setProgressStatusDnDConfirmModalVisible] = useState(false);
  const [dndData, setDndData] = useState(null);
  const [activeTab, setActiveTab] = useState<string>('clients');
  const [tabCountMap, setTabCountMap] = useState({});
  const [getLanguages, { data: languagesData }] = useGetLanguagesQueryLazyQuery();
  const [deletedClientOpened, setDeletedClientOpened] = useState(false);
  const [clientsCount, setClientsCount] = useState(0);

  const timeElapsed = (date: string) => {
    const prevDate = new Date(date);
    const difference = today.getTime() - prevDate.getTime();
    return difference;
  };

  const handleClickTab = (key: string) => {
    setActiveTab(key);

    trackCustomerIoEvent(CUSTOMER_IO_EVENTS.TAB_SELECT, {
      'Tab Description': 'Dashboard Tabs',
      'Tab Selected': progressStatuses.find((ps) => ps.id === key)?.status || key,
      'Records Count': key === 'clients' ? clientsCount : tabCountMap[key],
      url: window.location.href,
    });
  };

  const handleProgressStatusDnDConfirmModal = () => {
    // update job progress status
    try {
      updateTaxYearInterviewProgressStatus({
        variables: {
          id: dndData?.record?.taxYearInterviewId,
          progressStatusId: dndData?.dropResult?.id,
        },
        refetchQueries: [
          'GetClientsByProgressStatusId',
        ],
        awaitRefetchQueries: true,
      });
    } catch (error) {
      message.error('Something went wrong while trying to update the job status');
    }
    setProgressStatusDnDConfirmModalVisible(false);
  };

  const returnTableColumns = (underlyingStatus: TaxYearStatus, progressStatusId: string) => {
    switch (underlyingStatus) {
      case 'UNASSIGNED':
        return getUnassignedColumns(userRole, progressStatusId);
      case 'IN_PROGRESS':
        return getInProgressColumns(userRole, progressStatusId);
      case 'COMPLETED':
        return getCompletedColumns(userRole, progressStatusId);
      case 'CUSTOM':
        return getInProgressColumns(userRole, progressStatusId);
      default:
        return getInProgressColumns(userRole, progressStatusId);
    }
  };

  const returnTableIcons = (underlyingStatus: TaxYearStatus) => {
    switch (underlyingStatus) {
      case 'UNASSIGNED':
        return <InboxOutlined />;
      case 'IN_PROGRESS':
        return <FolderOpenOutlined />;
      case 'COMPLETED':
        return <CheckOutlined />;
      case 'CUSTOM':
        return <FolderOpenOutlined />;
      default:
        return <FolderOpenOutlined />;
    }
  };

  // store all languages in session slice
  useEffect(() => {
    if (languagesData) {
      const { languages } = languagesData;
      dispatch(setAllLanguages({ languages }));
    }
  }, [languagesData]);

  useEffect(() => {
    getLanguages();
  }, []);

  if (!setProgressStatusDnDConfirmModalVisible || !setDndData) {
    return null;
  }

  if (loadingProgressStatuses) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'flex-start' }}>
        <LoadingIndicator size="calc(100vh - 100px)" type="full" id="loading_jobs" />
      </div>
    );
  }

  const handleDeleteClientCollapseChange = (keys) => {
    if (keys.length > 0) {
      setDeletedClientOpened(true);
    } else {
      setDeletedClientOpened(false);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Tabs
        activeKey={activeTab}
        onTabClick={handleClickTab}
        type="card"
        defaultActiveKey={progressStatuses?.[0]?.id}
      >
        <TabPane
          tab={(
            <span>
              <UnorderedListOutlined />
              Client List
            </span>
          )}
          key="clients"
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ClientsTable
              setProgressStatusDnDConfirmModalVisible={setProgressStatusDnDConfirmModalVisible}
              setDndData={setDndData}
              columns={clientListColumns}
              loadingProp={loadingUpdateTaxYearInterviewProgressStatus}
              setClientsCount={setClientsCount}
            />
            <div style={{ height: '15px' }} />
            <Collapse
              onChange={(keys) => { handleDeleteClientCollapseChange(keys); }}
            >
              <Collapse.Panel
                header="Deleted Clients"
              >
                <DeletedClientsTable
                  loadData={deletedClientOpened}
                />
              </Collapse.Panel>
            </Collapse>
          </div>
        </TabPane>
        {progressStatuses
          && progressStatuses.map((item) => (
            <TabPane
              forceRender
              tab={(
                <DroppableTabPane
                  name={item.status}
                  id={item.id}
                  node={(
                    <span>
                      {returnTableIcons(item.underlyingStatus)}
                      {item.status}
                      <Badge
                        className="column-count-badge"
                        count={item.underlyingStatus !== 'COMPLETED' ? tabCountMap[item.id] : null} // Don't show count for completed
                        overflowCount={9999}
                      />
                    </span>
                  )}
                />
              )}
              key={item.id}
            >
              <JobsTable
                setProgressStatusDnDConfirmModalVisible={setProgressStatusDnDConfirmModalVisible}
                setDndData={setDndData}
                columns={returnTableColumns(item.underlyingStatus, item.id)}
                progressStatusId={item.id}
                progressStatusName={item.status}
                underlyingStatus={item.underlyingStatus}
                setTabCountMap={setTabCountMap}
                loadingProp={loadingUpdateTaxYearInterviewProgressStatus}
              />
            </TabPane>
          ))}
      </Tabs>
      <AddClientModal
        isModalVisible={isAddClientModalVisible}
        setIsModalVisible={setIsAddClientModalVisible}
      />
      <Modal
        title="Confirm"
        visible={progressStatusDnDConfirmModalVisible}
        onOk={handleProgressStatusDnDConfirmModal}
        onCancel={() => setProgressStatusDnDConfirmModalVisible(false)}
        okText="Confirm"
        okButtonProps={{ loading: loadingUpdateTaxYearInterviewProgressStatus }}
        cancelText="Cancel"
      >
        <span>
          {`${dndData?.record?.firstName} ${dndData?.record?.lastName}`}
          <b>
            {` ${dndData?.record?.taxYear} - ${dndData?.record?.jobType} `}
          </b>
          will be moved to
          <b>
            {` ${dndData?.dropResult?.name}`}
          </b>
          .
        </span>
      </Modal>
    </DndProvider>
  );
};

export default JobProgressTabs;
