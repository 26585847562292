.option-list-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.option-list-header {
  display: flex;
  flex-direction: column;
}

.option-list-header > button {
  width: max-content;
  margin-bottom: 16px;
}

.option-element {
  flex: 1;
  display: flex;
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 12px;
  flex-direction: row;
  background-color: #fafafa;
  box-shadow: 0 0 5px #0000001a;
}

.option-element > button {
  margin-left: 24px;
}
