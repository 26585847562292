import { FC, useState, useEffect } from 'react';
import {
  Typography,
  Row,
  Col,
  Icons,
  Button,
  Avatar,
  Alert,
  Spin,
} from 'taxaroo-ui';
import { useGetUserProfilePhotoPresignedUrlMutation, useUpdateUserProfilePhotoMutation } from '~src/graphql/mutations/settings';
import { useAppDispatch, useAppSelector } from '~src/redux/hooks';
import { setProfilePhoto } from '~src/redux/slices/sessionSlice';
import SettingsHeader from '~src/components/organisms/Settings/SettingsHeader';
import axios from 'axios';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import * as styles from './styles.module.css';
import AvatarSelector from './avatar.jsx'; // 'react-avatar-edit';

const { UserOutlined } = Icons;
const { Title, Paragraph, Text } = Typography;
const MAX_IMAGE_SIZE = 8 * 1024 * 1024; // 8MB

const ProfilePhoto: FC = () => {
  // Manage photo to be uploaded
  const [preview, setPreview] = useState<string | null>(null);
  const [updatingPhoto, setUpdatingPhoto] = useState<boolean>(false);
  // Handle errors
  const [error, setError] = useState<string | null>(null);
  // Access to user information in the Redux store
  const { id, profilePhoto } = useAppSelector((state) => state.session);
  // Access dispatcher to update profile photo
  const dispatch = useAppDispatch();
  // Mutation hook to update profile photo
  const [updateUserProfilePhoto, { data, loading }] = useUpdateUserProfilePhotoMutation();
  const [getUserProfilePhotoPresignedURL,
    { loading: presignedUrlLoading }] = useGetUserProfilePhotoPresignedUrlMutation();
  const [uploadingToS3, setUploadingToS3] = useState<boolean>(false);

  const onClose = () => {
    console.log('ProfilePhoto.onClose');
    setPreview(null);
  };

  const onCrop = (prev: string) => {
    console.log('ProfilePhoto.onCrop');
    setPreview(prev);
  };

  const onBeforeFileLoad = (elem: React.ChangeEvent<HTMLInputElement>) => {
    const { size } = elem.target.files[0];
    console.log('ProfilePhoto.onBeforeFileLoad - size:', size);
    if (size > MAX_IMAGE_SIZE) {
      setError('File to be uploaded is too big. Max image size 8MB.');
    }
  };

  const handleUpdatePhoto = () => {
    setError(null);
    setUpdatingPhoto(true);
  };

  const handleCancelFile = () => {
    setPreview(null);
    setUpdatingPhoto(false);
  };

  const handleSaveChanges = async () => {
    try {
      const presignedUrlData = await getUserProfilePhotoPresignedURL({
        variables: {
          updateUserInformationInput: {
            id,
          },
        },
      });
      const fileUrl = new URL(presignedUrlData.data.GetUserProfilePhotoPresignedURL);

      setUploadingToS3(true);
      await axios.put(
        fileUrl.toString(),
        Buffer.from(preview.split(',')[1], 'base64'),
        {
          headers: {
            'Content-Type': 'image/png',
          },
        },
      );
      setUploadingToS3(false);

      await updateUserProfilePhoto({
        variables: {
          updateUserInformationInput: {
            id,
            profilePhoto: `${fileUrl.origin}${fileUrl.pathname}`,
          },
        },
      });

      trackCustomerIoEvent(CUSTOMER_IO_EVENTS.CLICK, {
        Title: 'Update profile photo',
        Source: 'Button click',
        url: window.location.href,
      });
    } catch (e) {
      setUploadingToS3(false);
      console.warn(e);
      setError('Error while trying to upload the photo');
    }
  };

  useEffect(() => {
    if (data) {
      dispatch(
        setProfilePhoto(
          `${data.updateUserProfilePhoto.profilePhoto}?cacheControl=${Math.trunc(Math.random() * 1e15)}`,
        ),
      );
      setUpdatingPhoto(false);
      setPreview(null);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setUpdatingPhoto(false);
      setPreview(null);
    }
  }, [error]);

  const isLoading = loading || presignedUrlLoading || uploadingToS3;

  return (
    <>
      <SettingsHeader
        title="Profile Photo"
        tooltipTitle="Why do we need your picture?"
        tooltipContent={(
          <Paragraph>
            {`Your profile photo adds to your trusted
            brand and will be presented on your client's home page.`}
          </Paragraph>
        )}
      />
      <Row className={styles.descriptionRow}>
        <Text small>
          Image type should be jpeg or png. Max image size 8 MB.
        </Text>
      </Row>
      <Row
        gutter={[30, 0]}
        style={{ marginTop: '15px' }}
      >
        {isLoading && (<Spin size="large" />)}

        {!isLoading && updatingPhoto
          ? (
            <>
              <Col sm={24} md={18} lg={16} xl={14} className={styles.fileUploadCol}>
                <div style={{ cursor: 'pointer' }}>
                  <AvatarSelector
                    width={380}
                    imageWidth={380}
                    height={285}
                    onCrop={onCrop}
                    onClose={onClose}
                    onBeforeFileLoad={onBeforeFileLoad}
                    label="Click to upload your profile photo"
                    labelStyle={{
                      fontSize: '1.25em',
                      fontWeight: 700,
                      color: 'black',
                      display: 'inline-block',
                      fontFamily: 'sans-serif',
                      lineHeight: 285,
                      width: '100%',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </Col>
              <Col sm={24} md={14} lg={7} xl={5}>
                <Title level={4} style={{ textAlign: 'center' }}>Preview</Title>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Avatar
                    size={145}
                    src={preview}
                    alt="Preview"
                    icon={<UserOutlined />}
                  />
                </div>
              </Col>
              <Col span={24}>
                <Row gutter={[10, 0]} style={{ marginTop: '20px', marginLeft: '10px' }}>
                  <Col>
                    <Button
                      type="default"
                      size="large"
                      onClick={handleCancelFile}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      size="large"
                      onClick={handleSaveChanges}
                      disabled={!preview}
                    >
                      Save Changes
                    </Button>
                  </Col>
                </Row>
              </Col>
            </>
          )
          : (
            <>
              <Col sm={14} md={11} lg={8}>
                <Avatar
                  size={175}
                  src={profilePhoto}
                  alt="Profile Photo"
                  icon={<UserOutlined />}
                />
                <Button
                  type="default"
                  size="large"
                  onClick={handleUpdatePhoto}
                  loading={loading}
                  className={styles.uploadProfilePhotoBtn}
                >
                  Update Profile Photo
                </Button>
              </Col>
              <Col sm={14} md={11} lg={8}>
                {error && (
                <Alert
                  style={{ marginTop: 15, marginBottom: 15 }}
                  type="warning"
                  message={error}
                  description="Please, try again"
                />
                )}
              </Col>
            </>
          )}
      </Row>
    </>
  );
};

export default ProfilePhoto;
