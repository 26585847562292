/* eslint-disable max-len */
import { FC, useEffect, useState } from 'react';
import {
  Typography, Row, Space, Divider, Layout, Spin, message,
} from 'taxaroo-ui';
import InviteLinkRow from '~src/components/organisms/InviteLinkRow';
import SettingsHeader from '~src/components/organisms/Settings/SettingsHeader';
import CustomDomainStatusAlert from '~src/components/atoms/CustomDomainStatusAlert';
import {
  useUpdateFirmUniqueLinkMutation,
  useUpdateUserUniqueLinkMutation,
} from '~src/graphql/mutations/settings';
import { CustomDomainStatuses } from '~src/graphql';
import CustomDomainDNSModal from '~src/components/atoms/CustomDomainDNSModal';
import {
  GetFirmUniqueLinkDocument,
  GetUserUniqueLinkDocument,
  useGetFirmUniqueLinkQuery,
  useGetUserUniqueLinkQuery,
  useGetCustomDomainUserQuery,
  useCustomDomainCertificateStatusLazyQuery,
  useCustomDomainCnameStatusLazyQuery,
  useIsDomainLiveLazyQuery,
} from '~src/graphql/queries/settings';
import { useAppSelector } from '~src/redux/hooks';
import * as styles from './style.module.css';
import {
  handleOnErrorFirmLink, handleOnErrorUserLink, handleOnSuccessUserLink, handleOnSuccessFirmLink,
} from './utils';

const { Paragraph, Text } = Typography;

const getMoreInfoContent = () => (
  <Paragraph>
    This link is unique to your firm and profile. Team members who send out the link have clients
    automatically assigned to them in the queue.
  </Paragraph>
);

const initialState = {
  baseLink: `${process.env.REACT_APP_TAXAROO_CLIENT_URL ?? 'https://clients.taxaroo.com'}/invite`,
  baseFirmInput: '',
  basePersonalInput: '',
};

const InviteLink: FC = () => {
  const { firmAccountId, userId } = useAppSelector((state) => state.session);

  const [baseLink, setBaseLink] = useState(initialState.baseLink);
  const [firmInput, setFirmInput] = useState(initialState.baseFirmInput);
  const [personalInput, setPersonalInput] = useState(initialState.basePersonalInput);
  const [cnameError, setCnameError] = useState('');
  const [isCnameModalVisible, setIsCnameModalVisible] = useState(false);

  // get custom domain
  const { data: customDomainUserData, loading: loadingCustomDomainUser } = useGetCustomDomainUserQuery();
  const { CustomDomainUser } = customDomainUserData || {};
  // get custom domain certificate status
  const [getCustomDomainCertificateStatus, { data: certificateStatus, loading: certificateStatusLoading }] = useCustomDomainCertificateStatusLazyQuery();
  const { CustomDomainCertificateStatus } = certificateStatus || {};

  // Query for getting custom domain cname status
  const [getCustomDomainCnameStatus,
    { data: cnameStatus, loading: cnameStatusLoading }] = useCustomDomainCnameStatusLazyQuery({
    onError: (error) => {
      if (error?.message.includes('queryCname ENODATA')) {
        setCnameError('CNAME record not found.');
      } else {
        // message.error(`Error getting CNAME status: ${error.message}`);
      }
    },
    onCompleted: () => setCnameError(''),
  });

  // query for firm and user unique links
  const { data: customUserUniqueLinkData, loading: loadingUserUniqueLink } = useGetUserUniqueLinkQuery({
    variables: {
      id: userId,
    },
  });
  const { data: customFirmUniqueLinkData, loading: loadingFirmUniqueLinkQuery } = useGetFirmUniqueLinkQuery({
    variables: {
      id: firmAccountId,
    },
  });

  const [updateFirmLink, { data: firmData, error: firmError, loading: loadingUpdateFirmLinkMutation }] = useUpdateFirmUniqueLinkMutation({
    onCompleted: () => {
      setFirmInput(firmData.updateFirmUniqueLink.uniqueLink);
      handleOnSuccessFirmLink();
    },
    onError: () => {
      handleOnErrorFirmLink(firmError);
    },
    refetchQueries: [{
      query: GetFirmUniqueLinkDocument, variables: { id: firmAccountId, ownerId: userId },
    }],
    awaitRefetchQueries: true,
  });

  const [updateUserLink, { data: userData, error: userError, loading: loadingUpdateUserLink }] = useUpdateUserUniqueLinkMutation({
    onCompleted: () => {
      setPersonalInput(userData.updateUserUniqueLink.uniqueLink);
      handleOnSuccessUserLink();
    },
    onError: () => {
      handleOnErrorUserLink(userError);
    },
    refetchQueries: [{
      query: GetUserUniqueLinkDocument,
      variables: {
        id: userId,
      },
    }],
    awaitRefetchQueries: true,
  });

  // check if custom domain is live
  const [checkIfCustomDomainIsLive, { data: domainLiveData, loading: loadingIsDomainLive }] = useIsDomainLiveLazyQuery({
    variables: {
      url: CustomDomainUser?.name,
    },
  });
  const { IsDomainLive: isDomainLive } = domainLiveData || {};

  useEffect(() => {
    if (!isDomainLive) {
      // if custom domain is not working
      // we need to show default invite link
      setBaseLink(`${process.env.REACT_APP_TAXAROO_CLIENT_URL ?? 'https://clients.taxaroo.com'}/invite`);
      // and we need to run some checks to display why
      // fetch the current status of the certificate (this will also trigger an update to the CustomDomain status in the db)
      getCustomDomainCertificateStatus();
      // fetch the current status of the cname
      getCustomDomainCnameStatus();
    } else {
      setBaseLink(`https://${CustomDomainUser?.name}/invite`);
    }
  }, [isDomainLive, CustomDomainUser?.name]);

  useEffect(() => {
    if (CustomDomainUser?.name) {
      checkIfCustomDomainIsLive();
    }
  }, [CustomDomainUser?.name, CustomDomainCertificateStatus?.Status, cnameError]);

  useEffect(() => {
    setFirmInput(customFirmUniqueLinkData?.FirmAccountById?.uniqueLink || firmAccountId);
  }, [customFirmUniqueLinkData]);

  useEffect(() => {
    setPersonalInput(customUserUniqueLinkData?.UserById?.uniqueLink || userId);
  }, [customUserUniqueLinkData]);

  const openLink = (url: string) => {
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  };

  const saveFirmLink = (link: string) => {
    if (firmAccountId) {
      updateFirmLink({
        variables: {
          updateFirmAccountInput: {
            id: firmAccountId,
            uniqueLink: link,
          },
        },
      });
    }
  };

  const savePersonalLink = (link: string) => {
    if (userId) {
      updateUserLink({
        variables: {
          updateUserInput: {
            id: userId,
            uniqueLink: link,
          },
        },
      });
    }
  };

  if (loadingCustomDomainUser || loadingUserUniqueLink || loadingFirmUniqueLinkQuery || loadingIsDomainLive) {
    return <Spin size="large" />;
  }

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <Space direction="vertical" style={{ width: '98%' }}>
        <SettingsHeader
          title="Invite Link"
          tooltipTitle="How does this work?"
          tooltipContent={getMoreInfoContent}
        />
        <Row>
          <Text strong>
            Share with your link with your clients so they can submit through your portal
          </Text>
        </Row>
        <Row>
          <Text small>
            {`Add this link to your own website, send it to clients via email, or
            share on social media. Clients will be onboarding to your account,
            and you'll be notified when they sign up.`}
          </Text>
        </Row>
        <CustomDomainStatusAlert
          status={CustomDomainCertificateStatus?.Status}
          failureReason={CustomDomainCertificateStatus?.FailureReason}
          cnameError={cnameError}
          setIsCnameModalVisible={setIsCnameModalVisible}
          certificateStatus={certificateStatus}
          loadingCertificateStatus={certificateStatusLoading}
        />
        <Space direction="vertical" className={styles.inviteLinkBackground}>
          <InviteLinkRow
            updateLoad={loadingUpdateFirmLinkMutation}
            title="Firm Invite Link"
            subtitle="(Clients can access your secure portal)"
            baseLink={baseLink}
            defaultValue=""
            currentValue={firmInput}
            onChange={(value: string) => setFirmInput(value)}
            onSave={() => {
              saveFirmLink(firmInput);
            }}
            onReset={() => {
              saveFirmLink('');
            }}
            onOpen={() => openLink(`${baseLink}/${firmInput}`)}
          />
          <Divider />
          <InviteLinkRow
            updateLoad={loadingUpdateUserLink}
            title="Personal Invite Link"
            subtitle="(Clients are automatically assigned to you in the Jobs
              Queue)"
            baseLink={`${baseLink}/${firmInput}`}
            defaultValue=""
            currentValue={personalInput}
            onChange={(value: string) => setPersonalInput(value)}
            onSave={() => {
              savePersonalLink(personalInput);
            }}
            onReset={() => {
              savePersonalLink('');
            }}
            onOpen={() => openLink(`${baseLink}/${firmInput}/${personalInput}`)}
          />
        </Space>
      </Space>
      {(cnameError && customDomainUserData?.CustomDomainUser?.status === CustomDomainStatuses.Issued) && (
        <CustomDomainDNSModal
          isDNSModalVisible={isCnameModalVisible}
          setIsDNSModalVisible={setIsCnameModalVisible}
        />
      )}
    </Layout>
  );
};

export default InviteLink;
