import { useEffect, FC } from 'react';
import {
  Typography,
  Tabs,
  Icons,
  Row,
  Col,
  message,
} from 'taxaroo-ui';
import {
  useCreateEventTypeMutation,
  useUpdateEventTypeMutation,
} from '~src/graphql/mutations/appointments';
import { ApolloError } from '@apollo/client';
import {
  MyEventTypesDocument,
  useMyEventsQuery,
  useMyEventTypesQuery,
} from '~src/graphql/queries/appointments';
import { useGetClientsQuery } from '~src/graphql/queries/settings';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import { getTimeZone } from '~src/components/helpers/time';
import { Features, useUpgradeModal } from '~src/components/atoms/UpgradeModal/ModalProvider';
import EventTypes from '../../components/organisms/EventTypes';
import AppointmentsTable from '../../components/molecules/AppointmentsTable';

const { Text } = Typography;
const { CalendarOutlined, CalendarFilled } = Icons;
const { TabPane } = Tabs;

const Appointments: FC = () => {
  const handleOnError = (error: ApolloError) => {
    if (error.graphQLErrors) {
      const graphQLError = error.graphQLErrors[0];
      if (graphQLError.message.indexOf('Foreign key constraint failed') !== -1) {
        return message.error('This appointment type has been used by active appointments. You cannot delete this appointment type but you can deactivate it on the page.');
      }
    }
    return message.error(`An error has occurred: ${error.message}.`);
  };

  const {
    data: events,
    loading: loadingEvents,
    error: myEventsError,
  } = useMyEventsQuery();

  const {
    data: eventTypes,
    loading: loadingEventTypes,
    error: myEventTypesError,
  } = useMyEventTypesQuery({
    variables: {
      timezone: getTimeZone().large,
    },
  });

  const {
    data: clientsData,
    loading: clientsDataLoading,
    error: clientsDataLoadingError,
  } = useGetClientsQuery();

  const [createEventTypeMutation, { loading: createEventTypeLoading }] = useCreateEventTypeMutation({
    onError: handleOnError,
    refetchQueries: [MyEventTypesDocument],
    awaitRefetchQueries: true,
  });

  const [updateEventTypeMutation, { loading: updateEventTypeLoading }] = useUpdateEventTypeMutation({
    onError: handleOnError,
    refetchQueries: [MyEventTypesDocument],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (myEventTypesError) {
      return handleOnError(myEventTypesError);
    }
    if (clientsDataLoadingError) {
      return handleOnError(clientsDataLoadingError);
    }
    if (myEventsError) {
      return handleOnError(myEventsError);
    }
    return null;
  }, [myEventTypesError, clientsDataLoadingError, myEventsError]);

  const handleClickTab = (key: string) => {
    trackCustomerIoEvent(CUSTOMER_IO_EVENTS.TAB_SELECT, {
      'Tab Description': 'Appointments',
      'Tab Selected': key === '1' ? 'Appointments' : 'Appointment Types',
      url: window.location.href,
    });
  };

  const { showModal, hideModal, accessObject } = useUpgradeModal();

  if (accessObject?.[Features.appointments] === false) {
    showModal(Features.appointments, false);
  } else {
    hideModal();
  }

  return (
    <Row>
      <Col span={24}>
        <Tabs
          type="card"
          onTabClick={handleClickTab}
        >
          <TabPane
            key="1"
            tab={(
              <Text>
                <CalendarFilled />
                Appointments
              </Text>
            )}
          >
            <AppointmentsTable
              appointments={events}
              loadingAppointments={loadingEvents}
              taxPayers={clientsData}
              isTaxPayersLoading={clientsDataLoading}
              eventTypes={eventTypes}
              loadingEventTypes={loadingEventTypes}
              handleOnError={handleOnError}
            />
          </TabPane>

          <TabPane
            key="2"
            tab={(
              <Text>
                <CalendarOutlined />
                Appointment Types
              </Text>
            )}
          >
            <EventTypes
              eventTypes={eventTypes}
              loadingEventTypes={loadingEventTypes}
              createEventTypeMutation={createEventTypeMutation}
              createEventTypeLoading={createEventTypeLoading}
              handleOnError={handleOnError}
              updateEventTypeMutation={updateEventTypeMutation}
              updateEventTypeLoading={updateEventTypeLoading}
            />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default Appointments;
