import React, { FC } from 'react';
import { Typography } from 'taxaroo-ui';

const { Text } = Typography;
export interface SubmitButtonProps {
  label: string;
  children?: React.ReactNode;
  disabled: boolean;
}

const defaultProps = {
  children: null,
};

const SubmitButton: FC<SubmitButtonProps> = ({ label, children, disabled }) => (
  <button
    disabled={disabled}
    style={{
      color: 'white',
      background: '#1890ff',
      border: 'none',
      cursor: 'pointer',
      borderRadius: '5px',
      padding: '5px 15px',
    }}
    type="submit"
  >
    {children}
    {' '}
    <Text style={{ color: 'white', textTransform: 'uppercase' }}>{label}</Text>
  </button>
);

SubmitButton.defaultProps = defaultProps;

export default SubmitButton;
