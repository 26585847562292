import { Tooltip, Icons } from 'taxaroo-ui';
import { FormValues, ItemValue, OptionItem } from '../types';

const { InfoCircleOutlined } = Icons;

export default (
  {
    jobType,
    taxYear,
    progressStatusId,
    sendEmail,
  }: FormValues,
  taxYearOptions: Array<OptionItem>,
  progressStatusOptions: Array<OptionItem>,
): Array<ItemValue> => ([
  {
    span: {
      xs: 24,
    },
    key: 'taxYear',
    value: taxYear,
    required: true,
    label: 'Tax Year',
    inputType: 'select',
    options: taxYearOptions,
    placeHolder: 'Select Tax Year',
  },
  {
    span: {
      xs: 24,
    },
    key: 'jobType',
    value: jobType,
    required: true,
    label: 'Job Type',
    inputType: 'selectJobType',
    placeHolder: 'Select Job Type',
  },
  {
    span: {
      xs: 24,
    },
    key: 'progressStatusId',
    value: progressStatusId,
    required: false,
    label: 'Progress Status',
    inputType: 'select',
    options: progressStatusOptions,
    placeHolder: 'Select Progress Status',
  },
  {
    span: {
      xs: 24,
    },
    label: '',
    key: 'sendEmail',
    value: sendEmail,
    required: false,
    placeHolder: '',
    inputType: 'checkbox',
    checkLabel: () => (
      <>
        Send email to request to ask client to complete on-boarding interview
        {' '}
        <Tooltip title="We will send an email on your behalf to request that the client provide their detailed tax situation.">
          <InfoCircleOutlined style={{ fontSize: '14px' }} />
        </Tooltip>
      </>
    ),
  },
]);
