import React, {
  ReactElement,
  ReactNode,
  useMemo,
  useReducer,
} from 'react';
import { PhonesEntity, TaskEntity } from '~src/graphql';

const { createContext } = React;
const JobContext = createContext<JobTypes>(null);
interface JobTypes {
  children?: ReactNode | ReactElement | JSX.Element | undefined;
  entityId: string | undefined;
  entityOwnerId?: string | undefined;
  taxYearInterviewId: string | undefined;
  progressStatusId: string | undefined;
  notes: string | undefined;
  tasks: TaskEntity[] | undefined;
  completedAt?: Date | undefined;
  filesCompletedAt?: Date | undefined;
  expectedCompletion?: Date | undefined;
  paymentsCompletedAt?: Date | undefined;
  approvalsCompletedAt?: Date | undefined;
  interviewCompletedAt?: Date | undefined;
  clientFirstName: string | undefined;
  clientLastName: string | undefined;
  clientEmail: string | undefined;
  clientMobilePhone: PhonesEntity | undefined;
  clientBirthday: Date | undefined;
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  dispatch?: (value: { type: string, payload: Partial<JobTypes> }) => void;
}

const initialJob: JobTypes = {
  entityId: undefined,
  entityOwnerId: undefined,
  taxYearInterviewId: undefined,
  progressStatusId: undefined,
  notes: undefined,
  tasks: undefined,
  completedAt: undefined,
  filesCompletedAt: undefined,
  expectedCompletion: undefined,
  paymentsCompletedAt: undefined,
  approvalsCompletedAt: undefined,
  interviewCompletedAt: undefined,
  clientFirstName: undefined,
  clientLastName: undefined,
  clientEmail: undefined,
  clientMobilePhone: undefined,
  clientBirthday: undefined,
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'CREATE':
      return { ...action.payload };
    case 'UPDATE':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}

function JobProvider({
  children,
  entityId,
  entityOwnerId,
  taxYearInterviewId,
  progressStatusId,
  notes,
  tasks,
  completedAt,
  filesCompletedAt,
  expectedCompletion,
  paymentsCompletedAt,
  approvalsCompletedAt,
  interviewCompletedAt,
  clientFirstName,
  clientLastName,
  clientEmail,
  clientMobilePhone,
  clientBirthday,
}: JobTypes) {
  const [state, dispatch] = useReducer(reducer, {
    ...initialJob,
    loading: false,
    entityId,
    entityOwnerId,
    taxYearInterviewId,
    progressStatusId,
    notes,
    tasks,
    completedAt,
    filesCompletedAt,
    expectedCompletion,
    paymentsCompletedAt,
    approvalsCompletedAt,
    interviewCompletedAt,
    clientFirstName,
    clientLastName,
    clientEmail,
    clientMobilePhone,
    clientBirthday,
  });
  const valueProvider = useMemo(() => ({
    ...state,
    dispatch,
  }), [state, dispatch]);

  return (
    <JobContext.Provider value={valueProvider}>
      {children}
    </JobContext.Provider>
  );
}

JobProvider.defaultProps = {
  entityOwnerId: undefined,
  completedAt: undefined,
  filesCompletedAt: undefined,
  expectedCompletion: undefined,
  paymentsCompletedAt: undefined,
  approvalsCompletedAt: undefined,
  interviewCompletedAt: undefined,
  children: undefined,
};

export { JobContext, JobProvider };
