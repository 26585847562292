import {
  useState, useRef, useCallback, Key, FC,
} from 'react';
import {
  Table,
  Row,
  Button,
  message,
} from 'taxaroo-ui';
import getPrimaryPhone from '~/src/components/helpers/getPrimaryPhone';
import '../styles.css';
import { useGetClientsQuery } from '~src/graphql/queries/settings';
import Users from '~src/components/helpers/users';
import { UserFirmAccountOrderByInput } from '~src/graphql';
import DraggableBodyRow from '../../../molecules/DraggableTableRow/DraggableBodyRow';
import RowAboveJobsTable from '../../RowAboveJobsTable';
import AddClientModal from '../../../molecules/AddClientModal';

interface ClientsTableProps {
  setProgressStatusDnDConfirmModalVisible: (arg0: boolean) => void;
  setDndData: (arg0: { record: any, dropResult: any }) => void;
  columns: any[];
  loadingProp: boolean;
  setClientsCount: (value: number) => void;
}

const ClientsTable: FC<ClientsTableProps> = ({
  setProgressStatusDnDConfirmModalVisible,
  setDndData,
  columns,
  loadingProp,
  setClientsCount,
}) => {
  const sortedInfo = {
    order: 'ascend',
    columnKey: 'clientInterview',
  };
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [displayClearbutton, setDisplayClearButton] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedValue, setSearchedValue] = useState('');
  const [sortObject, setSortObject] = useState<UserFirmAccountOrderByInput>(() => {
    // Check if there is a sort order saved in localStorage
    const savedSort = localStorage.getItem('clientsListSortOrder');
    return savedSort ? JSON.parse(savedSort) : { updateAt: 'desc' };
  });

  const tableDivRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  const { data: clientsDataWithPagination, loading: loadingClients } = useGetClientsQuery({
    variables: {
      skip: (currentPage - 1) * pageSize,
      take: pageSize,
      searchTerm: searchedValue,
      orderBy: sortObject,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (searchedValue.length > 0) {
        if (data.Clients.data.length === 0) {
          message.error('No results found');
          searchRef.current?.focus();
        }
        setDisplayClearButton(true);
      } else {
        setDisplayClearButton(false);
      }

      setClientsCount(data.Clients?.pagination?.total);
    },
    onError: (error) => {
      message.error(error.message);
      // reset local storage sort order to default
      setSortObject({ updateAt: 'desc' });
      localStorage.setItem('clientsListSortOrder', JSON.stringify({ updateAt: 'desc' }));
    },
  });
  const { data: clientsData, pagination } = clientsDataWithPagination?.Clients || {};

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  // kyle todo: is this a duplicate?
  const progressStatusOnChange = (record: any, dropResult: any) => {
    setDndData({
      record,
      dropResult,
    });
    setProgressStatusDnDConfirmModalVisible(true);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], row: any) => {
      setSelectedKeys(selectedRowKeys);
      setSelectedRows(row);
    },
  };

  const moveRow = useCallback((record, notSure, dropResult) => {
    progressStatusOnChange(record, dropResult);
  }, []);

  const handleTableChange = (paginationInfo, filters, sorter) => {
    const orderByColumn = sorter.columnKey;
    const orderDirection = sorter.order === 'ascend' ? 'asc' : 'desc';
    let newSort: UserFirmAccountOrderByInput = { updateAt: 'desc' };
    switch (orderByColumn) {
      case 'firstName':
        newSort = { Entity: { Users: { UserInformation: { firstName: orderDirection } } } };
        break;
      case 'lastName':
        newSort = { Entity: { Users: { UserInformation: { lastName: orderDirection } } } };
        break;
      case 'email':
        newSort = { Entity: { Users: { email: orderDirection } } };
        break;
      default:
        newSort = { updateAt: 'desc' };
        break;
    }
    setSortObject(newSort);
    localStorage.setItem('clientsListSortOrder', JSON.stringify(newSort));
  };

  // ! assignment to property of function parameter 'columns'
  // columns[0].fixed = screens.sm ? 'left' : false;
  // columns.forEach((item: any) => {
  //   item.sortOrder = sortedInfo.columnKey === 'referredBy' && sortedInfo.order;
  // });

  // eslint-disable-next-line react/no-unstable-nested-components
  function NoClients() {
    const [isAddClientModalVisible, setIsAddClientModalVisible] = useState(false);
    return (
      <>
        <Row justify="center" style={{ marginTop: 25 }}>
          <Button type="primary" shape="round" onClick={() => setIsAddClientModalVisible(true)}>
            Add your first client
          </Button>
        </Row>
        <AddClientModal
          isModalVisible={isAddClientModalVisible}
          setIsModalVisible={setIsAddClientModalVisible}
        />
      </>
    );
  }

  const today = new Date();
  const timeElapsed = (date: string) => {
    const prevDate = new Date(date);
    const difference = today.getTime() - prevDate.getTime();
    return difference;
  };

  const clients = clientsData?.map((item) => {
    const entity = item.Entity;
    const user = entity.Users;
    const userInfo = user.UserInformation;
    const taxYear = item.Entity.TaxYear[0];
    const taxYearInterview = taxYear?.TaxYearInterview[0];
    const interview = taxYearInterview?.Interviews;
    const jobTracking = taxYearInterview?.JobTracking;
    const preparedUser = taxYearInterview?.PreparedUser;
    const preparedUserInfo = preparedUser?.UserInformation;
    const preparedName = preparedUserInfo ? `${preparedUserInfo.firstName} ${preparedUserInfo.lastName}` : undefined;
    const reviewedUser = taxYearInterview?.ReviewedUser;
    const reviewedUserInfo = reviewedUser?.UserInformation;
    const reviewedName = reviewedUserInfo ? `${reviewedUserInfo.firstName} ${reviewedUserInfo.lastName}` : undefined;

    return {
      lastName: userInfo.lastName,
      firstName: userInfo.firstName,
      taxYear: taxYear?.year,
      taxYearId: taxYear?.id,
      assignedAt: taxYearInterview?.assignedAt,
      taxYearInterviewId: taxYearInterview?.id,
      entityId: entity.id,
      name: `${userInfo.firstName} ${userInfo.lastName}`,
      email: user.email,
      phone: getPrimaryPhone(userInfo.Phones),
      jobType: interview?.name,
      invitationEmailSent: user.invitationEmailSent,
      password: user.hasPassword,
      userId: user.id,
      referredBy: Users.getUserName(user?.ReferredByUser),
      languageId: user?.languageId,
      userInformationId: userInfo?.id,
      progressStatusId: taxYearInterview?.progressStatusId,
      preparedId: preparedUser?.id,
      preparedName,
      reviewedId: reviewedUser?.id,
      reviewedName,
      completedDate: taxYearInterview?.completedAt,
      timeInQueue: timeElapsed(taxYearInterview?.createAt),
      expectedCompletion: jobTracking?.expectedCompletion,
      paymentsCompletedAt: jobTracking?.paymentsCompletedAt,
      approvalsCompletedAt: jobTracking?.approvalsCompletedAt,
      interviewCompletedAt: jobTracking?.interviewCompletedAt,
      filesCompletedAt: jobTracking?.filesCompletedAt,
      tasks: taxYearInterview?.Tasks,
      notes: userInfo?.notes,
      reviewStatus: taxYearInterview?.reviewStatus,
    };
  });

  return (
    <>
      <RowAboveJobsTable
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setSelectedKeys={setSelectedKeys}
        tableData={clients}
        tableRef={tableDivRef}
        type="clients"
        displayClearbutton={displayClearbutton}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setSearchedValue={setSearchedValue}
        searchRef={searchRef}
        loading={loadingClients || loadingProp}
      />
      <div style={{ height: '15px' }} />
      <div className="tableDivRef" ref={tableDivRef}>
        <Table
          loading={loadingClients || loadingProp}
          sticky={{ offsetHeader: 45 }}
          tableLayout="fixed"
          rowSelection={{
            type: 'checkbox',
            fixed: true,
            columnWidth: 35,
            selectedRowKeys: selectedKeys,
            ...rowSelection,
          }}
          // scroll={{ x: 1000, y: 'calc(100vh - 200px)' }}
          scroll={{ x: 1000 }}
          showSorterTooltip={false}
          components={components}
          onRow={(record, rowIndex) => ({
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick: (event) => {}, // click row
            moveRow: (event, p) => {
              moveRow(record, event, p);
            }, // move row
          })}
          columns={columns}
          dataSource={clients}
          rowKey={(record: any) => record.taxYearInterviewId}
          // onchange={function noRefCheck() {}}
          pagination={{
            hideOnSinglePage: true,
            pageSize,
            total: pagination?.total,
            current: currentPage,
            showTotal: (total) => `Total ${total} clients`,
            onChange: (page) => {
              setCurrentPage(page);
            },
            onShowSizeChange: (current, size) => {
              setPageSize(size);
            },
            pageSizeOptions: ['25', '100', '250', '500'],
          }}
          onChange={handleTableChange} // sorting when a column header is pressed
          noDataProps={{
            title: 'No clients yet',
            extra: <NoClients />,
          }}
        />
      </div>
    </>
  );
};

export default ClientsTable;
