import Pusher from 'pusher-js';
import config from '../../components/helpers/config';

export class PusherClient extends Pusher {
  static instance: PusherClient;

  constructor(token: string) {
    if (typeof PusherClient.instance === 'object') {
      // eslint-disable-next-line no-constructor-return
      return PusherClient.instance;
    }

    super(config.pusherKey, {
      cluster: 'us2',
      channelAuthorization: {
        transport: 'ajax',
        endpoint: `${config.serverUrl}/pusher`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });

    PusherClient.instance = this;

    // eslint-disable-next-line no-constructor-return
    return this;
  }
}
