.inviteActivateContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f6f8f9;
}

.inviteActivateContent {
  flex: 1;
  height: 100%;
  display: flex;
  padding: 32px 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.inviteActivateFormCard {
  flex: 1;
  padding: 24px;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  background-color: white;
}

.inviteActivateFormCard > div:first-child {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.inviteActivateFormHeader {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
}

.inviteActivateFormHeader strong {
  font-size: 24px;
}

form ant-card-body {
  padding: 32px !important;
}

.inviteActivateSpinner {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.inviteActivateTerms {
  display: flex;
  margin-top: 16px;
}

.inviteActivateCheckbox {
  margin-right: 16px !important;
}

.inviteInnerContentContainer {
  display: flex;
  position: relative;
}

.inviteLeftSideContainer {
  flex-basis: 70%;
  padding-right: 32px;
}

.inviteRightSideContainer {
  flex-basis: 30%;
}

.inviteRightSideContainer > p {
  color: #188fff;
  font-weight: 400;
}

.inviteWelcomeMessage {
  display: flex;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 16px;
  justify-content: center;
  border-bottom: 1px solid #f0f0f0;
}

.inviteAlreadyClientContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  justify-content: center;
  border-bottom: 1px solid #f0f0f0;
}

.inviteAlreadyClient {
  font-size: 15px;
  font-weight: 700;
  margin-right: 12px;
  color: #188fff !important;
  margin-bottom: 0 !important;
}
