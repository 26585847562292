import {
  useState, useContext, useEffect, FC,
} from 'react';
import {
  Typography,
  Collapse,
  Button,
  Icons,
  message,
} from 'taxaroo-ui';
import { TaxPayerContext } from '~src/components/providers/taxpayer-provider';
import { useUpdateUserInformationNotesMutation } from '~src/graphql/mutations/clients';
import { GetAllFilesByClientQuery, useGetAllFilesByClientLazyQuery } from '~src/graphql/queries/clients';
import AllFilesTable from './AllFilesTable';
import ClientInfoPanel from './ClientInfoPanel';
import EditClientInfoPanel from './EditClientInfoPanel';
import NotesPanel from '../NotesPanel';

const { Title } = Typography;
const { Panel } = Collapse;
const {
  EditOutlined,
} = Icons;

interface ClientInfoProps {
  initialActiveKeys: string[],
}

const ClientInfo: FC<ClientInfoProps> = ({ initialActiveKeys }) => {
  const [editingClientInfo, setEditingClientInfo] = useState(false);
  const [activeKey, setActiveKey] = useState<string | string[]>(initialActiveKeys);
  const [clientFiles, setClientFiles] = useState([]);

  const {
    userInformationId, notes, ownerId, urlTaxYearInterviewId, clientFilesChanged, firmFilesChanged, dispatch,
  } = useContext(TaxPayerContext);

  const [updateNotes, { loading }] = useUpdateUserInformationNotesMutation();

  const clientInfoExtra = () => (
    <Button
      size="small"
      type="link"
      icon={<EditOutlined />}
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        if (activeKey.includes('client-info')) {
          event.stopPropagation();
        }
        setEditingClientInfo(!editingClientInfo);
      }}
    >
      {editingClientInfo ? 'Cancel' : 'Edit'}
    </Button>
  );

  const handleChange = (value: string | string[]) => {
    setActiveKey(value);
  };

  const handleSaveNotes = (notesValue: string) => {
    try {
      updateNotes({
        variables: {
          userInformationId,
          notes: notesValue,
        },
        onCompleted: () => {
          message.success('The client notes have been successfully saved.');
        },
      });
    } catch (err) {
      message.error('Something went wrong while trying to update the client notes.');
    }
  };

  const [getAllFilesByClient, { loading: loadingFirmFilesData }] = useGetAllFilesByClientLazyQuery();
  const getAllClientFiles = () => {
    getAllFilesByClient({
      variables: {
        userId: ownerId,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        let files = data?.getAllFilesByClient;
        if (files) {
          if (urlTaxYearInterviewId !== '') {
            files = files.filter((file) => file.taxYearInterviewId === urlTaxYearInterviewId);
          }
          setClientFiles(files);
        }
      },
      onError: () => {
        message.error('An error ocurred while loading firm files');
      },
    });
  };

  useEffect(() => {
    if (ownerId) {
      getAllClientFiles();
    }
  }, [ownerId]);

  useEffect(() => {
    setActiveKey(initialActiveKeys);
  }, [initialActiveKeys]);

  useEffect(() => {
    if (clientFilesChanged || firmFilesChanged) {
      if (ownerId) {
        getAllClientFiles();
      }
      dispatch({
        type: 'UPDATE',
        payload: {
          clientFilesChanged: false,
          firmFilesChanged: false,
        },
      });
    }
  }, [clientFilesChanged, firmFilesChanged]);

  return (
    <>
      <Title level={3}>Permanent Client Information</Title>
      <Collapse
        activeKey={activeKey}
        onChange={handleChange}
      >
        <Panel header="Client Info" key="client-info" extra={clientInfoExtra()}>
          {editingClientInfo
            ? <EditClientInfoPanel handleExitEditMode={() => { setEditingClientInfo(false); }} />
            : <ClientInfoPanel />}
        </Panel>
        <Panel header="All Files" key="all-documents" className="not-printable">
          <AllFilesTable loading={loadingFirmFilesData} tableData={clientFiles} />
        </Panel>
        <Panel header="General Client Notes - Visible only to you and your team" key="notes">
          <NotesPanel
            initialNotes={notes}
            onSaveNotes={handleSaveNotes}
            loading={loading}
          />
        </Panel>
        {/* <Panel header="Messaging" key="messaging">
          <Messaging />
          </Panel> */}
      </Collapse>
    </>
  );
};

export default ClientInfo;
