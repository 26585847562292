import { useState } from 'react';
import {
  Form,
  Card,
  Input,
  Button,
  Row,
  Grid,
  Typography,
  message,
  Space,
  Skeleton,
  Alert,
} from 'taxaroo-ui';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Logo from '~src/assets/images/taxaroo_logo.svg';
import { useGetPasswordResetIncludeFirmQuery } from '~src/graphql/queries/passwordReset';
import { useResetPasswordMutation } from '~src/graphql/mutations/passwordReset';
import PasswordInputWithTooltip from '~src/components/molecules/PasswordInputWithTooltip';
import * as styles from './style.module.css';

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

function ResetPassword() {
  const [error, setError] = useState(null);
  const screens = useBreakpoint();

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const baseUrl = window.location.origin;

  // graphql
  // fetch user and firm name by PasswordResetId
  const { data, loading: loadingPasswordReset } = useGetPasswordResetIncludeFirmQuery({
    variables: { id },
    onError: (err) => {
      if (err.message.includes('1001')) {
        setError('CannotFindRecordException');
      } else if (err.message.includes('4002')) {
        setError('PasswordResetExpiredException');
      } else {
        message.error(err.message);
      }
    },
    onCompleted: () => {
      setError(null);
    },
  });
  const { Users } = data?.passwordResetIncludeFirm || {};
  const { email } = Users || {};
  // mutation to reset password
  const [resetPassword, { loading: loadingResetPassword }] = useResetPasswordMutation({
    onError: (err) => {
      if (err.message.includes('1005')) {
        message.error('Password doesn\'t meet the requirements.');
      } else {
        message.error(err.message);
      }
    },
    onCompleted: () => {
      message.success('Password reset. Please login.', 10);
      setTimeout(() => {
        navigate('/login');
      }, 300);
    },
  });

  const onFinish = (values: any) => {
    resetPassword({
      variables: {
        resetPasswordInput: {
          uuid: id,
          password: values.the_pass,
          passwordConfirmation: values.the_confirm_pass,
        },
      },
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
    message.error('Account activation failed.');
  };

  if (error) {
    return (
      <div className={styles['reset-password-page']}>
        <div className={styles['reset-password-wrapper']}>
          <Card
            style={{
              padding: screens.md ? '10px 80px' : '5px',
              maxWidth: '900px',
            }}
          >
            <Row justify="center" style={{ margin: '0 0 60px 0' }}>
              <Logo width="170" height="40" />
            </Row>
            <Row justify="center">
              <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <Title level={3}>Reset Your Password</Title>
                {error === 'CannotFindRecordException' && (
                  <Alert
                    type="error"
                    showIcon
                    message="Record could not be found."
                    description="This could be becuase you have already reset your password. Try logging in, or requesting a password reset again."
                    style={{ marginTop: '40px', maxWidth: '500px' }}
                  />
                )}
                {error === 'PasswordResetExpiredException' && (
                  <Alert
                    type="error"
                    showIcon
                    message="This link has expired."
                    description="Please request a new link to reset your password."
                    style={{ marginTop: '40px' }}
                  />
                )}
              </div>
            </Row>
            <div style={{ textAlign: 'center' }}>
              <Link to="/login">Go to login</Link>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div className={styles['reset-password-page']}>
      <div className={styles['reset-password-wrapper']}>
        <Card
          style={{
            padding: screens.md ? '10px 80px' : '5px',
            maxWidth: '900px',
          }}
        >
          <Row justify="center" style={{ margin: '0 0 60px 0' }}>
            <Logo width="170" height="40" />
          </Row>
          <Row justify="center">
            <Form
              name="Reset Password"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              // style={{ width: '300px' }}
            >
              <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <Title level={3}>Reset Your Password</Title>
                {loadingPasswordReset
                  ? <Skeleton paragraph={{ rows: 2, width: 500 }} style={{ marginTop: 40 }} />
                  : (
                    <Space direction="vertical">
                      <Text>
                        In the future you can sign in directly at
                        <Link to={`${baseUrl}/login`}>
                          {' '}
                          {baseUrl}
                          /login
                        </Link>
                        {' '}
                        with the email address
                        {' '}
                        <b>{email}</b>
                        .
                      </Text>
                    </Space>
                  )}
              </div>
              <Form.Item
                name="the_pass"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'The password is required',
                  },
                ]}
              >
                {/* div around PasswordInputWithTooltip is required for form to work for some reason */}
                <div>
                  <PasswordInputWithTooltip
                    tooltipProps={{
                      placement: 'topLeft',
                    }}
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="the_confirm_pass"
                label="Confirm Password"
                dependencies={['the_pass']}
                rules={[
                  {
                    required: true,
                    message: 'The password confirmation is required',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('the_pass') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password autoComplete="disabled" placeholder="Confirm Password" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingResetPassword}
                  style={{ width: '100%', justifyContent: 'center' }}
                  size="large"
                >
                  Reset Your Password
                </Button>
              </Form.Item>
              <div style={{ textAlign: 'center' }}>
                <Link to="/login">Go to login</Link>
              </div>
            </Form>
          </Row>
        </Card>
      </div>
    </div>
  );
}

export default ResetPassword;
