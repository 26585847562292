import {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Row, Col, Button, Icons, Upload, Empty,
} from 'taxaroo-ui';
import { CSVLink } from 'react-csv';
import { useGetPresignedUrlMutation, useUploadFileToFirmOnDbMutation } from '~src/graphql/mutations/clients';
import {
  GetAllFilesByClientDocument, GetFirmFilesFromDbDocument, useGetFirmFilesFromDbLazyQuery, useGetFirmFilesFromDbQuery,
} from '~src/graphql/queries/clients';
import DownloadAllFilesButton from '~src/components/atoms/DownloadAllFilesButton';
import { JobContext } from '~src/components/providers/job-provider';
import { isAllowedFileType } from '~src/components/helpers/fileTypes';
import { TaxPayerContext } from '~src/components/providers/taxpayer-provider';
import { FileSuccessEntity } from '~src/graphql';
import DocumentsTable, { DocumentsTableType } from '../../../DocumentsTable';
import {
  handleOnFileTypeNotSupported,
  handleOnGetPresignedURLS3Error,
  handleOnLoadFirmFilesError,
  handleOnUploadFileToDBError,
  onSuccessUploadFile,
} from './utils';
import { handleFileExceedsSize } from '../ClientFiles/utils';

const { CloudUploadOutlined } = Icons;

interface PreSignedUrlMapping {
  [uid: string]: FileSuccessEntity
}

interface FirmFilesProps {
  jobName: string;
}

function FirmFiles({ jobName }: FirmFilesProps) {
  const jobContext = useContext(JobContext);
  const {
    taxYearInterviewId,
    entityId,
    entityOwnerId,
    clientFirstName,
    clientLastName,
  } = jobContext;
  const csvLink = useRef<any>();
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any[]>();
  const [FirmFilesDataOnDB, setFirmFilesDataOnDB] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { allFilesChanged, dispatch } = useContext(TaxPayerContext);
  const [preSignedUrls, setPreSignedUrls] = useState<PreSignedUrlMapping>();

  const [getPresignedUrl, { data: uploadFileData }] = useGetPresignedUrlMutation({
    onError: handleOnGetPresignedURLS3Error,
  });
  const [uploadFileToUserOnDB, {
    loading: loadingUploadFileOnDBData,
  }] = useUploadFileToFirmOnDbMutation({
    onCompleted: onSuccessUploadFile,
    onError: handleOnUploadFileToDBError,
    refetchQueries: [
      {
        query: GetFirmFilesFromDbDocument,
        variables: {
          taxYearInterviewId,
        },
      },
      {
        query: GetAllFilesByClientDocument,
        variables: {
          userId: entityOwnerId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  // Get client files
  const [getFirmFilesFromDb, { loading: loadingFirmFilesData }] = useGetFirmFilesFromDbLazyQuery();

  const getFirmFilesByTaxYearId = () => {
    getFirmFilesFromDb({
      variables: {
        taxYearInterviewId,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        if (data?.getFirmFilesFromDb) {
          // map through the files and add a key
          setFirmFilesDataOnDB(data.getFirmFilesFromDb.map((file) => ({
            ...file,
            key: file.id,
          })));
        }
      },
      onError: handleOnLoadFirmFilesError,
    });
  };

  useEffect(() => {
    if (taxYearInterviewId) {
      getFirmFilesByTaxYearId();
    }
  }, [taxYearInterviewId]);

  const handleBeforeUpload = async ({
    name, type, uid, size,
  }): Promise<boolean> => {
    const maxSize = (1024 * 1024) * 25; // 25MB

    if (size > maxSize) {
      handleFileExceedsSize();
      return false;
    }

    if (!isAllowedFileType(type)) {
      handleOnFileTypeNotSupported();
      return false;
    }

    // Fetches the Signed URL from S3 bucket
    // Prepend with the ID to make the file name unique
    const result = await getPresignedUrl({
      variables: {
        fileName: `${entityId}/${taxYearInterviewId}/firmfiles/${name}`,
        fileType: type,
      },
    });
    setPreSignedUrls((prev) => ({
      ...(prev ?? {}),
      [uid]: result?.data?.generatePreSignedPutUrl,
    }));
    return true;
  };

  const handleFileChanged = ({ file }) => {
    if (file.status === 'removed') {
      setFileList([]);
    } else if (file.status === 'uploading') {
      setFileList((prev) => [...(prev ?? []), file]);
    } else if (file.status === 'error') {
      handleOnLoadFirmFilesError();
    } else if (file.status === 'done') {
      const {
        url,
      } = preSignedUrls[file.uid];

      const newFile = {
        ...file,
        url,
      };
      if (isAllowedFileType(file.type)) {
        setFileList([newFile]);
      }
    }
  };

  // Custom xhr upload method
  const handleUpload = async ({ onSuccess, onError, file }: any) => {
    if (isAllowedFileType(file.type)) {
      setIsFileUploading(true);
    }

    const xhr = new XMLHttpRequest();
    const {
      signedUrl, url,
    } = preSignedUrls[file.uid];

    // S3 requires PUT method!
    xhr.open('PUT', signedUrl);
    xhr.setRequestHeader('Content-Disposition', 'inline');
    xhr.setRequestHeader('Content-Type', file.type);

    xhr.onreadystatechange = async () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          onSuccess(null, file);
          await uploadFileToUserOnDB({
            variables: {
              fileRoute: `${url.split('.com/')[1]}`,
              size: file.size,
              taxYearInterviewId,
            },
          });
          setIsFileUploading(false);
        } else {
          onError(xhr.responseText, xhr.response, file);
          setIsFileUploading(false);
        }
      }
    };
    xhr.send(file);
  };

  // SignedRequest from s3 needs to be set as the action. This is the glue.
  const action = uploadFileData?.generatePreSignedPutUrl?.signedUrl;

  useEffect(() => {
    if (allFilesChanged) {
      if (taxYearInterviewId) {
        getFirmFilesByTaxYearId();
      }
      dispatch({
        type: 'UPDATE',
        payload: {
          allFilesChanged: false,
        },
      });
    }
  }, [allFilesChanged]);

  return (
    <>
      <div style={{ height: 10 }} />
      <Row justify="space-between" gutter={[10, 10]}>
        <Col>
          <DownloadAllFilesButton
            files={FirmFilesDataOnDB.filter((x) => selectedRowKeys.includes(x.id))}
            saveZipAs={`${clientFirstName} ${clientLastName} - ${jobName} - Firm Files`}
          >
            Download Selected Files
          </DownloadAllFilesButton>
        </Col>
        <Col>
          <Upload
            action={action}
            fileList={fileList}
            showUploadList={isFileUploading}
            customRequest={(e) => handleUpload(e)}
            beforeUpload={(args) => handleBeforeUpload(args)}
            onChange={(e) => handleFileChanged(e)}
            disabled={isFileUploading}
            multiple
          >
            <Button
              type="default"
              icon={<CloudUploadOutlined />}
              iconDirection="left"
              loading={isFileUploading}
            >
              Upload Files
            </Button>
          </Upload>
        </Col>
      </Row>
      <div style={{ height: 10 }} />
      {FirmFilesDataOnDB?.length > 0 ? (
        <DocumentsTable
          tableType={DocumentsTableType.Firm}
          tableData={FirmFilesDataOnDB}
          loading={loadingUploadFileOnDBData || loadingFirmFilesData}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      ) : <Empty description="No Data" />}
      <CSVLink
        data={FirmFilesDataOnDB}
        filename="table.csv"
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </>
  );
}

export default FirmFiles;
