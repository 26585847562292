import { useEffect } from 'react';
import { Row, Button } from 'taxaroo-ui';
import lottie from 'lottie-web/build/player/lottie_light';
import { Link } from 'react-router-dom';
import notFoundJson from '~src/assets/animations/404.json';

const NotFound = () => {
  useEffect(() => {
    const Elem = document.querySelector('#notFoundAnimation');
    if (Elem) {
      lottie.loadAnimation({
        container: Elem,
        animationData: notFoundJson,
      });
    }
  }, []);
  return (
    <Row justify="center">
      <div
        id="notFoundAnimation"
        style={{ width: '100%', height: '80vh', marginBottom: '-12vh' }}
      />
      <Link to="/">
        <Button type="primary" size="large">
          Back To Home
        </Button>
      </Link>
    </Row>
  );
};

export default NotFound;
