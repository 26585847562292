import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFileRequestByTaxYearInterviewIdQueryVariables = Types.Exact<{
  taxYearInterviewId: Types.Scalars['String'];
}>;


export type GetFileRequestByTaxYearInterviewIdQuery = { __typename?: 'Query', fileRequestsByTaxYearInterviewId: Array<{ __typename?: 'FileRequestEntity', id: string, userId?: string | null, notes?: string | null, title?: string | null, signatureRequest?: any | null, signatureComplete?: any | null, createAt: any, updateAt: any, taxYearInterviewId: string, FileApproval?: Array<{ __typename?: 'FileApprovalEntity', id: string, expiresAt: any, taskId?: string | null, completedAt?: any | null, createAt: any, updateAt: any, email: string, name: string, KBA?: Array<{ __typename?: 'KbaEntity', id: string, numKbaFailedAttempts?: number | null, numRequestedIdScans?: number | null, numFailedIdScans?: number | null, type: Types.KbaType, isIdScanPassed: boolean, isIdScanPending: boolean, isValidated: boolean, KBAFields?: Array<{ __typename?: 'KBAFieldEntity', id: string, description?: string | null, value?: string | null, type: Types.QuestionType }> | null }> | null, Tasks?: { __typename?: 'TaskEntity', id: string, taskType?: Types.TaskType | null, completedAt?: any | null, createAt: any, updateAt: any, deletedAt?: any | null } | null, SignatureFields?: Array<{ __typename?: 'SignatureFieldEntity', id: string, page?: number | null, fieldLeftPercentage?: number | null, fieldTopPercentage?: number | null, fieldWidthPercentage?: number | null, fieldHeightPercentage?: number | null, fieldType: Types.FieldType, completedAt?: any | null, title?: string | null, description?: string | null, signatureImage?: string | null, fileApprovalId: string }> | null }> | null, CompiledFile?: { __typename?: 'FileEntity', id: string, name?: string | null, s3Key?: string | null } | null, SignedFile?: { __typename?: 'FileEntity', id: string, name?: string | null, s3Key?: string | null } | null }> };


export const GetFileRequestByTaxYearInterviewIdDocument = gql`
    query GetFileRequestByTaxYearInterviewId($taxYearInterviewId: String!) {
  fileRequestsByTaxYearInterviewId(taxYearInterviewId: $taxYearInterviewId) {
    id
    userId
    notes
    title
    signatureRequest
    signatureComplete
    createAt
    updateAt
    taxYearInterviewId
    FileApproval {
      id
      expiresAt
      taskId
      completedAt
      createAt
      updateAt
      email
      name
      KBA {
        id
        numKbaFailedAttempts
        numKbaFailedAttempts
        numRequestedIdScans
        numFailedIdScans
        type
        isIdScanPassed
        isIdScanPending
        isValidated
        KBAFields {
          id
          description
          value
          type
        }
      }
      Tasks {
        id
        taskType
        completedAt
        createAt
        updateAt
        deletedAt
      }
      SignatureFields {
        id
        page
        fieldLeftPercentage
        fieldTopPercentage
        fieldWidthPercentage
        fieldHeightPercentage
        fieldType
        completedAt
        title
        description
        signatureImage
        fileApprovalId
      }
    }
    CompiledFile {
      id
      name
      s3Key
    }
    SignedFile {
      id
      name
      s3Key
    }
  }
}
    `;

/**
 * __useGetFileRequestByTaxYearInterviewIdQuery__
 *
 * To run a query within a React component, call `useGetFileRequestByTaxYearInterviewIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileRequestByTaxYearInterviewIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileRequestByTaxYearInterviewIdQuery({
 *   variables: {
 *      taxYearInterviewId: // value for 'taxYearInterviewId'
 *   },
 * });
 */
export function useGetFileRequestByTaxYearInterviewIdQuery(baseOptions: Apollo.QueryHookOptions<GetFileRequestByTaxYearInterviewIdQuery, GetFileRequestByTaxYearInterviewIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFileRequestByTaxYearInterviewIdQuery, GetFileRequestByTaxYearInterviewIdQueryVariables>(GetFileRequestByTaxYearInterviewIdDocument, options);
      }
export function useGetFileRequestByTaxYearInterviewIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFileRequestByTaxYearInterviewIdQuery, GetFileRequestByTaxYearInterviewIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFileRequestByTaxYearInterviewIdQuery, GetFileRequestByTaxYearInterviewIdQueryVariables>(GetFileRequestByTaxYearInterviewIdDocument, options);
        }
export type GetFileRequestByTaxYearInterviewIdQueryHookResult = ReturnType<typeof useGetFileRequestByTaxYearInterviewIdQuery>;
export type GetFileRequestByTaxYearInterviewIdLazyQueryHookResult = ReturnType<typeof useGetFileRequestByTaxYearInterviewIdLazyQuery>;
export type GetFileRequestByTaxYearInterviewIdQueryResult = Apollo.QueryResult<GetFileRequestByTaxYearInterviewIdQuery, GetFileRequestByTaxYearInterviewIdQueryVariables>;
export const namedOperations = {
  Query: {
    GetFileRequestByTaxYearInterviewId: 'GetFileRequestByTaxYearInterviewId'
  }
}