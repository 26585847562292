import { useReactiveVar } from '@apollo/client';
import {
  Drawer,
  Icons,
} from 'taxaroo-ui';
import { hamburgerMenuOpen } from '../../../cache';
import Menu from '../LeftSideMenu/Menu';
// import * as styles from './styles.module.css';

const { CloseOutlined } = Icons;

function HamburgerMenu() {
  const isHamburgerMenuOpen = useReactiveVar(hamburgerMenuOpen);
  return (
    <Drawer
      // title="Taxaroo"
      placement="left"
      onClose={() => hamburgerMenuOpen(false)}
      visible={isHamburgerMenuOpen}
      bodyStyle={{ zoom: '1.2', backgroundColor: 'var(--primary-dark)', padding: '5px' }}
      closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      forceRender
    >
      <Menu />
    </Drawer>
  );
}

export default HamburgerMenu;
