.breadcrumb {
  margin-bottom: 24px !important;
}

.reload-icon {
  transition: color, opacity 300ms ease;
}

.reload-icon:hover {
  cursor: pointer;
  color: #3590ff;
}

.reload-icon:active {
  opacity: 0.5;
}
