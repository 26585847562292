.noClientsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}

.container img {
  width: 130px;
}

.container p {
  margin: 0;
  color: #0a64b2;
  font-weight: 600;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
}
