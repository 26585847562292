import { SortableHandle } from 'react-sortable-hoc';
import { Icons, Tooltip } from 'taxaroo-ui';

const { MenuOutlined } = Icons;

const DragHandle = SortableHandle(({ disabled }: { disabled: boolean }) => {
  if (disabled) {
    return (
      <Tooltip title="Default statuses are not sortable">
        <MenuOutlined style={{ cursor: 'not-allowed', color: '#999', display: 'block' }} />
      </Tooltip>
    );
  }
  return <MenuOutlined style={{ cursor: 'ns-resize', color: '#999', display: 'block' }} />;
});

export default DragHandle;
