.layout {
  min-height: calc(100vh - 70px);
  display: flex;
  background-color: #fff !important;
  padding: 0 100px 40px;
}

.title {
  color: #292726;
  font-family: "Open Sans", sans-serif !important;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
}

.subText {
  color: #292726;
  font-family: "Open Sans", sans-serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.callToActionBox {
  max-width: 595px;
  border-radius: 15px;
  background: linear-gradient(270deg, #009be0 0%, #0057a5 100%);
  padding: 30px;
}

.callToActionTitle {
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.callToActionText {
  color: #d7d7d7;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.callToActionButton {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: #fff;
  color: #0057a5;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
  text-transform: none;
}

.clouds {
  display: block;
  width: 519.025px;
  height: 518.94px;
  flex-shrink: 0;
  fill: linear-gradient(180deg, #0057a5 0%, #009be0 100%);
  opacity: 0.2;
}

.person {
  position: absolute;
  right: 200px;
  bottom: 0;
  width: 632px;
  height: 597px;
  flex-shrink: 0;
}

.featuresList {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.featuresList ul {
  list-style: none;
  padding: 0;
}

.featuresList li {
  position: relative;
  padding: 8px 0;
  padding-left: 30px; /* Space for check mark */
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: linear-gradient(180deg, #0057a5 0%, #009be0 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.featuresList li::before {
  content: "✔"; /* Unicode character for check mark */
  position: absolute;
  left: 0;
  font-size: 16px; /* Adjust size as needed */
  background: linear-gradient(180deg, #0057a5 0%, #009be0 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.featuresList li::selection {
  background-color: #0074ffcc;
}

@media (max-width: 1315px) {
  .clouds {
    display: none;
  }

  .person {
    bottom: 0;
    right: 0;
  }
}

@media (max-width: 1199px) {
  .layout {
    padding-left: 50px;
    padding-right: 50px;
  }

  .clouds {
    display: none;
  }

  .person {
    bottom: -100px;
    right: 0;
  }
}

@media (max-width: 999px) {
  .person {
    right: 0;
    width: 386px;
    height: 408px;
  }
}

@media (max-width: 767px) {
  .layout {
    padding-left: 40px;
    padding-right: 40px;
  }

  .person {
    display: none;
  }
}

@media (max-width: 576px) {
  .layout {
    padding-left: 20px;
    padding-right: 20px;
  }
}
