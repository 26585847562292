import React, { FC } from 'react';
import moment from 'moment';
import { Avatar, Typography } from 'taxaroo-ui';
import { UserEntity } from '~src/graphql';
import * as styles from './style.module.css';

const { Text } = Typography;

const getAcronim = (str: string) => {
  const matches = str.match(/\b(\w)/g);
  const acronym = matches?.join('');
  return acronym?.substring(0, 2).toUpperCase();
};

interface ChatChannelProps {
  data: UserEntity;
  isSelected: boolean;
  onSelect: (id: string) => void;
}

const ChatChannel: FC<ChatChannelProps> = ({
  data,
  onSelect,
  isSelected,
}) => {
  const {
    id,
    Entity,
    UserInformation,
    UserFirmAccount,
  } = data;
  const { firstName, lastName } = UserInformation ?? { firstName: '', lastName: '' };

  const { Roles } = (!Entity.UserFirmAccount.length ? UserFirmAccount.find(({ userId }) => userId === id) : Entity.UserFirmAccount.find(({ Entity: firmEntity }) => firmEntity.Users.id === id)) ?? { Roles: { description: '' } };

  // eslint-disable-next-line max-len
  // TODO when the integration of sending messages is ready set this variable since this component is used in ChatList component
  // Messages.length > 0 ? moment(Messages[Messages.length - 1].updatedAt).fromNow() :
  const lastMessage = '';

  // ClientInterviews[0] && ClientInterviews[0].interviewForm
  // ? ClientInterviews[0].interviewForm.taxpayer_occupation
  // :
  // eslint-disable-next-line max-len
  // TODO: When integrating the change of "occupation" in the UserInformation table adjust the value of this variable
  const occupation = Roles?.description ?? '';

  const selectConversation = () => {
    onSelect(id);
  };
  // selectedConversation ? styles.containerSelect :

  return (
    <div
      className={isSelected ? styles.containerSelect : styles.channelChatContainer}
      onClick={selectConversation}
      onKeyDown={selectConversation}
      role="button"
      tabIndex={0}
    >
      <Avatar style={{ backgroundColor: '#22A7E7' }} size={40}>
        {getAcronim(`${firstName} ${lastName}`)}
      </Avatar>
      <div className={styles.containerData}>
        <Text className={styles.name}>{`${firstName} ${lastName}`}</Text>
        <Text className={styles.message}>{occupation}</Text>
      </div>
      {lastMessage !== '' && (
        <div className={styles.containeLastSeen}>
          <Text className={styles.name}>Last Seen</Text>
          <Text className={styles.message}>{lastMessage}</Text>
        </div>
      )}
    </div>
  );
};

export default ChatChannel;
