import { message } from 'taxaroo-ui';

const onSuccessMutation = () => {
  message.success('File status updated');
};

const handleOnError = () => {
  message.error('An error has ocurred while trying to update file status. Please try again');
};

export {
  onSuccessMutation,
  handleOnError,
};
