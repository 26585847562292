.passwordTooltip {
  max-width: 90% !important;
  word-wrap: break-word !important;
  border-radius: 10px !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.warningCircleFilled {
  font-size: 12px !important;
  color: white;
}

.checkCircleFilled {
  font-size: 12px !important;
  color: #399031 !important;
}
