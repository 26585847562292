import {
  Typography, Row,
} from 'taxaroo-ui';
import RegisterForm from '~src/components/organisms/RegisterFlow/RegisterForm';
import * as styles from './styles.module.css';

const { Title } = Typography;

interface RegisterPageProps {
  free?: boolean;
}
const RegisterSection = ({ free = false }: RegisterPageProps) => {
  const title = free ? 'Unlock Your Free Taxaroo Experience' : 'Start Your Taxaroo 7-day Free Trial';
  return (
    <div className={`showScrollbar ${styles.registerFlowContainer}`}>
      <Row justify="center" style={{ flex: 1, marginTop: -5 }}>
        <Row justify="center" style={{ marginBottom: 20 }}>
          <Title level={3}>
            {title}
          </Title>
        </Row>
        <RegisterForm free={free} />
      </Row>
    </div>
  );
};
RegisterSection.defaultProps = {
  free: false,
};

export default RegisterSection;
