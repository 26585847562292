import { FC } from 'react';
import moment from 'moment';
import { Popover } from 'taxaroo-ui';
import AvailabilityModal from '../../atoms/AvailabilityModal';
import * as styles from './style.module.css';

interface AvailabilityProps {
  event: any
  color: string
  deleteBracket: any
}

const Availability: FC<AvailabilityProps> = ({
  event: {
    day, end, start, bracket,
  }, color, deleteBracket,
}) => {
  const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

  return (
    <Popover
      placement="right"
      title={capitalize(day.toLowerCase())}
      content={(
        <AvailabilityModal
          startHour={moment(start).format('HH:mm')}
          endHour={moment(end).format('HH:mm')}
          del={() => deleteBracket(day, bracket)}
        />
      )}
    >
      <div className={styles.container} style={{ backgroundColor: color }} />
    </Popover>
  );
};

export default Availability;
