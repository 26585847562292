.newConversationModal {
  height: 100%;
}

.list {
  max-height: 50vh;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.list::-webkit-scrollbar {
  display: none;
}

.btnok {
  width: 90px;
}

.searchUsersInput > span > span {
  display: none !important;
}

.searchUsersInput > span > input {
  border-radius: 6px !important;
}

.spinContainer {
  height: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
