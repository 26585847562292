import ActionType from './action.type.enum';

const getInitialStateAction = (payload: Record<string, unknown>) => ({
  type: ActionType.INITIAL_STATE, payload,
});

const getESignModalOKButtonOnClickAction = (payload: Record<string, unknown>) => ({
  type: ActionType.E_SIGN_MODAL_OK_BUTTON_CLICK, payload,
});

/**
 * This is called when the tabs inside the modal ( E Signature and Signature Fields ) are clicked.
 * @param payload
 */
const getESignModalTabOnChangeAction = (payload: Record<string, unknown>) => ({
  type: ActionType.E_SIGN_MODAL_CONTENT_TAB_ONCHANGE, payload,
});

const getESignModalCancelButtonOnClickAction = (payload: Record<string, unknown>) => ({
  type: ActionType.E_SIGN_MODAL_CANCEL_BUTTON_CLICK, payload,
});

const update = (payload: Record<string, unknown>) => ({
  type: ActionType.UPDATE,
  payload,
});

const resetForm = () => ({
  type: ActionType.RESET_FORM,
});

const actionCreators = {
  getInitialStateAction,
  getESignModalOKButtonOnClickAction,
  getESignModalTabOnChangeAction,
  getESignModalCancelButtonOnClickAction,
  update,
  resetForm,
};

export default actionCreators;
