.flex-child {
  flex: 1;
  margin: 0 7px 12px 0;
}

.options-container {
  min-width: 200px;
}

.select {
  min-width: 200px;
}

@media all and (max-width: 570px) {
  .options-container {
    min-width: 120px;
  }

  .select {
    min-width: 120px;
    width: 100%;
  }
}

.popconfirmDangerButton span.anticon {
  margin-left: 0 !important;
}

.info {
  color: gray;
  font-size: 12px;
}
