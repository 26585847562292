import {
  useState, useRef, useEffect, useContext,
} from 'react';
import {
  Row, Col, Button, Icons, Upload, Popover, Empty,
} from 'taxaroo-ui';
import { CSVLink } from 'react-csv';
import {
  useGetPresignedUrlMutation,
  useUploadFileToUserOnDbMutation,
} from '~src/graphql/mutations/clients';
import {
  GetAllFilesByClientDocument,
  GetClientFilesFromDbDocument,
  useGetClientFilesFromDbLazyQuery,
  useGetClientFilesFromDbQuery,
} from '~src/graphql/queries/clients';
import DownloadAllFilesButton from '~src/components/atoms/DownloadAllFilesButton';
import { JobContext } from '~src/components/providers/job-provider';
import { isAllowedFileType } from '~src/components/helpers/fileTypes';
import { TaxPayerContext } from '~src/components/providers/taxpayer-provider';
import { FileSuccessEntity } from '~src/graphql';
import RequestFilesModal from '../../../../../molecules/RequestFilesModal';
import DocumentsTable, { DocumentsTableType } from '../../../DocumentsTable';
import {
  handleFileExceedsSize,
  handleOnFileTypeNotSupported,
  handleOnGetPresignedURLS3Error,
  handleOnLoadClientFilesError,
  handleOnUploadFileToDBError,
  onSuccessUploadFile,
} from './utils';

const { CloudUploadOutlined } = Icons;
interface PreSignedUrlMapping {
  [uid: string]: FileSuccessEntity
}

interface ClientFilesProps {
  jobName: string;
}

function ClientFiles({ jobName }: ClientFilesProps) {
  const jobContext = useContext(JobContext);
  const {
    taxYearInterviewId,
    entityId,
    entityOwnerId,
    clientFirstName,
    clientLastName,
  } = jobContext;
  const csvLink = useRef<any>();
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [isRequestFilesModalVisible, setIsRequestFilesModalVisible] = useState(false);
  const [fileList, setFileList] = useState<any[]>();
  const [clientFilesDataOnDB, setClientFilesDataOnDB] = useState([]);
  const [clientRequestedFilesDataOnDB, setClientRequestedFilesDataOnDB] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { allFilesChanged, dispatch } = useContext(TaxPayerContext);
  const [preSignedUrls, setPreSignedUrls] = useState<PreSignedUrlMapping>();

  const [getPresignedUrl, { data: uploadFileData }] = useGetPresignedUrlMutation({
    onError: handleOnGetPresignedURLS3Error,
  });
  const [uploadFileToUserOnDB, {
    loading: loadingUploadFileOnDBData,
  }] = useUploadFileToUserOnDbMutation({
    onCompleted: onSuccessUploadFile,
    onError: handleOnUploadFileToDBError,
    refetchQueries: [
      {
        query: GetClientFilesFromDbDocument,
        variables: {
          taxYearInterviewId,
          userId: entityOwnerId,
        },
      },
      {
        query: GetAllFilesByClientDocument,
        variables: {
          userId: entityOwnerId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  // Get client files
  const [getClientFilesFromDbQuery, { loading: loadingFilesData }] = useGetClientFilesFromDbLazyQuery();
  const getClientFilesFromDb = () => {
    getClientFilesFromDbQuery({
      variables: {
        taxYearInterviewId,
        userId: entityOwnerId,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data?.getClientFilesFromDb) {
          // map data to add key
          setClientFilesDataOnDB(data.getClientFilesFromDb.map((x) => ({
            ...x,
            key: x.id,
          })));
        }
        if (data?.ClientRequestedFiles) {
          setClientRequestedFilesDataOnDB(data.ClientRequestedFiles);
        }
      },
      onError: handleOnLoadClientFilesError,
    });
  };

  useEffect(() => {
    if (taxYearInterviewId && entityOwnerId) {
      getClientFilesFromDb();
    }
  }, [taxYearInterviewId, entityOwnerId]);

  const handleRequestFiles = () => {
    setIsRequestFilesModalVisible(true);
  };

  const handleBeforeUpload = async ({
    name, type, uid, size,
  }): Promise<boolean> => {
    const maxSize = (1024 * 1024) * 25; // 25MB

    if (size > maxSize) {
      handleFileExceedsSize();
      return false;
    }

    if (!isAllowedFileType(type)) {
      handleOnFileTypeNotSupported();
      return false;
    }

    // Fetches the Signed URL from S3 bucket
    // Prepend with the ID to make the file name unique
    const result = await getPresignedUrl({
      variables: {
        fileName: `${entityId}/${taxYearInterviewId}/clientfiles/${name}`,
        fileType: type,
      },
    });
    setPreSignedUrls((prev) => ({
      ...(prev ?? {}),
      [uid]: result?.data?.generatePreSignedPutUrl,
    }));
    return true;
  };

  const handleFileChanged = ({ file }) => {
    if (file.status === 'removed') {
      setFileList([]);
    } else if (file.status === 'uploading') {
      setFileList((prev) => [...(prev ?? []), file]);
    } else if (file.status === 'error') {
      handleOnLoadClientFilesError();
    } else if (file.status === 'done') {
      const {
        url,
      } = preSignedUrls[file.uid];

      const newFile = {
        ...file,
        url,
      };
      if (isAllowedFileType(file.type)) {
        setFileList([newFile]);
      }
    }
  };

  // Custom xhr upload method
  const handleUpload = async ({ onSuccess, onError, file }: any) => {
    if (isAllowedFileType(file.type)) {
      setIsFileUploading(true);
    }

    const xhr = new XMLHttpRequest();
    const {
      signedUrl, url,
    } = preSignedUrls[file.uid];

    // S3 requires PUT method!
    xhr.open('PUT', signedUrl);
    xhr.setRequestHeader('Content-Disposition', 'inline');
    xhr.setRequestHeader('Content-Type', file.type);

    xhr.onreadystatechange = async () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          onSuccess(null, file);
          await uploadFileToUserOnDB({
            variables: {
              fileRoute: `${url.split('.com/')[1]}`,
              size: file.size,
              taxYearInterviewId,
              userId: entityOwnerId,
            },
          });
          setIsFileUploading(false);
        } else {
          onError(xhr.responseText, xhr.response, file);
          setIsFileUploading(false);
        }
      }
    };
    xhr.send(file);
  };

  // SignedRequest from s3 needs to be set as the action. This is the glue.
  const action = uploadFileData?.generatePreSignedPutUrl?.signedUrl;

  const clientFilesTableData = [
    ...clientFilesDataOnDB,
    ...clientRequestedFilesDataOnDB.map((x) => ({
      id: x.id,
      name: x.fileName,
      taxYearInterviewId: x.taxYearInterviewId,
      createAt: x.createAt,
    })),
  ];

  useEffect(() => {
    if (allFilesChanged) {
      if (taxYearInterviewId && entityOwnerId) {
        getClientFilesFromDb();
      }
      dispatch({
        type: 'UPDATE',
        payload: {
          allFilesChanged: false,
        },
      });
    }
  }, [allFilesChanged]);

  return (
    <>
      <div style={{ height: 10 }} />
      <Row justify="space-between" gutter={[10, 10]}>
        <Col>
          <DownloadAllFilesButton
            files={clientFilesDataOnDB.filter((x) => selectedRowKeys.includes(x.id))}
            saveZipAs={`${clientFirstName} ${clientLastName} - ${jobName} - Client Files`}
          >
            Download Selected Files
          </DownloadAllFilesButton>
        </Col>
        <Col>
          <Row gutter={[10, 10]}>
            <Col>
              <Upload
                action={action}
                fileList={fileList}
                showUploadList={isFileUploading}
                customRequest={(e) => handleUpload(e)}
                beforeUpload={(args) => handleBeforeUpload(args)}
                onChange={(e) => handleFileChanged(e)}
                disabled={isFileUploading}
                multiple
              >
                <Popover
                  content="Add files to your clients' uploaded documents"
                >
                  <Button
                    type="default"
                    icon={<CloudUploadOutlined />}
                    iconDirection="left"
                    loading={isFileUploading}
                  >
                    Upload Files
                  </Button>
                </Popover>
              </Upload>
            </Col>
            <Col>
              <Popover
                content="Request client files with automated follow-ups"
              >
                <Button
                  type="primary"
                  icon={<CloudUploadOutlined />}
                  iconDirection="left"
                  onClick={handleRequestFiles}
                >
                  Request Files
                </Button>
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>
      <div style={{ height: 10 }} />
      {clientFilesTableData?.length > 0 ? (
        <DocumentsTable
          tableType={DocumentsTableType.Client}
          tableData={clientFilesTableData}
          loading={loadingUploadFileOnDBData || loadingFilesData}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      ) : <Empty description="No Data" />}
      <CSVLink
        data={clientFilesDataOnDB}
        filename="table.csv"
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
      <RequestFilesModal
        isModalVisible={isRequestFilesModalVisible}
        setIsModalVisible={setIsRequestFilesModalVisible}
      />
    </>
  );
}

export default ClientFiles;
