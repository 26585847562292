import React, { useState, useEffect, FC } from 'react';
import {
  Alert,
  Row,
  Layout,
  Space,
  Typography,
  Spin,
} from 'taxaroo-ui';
import {
  useCurrentStripeInformationQuery,
} from '~src/graphql/queries/settings';
import SettingsHeader from '../SettingsHeader';
import StripeInfo from './StripeInfo';

const { Paragraph, Text } = Typography;

const ClientInvoicing: FC = () => {
  const {
    data: stripeInfo,
    loading: loadingStripeInfo,
    error: stripeInfoError,
  } = useCurrentStripeInformationQuery();
  const [error, setError] = useState<string | undefined>(undefined);
  const [info, setInfo] = useState<string | undefined>(undefined);

  const setErrorHandler = (errorMessage?: string) => {
    setError(errorMessage);
  };

  useEffect(() => {
    if (stripeInfoError) {
      if (stripeInfoError.graphQLErrors[0].name === 'PermissionException') {
        setInfo('The admin of your account can connect Stripe to Taxaroo');
      } else {
        setError(stripeInfoError.message);
      }
    }
  }, [stripeInfoError]);

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <Space direction="vertical" style={{ width: '98%' }}>
        <SettingsHeader
          title="Setup Invoicing"
          tooltipTitle="Why do we need this information?"
          tooltipContent={(
            <Paragraph>
              This allows clients to pay easily and securely with credit card.
              For your protection,
              {' '}
              <Text strong> we do not view or store this information </Text>
              on our own servers. We pass this information securely to our payment services
              provider,
              <a href="https://stripe.com" target="_blank" rel="noreferrer"> Stripe </a>
              .
            </Paragraph>
            )}
        />
        <Row>
          <Text strong>
            Setup your bank account to accept direct payments
          </Text>
        </Row>
        <Row>
          <Paragraph>
            We have partnered with
            {' '}
            <a href="https://stripe.com" target="_blank" rel="noreferrer"> Stripe </a>
            to securely manage your bank account. When a client pays an invoice, Stripe
            will process directly into your bank account.
          </Paragraph>
        </Row>

        {loadingStripeInfo
          ? (
            <Row align="middle" justify="center" style={{ paddingTop: '15px' }}>
              <Spin size="large" />
            </Row>
          )
          : !stripeInfoError && (
            <StripeInfo
              handleSetError={setErrorHandler}
              areStripeInfoLoading={loadingStripeInfo}
              connectAccountId={stripeInfo?.CurrentStripeInformation.connectAccountId}
              isOnBoardingFinished={stripeInfo?.CurrentStripeInformation.finishedOnboarding}
            />
          )}
        {error
        && (
          <Row align="middle" justify="center" style={{ paddingTop: '15px' }}>
            <Alert
              style={{ width: '100%', padding: '20px' }}
              message={error}
              type="error"
              closable
              onClose={() => { setError(undefined); }}
            />
          </Row>
        )}
        {info
        && (
          <Row align="middle" justify="center" style={{ paddingTop: '15px' }}>
            <Alert
              style={{ width: '100%', padding: '20px' }}
              message={info}
              type="info"
              closable
              onClose={() => { setInfo(undefined); }}
            />
          </Row>
        )}
      </Space>
    </Layout>
  );
};

export default ClientInvoicing;
