import axios from 'axios';
import moment from 'moment';
import { Typography, Icons } from 'taxaroo-ui';
import React, { useContext, useEffect, useState } from 'react';
import { TaxPayerContext } from '../../providers/taxpayer-provider';
import config from '../../helpers/config';
import * as styles from './style.module.css';

const { Text } = Typography;
const { ClockCircleOutlined, ScheduleOutlined } = Icons;

function AppointmentsDetail() {
  const {
    ownerId: id,
  } = useContext(TaxPayerContext);
  const [next, setNext] = useState<{startDate: any, endDate: any}>();
  const [last, setLast] = useState<{startDate: any, endDate: any}>();

  const isAfter = (dateString: string) => {
    const today = moment().format('YYYY-MM-DD HH:mm');
    const date = moment(dateString).format('YYYY-MM-DD HH:mm');
    return moment(date).isAfter(today);
  };

  useEffect(() => {
    // const getEvents = async () => {
    //   const events = await axios({
    //     method: 'get',
    //     url: `${config.serverUrl}/events`,
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });

    //   const nextAppointment = events.data.filter(
    //     (ap: any) => ap.status === 'SCHEDULED' && isAfter(ap.startDate),
    //   );
    //   if (nextAppointment.length > 0) {
    //     const { startDate, endDate } = nextAppointment[0];
    //     setNext({ startDate, endDate });
    //   } else setNext(undefined);
    //   const lastAppointment = events.data.filter((ap: any) => ap.status === 'COMPLETED');
    //   if (lastAppointment.length > 0) {
    //     const { startDate, endDate } = lastAppointment[lastAppointment.length - 1];
    //     setLast({ startDate, endDate });
    //   } else setLast(undefined);
    // };

    // getEvents();
  }, [id]);

  return (
    <>
      {next && (
        <div className={styles.containerAppointment}>
          <Text className={`${styles.subtitle} ${styles.active}`}>Next Appointment</Text>
          <div className={styles.itemAppointment}>
            <ScheduleOutlined />
            <p>{moment(next.startDate).format('MMM DD, YYYY')}</p>
          </div>
          <div className={styles.itemAppointment}>
            <ClockCircleOutlined />
            <p>{`${moment(next.startDate).format('LT')} - ${moment(next.endDate).format('LT')}`}</p>
          </div>
        </div>
      )}
      {last && (
        <div className={styles.containerAppointment}>
          <Text className={styles.subtitle}>Last Appointment</Text>
          <div className={styles.itemAppointment}>
            <ScheduleOutlined />
            <p>{moment(last.startDate).format('MMM DD, YYYY')}</p>
          </div>
          <div className={styles.itemAppointment}>
            <ClockCircleOutlined />
            <p>{`${moment(last.startDate).format('LT')} - ${moment(last.endDate).format('LT')}`}</p>
          </div>
        </div>
      )}
    </>
  );
}

export default AppointmentsDetail;
