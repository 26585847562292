.userSidebarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  width: 270px;
  overflow-y: auto;
  padding-top: 40px;
  box-shadow: 0 5px 5px 5px #f2f2f2;
}

.containerSchedulle {
  border-left: 1px solid #c2c2c2;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
  width: 300px;
}

.userSidebarBadge {
  margin-top: 0;
}

.userSidebarBadge sup {
  width: 15px;
  height: 15px;
  top: 70px;
}

.name {
  margin-top: 10px;
  color: #0a64b2 !important;
  text-align: center;
}

.subtitle {
  font-size: 16px !important;
  font-weight: 600;
  color: #565656 !important;
}

.active {
  color: #0a64b2 !important;
}

.lastseen {
  margin-top: 5px;
  color: #a1a1a1 !important;
  font-size: 12px;
}

.displaySchedule {
  background-color: #dfe9f7;
}

.displaySchedule svg {
  color: #22a7e7;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.btnSideBar {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.btnSideBar svg {
  color: #565656;
}

.btnSideBar:hover,
.btnSideBar:focus {
  background-color: #f4f4f4;
}

.btnSideBar:hover svg,
.btnSideBar:focus svg {
  color: #22a7e7;
}

.btnSideBar button[disabled],
.btnSideBar button[disabled] svg {
  background: #ccc;
  color: #fff;
}

.btnMedia img {
  width: 17px;
  height: 17px;
}

@media only screen and (max-device-width: 700px) {
  .userSidebarContainer {
    width: unset;
    min-height: 25vh;
    max-height: 25vh;
  }
}
