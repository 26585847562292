import React from 'react';
import { Button as AntdButton, ButtonProps } from 'antd';

export interface TaxButtonProps extends ButtonProps {
  /**
   * Button type display state
   */
  type?: 'default' | 'primary' | 'link' | 'dashed';
  iconDirection?: 'left' | 'right';
}

const Button: React.FC<TaxButtonProps> = ({
  type = 'default',
  iconDirection = 'right',
  icon,
  children,
  className,
  ...rest
}) => (
  <AntdButton
    type={type}
    // icon={iconDirection === 'left' && icon}
    className={!children ? `justAnIcon ${className}` : className}
    {...rest}
  >
    {(icon && iconDirection === 'left') && (
    <span>
      {icon}
    </span>
    )}
    {children}
    {(icon && iconDirection === 'right') && (
    <span>
      {icon}
    </span>
    )}
  </AntdButton>
);

export default Button;
