export default class Firms {
  public static getFirmName(firm: {
    name: string;
    Users: {
      UserInformation: {
        firstName: string;
        lastName: string;
      }
    }
  }): string {
    if (firm.name) {
      return firm.name;
    }

    const userInfo = firm.Users?.UserInformation;
    if (userInfo) {
      return `${userInfo.firstName} ${userInfo.lastName}`;
    }

    return null;
  }
}
