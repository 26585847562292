import {
  Icons, Tooltip,
} from 'taxaroo-ui';

const {
  Circle,
  CheckCircleOutlined,
} = Icons;

function Notes(props: any) {
  const { record } = props || {};
  const { notes } = record || {};

  if (notes?.length > 0) {
    return (
      <Tooltip title="Notes found">
        <CheckCircleOutlined className="status-icon" style={{ color: 'green' }} />
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Notes not found">
      <span role="img" tabIndex={-1} className="anticon status-icon">
        <Circle width="1em" height="1em" className="status-icon" style={{ color: 'gray' }} />
      </span>
    </Tooltip>
  );
}

export default Notes;
