import { message } from 'taxaroo-ui';

const onSuccessResendActivation = () => {
  message.success('Activation sent succesfully!');
};

const handleOnError = (error) => {
  const errorMessage = error?.graphQLErrors?.[0]?.message;
  if (errorMessage === 'Error code 1022') {
    message.error('An error has occurred, the user has been already activated.');
  } else {
    message.error(errorMessage);
  }
};

export {
  handleOnError,
  onSuccessResendActivation,
};
