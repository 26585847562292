import { FC, useEffect, useState } from 'react';
import {
  Tabs, Row, Col, Typography, Grid, Icons,
} from 'taxaroo-ui';
import { useNavigate, useParams } from 'react-router-dom';

import ProfilePhoto from '~src/components/templates/ProfilePhoto';
import FirmInfo from '~src/components/templates/FirmInfo';
import SiteBranding from '~src/components/templates/SiteBranding';
import Calendars from '~src/components/organisms/Settings/Calendars';
import Notifications from '~src/components/organisms/Settings/Notifications';
import Pixels from '~src/components/organisms/Settings/Pixels';
import ClientInvoicing from '~src/components/organisms/Settings/ClientInvoicing';
import Security from '~src/components/organisms/Settings/Security';
import TaxarooDrive from '~src/components/organisms/Settings/TaxarooDrive';
import InviteLink from '~src/components/templates/InviteLink';
import ProgressStatuses from '~src/components/templates/ProgressStatuses';
import ManageTeam from '~src/components/templates/ManageTeam';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import { useAppSelector } from '~src/redux/hooks';
import { useCreateBillingPortalSessionMutation } from '~src/graphql/mutations/stripeInformation';
import { useCurrentStripeInformationQuery } from '~src/graphql/queries/settings';
import { Features, useUpgradeModal } from '~src/components/atoms/UpgradeModal/ModalProvider';

const { TabPane } = Tabs;
const { Title } = Typography;
const { useBreakpoint } = Grid;
const {
  BankOutlined,
  InfoCircleOutlined,
  PictureOutlined,
  FormatPainterOutlined,
  LinkOutlined,
  CreditCardOutlined,
  DollarOutlined,
  TeamOutlined,
  MailOutlined,
  CodeOutlined,
  ApartmentOutlined,
  LockOutlined,
  CalendarOutlined,
  CloudOutlined,
} = Icons;

const AccountSettings: FC = () => {
  const { userRole } = useAppSelector((state) => state.session);
  const [isStaff] = useState<boolean>(userRole === 'STAFF');
  const screens = useBreakpoint();
  const { sm } = screens;
  const { tab } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('firm-info');

  const { showModal, accessObject } = useUpgradeModal();

  // Get customerId
  const { data: currentStripeInformation } = useCurrentStripeInformationQuery();
  const { customerId } = currentStripeInformation?.CurrentStripeInformation ?? {};
  // Create billing portal session mutation
  const [createBillingPortalSession] = useCreateBillingPortalSessionMutation();

  const handleChange = (newTab: string) => {
    if (newTab === 'billing-portal') {
      createBillingPortalSession({
        variables: {
          createBillingPortalInput: {
            customer: customerId,
            return_url: window.location.href,
          },
        },
      }).then(({ data }) => {
        window.location.href = data?.createBillingPortalSession;
      });
      return;
    }
    if (newTab === 'taxaroo-drive') {
      if (!accessObject?.[Features.mappedDrive]) {
        showModal(Features.mappedDrive);
        return;
      }
    }
    if (newTab === 'pixels') {
      if (!accessObject?.[Features.pixels]) {
        showModal(Features.pixels);
        return;
      }
    }
    navigate(`/settings/${newTab}`);

    trackCustomerIoEvent(CUSTOMER_IO_EVENTS.TAB_SELECT, {
      'Tab Description': 'Settings Tabs',
      'Tab Selected': newTab,
      url: window.location.href,
    });
  };

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    }
  }, [tab]);

  return (
    <div>
      <Row>
        <Col>
          <Title level={3}>Account Settings</Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="card-container">
            <Tabs
              activeKey={activeTab}
              type="card"
              tabPosition={sm ? 'left' : 'top'}
              size="small"
              onChange={handleChange}
              tabBarStyle={{
                color: 'var(--primary-dark)',
              }}
            >
              <TabPane
                tab={(
                  <span>
                    <InfoCircleOutlined />
                    {isStaff ? 'Personal Info' : 'Firm Info'}
                  </span>
                )}
                key="firm-info"
              >
                <FirmInfo />
              </TabPane>

              <TabPane
                tab={(
                  <span>
                    <PictureOutlined />
                    Profile Photo
                  </span>
                )}
                key="profile-photo"
              >
                <ProfilePhoto />
              </TabPane>

              {!isStaff && (
                <TabPane
                  tab={(
                    <span>
                      <FormatPainterOutlined />
                      Site Branding
                    </span>
                )}
                  key="site-branding"
                >
                  <SiteBranding />
                </TabPane>
              )}

              <TabPane
                tab={(
                  <span>
                    <LinkOutlined />
                    Invite Link
                  </span>
                )}
                key="invite-link"
              >
                <InviteLink />
              </TabPane>

              {!isStaff && (
                <TabPane
                  tab={(
                    <span>
                      <BankOutlined />
                      Setup Invoicing
                    </span>
                  )}
                  key="setup-invoicing"
                >
                  <ClientInvoicing />
                </TabPane>
              )}

              {!isStaff && (
                <TabPane
                  tab={(
                    <span>
                      <CreditCardOutlined />
                      Billing Portal
                    </span>
                  )}
                  key="billing-portal"
                />
              )}

              {/*
                <TabPane
                  tab={(
                    <span>
                      <AppstoreAddOutlined />
                      Add-Ons
                    </span>
                  )}
                  key="add-ons"
                >
                  <AddOns />
                </TabPane>
              */}

              {/*
                <TabPane
                  tab={(
                    <span>
                      <CreditCardOutlined />
                      Credit Dashboard
                    </span>
                  )}
                  key="credit-dashboard"
                >
                  <CreditDashboard />
                </TabPane>
              */}

              {/* {!isStaff && (
                <TabPane
                  tab={(
                    <span>
                      <DollarOutlined />
                      Your Billing
                    </span>
                  )}
                  key="your-billing"
                >
                  <Invoice />
                </TabPane>
              )} */}

              {!isStaff && (
                <TabPane
                  tab={(
                    <span>
                      <TeamOutlined />
                      Manage Team
                    </span>
                  )}
                  key="manage-team"
                >
                  <ManageTeam
                    userId={321}
                    userRole="admin"
                    numTeamMembers={2}
                    // currentStep={currentStep}
                    token="token"
                  />
                </TabPane>
              )}

              <TabPane
                tab={(
                  <span>
                    <LockOutlined />
                    Security
                  </span>
                )}
                key="security"
              >
                <Security />
              </TabPane>

              <TabPane
                tab={(
                  <span>
                    <CloudOutlined />
                    Taxaroo Drive
                  </span>
                )}
                key="taxaroo-drive"
              >
                <TaxarooDrive />
              </TabPane>

              {!isStaff && (
                <TabPane
                  tab={(
                    <span>
                      <MailOutlined />
                      Notifications
                    </span>
                  )}
                  key="notifications"
                >
                  <Notifications />
                </TabPane>
              )}

              {!isStaff && (
                <TabPane
                  tab={(
                    <span>
                      <CodeOutlined />
                      Pixels
                    </span>
                  )}
                  key="pixels"
                >
                  <Pixels />
                </TabPane>
              )}

              <TabPane
                tab={(
                  <span>
                    <CalendarOutlined />
                    Calendars
                  </span>
                )}
                key="calendars"
              >
                <Calendars />
              </TabPane>

              {!isStaff && (
                <TabPane
                  tab={(
                    <span>
                      <ApartmentOutlined />
                      Workflow
                    </span>
                  )}
                  key="workflow"
                >
                  <ProgressStatuses />
                </TabPane>
              )}
            </Tabs>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AccountSettings;
