import { PhonesEntity, PhoneType } from '~src/graphql';

const getPrimaryPhone = (phones: PhonesEntity[]): PhonesEntity => {
  if (!phones) {
    return undefined;
  }
  // prioritize Mobile phones
  const personalPhones = phones.filter((phone: PhonesEntity) => phone.type === PhoneType.Mobile);
  if (personalPhones.length > 0) {
    return personalPhones[0];
  }
  // then Work phones
  const homePhones = phones.filter((phone: PhonesEntity) => phone.type === PhoneType.Work);
  if (homePhones.length > 0) {
    return homePhones[0];
  }
  // then Landline phones
  const officePhones = phones.filter((phone: PhonesEntity) => phone.type === PhoneType.Landline);
  if (officePhones.length > 0) {
    return officePhones[0];
  }

  return undefined;
};

export default getPrimaryPhone;
