import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetClientOnboardingDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetClientOnboardingDataQuery = { __typename?: 'Query', ClientOnboardingData: { __typename?: 'OnboardingClientDataEntity', ownerName?: string | null, email?: string | null, languageId?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, firstName: string, lastName: string, birthday?: any | null } | null, FirmAccount?: { __typename?: 'FirmAccountEntity', id: string, CustomLayout?: { __typename?: 'CustomLayoutEntity', id: string, headerBackgroundColor?: string | null, headerFontColor?: string | null, logo?: string | null, pixelCode?: string | null, createAt?: any | null, updateAt?: any | null } | null } | null } };

export type GetLanguagesQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLanguagesQueryQuery = { __typename?: 'Query', languages: Array<{ __typename?: 'LanguagesEntity', id: string, lang: string, description?: string | null }> };

export type GetTaxYearInterviewQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetTaxYearInterviewQuery = { __typename?: 'Query', taxYearInterview: { __typename?: 'TaxYearInterviewEntity', id: string, progressStatusId?: string | null, notes?: string | null, assignedAt: any, completedAt?: any | null, preparerAcceptedAt?: any | null, reviewStatus?: Types.ReviewStatus | null, createAt: any, updateAt: any, deletedAt?: any | null, Interviews?: { __typename?: 'InterviewEntity', id: string, name: string, isTemplate: boolean, isLive: boolean, createAt: any, updateAt: any } | null, TaxYear?: { __typename?: 'TaxYearEntity', id: string, year: number } | null } };

export type GetClientFilesFromDbQueryVariables = Types.Exact<{
  userId: Types.Scalars['String'];
  taxYearInterviewId: Types.Scalars['String'];
}>;


export type GetClientFilesFromDbQuery = { __typename?: 'Query', getClientFilesFromDb: Array<{ __typename?: 'FileEntity', id: string, userId?: string | null, firmAccountId?: string | null, taxYearInterviewId?: string | null, s3Key?: string | null, name?: string | null, size?: number | null, folder?: string | null, pages?: number | null, documentUsed?: boolean | null, documentReviewed?: boolean | null, createAt: any, updateAt: any }>, ClientRequestedFiles: Array<{ __typename?: 'RequestedFilesEntity', id: string, taxYearInterviewId: string, fileName: string, notes?: string | null, createAt?: any | null }> };

export type GetFirmFilesFromDbQueryVariables = Types.Exact<{
  taxYearInterviewId: Types.Scalars['String'];
}>;


export type GetFirmFilesFromDbQuery = { __typename?: 'Query', getFirmFilesFromDb: Array<{ __typename?: 'FileEntity', id: string, userId?: string | null, firmAccountId?: string | null, taxYearInterviewId?: string | null, s3Key?: string | null, name?: string | null, size?: number | null, folder?: string | null, pages?: number | null, documentUsed?: boolean | null, createAt: any, updateAt: any }> };

export type GetAllFilesByClientQueryVariables = Types.Exact<{
  userId: Types.Scalars['String'];
}>;


export type GetAllFilesByClientQuery = { __typename?: 'Query', getAllFilesByClient: Array<{ __typename?: 'FileEntity', id: string, userId?: string | null, firmAccountId?: string | null, taxYearInterviewId?: string | null, s3Key?: string | null, name?: string | null, size?: number | null, folder?: string | null, pages?: number | null, documentUsed?: boolean | null, createAt: any, updateAt: any }> };

export type GetAllFileRequestsFromInterviewQueryVariables = Types.Exact<{
  taxYearInterviewId: Types.Scalars['String'];
}>;


export type GetAllFileRequestsFromInterviewQuery = { __typename?: 'Query', getAllFileRequestsFromInterview: Array<{ __typename?: 'TaskEntity', taskType?: Types.TaskType | null, completedAt?: any | null }> };

export type GetSignedFileByS3KeyQueryVariables = Types.Exact<{
  s3Key: Types.Scalars['String'];
}>;


export type GetSignedFileByS3KeyQuery = { __typename?: 'Query', SignedFileByS3Key: string };


export const GetClientOnboardingDataDocument = gql`
    query GetClientOnboardingData {
  ClientOnboardingData {
    ownerName
    email
    languageId
    UserInformation {
      id
      firstName
      lastName
      birthday
    }
    FirmAccount {
      id
      CustomLayout {
        id
        headerBackgroundColor
        headerFontColor
        logo
        pixelCode
        createAt
        updateAt
      }
    }
  }
}
    `;

/**
 * __useGetClientOnboardingDataQuery__
 *
 * To run a query within a React component, call `useGetClientOnboardingDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientOnboardingDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientOnboardingDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientOnboardingDataQuery(baseOptions?: Apollo.QueryHookOptions<GetClientOnboardingDataQuery, GetClientOnboardingDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientOnboardingDataQuery, GetClientOnboardingDataQueryVariables>(GetClientOnboardingDataDocument, options);
      }
export function useGetClientOnboardingDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientOnboardingDataQuery, GetClientOnboardingDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientOnboardingDataQuery, GetClientOnboardingDataQueryVariables>(GetClientOnboardingDataDocument, options);
        }
export type GetClientOnboardingDataQueryHookResult = ReturnType<typeof useGetClientOnboardingDataQuery>;
export type GetClientOnboardingDataLazyQueryHookResult = ReturnType<typeof useGetClientOnboardingDataLazyQuery>;
export type GetClientOnboardingDataQueryResult = Apollo.QueryResult<GetClientOnboardingDataQuery, GetClientOnboardingDataQueryVariables>;
export const GetLanguagesQueryDocument = gql`
    query GetLanguagesQuery {
  languages {
    id
    lang
    description
  }
}
    `;

/**
 * __useGetLanguagesQueryQuery__
 *
 * To run a query within a React component, call `useGetLanguagesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguagesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguagesQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLanguagesQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetLanguagesQueryQuery, GetLanguagesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLanguagesQueryQuery, GetLanguagesQueryQueryVariables>(GetLanguagesQueryDocument, options);
      }
export function useGetLanguagesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLanguagesQueryQuery, GetLanguagesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLanguagesQueryQuery, GetLanguagesQueryQueryVariables>(GetLanguagesQueryDocument, options);
        }
export type GetLanguagesQueryQueryHookResult = ReturnType<typeof useGetLanguagesQueryQuery>;
export type GetLanguagesQueryLazyQueryHookResult = ReturnType<typeof useGetLanguagesQueryLazyQuery>;
export type GetLanguagesQueryQueryResult = Apollo.QueryResult<GetLanguagesQueryQuery, GetLanguagesQueryQueryVariables>;
export const GetTaxYearInterviewDocument = gql`
    query GetTaxYearInterview($id: String!) {
  taxYearInterview(id: $id) {
    id
    progressStatusId
    notes
    assignedAt
    completedAt
    preparerAcceptedAt
    reviewStatus
    createAt
    updateAt
    deletedAt
    Interviews {
      id
      name
      isTemplate
      isLive
      createAt
      updateAt
    }
    TaxYear {
      id
      year
    }
  }
}
    `;

/**
 * __useGetTaxYearInterviewQuery__
 *
 * To run a query within a React component, call `useGetTaxYearInterviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxYearInterviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxYearInterviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaxYearInterviewQuery(baseOptions: Apollo.QueryHookOptions<GetTaxYearInterviewQuery, GetTaxYearInterviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaxYearInterviewQuery, GetTaxYearInterviewQueryVariables>(GetTaxYearInterviewDocument, options);
      }
export function useGetTaxYearInterviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaxYearInterviewQuery, GetTaxYearInterviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaxYearInterviewQuery, GetTaxYearInterviewQueryVariables>(GetTaxYearInterviewDocument, options);
        }
export type GetTaxYearInterviewQueryHookResult = ReturnType<typeof useGetTaxYearInterviewQuery>;
export type GetTaxYearInterviewLazyQueryHookResult = ReturnType<typeof useGetTaxYearInterviewLazyQuery>;
export type GetTaxYearInterviewQueryResult = Apollo.QueryResult<GetTaxYearInterviewQuery, GetTaxYearInterviewQueryVariables>;
export const GetClientFilesFromDbDocument = gql`
    query GetClientFilesFromDb($userId: String!, $taxYearInterviewId: String!) {
  getClientFilesFromDb(userId: $userId, taxYearInterviewId: $taxYearInterviewId) {
    id
    userId
    firmAccountId
    taxYearInterviewId
    s3Key
    name
    size
    folder
    pages
    documentUsed
    documentReviewed
    createAt
    updateAt
  }
  ClientRequestedFiles(taxYearInterviewId: $taxYearInterviewId) {
    id
    taxYearInterviewId
    fileName
    notes
    createAt
  }
}
    `;

/**
 * __useGetClientFilesFromDbQuery__
 *
 * To run a query within a React component, call `useGetClientFilesFromDbQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientFilesFromDbQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientFilesFromDbQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      taxYearInterviewId: // value for 'taxYearInterviewId'
 *   },
 * });
 */
export function useGetClientFilesFromDbQuery(baseOptions: Apollo.QueryHookOptions<GetClientFilesFromDbQuery, GetClientFilesFromDbQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientFilesFromDbQuery, GetClientFilesFromDbQueryVariables>(GetClientFilesFromDbDocument, options);
      }
export function useGetClientFilesFromDbLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientFilesFromDbQuery, GetClientFilesFromDbQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientFilesFromDbQuery, GetClientFilesFromDbQueryVariables>(GetClientFilesFromDbDocument, options);
        }
export type GetClientFilesFromDbQueryHookResult = ReturnType<typeof useGetClientFilesFromDbQuery>;
export type GetClientFilesFromDbLazyQueryHookResult = ReturnType<typeof useGetClientFilesFromDbLazyQuery>;
export type GetClientFilesFromDbQueryResult = Apollo.QueryResult<GetClientFilesFromDbQuery, GetClientFilesFromDbQueryVariables>;
export const GetFirmFilesFromDbDocument = gql`
    query GetFirmFilesFromDb($taxYearInterviewId: String!) {
  getFirmFilesFromDb(taxYearInterviewId: $taxYearInterviewId) {
    id
    userId
    firmAccountId
    taxYearInterviewId
    s3Key
    name
    size
    folder
    pages
    documentUsed
    createAt
    updateAt
  }
}
    `;

/**
 * __useGetFirmFilesFromDbQuery__
 *
 * To run a query within a React component, call `useGetFirmFilesFromDbQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirmFilesFromDbQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirmFilesFromDbQuery({
 *   variables: {
 *      taxYearInterviewId: // value for 'taxYearInterviewId'
 *   },
 * });
 */
export function useGetFirmFilesFromDbQuery(baseOptions: Apollo.QueryHookOptions<GetFirmFilesFromDbQuery, GetFirmFilesFromDbQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFirmFilesFromDbQuery, GetFirmFilesFromDbQueryVariables>(GetFirmFilesFromDbDocument, options);
      }
export function useGetFirmFilesFromDbLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFirmFilesFromDbQuery, GetFirmFilesFromDbQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFirmFilesFromDbQuery, GetFirmFilesFromDbQueryVariables>(GetFirmFilesFromDbDocument, options);
        }
export type GetFirmFilesFromDbQueryHookResult = ReturnType<typeof useGetFirmFilesFromDbQuery>;
export type GetFirmFilesFromDbLazyQueryHookResult = ReturnType<typeof useGetFirmFilesFromDbLazyQuery>;
export type GetFirmFilesFromDbQueryResult = Apollo.QueryResult<GetFirmFilesFromDbQuery, GetFirmFilesFromDbQueryVariables>;
export const GetAllFilesByClientDocument = gql`
    query GetAllFilesByClient($userId: String!) {
  getAllFilesByClient(userId: $userId) {
    id
    userId
    firmAccountId
    taxYearInterviewId
    s3Key
    name
    size
    folder
    pages
    documentUsed
    createAt
    updateAt
  }
}
    `;

/**
 * __useGetAllFilesByClientQuery__
 *
 * To run a query within a React component, call `useGetAllFilesByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFilesByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFilesByClientQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAllFilesByClientQuery(baseOptions: Apollo.QueryHookOptions<GetAllFilesByClientQuery, GetAllFilesByClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllFilesByClientQuery, GetAllFilesByClientQueryVariables>(GetAllFilesByClientDocument, options);
      }
export function useGetAllFilesByClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllFilesByClientQuery, GetAllFilesByClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllFilesByClientQuery, GetAllFilesByClientQueryVariables>(GetAllFilesByClientDocument, options);
        }
export type GetAllFilesByClientQueryHookResult = ReturnType<typeof useGetAllFilesByClientQuery>;
export type GetAllFilesByClientLazyQueryHookResult = ReturnType<typeof useGetAllFilesByClientLazyQuery>;
export type GetAllFilesByClientQueryResult = Apollo.QueryResult<GetAllFilesByClientQuery, GetAllFilesByClientQueryVariables>;
export const GetAllFileRequestsFromInterviewDocument = gql`
    query GetAllFileRequestsFromInterview($taxYearInterviewId: String!) {
  getAllFileRequestsFromInterview(taxYearInterviewId: $taxYearInterviewId) {
    taskType
    completedAt
  }
}
    `;

/**
 * __useGetAllFileRequestsFromInterviewQuery__
 *
 * To run a query within a React component, call `useGetAllFileRequestsFromInterviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFileRequestsFromInterviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFileRequestsFromInterviewQuery({
 *   variables: {
 *      taxYearInterviewId: // value for 'taxYearInterviewId'
 *   },
 * });
 */
export function useGetAllFileRequestsFromInterviewQuery(baseOptions: Apollo.QueryHookOptions<GetAllFileRequestsFromInterviewQuery, GetAllFileRequestsFromInterviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllFileRequestsFromInterviewQuery, GetAllFileRequestsFromInterviewQueryVariables>(GetAllFileRequestsFromInterviewDocument, options);
      }
export function useGetAllFileRequestsFromInterviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllFileRequestsFromInterviewQuery, GetAllFileRequestsFromInterviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllFileRequestsFromInterviewQuery, GetAllFileRequestsFromInterviewQueryVariables>(GetAllFileRequestsFromInterviewDocument, options);
        }
export type GetAllFileRequestsFromInterviewQueryHookResult = ReturnType<typeof useGetAllFileRequestsFromInterviewQuery>;
export type GetAllFileRequestsFromInterviewLazyQueryHookResult = ReturnType<typeof useGetAllFileRequestsFromInterviewLazyQuery>;
export type GetAllFileRequestsFromInterviewQueryResult = Apollo.QueryResult<GetAllFileRequestsFromInterviewQuery, GetAllFileRequestsFromInterviewQueryVariables>;
export const GetSignedFileByS3KeyDocument = gql`
    query GetSignedFileByS3Key($s3Key: String!) {
  SignedFileByS3Key(s3Key: $s3Key)
}
    `;

/**
 * __useGetSignedFileByS3KeyQuery__
 *
 * To run a query within a React component, call `useGetSignedFileByS3KeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignedFileByS3KeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignedFileByS3KeyQuery({
 *   variables: {
 *      s3Key: // value for 's3Key'
 *   },
 * });
 */
export function useGetSignedFileByS3KeyQuery(baseOptions: Apollo.QueryHookOptions<GetSignedFileByS3KeyQuery, GetSignedFileByS3KeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSignedFileByS3KeyQuery, GetSignedFileByS3KeyQueryVariables>(GetSignedFileByS3KeyDocument, options);
      }
export function useGetSignedFileByS3KeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignedFileByS3KeyQuery, GetSignedFileByS3KeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSignedFileByS3KeyQuery, GetSignedFileByS3KeyQueryVariables>(GetSignedFileByS3KeyDocument, options);
        }
export type GetSignedFileByS3KeyQueryHookResult = ReturnType<typeof useGetSignedFileByS3KeyQuery>;
export type GetSignedFileByS3KeyLazyQueryHookResult = ReturnType<typeof useGetSignedFileByS3KeyLazyQuery>;
export type GetSignedFileByS3KeyQueryResult = Apollo.QueryResult<GetSignedFileByS3KeyQuery, GetSignedFileByS3KeyQueryVariables>;
export const namedOperations = {
  Query: {
    GetClientOnboardingData: 'GetClientOnboardingData',
    GetLanguagesQuery: 'GetLanguagesQuery',
    GetTaxYearInterview: 'GetTaxYearInterview',
    GetClientFilesFromDb: 'GetClientFilesFromDb',
    GetFirmFilesFromDb: 'GetFirmFilesFromDb',
    GetAllFilesByClient: 'GetAllFilesByClient',
    GetAllFileRequestsFromInterview: 'GetAllFileRequestsFromInterview',
    GetSignedFileByS3Key: 'GetSignedFileByS3Key'
  }
}