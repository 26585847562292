import {
  Form,
  Card,
  Input,
  Button,
  Row,
  Grid,
  Typography,
  message,
} from 'taxaroo-ui';
import { Link } from 'react-router-dom';
import Logo from '~src/assets/images/taxaroo_logo.svg';
import { useCreatePasswordResetByEmailMutation } from '~src/graphql/mutations/passwordReset';
import { getClientWidth, isMobileView } from '~src/components/helpers/screen';
import * as styles from './style.module.css';

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

function ForgotPassword() {
  const screens = useBreakpoint();

  // graphql
  // mutation to create password reset
  const [createPasswordReset, { loading: loadingCreatePasswordReset }] = useCreatePasswordResetByEmailMutation({
    onError: (err) => {
      if (err.message === 'Error code 1001') {
        message.error('This email is not registered in the system. Please try another email.');
      } else {
        message.error(err.message);
      }
    },
    onCompleted: () => {
      message.success('Password reset link sent.');
    },
  });

  const onFinish = async (values: any) => {
    console.log('values: ', values);
    await createPasswordReset({
      variables: {
        email: values.email,
      },
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
    message.error('Something went wrong.');
  };

  const cardWidth = isMobileView() ? getClientWidth() * 0.95 : undefined;

  return (
    <div className={styles['forgot-password-page']}>
      <div className={styles['forgot-password-wrapper']}>
        <Card
          style={{
            padding: screens.md ? '10px 80px' : '5px',
            maxWidth: 900,
            width: isMobileView() ? '100%' : cardWidth,
          }}
        >
          <Row justify="center" style={{ margin: '0 0 60px 0' }}>
            <Logo width="170" height="40" />
          </Row>
          <Row justify="center">
            <Form
              name="Forgot Password"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              style={{ width: isMobileView() ? '100%' : undefined }}
            >
              <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <Title level={3}>Forgot Your Password?</Title>
                <Text>
                  Enter your email address below and we&apos;ll send you a secure reset link.
                </Text>
              </div>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingCreatePasswordReset}
                  style={{ width: '100%', justifyContent: 'center' }}
                  size="large"
                >
                  Request Password Reset
                </Button>
              </Form.Item>
              <div style={{ textAlign: 'center' }}>
                <Link to="/login">Back to login</Link>
                <p>
                  New to Taxaroo?
                  {' '}
                  <Link to="/register">Create Account</Link>
                </p>
              </div>
            </Form>
          </Row>
        </Card>
      </div>
    </div>
  );
}

export default ForgotPassword;
