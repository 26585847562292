import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPageView } from '../helpers/customer.io';

interface PageViewProviderProps {
  children: Array<React.ReactNode> | React.ReactNode;
}

export const PageViewProvider: FC<PageViewProviderProps> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    trackPageView();
  }, [location.pathname]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {children}
    </>
  );
};
