/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Modal,
  Table,
  Spin,
  Icons,
  Layout,
  Space,
  Grid,
  message,
  Typography,
} from 'taxaroo-ui';
import { SortableElement, arrayMove } from 'react-sortable-hoc';
import {
  GetProgressStatusesDocument,
  useGetProgressStatusesQuery,
} from '~src/graphql/queries/settings';
import {
  useCreateProgressStatusesMutation,
  useRemoveProgressStatusesMutation,
  useSwitchOrderOfProgressStatusesMutation,
  useUpdateProgressStatusMutation,
} from '~src/graphql/mutations/settings';
import SettingsHeader from '~src/components/organisms/Settings/SettingsHeader';
import { TaxYearStatus } from '~src/graphql';
import { Message } from '../../helpers/forms';
import { handleOnError } from '../InviteLink/utils';
import {
  onSuccessCreateProgress,
  onSuccessDeleteProgress,
  onSuccessSortedProgress,
} from './utils';
import DragHandle from './DragHandle';
import ActionButton from '../../molecules/ActionButton';
import DraggableContainer from './DraggableContainer';
import * as styles from './style.module.css';
import './index.css';

const { EditOutlined, DeleteOutlined } = Icons;
const { Paragraph } = Typography;
const { useBreakpoint } = Grid;
const SortableItem = SortableElement((props: any) => <tr {...props} />);

// only new statuses are sortable/deletable, default statuses are not
const disableByObj = (obj: any) => (
  obj && [
    TaxYearStatus.Completed,
    TaxYearStatus.Unassigned,
    TaxYearStatus.InProgress,
  ].includes(obj.underlyingStatus)
);

const ProgressStatuses = () => {
  const screens = useBreakpoint();
  const [statuses, setStatuses] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [status, setStatus] = useState('');
  const [updateStatusId, setUpdateStatusId] = useState('');
  const [loading, setLoading] = useState(false);

  // Get progress status
  const { data: progressStatusData, loading: loadingStatusData } = useGetProgressStatusesQuery();

  useEffect(() => {
    if (progressStatusData && progressStatusData.ProgressStatuses) {
      const sorted = Object.assign([], progressStatusData.ProgressStatuses);
      setStatuses(sorted.sort((a, b) => a.index - b.index));
    }
  }, [progressStatusData]);

  // Delete progress status
  const [deleteProgressStatus, { loading: loadingDelete }] = useRemoveProgressStatusesMutation({
    onCompleted: onSuccessDeleteProgress,
    onError: (error) => {
      const errorMessage = error.graphQLErrors?.at(0)?.message;
      if (errorMessage && errorMessage.indexOf('is used') > -1) {
        message.error('You cannot delete a status while there are jobs assigned to it. Please remove all jobs from this status and you can delete it.');
      } else {
        handleOnError();
      }
    },
    refetchQueries: [{ query: GetProgressStatusesDocument }],
    awaitRefetchQueries: true,
  });

  const handleRemoveProgress = async (id: string) => {
    await deleteProgressStatus({
      variables: {
        id,
      },
    });
  };

  // Update progress status
  const [updateProgressStatus, { loading: loadingUpdate }] = useUpdateProgressStatusMutation({
    onError: handleOnError,
    refetchQueries: [
      {
        query: GetProgressStatusesDocument,
      },
    ],
    awaitRefetchQueries: true,
  });

  const [switchOrderOfProgressStatus, { loading: loadingSwitchOrder }] = useSwitchOrderOfProgressStatusesMutation({
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: onSuccessSortedProgress,
    refetchQueries: [
      {
        query: GetProgressStatusesDocument,
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleEditProgress = async () => {
    await updateProgressStatus({
      variables: {
        updateProgressStatusInput: {
          id: updateStatusId,
          status,
        },
      },
    });
  };

  // Create progress status
  const [createProgressStatus, { loading: loadingCreate }] = useCreateProgressStatusesMutation({
    onCompleted: onSuccessCreateProgress,
    onError: handleOnError,
    refetchQueries: [{ query: GetProgressStatusesDocument }],
    awaitRefetchQueries: true,
  });

  const handleCreateProgress = () => {
    if (status !== '') {
      const cap = status.replace(/\b\w/g, (l) => l.toUpperCase());
      if (statuses.find((item) => item.status === cap) === undefined) {
        const localstatus = Object.assign([], statuses);
        if (cap === 'Completed') {
          const newstatus = { status: cap };
          localstatus.push(newstatus);
        } else if (cap === 'Unassigned') {
          localstatus.unshift({ status: cap });
        } else {
          localstatus.splice(statuses.length - 1, 0, { status: cap });
        }
        localstatus.forEach(async (item, i) => {
          const index = statuses[statuses.length - 2].index + 1;
          if (!item.id) {
            await createProgressStatus({
              variables: {
                createProgressStatusesInput: {
                  index,
                  status: cap,
                },
              },
            });
          }
        });
      } else {
        Message('Status Progress already created.', 'error');
      }
    }
  };

  // modal actions
  // set edit modal
  const setUpdate = (obj: any) => {
    setUpdateStatusId(obj.id);
    setStatus(obj.status);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setStatus('');
    setUpdateStatusId('');
  };

  const handleOk = () => {
    if (updateStatusId !== '') handleEditProgress();
    else handleCreateProgress();
    handleCancel();
  };

  // Draggable actions
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      if (newIndex !== 0 && newIndex !== statuses.length - 1) {
        setLoading(true);
        // const newitems = arrayMove(statuses, oldIndex, newIndex);
        const newData = arrayMove([].concat(statuses), oldIndex, newIndex).filter((el) => !!el);
        setStatuses(newData);
        switchOrderOfProgressStatus({
          variables: {
            switchOrderOfProgressStatusesInput: {
              idOne: statuses[newIndex].id,
              idTwo: statuses[oldIndex].id,
              indexOne: statuses[oldIndex].index,
              indexTwo: statuses[newIndex].index,
            },
          },
        });
      } else {
        Message('You cannot place custom status before "Unassigned" or after "Completed" status.', 'error');
        setLoading(false);
      }
    }
  };

  const DraggableBodyRow: any = ({ ...restProps }) => {
    const index = statuses.findIndex((x) => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} disabled={disableByObj(statuses[index])} />;
  };

  if (loadingStatusData) {
    return <Spin size="large" />;
  }

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <Space direction="vertical" style={{ width: '98%' }}>
        <SettingsHeader
          title="Workflow"
          tooltipTitle="How does this work?"
          tooltipContent={(
            <Paragraph>
              Add or remove progress status sections for your jobs in the dashboard.
            </Paragraph>
          )}
        />
        <Table
          loading={loadingCreate || loadingUpdate || loadingDelete || loadingSwitchOrder}
          rowKey={(record) => record.id}
          columns={[
            {
              title: 'Sort',
              dataIndex: '',
              key: 'sort',
              width: 100,
              render(obj: any) {
                return <DragHandle disabled={disableByObj(obj)} />;
              },
            },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
            },
            {
              title: 'Actions',
              dataIndex: '',
              key: 'id',
              align: 'center',
              width: 200,
              render: (obj: any) => {
                const disabled = disableByObj(obj);
                return (
                  <div className={styles.btnContainer}>
                    <ActionButton tooltip="Update Progress Status " onClick={() => setUpdate(obj)}>
                      <EditOutlined />
                    </ActionButton>
                    <ActionButton
                      tooltip={
                        disabled ? 'Default statuses cannot be deleted' : 'Delete Progress Status'
                      }
                      confirmAction
                      confirmLabel="Are you sure?"
                      onClick={() => handleRemoveProgress(obj.id)}
                      buttonProps={{ danger: true, disabled }}
                    >
                      <DeleteOutlined />
                    </ActionButton>
                  </div>
                );
              },
            },
          ]}
          dataSource={statuses}
          scroll={{ x: 'max-content' }}
          sticky
          bordered
          components={{
            body: {
              wrapper: (props: any) => <DraggableContainer onSortEnd={onSortEnd} {...props} />,
              row: DraggableBodyRow,
            },
          }}
        />
        <Button
          size="large"
          type="primary"
          onClick={() => setIsModalVisible(true)}
          disabled={loadingCreate || loadingUpdate || loadingDelete}
        >
          Add Progress Status
        </Button>
        <Modal
          title={updateStatusId ? 'Update Status' : 'Add Status'}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>Label:</p>
          <Input value={status} onChange={(e) => setStatus(e.target.value)} />
        </Modal>
      </Space>
    </Layout>
  );
};

export default ProgressStatuses;
