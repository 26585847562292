import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import clientReducer from './slices/clientSlice';
import sessionReducer from './slices/sessionSlice';
import interviewsReducer from './slices/interviewsSlice';
import chatReducer from './slices/chatSlice';
import registerReducer from './slices/registerSlice';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['interviews', 'chat', 'register'],
};

const reducers = combineReducers({
  chat: chatReducer,
  client: clientReducer,
  session: sessionReducer,
  register: registerReducer,
  interviews: interviewsReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;
