import { FC } from 'react';
import { Row, Col, Typography } from 'taxaroo-ui';

const { Text } = Typography;

interface InterviewQuestionProps {
  label: string;
  answer: string;
  key: string;
}

const InterviewQuestion: FC<InterviewQuestionProps> = ({ label, answer, key }) => (
  <Row
    gutter={[10, 10]}
    key={key}
    style={{ paddingBottom: '10px' }}
  >
    <Col lg={8} md={8} sm={8} xs={12}>
      <Text strong>
        {label}
        :
      </Text>
    </Col>
    <Col lg={16} md={16} sm={16} xs={12}>
      <Text>
        {answer}
      </Text>
    </Col>
  </Row>
);

export default InterviewQuestion;
