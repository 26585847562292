import React, { useState, useRef, FC } from 'react';
import {
  Radio, InputNumber, Button, Select, Icons, TaxarooModal, Tooltip,
} from 'taxaroo-ui';
import AvailabilityBrackets from '../AvailabilityBrackets';
import TimeAvailabilitySettingsModal from '../TimeAvailabilitySettingsModal';
import { times } from '../../const/eventTypes';
import { getOptions } from '../../helpers/forms';
import { getBrackets } from '../../helpers/time';
import * as styles from './style.module.css';
import './style.css';

const { confirm } = TaxarooModal;
const { ExclamationCircleOutlined } = Icons;

interface EventTypeSettingsMenuProps {
  settings: any
  updateSettings: any
  availability: Array<any>
  setAvailability: any
}

const EventTypeSettingsMenu: FC<EventTypeSettingsMenuProps> = ({
  settings, updateSettings, availability, setAvailability,
}) => {
  const [modal, setModal] = useState(false);
  const radioButtons = useRef<HTMLDivElement>(null);
  const {
    duration, afterBuffer, beforeBuffer, maxEvents, minNotice, color,
  } = settings;

  const addAvailabilities = (paramsBrackets: any) => {
    const { from, to, day } = paramsBrackets;
    let updateBrackets = null;
    const brackets = getBrackets(from, to, duration);
    const dayIndex = availability.findIndex((ava) => ava.day === day);
    const updateAvailability = [...availability];

    if (dayIndex !== -1) {
      const merge = [...updateAvailability[dayIndex].EventTypeAvailabilityBracket, ...brackets];
      updateBrackets = merge.filter(
        ({
          startHour, startMinute, endHour, endMinute,
        }, index) => merge.findIndex(
          (bracket) => bracket.startHour === startHour
              && bracket.startMinute === startMinute
              && bracket.endHour === endHour
              && bracket.endMinute === endMinute,
        ) === index,
      );
      updateAvailability.splice(dayIndex, 1);
    }

    updateAvailability.push({
      day,
      EventTypeAvailabilityBracket: updateBrackets ? [...updateBrackets] : brackets,
    });

    setAvailability([...updateAvailability]);
    setModal(false);
  };

  const deleteBracket = (day: any, bracket: any) => {
    const {
      startHour, startMinute, endHour, endMinute,
    } = bracket;
    const updateAvailability = [...availability];
    const dayIndex = updateAvailability.findIndex((ava) => ava.day === day);
    const brackets = [...updateAvailability[dayIndex].EventTypeAvailabilityBracket];
    const filterBrackets = brackets.filter(
      (bra) => !(
        bra.startHour === startHour
          && bra.startMinute === startMinute
          && bra.endHour === endHour
          && bra.endMinute === endMinute
      ),
    );
    updateAvailability.splice(dayIndex, 1);
    updateAvailability.push({ day, EventTypeAvailabilityBracket: filterBrackets });
    setAvailability([...updateAvailability]);
  };

  const showConfirm = ({ target: { name, value } }: Record<string, any>) => {
    const valueNum = value === '' ? 0 : parseInt(value, 10);
    if (availability.length > 0) {
      confirm({
        title: 'Changes on the Appointment Duration field may alter or delete time brackets',
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to change this Appointment Type duration?',
        okButtonProps: { className: styles.btnok },
        cancelButtonProps: { className: styles.btncancel },
        onOk() {
          setAvailability([]);
          updateSettings(name, valueNum);
        },
        onCancel() {
          setTimeout(() => {
            radioButtons?.current?.focus();
          }, 100);
        },
      });
    } else updateSettings(name, valueNum);
  };

  const customValue = () => {
    switch (duration) {
      case 15:
      case 30:
      case 45:
      case 60:
        return '';
      default:
        return duration;
    }
  };

  return (
    <div className={styles.container}>
      <TimeAvailabilitySettingsModal
        visible={modal}
        duration={duration}
        handleCancel={() => setModal(false)}
        addAvailabilities={addAvailabilities}
      />
      <p className={styles.title}>Appointment Duration*</p>
      <div className={styles.continputs}>
        <Radio.Group name="duration" value={duration} onChange={showConfirm} className="grid-struct">
          <Radio.Button value={15}>15 min</Radio.Button>
          <Radio.Button ref={radioButtons} value={30}>
            30 min
          </Radio.Button>
          <Radio.Button value={45}>45 min</Radio.Button>
          <Radio.Button value={60}>60 min</Radio.Button>
        </Radio.Group>
        <div className="custom-input ant-input-wrapper ant-input-group">
          <InputNumber
            min={1}
            max={160}
            name="duration"
            value={customValue()}
            placeholder="Custom"
            onClick={showConfirm}
            onChange={(value) => updateSettings('duration', !value || value === '' ? 0 : value)}
          />
          {customValue() !== '' && <span className="ant-input-group-addon">Min.</span>}
        </div>
      </div>
      <div className={styles.contTitleTime}>
        <p className={styles.title}>Time Availability*</p>
        <Tooltip
          title="Click on the button to add time slots that your clients can book appointments for."
        >
          <Button
            className={styles.btn}
            type="link"
            onClick={() => duration > 0 && setModal(true)}
          >
            Add New Time Availability
          </Button>
        </Tooltip>
      </div>
      <div id="container" className={styles.containerAvailabilities}>
        <AvailabilityBrackets
          availabilities={availability}
          deleteBracket={deleteBracket}
          color={color}
        />
      </div>

      <p className={styles.title}>Appointment Max per Day (Optional)</p>
      <p className={styles.desc}>
        Use this to limit the number of appointments that can be scheduled in a day.
      </p>
      <InputNumber
        className={styles.inputTop}
        min={1}
        max={160}
        value={maxEvents}
        onChange={(value) => updateSettings('maxEvents', value)}
        placeholder="Max number of Appointments"
      />

      <p className={styles.title}>Minimum Schedule Notice (Optional)</p>
      <p className={styles.desc}>Use this to prevent last minute appointments.</p>
      <InputNumber
        className={styles.inputTop}
        min={1}
        max={160}
        value={minNotice}
        onChange={(value) => updateSettings('minNotice', value)}
        placeholder="Minimum number of Hours Notice for New Appointments"
      />

      <p className={styles.title}>Appointment Buffers</p>
      <p className={styles.desc}>
        Use this to set aside preparation, rest or travel
        time before or after appointments. For example,
        if you define a 5 minute buffer before your
        appointments we will make sure you have 5 minutes of
        free time before your scheduled appointments.
      </p>
      <p className={styles.title}>Buffer Before Appointment (Optional)</p>
      <Select
        allowClear
        className={styles.input}
        placeholder="Time"
        value={beforeBuffer}
        onChange={(value) => updateSettings('beforeBuffer', value)}
      >
        {getOptions(times)}
      </Select>
      <p className={styles.title}>Buffer After Appointment (Optional)</p>
      <Select
        allowClear
        className={styles.input}
        placeholder="Time"
        value={afterBuffer}
        onChange={(value) => updateSettings('afterBuffer', value)}
      >
        {getOptions(times)}
      </Select>
    </div>
  );
};

export default EventTypeSettingsMenu;
