import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyEventTypesQueryVariables = Types.Exact<{
  timezone: Types.Scalars['String'];
}>;


export type MyEventTypesQuery = { __typename?: 'Query', MyEventTypes: Array<{ __typename?: 'EventTypeEntity', id: string, userId: string, name: string, description?: string | null, color: string, duration: number, maxEvents?: number | null, minNotice?: number | null, beforeBuffer?: number | null, afterBuffer?: number | null, timezone?: string | null, active: boolean, EventTypeAvailability?: Array<{ __typename?: 'EventTypeAvailabilityEntity', id: string, day: string, EventTypeAvailabilityBracket?: Array<{ __typename?: 'EventTypeAvailabilityBracketEntity', id: string, startHour: number, startMinute: number, endHour: number, endMinute: number }> | null }> | null }> };

export type MyEventsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyEventsQuery = { __typename?: 'Query', MyEvents: Array<{ __typename?: 'EventEntity', id: string, eventTypeId: string, organizerId: string, meetingId: string, status: Types.EventStatuses, startDate: any, endDate: any, title: string, notes?: string | null, type: Types.EventType, createAt: any, Meetings: { __typename?: 'MeetingEntity', id: string, channelId: string, MeetingAttendees: Array<{ __typename?: 'MeetingAttendeesEntity', id: string, userId?: string | null, unregisteredUserId?: string | null, firstName?: string | null, lastName?: string | null }> }, EventTypes: { __typename?: 'EventTypeEntity', id: string, userId: string, name: string, description?: string | null, color: string, duration: number, maxEvents?: number | null, minNotice?: number | null, beforeBuffer?: number | null, afterBuffer?: number | null, timezone?: string | null, active: boolean } }> };


export const MyEventTypesDocument = gql`
    query MyEventTypes($timezone: String!) {
  MyEventTypes(timezone: $timezone) {
    id
    userId
    name
    description
    color
    duration
    maxEvents
    minNotice
    beforeBuffer
    afterBuffer
    timezone
    active
    EventTypeAvailability {
      id
      day
      EventTypeAvailabilityBracket {
        id
        startHour
        startMinute
        endHour
        endMinute
      }
    }
  }
}
    `;

/**
 * __useMyEventTypesQuery__
 *
 * To run a query within a React component, call `useMyEventTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyEventTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyEventTypesQuery({
 *   variables: {
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useMyEventTypesQuery(baseOptions: Apollo.QueryHookOptions<MyEventTypesQuery, MyEventTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyEventTypesQuery, MyEventTypesQueryVariables>(MyEventTypesDocument, options);
      }
export function useMyEventTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyEventTypesQuery, MyEventTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyEventTypesQuery, MyEventTypesQueryVariables>(MyEventTypesDocument, options);
        }
export type MyEventTypesQueryHookResult = ReturnType<typeof useMyEventTypesQuery>;
export type MyEventTypesLazyQueryHookResult = ReturnType<typeof useMyEventTypesLazyQuery>;
export type MyEventTypesQueryResult = Apollo.QueryResult<MyEventTypesQuery, MyEventTypesQueryVariables>;
export const MyEventsDocument = gql`
    query MyEvents {
  MyEvents {
    id
    eventTypeId
    organizerId
    meetingId
    status
    startDate
    endDate
    title
    notes
    type
    createAt
    Meetings {
      id
      channelId
      MeetingAttendees {
        id
        userId
        unregisteredUserId
        firstName
        lastName
      }
    }
    EventTypes {
      id
      userId
      name
      description
      color
      duration
      maxEvents
      minNotice
      beforeBuffer
      afterBuffer
      timezone
      active
    }
  }
}
    `;

/**
 * __useMyEventsQuery__
 *
 * To run a query within a React component, call `useMyEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyEventsQuery(baseOptions?: Apollo.QueryHookOptions<MyEventsQuery, MyEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyEventsQuery, MyEventsQueryVariables>(MyEventsDocument, options);
      }
export function useMyEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyEventsQuery, MyEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyEventsQuery, MyEventsQueryVariables>(MyEventsDocument, options);
        }
export type MyEventsQueryHookResult = ReturnType<typeof useMyEventsQuery>;
export type MyEventsLazyQueryHookResult = ReturnType<typeof useMyEventsLazyQuery>;
export type MyEventsQueryResult = Apollo.QueryResult<MyEventsQuery, MyEventsQueryVariables>;
export const namedOperations = {
  Query: {
    MyEventTypes: 'MyEventTypes',
    MyEvents: 'MyEvents'
  }
}