import { useState } from 'react';
import axios from 'axios';
import { Icons } from 'taxaroo-ui';
import ActionButton from '~src/components/molecules/ActionButton';
import { useDownloadAllFilesFromS3Mutation } from '~src/graphql/mutations/clients';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { handleOnErrorDownloadAllFiles } from './utils';

const { DownloadOutlined } = Icons;

interface DownloadAllFilesButtonProps {
  files: Array<EachFileProps>,
  saveZipAs: string,
  children: React.ReactNode,
}

interface EachFileProps {
  s3Key: string;
  name: string
}

const DownloadAllFilesButton = ({ files, saveZipAs, children }: DownloadAllFilesButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [downloadAllFiles] = useDownloadAllFilesFromS3Mutation({
    onError: handleOnErrorDownloadAllFiles,
  });

  const handleDownloadAllFiles = async () => {
    setIsLoading(true);
    const allS3Keys = files.map((file) => file.s3Key);

    const download = await downloadAllFiles({
      variables: {
        s3Keys: allS3Keys,
      },
    });

    const zip = new JSZip();
    const zipFilename = `${saveZipAs}.zip`;

    const downloadPromises = download.data.downloadAllFiles.signedUrlArray.map((signedUrl) => axios.get(signedUrl.url, {
      responseType: 'blob',
    }).then((response) => {
      zip.file(signedUrl.name, response.data);
    }));

    // Wait for all files to be added to the zip
    await Promise.all(downloadPromises);

    // Generate zip file and trigger download
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, zipFilename);
      setIsLoading(false); // End loading
    }).catch(() => {
      setIsLoading(false); // Ensure loading is false in case of error
    });
  };

  return (
    <ActionButton
      tooltip="Download All Files selected in the table."
      confirmAction
      confirmLabel="Download files?"
      onClick={() => handleDownloadAllFiles()}
      buttonProps={{
        disabled: files.length === 0,
        title: files.length === 0 ? 'Select the files you would like to download from the table.' : '',
        loading: isLoading,
      }}
    >
      <DownloadOutlined />
      {children}
    </ActionButton>
  );
};

export default DownloadAllFilesButton;
