import React, { useState } from 'react';
import Chat from '~src/components/organisms/ChatAdmin';
import ChatsList from '~src/components/organisms/ChatsList';
import UserSidebar from '~src/components/organisms/UserSidebar';
import { useAppSelector } from '~src/redux/hooks';
import { selectCurrentChannel } from '~src/redux/slices/chatSlice';
import EmptyState from './EmptyState';
import * as styles from './index.module.css';

const ChatTemplate = () => {
  const currentSelectedChannel = useAppSelector(selectCurrentChannel);
  const [newConversationModalVisible, setNewConversationModalVisible] = useState(false);

  const handleNewConversationModalHide = () => {
    setNewConversationModalVisible(false);
  };

  const handleNewConversationModalShow = () => {
    setNewConversationModalVisible(true);
  };

  return (
    <div className={styles.chatContainer}>
      <ChatsList
        showModal={handleNewConversationModalShow}
        hideModal={handleNewConversationModalHide}
        modalVisible={newConversationModalVisible}
      />
      {currentSelectedChannel
        ? (
          <Chat
            channelInfo={currentSelectedChannel}
          />
        )
        : (
          <EmptyState showModal={handleNewConversationModalShow} />
        )}
      <UserSidebar
        channelInfo={currentSelectedChannel}
      />
    </div>
  );
};

export default ChatTemplate;
