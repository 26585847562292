import { useState } from 'react';
import { Layout } from 'taxaroo-ui';
import { isMobileView } from '~src/components/helpers/screen';
import Menu from './Menu';

const { Sider } = Layout;

function LeftSideMenu() {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Sider
      className="site-layout-background"
      width={175}
      breakpoint="md"
      onBreakpoint={(broken) => {
        if (broken) {
          if (!collapsed) {
            setCollapsed(!collapsed);
          }
        }
      }}
      collapsed={collapsed}
      collapsible
      onCollapse={() => setCollapsed(!collapsed)}
    >
      <Menu
        onClick={
          isMobileView()
            ? () => setCollapsed(true)
            : undefined
        }
      />
    </Sider>
  );
}

export default LeftSideMenu;
