import {
  ReactNode,
  useState,
  createContext,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import { trackPlanType } from '~src/components/helpers/customer.io';
import { useGetPlanAccessLevelQuery } from '~src/graphql/queries/stripeInformation';
import { useAppSelector } from '~src/redux/hooks';
import { selectSession } from '~src/redux/slices/sessionSlice';

// enum for all the features that can be upgraded

export enum Features {
  signatures = 'signatures',
  approvals = 'approvals',
  followUps = 'followUps',
  aiMessaging = 'aiMessaging',
  mappedDrive = 'mappedDrive',
  entityInterviews = 'entityInterviews',
  reviews = 'reviews',
  appointments = 'appointments',
  aiResearch = 'aiResearch',
  export = 'export',
  videoCalls = 'videoCalls',
  birthdayEmails = 'birthdayEmails',
  pixels = 'pixels',
  teamMembers = 'teamMembers',
}

export const liteFeatures = [
  Features.appointments,
  Features.pixels,
  Features.birthdayEmails,
];

interface ModalContextProps {
  isModalVisible: boolean;
  showModal: (newFeature: Features, closable?: boolean) => void;
  hideModal: () => void;
  accessObject: Record<Features, boolean> | null;
  feature: Features | null;
  closable: boolean;
}

const ModalContext = createContext({
  isModalVisible: false,
  showModal: (newFeature: Features, closable?: boolean) => null,
  hideModal: () => null,
  accessObject: {},
  feature: null,
  closable: true,
} as ModalContextProps);

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const { accessToken } = useAppSelector(selectSession);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [feature, setFeature] = useState(null);
  const [closable, setClosable] = useState(true);
  const [accessObject, setAccessObject] = useState(null);

  const { data: planAccessData, loading: loadingPlanType } = useGetPlanAccessLevelQuery({
    skip: !accessToken,
  });
  const currentPlanAccess = planAccessData?.getPlanAccessLevel?.planType;

  const getAccess = (theFeature) => {
    if (currentPlanAccess === 'FREE') { // If on free plan or paused they are on freemium
      return false;
    }
    if (currentPlanAccess === 'LITE') { // If on lite plan they have access to all lite features
      return liteFeatures.includes(theFeature);
    }
    return true; // must be on pro plan
  };

  useEffect(() => {
    if (!loadingPlanType && currentPlanAccess) {
      const accessResults = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const f of Object.values(Features)) {
        accessResults[f] = getAccess(f);
      }
      console.log('accessResults', accessResults);
      setAccessObject(accessResults);

      trackPlanType(currentPlanAccess);
    }
  }, [currentPlanAccess, feature, loadingPlanType]);

  const showModal = (newFeature = null, isClosable = true) => {
    setFeature(newFeature);
    setClosable(isClosable);
    setIsModalVisible(true);
  };
  const hideModal = () => {
    setIsModalVisible(false);
    setFeature(null); // Reset feature when closing the modal
  };

  // Memoize the context value
  const contextValue = useMemo(() => ({
    isModalVisible,
    feature,
    closable,
    showModal,
    hideModal,
    accessObject,
  }), [isModalVisible, feature, closable, currentPlanAccess, accessObject]);

  console.log('accessObject', accessObject);

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

export const useUpgradeModal = () => useContext(ModalContext);
