import { useState } from 'react';
import { Button, Icons, Space } from 'taxaroo-ui';
import AddClientModal from '../../AddClientModal';
import ImportClientsModal from '../../ImportClientsModal';
import * as styles from './style.module.css';
import Empty from './empty.png';

const { UserAddOutlined, CloudUploadOutlined } = Icons;

function EmptyState() {
  const [isAddClientModalVisible, setIsAddClientModalVisible] = useState(false);
  const [isImportClientsModalVisible, setIsImportClientsModalVisible] = useState(false);

  return (
    <div className={styles.noClientsContainer}>
      <img alt="Add Client" src={Empty} />
      <p>You don&apos;t have any clients in Taxaroo yet</p>

      <Space>
        <Button
          icon={<UserAddOutlined />}
          onClick={() => {
            setIsAddClientModalVisible(true);
          }}
        >
          Add Client
        </Button>

        <Button
          icon={<CloudUploadOutlined />}
          onClick={() => {
            setIsImportClientsModalVisible(true);
          }}
        >
          Import Clients
        </Button>
      </Space>

      <AddClientModal
        isModalVisible={isAddClientModalVisible}
        setIsModalVisible={setIsAddClientModalVisible}
      />
      <ImportClientsModal
        isModalVisible={isImportClientsModalVisible}
        setIsModalVisible={setIsImportClientsModalVisible}
      />
    </div>
  );
}

export default EmptyState;
