import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertJobTrackingByTaxYearInterviewIdMutationVariables = Types.Exact<{
  createJobTrackingInput: Types.CreateJobTrackingInput;
}>;


export type UpsertJobTrackingByTaxYearInterviewIdMutation = { __typename?: 'Mutation', upsertJobTrackingByTaxYearInterviewId: { __typename?: 'JobTrackingEntity', id: string, taxYearInterviewId: string, expectedCompletion?: any | null, paymentsCompletedAt?: any | null, approvalsCompletedAt?: any | null, interviewCompletedAt?: any | null, filesCompletedAt?: any | null } };


export const UpsertJobTrackingByTaxYearInterviewIdDocument = gql`
    mutation upsertJobTrackingByTaxYearInterviewId($createJobTrackingInput: CreateJobTrackingInput!) {
  upsertJobTrackingByTaxYearInterviewId(
    createJobTrackingInput: $createJobTrackingInput
  ) {
    id
    taxYearInterviewId
    expectedCompletion
    paymentsCompletedAt
    approvalsCompletedAt
    interviewCompletedAt
    filesCompletedAt
  }
}
    `;
export type UpsertJobTrackingByTaxYearInterviewIdMutationFn = Apollo.MutationFunction<UpsertJobTrackingByTaxYearInterviewIdMutation, UpsertJobTrackingByTaxYearInterviewIdMutationVariables>;

/**
 * __useUpsertJobTrackingByTaxYearInterviewIdMutation__
 *
 * To run a mutation, you first call `useUpsertJobTrackingByTaxYearInterviewIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertJobTrackingByTaxYearInterviewIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertJobTrackingByTaxYearInterviewIdMutation, { data, loading, error }] = useUpsertJobTrackingByTaxYearInterviewIdMutation({
 *   variables: {
 *      createJobTrackingInput: // value for 'createJobTrackingInput'
 *   },
 * });
 */
export function useUpsertJobTrackingByTaxYearInterviewIdMutation(baseOptions?: Apollo.MutationHookOptions<UpsertJobTrackingByTaxYearInterviewIdMutation, UpsertJobTrackingByTaxYearInterviewIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertJobTrackingByTaxYearInterviewIdMutation, UpsertJobTrackingByTaxYearInterviewIdMutationVariables>(UpsertJobTrackingByTaxYearInterviewIdDocument, options);
      }
export type UpsertJobTrackingByTaxYearInterviewIdMutationHookResult = ReturnType<typeof useUpsertJobTrackingByTaxYearInterviewIdMutation>;
export type UpsertJobTrackingByTaxYearInterviewIdMutationResult = Apollo.MutationResult<UpsertJobTrackingByTaxYearInterviewIdMutation>;
export type UpsertJobTrackingByTaxYearInterviewIdMutationOptions = Apollo.BaseMutationOptions<UpsertJobTrackingByTaxYearInterviewIdMutation, UpsertJobTrackingByTaxYearInterviewIdMutationVariables>;
export const namedOperations = {
  Mutation: {
    upsertJobTrackingByTaxYearInterviewId: 'upsertJobTrackingByTaxYearInterviewId'
  }
}