import React, { useEffect, HTMLAttributes } from 'react';
import lottie from 'lottie-web/build/player/lottie_light';
import spinLoaderJSON from '../../../assets/animations/loader.json';
import taxarooLoaderJSON from '../../../assets/animations/taxaroo_logo_final.json';
import taxarooIconLoaderJSON from '../../../assets/animations/taxaroo_logo_icon.json';

export interface LoadingIndicatorProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * LoadingIndicator type display state
   */
  size: 'medium' | 'large' | 'small' | number | string;
  id: string;
  type: 'spin' | 'icon' | 'full';
  speed?: number;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  size = 'medium',
  id,
  type = 'spin',
  speed = 1,
  ...rest
}) => {
  useEffect(() => {
    const Elem = document.querySelector(`#${id}`);
    let animation: any = spinLoaderJSON;
    if (type === 'icon') { animation = taxarooIconLoaderJSON; }
    if (type === 'full') { animation = taxarooLoaderJSON; }
    if (Elem) {
      lottie.loadAnimation({
        container: Elem,
        animationData: animation,
      });
    }
  }, [id, type]);

  useEffect(() => {
    const Elem = document.querySelector(`#${id}`);
    if (Elem) {
      lottie.setSpeed(speed);
    }
  }, [speed, id]);

  let widthHeight = size;
  if (size === 'small') { widthHeight = 50; }
  if (size === 'medium') { widthHeight = 100; }
  if (size === 'large') { widthHeight = 200; }

  return (
    <div
      id={id}
      style={{ width: widthHeight, height: widthHeight }}
      {...rest}
    />
  );
};

LoadingIndicator.defaultProps = {
  speed: 1,
};

export default LoadingIndicator;
