/* eslint-disable camelcase */
import { useCallback, useEffect, useState } from 'react';
import {
  Row, Col, Form, Input, Typography, Select,
} from 'taxaroo-ui';
import { RegisterStep, selectRegisterState, setPersonalAddress } from '~src/redux/slices/registerSlice';
import { useAppDispatch, useAppSelector } from '~src/redux/hooks';
import { isMobileView } from '../../../helpers/screen';
import CardNav from '../CardNav.tsx';
import USStates from '../../../helpers/USStatesList';

const { Title } = Typography;
const { Option } = Select;

interface PersonalAddressProps {
  setStep: (arg1: keyof typeof RegisterStep) => void;
  signUpFlow: boolean;
  onSave: () => void;
  loadingSave: boolean;
}
function PersonalAddress({
  setStep,
  signUpFlow,
  onSave,
  loadingSave,
}: PersonalAddressProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [disabledNext, setDisabledNext] = useState(true);
  const {
    personalAddress: {
      the_line1, the_line2, the_city, the_state, the_zip_code,
    },
  } = useAppSelector(selectRegisterState);

  const onSubmit = useCallback(
    (values) => {
      // update the redux store
      dispatch(setPersonalAddress({
        the_line1: values.the_line1,
        the_line2: values.the_line2,
        the_city: values.the_city,
        the_state: values.the_state,
        the_zip_code: values.the_zip_code,
      }));
      // move to next step
      if (signUpFlow) {
        setStep('SELECT_PLAN');
      } else {
        // save the changes
        onSave();
      }
    },
    [setStep, dispatch, setPersonalAddress, setStep],
  );

  // const handleCancel = useCallback(() => {
  //   form.resetFields();
  // }, [form]);

  const handleFormChange = (target) => {
    const [{ name: [name], value }] = target;

    const hasErrors = form.getFieldsError().some(({ errors }) => errors?.length);
    const currentLine1 = form.getFieldValue('the_line1');
    const currentCity = form.getFieldValue('the_city');
    const currentState = form.getFieldValue('the_state');
    const currentZipCode = form.getFieldValue('the_zip_code');
    const noValues = [
      currentLine1,
      currentCity,
      currentState,
      currentZipCode,
    ].every((fieldValue) => fieldValue?.length);
    setDisabledNext(hasErrors || !noValues);
  };

  useEffect(() => {
    if (the_line1?.length && the_city?.length && the_state?.length && the_zip_code?.length) {
      setDisabledNext(false);
    }
  }, []);

  const firstLine = (
    <>
      <Col md={12} sm={24}>
        <Form.Item
          name="the_line1"
          label="Address Line 1"
          rules={[{ required: true }]}
        >
          <Input placeholder="Address Line 1" autoComplete="address-line1" />
        </Form.Item>
      </Col>
      <Col md={12} sm={24}>
        <Form.Item
          name="the_line2"
          label="Address Line 2"
          rules={[{ required: false }]}
        >
          <Input placeholder="Address Line 2" autoComplete="address-line2" />
        </Form.Item>
      </Col>
    </>
  );

  const secondLine = (
    <>
      <Col md={8} sm={24}>
        <Form.Item name="the_city" label="City" rules={[{ required: true }]}>
          <Input placeholder="City" autoComplete="address-level2" />
        </Form.Item>
      </Col>
      <Col md={8} sm={24}>
        <Form.Item name="the_state" label="State" rules={[{ required: true }]}>
          <Select
            placeholder="Select state"
            showSearch
            filterOption={(input, option: any) => {
              if (option) {
                return (
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }
              return false;
            }}
            onChange={(value) => form.setFieldsValue({ state: value })}
            autoComplete="address-level1"
          >
            {USStates.map((item) => (
              <Option value={item.abbreviation}>{`${item.name}`}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col md={8} sm={24}>
        <Form.Item name="the_zip_code" label="Zip code" rules={[{ required: true }]}>
          <Input placeholder="Zip code" autoComplete="postal-code" />
        </Form.Item>
      </Col>
    </>
  );

  return (
    <Form
      style={{ width: '100%' }}
      layout="vertical"
      form={form}
      onFinish={onSubmit}
      onFieldsChange={handleFormChange}
      // eslint-disable-next-line no-template-curly-in-string
      validateMessages={{ required: '${label} is required' }}
      initialValues={{
        the_city,
        the_state,
        the_line1,
        the_line2,
        the_zip_code,
      }}
    >
      <Title level={4}>Personal Address</Title>
      {isMobileView() ? firstLine : (
        <Row gutter={[15, 15]}>
          {firstLine}
        </Row>
      )}
      {isMobileView() ? secondLine : (
        <Row gutter={[15, 15]}>
          {secondLine}
        </Row>
      )}
      <CardNav
        onBack={() => setStep('PERSONAL_INFO')}
        onSkip={() => setStep('SELECT_PLAN')}
        showSkip={false}
        disabled={disabledNext}
        nextText={signUpFlow ? 'Next' : 'Finish'}
        loading={loadingSave}
      />
    </Form>
  );
}

export default PersonalAddress;
