import React from 'react';
import { Input as AntdInput, InputProps } from 'antd';
import {
  GroupProps,
  PasswordProps,
  SearchProps,
  TextAreaProps,
} from 'antd/lib/input';

interface TaxInputProps extends InputProps {
  type?: 'default' | 'white';
}

const Input: React.FC<TaxInputProps> & {
  TextArea: React.FC<TextAreaProps>;
  Search: React.FC<SearchProps>;
  Group: React.FC<GroupProps>;
  Password: React.FC<PasswordProps>;
} = ({
  type = 'default',
  className = '',
  ...rest
}) => (
  <AntdInput
    className={type === 'white' ? `whiteInput ${className}` : className}
    {...rest}
  />
);

const Password: React.FC<PasswordProps> = ({
  type = 'default',
  className = '',
  ...rest
}) => (
  <AntdInput.Password
    className={type === 'white' ? `whiteInput ${className}` : className}
    {...rest}
  />
);

Input.TextArea = AntdInput.TextArea;
Input.Search = AntdInput.Search;
Input.Group = AntdInput.Group;
Input.Password = Password;

export default Input;
