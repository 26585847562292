.messages {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}

.message {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 11px;
  text-align: left;
}

.messageMine {
  align-items: flex-end;
}

.userInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.userInfoMine {
  justify-content: flex-end;
}

.fromName {
  color: #0a64b2;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  margin-left: 9px;
}

.nameMine {
  margin-left: 0;
  margin-right: 9px;
}

.textMine {
  margin-left: 0;
  margin-right: 9px;
}

.messageDate {
  margin: 0;
  margin-top: 5px;
  color: #969696;
  font-size: 10px;
  font-family: monospace;
}

.text {
  background-color: #f4f6f9;
  border-radius: 4px;
  width: fit-content;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
  padding: 10px 15px;
  box-sizing: border-box;
  margin-top: 10px;
  max-width: 70%;
  white-space: pre-line;
}

.textsms {
  background-color: rgb(253 242 226);
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
  padding: 10px 15px;
  box-sizing: border-box;
  margin-top: 10px;
  max-width: 70%;
  white-space: pre-line;
}

.containerNext {
  display: flex;
  flex-direction: column;
}

.loadingFile {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #ffffffbd;
}

.nameFile {
  display: block !important;
  width: fit-content;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
