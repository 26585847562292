import { useState } from 'react';
import {
  Menu, Dropdown, Button, Tooltip, DropDownProps,
} from 'taxaroo-ui';

type MyDropDownProps = Omit<DropDownProps, 'overlay'>
interface SelectionDropdownProps {
  onSelect: (arg1: any) => void;
  options: any[];
  keyText: string;
  buttonProps: any;
  dropDownProps?: MyDropDownProps;
  tooltipTitle?: string;
  children?: any;
}

const defaultProps = {
  dropDownProps: {},
  tooltipTitle: '',
  children: null,
};

function SelectionDropdown({
  onSelect,
  options,
  keyText,
  buttonProps,
  dropDownProps,
  tooltipTitle,
  children,
}: SelectionDropdownProps) {
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  if (!options.length) return null;

  const optionsMenu = () => (
    <Menu>
      {options.map((opt, i) => (
        <Menu.Item
          key={`${opt.key}-${new Date().getUTCDate() * Math.random()}`}
          icon={opt.icon}
          onClick={({ domEvent }) => {
            domEvent.stopPropagation();
            onSelect(opt);
            setDropdownVisible(false);
          }}
          danger={opt.danger}
        >
          {opt[keyText]}
        </Menu.Item>
      ))}
    </Menu>
  );

  const onTooltipVisibleChange = (visible: boolean) => {
    if (visible && !dropdownVisible) {
      setTooltipVisible(true);
    } else {
      setTooltipVisible(false);
    }
  };

  const onDropdownVisibleChange = (visible: boolean) => {
    setDropdownVisible(visible);
    if (visible) {
      setTooltipVisible(false);
    }
  };

  return (
    <Tooltip
      title={tooltipTitle}
      visible={tooltipTitle ? tooltipVisible : false}
      onVisibleChange={onTooltipVisibleChange}
      trigger={['hover']}
    >
      <Dropdown
        overlay={optionsMenu()}
        visible={dropdownVisible}
        onVisibleChange={onDropdownVisibleChange}
        trigger={['click']}
        placement="topLeft"
        {...dropDownProps}
      >
        <Button
          {...buttonProps}
        >
          {children}
        </Button>
      </Dropdown>
    </Tooltip>
  );
}

SelectionDropdown.defaultProps = defaultProps;

export default SelectionDropdown;
