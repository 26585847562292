import {
  Collapse, Button, Icons, Form, message, Row,
} from 'taxaroo-ui';
import {
  FC, useState,
} from 'react';
import moment, { Moment } from 'moment';
import {
  selectClient,
  NO_ANSWER_FOUND_STATE,
} from '~src/redux/slices/clientSlice';
import { AnswerEntity, SectionEntity, TranslationEntity } from '~src/graphql';
import { useCreateAnswerMutation, useUpdateAnswerMutation } from '~src/graphql/mutations/clients';
import { useAppSelector } from '~src/redux/hooks';
import { useUpdateUserInformationMutation } from '~src/graphql/mutations/settings';
import InterviewQuestion from '../questions/QuestionDetails';
import EditInterviewQuestion from '../questions/EditQuestion';

const { Panel } = Collapse;
const { EditOutlined } = Icons;

type InterviewSectionProps = Omit<SectionEntity, '__typename'> & {
  jobId: string;
  Answers: Array<AnswerEntity>;
  interviewName?: string;
  refetchInterviews: () => void;
  expandInterviewSections: boolean;
};

const InterviewSection: FC<InterviewSectionProps> = ({
  id,
  SubSections,
  Questions,
  jobId,
  Translations: sectionTranslations,
  Answers,
  interviewName,
  refetchInterviews,
  expandInterviewSections,
}) => {
  const [form] = Form.useForm();
  const [createAnswer] = useCreateAnswerMutation();
  const [updateAnswer] = useUpdateAnswerMutation();
  const [updateUserInformation] = useUpdateUserInformationMutation();
  const [isEditing, setIsEditing] = useState(false);
  const { languageId, userInformationId } = useAppSelector(selectClient);
  const [hasSubSections] = useState(Boolean(SubSections && SubSections.length));

  const translateQuestion = (translations: Array<TranslationEntity>) => translations.filter((item) => item.languageId === languageId)[0]?.description ?? '';

  const getInitialAnswerValues = () => Questions?.reduce((acc, {
    id: currentQuestionId, type,
  }) => {
    let answer: string | Moment = Answers.filter(({
      questionId,
    }) => questionId === currentQuestionId)[0]
      ?.description ?? '';

    if (type === 'DATE') {
      const date = moment(answer);
      answer = date.isValid() ? date : '';
    }

    const result = { ...acc, [currentQuestionId]: answer };

    return result;
  }, {});

  const saveAnswersHandler = (values: any, sectionId: string) => {
    const arrayValues = Object.keys(values).map((key) => ({ [key]: values[key] }));
    Promise.allSettled(
      arrayValues.map((item) => {
        const question = Questions.filter(
          ({ sectionId: questionSectionId }) => questionSectionId === sectionId,
        ).filter((
          { Translations },
        ) => Translations.filter(({ questionId }) => questionId === Object.keys(item)[0])[0]);

        const answer = Answers.filter(
          ({ questionId }) => questionId === question[0].id,
        )[0];

        // if updating birthday on individual tax return, also update the user's userInformation
        if (
          question[0]?.Translations?.filter(({ description }) => description === 'Taxpayer Date of Birth').length > 0 // if any of the translations are 'Taxpayer Date of Birth'
          && interviewName === 'Individual' // only if the interview is an individual tax return
        ) {
          updateUserInformation({
            variables: {
              updateUserInformationInput: {
                id: userInformationId,
                birthday: item[Object.keys(item)[0]],
              },
            },
            refetchQueries: [
              'GetAllEntityDataByEntityId', // Query name
            ],
          });
        }

        if (!answer?.id) {
          return createAnswer({
            variables: {
              createAnswerInput: {
                questionId: question[0].id,
                taxYearInterviewId: jobId,
                description: item[Object.keys(item)[0]],
              },
            },
          });
        }

        return updateAnswer({
          variables: {
            updateAnswerInput: {
              id: answer.id,
              description: item[Object.keys(item)[0]],
            },
          },
        });
      }),
    )
      .then((results) => {
        results.forEach((item) => {
          if (item.status === 'fulfilled') {
            // console.log('great!');
          } else if (item.status === 'rejected') {
            const error = item.reason;

            if (error.message?.includes('Error code 1002')) {
              message.error('The answer has already been registered previously');
            } else {
              message.error(
                `Something went wrong while trying to save the Answers: ${error?.message}`,
              );
            }
          }
        });
      })
      .catch((error) => {
        message.error('Something went wrong while trying to save the Answers');
      })
      .finally(() => {
        refetchInterviews();
        message.success('The questions in this section have been successfully saved.');
        setIsEditing(false);
      });
  };

  const onAnswerChange = (questionId: string, answer: string) => {
    form.setFieldsValue({ [questionId]: answer });
  };

  const editInfoExtra = () => (
    <>
      <Button
        size="small"
        type="link"
        icon={<EditOutlined />}
        onClick={(event) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();

          setIsEditing(!isEditing);
        }}
      >
        {isEditing ? 'Cancel' : 'Edit'}
      </Button>
      {isEditing && (
        <Button
          type="primary"
          style={{
            marginLeft: 12,
          }}
          onClick={(e) => {
            e.stopPropagation();
            form.submit();
          }}
        >
          Save
        </Button>
      )}
    </>
  );

  const getAnswer = (currentQuestionId: string) => {
    const { description } = Answers.filter(({
      questionId,
    }) => questionId === currentQuestionId)[0]
      ?? NO_ANSWER_FOUND_STATE;

    return description ?? '';
  };

  const activeKey = expandInterviewSections ? { activeKey: id } : {};

  return (
    <div>
      <Collapse
        className="interview-panel"
        {...activeKey}
      >
        <Panel
          header={
            sectionTranslations.filter((item) => item.languageId === languageId)[0]?.description ?? ''
          }
          key={id}
          extra={editInfoExtra()}
          style={{ marginBottom: '20px' }}
        >
          {!isEditing
            && Questions.map(({
              id: currentQuestionId, type, Translations, Options,
            }) => {
              const question = translateQuestion(Translations);
              const description = getAnswer(currentQuestionId);

              let answer = description ?? '';

              if (type === 'RADIO' || type === 'DROPDOWN') {
                answer = Options.filter(({ id: optionId }) => optionId === answer)[0]?.Translations?.filter((item) => item.languageId === languageId)[0]?.description ?? '';
              } else if (type === 'DATE') {
                answer = description ? moment(description).format('MM/DD/YYYY') : '';
              }

              return (
                <InterviewQuestion label={question} answer={answer} key={currentQuestionId} />
              );
            })}
          {isEditing && (
            <Form
              name={id}
              form={form}
              style={{
                width: '100%',
              }}
              layout="vertical"
              initialValues={getInitialAnswerValues()}
              onFinish={(values) => saveAnswersHandler(values, id)}
            >
              {Questions.map(({
                required, Translations, type, Options, id: currentQuestionId,
              }) => {
                const question = translateQuestion(Translations);
                const options = type === 'DROPDOWN' || type === 'RADIO'
                  ? Options?.map(({ id: optionId, Translations: optionTranslations }) => ({
                    value: optionId,
                    label: translateQuestion(optionTranslations ?? []),
                  }))
                  : [];
                let answer = '';
                const description = getAnswer(currentQuestionId);
                answer = description ?? '';

                return (
                  <EditInterviewQuestion
                    type={type}
                    options={options}
                    required={required}
                    questionName={question}
                    onAnswerChange={onAnswerChange}
                    currentValue={answer}
                    questionId={currentQuestionId}
                  />
                );
              })}
              <Row justify="start" style={{ margin: '0 0 20px 0' }}>
                <Button
                  type="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    form.submit();
                  }}
                >
                  Save
                </Button>
              </Row>
            </Form>
          )}
          {hasSubSections && SubSections.map((sub) => (
            <InterviewSection
              {...{ ...sub, jobId, Answers }}
              key={sub.id}
              refetchInterviews={refetchInterviews}
              expandInterviewSections={expandInterviewSections}
            />
          ))}
        </Panel>
      </Collapse>
    </div>
  );
};

InterviewSection.defaultProps = {
  interviewName: '',
};

export default InterviewSection;
