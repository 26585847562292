import React from 'react';
import RegisterTemplate from '~src/components/templates/Register';

interface RegisterPageProps {
  free?: boolean;
}
const RegisterPage = ({ free = false }: RegisterPageProps) => <RegisterTemplate free={free} />;

RegisterPage.defaultProps = {
  free: false,
};

export default RegisterPage;
