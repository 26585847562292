import { FC, useState } from 'react';
import { Form, message, Modal } from 'taxaroo-ui';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import DocumentSignApproveRequestModalContent from '../DocumentSignApproveRequestModalContent';
import actionCreators from '../NewESignatureRequestModal/action.creator';
import './styles.css';

interface DocumentApprovalRequestModalProps {
  isModalVisible: boolean
  setIsModalVisible: (b: boolean) => void
  mode: 'create' | 'edit'
  eSignatureAndApprovalState: any,
  eSignatureAndApprovalDispatch: any,
  refetchFileRequests: () => void
}

const DocumentApprovalRequestModal: FC<DocumentApprovalRequestModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  mode,
  eSignatureAndApprovalState,
  eSignatureAndApprovalDispatch,
  refetchFileRequests,
}) => {
  const [sendButtonDisabled, setSendButtonDisabled] = useState(true);
  const [okButtonLoading, setOkButtonLoading] = useState(false);
  const [form] = Form.useForm();
  const [isCompiledFileUpdated, setIsCompiledFileUpdated] = useState(false);

  const onOk = async () => {
    try {
      await form.validateFields();
      form.submit();

      trackCustomerIoEvent(CUSTOMER_IO_EVENTS.CLICK, {
        Title: mode === 'create' ? 'Create document approval request' : 'Update document approval request',
        Source: 'Button click',
        url: window.location.href,
      });
    } catch (error) {
      message.error('Please fill in all required fields.');
    }
  };

  const onCancel = () => {
    // reset fileList and newDraggableBoundarySignFields
    eSignatureAndApprovalDispatch(actionCreators.update({
      newDraggableBoundarySignFields: [],
      fileList: [],
      fileRequest: undefined,
    }));

    setIsCompiledFileUpdated(false);
    setIsModalVisible(false);
  };

  return (
    <Modal
      wrapClassName="document-approval-request-modal-wrapper"
      bodyStyle={{ height: 450, overflowY: 'scroll' }}
      title={mode === 'create'
        ? 'Create Document Approval'
        : 'Edit Document Approval'}
      okText={mode === 'create'
        ? 'Send For Approval'
        : 'Update Approval'}
      okButtonProps={{ disabled: sendButtonDisabled, loading: okButtonLoading }}
      centered
      visible={isModalVisible}
      onOk={onOk}
      onCancel={onCancel}
      width={1000}
      destroyOnClose
    >
      <DocumentSignApproveRequestModalContent
        form={form}
        type="approve"
        editMode={mode === 'edit'}
        setSendButtonDisabled={setSendButtonDisabled}
        closeModal={onCancel}
        setOkButtonLoading={setOkButtonLoading}
        state={eSignatureAndApprovalState}
        dispatch={eSignatureAndApprovalDispatch}
        refetchFileRequests={refetchFileRequests}
        isCompiledFileUpdated={isCompiledFileUpdated}
        setIsCompiledFileUpdated={setIsCompiledFileUpdated}
      />
    </Modal>
  );
};

export default DocumentApprovalRequestModal;
