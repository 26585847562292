import React from 'react';
import { Select, message } from 'taxaroo-ui';
import { useAppSelector } from '~src/redux/hooks';
import { useGetUserFirmAccountByFirmAccountAndFilterQuery } from '~src/graphql/queries/settings';
import { useAssignTaxYearInterviewMutation } from '~src/graphql/mutations/settings';

const { Option } = Select;

interface SelectTeamMemberProps {
  taxYearInterviewId: string;
  type: 'Reviewer' | 'Preparer';
  assignedMemberId: string;
  progressStatusId: string;
  userRole: string;
}

const SelectTeamMember: React.FC<SelectTeamMemberProps> = ({
  type,
  taxYearInterviewId,
  assignedMemberId,
  progressStatusId,
  userRole,
}) => {
  const { firmAccountId, progressStatuses } = useAppSelector((state) => state.session);

  const { data, loading } = useGetUserFirmAccountByFirmAccountAndFilterQuery({
    variables: {
      firmAccountId,
      filterDelete: true,
    },
  });

  const [
    assignTaxYearInterview,
    { loading: assignTaxYearInterviewLoading },
  ] = useAssignTaxYearInterviewMutation();

  const handleChange = async (value: string) => {
    try {
      if (value !== assignedMemberId && !(assignedMemberId === undefined && value === 'Not Assigned')) {
        const preparedOrReviewed = type === 'Preparer' ? 'preparedId' : 'reviewedId';
        const payload = type === 'Preparer' ? {
          preparedId: value !== 'Not Assigned' ? value : null,
        } : {
          reviewedId: value !== 'Not Assigned' ? value : null,
        };

        const result = (await assignTaxYearInterview({
          variables: {
            input: {
              id: taxYearInterviewId,
              ...payload,
            },
          },
          refetchQueries: [
            'GetClientsByProgressStatusId',
          ],
          awaitRefetchQueries: true,
        }));

        const updatedJob = result.data?.AssignTaxYearInterviewJob;
        const newAssigned = updatedJob[preparedOrReviewed];
        const newProgressStatusId = updatedJob.progressStatusId;

        let messageText = `The ${type.toLocaleLowerCase()} was successfully ${newAssigned ? 'assigned' : 'unassigned'}.`;
        if (newProgressStatusId !== progressStatusId) {
          const newProgressStatusName = progressStatuses.find((x) => x.id === newProgressStatusId)?.status;
          if (newProgressStatusName) {
            messageText += ` The job progress status was changed to ${newProgressStatusName}.`;
          }
        }

        // dispatch(updateJobInList({ id: taxYearInterviewId, [preparedOrReviewed]: newAssigned }));
        message.success(messageText);
      }
    } catch (error) {
      message.error(`Something went wrong while trying to assign the ${type.toLocaleLowerCase()}`);
    }
  };

  const userFirmAccounts = data?.UserFirmAccountByFirmAccountAndFilter;
  const currentItem = userFirmAccounts?.find((x) => x?.Users?.id === assignedMemberId);
  const currentUserInfo = currentItem?.Users?.UserInformation;
  const currentName = currentUserInfo ? `${currentUserInfo.firstName} ${currentUserInfo.lastName}` : 'Not Assigned';

  return (
    <div>
      {
        userRole === 'STAFF'
          ? (
            <span>
              {currentName}
            </span>
          )
          : (
            <Select
              size="small"
              style={{ maxWidth: '250px', minWidth: '145px' }}
              onSelect={handleChange}
              loading={loading || assignTaxYearInterviewLoading}
              onClick={(e) => {
                e.stopPropagation();
              }}
              value={assignedMemberId ?? 'Not Assigned'}
              defaultValue={assignedMemberId ?? 'Not Assigned'}
            >
              <Option value="Not Assigned">Not Assigned</Option>
              {
                userFirmAccounts
                && userFirmAccounts.map((item) => (
                  <Option
                    key={item.Users.id}
                    value={item.Users.id}
                    style={item.Users.deleteAt ? { display: 'none' } : {}}
                  >
                    {`${item.Users.UserInformation?.firstName} ${item.Users.UserInformation?.lastName}`}
                  </Option>
                ))
              }
            </Select>
          )
      }
    </div>
  );
};

export default SelectTeamMember;
