import moment from 'moment';
import { useState, useEffect } from 'react';
import {
  Table, Button, Icons, message,
} from 'taxaroo-ui';
import getPhoneIcon from '~src/components/helpers/getPhoneIcon';
import getPrimaryPhone from '~src/components/helpers/getPrimaryPhone';
import { PhonesEntity, UserFirmAccountEntity, UserFirmAccountOrderByInput } from '~src/graphql';
import { useRecoverDeletedClientMutation } from '~src/graphql/mutations/settings';
import { useGetDeletedClientsLazyQuery } from '~src/graphql/queries/settings';

const { UserSwitchOutlined } = Icons;

function DeletedClientsTable({ loadData }: { loadData: boolean }) {
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
    sorter: {},
  });
  const [getDeletedClients, { loading }] = useGetDeletedClientsLazyQuery();
  const [deletedClients, setDeletedClients] = useState([]);
  const [recoverDeletedClient, { loading: recoverLoading, data: deletedClientResult }] = useRecoverDeletedClientMutation();

  const mapDeletedClients = (data: UserFirmAccountEntity[]) => data?.map((client) => {
    const taxYear = client.Entity.TaxYear[0];
    const taxYearInterview = taxYear?.TaxYearInterview[0];
    const interview = taxYearInterview?.Interviews;
    return {
      userFirmAccountId: client.id,
      firstName: client.Entity?.Users?.UserInformation?.firstName,
      lastName: client.Entity?.Users?.UserInformation?.lastName,
      email: client.Entity?.Users?.email,
      phone: getPrimaryPhone(client.Entity?.Users?.UserInformation?.Phones),
      taxYear: taxYear?.year,
      jobType: interview?.name,
      deleteAt: moment(client.deleteAt).format('MMM DD, YYYY'),
    };
  });

  useEffect(() => {
    if (loadData) {
      getDeletedClients({
        variables: {
          orderBy: tableParams.sorter,
          skip: (tableParams.pagination.current - 1) * tableParams.pagination.pageSize,
          take: tableParams.pagination.pageSize,
        },
        onCompleted: (data) => {
          setDeletedClients(mapDeletedClients(data?.DeletedClients?.data));
          setTableParams((prev) => ({
            ...prev,
            pagination: {
              ...prev.pagination,
              total: data?.DeletedClients?.pagination?.total,
            },
          }));
        },
        onError: (error) => {
          message.error(error.message);
        },
      });
    }
  }, [loadData, tableParams.pagination.current, tableParams.pagination.pageSize, tableParams.sorter]);

  const handleTableChange = (pagination, filters, sorter) => {
    const orderByColumn = sorter.columnKey;
    const orderDirection = sorter.order === 'ascend' ? 'asc' : 'desc';
    let newSort: UserFirmAccountOrderByInput = { updateAt: 'desc' };
    switch (orderByColumn) {
      case 'firstName':
        newSort = { Entity: { Users: { UserInformation: { firstName: orderDirection } } } };
        break;
      case 'lastName':
        newSort = { Entity: { Users: { UserInformation: { lastName: orderDirection } } } };
        break;
      case 'email':
        newSort = { Entity: { Users: { email: orderDirection } } };
        break;
      case 'deleteAt':
        newSort = { deleteAt: orderDirection };
        break;
      default:
        newSort = { updateAt: 'desc' };
        break;
    }
    setTableParams({
      pagination,
      sorter: newSort,
    });
  };

  const handleRecoverClient = (record) => {
    recoverDeletedClient({
      variables: {
        userFirmAccountId: record,
      },
      onCompleted: () => {
        message.success('Client recovered successfully');
      },
      onError: (error) => {
        message.error(error.message);
      },
      refetchQueries: [
        'GetDeletedClients',
        'GetClients',
      ],
    });
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 200,
      sorter: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      width: 200,
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 200,
      sorter: true,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      sorter: false,
      render: (record: PhonesEntity) => (
        <span>
          {record?.value && (
            <>
              {getPhoneIcon(record)}
              {' '}
              {record?.value ?? ''}
            </>
          )}
        </span>
      ),
      key: 'phone',
      width: 200,
    },
    {
      title: 'Recent Tax Year',
      dataIndex: 'taxYear',
      sorter: false,
      key: 'taxYear',
      width: 115,
    },
    {
      title: 'Deleted At',
      dataIndex: 'deleteAt',
      sorter: true,
      key: 'deleteAt',
      width: 140,
    },
    {
      title: 'Recover Client',
      dataIndex: 'userFirmAccountId',
      key: 'userFirmAccountId',
      width: 120,
      align: 'center',
      render: (record) => (
        <Button type="primary" shape="circle" icon={<UserSwitchOutlined />} onClick={() => { handleRecoverClient(record); }} />
      ),
    },
  ];

  return (
    <Table
      loading={loading}
      sticky={{ offsetHeader: 45 }}
      tableLayout="fixed"
      scroll={{ x: 1000 }}
      showSorterTooltip={false}
      columns={columns}
      dataSource={deletedClients}
      rowKey={(record: any) => record.taxYearInterviewId}
      pagination={tableParams.pagination}
      onChange={handleTableChange}
      noDataProps={{
        title: 'No clients deleted',
      }}
    />
  );
}

export default DeletedClientsTable;
