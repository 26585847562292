.emptyUserSidebarContainer {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 5px 10px 8px #f2f2f2;
  padding-top: 40px;
  box-sizing: border-box;
  min-width: 150px;
}

.avatar {
  width: 80px;
  height: 80px;
  margin-top: 50px;
  border-radius: 100%;
  background-color: #f4f4f4;
}

.row {
  width: 124px;
  height: 15px;
  background-color: #f4f4f4;
  margin-top: 9px;
}

.rowmed {
  width: 92px;
  height: 15px;
  background-color: #f4f4f4;
  margin-top: 9px;
}

.rowsmall {
  width: 66px;
  height: 15px;
  background-color: #f4f4f4;
  margin-top: 9px;
}

.rowbuttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button {
  width: 36px;
  height: 36px;
  background-color: #f4f4f4;
  border-radius: 100%;
  margin-right: 5px;
}
