import { useState, useEffect, FC } from 'react';
import {
  Typography,
  Modal,
  Row,
  Icons,
} from 'taxaroo-ui';
import {
  CreateEventTypeAvailabilityInput,
  CreateEventTypeInput,
  EventTypeEntity,
  UpdateEventTypeInput,
} from '~src/graphql';
import EventTypeSettingsDescription from '../../molecules/EventTypeSettingsDescription';
import EventTypeSettingsMenu from '../../molecules/EventTypeSettingsMenu';
import { settings as initialSettings } from '../../const/eventTypes';

const { Title } = Typography;
const { InfoCircleOutlined } = Icons;

interface EventTypeSettingsProps {
  visible: boolean;
  handleCancel: () => void;
  eventType: EventTypeEntity;
  handleSave: (data: CreateEventTypeInput | UpdateEventTypeInput) => void,
  isLoading: boolean,
}

const EventTypeSettings: FC<EventTypeSettingsProps> = ({
  eventType,
  visible,
  handleCancel,
  handleSave,
  isLoading,
}) => {
  const [settings, setSettings] = useState<CreateEventTypeInput | UpdateEventTypeInput>({ ...initialSettings });
  const [availability, setAvailability] = useState<CreateEventTypeAvailabilityInput[]>([]);
  const [itsOK, setItsOK] = useState({
    descriptionTab: false,
    settingsTab: false,
    showErrors: false,
  });

  const updateSettings = (name: string, value: any) => {
    setSettings({
      ...settings,
      [name]: value,
    });
  };

  useEffect(() => {
    if (eventType) {
      setAvailability([...eventType.EventTypeAvailability]);
      setSettings({
        ...eventType,
        EventTypeAvailability: undefined,
      });
      setItsOK({
        descriptionTab: true,
        settingsTab: true,
        showErrors: false,
      });
    } else {
      setAvailability([]);
      setSettings({ ...initialSettings });
    }
  }, [eventType]);

  useEffect(() => {
    setItsOK({
      ...itsOK,
      settingsTab: availability.length > 0,
    });
  }, [availability]);

  useEffect(() => {
    if (!visible) {
      setItsOK({
        descriptionTab: false,
        settingsTab: false,
        showErrors: false,
      });
      setSettings({ ...initialSettings });
      setAvailability([]);
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      okButtonProps={{
        loading: isLoading,
        disabled: !itsOK.descriptionTab || !itsOK.settingsTab,
      }}
      okText="Save"
      onOk={() => {
        const updateAvailability = availability.map((ava) => {
          if (ava.EventTypeAvailabilityBracket.length === 0) {
            return undefined;
          }
          return {
            ...ava,
            id: undefined,
            __typename: undefined,
            EventTypeAvailabilityBracket: ava.EventTypeAvailabilityBracket.map((ab) => ({
              ...ab,
              id: undefined,
              __typename: undefined,
            })),
          };
        }).filter((avail) => avail);

        const createUpdateEventType = {
          ...settings,
          __typename: undefined,
          userId: undefined,
          beforeBuffer: Number(settings.beforeBuffer) || null,
          afterBuffer: Number(settings.afterBuffer) || null,
          EventTypeAvailability: updateAvailability,
        };

        handleSave(createUpdateEventType);
      }}
      onCancel={handleCancel}
      width="75%"
    >
      <Row>
        <Title level={3}>
          {settings.name !== '' ? settings.name : 'New Appointment Type'}
        </Title>
        <a
          href="https://help.taxaroo.com/article/82-create-appointment-type"
          target="_blank"
          rel="noreferrer"
        >
          <InfoCircleOutlined
            style={{
              fontSize: '16px',
              position: 'relative',
              top: '-0.3em',
              left: '0.5em',
            }}
          />
        </a>
        <EventTypeSettingsDescription
          itsOK={itsOK}
          setItsOK={setItsOK}
          data={settings}
          updateSettings={updateSettings}
        />
        <EventTypeSettingsMenu
          settings={settings}
          updateSettings={updateSettings}
          availability={availability}
          setAvailability={setAvailability}
        />
      </Row>
    </Modal>
  );
};

export default EventTypeSettings;
