import { message } from 'taxaroo-ui';

const onSuccessCreateProgress = () => {
  message.success('Progress Status created successfully!');
};

const onSuccessUpdateProgress = () => {
  message.success('Progress Status updated successfully!');
};

const onSuccessDeleteProgress = () => {
  message.success('Progress Status deleted  successfully!');
};

const onSuccessSortedProgress = () => {
  message.success('Progress Status sorted successfully!');
};

export {
  onSuccessCreateProgress,
  onSuccessUpdateProgress,
  onSuccessDeleteProgress,
  onSuccessSortedProgress,
};
