import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateEmailAddressMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type ValidateEmailAddressMutation = { __typename?: 'Mutation', validateEmailAddress: { __typename?: 'ReoonResponseEntity', isFormatValid: boolean, isMxFound: boolean, isDisposable: boolean, domainSuggestion?: string | null } };


export const ValidateEmailAddressDocument = gql`
    mutation validateEmailAddress($email: String!) {
  validateEmailAddress(email: $email) {
    isFormatValid
    isMxFound
    isDisposable
    domainSuggestion
  }
}
    `;
export type ValidateEmailAddressMutationFn = Apollo.MutationFunction<ValidateEmailAddressMutation, ValidateEmailAddressMutationVariables>;

/**
 * __useValidateEmailAddressMutation__
 *
 * To run a mutation, you first call `useValidateEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateEmailAddressMutation, { data, loading, error }] = useValidateEmailAddressMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useValidateEmailAddressMutation(baseOptions?: Apollo.MutationHookOptions<ValidateEmailAddressMutation, ValidateEmailAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateEmailAddressMutation, ValidateEmailAddressMutationVariables>(ValidateEmailAddressDocument, options);
      }
export type ValidateEmailAddressMutationHookResult = ReturnType<typeof useValidateEmailAddressMutation>;
export type ValidateEmailAddressMutationResult = Apollo.MutationResult<ValidateEmailAddressMutation>;
export type ValidateEmailAddressMutationOptions = Apollo.BaseMutationOptions<ValidateEmailAddressMutation, ValidateEmailAddressMutationVariables>;
export const namedOperations = {
  Mutation: {
    validateEmailAddress: 'validateEmailAddress'
  }
}