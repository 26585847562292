import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FirmTaxPreparersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FirmTaxPreparersQuery = { __typename?: 'Query', FirmTaxPreparers?: Array<{ __typename?: 'TaxPreparersEntity', id: string, name: string }> | null };


export const FirmTaxPreparersDocument = gql`
    query FirmTaxPreparers {
  FirmTaxPreparers {
    id
    name
  }
}
    `;

/**
 * __useFirmTaxPreparersQuery__
 *
 * To run a query within a React component, call `useFirmTaxPreparersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFirmTaxPreparersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFirmTaxPreparersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFirmTaxPreparersQuery(baseOptions?: Apollo.QueryHookOptions<FirmTaxPreparersQuery, FirmTaxPreparersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FirmTaxPreparersQuery, FirmTaxPreparersQueryVariables>(FirmTaxPreparersDocument, options);
      }
export function useFirmTaxPreparersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FirmTaxPreparersQuery, FirmTaxPreparersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FirmTaxPreparersQuery, FirmTaxPreparersQueryVariables>(FirmTaxPreparersDocument, options);
        }
export type FirmTaxPreparersQueryHookResult = ReturnType<typeof useFirmTaxPreparersQuery>;
export type FirmTaxPreparersLazyQueryHookResult = ReturnType<typeof useFirmTaxPreparersLazyQuery>;
export type FirmTaxPreparersQueryResult = Apollo.QueryResult<FirmTaxPreparersQuery, FirmTaxPreparersQueryVariables>;
export const namedOperations = {
  Query: {
    FirmTaxPreparers: 'FirmTaxPreparers'
  }
}