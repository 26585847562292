import moment from 'moment';
import { FC, useState } from 'react';
import {
  TaxarooButton,
  Icons,
  Avatar,
  Spin,
} from 'taxaroo-ui';
import { useDownloadFileFromS3Mutation } from '~src/graphql/mutations/clients';
import DownloadFile from '../../atoms/DownloadFile';
import Meeting from '../../atoms/Meeting';
import * as styles from './style.module.css';

const { FileOutlined, UserOutlined } = Icons;

interface MessageProps {
  message: string;
  attachment: string;
  last: boolean;
  mine: boolean;
  name: string;
  isSms: boolean;
  createdAt: Date;
  readDate?: Date;
}

const Message: FC<MessageProps> = ({
  message, attachment, last, mine, name, createdAt, readDate, isSms,
}) => {
  const attach = attachment ? JSON.parse(attachment) : null;

  const newMessage = message ? message.trimEnd() : '';
  const [urlS3File] = useDownloadFileFromS3Mutation();
  const [loading, setLoading] = useState<boolean>(false);

  const getLinkFile = async (s3key: string): Promise<string> => {
    setLoading(true);
    const { data } = await urlS3File({ variables: { s3Key: s3key } });
    setLoading(false);

    return data.downloadFile.signedUrl;
  };

  const openFile = async (s3key: string): Promise<void> => {
    const url = await getLinkFile(s3key);
    window.open(url, '_blank');
  };

  return (
    <div className={styles.messages}>
      <div className={`${styles.message} ${mine ? styles.messageMine : ''}`}>
        <div className={`${styles.userInfo}`}>
          {!mine && <Avatar size={25} icon={<UserOutlined />} />}
          <p className={`${styles.fromName} ${mine ? styles.nameMine : ''}`}>{name}</p>
          {mine && <Avatar size={25} icon={<UserOutlined />} />}
        </div>
        {attach && (
          <div className={`${styles.text} ${mine ? styles.textMine : ''}`}>
            <TaxarooButton
              type="link"
              size="middle"
              target="_blank"
              style={{ width: '100%' }}
              onClick={() => openFile(attach.s3key)}
            >
              <FileOutlined />
              <span className={styles.nameFile}>
                {attach.name.length > 30
                  ? `${attach.name.substring(0, 27)}...`
                  : attach.name}
              </span>
            </TaxarooButton>
            <br />
            <DownloadFile getUrl={async () => getLinkFile(attach.s3key)} name={attach.name} />
            {loading && (
              <div className={styles.loadingFile}>
                <Spin size="small" />
              </div>
            )}
          </div>
        )}
        {!attach && (
          // eslint-disable-next-line no-nested-ternary
          newMessage.includes('#{meetingId}=')
            ? (
              <Meeting meetingId={Number(newMessage.split('=')[1])} />
            )
            : isSms
              ? (
                <p className={styles.textsms}>{newMessage}</p>
              )
              : (
                <p className={styles.text}>{newMessage}</p>
              )
        )}
        {!isSms
          ? (
            <p className={styles.messageDate}>
              {moment(createdAt).format('MMM Do, YYYY, h:mma')}
              {' '}
              Email / Chat
            </p>
          )
          : (
            <p className={styles.messageDate}>
              {moment(createdAt).format('MMM Do, YYYY, h:mma')}
              {' '}
              SMS
            </p>
          )}
      </div>
    </div>
  );
};

Message.defaultProps = {
  readDate: null,
};

export default Message;
