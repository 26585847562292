import { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Typography,
  Rate,
  Skeleton,
} from 'taxaroo-ui';
import { AvgRatingEntity, TaxPreparersEntity } from '~src/graphql';
import { useGetFirmsAverageRatingQuery, useGetPreparerAverageRatingLazyQuery } from '~src/graphql/queries/analytics';

const { Title } = Typography;

interface LineChartSectionProps {
  preparers: TaxPreparersEntity[];
}
function FirmCompiledReviewsSection({
  preparers,
}: LineChartSectionProps) {
  const [preparerRatings, setPreparerRatings] = useState([]);
  // graphql queries
  // get firm avg review
  const { data: avgRatingData, loading: loadingAvgRating } = useGetFirmsAverageRatingQuery();
  const avgRating = avgRatingData?.firmsAverageRating;
  // get a preparer's avg review
  const [getPreparerAvgRating] = useGetPreparerAverageRatingLazyQuery();

  useEffect(() => {
    // fetch avg rating for each preparer
    const promises = preparers.map((preparer) => getPreparerAvgRating({
      variables: {
        preparerId: preparer.id,
      },
    }));
    Promise.all(promises).then((results) => {
      const avgRatings = [];
      results.forEach((result) => {
        avgRatings.push({
          avgRating: result.data?.preparerAverageRating?.avgRating,
          preparerName: result.data?.preparerAverageRating?.preparerName,
          numberOfReviews: result.data?.preparerAverageRating?.numberOfReviews,
        });
      });
      setPreparerRatings(avgRatings);
    });
  }, [preparers, setPreparerRatings, getPreparerAvgRating]);

  return (
    <>
      {!loadingAvgRating ? (
        <Title level={4} style={{ marginTop: -5, marginBottom: 20 }}>
          {avgRating !== 'NaN' ? `${avgRating} average star rating` : 'No reviews yet'}
        </Title>
      ) : (
        <Skeleton active paragraph={{ rows: 0 }} />
      )}
      <Row>
        <Col span={12}>
          {preparerRatings.map((preparerRating: AvgRatingEntity, index) => (
            <Row key={`compiled-review-section-${index}`}>
              <span>
                <Rate allowHalf disabled defaultValue={parseFloat(preparerRating?.avgRating)} />
                {' '}
                {preparerRating?.preparerName}
                {' '}
                |
                {' '}
                {preparerRating?.numberOfReviews}
                {' '}
                reviews
              </span>
            </Row>
          ))}
        </Col>
        <Col span={12}>
          {/* <Chart options={options} /> */}
        </Col>
      </Row>
    </>
  );
}

export default FirmCompiledReviewsSection;
