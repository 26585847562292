import { useState } from 'react';
import {
  Input,
  Tooltip,
  Icons,
} from 'taxaroo-ui';
import { TooltipProps } from 'taxaroo-ui/node_modules/antd';
import WarningCircleFilled from '~src/assets/icons/warningCircleFilled.svg';
import * as styles from './styles.module.css';

const { CheckCircleFilled } = Icons;

const PasswordRequirements = [
  {
    message: 'Must be between 8 and 30 characters long',
    validate: (password: string) => password?.length >= 8 && password?.length <= 30,
  },
  {
    message: 'Must contain at least one lowercase letter',
    validate: (password: string) => /[a-z]/.test(password),
  },
  {
    message: 'Must contain at least one uppercase letter',
    validate: (password: string) => /[A-Z]/.test(password),
  },
  {
    message: 'Must contain at least one number',
    validate: (password: string) => /[0-9]/.test(password),
  },
  {
    message: 'Must contain at least one special character',
    validate: (password: string) => /[^A-Za-z0-9]/.test(password),
  },
];

const PasswordTooltipValue = ({ password }: { password: string }) => (
  <div style={{ paddingTop: 10 }}>
    {PasswordRequirements.map((requirement) => (
      <p key={requirement.message} style={{ color: requirement.validate(password) ? '#404040' : 'white' }}>
        {requirement.validate(password)
          ? <CheckCircleFilled className={styles.checkCircleFilled} />
          : <WarningCircleFilled className={styles.warningCircleFilled} />}
        {' '}
        {requirement.message}
      </p>
    ))}
  </div>
);

type MyTooltipProps = Omit<TooltipProps, 'title'> & Partial<Pick<TooltipProps, 'title'>>;

interface PasswordInputWithTooltipProps {
  tooltipProps?: MyTooltipProps;
}

const PasswordInputWithTooltip = ({ tooltipProps }: PasswordInputWithTooltipProps) => {
  const [password, setPassword] = useState<string>('');
  return (
    <Tooltip
      trigger={['focus']}
      title={<PasswordTooltipValue password={password} />}
      placement="bottomLeft"
      color="#282828"
      overlayClassName={styles.passwordTooltip}
      {...tooltipProps}
    >
      <Input.Password
        autoComplete="new-password"
        type="white"
        placeholder="Enter Password ..."
        size="large"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
      />
    </Tooltip>
  );
};

PasswordInputWithTooltip.defaultProps = {
  tooltipProps: {},
};

export default PasswordInputWithTooltip;
