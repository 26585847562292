/* stylelint-disable font-family-name-quotes */
.container {
  border-radius: 4px;
  padding: 10px 15px;
  margin-top: 10px;
  background-color: #f4f6f9;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
  color: #565656;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
}

.key {
  line-height: 18px;
  display: flex;
  margin: 1rem;
}

.keyTitle {
  font-size: 16px;
  color: #4c4c4c;
  font-weight: bolder;
  font-family: "LatoBold", sans-serif;
  text-align: right;
}

.value {
  font-size: 15px;
  color: #4c4c4c;
  font-family: "LatoLight", sans-serif;
}

.join {
  font-family: "LatoBold", sans-serif;
  display: block;
  font-size: 16px;
  line-height: 28px;
  padding: 6px;
  letter-spacing: 0.6px;
  text-align: center;
  border-radius: 6px;
  outline: none;
  border: none;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  background-color: #3d80f3;
  color: #f9f9f9;
  margin: 0 auto;
  width: 100px;
}

.join:hover {
  color: #f9f9f9;
  background-color: #78bffd;
}
