/* eslint-disable */
import moment from 'moment';

const getLang = () => localStorage.getItem('lang') || 'en';
const getMomentLocaleTime = (lang: string, time: any, utc = false) => {
  const momentLocale = utc ? moment.utc(time) : moment(time);
  if (lang !== 'en') {
    // const momentLang = require('moment/locale/es');
    // momentLocale.locale(lang, [momentLang]);
    momentLocale.locale(lang);
  }
  let timeWithLocale = momentLocale.format('MMMM DD, YYYY h:mm a');

  // reset moment because makes a conflict with react-calendar
  moment.locale('en');

  if (lang === 'es') {
    timeWithLocale = timeWithLocale.charAt(0).toUpperCase() + timeWithLocale.slice(1);
  }

  return timeWithLocale;
};

export const utcToCustomTime = (time: any, utc = true) => {
  const lang = getLang();
  const timeFormat = getMomentLocaleTime(lang, time, utc);
  if (time) {
    return timeFormat;
  }
  return '-';
};
