/* eslint-disable no-unused-vars */

import React from 'react';
import {
  Upload, UploadProps, List, Avatar, Tooltip,
} from 'antd';
import {
  InboxOutlined,
  FilePdfOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';

const { Dragger } = Upload;

interface FileUploadDraggerProps extends UploadProps {
  createTemplate?: (id: string) => void;
  deleteFile: (id: string, index: number) => void;
}

// eslint-disable-next-line arrow-body-style
const FileUploadDragger: React.FC<FileUploadDraggerProps> = ({
  createTemplate,
  deleteFile,
  fileList,
  ...rest
}) => {
  let listHeight = 100;
  listHeight = fileList?.length === 0 ? 0 : listHeight;
  listHeight = fileList?.length === 1 ? 50 : listHeight;

  return (
    <div>
      <Dragger {...rest}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
      </Dragger>
      <List
        size="small"
        style={{ height: listHeight, overflowY: 'scroll' }}
        dataSource={fileList}
        renderItem={(item, index) => (
          <List.Item key={item.name}>
            <List.Item.Meta
              avatar={(
                <Avatar
                  icon={
                    <FilePdfOutlined />
                  }
                />
              )}
              title={<p><strong>{item.name}</strong></p>}
            />

            {createTemplate && (
              <Tooltip
                title="Save as Template"
              >
                <Avatar
                  icon={(
                    <PlusOutlined
                      onClick={() => {
                        createTemplate(item.uid);
                      }}
                    />
                  )}
                />
              </Tooltip>
            )}

            <Tooltip
              title="Delete File"
            >
              <Avatar
                icon={(
                  <DeleteOutlined
                    onClick={() => {
                      deleteFile(item.uid, index);
                    }}
                  />
                )}
              />
            </Tooltip>
          </List.Item>
        )}
      />
    </div>
  );
};

export default FileUploadDragger;
