.activate-account-page {
  background: url("https://d2slcw3kip6qmk.cloudfront.net/app/common/purchase-flow/create-account-background-2.svg") no-repeat;
  background-size: 87%;
  background-position-y: 40px;
  min-height: calc(100vh);
}

.activate-account-wrapper {
  /* height: 100vh; */
  margin: 50px 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}
