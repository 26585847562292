import { message } from 'taxaroo-ui';

const onSuccessMutation = () => {
  message.success('Success!');
};

const onSuccessUploadFile = () => {
  message.success('File uploaded');
};

const handleOnError = () => {
  message.error('An error has occurred');
};

const handleOnUploadFileToDBError = (error) => {
  console.log({ error });
  message.error('An error ocurred while uploading to our DB');
};

const handleOnLoadFirmFilesError = () => {
  message.error('An error ocurred while loading firm files');
};

const handleOnGetPresignedURLS3Error = () => {
  message.error('An error while trying to upload file to S3');
};

const handleOnFileTypeNotSupported = () => {
  message.error('The document of this type is not allowed. You can upload PDFs, texts and images only.');
};

export {
  handleOnError,
  handleOnGetPresignedURLS3Error,
  handleOnUploadFileToDBError,
  handleOnLoadFirmFilesError,
  handleOnFileTypeNotSupported,
  onSuccessMutation,
  onSuccessUploadFile,
};
