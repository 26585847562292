import { Dispatch, SetStateAction } from 'react';
import {
  Row, Col, Typography, Modal, TaxarooButton, message,
} from 'taxaroo-ui';
import { useGetCloudFrontDistributionByCertificateArnQuery } from '~src/graphql/queries/settings';

const { Text } = Typography;

interface CustomDomainDNSModalProps {
  isDNSModalVisible: boolean;
  setIsDNSModalVisible: Dispatch<SetStateAction<boolean>>;
}
const CustomDomainDNSModal = ({
  isDNSModalVisible, setIsDNSModalVisible,
}: CustomDomainDNSModalProps) => {
  // Query for getting cloudfront distribution
  const { data: cloudfrontDistributionData } = useGetCloudFrontDistributionByCertificateArnQuery({
    // onError: (error) => message.error(`Error getting cloudfront distribution: ${error?.message}`),
  });
  const { GetCloudFrontDistributionByCertificateArn } = cloudfrontDistributionData || {};

  const [subdomain, ...domainSection] = GetCloudFrontDistributionByCertificateArn?.Aliases?.Items?.[0]?.split('.') || [];

  return (
    <Modal
      title="Final step: DNS Configuration"
      width="75%"
      visible={isDNSModalVisible}
      onCancel={() => setIsDNSModalVisible(false)}
      footer={[
        <TaxarooButton
          onClick={() => setIsDNSModalVisible(false)}
          type="primary"
        >
          close
        </TaxarooButton>,
      ]}
    >
      <Row gutter={16}>
        <Col span={24}>
          You must setup a CNAME record for your domain to complete the custom domain setup.
          <br />
          <br />
          <b>To set up:</b>
          <br />
          1. Log in to your domain registrar.
          <br />
          2. Navigate to the DNS settings for your domain.
          <br />
          3. Create a CNAME record with the host
          {' '}
          <Text copyable code>{subdomain}</Text>
          {' '}
          with the value
          {' '}
          <Text copyable code>{GetCloudFrontDistributionByCertificateArn?.DomainName}</Text>
          .
          <br />
          <br />
          For further assistance, please check out our
          {' '}
          <a href="https://help.taxaroo.com/article/87-how-to-create-custom-domain" target="_blank" rel="noreferrer">
            help document
          </a>
          , contact support, or contact your domain registrar.
          <br />
          <br />
          *Note: DNS changes can take up to an hour and sometimes longer to take effect.
        </Col>
      </Row>
    </Modal>
  );
};

export default CustomDomainDNSModal;
