.inviteLinkBackground {
  background-color: aliceblue;
  padding: 20px;
  margin-bottom: 20px;
  min-height: 20px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  text-align: center;
  width: 100%;
}

.saveOutlined {
  font-size: 20px;
  cursor: pointer;
}

.container {
  background-color: white !important;
}

.dashedHeader {
  border-bottom: 1px dashed #d6d6d6;
  margin-right: 15px;
}

.centeredText {
  text-align: center;
}
