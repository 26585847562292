import React, {
  useState, useEffect, FC, useRef,
} from 'react';
import {
  Input, Modal, List, Spin, message,
} from 'taxaroo-ui';
import { useAppDispatch, useAppSelector } from '~src/redux/hooks';
import { selectSession } from '~src/redux/slices/sessionSlice';
import useDebouncedEffect from '~src/components/hooks/useDebouncedEffect';
import { useFindAllUsersInFirmLazyQuery } from '~src/graphql/queries/messaging';
import { useCreateChannelMutation } from '~src/graphql/mutations/messaging';
import { selectChannels, setChannels, setSelectedChannel } from '~src/redux/slices/chatSlice';
import UseChannels from '~src/components/hooks/useChannels';
import Conversation from '../Conversation';
import EmptyState from './EmptyState';
import * as styles from './style.module.css';

const { Search } = Input;

interface NewConversationModalProps {
    handleCancel: () => void;
    visible: boolean
}

const NewConversationModal: FC<NewConversationModalProps> = ({
  visible, handleCancel,
}) => {
  const firstRender = useRef(true);
  const dispatch = useAppDispatch();
  const currentChannels = useAppSelector(selectChannels);
  const { firmAccountId, userId } = useAppSelector(selectSession);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [channelSearch, setChannelSearch] = useState('');
  const [createChannel, {
    loading: createChannelLoading,
  }] = useCreateChannelMutation();
  const [findAllUsersInFirm, {
    data: usersInFirm,
    loading: usersInFirmLoading,
  }] = useFindAllUsersInFirmLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const { addChannel } = UseChannels(false);

  const onOk = async () => {
    try {
      const { firstName, lastName } = usersInFirm?.findAllUsersInFirm
        ?.find(({ id }) => id === selectedUserId)?.UserInformation ?? {
        UserInformation: {
          firstName: '',
          lastName: '',
        },
      };

      const newChannel = (await createChannel({
        variables: {
          createChannelInput: {
            firmAccountId,
            currentUserId: userId,
            userIdToConnect: selectedUserId,
            description: `${firstName} ${lastName}`,
          },
        },
      })).data.createChannel;

      if (newChannel.id) {
        message.success('The channel was created successfully!');
        addChannel(newChannel, true);
      }
      handleCancel();
      setSelectedUserId('');
    } catch (error) {
      message.error('Something went wrong while trying to create the channel');
    }
  };

  const handleSelectUser = (id: string) => {
    setSelectedUserId((previousId) => {
      if (previousId === id) {
        return '';
      }

      return id;
    });
  };

  useEffect(() => {
    const controller = new AbortController();
    findAllUsersInFirm({
      variables: {
        firmAccountId,
      },
    });

    return () => {
      controller.abort();
    };
  }, []);

  useDebouncedEffect(() => {
    const controller = new AbortController();

    if (firstRender.current) {
      firstRender.current = false;
    } else {
      findAllUsersInFirm({
        variables: {
          firmAccountId,
          searchWord: channelSearch,
        },
      });
    }

    return () => controller.abort();
  }, 500, [channelSearch]);

  return (
    <Modal
      onOk={onOk}
      destroyOnClose
      visible={visible}
      onCancel={handleCancel}
      title="Start a conversation"
      okButtonProps={{
        className: styles.btnok,
        disabled: !selectedUserId,
      }}
      wrappClassName={styles.newConversationModal}
    >
      <Search
        enterButton={null}
        value={channelSearch}
        className={styles.searchUsersInput}
        onChange={(value) => {
          setChannelSearch(value.currentTarget.value);
        }}
        placeholder="Search"
        onSearch={() => undefined}
        style={{ width: '100%', marginBottom: 20 }}
      />
      {
      usersInFirmLoading || createChannelLoading ? (
        <div className={styles.spinContainer}>
          <Spin />
        </div>
      ) : (
        <div>
          {
        !usersInFirm?.findAllUsersInFirm?.length ? <EmptyState /> : (
          <List
            // size="small"
            header={<div />}
            footer={<div />}
            className={styles.list}
            bordered={false}
            dataSource={usersInFirm?.findAllUsersInFirm ?? []}
            renderItem={(item) => (
              <Conversation
                data={item}
                onSelect={handleSelectUser}
                isSelected={selectedUserId === item.id}
              />
            )}
          />
        )
        }
        </div>
      )
    }
    </Modal>
  );
};

export default NewConversationModal;
