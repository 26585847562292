import {
  useState, FC, useEffect, useRef,
} from 'react';
import { useDrop } from 'react-dnd';
import { Document, Page } from 'react-pdf/dist/esm/entry.parcel2';
import { FileApprovalEntity } from '~src/graphql';
import actionCreators from '../action.creator';
import DroppableContainer from '../DroppableContainer';
import './styles.css';

// const pdfFile = 'https://raw.githubusercontent.com/wojtekmaj/react-pdf/main/sample/parcel2/sample.pdf';
const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
};

interface SignatureDocSignAreaDroppableProps {
  state: any;
  dispatch: any;
  form: any;
  editMode: boolean;
  setSignatureFieldsCompleted: (val: boolean) => void;
  isCompiledFileUpdated: boolean;
}

const SignatureDocSignAreaDroppable: FC<SignatureDocSignAreaDroppableProps> = ({
  state,
  dispatch,
  form,
  editMode,
  setSignatureFieldsCompleted,
  isCompiledFileUpdated,
}) => {
  const ref = useRef<HTMLDivElement>();
  const finalValues = form.getFieldsValue(true);
  const { compiledFile, currentActiveTab } = state;

  const [numPages, setNumPages] = useState(null);
  const [fileWidth, setFileWidth] = useState(null);
  const [fileHeight, setFileHeight] = useState(null);
  const [pageViewports, setPageViewports] = useState([]);
  const [signersArray, setSignersArray] = useState([]);
  const [documentRect, setDocumentRect] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (currentActiveTab === '2') { // recalculate signature fields initial position
      if (ref.current) {
        const { width, height } = ref.current.getBoundingClientRect();
        if (width && height) {
          setDocumentRect({ width, height });
        }
      }
    }
  }, [currentActiveTab, fileWidth, fileHeight, pageViewports]);

  useEffect(() => {
    const signers = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in finalValues) {
      // eslint-disable-next-line no-prototype-builtins
      if (finalValues.hasOwnProperty(key)) {
        const element = finalValues[key];
        if (key.substring(0, 9) === 'signerKBA' && element.signatureKBAType) { // the substring of signerKBA[0] is signerKBA
          signers.push({
            key, // i.e. signerKBA[0]
            email: element.signerEmail,
            name: element.signerName,
          });
        }
      }
    }
    setSignersArray(signers);
  }, [finalValues]);

  useEffect(() => {
    if (editMode) {
      // if compiled file was changed - do not restore existing fields
      if (isCompiledFileUpdated) {
        dispatch(actionCreators.update({
          newDraggableBoundarySignFields: [],
        }));

        return;
      }

      if (!numPages || pageViewports.length < numPages || !documentRect.width) return;

      const singatureFields = [];
      state.fileRequest?.FileApproval?.forEach((fa: FileApprovalEntity) => {
        const signer = signersArray.find((s) => s.email === fa.email || s.name === fa.name);
        if (signer) {
          fa?.SignatureFields.forEach((field) => {
            const pageViewport = pageViewports[field.page - 1];
            const scale = documentRect.width / pageViewport.width;
            const actualHeight = fileHeight * scale;

            singatureFields.push({
              id: field.id,
              fieldType: field.fieldType,
              left: (field.fieldLeftPercentage / 100) * documentRect.width,
              width: (field.fieldWidthPercentage / 100) * documentRect.width,
              top: (field.fieldTopPercentage / 100) * actualHeight - 20,
              height: (field.fieldHeightPercentage / 100) * actualHeight - 20,
              fieldLeftPercentage: field.fieldLeftPercentage,
              fieldTopPercentage: field.fieldTopPercentage,
              fieldWidthPercentage: field.fieldWidthPercentage,
              fieldHeightPercentage: field.fieldHeightPercentage,
              page: field.page,
              title: field.title,
              email: signer.email,
              signerId: signer.key,
              fileApprovalId: fa.id,
              signatureImage: field.signatureImage,
              completedAt: field.completedAt,
              description: field.description,
            });
          });
        }
      });

      dispatch(actionCreators.update({
        newDraggableBoundarySignFields: singatureFields,
      }));
    }
  }, [signersArray, isCompiledFileUpdated, documentRect, pageViewports]);

  // this useDrop is for changing the background color of the signature area (if we want to)
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ['SIGNATURE_FIELD', 'SIGNATURE_BOUNDARY_FIELD'],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }), []);

  useEffect(() => {
    if (state.newDraggableBoundarySignFields?.filter((f: any) => f.fieldType === 'SIGNATURE')?.length >= signersArray.length
      && state.newDraggableBoundarySignFields.every((field: any) => field.signerId)) {
      setSignatureFieldsCompleted(true);
    } else {
      setSignatureFieldsCompleted(false);
    }
  }, [state.newDraggableBoundarySignFields]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onDocumentLoadSuccess = async (pdf: any) => {
    setNumPages(pdf.numPages);

    const firstPage = await pdf.getPage(1);
    setFileWidth(firstPage.view[2]);
    setFileHeight(firstPage.view[3]);

    const pageNumbers = Array.from(new Array(pdf.numPages)).map((_, index) => index + 1);
    pageNumbers.forEach(async (pageNumber) => {
      const page = await pdf.getPage(pageNumber);
      setPageViewports((oldVal) => [...oldVal, page.getViewport({ scale: 1 })]);
    });
  };

  const onLoadError = (error) => {
    console.log('loading error:', error);
  };

  if (!compiledFile) {
    return <div>loading...</div>;
  }

  return (
    <div ref={ref} style={{ padding: 0, margin: 0 }}>
      <Document
        file={compiledFile}
        onLoadSuccess={onDocumentLoadSuccess}
        option={options}
        onLoadError={onLoadError}
      >
        {Array.from(new Array(numPages), (_el, index) => (
          <DroppableContainer
            key={`page_${index + 1}`}
            className="droppable-container"
            thisPage={index + 1}
            state={state}
            dispatch={dispatch}
            signersArray={signersArray}
          >
            <div
              id={`page_${index + 1}`}
              className="page-wrapper"
              data-page={index + 1}
            >
              <Page pageNumber={index + 1} />
            </div>
          </DroppableContainer>
        ))}
      </Document>
    </div>
  );
};

export default SignatureDocSignAreaDroppable;
