import {
  useEffect, useRef, useState,
} from 'react';
import { PresenceChannel } from 'pusher-js';
import { message as messageAlert } from 'taxaroo-ui';
import { ChannelEntity, MessageEntity } from '~src/graphql';
import { selectSession } from '~src/redux/slices/sessionSlice';
import { useAppDispatch, useAppSelector } from '~src/redux/hooks';
import {
  addMessages,
  selectChannels, selectCurrentChannel, selectMessages, setChannels, setSelectedChannel,
} from '~src/redux/slices/chatSlice';
import { useGetChannelMessagesLazyQuery, useGetUserChannelsLazyQuery } from '~src/graphql/queries/messaging';
import { useUpdateMessageMutation } from '~src/graphql/mutations/messaging';
import notify from '../helpers/browser-notification';
import { PusherClient } from '../../modules/PusherClient';

const UseChannels = (fetchChannels: boolean) => {
  // * REDUX DATA
  const dispatch = useAppDispatch();
  const currentChannels = useAppSelector(selectChannels).filter((channel) => channel.ChannelMembers.length > 1); // * Remove channels with only one member
  const currentChannel = useAppSelector(selectCurrentChannel);
  const messages = useAppSelector(selectMessages);
  const { accessToken: token, userId } = useAppSelector(selectSession);
  // * LOCAL STATE DATA
  const pusher = new PusherClient(token);
  const [getChannels, {
    loading: loadingChannels,
  }] = useGetUserChannelsLazyQuery();
  const currentChannelRef = useRef(currentChannel);
  const [updateMessage] = useUpdateMessageMutation();
  const currentChannelsRef = useRef(currentChannels);
  const messagesRef = useRef(messages);
  const [loadingMoreMessages, setLoadingMoreMessages] = useState<boolean>(false);

  const [getChannelMessages] = useGetChannelMessagesLazyQuery();

  const getMoreMessages = async (channelId: string, take?: number, skip?: number) => {
    setLoadingMoreMessages(true);
    const { data } = await getChannelMessages({
      fetchPolicy: 'network-only',
      variables: {
        channelId,
        take: take || 10,
        skip: skip || messagesRef.current[channelId]?.length || 0,
      },
      onError: (err) => {
        messageAlert.error(err.message);
      },
    });
    const fetchedMessages = data?.getChannelMessages;
    dispatch(addMessages({
      channelId,
      messages: fetchedMessages,
    }));
    setLoadingMoreMessages(false);
  };

  const addMessage = async (message: MessageEntity) => {
    if (currentChannelRef.current && currentChannelRef.current.id === message.channelId) {
      let newMessageData = { ...message };
      if (message.userId !== userId) {
        const updatedMessage = (await updateMessage({
          variables: {
            updateMessageInput: {
              id: message.id,
              readDate: new Date(),
            },
          },
        })).data?.updateMessage;

        newMessageData = {
          ...updatedMessage,
        };
      }

      dispatch(addMessages({
        channelId: message.channelId,
        messages: [newMessageData],
      }));
    } else {
      const channelTarget = currentChannelsRef.current.find(({ id }) => id === message.channelId);

      if (channelTarget) {
        dispatch(addMessages({
          channelId: message.channelId,
          messages: [message],
        }));
      }
    }
  };

  const onMessage = (message: MessageEntity) => {
    // console.log("onMessage", message);
    addMessage(message);
  };

  const onStatusChange = async (id: string, newStatus: boolean, memberUserId: string) => {
    const channelTarget = currentChannelsRef.current?.find(({ id: currentId }) => currentId === id);

    if (channelTarget) {
      if (currentChannelRef.current && channelTarget.id === currentChannelRef.current.id) {
        dispatch(setSelectedChannel({
          ...currentChannelRef.current,
          status: newStatus,
        }));
      }

      const updatedChannel = {
        ...channelTarget,
        status: newStatus,
      };

      dispatch(setChannels(currentChannelsRef.current.map((channel) => {
        if (channel.id === updatedChannel.id) {
          return updatedChannel;
        }
        return channel;
      })));
    }
  };

  const addChannel = (newChannel: ChannelEntity, setSelected = false) => {
    // * Subscribe to corresponding channel
    let channel = pusher.channel(`presence-${newChannel.id}`) as PresenceChannel;

    if (!channel) {
      const onMemberAddedHandler = (member: { id: string, info: { email: string } }) => {
        onStatusChange(newChannel.id, true, member.id);
      };

      const onMemberRemovedHandler = (member: { id: string, info: { email: string } }) => {
        onStatusChange(newChannel.id, false, member.id);
      };
      channel = pusher.subscribe(`presence-${newChannel.id}`) as PresenceChannel;

      channel.bind('chat', (message: MessageEntity) => {
        const { text, Users, userId: messageUserId } = message;
        const { firstName, lastName } = Users.UserInformation;

        if (messageUserId !== userId) {
          notify(`${firstName} ${lastName}`, text);
        }
        onMessage(message);
      });

      channel.bind('pusher:member_added', onMemberAddedHandler);
      channel.bind('pusher:member_removed', onMemberRemovedHandler);
      channel.bind('pusher:subscription_succeeded', (member: { id: string, info: { email: string } }) => {
        if (channel.members.count > 1) {
          onStatusChange(newChannel.id, true, member.id);
        }
      });

      channel.bind('pusher:subscription_error', (error) => {
        messageAlert.error('Something went wrong while trying to connect the chat');
        // eslint-disable-next-line no-console
        console.log({ error });
      });
    }

    if (setSelected) {
      dispatch(setSelectedChannel({ ...newChannel, status: channel.members.count > 1 }));
    }

    if (!currentChannelsRef.current.find(({ id }) => id === newChannel.id)) {
      dispatch(setChannels([newChannel, ...currentChannelsRef.current]));
    }
  };

  const attachToChannelsPusher = async (channels: ChannelEntity[]) => {
    const wait = 10000;
    // eslint-disable-next-line no-restricted-syntax
    for (const ch of channels) {
      addChannel(ch);
      // eslint-disable-next-line
      await new Promise((resolve) => setTimeout(resolve, wait));
    }
  };

  useEffect(() => {
    currentChannelsRef.current = currentChannels;
  }, [currentChannels]);

  useEffect(() => {
    currentChannelRef.current = currentChannel;
  }, [currentChannel]);

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      if (pusher.connection.state === 'connected') {
        pusher.disconnect();
      }
    });

    if (pusher.connection.state === 'disconnected') {
      pusher.connect();
    }

    if (fetchChannels && currentChannels.length === 0) {
      getChannels({
        variables: {
          searchWord: '',
        },
      }).then(({ data }) => {
        dispatch(setChannels(data.userChannels));
        attachToChannelsPusher(data.userChannels);
        // eslint-disable-next-line no-restricted-syntax
        for (const channel of data.userChannels) {
          // if there are no currently fetched messages in the messages store, store any messages fetched with the getChannels query (usually 1 message)
          if (!messagesRef?.current?.[channel.id] || messagesRef?.current?.[channel.id]?.length === 0) {
            dispatch(addMessages({
              channelId: channel.id,
              messages: channel.Messages,
            }));
          }
        }
      }).catch((err) => console.log('Fetch user channels error', err));
    }
  }, []);

  return {
    addChannel,
    getMoreMessages,
    loadingMoreMessages,
    currentChannel,
    loadingChannels,
    channels: currentChannels,
    messages,
  };
};

export default UseChannels;
