import { useContext, useState } from 'react';
import {
  Button, Typography, Table, Row, Col, Icons, message, Tooltip, Badge, Dropdown, Menu, Space, Empty,
} from 'taxaroo-ui';
import { Link as RouterLink } from 'react-router-dom';
import { GetClientsByProgressStatusIdDocument, GetClientsDocument, useCurrentStripeInformationQuery } from '~src/graphql/queries/settings';
import { JobContext } from '~src/components/providers/job-provider';
import { PaymentsEntity } from '~src/graphql';
import { GetPaymentsByTaxYearInterviewIdDocument, useGetPaymentsByTaxYearInterviewIdQuery } from '~src/graphql/queries/payments';
import { useDeletePaymentWithInvoicesMutation, useSendInvoiceReminderMutation, useUpdatePaymentMutation } from '~src/graphql/mutations/payments';
import { useUpsertJobTrackingByTaxYearInterviewIdMutation } from '~src/graphql/mutations/jobTracking';
import { Features, useUpgradeModal } from '~src/components/atoms/UpgradeModal/ModalProvider';
import { useAppSelector } from '~src/redux/hooks';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import CreateOrUpdateInvoiceModal from '../../../../molecules/CreateOrUpdateInvoiceModal';
import ViewInvoiceModal from '../../../../molecules/ViewInvoiceModal';

const { Paragraph, Link } = Typography;
const {
  CheckCircleOutlined,
  DollarOutlined,
  PlusOutlined,
  UndoOutlined,
  ArrowDownOutlined,
  SendOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  EditOutlined,
} = Icons;

function InvoicesStatusTracking() {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isViewInvoiceModalVisible, setIsViewInvoiceModalVisible] = useState<boolean>(false);
  const [viewInvoiceData, setViewInvoiceData] = useState<any>(null);
  const [updateInvoiceData, setUpdateInvoiceData] = useState<PaymentsEntity>(undefined);
  const { userRole } = useAppSelector((state) => state.session);

  // context
  const {
    taxYearInterviewId,
    progressStatusId,
    paymentsCompletedAt,
    dispatch,
  } = useContext(JobContext);

  const { showModal, accessObject } = useUpgradeModal();

  // graphql
  // get stripe information
  const { data: currentStripeInformation, loading: loadingStripeInformation } = useCurrentStripeInformationQuery({
    onError: (error) => {
      console.log('error fetching stripe information:', error);
    },
  });
  const stripeInformation = currentStripeInformation?.CurrentStripeInformation;
  // get payments
  const { data: paymentsData, loading: loadingPayments } = useGetPaymentsByTaxYearInterviewIdQuery({
    variables: {
      taxYearInterviewId,
      includeDeleted: true,
    },
  });
  const payments = paymentsData?.paymentsByTaxYearInterviewId;
  // send payment reminder
  const [sendInvoiceReminder, { loading: loadingSendPaymentReminder }] = useSendInvoiceReminderMutation({
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: () => {
      message.success('Payment reminder sent');
    },
    refetchQueries: [
      'GetPaymentsByTaxYearInterviewId',
    ],
  });
  // update payments
  const [updatePayment, { loading: loadingCancelInvoice }] = useUpdatePaymentMutation({
    onError: (error) => {
      message.error(error.message);
    },
    refetchQueries: [
      {
        query: GetPaymentsByTaxYearInterviewIdDocument,
        variables: {
          taxYearInterviewId,
        },
      },
    ],
  });
  // delete payment
  const [deletePayment, { loading: loadingDeleteInvoice }] = useDeletePaymentWithInvoicesMutation({
    onError: (error) => {
      message.error(error.message);
    },
    refetchQueries: [
      'GetPaymentsByTaxYearInterviewId',
    ],
  });

  const [upsertJobTracking, { loading: loadingUpsertJobTracking }] = useUpsertJobTrackingByTaxYearInterviewIdMutation({
    onError: (error) => {
      message.error(error.message);
    },
  });

  const createInvoiceButtonOnClick = () => {
    setUpdateInvoiceData(undefined);
    setIsModalVisible(true);

    trackCustomerIoEvent(CUSTOMER_IO_EVENTS.MODAL_SHOW, {
      'Modal Title': 'Create invoice',
      Source: 'Click on button',
      url: window.location.href,
    });
  };

  const handlePaymentsComplete = () => {
    const today = new Date();
    upsertJobTracking({
      variables: {
        createJobTrackingInput: {
          taxYearInterviewId,
          paymentsCompletedAt: today,
        },
      },
      onCompleted: () => {
        message.success('Payments marked completed.');
        dispatch({ type: 'UPDATE', payload: { paymentsCompletedAt: today } });
      },
    });
  };

  const handlePaymentsNotComplete = () => {
    upsertJobTracking({
      variables: {
        createJobTrackingInput: {
          taxYearInterviewId,
          paymentsCompletedAt: null,
        },
      },
      onCompleted: () => {
        message.success('Payments marked not complete.');
        dispatch({ type: 'UPDATE', payload: { paymentsCompletedAt: null } });
      },
      refetchQueries: [
        {
          query: GetClientsDocument,
        },
        {
          query: GetClientsByProgressStatusIdDocument,
          variables: {
            progressStatusId,
          },
        },
      ],
    });
  };

  const renderStatus = (row: PaymentsEntity) => {
    const { completedAt, deletedAt, status } = row;
    // if it's marked deleted, then it's cancelled
    if (deletedAt) {
      return (
        <Badge status="error" text="Cancelled" />
      );
    }
    // I do processing first because ACH payments are marked complete while still in processing phase
    if (status === 'processing') {
      return (
        <Badge status="processing" text="Processing" />
      );
    }
    // if it's not processing and it it's marked complete, than it's completed and successful
    if (completedAt) {
      return (
        <Badge status="success" text="Completed" />
      );
    }
    return (
      <Badge status="default" text="Requested" />
    );
  };

  const menuItemOnClick = (menuItem: any, tableRow: PaymentsEntity) => {
    switch (menuItem.key) {
      case '1':
        sendInvoiceReminder({
          variables: {
            paymentId: tableRow.id,
          },
        });
        break;
      case '2':
        // edit invoice
        setUpdateInvoiceData(tableRow);
        setIsModalVisible(true);

        trackCustomerIoEvent(CUSTOMER_IO_EVENTS.MODAL_SHOW, {
          'Modal Title': 'Update invoice',
          Source: 'Click on menu',
          url: window.location.href,
        });

        break;

      case '3':
        // mark as paid
        updatePayment({
          variables: {
            updatePaymentsInput: {
              id: tableRow.id,
              paidOffline: true,
              completedAt: new Date().toISOString(),
            },
          },
          onCompleted: () => {
            message.success('Invoice marked as paid.');
          },
        });
        break;

      case '4':
        // cancel invoice
        updatePayment({
          variables: {
            updatePaymentsInput: {
              id: tableRow.id,
              deletedAt: new Date().toISOString(),
            },
          },
          onCompleted: () => {
            message.success('Invoice cancelled.');
          },
        });
        break;
      case '5':
        // delete invoice
        deletePayment({
          variables: {
            paymentId: tableRow.id,
          },
          onCompleted: () => {
            message.success('Invoice deleted.');
          },
        });
        break;
      default:
        break;
    }
  };

  const getMenuItems = (payment: PaymentsEntity) => {
    const { deletedAt } = payment;

    return (
      <Menu onClick={(menuItem) => { menuItemOnClick(menuItem, payment); }}>
        {deletedAt
          ? (
            <Menu.Item key={5} danger>
              <span>
                <Space>
                  <DeleteOutlined />
                  Delete Invoice
                </Space>
              </span>
            </Menu.Item>
          )
          : (
            <>
              <Menu.Item key={1}>
                <span>
                  <Space>
                    <SendOutlined />
                    Send Invoice reminder
                  </Space>
                </span>
              </Menu.Item>
              <Menu.Item key={2}>
                <span>
                  <Space>
                    <EditOutlined />
                    Edit Invoice
                  </Space>
                </span>
              </Menu.Item>
              <Menu.Item key={3}>
                <span>
                  <Space>
                    <DownloadOutlined />
                    Make as paid
                  </Space>
                </span>
              </Menu.Item>
              <Menu.Item key={4} danger>
                <span>
                  <Space>
                    <DeleteOutlined />
                    Cancel Invoice
                  </Space>
                </span>
              </Menu.Item>
            </>
          )}
      </Menu>
    );
  };

  const getActionColumns = (payment: PaymentsEntity) => {
    const { completedAt } = payment;
    if (completedAt || (payment.deletedAt && userRole !== 'TAX_PREPARER')) {
      return (
        <Button
          size="small"
          onClick={() => {
            setIsViewInvoiceModalVisible(true);
            setViewInvoiceData(payment);
          }}
          icon={<EyeOutlined />}
        >
          View
        </Button>
      );
    }
    return (
      <Dropdown.Button
        icon={<ArrowDownOutlined />}
        trigger={['click']}
        size="small"
        onClick={() => {
          setIsViewInvoiceModalVisible(true);
          setViewInvoiceData(payment);
        }}
        overlay={getMenuItems(payment)}
        loading={loadingSendPaymentReminder}
      >
        View
      </Dropdown.Button>
    );
  };

  const columns = [
    {
      title: 'Total',
      dataIndex: 'totalDollarAmount',
      key: 'totalDollarAmount',
      sorter: (a: PaymentsEntity, b: PaymentsEntity) => a.totalDollarAmount - b.totalDollarAmount,
      render: (text: number, row: PaymentsEntity) => (
        <Button type="dashed" icon={<DollarOutlined />}>
          <span style={{ textDecoration: row.deletedAt ? 'line-through' : 'none' }}>
            {text.toFixed(2)}
          </span>
        </Button>
      ),
      // fixed: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a: PaymentsEntity, b: PaymentsEntity) => {
        // Assign a sort order value based on the status
        const getStatusSortValue = (row: PaymentsEntity) => {
          if (row.status === 'processing') return 2;
          if (row.completedAt) return 3; // Assumes that completedAt being truthy means the status is 'Completed'
          return 1; // Default case is 'Requested'
        };

        const statusA = getStatusSortValue(a);
        const statusB = getStatusSortValue(b);

        return statusA - statusB; // Sort by the assigned status order values
      },
      render: (text: any, row: any) => (
        <div style={{ minWidth: 100 }}>
          {renderStatus(row)}
        </div>
      ),
      // fixed: true,
    },
    {
      title: 'Date Requested',
      dataIndex: 'requestAt',
      key: 'requestAt',
      sorter: (a: any, b: any) => new Date(a.requestAt).getTime() - new Date(b.requestAt).getTime(),
      render: (text) => (
        <div style={{ minWidth: 80 }}>
          {text ? new Date(text).toDateString() : ''}
        </div>
      ),
    },
    {
      title: 'Paid On',
      dataIndex: 'completedAt',
      key: 'completedAt',
      sorter: (a: any, b: any) => new Date(a.completedAt).getTime() - new Date(b.completedAt).getTime(),
      // render: (text: any) => text && new Date(text).toDateString(),
      render: (text) => (
        <div style={{ minWidth: 80 }}>
          {text ? new Date(text).toDateString() : ''}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, row: any) => getActionColumns(row),
    },
  ];

  const data = [
    {
      key: 1,
      id: 1,
      amountPaid: '1.00',
      paidOn: 'January 25th 2021 at 8:20 am',
    },
    {
      key: 2,
      id: 2,
      amountPaid: '1.00',
      paidOn: 'January 25th 2021 at 8:20 am',
    },
    {
      key: 3,
      id: 3,
      amountPaid: '1.00',
      paidOn: 'January 25th 2021 at 8:20 am',
    },
    {
      key: 4,
      id: 4,
      amountPaid: '1.00',
      paidOn: 'January 25th 2021 at 8:20 am',
    },
    {
      key: 5,
      id: 5,
      amountPaid: '1.00',
      paidOn: 'January 25th 2021 at 8:20 am',
    },
    {
      key: 6,
      id: 6,
      amountPaid: '1.00',
      paidOn: 'January 25th 2021 at 8:20 am',
    },
  ];

  // eslint-disable-next-line react/no-unstable-nested-components
  function NoDataExtra() {
    if (stripeInformation?.connectAccountId) {
      return (
        <Row justify="center" style={{ marginTop: 25 }}>
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            onClick={createInvoiceButtonOnClick}
          >
            Create your first invoice
          </Button>
        </Row>
      );
    }
    return (
      <Tooltip title="You must connect your Stripe account to create payments. See Client Invoicing in settings.">
        <span>
          {' '}
          {/* required for toolitp to work... */}
          <Button
            style={{ pointerEvents: 'none' }} // Also, required for toolitp to work...
            type="primary"
            shape="round"
            onClick={createInvoiceButtonOnClick}
            icon={<PlusOutlined />}
            iconDirection="left"
            disabled
          >
            Create your first invoice
          </Button>
        </span>
      </Tooltip>
    );
  }

  return (
    <div style={{ marginLeft: 5 }}>
      <Typography>
        <Paragraph style={{ marginTop: 30 }}>
          Payment requets are sent to your client via email. Clients can pay
          securely through&nbsp;
          <Link href="http://www.stripe.com" target="_blank">
            Stripe
          </Link>
          , and funds typically arrive in your bank account within 3 business
          days after payment.
          {!stripeInformation?.connectAccountId && (
            <>
              {' '}
              You can get set up with Stripe in
              {' '}
              <RouterLink to="/settings/setup-invoicing" target="_blank">
                Client Invoicing
              </RouterLink>
              {' '}
              settings.
            </>
          )}
        </Paragraph>
      </Typography>
      <Row gutter={[20, 10]} style={{ marginTop: 10, marginBottom: 15 }}>
        <Col>
          {stripeInformation?.connectAccountId
            ? (
              <Button
                type="default"
                onClick={createInvoiceButtonOnClick}
                icon={<PlusOutlined />}
                iconDirection="left"
              >
                Create Payment
              </Button>
            )
            : (
              <Tooltip title="You must connect your Stripe account to create payments. See Client Invoicing in settings.">
                <span>
                  {' '}
                  {/* required for toolitp to work... */}
                  <Button
                    style={{ pointerEvents: 'none' }} // Also, required for toolitp to work...
                    type="default"
                    onClick={createInvoiceButtonOnClick}
                    icon={<PlusOutlined />}
                    iconDirection="left"
                    disabled
                  >
                    Create Payment
                  </Button>
                </span>
              </Tooltip>
            )}
        </Col>
        <Col>
          {!paymentsCompletedAt ? (
            <Button
              type="primary"
              icon={<CheckCircleOutlined />}
              iconDirection="left"
              onClick={handlePaymentsComplete}
              loading={loadingUpsertJobTracking}
            >
              Mark Payments Complete
            </Button>
          ) : (
            <Button
              type="default"
              icon={<UndoOutlined />}
              iconDirection="left"
              onClick={handlePaymentsNotComplete}
              loading={loadingUpsertJobTracking}
            >
              Mark Payments Not Complete
            </Button>
          )}
        </Col>
      </Row>
      {payments?.length > 0 ? (
        <Table
          columns={columns}
          dataSource={payments}
          pagination={{ pageSize: 50 }}
          scroll={{ x: true, y: 320 }}
          noDataProps={{
            title: 'No payments yet',
            extra: <NoDataExtra />,
          }}
          loading={loadingPayments || loadingCancelInvoice || loadingDeleteInvoice}
          rowKey={(row) => row.id}
        />
      )
        : (
          <Empty
            description="No payments yet"
          >
            {/* <NoDataExtra /> */}
          </Empty>
        )}
      <ViewInvoiceModal
        paymentData={viewInvoiceData}
        isModalVisible={isViewInvoiceModalVisible}
        setIsModalVisible={setIsViewInvoiceModalVisible}
      />
      <CreateOrUpdateInvoiceModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        paymentData={updateInvoiceData}
      />
    </div>
  );
}

export default InvoicesStatusTracking;
