.onBoardingListContainer {
  padding: 0 2.5rem;
}

@media only screen and (max-width: 576px) {
  .onBoardingListContainer {
    padding: 0;
  }
}

.leftIcons {
  display: flex;
  align-items: center;
}

.leftIcons span {
  margin-right: 16px;
}

.leftIcons :last-child {
  margin-right: 0;
}

.titleList {
  margin: 0;
  padding: 1rem 0;
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  font-size: 16px;
  font-family: sans-serif;

  /* font-weight: bold; */
}

.btn {
  width: 7.5em;
  justify-content: center;
}

.btnComplete {
  width: 7.5em;
  justify-content: center;
  color: #00000040 !important;
  border-color: #00000040 !important;
}

.rowEmail {
  display: flex;
  align-items: baseline;
}

.hasError {
  border-color: #ff4d4f;
}

.secondSendButton {
  margin-top: 10px;
}

@media only screen and (min-width: 840px) {
  .secondSendButton {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .rowEmail {
    flex-direction: column;
  }
}
