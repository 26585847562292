import {
  Dropdown, Icons, Menu, message, Tooltip,
} from 'taxaroo-ui';
import { TaskEntity, TaskType } from '~src/graphql';
import { useUpsertJobTrackingByTaxYearInterviewIdMutation } from '~src/graphql/mutations/jobTracking';

const {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  Circle,
  LoadingOutlined,
  ClockCircleOutlined,
} = Icons;

function Interview(props: any) {
  const { record } = props;
  const { interviewCompletedAt, tasks, taxYearInterviewId } = record;
  const interviewTasks = tasks?.filter((task: TaskEntity) => task.taskType === TaskType.Interview);
  const inCompleteInterviewTasks = interviewTasks?.filter((task: TaskEntity) => !task.completedAt);

  // graphql
  const [upsertJobTracking, { loading: loadingUpsertJobTracking }] = useUpsertJobTrackingByTaxYearInterviewIdMutation({
    onError: (error) => {
      message.error(error.message);
    },
  });

  const markComplete = async () => {
    upsertJobTracking({
      variables: {
        createJobTrackingInput: {
          taxYearInterviewId,
          interviewCompletedAt: new Date(),
        },
      },
      onCompleted: () => {
        message.success('Marked as complete');
      },
    });
  };

  const markNotComplete = async () => {
    upsertJobTracking({
      variables: {
        createJobTrackingInput: {
          taxYearInterviewId,
          interviewCompletedAt: null,
        },
      },
      onCompleted: () => {
        message.success('Marked as not complete');
      },
    });
  };

  const markReadMenu = (
    <Menu>
      <Menu.Item key="mark-read" onClick={markNotComplete}>
        Mark as not complete
      </Menu.Item>
    </Menu>
  );
  const markUnreadMenu = (
    <Menu>
      <Menu.Item key="mark-unread" onClick={markComplete}>
        Mark as complete
      </Menu.Item>
    </Menu>
  );

  if (loadingUpsertJobTracking) {
    return <LoadingOutlined className="status-icon" />;
  }
  if (interviewCompletedAt) {
    return (
      <Dropdown overlay={markReadMenu} trigger={['click']}>
        <Tooltip title="Interview Completed!">
          <CheckCircleOutlined className="status-icon" style={{ color: 'green' }} />
        </Tooltip>
      </Dropdown>
    );
  }
  if (interviewTasks?.length > 0 && inCompleteInterviewTasks?.length === 0) {
    return (
      <Dropdown overlay={markUnreadMenu} trigger={['click']}>
        <Tooltip title="Client updated info">
          <ExclamationCircleOutlined className="status-icon" style={{ color: 'orange' }} />
        </Tooltip>
      </Dropdown>
    );
  }
  if (interviewTasks?.length > 0 && inCompleteInterviewTasks?.length > 0) {
    return (
      <Dropdown overlay={markUnreadMenu} trigger={['click']}>
        <Tooltip title="Waiting for client response">
          <ClockCircleOutlined className="status-icon" style={{ color: 'orange' }} />
        </Tooltip>
      </Dropdown>
    );
  }
  return (
    <Dropdown overlay={markUnreadMenu} trigger={['click']}>
      <Tooltip title="Not started">
        <span role="img" tabIndex={-1} className="anticon status-icon ant-dropdown-trigger">
          <Circle width="1em" height="1em" className="status-icon" style={{ color: 'gray' }} />
        </span>
      </Tooltip>
    </Dropdown>
  );
}

export default Interview;
