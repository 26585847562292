import { FC, CSSProperties } from 'react';
import {
  Tag, Icons, Row, Typography,
} from 'taxaroo-ui';
import { useDrag } from 'react-dnd';
import { FieldType } from '~src/graphql';
import SignatureIcon from '../../../../assets/icons/signature.svg';

const { CalendarOutlined, EditOutlined } = Icons;
const { Text } = Typography;

const style: CSSProperties = {
  marginBottom: '1.5rem',
  cursor: 'move',
};

interface SignatureFieldDraggableProps {
  name: string
  fieldType: FieldType
}

interface DragItem {
  name: string
  fieldType: FieldType
}

// this is for dragging on a new field
const SignatureFieldDraggable: FC<SignatureFieldDraggableProps> = ({ name, fieldType }) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'SIGNATURE_FIELD',
      item: { name, fieldType },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<DragItem>();
        if (item && dropResult) {
          // alert(`You dropped ${item.name} into ${dropResult.name}!`)
          console.log('Dropped in ', item, ' Container: ', dropResult);
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [name, fieldType],
  );

  const opacity = isDragging ? 0.4 : 1;

  return (
    <Tag
      style={{ ...style, opacity, width: '100%' }}
      ref={drag}
      color="success"
    >
      <Row style={{ flexFlow: 'nowrap' }} justify="center">
        {fieldType === FieldType.Signature && (
          <SignatureIcon style={{
            height: '18px', width: '18px', minWidth: '18px', marginRight: '5px', fill: '#52c41a',
          }}
          />
        )}

        {fieldType === FieldType.Date && (
          <CalendarOutlined style={{
            height: '14px', width: '14px', minWidth: '14px', margin: '3px 5px 0 0',
          }}
          />
        )}

        {fieldType === FieldType.Text && (
          <EditOutlined style={{
            height: '14px', width: '14px', minWidth: '14px', margin: '3px 5px 0 0',
          }}
          />
        )}
        <Text style={{ color: '#52c41a' }}>{name}</Text>
      </Row>
    </Tag>
  );
};

export default SignatureFieldDraggable;
