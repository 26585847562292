/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import * as Types from '../../graphql';

type ProgressStatuses = {
  id: string,
  status: string,
  underlyingStatus: Types.TaxYearStatus,
  index: number,
};

// Define a type for the slice state
interface SessionState {
  email: string,
  accessToken?: string;
  previousAccessToken?: string;
  id?: string;
  userId?: string;
  firstName?: string;
  lastName?: string;
  birthday?: string;
  ssn?: string;
  profilePhoto?: string;
  firmAccountId?: string;
  userInformationId?: string;
  languageId: string;
  userRole: string;
  progressStatuses?: Array<{ id: string, status: string, underlyingStatus: Types.TaxYearStatus, index: number }>;
  languages: Array<Types.LanguagesEntity>;
  firmName: string;
  firmAddress: string;
  firmPhoneNumber: string;
  firmCreatedAt: string;
}

interface SessionPayload {
  email: string;
  accessToken: string;
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  birthday: string;
  ssn: string;
  profilePhoto: string;
  firmAccountId: string;
  userInformationId: string;
  languageId: string;
  userRole: string;
  languages?: Array<Types.LanguagesEntity>;
  progressStatuses?: Array<{ id: string, status: string, underlyingStatus: Types.TaxYearStatus, index: number }>;
  firmName: string;
  firmAddress: string;
  firmPhoneNumber: string;
  firmCreatedAt: string;
}

interface SetAllLanguagesPayload {
  languages: Array<Types.LanguagesEntity>,
}

// Define the initial state using that type
const initialState: SessionState = {
  email: undefined,
  accessToken: undefined,
  previousAccessToken: undefined,
  id: undefined,
  userId: undefined,
  firstName: undefined,
  lastName: undefined,
  birthday: undefined,
  ssn: undefined,
  profilePhoto: undefined,
  firmAccountId: undefined,
  userInformationId: undefined,
  languageId: undefined,
  userRole: undefined,
  progressStatuses: undefined,
  languages: undefined,
  firmName: undefined,
  firmAddress: undefined,
  firmPhoneNumber: undefined,
  firmCreatedAt: undefined,
};

export const sessionSlice = createSlice({
  name: 'session',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setProfilePhoto: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        profilePhoto: action.payload,
      };
    },
    setSession: (state, action: PayloadAction<SessionPayload>) => {
      return {
        ...state,
        email: action.payload.email,
        accessToken: action.payload.accessToken,
        id: action.payload.id,
        userId: action.payload.userId,
        userInformationId: action.payload.userInformationId,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        birthday: action.payload.birthday,
        ssn: action.payload.ssn,
        profilePhoto: action.payload.profilePhoto,
        firmAccountId: action.payload.firmAccountId,
        languageId: action.payload.languageId,
        userRole: action.payload.userRole,
        firmName: action.payload.firmName,
        progressStatuses: action.payload.progressStatuses,
        firmAddress: action.payload.firmAddress,
        firmPhoneNumber: action.payload.firmPhoneNumber,
        firmCreatedAt: action.payload.firmCreatedAt,
      };
    },
    addProgressStatus: (state, action: PayloadAction<ProgressStatuses>) => ({
      ...state,
      progressStatuses: [...state.progressStatuses, action.payload],
    }),
    updateProgressStatus: (state, action: PayloadAction<ProgressStatuses>) => ({
      ...state,
      progressStatuses: state.progressStatuses.map(({ id, ...statusData}) => {
        if (id === action.payload.id) {
          return action.payload;
        }
        return { id, ...statusData };
      })
    }),
    removeProgressStatus: (state, action: PayloadAction<{ id: string }>) => ({
      ...state,
      progressStatuses: state.progressStatuses.filter(({ id }) => {
        if (action.payload.id === id) {
          return false;
        }

        return true;
      })
    }),
    resetSession: () => {
      return initialState;
    },
    setPreviousAccessToken: (state, action: PayloadAction<{ accessToken: string }>) => {
      return {
        ...state,
        previousAccessToken: action.payload.accessToken,
      };
    },
    setAllLanguages: (state, action: PayloadAction<SetAllLanguagesPayload>) => ({
      ...state,
      languages: action.payload.languages
    }),
  },
});

export const { setSession, setProfilePhoto, resetSession, addProgressStatus, updateProgressStatus, removeProgressStatus, setPreviousAccessToken, setAllLanguages } = sessionSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectSession = (state: RootState) => state.session;

export const selectCurrentLanguage = ({ session }: RootState) => session.languages.filter(({ id }) => id === session.languageId)[0];

export default sessionSlice.reducer;
