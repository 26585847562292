import { useEffect, useState } from 'react';
import {
  Form, Card, Input, Button, Row, Grid, Alert, Typography, Modal, message, Spin,
} from 'taxaroo-ui';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '~src/assets/images/taxaroo_logo.svg';
import {
  BusinessInformationEntity, BusinessType, UserEntity, UserFirmAccountEntity,
} from '~src/graphql';
import { trackSignIn } from '~src/components/helpers/customer.io';
import {
  getClientWidth, isMobileView,
} from '~src/components/helpers/screen';
import { useLoginMutation } from '../../graphql/mutations/login';
import { useAppDispatch } from '../../redux/hooks';
import { setSession } from '../../redux/slices/sessionSlice';
import * as styles from './style.module.css';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const getFirmAddress = (businessType: BusinessType, { User, BusinessInformation }: {
  User: UserEntity,
  BusinessInformation: BusinessInformationEntity
}) => {
  if (businessType === 'INDIVIDUAL') {
    const [Address] = User.UserInformation.Address.filter(({ type }) => type === 'PERSONAL');
    if (Address) {
      const {
        lineOne, lineTwo, city, state, zip,
      } = Address;
      return `${lineOne ?? ''}, ${lineTwo ?? ''} ${city}, ${state} ${zip}`;
    }
  }

  const [Address] = BusinessInformation?.Address.filter(({ type }) => type === 'BUSINESS') ?? [];
  if (Address) {
    const {
      lineOne, lineTwo, city, state, zip,
    } = Address;
    return `${lineOne ?? ''}, ${lineTwo ?? ''} ${city}, ${state} ${zip}`;
  }

  return '';
};

const getFirmPhoneNumber = (businessType: BusinessType, { User, BusinessInformation }: {
  User: UserEntity,
  BusinessInformation: BusinessInformationEntity
}) => {
  if (businessType === 'INDIVIDUAL') {
    const [Phone] = User.UserInformation.Phones.filter(({ type }) => type === 'PERSONAL');
    if (Phone) {
      const { value } = Phone;
      return value ?? '';
    }
    return '';
  }

  const [Phone] = BusinessInformation.Phones.filter(({ type }) => type === 'OFFICE');
  if (Phone) {
    const { value } = Phone;
    return value ?? '';
  }

  return '';
};

export const loginAsPreparer = (
  dispatch: any,
  accessToken: string,
  UserFirmAccount: UserFirmAccountEntity,
) => {
  const { FirmAccount } = UserFirmAccount;
  const { businessType, Users: FirmUser, BusinessInformation } = FirmAccount;

  dispatch(setSession({
    email: UserFirmAccount.Users.email,
    accessToken,
    id: UserFirmAccount.Users.UserInformation.id,
    userId: UserFirmAccount.Users.UserInformation.userId,
    userInformationId: UserFirmAccount.Users.UserInformation.id,
    firstName: UserFirmAccount.Users.UserInformation?.firstName,
    lastName: UserFirmAccount.Users.UserInformation?.lastName,
    birthday: UserFirmAccount.Users.UserInformation?.birthday,
    ssn: UserFirmAccount.Users.UserInformation.ssn,
    profilePhoto: UserFirmAccount.Users.UserInformation.profilePhoto,
    firmAccountId: UserFirmAccount.FirmAccount.id,
    languageId: UserFirmAccount.Users.languageId,
    userRole: UserFirmAccount.Roles.description,
    // eslint-disable-next-line
    progressStatuses: UserFirmAccount.FirmAccount.ProgressStatuses?.map(({ status, underlyingStatus, index, id }) => ({ status, underlyingStatus, index, id })) ?? undefined,
    firmName: UserFirmAccount.FirmAccount.name ?? '',
    firmAddress: getFirmAddress(businessType, { User: FirmUser, BusinessInformation }),
    firmPhoneNumber: getFirmPhoneNumber(businessType, {
      User: FirmUser,
      BusinessInformation,
    }),
    firmCreatedAt: UserFirmAccount.FirmAccount.createAt,
  }));
};

function Login() {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [login, { data, loading, error }] = useLoginMutation();
  const [isMfaUsed, setMfaUsed] = useState(false);
  const [errorDescription, setErrorDescription] = useState('');

  const location = useLocation();
  const fromStateSearch = location.state?.from?.search || '';
  const fromStateSearchParams = new URLSearchParams(fromStateSearch);
  const fromStateSearchParamsObject: any = {};

  Array.from(fromStateSearchParams.entries()).forEach(([key, value]) => {
    fromStateSearchParamsObject[key] = value;
  });

  const OnFinish = async (values: { email: string; password: string, code: string }) => {
    try {
      await login({
        variables: {
          loginInput: {
            email: values.email,
            password: values.password,
            code: values.code,
            // selectedFirmAccount: '1', // todo: get real firm account
          },
        },
      });
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    const errorMessage = error?.message;
    const graphqlError = error?.graphQLErrors?.at(0);
    const graphqlErrorName = graphqlError?.name;

    if (errorMessage === 'Not Found') {
      setErrorDescription('Username/email not registered');
    } else if (errorMessage === 'Unauthorized') {
      setErrorDescription('Incorrect password');
    } else if (errorMessage === 'Account Login Suspended') {
      setErrorDescription('Your account has been suspended due to 5 incorrect login attempts. A password reset link has been sent to your email. Please update your password and then log in again.');
    } else if (errorMessage === 'GoogleAuthenticatorCodeMissed') {
      setErrorDescription(''); // not an error
    } else if (graphqlErrorName === 'GoogleAuthenticatorCodeNotValid') {
      setErrorDescription('Incorrect code');
    } else {
      setErrorDescription(errorMessage);
    }

    if (errorMessage === 'GoogleAuthenticatorCodeMissed') {
      setMfaUsed(true);
    }

    if (data) {
      if (data.Login?.UserFirmAccount?.Entity) {
        // * This case is for TAXPAYER role
        const { UserFirmAccount, accessToken } = data.Login;
        const { Users } = UserFirmAccount.Entity;
        const {
          id: userId, email, UserInformation, languageId,
        } = Users;
        const {
          id: userInformationId, firstName, lastName, birthday, ssn, profilePhoto,
        } = UserInformation;
        const { Roles } = UserFirmAccount;
        const {
          id: firmAccountId, ProgressStatuses, name: firmName,
          businessType, Users: FirmUser, BusinessInformation, createAt,
        } = UserFirmAccount.FirmAccount;

        dispatch(setSession({
          ssn,
          email,
          userId,
          lastName,
          birthday,
          firmName: firmName ?? '',
          firstName,
          languageId,
          accessToken,
          profilePhoto,
          firmAccountId,
          userInformationId,
          id: userInformationId,
          firmAddress: getFirmAddress(businessType, { User: FirmUser, BusinessInformation }),
          firmPhoneNumber: getFirmPhoneNumber(businessType, {
            User: FirmUser,
            BusinessInformation,
          }),
          firmCreatedAt: createAt ?? '',
          userRole: Roles.description,
          progressStatuses: ProgressStatuses?.map(
            ({
              status, underlyingStatus, index, id,
            }) => ({
              status, underlyingStatus, index, id,
            }),
          ) ?? undefined,
        }));
        navigate('/clients');
      } else {
        // * This is for Tax Preparer role
        const { UserFirmAccount } = data.Login;
        loginAsPreparer(dispatch, data.Login.accessToken, UserFirmAccount as UserFirmAccountEntity);
        trackSignIn();
        // eslint-disable-next-line
        window['profitwell']('start', { user_email: UserFirmAccount.Users.email });
        navigate('/');
      }
    }
  }, [error, data, navigate, dispatch]);

  const formWidth = isMobileView() ? getClientWidth() * 0.7 : 375;

  return (
    <div className={styles.loginPage}>
      <div className={styles.loginWrapper}>
        <Card
          // elevated
          style={{
            padding: screens.md ? '10px 80px' : '5px',
            maxWidth: '900px',
            width: isMobileView() ? '100%' : undefined,
          }}
        >
          <Row justify="center" style={{ margin: '0 0 60px 0' }}>
            <Logo width="170" height="40" />
          </Row>
          <Row>
            {errorDescription && (
              <Alert
                style={{
                  marginBottom: 15,
                  width: isMobileView() ? '100%' : `${formWidth}px`,
                }}
                type="warning"
                message="Please fix the errors below:"
                description={errorDescription}
              />
            )}
            {fromStateSearchParamsObject?.from === 'taxaroo' && (
              <Alert
                style={{
                  marginBottom: 20,
                  width: isMobileView() ? '100%' : `${formWidth}px`,
                }}
                type="success"
                message={(
                  <Text style={{ color: 'white', fontWeight: 'bolder', fontSize: 19 }}>
                    Congratulations! Your migration to Taxaroo 3.0 is complete.
                  </Text>
                )}
                description={`
                  From now on, you will log in here at ${window.location.origin} and enjoy the enhancements of your trusted software. Thank you for upgrading!
                `}
              />
            )}
          </Row>
          <Form
            name="login"
            layout="vertical"
            onFinish={OnFinish}
            style={{ width: isMobileView() ? '100%' : `${formWidth}px` }}
          >
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please input a valid email!',
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>
            {isMfaUsed && (
            <Form.Item
              label="Two-Factor Authentication Code"
              extra={
                (
                  <div style={{ fontSize: '0.9em', lineHeight: 1.2 }}>
                    To continue, please enter the 6-digit verification code generated by your authenticator app.
                  </div>
                )
              }
              name="code"
              rules={[
                {
                  required: isMfaUsed,
                  message: 'Please input a valid code!',
                },
              ]}
            >
              <Input
                size="large"
              />
            </Form.Item>
            )}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{ width: '100%', justifyContent: 'center' }}
              >
                Login
              </Button>
            </Form.Item>
            <div style={{ textAlign: 'center' }}>
              <Link to="/forgot-password">Forgot Password?</Link>
              <p>
                New to Taxaroo?
                {' '}
                <Link to="/register">Create Account</Link>
              </p>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
}

export default Login;
