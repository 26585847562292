import { useEffect, useState, FC } from 'react';
import axios from 'axios';
import config from '../../helpers/config';
import status from './status';
import { utcToCustomTime } from '../../helpers/utcToCustomTime';
import { getTimeZone } from '../../helpers/time';
import * as styles from './style.module.css';

interface MeetingProps {
  meetingId: number
}

const Meeting: FC<MeetingProps> = ({ meetingId }) => {
  const [event, setEvent] = useState<any>();

  useEffect(() => {
    const getMeeting = async () => {
      const getEvent = await axios({
        method: 'GET',
        url: `${config.serverUrl}/events/meeting/${meetingId}`,
        headers: {
          Authorization: 'Bearer ',
        },
      });
      const appointment = getEvent.data && getEvent.data.length ? getEvent.data[0] : '';
      setEvent(appointment);
    };
    if (!event) {
      getMeeting();
    }
  }, [event, meetingId]);

  if (!event) return null;

  return (
    <div className={styles.container}>
      {!!event && (
        <div key={event.id}>
          {event.title && <h3>{event.title}</h3>}
          <p className={styles.key}>
            <span className={styles.keyTitle}>{'Status: '}</span>
            <span className={styles.value}>{status[event.status]}</span>
          </p>
          { event.type
            && (
            <p className={styles.key}>
              <span className={styles.keyTitle}>
                {'Type: '}
              </span>
              <span className={styles.value} style={{ textTransform: 'capitalize' }}>
                {event.type.toLowerCase()}
              </span>
            </p>
            )}
          <p className={styles.key}>
            <span className={styles.keyTitle}>{'Starts: '}</span>
            <span className={styles.value}>
              {utcToCustomTime(event.startDate, false)}
              {' '}
              (
              {getTimeZone(event.startDate).short}
              )
            </span>
          </p>
          <p className={styles.key}>
            <span className={styles.keyTitle}>{'Ends: '}</span>
            <span className={styles.value}>
              {utcToCustomTime(event.endDate, false)}
              {' '}
              (
              {getTimeZone(event.endDate).short}
              )
            </span>
          </p>
          {event.notes && (
            <p className={styles.key}>
              <span className={styles.keyTitle}>{'Notes: '}</span>
              <span className={styles.value}>{event.notes}</span>
            </p>
          )}
          {
            (!event.type || event.type === 'VIDEO')
            && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.join}
              href={`${config.clientUrl}/videocall/${event.Meetings.name}?token=""`}
            >
              Join
            </a>
            )
          }
        </div>
      )}
    </div>
  );
};

export default Meeting;
