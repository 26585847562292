import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserStepsByUserIdQueryVariables = Types.Exact<{
  userId: Types.Scalars['String'];
}>;


export type GetUserStepsByUserIdQuery = { __typename?: 'Query', userSteps: Array<{ __typename?: 'UserStepEntity', stepId: string, userId: string, completed: boolean, Steps?: { __typename?: 'StepsEntity', id: string, name?: string | null, description?: string | null, order: number, tooltip?: string | null, Buttons?: Array<{ __typename?: 'ButtonsEntity', id: string, label?: string | null, stepId?: string | null, action?: string | null, className?: string | null, color?: string | null, link?: string | null, popConfirm?: string | null }> | null } | null }> };

export type GetFirmOperatingAsQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetFirmOperatingAsQuery = { __typename?: 'Query', FirmAccountById: { __typename?: 'FirmAccountEntity', id: string, businessType: Types.BusinessType } };


export const GetUserStepsByUserIdDocument = gql`
    query GetUserStepsByUserId($userId: String!) {
  userSteps(userId: $userId) {
    stepId
    userId
    completed
    Steps {
      id
      name
      description
      order
      tooltip
      Buttons {
        id
        label
        stepId
        action
        className
        color
        link
        popConfirm
      }
    }
  }
}
    `;

/**
 * __useGetUserStepsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetUserStepsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserStepsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserStepsByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserStepsByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserStepsByUserIdQuery, GetUserStepsByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserStepsByUserIdQuery, GetUserStepsByUserIdQueryVariables>(GetUserStepsByUserIdDocument, options);
      }
export function useGetUserStepsByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserStepsByUserIdQuery, GetUserStepsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserStepsByUserIdQuery, GetUserStepsByUserIdQueryVariables>(GetUserStepsByUserIdDocument, options);
        }
export type GetUserStepsByUserIdQueryHookResult = ReturnType<typeof useGetUserStepsByUserIdQuery>;
export type GetUserStepsByUserIdLazyQueryHookResult = ReturnType<typeof useGetUserStepsByUserIdLazyQuery>;
export type GetUserStepsByUserIdQueryResult = Apollo.QueryResult<GetUserStepsByUserIdQuery, GetUserStepsByUserIdQueryVariables>;
export const GetFirmOperatingAsDocument = gql`
    query GetFirmOperatingAs($id: String!) {
  FirmAccountById(id: $id) {
    id
    businessType
  }
}
    `;

/**
 * __useGetFirmOperatingAsQuery__
 *
 * To run a query within a React component, call `useGetFirmOperatingAsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirmOperatingAsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirmOperatingAsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFirmOperatingAsQuery(baseOptions: Apollo.QueryHookOptions<GetFirmOperatingAsQuery, GetFirmOperatingAsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFirmOperatingAsQuery, GetFirmOperatingAsQueryVariables>(GetFirmOperatingAsDocument, options);
      }
export function useGetFirmOperatingAsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFirmOperatingAsQuery, GetFirmOperatingAsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFirmOperatingAsQuery, GetFirmOperatingAsQueryVariables>(GetFirmOperatingAsDocument, options);
        }
export type GetFirmOperatingAsQueryHookResult = ReturnType<typeof useGetFirmOperatingAsQuery>;
export type GetFirmOperatingAsLazyQueryHookResult = ReturnType<typeof useGetFirmOperatingAsLazyQuery>;
export type GetFirmOperatingAsQueryResult = Apollo.QueryResult<GetFirmOperatingAsQuery, GetFirmOperatingAsQueryVariables>;
export const namedOperations = {
  Query: {
    GetUserStepsByUserId: 'GetUserStepsByUserId',
    GetFirmOperatingAs: 'GetFirmOperatingAs'
  }
}