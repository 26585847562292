.chatEmptyContainer {
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  margin: 0 10px;
  padding-left: 15px;
  box-shadow: 0 5px 5px 5px #f2f2f2;
  padding-right: 15px;
  text-align: center;
}

.chatEmptyContainer img {
  width: 78px;
}

.small {
  color: #565656;
  font-size: 12px;
  font-weight: normal;
  margin-top: 10px;
}

.emptyContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-device-width: 700px) {
  .chatEmptyContainer {
    margin: 10px 0 0;
  }
}
