import { useState, FC } from 'react';
import moment from 'moment';
import {
  Button, Input, Select, Icons, Row, Spin, message as AntDMessage,
} from 'taxaroo-ui';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import { MyEventsDocument, useMyEventTypesQuery } from '~src/graphql/queries/appointments';
import { useCreateEventMutation, usePreparerAvailabilityMutation } from '~src/graphql/mutations/appointments';
import { createDate, parseHoursRange, getTimeZone } from '../../helpers/time';
import { Message } from '../../helpers/forms';
// import config from '../../helpers/config';
import * as styles from './style.module.css';
import './style.css';

const { Option } = Select;
const { TextArea } = Input;
const {
  CalendarOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  HourglassOutlined,
  LeftOutlined,
  LoadingOutlined,
  PhoneOutlined,
  HomeOutlined,
  DesktopOutlined,
  CoffeeOutlined,
} = Icons;

const renderIcon = (saved: any) => {
  if (saved === undefined) return <LoadingOutlined style={{ fontSize: 60 }} />;
  if (saved) return <CheckCircleOutlined className={styles.success} />;
  return <CloseCircleOutlined className={styles.error} />;
};

const getIcon = (type: string) => {
  switch (type) {
    case 'VIDEO':
      return <DesktopOutlined />;
    case 'CALL':
      return <PhoneOutlined />;
    case 'HOME':
      return <HomeOutlined />;
    case 'OFFICE':
      return <CoffeeOutlined />;
    default:
      return <DesktopOutlined />;
  }
};

interface ScheduleCallProps {
  organizerId: string
  userId: string
  closeSchedule: any
  channelName: string
}

const ScheduleCall: FC<ScheduleCallProps> = ({
  organizerId,
  userId,
  closeSchedule,
  channelName,
}) => {
  const [step, setStep] = useState(1);
  const [scheduled, setScheduled] = useState(false);
  const [notes, setNotes] = useState('');
  const [eventType, setEventType] = useState<any>();
  const [day, setDay] = useState(moment());
  const [brackets, setBrackets] = useState([]);
  const [hours, setHours] = useState('');
  const [saved, setSaved] = useState<boolean>();
  const [type, setType] = useState<any>();
  const { t: translation } = useTranslation();

  const timezone = getTimeZone();

  const updateNotes = ({ target: { value } }: any) => {
    setNotes(value);
  };

  const nextStep = () => {
    setStep(step + 1);
  };
  const prevStep = () => {
    setStep(step - 1);
  };

  const selectEventType = (et: any) => {
    setEventType(et);
    nextStep();
  };

  const [preparerAvailability, { loading: loadingPreparerAvailability }] = usePreparerAvailabilityMutation({
    onError: (err) => AntDMessage.error(err.message),
  });

  const selectDay = async (value: any) => {
    const momentDay = moment(value);
    setDay(momentDay);
    nextStep();
    const availability = eventType.EventTypeAvailability.find(
      (ETA: any) => ETA.day === momentDay.format('dddd').toUpperCase(),
    );

    const availabilityResult = await preparerAvailability({
      variables: {
        input: {
          availabilityBrackets:
            availability.EventTypeAvailabilityBracket.map((bracket) => ({ ...bracket, __typename: undefined })),
          day: momentDay.format('YYYY-MM-DD'),
          today: moment().toDate(),
          minNotice: eventType.minNotice,
          beforeBuffer: eventType.beforeBuffer,
          afterBuffer: eventType.afterBuffer,
          timezone: getTimeZone().large,
          preparerId: organizerId,
        },
      },
    });

    const Brackets = availabilityResult?.data.PreparerAvailability.brackets.sort(
      (a, b) => {
        const aName = Number(`${a.startHour}${a.startMinute === 0 ? '00' : a.startMinute}`);
        const bName = Number(`${b.startHour}${b.startMinute === 0 ? '00' : b.startMinute}`);
        if (aName > bName) return 1;
        if (aName < bName) return -1;
        return 0;
      },
    );
    const EventsInDay = availabilityResult?.data.PreparerAvailability.eventsInDay;

    if (Brackets.length > 0 && (!eventType.maxEvents || eventType.maxEvents > EventsInDay)) {
      setBrackets(Brackets);
    } else {
      setStep((old) => old - 1);
      Message(translation('scheduleCall.error'), 'error');
    }
  };

  const selectHours = (value: string) => {
    setHours(value);
    nextStep();
  };

  const selectType = (et: any) => {
    setType(et);
    nextStep();
  };

  const getDate = (bracket: any) => {
    const start = new Date().setHours(bracket.startHour, bracket.startMinute);
    const end = new Date().setHours(bracket.endHour, bracket.endMinute);
    return `${moment(start).format('LT')} - ${moment(end).format('LT')}`;
  };

  const disabledDate = ({ date }: { date: any }) => {
    const actualDate = moment().format();
    const currentDate = moment(date);

    if (currentDate.isBefore(actualDate) && !currentDate.isSame(actualDate, 'day')) return true;
    return (
      eventType.EventTypeAvailability.findIndex(
        (ETAvailability: any) => ETAvailability.day === currentDate.format('dddd').toUpperCase()
          && ETAvailability.EventTypeAvailabilityBracket.length > 0,
      ) < 0
    );
  };

  const [createEventMutation] = useCreateEventMutation({
    onError: (err) => AntDMessage.error(err.message),
    refetchQueries: [{ query: MyEventsDocument }],
    awaitRefetchQueries: true,
  });

  const returnEvent = async () => {
    const hoursDetails = parseHoursRange(hours);
    const { startHour, endHour } = hoursDetails;
    const startDate = createDate(day, startHour.hours, startHour.minutes);
    const endDate = createDate(day, endHour.hours, endHour.minutes);

    setScheduled(true);

    const saveResult = await createEventMutation({
      variables: {
        input: {
          title: channelName,
          type,
          eventTypeId: eventType.id,
          clientId: userId,
          startDate,
          endDate,
          notes,
        },
      },
    });

    if (saveResult.data) {
      setSaved(true);
    } else {
      setSaved(false);
    }
  };

  const {
    data: eventTypes,
    loading: loadingEventTypes,
    error: myEventTypesError,
  } = useMyEventTypesQuery({
    variables: {
      timezone: getTimeZone().large,
    },
  });

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className={styles.containerStep1}>
            <p className={styles.title}>{translation('scheduleCall.scheduleCall')}</p>

            {loadingEventTypes
              ? (
                <Row align="middle" justify="center">
                  <Spin size="large" />
                </Row>
              )
              : eventTypes?.MyEventTypes.map((et: any) => (
                <Button key={et.id} onClick={() => selectEventType(et)}>
                  {et.name}
                </Button>
              ))}
          </div>
        );
      case 2:
        return (
          <div className={styles.containerStep2}>
            <p className={styles.title}>{eventType.name}</p>
            <TextArea
              onChange={updateNotes}
              placeholder={translation('scheduleCall.notes')}
              className={styles.textArea}
              rows={4}
            />
            <Button onClick={nextStep}>
              {translation('scheduleCall.next')}
            </Button>
          </div>
        );
      case 3:
        return (
          <div className={styles.containerStep3}>
            <p className={styles.title}>{eventType.name}</p>
            <Calendar
              className="schedule-inline-cal"
              value={new Date()}
              onChange={selectDay}
              tileDisabled={disabledDate}
            />
          </div>
        );
      case 4:
        return (
          <div className={styles.containerStep4}>
            <p className={styles.title}>{eventType.name}</p>
            <div>
              <div className={styles.contLabel}>
                <CalendarOutlined />
                <p>
                  {`${translation('scheduleCall.date')}: ${day.format(
                    'ddd MMM DD, YYYY',
                  )}`}
                </p>
              </div>
              <div className={styles.contLabel}>
                <ClockCircleOutlined />
                <p>
                  {`${translation('scheduleCall.duration')}: ${eventType.duration
                  } min`}
                </p>
              </div>
            </div>
            <Select
              value={type}
              placeholder="Choose Type"
              style={{ width: '85%', marginTop: 10 }}
              onSelect={selectType}
            >
              <Option value="VIDEO">Video</Option>
              <Option value="CALL">Phone Call</Option>
              <Option value="HOME">Home</Option>
              <Option value="OFFICE">Office</Option>
              <Option value="OTHER">Other place of service</Option>
            </Select>
          </div>
        );
      case 5:
        return (
          <div className={styles.containerStep4}>
            <p className={styles.title}>{eventType.name}</p>
            <div>
              <div className={styles.contLabel}>
                <CalendarOutlined />
                <p style={{ paddingLeft: '10px' }}>
                  {`${translation('scheduleCall.date')}: ${day.format(
                    'ddd MMM DD, YYYY',
                  )}`}
                </p>
              </div>
              <div className={styles.contLabel}>
                <ClockCircleOutlined />
                <p style={{ paddingLeft: '10px' }}>
                  {`${translation('scheduleCall.duration')}: ${eventType.duration
                  } min`}
                </p>
              </div>
              <div className={styles.contLabel}>
                {getIcon(type)}
                <p style={{ textTransform: 'capitalize', paddingLeft: '10px' }}>{type.toLocaleLowerCase()}</p>
              </div>
            </div>
            <div style={{ width: '85%', marginTop: 10 }}>
              <Select
                placeholder={translation('scheduleCall.selectOption')}
                optionFilterProp="children"
                style={{ width: '100%' }}
                onChange={selectHours}
                showSearch
              >
                {brackets.map((opt: any, index) => (
                  <Option key={index} value={getDate(opt)}>
                    {`${getDate(opt)} ${timezone.short}`}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        );
      case 6:
        return (
          <div className={styles.containerStep5}>
            <p className={styles.title}>{eventType.name}</p>
            <div>
              <div className={styles.contLabel}>
                <CalendarOutlined />
                <p style={{ paddingLeft: '10px' }}>
                  {`${translation('scheduleCall.date')}: ${day.format(
                    'ddd MMM DD, YYYY',
                  )}`}
                </p>
              </div>
              <div className={styles.contLabel}>
                <ClockCircleOutlined />
                <p style={{ paddingLeft: '10px' }}>
                  {`${translation('scheduleCall.duration')}: ${eventType.duration
                  } min`}
                </p>
              </div>
              <div className={styles.contLabel}>
                {getIcon(type)}
                <p style={{ textTransform: 'capitalize', paddingLeft: '10px' }}>{type.toLocaleLowerCase()}</p>
              </div>
              <div className={styles.contLabel}>
                <HourglassOutlined />
                <p style={{ paddingLeft: '10px' }}>
                  {`${hours} ${timezone.short}`}
                </p>
              </div>
            </div>
            {!scheduled ? (
              <Button onClick={returnEvent}>
                {translation('scheduleCall.scheduleCall')}
              </Button>
            ) : (
              <>
                {renderIcon(saved)}
                <p className={styles.successtxt}>
                  {translation('scheduleCall.callScheduled')}
                </p>
              </>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      <LeftOutlined
        className={styles.left}
        onClick={() => (step === 1 ? closeSchedule() : prevStep())}
      />
      <CloseOutlined className={styles.right} onClick={closeSchedule} />
      {renderStep()}
    </div>
  );
};

export default ScheduleCall;
