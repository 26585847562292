import moment from 'moment';

export const humanFileSize = (size: number): string => {
  if (size < 0) {
    return 'Coming soon';
  }
  if (size === 0) {
    return '0 B';
  }
  const i = Math.max(Math.floor(Math.log(size) / Math.log(1024)), 1);
  return `${(size / (1024 ** i)).toFixed(2)} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
};

export const humanDate = (date: Date): string => (
  moment(date).format('MMM DD, YYYY hh:mm a')
);
