import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  message,
  Modal,
  Grid,
} from 'taxaroo-ui';
import config from '~src/components/helpers/config';
import LoadingIndicator from '~src/components/atoms/LoadingIndicator';
import PhoneInputField from '~src/components/atoms/PhoneInputField';
import { MailerooResponseEntity, PhoneType } from '~src/graphql';
import { useRegisterMutation } from '~src/graphql/mutations/register';
import { useGetLanguagesQueryQuery } from '~src/graphql/queries/clients';
import { useAppDispatch } from '~src/redux/hooks';
import { setAllLanguages, setSession } from '~src/redux/slices/sessionSlice';
import { trackRegister } from '~src/components/helpers/customer.io';
import PasswordInputWithTooltip from '~src/components/molecules/PasswordInputWithTooltip';
import { useValidateEmailAddressMutation } from '~src/graphql/mutations/auth';
import { useUpsertContactMutation } from '~src/graphql/mutations/activeCampaign';
import * as styles from './styles.module.css';
import './styles.css';

const { useBreakpoint } = Grid;

interface RegisterPageProps {
  free?: boolean;
}
function RegisterForm({ free = false }: RegisterPageProps) {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [disabledNext, setDisabledNext] = useState<boolean>(true);
  const [loadingText, setLoadingText] = useState<string>('Hang tight while we create your account...');
  const [fadeOut, setFadeOut] = useState<boolean>(false);
  const [emailAddressesCache, setEmailAddressesCache] = useState<Map<string, MailerooResponseEntity>>(new Map());

  const [searchParams] = useSearchParams();
  const initialEmail = searchParams.get('email');

  const [registUser, { loading: registerLoading }] = useRegisterMutation();
  const [upsertContactActiveCampaign] = useUpsertContactMutation();

  const { data: languageData } = useGetLanguagesQueryQuery();
  const { languages } = languageData ?? {};

  const [validateEmail] = useValidateEmailAddressMutation();

  const verifyEmailAddress = async (email: string) => {
    const cachedValue = emailAddressesCache.get(email);
    if (cachedValue) {
      return cachedValue;
    }

    const result = await validateEmail({ variables: { email } });
    if (result?.data?.validateEmailAddress) {
      setEmailAddressesCache((curr) => curr.set(email, result.data.validateEmailAddress));
      return result.data.validateEmailAddress;
    }

    return null;
  };

  const validateEmailAddress = async (emailValue: string) => {
    try {
      const result = await verifyEmailAddress(emailValue);
      if (result) {
        const errors = [];
        if (result.domainSuggestion) {
          const emailName = emailValue.split('@')[0];
          errors.push(`Did you mean ${emailName}@${result.domainSuggestion}?`);
        } else if (result.isDisposable) {
          errors.push('Temporary email cannot be used');
        } else if (!result.isFormatValid) {
          errors.push('Format is not valid');
        } else if (!result.isMxFound) {
          errors.push('Domain is not exists');
        }

        form.setFields([{ name: 'email', errors }]);
      }
    } catch (err) {
      console.warn(err.message);
    }
  };

  useEffect(() => {
    if (registerLoading) {
      const timeouts = [];
      timeouts.push(setTimeout(() => {
        setFadeOut(true); // Start fade out
        timeouts.push(setTimeout(() => {
          setLoadingText('Kangaroo\'s are fast, but optimizing your practice takes a moment...');
          setFadeOut(false); // Fade in the new text
        }, 1000)); // this should be the length of your fade out
      }, 5000));
      timeouts.push(setTimeout(() => {
        setFadeOut(true); // Start fade out
        timeouts.push(setTimeout(() => {
          setLoadingText('Just a hop away from completion...');
          setFadeOut(false); // Fade in the new text
        }, 1000)); // this should be the length of your fade out
      }, 11000));

      return () => {
        timeouts.forEach(clearTimeout); // Clear all timeouts when the component unmounts
      };
    }
    return () => null;
  }, [registerLoading]);

  const onSubmit = async (values: any) => {
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      password,
    } = values;

    // validate email address before submit
    await validateEmailAddress(email);

    const emailErrors = form.getFieldError('email');
    if (emailErrors.length) {
      return;
    }

    // register
    try {
      const sessionEntity = (await registUser({
        variables: {
          createUserInput: {
            planId: free ? config.stripeFreePlanPriceId : config?.stripeMonthlyPlanPriceId ?? undefined, // could leave this blank, but it would make it slower
            email,
            password,
            UserInformation: {
              firstName,
              lastName,
              Phones: [{
                value: phoneNumber,
                type: PhoneType.Mobile,
              }],
            },
          },
        },
        onCompleted: () => {
          message.success('Your account was successfully created!');
        },
      })).data?.Register ?? {};

      if (sessionEntity.accessToken?.length) {
        const { accessToken, UserFirmAccount } = sessionEntity;
        const { FirmAccount, Users, Roles } = UserFirmAccount;
        const { businessType, Users: FirmUser, BusinessInformation } = FirmAccount;
        const { UserInformation } = Users;

        dispatch(setSession({
          accessToken,
          id: UserInformation?.id ?? '',
          email: Users?.email ?? '',
          userId: UserInformation?.userId ?? '',
          userInformationId: UserInformation?.id ?? '',
          firstName: UserInformation?.firstName ?? '',
          lastName: UserInformation?.lastName ?? '',
          birthday: UserInformation?.birthday ?? '',
          ssn: UserInformation?.ssn ?? '',
          profilePhoto: UserInformation?.profilePhoto ?? '',
          firmAccountId: FirmAccount?.id ?? '',
          languageId: Users?.languageId ?? '',
          userRole: Roles?.description ?? '',
          progressStatuses: [...(FirmAccount.ProgressStatuses ?? [])].map(({
            id: statusId,
            status,
            underlyingStatus,
            index,
          }) => ({
            status, underlyingStatus, index, id: statusId,
          })),
          firmName: FirmAccount.name ?? '',
          firmAddress: '',
          firmPhoneNumber: phoneNumber,
          firmCreatedAt: FirmAccount.createAt ?? '',
        }));
        dispatch(setAllLanguages({ languages }));

        trackRegister(
          UserInformation?.userId,
          Users?.email,
          UserInformation?.firstName,
          UserInformation?.lastName,
          Roles?.description,
          FirmAccount?.id,
        );

        navigate('/onboarding');
      }
    } catch (error) {
      if (error.message && error.message === 'Error code 1002') {
        message.error('The user with this email is already registered. Please try another email.');
      } else if (error.message && error.message === 'Error code 1005') {
        message.error('Password does not meet the requirements. Please use a stronger password.', 7);
      } else {
        message.error(error.message ?? 'Something went wrong while trying to regist the user');
      }
    }
  };

  // use this to disable the submit button until all fields are filled
  // const handleFormChange = (target) => {
  //   const [{ name: [name], value }] = target;
  //   const hasErrors = form.getFieldsError().some(({ errors }) => errors?.length);
  //   const currentFirstName = form.getFieldValue('firstName');
  //   const currentLastName = form.getFieldValue('lastName');
  //   const currentPhoneNumber = form.getFieldValue('phoneNumber');
  //   const currentEmail = form.getFieldValue('email');
  //   const currentPassword = form.getFieldValue('password');
  //   const currentConfirmPassword = form.getFieldValue('confirmPassword');
  //   const noValues = [
  //     currentFirstName,
  //     currentLastName,
  //     currentPhoneNumber,
  //     currentEmail,
  //     currentPassword,
  //     currentConfirmPassword,
  //   ].filter(
  //     (fieldValue) => (typeof fieldValue === 'string' && !fieldValue?.length) || (typeof fieldValue === 'object' && !fieldValue),
  //   );
  //   setDisabledNext(hasErrors || noValues?.length > 0);
  // };

  const sendUserToActiveCampaign = async () => {
    try {
      const firstName = form.getFieldValue('firstName');
      const lastName = form.getFieldValue('lastName');
      const email = form.getFieldValue('email');
      const phone = form.getFieldValue('phoneNumber');
      if (firstName && lastName && email) {
        await upsertContactActiveCampaign({
          variables: {
            syncContactInput: {
              firstName,
              lastName,
              email,
              phone,
              tag: '146',
            },
          },
        });
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const handleEmailBlur = async () => {
    const emailErrors = form.getFieldError('email');
    if (emailErrors.length) {
      return;
    }

    const emailValue = form.getFieldValue('email');
    if (emailValue) {
      await validateEmailAddress(emailValue);

      const newEmailErrors = form.getFieldError('email');
      if (!newEmailErrors.length) {
        await sendUserToActiveCampaign();
      }
    }
  };

  const handlePhoneBlur = async () => {
    const phoneErrors = form.getFieldError('phoneNumber');
    if (phoneErrors.length) {
      return;
    }

    const phoneValue = form.getFieldValue('phoneNumber');
    if (phoneValue) {
      await sendUserToActiveCampaign();
    }
  };

  return (
    <>
      <Form
        style={{ width: '100%' }}
        layout="vertical"
        form={form}
        initialValues={{
          email: initialEmail || '',
        }}
        onFinish={onSubmit}
        // onFieldsChange={handleFormChange}
        // eslint-disable-next-line no-template-curly-in-string
        validateMessages={{ required: '${label} is required' }}
        key="register-flow"
        className="registerForm"
      >
        <Row gutter={20}>
          <Col span={24} md={12}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[{ required: true }]}
              key="first-name"
              className={styles.registerFormItem}
            >
              <Input
                key="first-name-input"
                autoComplete="given-name"
                type="white"
                placeholder="Enter First name ..."
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ required: true }]}
              key="last-name"
              className={styles.registerFormItem}
            >
              <Input
                autoComplete="family-name"
                type="white"
                placeholder="Enter Last name ..."
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Email is required' },
            { type: 'email', message: 'Invalid email' },
          ]}
          className={styles.registerFormItem}
          style={{ display: initialEmail ? 'none' : 'block' }}
        >
          <Input
            autoComplete="email"
            placeholder="Enter Email ..."
            size="large"
            type="white"
            onBlur={handleEmailBlur}
          />
        </Form.Item>
        <Form.Item
          className={styles.registerFormItem}
          style={{
            flex: 1,
          }}
          name="phoneNumber"
          label="Mobile Number"
          rules={[{ required: true }]}
          key="phone-number"
        >
          <PhoneInputField
            // style={{ background: '#FFFFFF !important' }}
            color="white"
            size="large"
            placeholder="Enter Mobile Number ..."
            onBlur={handlePhoneBlur}
          />
        </Form.Item>
        <Row gutter={20}>
          <Col span={24} md={12}>
            <Form.Item
              name="password"
              label="Create Password"
              rules={[
                {
                  required: true,
                  message: 'The password is required',
                },
              ]}
              className={styles.registerFormItem}
            >
              {/* div around PasswordInputWithTooltip is required for form to work for some reason */}
              <div>
                <PasswordInputWithTooltip />
              </div>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={['password']}
              rules={[{ required: true }, ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
              })]}
              className={styles.registerFormItem}
            >
              <Input.Password
                autoComplete="new-password"
                type="white"
                style={{ fontSize: '14px !important' }}
                placeholder="Enter Confirm Password ..."
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]} justify="space-between" style={{ marginTop: screens.md ? 15 : 0 }}>
          <Col style={{ fontSize: 14, margin: '0 auto', display: 'flex' }}>
            <p style={{ alignSelf: 'center', margin: '0 auto' }}>
              {free ? 'Subscribe for free.' : 'Get started risk free.'}
              {' '}
              <span style={{ fontWeight: 'bold' }}>No credit card required!</span>
            </p>
          </Col>
          <Col style={{ margin: '0 auto' }}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="primary"
                size="large"
                loading={registerLoading}
                htmlType="submit"
                // icon={<RightOutlined />}
                className={styles.registerButton}
              >
                {free ? 'Get Started' : 'Try now'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        visible={registerLoading}
        closable={false}
        footer={null}
        width={500}
        bodyStyle={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '50px 0',
        }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <LoadingIndicator
          size="calc(30vh)"
          type="icon"
          id="billing_portal_redirect"
          speed={1.3}
        />
        <h3
          style={{ marginTop: 20, textAlign: 'center' }}
          className={fadeOut ? styles.fadeOut : styles.fadeIn}
        >
          {loadingText}
        </h3>
      </Modal>
    </>
  );
}

RegisterForm.defaultProps = {
  free: false,
};

export default RegisterForm;
