import ClientInfo from '~src/components/organisms/Client/ClientInfo';
import Jobs from '~src/components/organisms/Client/Jobs';
import ProfileCard from '~src/components/molecules/ProfileCard';
import { useGetInterviewNamesQuery } from '~src/graphql/queries/interview';
import { TaxpayerProvider } from '~src/components/providers/taxpayer-provider';
import { useState } from 'react';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';

function Divider() {
  return <div style={{ height: '30px' }} />;
}

function Client() {
  const { data: interviewsData } = useGetInterviewNamesQuery();
  const [initialActiveKeys, setInitialActiveKeys] = useState<string[]>(['client-info']);
  const [printableJob, setPrintableJob] = useState<string>('');

  const printInterview = (taxYearInterviewId: string) => {
    setInitialActiveKeys(['client-info', 'notes']);
    setPrintableJob(taxYearInterviewId);
    setTimeout(() => window.print(), 500);

    trackCustomerIoEvent(CUSTOMER_IO_EVENTS.CLICK, {
      Title: 'Print interview',
      Source: 'Button click',
      url: window.location.href,
    });
  };

  // action after preview closed
  window.addEventListener('afterprint', () => {
    setInitialActiveKeys([]);
    setPrintableJob('');
  });

  return (
    <TaxpayerProvider>
      <ProfileCard
        interviews={interviewsData?.interviewNames ?? []}
      />
      <Divider />
      <ClientInfo initialActiveKeys={initialActiveKeys} />
      <Divider />
      <Jobs
        interviews={interviewsData?.interviewNames ?? []}
        printInterview={printInterview}
        printableJob={printableJob}
      />
    </TaxpayerProvider>
  );
}

export default Client;
