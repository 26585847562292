import { useEffect, useState, FC } from 'react';
import {
  TaxarooButton, Dropdown, Input, Menu, Upload, message as antdMessage, Icons, Spin,
} from 'taxaroo-ui';
import { useTranslation } from 'react-i18next';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import { useCreateMessageWithFileMutation } from '~src/graphql/mutations/messaging';
import { useAppSelector } from '~src/redux/hooks';
import { selectCurrentChannel } from '~src/redux/slices/chatSlice';
import { selectSession } from '~src/redux/slices/sessionSlice';
import { useGetTaxYearInterviewForChannelLazyQuery } from '~src/graphql/queries/taxYearInterview';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import OutsideAlerter from '../../helpers/OutSiderAlerter';
import * as styles from './style.module.css';

const {
  PaperClipOutlined, SendOutlined, SmileOutlined, WechatOutlined,
} = Icons;

interface ChatInputAdminProps {
  onSendMessage: any
  clientData: any,
  loadingSendMessage: boolean;
}

const MAX_ATTACHMENT_SIZE_MB = 5;
const MAX_ATTACHMENT_SIZE_B = MAX_ATTACHMENT_SIZE_MB * 1024 * 1024;

const ChatInputAdmin: FC<ChatInputAdminProps> = ({ onSendMessage, clientData, loadingSendMessage }) => {
  const [val, setValue] = useState('');
  const [uploadingStatus, setUploadingStatus] = useState(false);
  const [emojiVisible, setEmojiVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [openchat, setOpenchat] = useState(true);
  const [projects, setProjects] = useState([]);
  const [sendMessageWithFile] = useCreateMessageWithFileMutation();
  const { userId } = useAppSelector(selectSession);
  const { ChannelMembers, id: channelId } = useAppSelector(selectCurrentChannel);
  const channelUser = ChannelMembers.find((channel) => channel.userId !== userId);
  const [
    getTaxYearInterview,
    {
      loading: loadingTaxYearInterview,
    },
  ] = useGetTaxYearInterviewForChannelLazyQuery({
    variables: { channelUserId: channelUser.userId },
  });
  const [uploadPercent, setUploadPercent] = useState<number>(0);

  const { t: translation, i18n }: {t: any, i18n: any} = useTranslation();

  const callProjects = async () => {
    const data = await getTaxYearInterview();
    setProjects(data.data.GetTaxYearInterviewForChannel);
  };

  useEffect(() => {
    if (clientData) {
      callProjects();
      setPhone(clientData.Phones[0]?.value || null);
    }
  }, [clientData]);

  const uploadPros = {
    name: 'file',
    onChange(data: any) {
      const info = data;

      if (info.file.status === 'uploading') {
        if (!uploadingStatus) {
          setUploadingStatus(true);
        }
        if (info.event) {
          setUploadPercent(info.event.percent);
        }
      }
      if (info.file.status === 'done') {
        setUploadingStatus(false);
        setUploadPercent(0);
        info.file.s3key = info.file.originFileObj.s3key;
        onSendMessage({ text: undefined, phone: undefined, attachment: JSON.stringify(info.file) });
        antdMessage.success({
          content: `${info.file.name} ${translation('chat.chatInput.fileUploaded')}`,
          duration: 3,
          className: 'custom-alert-message-success',
          style: {
            marginTop: '38px',
            textAlign: 'center',
            left: '50%',
            position: 'absolute',
            transform: 'translateX(-35%)',
          },
        });
      } else if (info.file.status === 'error') {
        antdMessage.error(`${info.file.name} ${translation('chat.chatInput.fileNotUploaded')}`);
      }
    },
  };

  const sendMessage = async () => {
    if (openchat) {
      onSendMessage({ text: val, phone: undefined, attachment: undefined });

      trackCustomerIoEvent(CUSTOMER_IO_EVENTS.CLICK, {
        Title: 'Send chat message (text)',
        Source: 'Button click',
        url: window.location.href,
      });
    } else {
      onSendMessage({ text: message, phone, attachment: undefined });

      trackCustomerIoEvent(CUSTOMER_IO_EVENTS.CLICK, {
        Title: 'Send chat message (SMS)',
        Source: 'Button click',
        url: window.location.href,
      });
    }

    setValue('');
  };

  const getBase64 = (file: File) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
    });
  };

  const uploadFile = async (options: any, projectId: string) => {
    if (options.file.size > MAX_ATTACHMENT_SIZE_B) {
      antdMessage.error(`
        The size of the uploaded file exceeds the maximum size of ${MAX_ATTACHMENT_SIZE_MB}MB.
        Please try uploading a different file.
      `);
      setUploadingStatus(false);
      return;
    }

    setUploadingStatus(true);

    const resultBase64 = await getBase64(options.file);

    const form = {
      channelUserId: channelUser.userId,
      taxYearInterviewId: projectId,
      folder: 'client',
      appendName: true,
      file: {
        name: options.file.name,
        type: options.file.type,
        size: options.file.size,
        blob: resultBase64 as string,
      },
    };

    sendMessageWithFile({
      variables: {
        createMessageWithFileInput: form,
      },
    }).then((response) => {
      const { s3Key } = response.data.createMessageWithFile;
      options.file.s3key = s3Key; // eslint-disable-line no-param-reassign
      antdMessage.success({
        content: `${options.file.name} ${translation('chat.chatInput.fileUploaded')}`,
        duration: 3,
        className: 'custom-alert-message-success',
        style: {
          marginTop: '38px',
          textAlign: 'center',
          left: '50%',
          position: 'absolute',
          transform: 'translateX(-35%)',
        },
      });

      trackCustomerIoEvent(CUSTOMER_IO_EVENTS.CLICK, {
        Title: 'Send chat message (attachment)',
        Source: 'Button click',
        url: window.location.href,
      });

      options.onSuccess({}, options.file);
    }).catch(() => {
      antdMessage.error(`${options.file.name} ${translation('chat.chatInput.fileNotUploaded')}`);
    }).finally(() => {
      setUploadingStatus(false);
    });
  };

  const sendMessageSms = async () => {
    setValue(message);
    sendMessage();
    setMessage('');
  };

  const { name, onChange } = uploadPros;

  const beforeUpload = (file: File) => {
    const { size } = file;
    const maxSize = (1024 * 1024) * 25; // 25MB

    if (size > maxSize) {
      antdMessage.error('File size exceeds 25MB');
      return false;
    }

    return true;
  };

  const projectsMenu = () => (
    <Menu>
      <Menu.Item style={{ fontWeight: 'bolder' }} disabled key={0}>
        {translation('chat.chatInput.selectProject')}
      </Menu.Item>
      {projects.map((p) => (
        <Menu.Item key={p.id}>
          <Upload
            name={name}
            onChange={onChange}
            showUploadList={false}
            disabled={uploadingStatus}
            multiple={false}
            beforeUpload={beforeUpload}
            customRequest={(options) => uploadFile(options, p.id)}
          >
            {`${p.TaxYear.year} ${p.Interviews.name}`}
          </Upload>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className={styles.chatInputContainer}>
      {emojiVisible && (
        <OutsideAlerter onOutside={() => setEmojiVisible(false)}>
          <Picker
            set="apple"
            showPreview={false}
            showSkinTones={false}
            style={{
              width: '100%',
              maxHeight: '288px',
              overflowY: 'auto',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
            }}
            sheetSize={32}
            onSelect={(emoji: any) => {
              if (openchat) {
                setValue(`${val} ${emoji.native} `);
                setMessage(`${message} `);
              } else {
                setValue(`${val} `);
                setMessage(`${message} ${emoji.native} `);
              }
            }}
            i18n={i18n.getDataByLanguage(i18n.language).emojiMart}
          />
        </OutsideAlerter>
      )}

      <div className={styles.containerInput}>
        {openchat
          ? (
            <Dropdown overlay={projectsMenu()} trigger={['click']} placement="topLeft">
              <TaxarooButton
                className={`${styles.clip} ant-dropdown-link`}
                type="default"
                shape="circle"
                size="middle"
                onClick={(e: any) => e.preventDefault()}
              >
                {!uploadingStatus && !loadingTaxYearInterview ? (
                  <PaperClipOutlined />
                ) : (
                  <Spin size="small" style={{ marginRight: 8 }} />
                )}
              </TaxarooButton>
            </Dropdown>
          )
          : (
            <Dropdown overlay={projectsMenu()} trigger={[]} placement="topLeft">
              <TaxarooButton
                className={`${styles.clipsms} ant-dropdown-link`}
                type="default"
                shape="circle"
                size="middle"
                onClick={(e) => e.preventDefault()}
              >
                {!uploadingStatus && !loadingTaxYearInterview ? (
                  <PaperClipOutlined />
                ) : (
                  <Spin size="small" style={{ marginRight: 8 }} />
                )}
              </TaxarooButton>
            </Dropdown>
          )}

        {Boolean(phone) && (
          <div className={styles.dropup}>
            <WechatOutlined
              className={styles.dropbtn}
            />
            <div className={styles.dropupcontent}>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
              <p
                onClick={() => { setOpenchat(false); }}
              >
                SMS
              </p>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
              <p
                onClick={() => { setOpenchat(true); }}
              >
                Email
              </p>
            </div>
          </div>
        )}

        {openchat ? (
          <Input.TextArea
            className={styles.input}
            placeholder="Send Message via Email / Chat"
            value={val}
            // onPressEnter={sendMessage}
            onChange={(e: any) => setValue(e.target.value)}
            autoComplete="off"
            autoSize={{ minRows: 1, maxRows: 6 }}
          />
        ) : (
          <Input.TextArea
            className={styles.input}
            placeholder="Send Message via SMS"
            value={message}
            // onPressEnter={sendMessageSms}
            onChange={(e: any) => setMessage(e.target.value)}
            autoComplete="off"
            autoSize={{ minRows: 1, maxRows: 6 }}
          />
        )}

        <TaxarooButton
          className={styles.clip}
          type="default"
          shape="circle"
          icon={<SmileOutlined />}
          size="middle"
          onClick={() => setEmojiVisible(!emojiVisible)}
        />
        {openchat ? (
          <TaxarooButton
            className={styles.send}
            type="default"
            shape="circle"
            icon={<SendOutlined />}
            size="middle"
            onClick={() => sendMessage()}
            loading={loadingSendMessage}
          />
        ) : (
          <TaxarooButton
            className={styles.send}
            type="default"
            shape="circle"
            icon={<SendOutlined />}
            size="middle"
            onClick={() => sendMessageSms()}
            loading={loadingSendMessage}
          />
        )}
      </div>
    </div>
  );
};

export default ChatInputAdmin;
