.invite-header {
  height: 100px;
  padding-left: 32px;
  padding-right: 32px;
  background-color: white;
}

.invite-header-wrapper {
  height: 100%;
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  align-items: center;
}

.invite-header-logo {
  width: auto;
  max-height: 80px;
  max-width: 200px;
  border-style: none;
}
