import {
  Form,
  Input,
  Radio,
} from 'taxaroo-ui';
import { FormInstance } from 'taxaroo-ui/node_modules/antd';
import * as styles from './styles.module.css';

const { TextArea } = Input;
const { Item } = Form;

interface ReasonFormProps {
  form: FormInstance;
}

export const ReasonForm = ({ form }: ReasonFormProps) => (
  <Form
    form={form}
    style={{
      width: '100%',
    }}
    layout="vertical"
    name="cancelSubscriptionForm"
  >
    <Item
      name="reasonSelection"
      label="Please select a reason"
      rules={[{ required: true, message: 'Please select a reason' }]}
    >
      <Radio.Group className={styles.radioGroup}>
        <Radio value="too_complex">Ease of use was less than expected</Radio>
        <Radio value="switched_service">I found an alternative (please describe which and why)</Radio>
        <Radio value="too_expensive">It is too expensive (please describe your budget)</Radio>
        <Radio value="unused">It is no longer tax season</Radio>
        <Radio value="missing_features">I need more features (please describe which and why)</Radio>
        <Radio value="customer_service">Customer service was less than expected (please describe)</Radio>
        <Radio value="low_quality">Quality was less than expected (please describe what went wrong)</Radio>
        <Radio value="other">Other reason (please describe)</Radio>
      </Radio.Group>
    </Item>
    <Item
      name="reason"
      label="Please describe"
      rules={[{ required: true, message: 'Please describe' }]}
    >
      <TextArea placeholder="Taxaroo is always looking to improve. Please let us know why you are canceling your subscription." />
    </Item>
  </Form>
);
