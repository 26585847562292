import React from 'react';
import {
  Menu as AntMenu,
  MenuProps,
  SubMenuProps,
  DividerProps,
} from 'antd';
import { MenuItemGroupProps } from 'antd/lib/menu';

export interface TaxMenuProps extends MenuProps {}

const Menu: React.FC<TaxMenuProps>&{
  Item: typeof AntMenu.Item
  SubMenu: React.FC<SubMenuProps>
  ItemGroup: React.FC<MenuItemGroupProps>
  Divider: React.FC<DividerProps>
} = (props) => (
  <AntMenu
    {...props}
    // className={[
    //   className,
    // ].filter(Boolean).join(' ')}
  />
);

Menu.Item = AntMenu.Item;
Menu.SubMenu = AntMenu.SubMenu;
Menu.ItemGroup = AntMenu.ItemGroup;
Menu.Divider = AntMenu.Divider;

export default Menu;
