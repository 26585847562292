.modal-content-container {
  display: flex;
  flex-direction: column;
}

.select-container {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
}

.select-container > strong {
  margin-bottom: 12px;
}

.modal-header {
  display: flex;
  align-items: center;
}

.modal-header > h2 {
  margin-bottom: 0;
  margin-right: 6px;
  font-size: 24px !important;
}

.modal-header > span {
  width: 16px !important;
  height: 16px !important;
}

.modal-header > span > svg {
  width: 100%;
  height: auto;
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
}
