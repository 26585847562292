.signatureDocSignArea {
  overflow-x: auto;
  overflow-y: scroll;
  height: 510px;
  padding-left: 10px;
}

.signatureDocSignArea::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.signatureDocSignArea::-webkit-scrollbar-thumb {
  background: #888;
}
