import { useState, useEffect, FC } from 'react';
import {
  Tabs,
  Icons,
  Modal,
  Form,
  message,
} from 'taxaroo-ui';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import SignatureFields from './SignatureFields';
import actionCreators from './action.creator';
import DocumentSignApproveRequestModalContent from '../DocumentSignApproveRequestModalContent';
import './styles.css';

const { HighlightOutlined, FormOutlined, ExclamationCircleOutlined } = Icons;
const { useForm } = Form;
const { TabPane } = Tabs;

interface NewESignatureRequestModalProps {
  isModalVisible: boolean
  setIsModalVisible: (b: boolean) => void
  mode: 'create' | 'edit'
  eSignatureAndApprovalState: any,
  eSignatureAndApprovalDispatch: any,
  refetchFileRequests: () => void
}

const NewESignatureRequestModal: FC<NewESignatureRequestModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  mode,
  eSignatureAndApprovalState,
  eSignatureAndApprovalDispatch,
  refetchFileRequests,
}) => {
  // const { accessToken, firmAccountId, userId, lastName } = useAppSelector((state) => state.session);
  const [signatureFieldsTabDisabled, setSignatureFieldsTabDisabled] = useState(true);
  const [okButtonLoading, setOkButtonLoading] = useState(false);
  const [isCompiledFileUpdated, setIsCompiledFileUpdated] = useState(false);
  const [signatureFieldsCompleted, setSignatureFieldsCompleted] = useState(false);

  const { currentActiveTab } = eSignatureAndApprovalState;
  const [form] = useForm();

  useEffect(() => {
    if (eSignatureAndApprovalState.fileList?.length > 0) {
      setSignatureFieldsTabDisabled(false);
    } else {
      setSignatureFieldsTabDisabled(true);
    }
  }, [eSignatureAndApprovalState.fileList]);

  const onOk = async () => {
    if (currentActiveTab === '1') {
      // User has uploaded atleast one file and its upload API call is sucess.
      // Change the currentActiveTab to '2'
      try {
        await form.validateFields();
        eSignatureAndApprovalDispatch(actionCreators.getESignModalOKButtonOnClickAction({
          currentActiveTab: '2',
          eSignatureModalOkText: 'Send For Signature',
          eSignatureModalCacelText: 'Back',
        }));
      } catch (error) {
        message.error('Please fill all the mandatory fields.');
      }
    } else {
      form.submit();

      trackCustomerIoEvent(CUSTOMER_IO_EVENTS.CLICK, {
        Title: mode === 'edit' ? 'Update signature request' : 'Create signature request',
        Source: 'Button click',
        url: window.location.href,
      });
    }
  };

  const onModalExit = () => {
    // reset fileList and newDraggableBoundarySignFields
    eSignatureAndApprovalDispatch(actionCreators.update({
      newDraggableBoundarySignFields: [],
      fileList: [],
      eSignatureModalOkText: 'Add Signature Fields',
      currentActiveTab: '1',
      eSignatureModalCacelText: 'Cancel',
      fileRequest: undefined,
    }));

    setIsCompiledFileUpdated(false);
    setIsModalVisible(false);
  };

  const onCancel = () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { currentActiveTab, eSignatureModalCacelText } = eSignatureAndApprovalState;

    if (currentActiveTab === '2' && eSignatureModalCacelText === 'Back') {
      eSignatureAndApprovalDispatch(actionCreators.getESignModalCancelButtonOnClickAction({
        eSignatureModalOkText: mode === 'edit' ? 'Update Signatures' : 'Add Signature Fields',
        currentActiveTab: '1',
        eSignatureModalCacelText: 'Cancel',
      }));
    } else {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to quit the E-Signature process?',
        okText: 'Ok',
        cancelText: 'Cancel',
        onOk: onModalExit,
      });
    }
  };

  const tabOnClick = async (value: string) => {
    try {
      await form.validateFields();
    } catch (error) {
      // form values not valid
      message.error('Please fill all the mandatory fields below.');
      eSignatureAndApprovalDispatch(actionCreators.getESignModalTabOnChangeAction({
        currentActiveTab: '1',
        eSignatureModalOkText: mode === 'edit' ? 'Update Signatures' : 'Add Signature Fields',
        eSignatureModalCacelText: 'Cancel',
      }));
    }
  };

  const tabOnChange = (value: string) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    let eSignatureModalOkText = '';
    let eSignatureModalCacelText = 'Cancel';

    if (value === '1') {
      eSignatureModalOkText = mode === 'edit' ? 'Update Signatures' : 'Add Signature Fields';
    } else {
      eSignatureModalCacelText = 'Back';
      eSignatureModalOkText = 'Send For Signature';
    }

    eSignatureAndApprovalDispatch(actionCreators.getESignModalTabOnChangeAction({
      currentActiveTab: value,
      eSignatureModalOkText,
      eSignatureModalCacelText,
    }));
  };

  return (
    <Modal
      wrapClassName="signer-kba-section-wrapper"
      bodyStyle={{
        height: 600,
      }}
      title={mode === 'create'
        ? 'Create Document Signature'
        : 'Edit Document Signature'}
      okText={eSignatureAndApprovalState.eSignatureModalOkText}
      cancelText={eSignatureAndApprovalState.eSignatureModalCacelText}
      okButtonProps={{
        disabled: signatureFieldsTabDisabled || (currentActiveTab === '2' && !signatureFieldsCompleted),
        loading: okButtonLoading,
      }}
      centered
      visible={isModalVisible}
      onOk={onOk}
      onCancel={onCancel}
      width={1000}
      maskClosable={false}
      destroyOnClose
    >
      <Tabs
        activeKey={eSignatureAndApprovalState.currentActiveTab}
        onTabClick={tabOnClick}
        onChange={tabOnChange}
        defaultActiveKey="1"
        style={{ padding: '2px', width: 'calc(100% + 30px)' }}
      >
        <TabPane
          tab={(
            <span>
              <HighlightOutlined />
              E Signature
            </span>
          )}
          key="1"
        >
          <DocumentSignApproveRequestModalContent
            form={form}
            type="sign"
            editMode={mode === 'edit'}
            setSendButtonDisabled={setSignatureFieldsTabDisabled}
            dispatch={eSignatureAndApprovalDispatch}
            state={eSignatureAndApprovalState}
            closeModal={onModalExit}
            setOkButtonLoading={setOkButtonLoading}
            refetchFileRequests={refetchFileRequests}
            isCompiledFileUpdated={isCompiledFileUpdated}
            setIsCompiledFileUpdated={setIsCompiledFileUpdated}
          />
        </TabPane>
        <TabPane
          disabled={signatureFieldsTabDisabled}
          tab={(
            <span>
              <FormOutlined />
              Signature Fields
            </span>
          )}
          key="2"
        >
          <SignatureFields
            dispatch={eSignatureAndApprovalDispatch}
            state={eSignatureAndApprovalState}
            form={form}
            editMode={mode === 'edit'}
            setSignatureFieldsCompleted={setSignatureFieldsCompleted}
            isCompiledFileUpdated={isCompiledFileUpdated}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default NewESignatureRequestModal;
