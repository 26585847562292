.emptyChatContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: white;
}

.emptyChatContainer img {
  width: 78px;
}

.emptyChatContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.title {
  color: #0a64b2;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  margin-top: 20px;
}

.small {
  color: #565656;
  font-size: 12px;
  font-weight: normal;
  margin-top: 10px;
}
