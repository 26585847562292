enum ActionType {
    INITIAL_STATE,
    E_SIGN_MODAL_OK_BUTTON_CLICK,
    E_SIGN_MODAL_CONTENT_TAB_ONCHANGE,
    E_SIGN_MODAL_CANCEL_BUTTON_CLICK,
    UPDATE,
    RESET_FORM,
}

export default ActionType;
