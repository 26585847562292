import { useState, FC, useEffect } from 'react';
import {
  Table, Button, Space, Icons, Typography, Spin, message, Modal, Input, Form, Row, Divider, Alert,
} from 'taxaroo-ui';
import * as yup from 'yup';
import {
  useCanAddMemberToFirmLazyQuery,
  useCreateSubscriptionMutation,
  useDeleteTeamMemberMutation,
  useReactivateTeamMemberMutation,
  useUpdateMemberRoleMutation,
  useUpdateSubscriptionMutation,
} from '~src/graphql/mutations/settings';
import { useAppSelector } from '~src/redux/hooks';
import {
  GetUserFirmAccountByFirmAccountAndFilterDocument,
  useCurrentStripeInformationQuery,
  useGetRolesQuery,
  useGetSubscriptionQuery,
  useGetUserFirmAccountByFirmAccountAndFilterQuery,
} from '~src/graphql/queries/settings';
import ActionButton from '~src/components/molecules/ActionButton';
import NoTeamMembers from '~src/components/organisms/ManageTeam/NoTeamMembers';
import SettingsHeader from '~src/components/organisms/Settings/SettingsHeader';
import { useCreateBillingPortalSessionMutation } from '~src/graphql/mutations/stripeInformation';
import CancelSubscriptionModal from '~src/components/molecules/CancelSubscriptionModal';
import { useUpgradeModal, Features } from '~src/components/atoms/UpgradeModal/ModalProvider';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import * as styles from './style.module.css';
import { Message } from '../../helpers/forms';
// import updateStep from '../../helpers/updateStep';
import { ManageTeamProps } from './interface';
import ManageTeamModal from '../../molecules/ManageTeamModal';
import ManageTeamRoleSelect from '../../atoms/ManageTeamRoleSelect';
import './style.css';

const { Text, Paragraph } = Typography;
const {
  PlusOutlined, SyncOutlined, SaveOutlined, DeleteOutlined,
} = Icons;

const ManageTeam: FC<ManageTeamProps> = () => {
  const schema = yup.object().shape({
    email: yup.string().email('Please enter a valid email address').required('Please enter your email'),
  });

  const { firmAccountId, languageId } = useAppSelector((state) => state.session);
  const [members, setMembers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [modal, setModal] = useState(false);
  const [updatePlanModalVisible, setUpdatePlanModalVisible] = useState(false);
  const [overPaying, setOverPaying] = useState(false);
  const [cancelPlanModalVisible, setCancelPlanModalVisible] = useState(false);
  const [emailErrorIds, setEmailErrorIds] = useState({});

  const { showModal, accessObject } = useUpgradeModal();

  // graphql
  // Get customerId
  const { data: currentStripeInformation, loading: loadingCustomer } = useCurrentStripeInformationQuery();
  const { customerId } = currentStripeInformation?.CurrentStripeInformation ?? {};
  // Get subscription info query
  const { data: subscriptionData, loading: loadingSubscription } = useGetSubscriptionQuery();
  // Create billing portal session mutation
  const [createBillingPortalSession] = useCreateBillingPortalSessionMutation({
    onError: (err) => {
      message.error(err.message);
    },
  });

  // get team members
  const {
    data: customUserFirmAccount,
    loading: loadingUserFirmAccount,
  } = useGetUserFirmAccountByFirmAccountAndFilterQuery({
    variables: {
      firmAccountId,
      filterDelete: false,
    },
  });

  // get role types
  const { data: customAllRoles, loading: loadingCustomAllRoles } = useGetRolesQuery();

  // update subscription
  const [updateSubscritpion, { loading: loadingUpdateSubscription }] = useUpdateSubscriptionMutation();
  // create subscription
  const [createSubscritpion, { loading: loadingCreateSubscription }] = useCreateSubscriptionMutation();

  // set members
  useEffect(() => {
    if (
      customUserFirmAccount
      && customUserFirmAccount.UserFirmAccountByFirmAccountAndFilter.length > 0
    ) {
      setMembers(customUserFirmAccount.UserFirmAccountByFirmAccountAndFilter);
    }
  }, [customUserFirmAccount]);

  // set roles
  useEffect(() => {
    if (customAllRoles && customAllRoles.Roles) {
      const filteredRoles = customAllRoles.Roles.filter((item) => (
        item.description === 'TAX_PREPARER' || item.description === 'STAFF'
      ));
      setRoles(filteredRoles);
    }
  }, [customAllRoles]);

  // if paying for more seats than you have, notify user
  useEffect(() => {
    if (members && subscriptionData?.GetSubscriptionByContext?.quantity) {
      const activeMembers = members.filter((member) => !member.Users.deleteAt);
      if (subscriptionData?.GetSubscriptionByContext?.quantity > activeMembers.length && subscriptionData?.GetSubscriptionByContext?.quantity > 2) {
        setOverPaying(true);
      } else {
        setOverPaying(false);
      }
    }
  }, [members, subscriptionData?.GetSubscriptionByContext?.quantity]);

  // Query to check if it's possible to add a new member
  const [checkCanAddTeamMember] = useCanAddMemberToFirmLazyQuery({
    variables: {
      firmAccountId,
    },
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setModal(true);

      trackCustomerIoEvent(CUSTOMER_IO_EVENTS.MODAL_SHOW, {
        'Modal Title': 'Add new team member',
        Source: 'Click on button',
        url: window.location.href,
      });
    },
    onError: (error) => {
      const graphqlError = error.graphQLErrors[0];
      const text = graphqlError?.message;
      if (text === 'Error code 2004') {
        setUpdatePlanModalVisible(true);
      } else {
        message.error('Could not add member team');
      }
    },
  });

  // Mutation to delete a team member
  const [
    deleteTeamMember,
    { loading: loadingDeleteTeamMember },
  ] = useDeleteTeamMemberMutation({
    onCompleted: () => {
      message.success('Changes saved successfully!');
    },
    onError: () => message.warn('Couldn\'t delete user.'),
    refetchQueries: [
      'GetSubscriptionByContext',
      'GetUserFirmAccountByFirmAccountAndFilter',
    ],
    awaitRefetchQueries: true,
  });

  // Mutation to update a team member
  const [
    updateRoleTeamMember,
    { loading: loadingUpdateTeamMember },
  ] = useUpdateMemberRoleMutation({
    onCompleted: () => message.success('Changes saved successfully!'),
    onError: (error) => {
      const graphqlErrorName = error.graphQLErrors?.at(0)?.name;
      if (graphqlErrorName === 'RecordAlreadyInDb') {
        message.error('Saving is impossible: this email is already used by another user.');
      } else if (graphqlErrorName === 'PermissionException') {
        message.info('Please contact support to update the account owner email address.');
      } else {
        message.warn('Update not possible.');
      }
    },
    refetchQueries: [
      {
        query: GetUserFirmAccountByFirmAccountAndFilterDocument,
        variables: {
          firmAccountId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  // Mutation to reactivate a team member
  const [
    reactivateTeamMember,
    { loading: loadingReactivateTeamMember },
  ] = useReactivateTeamMemberMutation({
    onCompleted: () => message.success('Changes saved successfully!'),
    onError: (err) => {
      if (err.message === 'Error code 2004') {
        setUpdatePlanModalVisible(true);
      } else {
        message.warn('Update unsuccessful.');
      }
    },
    refetchQueries: [
      'GetSubscriptionByContext',
      'GetUserFirmAccountByFirmAccountAndFilter',
    ],
    awaitRefetchQueries: true,
  });

  const updateMember = (preparerDetailId: string, param: string, value: string) => {
    const newRole = roles.find((obj) => obj.id === value);
    const editedMembers = members.map((obj) => ({
      ...obj,
      Roles: param === 'role' && obj.Users.id === preparerDetailId ? newRole : obj.Roles,
      Users: param === 'email' && obj.Users.id === preparerDetailId ? { ...obj.Users, email: value } : obj.Users,
    }));
    setMembers(editedMembers);
  };

  const saveMember = (preparerDetailId: string) => {
    const selectedMember = members.find((obj) => obj.Users.id === preparerDetailId);
    updateRoleTeamMember({
      variables: {
        firmAccountId,
        userId: preparerDetailId,
        newRoleId: selectedMember.Roles.id,
        newEmail: selectedMember.Users.email,
      },
    });
  };

  const deleteMember = (preparerDetailId: string) => {
    const ids = { ...emailErrorIds };
    delete ids[preparerDetailId];
    setEmailErrorIds(ids);

    deleteTeamMember({
      variables: {
        firmAccountId,
        userId: preparerDetailId,
      },
    });
  };

  const reactivateMember = (preparerDetailId: string) => {
    reactivateTeamMember({
      variables: {
        firmAccountId,
        userId: preparerDetailId,
      },
    });
  };

  const handleUpdatePlan = async () => {
    if (loadingSubscription || loadingCustomer) {
      message.info('Loading subscription information. Please wait a couple seconds and try again.');
      return;
    }
    const { data } = await createBillingPortalSession({
      variables: {
        createBillingPortalInput: {
          customer: customerId,
          return_url: window.location.href,
          flow_data: (!subscriptionData?.GetSubscriptionByContext?.pause_collection?.behavior || subscriptionData?.GetSubscriptionByContext?.status === 'paused') ? {
            type: 'subscription_update',
            subscription_update: {
              subscription: subscriptionData?.GetSubscriptionByContext?.id,
            },
          } : undefined,
        },
      },
    });
    window.location.href = data.createBillingPortalSession;
  };

  const handleCancelPlan = async () => {
    console.log('handleCancelPlan');
    if (loadingSubscription || loadingCustomer) {
      message.info('Loading subscription information. Please wait a couple seconds and try again.');
      return;
    }
    setCancelPlanModalVisible(true);
  };

  const handleRenewPlan = async () => {
    if (loadingSubscription || loadingCustomer) {
      message.info('Loading subscription information. Please wait a couple seconds and try again.');
      return;
    }
    if (subscriptionData.GetSubscriptionByContext?.status === 'active' && subscriptionData.GetSubscriptionByContext?.cancel_at_period_end) {
      // update subscription to cancel at period end to false
      await updateSubscritpion({
        variables: {
          updateStripeSubscriptionInput: {
            cancel_at_period_end: false,
          },
        },
        refetchQueries: ['GetSubscriptionByContext'],
        onCompleted: () => {
          message.success('Subscription renewed successfully');
        },
        onError: (error) => {
          message.error(`Subscription could not be renewed. ${error.message}`);
        },
      });
    } else if (subscriptionData.GetSubscriptionByContext?.status === 'canceled') {
      // we need to start a whole new subscription
      await createSubscritpion({
        variables: {
          createStripeSubscriptionInput: {
            customerId: subscriptionData.GetSubscriptionByContext?.customer,
            planId: subscriptionData.GetSubscriptionByContext?.items.data[0].plan.id,
            quantity: subscriptionData.GetSubscriptionByContext?.quantity,
            firmAccountId,
          },
        },
        refetchQueries: ['GetSubscriptionByContext'],
        onCompleted: () => {
          message.success('Subscription renewed successfully');
        },
        onError: (error) => {
          message.error(`Subscription could not be renewed. ${error.message}`);
        },
      });
    }
  };

  const handleAddTeamMember = () => {
    // first check if on a plan that allows
    if (!accessObject?.[Features.teamMembers]) {
      showModal(Features.teamMembers);
      return;
    }
    // then check if their plan has room for another member
    checkCanAddTeamMember();
  };

  if (loadingUserFirmAccount || loadingCustomAllRoles) {
    return <Spin size="large" />;
  }

  const getClassByUsers = (users: any) => (
    users.deleteAt ? 'manage-team-deleted-user' : ''
  );

  return (
    <Space size="small" direction="vertical" style={{ width: '100%' }}>
      <SettingsHeader
        title="Manage Team"
        tooltipTitle="How does this work?"
        tooltipContent={(
          <Paragraph>
            Add or remove members of your team.
          </Paragraph>
        )}
      />
      {overPaying && (
        <Alert
          type="success"
          message="Your plan supports more team members than you have active."
          description="You may add more team members or update your billing plan to reduce the number of seats you are paying for."
          showIcon
          style={{ marginTop: 20, marginBottom: 20 }}
          closable
        />
      )}
      <Table
        rowKey={(record) => record.id}
        columns={[
          {
            title: 'Name',
            dataIndex: 'Users',
            key: 'name',
            fixed: 'left',
            width: 150,
            render: (users) => (
              <Text className={getClassByUsers(users)}>
                {`${users.UserInformation?.firstName} ${users.UserInformation?.lastName}`}
              </Text>
            ),
          },
          {
            title: 'Email',
            dataIndex: 'Users',
            key: 'email',
            width: 150,
            render: (users) => (
              users.deleteAt
                ? (
                  <Text className={getClassByUsers(users)}>
                    {users.email}
                  </Text>
                )
                : (
                  <Form.Item
                    validateStatus={emailErrorIds[users.id] ? 'error' : 'success'}
                    help={emailErrorIds[users.id] ? 'Email address is invalid' : ''}
                    className="manage-team-form-item"
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      value={users.email}
                      validateStatus
                      onChange={(e) => {
                        const email = e.target.value;
                        updateMember(users.id, 'email', e.target.value);
                        schema.validate({ email })
                          .then(() => {
                            const ids = { ...emailErrorIds };
                            delete ids[users.id];
                            setEmailErrorIds(ids);
                          })
                          .catch(() => {
                            setEmailErrorIds({ ...emailErrorIds, [users.id]: true });
                          });
                      }}
                    />
                  </Form.Item>
                )
            ),
          },
          {
            title: 'Role',
            key: 'role',
            width: 150,
            render: (data) => (
              !data.Users.deleteAt ? (
                <ManageTeamRoleSelect
                  accountRole={data.Roles.description}
                  firm={roles}
                  updateMember={(preparerDetailId, role) => updateMember(preparerDetailId, 'role', role)}
                  preparerDetailId={data.Users.id}
                />
              ) : (
                <Text
                  className={getClassByUsers(data.Users)}
                  style={{ textTransform: 'capitalize' }}
                >
                  {data.Roles.description === 'TAX_PREPARER' ? 'Admin' : 'Staff'}
                </Text>
              )
            ),
          },
          // {
          //   title: 'Referral Code',
          //   key: 'Referal',
          //   width: 120,
          //   render: (data) => {
          //     if (data.Users.deleteAt) {
          //       return (
          //         <span style={{ padding: 15 }}>
          //           <StopOutlined title="Deleted team member" />
          //         </span>
          //       );
          //     }
          //     return <ManageTeamReferral userId={data.Users.id} />;
          //   },
          // },
          {
            title: '',
            dataIndex: '',
            key: 'action',
            width: 120,
            render: (data) => {
              if (data.Users.deleteAt) {
                return (
                  <div className={styles.actionButtons}>
                    {loadingReactivateTeamMember ? (
                      <Spin size="small" />
                    ) : (
                      <ActionButton
                        tooltip="Reactivate Member"
                        confirmAction
                        confirmLabel="Are you sure?"
                        onClick={() => reactivateMember(data.Users.id)}
                      >
                        <SyncOutlined />
                      </ActionButton>
                    )}
                  </div>
                );
              }
              return (
                <div className={styles.actionButtons}>
                  {
                    !emailErrorIds[data.Users.id]
                    && (
                      <ActionButton
                        tooltip="Save Change"
                        confirmAction
                        confirmLabel="Are you sure?"
                        onClick={() => saveMember(data.Users.id)}
                      >
                        <SaveOutlined />
                      </ActionButton>
                    )
                  }

                  <ActionButton
                    tooltip="Delete Team Member"
                    confirmAction
                    confirmLabel="Are you sure?"
                    onClick={() => deleteMember(data.Users.id)}
                    buttonProps={{ type: 'primary' }}
                  >
                    <DeleteOutlined />
                  </ActionButton>
                </div>
              );
            },
          },
        ]}
        loading={loadingDeleteTeamMember || loadingUpdateTeamMember}
        dataSource={members}
        scroll={{ x: true }}
        bordered
        noDataProps={{
          title: 'No team members yet',
          extra: <NoTeamMembers onClick={() => setModal(true)} />,
        }}
        rowClassName={() => 'manage-team-row'}
        pagination={{ hideOnSinglePage: true }}
      />
      <Button
        type="primary"
        onClick={handleAddTeamMember}
        icon={<PlusOutlined />}
        iconDirection="left"
        style={{ marginBottom: 20, marginTop: 25 }}
        disabled={loadingDeleteTeamMember || loadingUpdateTeamMember}
      >
        Add Team Member
      </Button>
      <Text style={{ color: 'grey' }}>
        <Text strong style={{ color: 'grey' }}>
          Admins
          {' '}
        </Text>
        have access to all clients and firm settings
      </Text>
      <Text style={{ color: 'grey' }}>
        <Text strong style={{ color: 'grey' }}>
          Staff
          {' '}
        </Text>
        only have access to assigned clients and personal settings
      </Text>
      <Divider style={{ margin: '8px 0' }} />
      <Row style={{ marginLeft: -15, marginBottom: 30 }}>
        <Button
          type="link"
          onClick={handleUpdatePlan}
          style={{
            color: 'gray',
            fontSize: '14px',
            fontWeight: 'normal',
            textTransform: 'none',
          }}
        >
          Add team members
        </Button>
        <Button
          type="link"
          onClick={handleUpdatePlan}
          style={{
            color: 'gray',
            fontSize: '14px',
            fontWeight: 'normal',
            textTransform: 'none',
          }}
        >
          Update plan
        </Button>
        {/* if not already canceled */}
        {subscriptionData?.GetSubscriptionByContext?.status !== 'canceled' && !subscriptionData?.GetSubscriptionByContext?.cancel_at && (
          <Button
            type="link"
            onClick={handleCancelPlan}
            style={{
              color: 'gray',
              fontSize: '14px',
              fontWeight: 'normal',
              textTransform: 'none',
            }}
          >
            Cancel account
          </Button>
        )}
        {/* if already canceled */}
        {(subscriptionData?.GetSubscriptionByContext?.status === 'canceled' || subscriptionData?.GetSubscriptionByContext?.cancel_at) && (
          <Button
            type="link"
            onClick={handleRenewPlan}
            loading={loadingUpdateSubscription || loadingCreateSubscription}
            style={{
              color: 'gray',
              fontSize: '14px',
              fontWeight: 'normal',
              textTransform: 'none',
            }}
          >
            Reactivate account
          </Button>
        )}
      </Row>

      <ManageTeamModal
        roles={roles}
        showModal={modal}
        setModal={setModal}
        onAddMemberTeam={(newMember: undefined) => {
          setMembers([...members, newMember]);
          // updateStep(accessToken, 5);
          Message('The Team Member Was Added Successfully', 'success');
        }}
        firmAccountId={firmAccountId}
        languageId={languageId}
      />
      <Modal
        title="No more seats available"
        visible={updatePlanModalVisible}
        onCancel={() => setUpdatePlanModalVisible(false)}
        footer={[
          <Button
            key="updatePlan"
            type="primary"
            onClick={handleUpdatePlan}
          >
            Update Plan
          </Button>,
          <Button
            key="close"
            type="default"
            onClick={() => setUpdatePlanModalVisible(false)}
          >
            Close
          </Button>,
        ]}
      >
        <p>Your account is already using all the team member seats available.</p>
        <p>You may add more seats to your plan from the Billing Portal by increasing the quantity of your plan.</p>
      </Modal>
      {/* Cancel plan modal */}
      <CancelSubscriptionModal
        setIsModalVisible={setCancelPlanModalVisible}
        isModalVisible={cancelPlanModalVisible}
        subscription={subscriptionData?.GetSubscriptionByContext}
      />
    </Space>
  );
};

export default ManageTeam;
