import { useCallback } from 'react';
import {
  Icons,
  Modal,
  Form,
  Input,
  Select,
  message,
} from 'taxaroo-ui';
import PhoneInputField from '../../atoms/PhoneInputField';

const { Option } = Select;
const { UserOutlined } = Icons;

interface UpdateClientInfoModalProps {
  isModalVisible: boolean
  setIsModalVisible: (b: boolean) => void
}
function UpdateClientInfoModal({
  setIsModalVisible,
  isModalVisible,
}: UpdateClientInfoModalProps) {
  const [form] = Form.useForm();

  const onSubmit = useCallback((values) => {
    console.log('values:', values);
    message.success('Client Updated!');
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  const handleCancel = useCallback(() => {
    form.resetFields();
    setIsModalVisible(false);
  }, [form, setIsModalVisible]);

  return (
    <Modal
      visible={isModalVisible}
      title="Edit Client Info"
      okButtonProps={{ icon: <UserOutlined /> }}
      okText="Update Client"
      onCancel={handleCancel}
      onOk={form.submit}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        // eslint-disable-next-line no-template-curly-in-string
        validateMessages={{ required: '${label} is required' }}
      >
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[{ required: true }]}
        >
          <Input placeholder="Taxpayer First Name" />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[{ required: true }]}
        >
          <Input placeholder="Taxpayer Last Name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, type: 'email' }]}
        >
          <Input placeholder="Taxpayer Email" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone"
          rules={[{ required: true, type: 'string' }]}
        >
          <PhoneInputField
            onChange={(value: string) => form.setFieldsValue({ phone: value })}
            // form={form}
          />
        </Form.Item>
        <Form.Item
          name="language"
          label="Language"
          rules={[{ required: false }]}
        >
          <Select
            placeholder="Select language"
            onChange={(value: string) => form.setFieldsValue({ taxYear: value })}
          >
            <Option value="english" key="english">English</Option>
            <Option value="spanish" key="spanish">Spanish</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UpdateClientInfoModal;
