import { ApolloError } from '@apollo/client';
import { FC, useEffect, useState } from 'react';
import {
  Typography,
  Row,
  Col,
  TaxarooButton,
  Select,
  Icons,
  message,
} from 'taxaroo-ui';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import { CreateEventTypeInput, EventTypeEntity, UpdateEventTypeInput } from '~src/graphql';
import { CreateEventTypeMutationFn, UpdateEventTypeMutationFn, useUpdateEventTypeTimezoneMutation } from '~src/graphql/mutations/appointments';
import {
  MyEventTypesDocument,
  MyEventTypesQuery,
} from '~src/graphql/queries/appointments';
import momentTz from 'moment-timezone';
import EventType from '../../molecules/EventType';
import EventTypeSettings from '../EventTypeSettings';

const {
  Link, Title, Text, Paragraph,
} = Typography;
const { SaveOutlined } = Icons;

interface EventTypesProps {
  eventTypes: MyEventTypesQuery,
  loadingEventTypes: boolean,
  createEventTypeMutation: CreateEventTypeMutationFn,
  createEventTypeLoading: boolean,
  handleOnError: (error: ApolloError) => void,
  updateEventTypeMutation: UpdateEventTypeMutationFn,
  updateEventTypeLoading: boolean,
}

const EventTypes: FC<EventTypesProps> = ({
  eventTypes,
  loadingEventTypes,
  createEventTypeMutation,
  createEventTypeLoading,
  handleOnError,
  updateEventTypeMutation,
  updateEventTypeLoading,
}) => {
  const [eventType, setEventType] = useState<EventTypeEntity>(undefined);
  const [modalVisible, setModalVisible] = useState(false);
  const [defaultTimezone, setDefaultTimezone] = useState(eventTypes?.MyEventTypes?.[0].timezone);
  const [timezone, setTimezone] = useState(undefined);

  const [updateEventTypeTzMutation, { loading: updateEventTypeTzLoading }] = useUpdateEventTypeTimezoneMutation({
    onError: handleOnError,
    refetchQueries: [MyEventTypesDocument],
    awaitRefetchQueries: true,
  });

  const selectEventType = (data: EventTypeEntity) => {
    setEventType(data);
    setModalVisible(true);
  };

  const handleCancel = () => {
    setEventType(undefined);
    setModalVisible(false);
  };

  const handleSave = async (data: CreateEventTypeInput | UpdateEventTypeInput) => {
    if (Object.keys(data).some((prop) => prop === 'id')) {
      const result = await updateEventTypeMutation({
        variables: {
          input: data as UpdateEventTypeInput,
        },
      });

      if (result.data?.updateEventType.id) {
        setModalVisible(false);
      }
    } else {
      const result = await createEventTypeMutation({
        variables: {
          input: data as CreateEventTypeInput,
        },
      });

      if (result.data?.createEventType.id) {
        setModalVisible(false);
      }
    }
  };

  const handleTimezoneSave = async () => {
    const result = await updateEventTypeTzMutation({
      variables: {
        timezone,
      },
    });

    if (result.data.UpdateEventTypeTimezone) {
      message.success('Timezone successfuly updated');
    }
  };

  useEffect(() => {
    if (eventTypes?.MyEventTypes?.[0].timezone) {
      setDefaultTimezone(eventTypes.MyEventTypes[0].timezone);
      setTimezone(eventTypes.MyEventTypes[0].timezone);
    }
  }, [eventTypes?.MyEventTypes]);

  return (
    <>
      <Row
        justify="space-between"
        style={{ paddingTop: '20px' }}
      >
        <Title level={3}>
          Appointment Types
        </Title>
        <TaxarooButton
          type="primary"
          onClick={() => {
            setModalVisible(true);
            trackCustomerIoEvent(CUSTOMER_IO_EVENTS.MODAL_SHOW, {
              'Modal Title': 'New Appointment Type',
              Source: 'Click on button',
              url: window.location.href,
            });
          }}
        >
          <Text style={{ color: '#fff' }}>
            New Appointment Type
          </Text>
        </TaxarooButton>
      </Row>

      <Row gutter={15}>
        {eventTypes?.MyEventTypes.length
          ? eventTypes?.MyEventTypes.map((event) => (
            <Col key={event.id} style={{ margin: '10px' }}>
              <EventType
                onClick={selectEventType}
                eventType={event as EventTypeEntity}
                isLoading={loadingEventTypes || updateEventTypeLoading}
                handleOnError={handleOnError}
              />
            </Col>
          ))
          : <Col>Appointment types list is empty.</Col>}
      </Row>

      <Row style={{ padding: '25px 0 0 10px' }}>
        <Paragraph>Your Appointments Timezone: (only affects your Appointment Type availability times)</Paragraph>
      </Row>
      <Row style={{ padding: '0 0 0 10px' }}>
        <Select
          defaultValue={defaultTimezone}
          style={{ width: '240px' }}
          optionFilterProp="children"
          showSearch
          placeholder="Select Timezone"
          onChange={(val: string) => setTimezone(val)}
          value={timezone}
        >
          {momentTz.tz.names().map((name, index) => (
            <Select.Option key={index} value={name}>
              {name}
            </Select.Option>
          ))}
        </Select>
        <TaxarooButton
          type="link"
          icon={<SaveOutlined />}
          title="Save timezone"
          disabled={defaultTimezone === timezone}
          loading={updateEventTypeTzLoading}
          onClick={handleTimezoneSave}
          style={{ marginLeft: '12px' }}
        />
      </Row>

      <Row style={{ paddingTop: '35px' }}>
        <Link href={`${window.location.origin}/settings/calendars`}>
          Connect your Google or Outlook calendar here
        </Link>
      </Row>

      <EventTypeSettings
        visible={modalVisible}
        eventType={eventType}
        handleCancel={handleCancel}
        handleSave={handleSave}
        isLoading={createEventTypeLoading || updateEventTypeLoading}
      />
    </>
  );
};

export default EventTypes;
