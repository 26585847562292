.header {
  display: flex;
  justify-content: space-between;
  height: 70px;
  left: 151px;
  padding: 0 150px !important;
}

.signInLink {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #b3b3b3;
}

.logo {
  width: 177px;
  height: 70px;
  left: 151px;
}

.signupContainer {
  padding: 40px 150px !important;
  padding-bottom: 15px;
  background-color: #f8f8f8;
}

.textContainer p {
  font-family: "Open Sans", sans-serif;
  font-size: 36px;
  font-weight: 700;
  text-align: left;
  margin-top: 8px;
  line-height: 47.88px;
}

.textContainer ul {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  padding-left: 0;
}

.textContainer ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.textContainer ul li .checkboxIcon {
  margin-right: 20px;
  color: #0057a5;
  font-size: 20px;
}

.registerTitle {
  font-family: "Open Sans", sans-serif !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  color: #0057a5 !important;
}

.registerFlowContainer {
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  background-color: white;
  padding: 30px 20px 23px;
  margin: 0 auto;
  box-shadow: 10px 10px #d8d8d8, 20px 20px #cacaca;
}

.registerFlowContainer h3 {
  font-family: "Open Sans", sans-serif !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  color: #0057a5 !important;
  margin-bottom: 24px;
  text-align: center;
}

@media (max-width: 1199px) {
  .registerSectionCol {
    margin-bottom: 40px;
  }

  .registerFlowContainer {
    width: 100%;
    height: auto;
    box-shadow: none;
  }

  .reverseOnSmall {
    flex-wrap: wrap-reverse !important;
    margin-top: -20px;
  }

  .registerTitle {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .registerSectionCol {
    margin-bottom: 20px;
  }

  .registerFlowContainer {
    width: 100%;
    height: auto;
    box-shadow: none;
  }

  .signupContainer {
    padding: 40px 55px !important;
  }

  .header {
    padding: 0 55px !important;
  }

  .reverseOnSmall {
    flex-wrap: wrap-reverse !important;
    margin-top: -20px;
  }

  .registerTitle {
    display: none !important;
  }
}

@media (max-width: 576px) {
  .registerSectionCol {
    margin-bottom: 10px;
  }

  .signupContainer {
    padding: 40px 15px !important;
  }

  .header {
    padding: 0 15px !important;
  }

  .reverseOnSmall {
    flex-wrap: wrap-reverse !important;
    margin-top: -20px;
  }
}
