import React from 'react';
import { Rate as AntdRate, RateProps } from 'antd';

export interface TaxRateProps extends RateProps {
}

const Rate: React.FC<TaxRateProps> = ({
  ...rest
}) => (
  <AntdRate
    {...rest}
  />
);

export default Rate;
