.container {
  width: 100%;
  padding: 15px 15px 0;
  background-color: #f4f6f9;
  border-radius: 3px;
  padding-left: 20px;
}

.input {
  border: 1px solid #aaa !important;
  background-color: transparent !important;
  border-radius: 4px !important;
}

.title {
  font-size: 14px;
  font-weight: 500;
  color: #565656;
  margin: 0;
  margin-top: 15px;
  margin-bottom: 10px;
}

.error {
  border-color: #f54343 !important;
}

.errorLabel {
  color: #f54343;
  font-size: 10px;
  margin: 0;
  margin-top: 5px;
}
