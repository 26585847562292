import { RefObject, useRef } from 'react';
import { Icons, message } from 'taxaroo-ui';
import { CSVLink } from 'react-csv';
import { useReactToPrint } from 'react-to-print';
import SelectionDropdown from '../../molecules/SelectionDropdown';
import { clientDataToCsvData, csvDataToTextData, jobDataToCsvData } from './exportData';

const {
  CopyOutlined,
  DownOutlined,
  PrinterOutlined,
  CloudDownloadOutlined,
} = Icons;

const pageStyle = `
  @page {
    size: auto;
    margin: 5mm 1mm;
  }
  @media print {
    body {
      -webkit-print-color-adjust: exact;
    }
    .ant-table-sticky-holder {
      top: 0px !important;
    }
    .ant-table-body {
      overflow: visible !important;
    }
  }
`;

const onCopy = (copyText: string) => {
  navigator.clipboard.writeText(copyText)
    .then(() => {
      message.success('Copied!');
    })
    .catch((error) => {
      message.error(error);
    });
};

type DataType = 'clients' | 'jobs';
interface ExportButtonProps {
  dataType: DataType;
  progressStatusName: string;
  tableData: any[];
  tableRef: RefObject<HTMLDivElement>;
}

function ExportButton({
  dataType, progressStatusName, tableData, tableRef,
}: ExportButtonProps) {
  // setup the ref that we'll use for the hidden CsvLink click once we've updated the data
  const csvLink = useRef<any>();

  const csvData = dataType === 'clients' ? clientDataToCsvData(tableData) : jobDataToCsvData(tableData);

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    pageStyle,
  });

  const handleDropdownSelection = (option: { type: string, title: string }) => {
    const { type } = option;
    if (type === 'copy') {
      onCopy(csvDataToTextData(csvData));
    } else if (type === 'print') {
      if (handlePrint) {
        handlePrint();
      }
    } else if (type === 'csv_export') {
      if (csvLink?.current?.link) {
        csvLink.current.link.click();
      }
    }
  };

  const getCsvFileName = () => {
    let prefix = '';
    if (progressStatusName) {
      prefix = `${progressStatusName.toLowerCase().replace(/ /g, '_')}_`;
    }
    const name = dataType === 'jobs' ? 'jobs' : 'clients';
    return `${prefix}${name}`;
  };

  return (
    <>
      <SelectionDropdown
        buttonProps={{
          style: { color: '#5a5a5a', borderColor: '#5a5a5a' },
          icon: <DownOutlined />,
          // iconDirection: 'left',
          type: 'default',
        }}
        dropDownProps={{
          placement: 'bottomLeft',
        }}
        onSelect={(option: { type: string, title: string }) => handleDropdownSelection(option)}
        options={[
          { type: 'copy', title: 'Copy', icon: <CopyOutlined /> },
          { type: 'print', title: 'Print', icon: <PrinterOutlined /> },
          { type: 'csv_export', title: 'CSV Export', icon: <CloudDownloadOutlined /> },
        ]}
        keyText="title"
      >
        Export Grid
      </SelectionDropdown>
      <CSVLink
        data={csvData}
        filename={getCsvFileName()}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </>
  );
}

export default ExportButton;
