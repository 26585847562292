import React from 'react';
import { Popover as AntPopover, PopoverProps } from 'antd';

export interface TaxPopoverProps extends PopoverProps {
  //
}

const Popover: React.FC<TaxPopoverProps> = (props) => {
  const { className } = props;

  return (
    <AntPopover
      {...props}
      className={[
        className,
      ].filter(Boolean).join(' ')}
    />
  );
};

export default Popover;
