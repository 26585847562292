import { Icons } from 'taxaroo-ui';
import moment from 'moment';
import { PhonesEntity, TaxYearInterviewOrderByInput } from '~src/graphql';
import getPhoneIcon from '~src/components/helpers/getPhoneIcon';
import SelectTeamMember from '../../../atoms/SelectTeamMember';
import Documents from '../../JobsTable/statusIcons/Documents';
import Activation from '../../JobsTable/statusIcons/Activation';
import Notes from '../../JobsTable/statusIcons/Notes';
import Interview from '../../JobsTable/statusIcons/Interview';
import Invoice from '../../JobsTable/statusIcons/Invoice';
import Review from '../../JobsTable/statusIcons/Review';
import Approval from '../../JobsTable/statusIcons/Approval';
import NameColumn from './NameColumn';
import { RecordTarget } from '..';
import '../styles.css';
import {
  propertyNameSortArray, propertyPhoneSortArray, propertySortArray, propertyTaskSortArray,
} from './clientListColumns';

const {
  MailOutlined,
  FileOutlined,
  FormOutlined,
  QuestionCircleOutlined,
  DollarOutlined,
  LikeOutlined,
  CheckOutlined,
} = Icons;

const convertShortFormSortOrderToLongForm = (sortOrder: string) => {
  if (sortOrder === 'asc') return 'ascend';
  if (sortOrder === 'desc') return 'descend';
  return null;
};

const getCompletedColumns = (userRole: string, progressStatusId: string) => {
  const savedSort: TaxYearInterviewOrderByInput = JSON.parse(localStorage.getItem(`${progressStatusId}SortOrder`));
  return [
    {
      title: 'First Name',
      dataIndex: '',
      key: 'firstName',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.TaxYear?.Entity?.Users?.UserInformation?.firstName),
      // sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      // eslint-disable-next-line react/display-name
      render: ({
        email,
        phone,
        userId,
        entityId,
        firstName,
        languageId,
        workNumber,
        landlineNumber,
        userInformationId,
        taxYearInterviewId,
      }: RecordTarget) => (
        <NameColumn
          email={email}
          userId={userId}
          entityId={entityId}
          phone={phone?.value}
          name={firstName}
          languageId={languageId}
          workNumber={workNumber?.value}
          userInformationId={userInformationId}
          landlineNumber={landlineNumber?.value}
          taxYearInterviewId={taxYearInterviewId}
        />
      ),
      // fixed: screens.sm ? 'left' : false,
      width: 126,
      className: 'column-name-overflow-hidden',
    },
    {
      title: 'Last Name',
      dataIndex: '',
      key: 'lastName',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.TaxYear?.Entity?.Users?.UserInformation?.lastName),
      // sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      // eslint-disable-next-line react/display-name
      render: ({
        email,
        phone,
        userId,
        entityId,
        lastName,
        languageId,
        workNumber,
        landlineNumber,
        userInformationId,
        taxYearInterviewId,
      }: RecordTarget) => (
        <NameColumn
          email={email}
          userId={userId}
          entityId={entityId}
          phone={phone?.value}
          name={lastName}
          languageId={languageId}
          workNumber={workNumber?.value}
          userInformationId={userInformationId}
          landlineNumber={landlineNumber?.value}
          taxYearInterviewId={taxYearInterviewId}
        />
      ),
      // fixed: screens.sm ? 'left' : false,
      width: 126,
      className: 'column-name-overflow-hidden',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.TaxYear?.Entity?.Users?.email),
      // sortOrder: sortedInfo.columnKey === "email" && sortedInfo.order,
      key: 'email',
      width: 200,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      sorter: false,
      // sortOrder: sortedInfo.columnKey === "email" && sortedInfo.order,
      render: (record: PhonesEntity) => (
        <span>
          {record?.value && (
          <>
            {(getPhoneIcon(record))}
            {' '}
            {record?.value ?? ''}
          </>
          )}
        </span>
      ),
      key: 'phone',
      width: 200,
    },
    {
      title: 'Tax Year',
      dataIndex: 'taxYear',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.TaxYear?.year),
      // sortOrder: sortedInfo.columnKey === "taxYear" && sortedInfo.order,
      key: 'taxYear',
      width: 85,
    },
    {
      title: 'Job Type',
      dataIndex: 'jobType',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.Interviews?.name),
      // sortOrder: sortedInfo.columnKey === "jobType" && sortedInfo.order,
      key: 'jobType',
      width: 140,
    },
    {
      title: 'Completed Date',
      dataIndex: 'completedDate',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.completedAt),
      // sortOrder: sortedInfo.columnKey === "inProgressTime" && sortedInfo.order,
      key: 'completedDate',
      render: (text: number) => moment(new Date(text)).format('MM/DD/YYYY'),
      renderText: (text: number) => moment(new Date(text)).format('MM/DD/YYYY'),
      width: 130,
    },
    {
      title: <FileOutlined title="Documents" className="column-icon" />,
      label: 'Documents',
      key: 'files',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.JobTracking?.filesCompletedAt),
      // sortOrder: sortedInfo.columnKey === "files" && sortedInfo.order,
      // eslint-disable-next-line react/display-name
      render: (text, record: RecordTarget) => <Documents record={record} />,
      renderText: (docStatus: any) => {
        if (!docStatus) return 'Documents not found';
        return null;
      },
      width: 55,
    },
    {
      title: <QuestionCircleOutlined title="Client Interview Status" className="column-icon" />,
      label: 'Client Interview Status',
      dataIndex: '',
      key: 'clientInterview',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.JobTracking?.interviewCompletedAt),
      // sortOrder: sortedInfo.columnKey === "clientInterview" && sortedInfo.order,
      render: (text, record: RecordTarget) => <Interview record={record} />,
      // renderText: (data, row) => statusIndicators(row.taxYearStatus.clientInterview, row, true),
      width: 55,
    },
    {
      title: <LikeOutlined title="Review Status" className="column-icon" />,
      label: 'Review Status',
      dataIndex: 'reviewStatus',
      key: 'reviewStatus',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.reviewStatus),
      // sortOrder: sortedInfo.columnKey === "reviewStatus" && sortedInfo.order,
      render: (text, record: RecordTarget) => <Review reviewStatus={text} />,
      // renderText: (data, row) => RenderInternalReviewStatus(data, row, true),
      width: 55,
    },
    {
      title: <CheckOutlined title="Client E-File Approval" className="column-icon" />,
      label: 'Client Document Approval Status',
      dataIndex: '',
      key: 'clientCopyUploaded',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.JobTracking?.approvalsCompletedAt),
      // sortOrder: sortedInfo.columnKey === "clientCopyUploaded" && sortedInfo.order,
      render: (text, record: RecordTarget) => <Approval record={record} />,
      // renderText: (data, row) => statusIndicators(row.taxYearStatus.eFileAuth, row, true),
      width: 55,
    },
    {
      title: () => <MailOutlined title="Activation Sent" className="column-icon" />,
      label: 'Envelop',
      dataIndex: 'invitationEmailSent',
      key: 'invitationEmailSent',
      sorter: false,
      // sortOrder: sortedInfo.columnKey === "invitationEmailSent" && sortedInfo.order,
      // eslint-disable-next-line react/display-name
      render: (text: boolean, record: RecordTarget) => <Activation invitationEmailSent={text} password={record?.password} />,
      width: 55,
    },
    {
      title: <FormOutlined title="Notes" className="column-icon" />,
      label: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      sorter: false,
      // sortOrder: sortedInfo.columnKey === "comments" && sortedInfo.order,
      // eslint-disable-next-line react/display-name
      render: (text, record: RecordTarget) => <Notes record={record} />,
      renderText: (comments: { createdAt: string | number | Date }) => {
        if (!comments) return 'Notes not found';
        return moment(new Date(comments.createdAt)).format();
      },
      width: 55,
    },
    {
      title: <DollarOutlined title="Client Payment Received" className="column-icon" />,
      label: 'Client Payment Status',
      dataIndex: '',
      key: 'clientInvoice',
      sorter: false,
      // sortOrder: sortedInfo.columnKey === "clientInvoice" && sortedInfo.order,
      render: (record: RecordTarget) => <Invoice record={record} />,
      // renderText: (data, row) => statusIndicators(row.taxYearStatus.clientInvoice, row, true),
      width: 55,
    },
    {
      title: 'Referred By',
      dataIndex: 'referredBy',
      sorter: false,
      // sortOrder: sortedInfo.columnKey === "referredBy" && sortedInfo.order,
      key: 'referredBy',
      width: 150,
    },
    {
      title: 'Preparer',
      dataIndex: '',
      align: 'center',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.PreparedUser?.UserInformation?.firstName),
      key: 'currentPreparer',
      // sortOrder: sortedInfo.columnKey === "currentPreparer" && sortedInfo.order,
      // eslint-disable-next-line react/display-name
      render: ({ taxYearInterviewId, preparedId, progressStatusId: progStatusId }: RecordTarget) => (
        <SelectTeamMember
          type="Preparer"
          taxYearInterviewId={taxYearInterviewId}
          assignedMemberId={preparedId}
          progressStatusId={progStatusId}
          userRole={userRole}
        />
      ),
      width: 170,
    },
    {
      title: 'Reviewer',
      dataIndex: '',
      align: 'center',
      sorter: true,
      defaultSortOrder: convertShortFormSortOrderToLongForm(savedSort?.ReviewedUser?.UserInformation?.firstName),
      key: 'currentReviewer',
      // sortOrder: sortedInfo.columnKey === "currentReviewer" && sortedInfo.order,
      // eslint-disable-next-line react/display-name
      render: ({ taxYearInterviewId, reviewedId, progressStatusId: progStatusId }: RecordTarget) => (
        <SelectTeamMember
          type="Reviewer"
          taxYearInterviewId={taxYearInterviewId}
          assignedMemberId={reviewedId}
          progressStatusId={progStatusId}
          userRole={userRole}
        />
      ),
      width: 170,
    },
  ];
};

export default getCompletedColumns;
