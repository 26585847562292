.optionElement {
  flex: 1;
  display: flex;
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 12px;
  flex-direction: row;
  background-color: #fafafa;
  box-shadow: 0 0 5px #0000001a;
}

.optionElement > button {
  margin-left: 24px;
}
