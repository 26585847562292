import React, { FC } from 'react';
// import moment from "moment";
import PropTypes from 'prop-types';
import { Avatar, Badge, Typography } from 'antd';
// import { getName } from '../../helpers/userInformation';
import './ChatChannel.module.css';

// console.log('asddddddddddd: ', headerStyles);

const styles = {
  container: 'cc_container',
  selected: 'cc_selected',
  badge: 'cc_badge',
  containerData: 'cc_containerData',
  name: 'cc_name',
  date: 'cc_date',
  last: 'cc_last',
  message: 'cc_message',
  count: 'cc_count',
};

const { Text } = Typography;
// const styles: any = {};
const getAcronim = (str: string) => {
  const matches = str.match(/\b(\w)/g);
  const acronym = matches?.join('');
  return acronym?.substring(0, 2).toUpperCase();
};

interface ChatChannelProps {
  data: any,
  setChannel: any,
  selected: boolean,
  userId: number,
  dateFromNow: string,
}

const ChatChannel: FC<ChatChannelProps> = ({
  data,
  setChannel,
  selected,
  userId,
  dateFromNow,
}) => {
  const { ChannelMembers, Messages, status } = data;
  /* const date = () => {
    if (Messages.length <= 0) return '';
    const { updatedAt } = Messages[Messages.length - 1];
    const dateMoment = moment(updatedAt);
    return dateMoment.fromNow();
  }; */

  const getName = (obj: any) => {
    if (!obj) return '';
    let firstName = '';
    let lastName = '';
    if (obj.Users && obj.Users.PreparerDetails) {
      firstName = obj.Users.PreparerDetails.firstName;
      lastName = obj.Users.PreparerDetails.lastName;
    } else if (obj.Users.Clients_Clients_clientUserIdToUsers) {
      firstName = obj.Users.Clients_Clients_clientUserIdToUsers.firstName;
      lastName = obj.Users.Clients_Clients_clientUserIdToUsers.lastName;
    }
    return `${firstName} ${lastName}`;
  };

  const getChannelMembersNames = () => {
    const member = ChannelMembers.find((mb: any) => mb.Users.id !== userId);
    return getName(member);
  };

  const lastMessage = () => {
    if (Messages.length <= 0) return '';
    const { text } = Messages[Messages.length - 1];
    if (!text) {
      return '';
    }
    if (text.includes('#{meetingId}=')) {
      return '#Incoming meeting';
    }

    return text.split('~#*')[0];
  };

  const filterMessages = () => {
    if (Messages.length <= 0) return undefined;
    const messages = Messages.filter((msg: any) => !msg.readDate && msg.userId !== userId);
    return messages.length <= 0 ? undefined : messages.length;
  };

  const getStatusConnection = () => {
    if (status) return 'green';
    return 'gray';
  };

  const selectChannel = () => {
    setChannel(data.id);
  };

  const unreadMessages = filterMessages();

  return (
    <div
      className={`${styles?.container} ${selected && styles?.selected}`}
      onClick={selectChannel}
      onKeyDown={selectChannel}
      role="button"
      tabIndex={0}
    >
      <Badge dot className={styles?.badge} color={getStatusConnection()}>
        <Avatar style={{ backgroundColor: '#22A7E7' }} size={50}>
          {getAcronim(getChannelMembersNames())}
        </Avatar>
      </Badge>
      <div className={styles?.containerData}>
        <div>
          <Text className={styles?.name}>{getChannelMembersNames()}</Text>
          <Text className={styles?.date}>{dateFromNow}</Text>
        </div>
        <div className={styles?.last}>
          <Text className={styles?.message}>{lastMessage()}</Text>
          {' '}
          {unreadMessages && <p className={styles?.count}>{unreadMessages}</p>}
        </div>
      </div>
    </div>
  );
};

ChatChannel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  setChannel: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  dateFromNow: PropTypes.string.isRequired,
};
export default ChatChannel;
