import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import moment, { Moment } from 'moment';
import {
  Modal, Select, Input, message, Form, FormInstance,
} from 'taxaroo-ui';
import { GetClientsQuery } from '~src/graphql/queries/settings';
import { MyEventsDocument, MyEventTypesQuery } from '~src/graphql/queries/appointments';
import './style.css';
import { useCreateEventMutation, useUpdateEventMutation } from '~src/graphql/mutations/appointments';
import { InfoCircleOutlined } from 'taxaroo-ui/src/Icons';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import { FormatedEvent, NewAppointmentFormValues } from '../AppointmentsTable/AppointmentsTable';
import { parseHoursRange } from '../../helpers/time';

const { Option } = Select;
const { TextArea } = Input;

export type UpdateAppointmentProps = {
  id: string;
  clientId: string;
  meetingType: string;
  eventTypeId: string;
  start: Date;
  end: Date;
  notes: string;
};

interface NewAppointmentModalProps {
  visible: boolean;
  handleCancel: any;
  clients: GetClientsQuery['Clients']['data'];
  isTaxPayersLoading: boolean,
  eventTypes: MyEventTypesQuery['MyEventTypes'],
  loadingEventTypes: boolean,
  newAppointmentForm: FormInstance<NewAppointmentFormValues>,
  selectedDate: Date,
  events: FormatedEvent[],
  appointment2Update?: UpdateAppointmentProps,
  reloadEvents: () => void;
}

const NewAppointmentModal: React.FC<NewAppointmentModalProps> = ({
  visible,
  handleCancel,
  clients,
  isTaxPayersLoading,
  eventTypes,
  loadingEventTypes,
  newAppointmentForm,
  selectedDate,
  events,
  appointment2Update,
  reloadEvents,
}) => {
  const eventTypeId = Form.useWatch('eventType', newAppointmentForm);
  const eventType = eventTypeId ? eventTypes.find((et) => et.id === eventTypeId) : eventTypes[0];
  const client = Form.useWatch('client', newAppointmentForm);
  const day = Form.useWatch('day', newAppointmentForm);
  const time = Form.useWatch('time', newAppointmentForm);
  const [brackets, setBrackets] = useState([]);
  const [selectedDay, setSelectedDay] = useState<Moment>(undefined);
  const [selectDayDropdownOpened, setSelectDayDropdownOpened] = useState(false);
  const [createEventMutation, { loading: createEventLoading }] = useCreateEventMutation({
    onError: (err) => {
      if (err.message === 'Email notification was not sent. Client email is not set.') {
        reloadEvents();
      }
      message.error(err.message, 10);
    },
    refetchQueries: [MyEventsDocument],
    awaitRefetchQueries: true,
  });
  const [updateEventMutation, { loading: updateEventLoading }] = useUpdateEventMutation({
    onError: (err) => {
      if (err.message === 'Email notification was not sent. Client email is not set.') {
        reloadEvents();
      }
      message.error(err.message, 10);
    },
    refetchQueries: [MyEventsDocument],
    awaitRefetchQueries: true,
  });

  const initialformValues: NewAppointmentFormValues = {
    client: undefined,
    meetingType: 'VIDEO',
    eventType: eventTypes[0]?.id || undefined,
    day: undefined,
    time: undefined,
    notes: '',
  };

  const getDate = (theBrackets: any) => {
    const start = new Date().setHours(theBrackets.startHour, theBrackets.startMinute);
    const end = new Date().setHours(theBrackets.endHour, theBrackets.endMinute);
    return `${moment(start).format('LT')} - ${moment(end).format('LT')}`;
  };

  const filterOption = (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const disabledDate = ({ date }: { date: any }) => {
    const actualDate = moment().format();
    const currentDate = moment(date);

    if (
      currentDate.isBefore(actualDate)
      && !currentDate.isSame(actualDate, 'day')
    ) return true;
    return (
      eventType.EventTypeAvailability.findIndex(
        (ETAvailability: any) => ETAvailability.day === currentDate.format('dddd').toUpperCase()
          && ETAvailability.EventTypeAvailabilityBracket.length > 0,
      ) < 0
    );
  };

  const selectDay = async (value: Date, updatedEventTypeId?: string) => {
    const momentDay = moment(value);
    setSelectedDay(momentDay);
    newAppointmentForm.setFieldsValue({
      day: momentDay.format('ddd MMM DD, YYYY'),
    });
    setSelectDayDropdownOpened(false);

    const eventTypeSelected = updatedEventTypeId
      ? eventTypes.find((et) => et.id === updatedEventTypeId)
      : eventType;

    const availability = eventTypeSelected?.EventTypeAvailability.find(
      (ETA: any) => ETA.day === momentDay.format('dddd').toUpperCase(),
    );

    const selectedDayEvents = events.filter((event) => momentDay.isSame(event.start, 'day'));
    const filteredBrackets = availability?.EventTypeAvailabilityBracket.filter((bracket) => {
      const bracketStartDate = moment(momentDay).set({ hours: bracket.startHour, minutes: bracket.startMinute });
      const bracketEndDate = moment(momentDay).set({ hours: bracket.endHour, minutes: bracket.endMinute });
      const isEventFound = selectedDayEvents.some((evt) => {
        if (moment(evt.start).isBetween(bracketStartDate, bracketEndDate, undefined, '[)')
          || moment(evt.end).isBetween(bracketStartDate, bracketEndDate, undefined, '(]')
          || bracketStartDate.isBetween(evt.start, evt.end, undefined, '[)')
          || bracketEndDate.isBetween(evt.start, evt.end, undefined, '(]')
        ) {
          return true;
        }
        return false;
      });

      // to preselect updated event time frame
      const event2Update = selectedDayEvents.find((evt) => evt.id === appointment2Update?.id);
      if (event2Update && bracketStartDate.isSame(appointment2Update.start)
        && bracketEndDate.isSame(appointment2Update.end)) {
        return true;
      }

      if (bracketStartDate.isBefore() || isEventFound) {
        return false;
      }
      return true;
    })
      .sort(
        (a, b) => {
          const aName = Number(`${a.startHour}${a.startMinute === 0 ? '00' : a.startMinute}`);
          const bName = Number(`${b.startHour}${b.startMinute === 0 ? '00' : b.startMinute}`);
          if (aName > bName) return 1;
          if (aName < bName) return -1;
          return 0;
        },
      ) || [];

    if (
      filteredBrackets.length > 0
      && (!eventType.maxEvents || eventType.maxEvents > selectedDayEvents.length)
    ) {
      setBrackets(filteredBrackets);

      const startHour = momentDay.hours();
      const startMinute = momentDay.minutes();
      const bracketFound = filteredBrackets.find(
        (bracket) => bracket.startHour === startHour && bracket.startMinute === startMinute,
      );

      if (bracketFound) {
        newAppointmentForm.setFieldsValue({
          time: getDate(bracketFound),
        });
      } else {
        newAppointmentForm.setFieldsValue({
          time: undefined,
        });
      }
    } else {
      setBrackets([]);
      message.error('There are no more appointments available for this day. You can change availability in Appointment Types.');
    }
  };

  useEffect(() => {
    if (selectedDate && selectedDate >= moment().startOf('date').toDate()) {
      selectDay(selectedDate);
    }
  }, [selectedDate]);

  const handleSave = async () => {
    const values: NewAppointmentFormValues = newAppointmentForm.getFieldsValue(true);
    const hoursDetails = parseHoursRange(values.time);
    const { startHour, endHour } = hoursDetails;
    const startDate = selectedDay
      .set({
        hours: startHour.hours,
        minutes: startHour.minutes,
      })
      .format();
    const endDate = selectedDay
      .set({
        hours: endHour.hours,
        minutes: endHour.minutes,
      })
      .format();

    const theClient = clients.find(
      (c) => c.Entity.Users.id === values.client,
    );

    if (appointment2Update?.id) {
      await updateEventMutation({
        variables: {
          input: {
            id: appointment2Update?.id,
            title:
              `${theClient.Entity.Users.UserInformation?.firstName} ${theClient.Entity.Users.UserInformation?.lastName}`,
            type: values.meetingType,
            eventTypeId: values.eventType,
            clientId: values.client,
            startDate,
            endDate,
            notes: values.notes,
          },
        },
      });

      trackCustomerIoEvent(CUSTOMER_IO_EVENTS.CLICK, {
        Title: 'Update an appointment',
        Source: 'Button click',
        url: window.location.href,
      });
    } else {
      await createEventMutation({
        variables: {
          input: {
            title:
              `${theClient.Entity.Users.UserInformation?.firstName} ${theClient.Entity.Users.UserInformation?.lastName}`,
            type: values.meetingType,
            eventTypeId: values.eventType,
            clientId: values.client,
            startDate,
            endDate,
            notes: values.notes,
          },
        },
      });

      trackCustomerIoEvent(CUSTOMER_IO_EVENTS.CLICK, {
        Title: 'Create an appointment',
        Source: 'Button click',
        url: window.location.href,
      });
    }

    handleCancel();
  };

  // set values according to selected appointment (update appointment)
  useEffect(() => {
    if (appointment2Update?.id) {
      newAppointmentForm.setFieldsValue({
        client: appointment2Update.clientId,
        meetingType: appointment2Update.meetingType,
        eventType: appointment2Update.eventTypeId,
        notes: appointment2Update.notes,
      });

      selectDay(appointment2Update.start, appointment2Update.eventTypeId);
    }
  }, [appointment2Update]);

  return (
    <Modal
      title={(
        <>
          {appointment2Update?.clientId ? 'Update an Appointment' : 'Schedule an Appointment'}
          <a
            href="https://help.taxaroo.com/article/79-how-to-schedule-appointment"
            target="_blank"
            rel="noreferrer"
          >
            <InfoCircleOutlined
              style={{
                fontSize: '16px',
                position: 'relative',
                top: '-0.5em',
                left: '0.5em',
              }}
            />
          </a>
        </>
      )}
      visible={visible}
      onOk={handleSave}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: !client || !time || !day,
        loading: createEventLoading || updateEventLoading,
      }}
      okText={appointment2Update?.id ? 'Update' : 'Save'}
      keyboard={false}
    >
      <Form
        layout="vertical"
        form={newAppointmentForm}
        // eslint-disable-next-line no-template-curly-in-string
        validateMessages={{ required: '${label} is required' }}
        initialValues={initialformValues}
      >
        <Form.Item
          name="client"
          label="Client"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder="Type the client name here to select"
            optionFilterProp="children"
            filterOption={filterOption}
            loading={isTaxPayersLoading}
          >
            {clients.map((c) => (
              <Option
                value={c.Entity.Users.id}
                key={c.Entity.Users.id}
              >
                {`${c.Entity.Users.UserInformation?.firstName} ${c.Entity.Users.UserInformation?.lastName}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="meetingType"
          label="Meeting Type"
          rules={[{ required: true }]}
        >
          <Select>
            <Option value="VIDEO" key="1">Video</Option>
            <Option value="CALL" key="2">Phone Call</Option>
            <Option value="HOME" key="3">Home</Option>
            <Option value="OFFICE" key="4">Office</Option>
            <Option value="OTHER" key="5">Other place of service</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="eventType"
          label="Type"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder="Type"
            optionFilterProp="children"
            filterOption={filterOption}
            notFoundContent="Please define Appointment Types in Appointment Types tab"
            loading={loadingEventTypes}
            onChange={() => {
              newAppointmentForm.setFieldsValue({
                day: undefined,
                time: undefined,
              });
              setSelectedDay(undefined);
            }}
          >
            {eventTypes.map((type) => (
              <Option value={type.id} key={type.id}>
                {type.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="day"
          label="Date"
          rules={[{ required: true }]}
        >
          <Select
            open={selectDayDropdownOpened}
            onDropdownVisibleChange={(value: boolean) => setSelectDayDropdownOpened(value)}
            // eslint-disable-next-line react/no-unstable-nested-components
            dropdownRender={() => (
              <Calendar
                value={appointment2Update?.start || new Date()}
                onChange={(val: Date) => selectDay(val)}
                tileDisabled={disabledDate}
                className="react-calendar-dropdown"
              />
            )}
          />
        </Form.Item>
        <Form.Item
          name="time"
          label="Local Time"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder="Time"
            optionFilterProp="children"
            filterOption={filterOption}
            disabled={!day}
          >
            {brackets.map((t, index) => (
              <Option value={getDate(t)} key={index}>
                {getDate(t)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="notes"
          label="Notes"
        >
          <TextArea
            placeholder="Notes"
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

NewAppointmentModal.defaultProps = {
  appointment2Update: {
    id: '',
    clientId: '',
    meetingType: '',
    eventTypeId: '',
    start: undefined,
    end: undefined,
    notes: '',
  },
};

export default NewAppointmentModal;
