import { useState } from 'react';
import {
  Button,
  Modal,
  List,
  Icons,
  Typography,
  message,
} from 'taxaroo-ui';
import { useCurrentStripeInformationQuery, useGetAllPlansQuery, useGetSubscriptionQuery } from '~src/graphql/queries/settings';
import config from '~src/components/helpers/config';
import { useGetStripeCustomerQuery } from '~src/graphql/queries/stripeInformation';
import { useCreateBillingPortalSessionMutation, useCreateCheckoutSessionMutation } from '~src/graphql/mutations/stripeInformation';
import { getUrlWithAddedSearchParams } from '~src/components/helpers/searchParams';
import { useAppSelector } from '~src/redux/hooks';
import { useUpdateSubscriptionMutation } from '~src/graphql/mutations/settings';
import { Features, useUpgradeModal } from './ModalProvider';

const { Title } = Typography;
const { CheckCircleOutlined } = Icons;

const proFeaturesList = [
  {
    title: 'IRS e-Signatures',
  },
  {
    title: 'Payments (credit & ACH)',
  },
  {
    title: 'Automated followups',
  },
  {
    title: 'Appointment Scheduling',
  },
  {
    title: 'AI-assisted answers',
  },
  {
    title: 'AI tax research',
  },
  {
    title: 'Mapped portal drive',
  },
  {
    title: 'Export to Tax Software (Drake)',
  },
  {
    title: 'Entity interviews',
  },
  {
    title: 'Video calls',
  },
  {
    title: 'Preparer Reviews',
  },
  {
    title: 'Birthday emails',
  },
  {
    title: 'Zapier Integration',
  },
  {
    title: 'Marketing Pixels',
  },
  {
    title: '24 hour email support',
  },
  {
    title: 'Team member accounts',
  },
];

const liteFeaturesList = [
  {
    title: 'Interviews',
  },
  {
    title: 'Document uploads',
  },
  {
    title: 'Messaging',
  },
  {
    title: 'White Labeling',
  },
  {
    title: 'Analytics',
  },
  {
    title: 'Spanish Workflows',
  },
  {
    title: 'Referral Program',
  },
  {
    title: 'Video calls',
  },
  {
    title: 'Payments',
  },
  {
    title: 'Pixels',
  },
  {
    title: 'Birthday emails',
  },
];

const getLabel = (feature: Features) => {
  switch (feature) {
    case Features.signatures:
      return 'Signatures are';
    case Features.approvals:
      return 'Approvals are';
    case Features.followUps:
      return 'Follow ups are';
    case Features.aiMessaging:
      return 'AI Messaging are';
    case Features.mappedDrive:
      return 'Mapped Drive are';
    case Features.entityInterviews:
      return 'Entity Interviews are';
    case Features.reviews:
      return 'Reviews are';
    case Features.appointments:
      return 'Appointments are';
    case Features.aiResearch:
      return 'AI Research is';
    case Features.export:
      return 'Export to Drake is';
    case Features.videoCalls:
      return 'Video Calls are';
    case Features.birthdayEmails:
      return 'Birthday Emails are';
    case Features.pixels:
      return 'Pixels are';
    case Features.teamMembers:
      return 'Team Members are';
    default:
      return '';
  }
};

const UpgradeModal = () => {
  const [modalType, setModalType] = useState<'lite' | 'pro'>('pro');

  const {
    isModalVisible,
    hideModal,
    feature,
    closable,
  } = useUpgradeModal();

  const { email } = useAppSelector((state) => state.session);

  const [createBillingPortalSession, { loading: loadingBillingPortalSession }] = useCreateBillingPortalSessionMutation();
  // create checkout session mutation
  const [createCheckoutSession] = useCreateCheckoutSessionMutation({
    onError: (err) => {
      message.error(err.message);
    },
  });
  // update subscription
  const [updateSubscritpion, { loading: loadingUpdateSubscription }] = useUpdateSubscriptionMutation();

  const { data: subscriptionData, loading: loadingSubscription } = useGetSubscriptionQuery();
  const subscription = subscriptionData?.GetSubscriptionByContext;
  const isOnFreePlan = subscription?.items.data[0]?.plan?.id === config?.stripeFreePlanPriceId;
  const isOnLitePlan = subscription?.items.data[0]?.plan?.id === config?.stripeLitePlanPriceId;
  const isCurrentlyPaused = subscription?.pause_collection?.behavior || subscription?.status === 'paused';
  // we also want to get the customer, because we need to check the customre for a default payment method
  const { data: currentStripeInformation } = useCurrentStripeInformationQuery();
  const { customerId } = currentStripeInformation?.CurrentStripeInformation ?? {};
  const { data: customerData } = useGetStripeCustomerQuery({
    variables: {
      getStripeCustomerInput: {
        customerId,
      },
    },
  });
  const customer = customerData?.getStripeCustomer;
  const defaultPaymentMethod = customer?.invoice_settings?.default_payment_method || customer?.default_source;

  const resumePlan = () => {
    createBillingPortalSession({
      variables: {
        createBillingPortalInput: {
          customer: customerId,
          return_url: window.location.href,
        },
      },
    }).then(({ data: billingPortalData }) => {
      window.location.href = billingPortalData?.createBillingPortalSession;
    });
  };

  const createUpgradeBillingPortalSession = () => {
    createBillingPortalSession({
      variables: {
        createBillingPortalInput: {
          customer: customerId,
          flow_data: {
            type: 'subscription_update_confirm',
            subscription_update_confirm: {
              subscription: subscription?.id,
              items: [
                {
                  id: subscription?.items.data[0].id,
                  quantity: modalType === 'lite' ? 1 : 2,
                  price: modalType === 'lite' ? config.stripeLitePlanPriceId : config.stripeMonthlyPlanPriceId,
                },
              ],
            },
            after_completion: {
              type: 'redirect',
              redirect: {
                return_url: `${window.location.origin}${getUrlWithAddedSearchParams([
                  { key: 'upgraded', value: '1' },
                  { key: 'subscribeToYearly', value: modalType === 'pro' ? '1' : '0' },
                ])}`,
              },
            },
          },
          return_url: window.location.href,
        },
      },
    }).then(({ data: billingPortalData }) => {
      window.location.href = billingPortalData?.createBillingPortalSession;
    });
  };

  const createTheCheckoutSession = () => {
    let priceId = '';
    if (modalType === 'pro') {
      priceId = config.stripeMonthlyPlanPriceId;
    } else if (modalType === 'lite') {
      priceId = config.stripeLitePlanPriceId;
    }
    createCheckoutSession({
      variables: {
        createCheckoutSessionInput: {
          customerId,
          customerEmail: email,
          quantity: modalType === 'lite' ? 1 : 2,
          adjustableQuantity: modalType === 'lite'
            ? {
              enabled: false,
            }
            : {
              enabled: true,
              maximum: 99,
              minimum: 2,
            },
          priceId,
          success_url: `${window.location.origin}`,
          cancel_url: `${window.location.origin}`,
        },
      },
    }).then(({ data }) => {
      if (data?.createCheckoutSession) {
        window.location.href = data?.createCheckoutSession;
      }
    }).catch((error) => {
      message.error(`Error creating checkout session - ${error.message}. Please contact customer support for assistance`, 10);
    });
  };

  const handleSwitchToPlan = (priceId: string, quantity: number) => {
    updateSubscritpion({
      variables: {
        resume: isCurrentlyPaused ? true : undefined,
        updateStripeSubscriptionInput: {
          items: [{
            id: subscription?.items?.data[0]?.id,
            price: priceId,
            quantity,
          }],
        },
      },
      refetchQueries: ['GetSubscription', 'GetPlanAccessLevel'],
      onCompleted: () => {
        message.success('Subscription updated!');
        hideModal();
        setModalType('pro');
      },
      onError: () => {
        // error could be that the user is on a free plan and has no payment method
        if (priceId === config.stripeLitePlanPriceId) {
          createTheCheckoutSession();
        }
        if (priceId === config.stripeMonthlyPlanPriceId) {
          createUpgradeBillingPortalSession();
        }
      },
    });
  };

  const upgradePlan = () => {
    // if upgrading to pro plan we can create billing portal session if upgrading to lite we use checkout session
    if (modalType === 'pro') {
      if (defaultPaymentMethod) {
        handleSwitchToPlan(config.stripeMonthlyPlanPriceId, 2);
      } else {
        createUpgradeBillingPortalSession();
      }
    }
    if (modalType === 'lite') {
      // if there is a payment method on file, try to upgrade, otherwise create a checkout session
      if (defaultPaymentMethod) {
        handleSwitchToPlan(config.stripeLitePlanPriceId, 1);
      } else {
        createTheCheckoutSession();
      }
    }
  };

  const updatePaymentMethod = () => {
    createBillingPortalSession({
      variables: {
        createBillingPortalInput: {
          customer: customerId,
          flow_data: {
            type: 'payment_method_update',
            after_completion: {
              type: 'hosted_confirmation',
              hosted_confirmation: {
                custom_message: 'Your payment method has been successfully added!',
              },
            },
          },
          return_url: window.location.href,
        },
      },
    }).then(({ data: billingPortalData }) => {
      window.location.href = billingPortalData?.createBillingPortalSession;
    });
  };

  const getButtonLabel = () => {
    console.log('isCurrentlyPaused', isCurrentlyPaused);
    console.log('modalType', modalType);
    console.log('isOnLitePlan', isOnLitePlan);
    console.log('isOnFreePlan', isOnFreePlan);
    if (isCurrentlyPaused && ((modalType === 'lite' && isOnLitePlan) || (modalType === 'pro' && !isOnLitePlan && !isOnFreePlan))) { // if paused and modal type is same as current subscription type, we want to resume subscription
      return 'Resume Subscription';
    } if (!isOnFreePlan && !isOnLitePlan && subscription?.status !== 'active') { // already on pro plan, but must not be active
      return 'Add Payment Method';
    }
    return `Upgrade to ${modalType === 'lite' ? 'Lite Plan' : 'Pro Plan'}`;
  };

  const handleUpgrade = () => {
    // // if paused and modal type is same as current subscription type, we want to resume subscription
    if (isCurrentlyPaused) { // if paused, stripe billing portal will not create a flow session
      // prompt user to resume subscription
      resumePlan();
    } else if (!isOnFreePlan && !isOnLitePlan && subscription?.status !== 'active') { // already on pro plan, but must not be active
      // prompt user to add or update payment method
      updatePaymentMethod();
    } else { // not on pro plan, upgrade to appropriate plan
      // prompt user to upgrade if they have no default payment method they will be prompted to add a one
      upgradePlan();
    }
  };

  const onCancel = () => {
    if (modalType === 'lite') {
      hideModal();
      setModalType('pro'); // for next time
      return;
    }
    // from here on out we're on a pro modal
    if (!isOnFreePlan && !isOnLitePlan) { // already on pro plan (maybe failed payment or something)
      hideModal();
      setModalType('pro'); // for next time
      return;
    }
    if (isOnFreePlan) { // on free plan, suggest lite plan
      setModalType('lite');
      return;
    }
    if (isOnLitePlan && (isCurrentlyPaused || (subscription.status !== 'active' && subscription.status !== 'trialing'))) { // on lite plan and (paused or in an unacceptable state)
      setModalType('lite');
      return;
    }
    hideModal();
    setModalType('pro'); // for next time
  };

  if (loadingSubscription) {
    return null;
  }

  return (
    <Modal
      title={modalType === 'lite' ? 'Okay gotcha, you’re not ready for our Pro Plan yet' : 'Upgrade to Pro Plan'}
      visible={isModalVisible}
      onCancel={onCancel}
      closable={closable}
      maskClosable={closable}
      width={700}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={handleUpgrade}
          loading={loadingBillingPortalSession || loadingUpdateSubscription}
        >
          {getButtonLabel()}
        </Button>,
      ]}
    >
      <div>
        <div>
          <div>
            {modalType === 'lite'
              ? 'Consider upgrading to our Lite Plan to get access to all the features below at just $395 a year.'
              : `${getLabel(feature)} not available in your current plan. Upgrade to Pro to enable this feature.`}
          </div>
          <br />
          <Title level={5}>
            {`Included in the ${modalType === 'lite' ? 'Lite Plan' : 'Pro Plan'}:`}
          </Title>
          <List
            itemLayout="horizontal"
            dataSource={modalType === 'lite' ? liteFeaturesList : proFeaturesList}
            style={{
              height: '100%',
              maxHeight: '365px',
              overflow: 'hidden scroll',
            }}
            grid={{
              gutter: 10,
              xs: 1,
              sm: modalType === 'lite' ? 1 : 2,
              md: modalType === 'lite' ? 1 : 2,
              lg: modalType === 'lite' ? 1 : 2,
              xl: modalType === 'lite' ? 1 : 2,
              xxl: modalType === 'lite' ? 1 : 2,
            }}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<CheckCircleOutlined style={{ color: 'green' }} />}
                  title={item.title}
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UpgradeModal;
