.chatContainer {
  display: flex;
  align-items: center;
  height: calc(100vh - 120px);
}

.chatContainer > div {
  background-color: white;
}

@media only screen and (max-device-width: 700px) {
  .chatContainer {
    width: 100%;
    align-items: unset;
    flex-direction: column;
  }
}
