import React from 'react';
import {
  TableProps,
} from 'antd';
import { Props } from 'react-infinite-scroll-component';
import Table from '../Table';
import Wrapper from './Wrapper';

export interface InfiniteScrollTableProps<RecordType extends object = any>
  extends TableProps<RecordType> {
    infiniteScrollProps: Props,
}

const TaxInfiniteScrollTable: React.FC<InfiniteScrollTableProps> = ({
  infiniteScrollProps,
  ...rest
}) => (
  <Wrapper
    isInfiniteScrollOn
    infiniteScrollProps={infiniteScrollProps}
  >
    <Table
      {...rest}
    />
  </Wrapper>
);

export default TaxInfiniteScrollTable;
