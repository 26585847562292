import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllEntityDataByEntityIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetAllEntityDataByEntityIdQuery = { __typename?: 'Query', GetAllEntityDataByEntityId: { __typename?: 'Entity', id: string, Users?: { __typename?: 'UserEntity', id: string, email?: string | null, has2Fa?: boolean | null, Languages?: { __typename?: 'LanguagesEntity', id: string, description?: string | null } | null, Files?: Array<{ __typename?: 'FileEntity', id: string }> | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, firstName: string, lastName: string, birthday?: any | null, notes?: string | null, Phones?: Array<{ __typename?: 'PhonesEntity', id: string, value: string, type: Types.PhoneType, createAt: any, updateAt: any, userInformationId?: string | null }> | null, Address?: Array<{ __typename?: 'AddressEntity', lineOne: string, lineTwo?: string | null, city: string, state: string, zip: string, type: Types.AddressType }> | null } | null } | null, TaxYear?: Array<{ __typename?: 'TaxYearEntity', id: string, year: number, TaxYearInterview?: Array<{ __typename?: 'TaxYearInterviewEntity', id: string, notes?: string | null, progressStatusId?: string | null, completedAt?: any | null, JobTracking?: { __typename?: 'JobTrackingEntity', id: string, expectedCompletion?: any | null, paymentsCompletedAt?: any | null, approvalsCompletedAt?: any | null, interviewCompletedAt?: any | null, filesCompletedAt?: any | null } | null, Interviews?: { __typename?: 'InterviewEntity', id: string, name: string } | null, ProgressStatuses?: { __typename?: 'ProgressStatusesEntity', id: string, status: string, underlyingStatus: Types.TaxYearStatus, index: number } | null, Tasks?: Array<{ __typename?: 'TaskEntity', id: string, taskType?: Types.TaskType | null, completedAt?: any | null }> | null }> | null }> | null, UserFirmAccount?: Array<{ __typename?: 'UserFirmAccountEntity', id: string, deleteAt?: any | null }> | null } };


export const GetAllEntityDataByEntityIdDocument = gql`
    query GetAllEntityDataByEntityId($id: String!) {
  GetAllEntityDataByEntityId(id: $id) {
    id
    Users {
      id
      email
      has2Fa
      Languages {
        id
        description
      }
      Files {
        id
      }
      UserInformation {
        id
        firstName
        lastName
        birthday
        notes
        Phones {
          id
          value
          type
          createAt
          updateAt
          userInformationId
        }
        Address {
          lineOne
          lineTwo
          city
          state
          zip
          type
        }
      }
    }
    TaxYear {
      id
      year
      TaxYearInterview {
        id
        notes
        progressStatusId
        completedAt
        JobTracking {
          id
          expectedCompletion
          paymentsCompletedAt
          approvalsCompletedAt
          interviewCompletedAt
          filesCompletedAt
        }
        Interviews {
          id
          name
        }
        ProgressStatuses {
          id
          status
          underlyingStatus
          index
        }
        Tasks {
          id
          taskType
          completedAt
        }
      }
    }
    UserFirmAccount {
      id
      deleteAt
    }
  }
}
    `;

/**
 * __useGetAllEntityDataByEntityIdQuery__
 *
 * To run a query within a React component, call `useGetAllEntityDataByEntityIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEntityDataByEntityIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEntityDataByEntityIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAllEntityDataByEntityIdQuery(baseOptions: Apollo.QueryHookOptions<GetAllEntityDataByEntityIdQuery, GetAllEntityDataByEntityIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEntityDataByEntityIdQuery, GetAllEntityDataByEntityIdQueryVariables>(GetAllEntityDataByEntityIdDocument, options);
      }
export function useGetAllEntityDataByEntityIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEntityDataByEntityIdQuery, GetAllEntityDataByEntityIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEntityDataByEntityIdQuery, GetAllEntityDataByEntityIdQueryVariables>(GetAllEntityDataByEntityIdDocument, options);
        }
export type GetAllEntityDataByEntityIdQueryHookResult = ReturnType<typeof useGetAllEntityDataByEntityIdQuery>;
export type GetAllEntityDataByEntityIdLazyQueryHookResult = ReturnType<typeof useGetAllEntityDataByEntityIdLazyQuery>;
export type GetAllEntityDataByEntityIdQueryResult = Apollo.QueryResult<GetAllEntityDataByEntityIdQuery, GetAllEntityDataByEntityIdQueryVariables>;
export const namedOperations = {
  Query: {
    GetAllEntityDataByEntityId: 'GetAllEntityDataByEntityId'
  }
}