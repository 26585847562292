import { FC } from 'react';
import {
  Button, Icons,
} from 'taxaroo-ui';
import { useResendActivationMutation } from '~src/graphql/mutations/clients';
import { handleOnError, onSuccessResendActivation } from './utils';

const {
  SendOutlined,
} = Icons;

interface ActivationEmailProps {
  userId: string
}

const ResendActivationEmailButton: FC<ActivationEmailProps> = ({
  userId,
}) => {
  const [resendEmailActivation, {
    loading: loadingResendEmailActivation,
  }] = useResendActivationMutation({
    onCompleted: onSuccessResendActivation,
    onError: handleOnError,
  });

  const handleSendActivation = async () => {
    await resendEmailActivation({
      variables: {
        userId,
      },
    });
  };

  return (
    <Button
      type="link"
            // size="small"
      icon={<SendOutlined />}
      iconDirection="left"
      loading={loadingResendEmailActivation}
      onClick={() => handleSendActivation()}
    >
      Send Activation Email
    </Button>
  );
};

export default ResendActivationEmailButton;
