import React, {
  ChangeEvent,
  FC, useEffect, useRef, useState,
} from 'react';
import {
  Modal, Tooltip, Spin, Checkbox, Icons,
} from 'taxaroo-ui';
import style from './style.module.css';

const { InfoCircleOutlined } = Icons;

export interface UiOption {
  value: string;
  label: string;
  checked: boolean;
}

interface ConditionalModalProps {
  visible: boolean;
  loading: boolean;
  isEditing: boolean;
  onCancel: () => void;
  options: Array<UiOption>;
  onOk: (result: { selectedOptions: Array<UiOption> }) => void;
}

const ConditionalModal: FC<ConditionalModalProps> = ({
  visible, options, isEditing, onCancel, onOk, loading,
}) => {
  const initialRender = useRef<boolean>(true);
  const [initialOptions, setInitialOptions] = useState<Array<UiOption>>(options);
  const [optionCheckboxes, setOptionCheckboxes] = useState<Array<UiOption>>(options);

  const handleOnOk = () => {
    if (!isEditing) {
      onOk({ selectedOptions: optionCheckboxes.filter(({ checked }) => checked) });
      setInitialOptions([]);
    } else {
      onOk({
        selectedOptions: optionCheckboxes.filter(({ value, checked }) => {
          const currentOption = initialOptions
            .find(({ value: optionId }) => optionId === value);

          return !!currentOption && (currentOption.checked !== checked);
        }),
      });
      setInitialOptions([]);
    }
  };

  const handleSelectedOptionsChange = (event: ChangeEvent) => {
    setOptionCheckboxes((previous) => previous.map((option) => {
      if (event.target.id === option.value) {
        return {
          ...option,
          checked: !option.checked,
        };
      }

      return option;
    }));
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    }

    if (!initialRender.current && !initialOptions.length) {
      setInitialOptions(options);
    }
    setOptionCheckboxes(options);
  }, [options]);

  return (visible ? (
    <Modal
      visible={visible}
      onCancel={onCancel}
      okText="Save"
      okButtonProps={{
        disabled: !optionCheckboxes.length,
      }}
      onOk={handleOnOk}
      title={(
        <div className={style.modalHeader}>
          <h2>
            {`${isEditing ? 'Update' : 'Create'}`}
            {' '}
            Conditionals
          </h2>
          <Tooltip placement="bottom" title="Consists of selecting the options of the questions of the parent section with which you want this sub-section to be active/visible to the client.">
            <InfoCircleOutlined size="small" />
          </Tooltip>
        </div>
)}
    >
      <div className={style.modalContentContainer}>
        {
          loading && (<Spin />)
        }
        <div className={style.selectContainer}>
          <strong>{optionCheckboxes.length ? 'Check the desired options to condition this section' : 'There is no options to condition this section'}</strong>
          <div className={style.checkboxContainer}>
            {
            optionCheckboxes.map(({ label, checked, value }) => (
              <Checkbox
                id={value}
                checked={checked}
                onChange={handleSelectedOptionsChange}
              >
                {label}
              </Checkbox>
            ))
          }
          </div>
        </div>
        <div className={style.selectContainer} />
      </div>
    </Modal>
  ) : null);
};

export default ConditionalModal;
