import { Icons, Switch } from 'taxaroo-ui';
import ActionButton from '~src/components/molecules/ActionButton';
import {
  useUpdateClientFileMutation,
} from '~src/graphql/mutations/clients';
import { handleOnError, onSuccessMutation } from './utils';

const { Circle, CheckCircleOutlined } = Icons;

export enum ClientFileUsedUpdateType {
  Used,
  Reviewed,
}

interface UpdateClientFileButtonProps {
  type: ClientFileUsedUpdateType;
  id: string;
  used: boolean;
}

const ClientFileUsedUpdate = ({
  type,
  id,
  used,
}: UpdateClientFileButtonProps) => {
  const [updateClientFile, { loading: loadingUpdateFile }] = useUpdateClientFileMutation({
    variables: {
      updateFileInput: {
        id,
        documentUsed: type === ClientFileUsedUpdateType.Used ? !used : undefined,
        documentReviewed: type === ClientFileUsedUpdateType.Reviewed ? !used : undefined,
      },
    },
    onCompleted: onSuccessMutation,
    onError: handleOnError,
  });

  const handleUpdateClientFile = async () => {
    await updateClientFile();
  };

  // if (used) {
  //   return (
  //     <Switch
  //       tooltip="Change value"
  //       onClick={() => handleUpdateClientFile()}
  //       loading={loadingUpdateFile}
  //     >
  //       {/* <CheckCircleOutlined style={{ color: 'green' }} /> */}
  //     </Switch>
  //   );
  // }
  return (
    <Switch
      tooltip="Change value"
      onClick={() => handleUpdateClientFile()}
      loading={loadingUpdateFile}
      checked={used}
    >
      {/* <Circle /> */}
    </Switch>
  );
};

export default ClientFileUsedUpdate;
