import { FC, useEffect, useState } from 'react';
import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import {
  Typography,
  Row,
  Col,
  Input,
  Layout,
  Icons,
  Form,
  Alert,
  Spin,
  message,
  Switch,
} from 'taxaroo-ui';

import SubmitButton from '~src/components/Formik/SubmitButton';
import ActionButton from '~src/components/molecules/ActionButton';
import { ApolloError } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import {
  GetFirmSettingDocument, GetNylasAccountsDocument, useGetFirmSettingQuery, useGetNylasAccountsQuery,
} from '~src/graphql/queries/settings';
import {
  useCreateFirmSettingMutation, useDeleteNylasAccountMutation, useGetNylasAuthUrlMutation, useHandleNylasAuthCallbackMutation, useUpdateFirmSettingMutation,
} from '~src/graphql/mutations/settings';
import SettingsHeader from '../SettingsHeader';

const { DeleteOutlined, ToolFilled, WarningFilled } = Icons;
const { Paragraph, Text, Title } = Typography;
const validationSchema = yup.object().shape({
  email: yup.string().email('Please enter a valid email').required('Email is required'),
});

const Calendars: FC = () => {
  const [getAuthUrl, { loading: loadingAuthUrl, error: errorAuthUrl }] = useGetNylasAuthUrlMutation();
  const handleEmailSync = async (email: string) => {
    const result = await getAuthUrl({
      variables: {
        input: {
          email,
          callbackUrl: `${window.location.origin}/settings/calendars`,
        },
      },
    });

    window.location.href = result.data.getNylasAuthUrl.url;
  };

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema,
    onSubmit: (values) => { handleEmailSync(values.email); },
  });
  const {
    touched, values, errors, handleSubmit, handleChange,
  } = formik;
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnError = (error: ApolloError) => {
    message.error(`An error has occurred: ${error.message}.`);
  };

  const {
    data: accounts,
    loading: loadingAccounts,
    error: errorAccounts,
  } = useGetNylasAccountsQuery();

  const [deleteNylasAccountMutation, { loading: loadingDeleteNylasAccount }] = useDeleteNylasAccountMutation({
    onError: handleOnError,
    refetchQueries: [GetNylasAccountsDocument],
    awaitRefetchQueries: true,
  });
  const handleDeleteAccount = async (accountId: string) => {
    await deleteNylasAccountMutation({ variables: { accountId } });
  };

  const [authUrlCallback, { loading: loadingAuthUrlCallback }] = useHandleNylasAuthCallbackMutation({
    onError: handleOnError,
    refetchQueries: [GetNylasAccountsDocument],
    awaitRefetchQueries: true,
  });

  const {
    data: firmSettingsData,
    loading: firmSettingsLoading,
    error: firmSettingsError,
  } = useGetFirmSettingQuery({ variables: { name: 'disable-taxaroo-calendar' } });
  const [disableTaxarooCalendarValue, setDisableTaxarooCalendarValue] = useState<boolean>(false);

  const [createFirmSetting, { loading: createFirmSettingLoading }] = useCreateFirmSettingMutation({
    onError: handleOnError,
    refetchQueries: [{ query: GetFirmSettingDocument, variables: { name: 'disable-taxaroo-calendar' } }],
    awaitRefetchQueries: true,
  });
  const [updateFirmSetting, { loading: updateFirmSettingLoading }] = useUpdateFirmSettingMutation({
    onError: handleOnError,
    refetchQueries: [{ query: GetFirmSettingDocument, variables: { name: 'disable-taxaroo-calendar' } }],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (firmSettingsData?.getFirmSetting?.name) {
      // eslint-disable-next-line no-underscore-dangle
      if (firmSettingsData.getFirmSetting.value.__typename === 'BooleanValue') {
        setDisableTaxarooCalendarValue(firmSettingsData.getFirmSetting.value.boolValue);
      }
    }
  }, [firmSettingsData?.getFirmSetting]);

  useEffect(() => {
    if (searchParams.get('state') && searchParams.get('code')) {
      const code = searchParams.get('code');
      const handleAuthUrlCallback = async (theCode: string) => {
        await authUrlCallback({ variables: { input: { code: theCode } } });
      };
      handleAuthUrlCallback(code);
    }
  }, []);

  useEffect(() => {
    if (errorAccounts) {
      return handleOnError(errorAccounts);
    } if (errorAuthUrl) {
      return handleOnError(errorAuthUrl);
    } if (firmSettingsError) {
      return handleOnError(firmSettingsError);
    }
    return null;
  }, [errorAccounts, errorAuthUrl, firmSettingsError]);

  const changeDisableTaxarooCalendarSetting = async (val: boolean) => {
    if (firmSettingsData?.getFirmSetting?.id) {
      await updateFirmSetting({
        variables: {
          id: firmSettingsData.getFirmSetting.id,
          value: String(val),
        },
      });
    } else {
      await createFirmSetting({
        variables: {
          name: firmSettingsData.getFirmSetting.name,
          value: String(val),
        },
      });
    }
  };

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <SettingsHeader
        title="Calendars"
        tooltipTitle="What do we do with this information?"
        tooltipContent={(
          <Paragraph>
            <Text>Calendars</Text>
          </Paragraph>
        )}
      />
      <Col style={{ paddingTop: '10px' }}>
        <Typography>
          <Paragraph>
            <Text>Synchronize your Google or Outlook calendar</Text>
          </Paragraph>
        </Typography>

        <FormikProvider value={formik}>
          <Form
            name="basic"
            onFinish={handleSubmit}
            style={{ paddingBottom: '25px' }}
          >
            <Row align="middle">
              <Input
                style={{ width: '50%' }}
                name="email"
                onChange={handleChange}
                value={values.email}
                placeholder="Email"
                disabled={loadingAccounts || loadingDeleteNylasAccount || loadingDeleteNylasAccount}
              />
              {loadingAuthUrl
                ? <Spin size="large" />
                : (
                  <SubmitButton
                    label="+"
                    disabled={loadingAccounts || loadingDeleteNylasAccount || loadingDeleteNylasAccount}
                  />
                )}
            </Row>
            <Row align="middle">
              {touched.email && errors.email && (
              <Alert
                message={errors.email}
                type="error"
                style={{ width: '40%' }}
              />
              )}
            </Row>
          </Form>
        </FormikProvider>

        {loadingAccounts || loadingDeleteNylasAccount || loadingAuthUrlCallback
          ? (
            <Row align="middle" justify="center">
              <Spin size="large" />
            </Row>
          )
          : accounts.getNylasAccounts.map((account) => (
            <Row
              key={account.accountId}
              style={{ paddingBottom: '10px' }}
            >
              <Col style={{ width: '50%' }}>
                {account.syncState.includes('invalid')
                  && <WarningFilled style={{ padding: '0 15px 0 0' }} />}
                <Text>{account.emailAddress}</Text>
              </Col>
              <Col>
                <Row gutter={15}>
                  {account.syncState.includes('invalid')
                    && (
                    <Col>
                      {loadingAuthUrl
                        ? <Spin size="large" />
                        : (
                          <ActionButton
                            tooltip="Please try to authenticating again or use a different email account"
                            confirmAction={false}
                            onClick={() => handleEmailSync(account.emailAddress)}
                            buttonProps={{ type: 'default' }}
                          >
                            <ToolFilled />
                          </ActionButton>
                        )}
                    </Col>
                    )}
                  <Col>
                    <ActionButton
                      tooltip="Delete Email"
                      confirmAction
                      confirmLabel="Are you sure?"
                      onClick={() => handleDeleteAccount(account.accountId)}
                      buttonProps={{ type: 'primary' }}
                    >
                      <DeleteOutlined />
                    </ActionButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
      </Col>

      <Row style={{ paddingTop: '20px' }}>
        <Row style={{ width: '100%' }}>
          <Title level={5}>Settings</Title>
        </Row>

        {firmSettingsLoading && (
          <Row align="middle" justify="center">
            <Spin size="large" />
          </Row>
        )}

        {firmSettingsData?.getFirmSetting?.description && (
          <Row gutter={15} align="middle">
            <Col style={{ width: '60%' }}>
              <Text>
                {firmSettingsData.getFirmSetting.description}
              </Text>
            </Col>
            <Col>
              <Switch
                onChange={(val: boolean) => changeDisableTaxarooCalendarSetting(val)}
                checked={disableTaxarooCalendarValue}
                loading={createFirmSettingLoading || updateFirmSettingLoading}
              />
            </Col>
          </Row>
        )}
      </Row>
    </Layout>
  );
};

export default Calendars;
