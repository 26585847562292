import React, { useState, FC } from 'react';
import {
  Avatar, Typography, TaxarooButton, Badge, Tooltip, Icons, Row, Col,
} from 'taxaroo-ui';
import { useAppSelector } from '~src/redux/hooks';
import { selectSession } from '~src/redux/slices/sessionSlice';
import moment from 'moment';
import { ChannelsState } from '~src/redux/slices/chatSlice';
import AIMessagingSuggestion from '~src/components/molecules/AIMessagingSuggestion';
import { useUpgradeModal, Features } from '~src/components/atoms/UpgradeModal/ModalProvider';
import ScheduleCall from '../../molecules/ScheduleCall';
import AppointmentsDetail from '../../atoms/AppointmentsDetail';
import EmptyState from './EmptyState';
import * as styles from './style.module.css';
// import config from '../../helpers/config';
// import updateStep from '../../helpers/updateStep';
// import { TaxPayerContext } from '../../providers/taxpayer-provider';
// import VideoMuted from '../../../assets/image/camera-off.png';
// import Video from '../../../assets/image/camera-on.png';

const { Title, Text } = Typography;
const {
  CalendarOutlined, MailOutlined,
} = Icons;

const getAcronim = (str: string) => {
  if (str === '' || !str) {
    return '';
  }
  const matches = str.match(/\b(\w)/g);
  const acronym = matches?.join('');
  return acronym?.substring(0, 2).toUpperCase();
};

interface UserSidebarProps {
  channelInfo: ChannelsState
  // currentStep?: any
}

const UserSidebar: FC<UserSidebarProps> = ({ channelInfo }) => {
  // const taxpayer = useContext(TaxPayerContext);
  const [displaySchedule, setDisplaySchedule] = useState(false);
  // const taxpayerHref = `/account/clients/?clientId=${taxpayer?.ownerId}&taxYearInterviewId=${taxpayer?.urlTaxYearInterviewId}`;
  const { userId: currentUserId } = useAppSelector(selectSession);

  const clientInfo = channelInfo?.ChannelMembers
    .find(({ userId: memberUserId }) => memberUserId !== currentUserId);

  const { showModal, accessObject } = useUpgradeModal();

  const createCall = async () => {
    // const meeting = await axios({
    //   method: 'post',
    //   url: `${config.serverUrl}/meetings`,
    //   data: {
    //     userId: 0,
    //     channelName: '',
    //     attendees: [0, taxpayer.id],
    //   },
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // });

    // const { name } = meeting.data.data;

    // updateStep(token, 6);

    // eslint-disable-next-line max-len
    // window.open(`${config.clientUrl}/videocall/${name}${token ? `?token=${token}` : ''}`, '_blank');
  };

  const callPhone = (phone: string) => {
    const tab: Window | any = window.open('about:blank');
    tab.location = `tel:${phone}`;
    tab.focus();
    // window.open(`tel:${phone}`, "_blank");
  };

  // const getPhoneCallView = () => {
  // eslint-disable-next-line max-len
  //   let phone = taxpayer.mobile ? taxpayer.mobile.replace(/[^\w\s]/gi, '').replace(' ', '') : null;
  //   phone = phone || (taxpayer.phone
  //     ? taxpayer.phone.replace(/[^\w\s]/gi, '').replace(' ', '')
  //     : null);
  //   phone = phone || (taxpayer.phoneWork
  //     ? taxpayer.phoneWork.replace(/[^\w\s]/gi, '').replace(' ', '')
  //     : null);

  // eslint-disable-next-line max-len
  //   const tooltipTitle = phone ? `Do you wish to call ${phone} ?` : 'Client\'s phone number is not available';
  //   const disabled = !phone;
  //   return (
  //     <Tooltip placement="left" title={tooltipTitle}>
  //       <TaxarooButton
  //         // href={`tel:${'8384884848'}`}
  //         disabled={disabled}
  //         shape="circle"
  //         className={`${styles.btnSideBar}`}
  //         onClick={() => callPhone(phone)}
  //         icon={<MobileOutlined />}
  //       />
  //     </Tooltip>
  //   );
  // };

  const getChatName = () => {
    const otherMember = channelInfo.ChannelMembers
      ?.find(({ userId }) => userId !== currentUserId);

    if (otherMember) {
      const { firstName, lastName } = otherMember.Users.UserInformation || {};

      if (`${firstName} ${lastName}` !== channelInfo.description) {
        return `${firstName} ${lastName}`;
      }

      return channelInfo.description;
    }

    return '';
  };

  const userEmail = channelInfo?.ChannelMembers
    .find(({ userId: memberUserId }) => memberUserId !== currentUserId)?.Users?.email || '';

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {channelInfo?.description ? (
        <div className={styles.userSidebarContainer}>
          <Badge
            dot
            className={styles.userSidebarBadge}
            color={channelInfo.status ? 'green' : 'gray'}
          >
            <Avatar style={{ backgroundColor: '#22A7E7', fontSize: 25 }} size={80}>
              {getAcronim(getChatName())}
            </Avatar>
          </Badge>

          <Title className={styles.name} level={4}>
            {getChatName()}
          </Title>

          {channelInfo?.status
            ? (
              <Text className={styles.lastseen}>Active Now</Text>
            )
            : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {channelInfo.ChannelMembers
                  .find(({ userId: memberUserId }) => memberUserId !== currentUserId)
                  ?.lastConnection && (
                  <>
                    <Text className={styles.lastseen}>Last Seen:</Text>
                    <Text className={styles.lastseen}>
                      {moment(channelInfo.ChannelMembers
                        .find(({ userId: memberUserId }) => memberUserId !== currentUserId)
                        ?.lastConnection).fromNow()}
                    </Text>
                  </>
                )}
              </div>
            )}

          <Row justify="space-between" gutter={25}>
            <Col>
              <Tooltip placement="left" title="Schedule a Meeting">
                <TaxarooButton
                  shape="circle"
                  className={`${styles.btnSideBar}`}
                  icon={<CalendarOutlined />}
                  onClick={() => {
                    // check if user has access to appointments
                    if (!accessObject?.[Features.appointments]) {
                      showModal(Features.appointments);
                      return;
                    }
                    setDisplaySchedule(!displaySchedule);
                  }}
                />
              </Tooltip>
            </Col>
            {Boolean(userEmail) && (
              <Col style={{ paddingTop: '2px' }}>
                <Tooltip placement="left" title="Send Email">
                  <TaxarooButton
                    href={`mailto:${userEmail}`}
                    className={styles.btnSideBar}
                    shape="circle"
                    icon={<MailOutlined />}
                    target="_blank"
                  />
                </Tooltip>
              </Col>
            )}
            {/* <Col>
                {!channelInfo?.status ? (
                  <Tooltip placement="left" title="Client must be online in their portal for video calls">
                    <TaxarooButton
                      disabled={!channelInfo?.status}
                      shape="circle"
                      className={[styles.btnSideBar, styles.btnMedia].join(' ')}
                      onClick={createCall}
                      icon={<img alt="Video off" src={VideoMuted} />}
                    />
                  </Tooltip>
                ) : (
                  <TaxarooButton
                    disabled={!channelInfo?.status}
                    shape="circle"
                    className={styles.btnSideBar}
                    onClick={createCall}
                    icon={<VideoCameraOutlined />}
                  />
                )}
                </Col>
                <Col>
                { getPhoneCallView() }
              </Col> */}
          </Row>

          {displaySchedule ? (
            <ScheduleCall
              channelName={channelInfo?.description}
              organizerId={currentUserId}
              userId={clientInfo?.userId}
              closeSchedule={() => setDisplaySchedule(false)}
            />
          ) : (
            <AppointmentsDetail />
          )}
          {!displaySchedule && channelInfo?.id && channelInfo?.Messages?.length > 0 && (
            <AIMessagingSuggestion
              channelId={channelInfo?.id}
              channelInfo={channelInfo}
            />
          )}
        </div>
      ) : (
        <EmptyState />
      )}
    </>
  );
};

export default UserSidebar;
