import { ModalProps, Modal as AntModal } from 'antd';
// import { ModalFunc, ModalStaticFunctions } from 'antd/lib/modal/confirm';
import React from 'react';

const antConfirm = AntModal.confirm

export interface TaxModalProps extends ModalProps {
  /**
   * Modal display type
   */
	type?: 'default' | 'error' | 'warning' | 'success' | 'info'| 'confirm'
  /**
   * Prefix title icon
   */
  prefix?: React.ReactNode
  /**
   * Turns the modal title to a smaller size
   */
  smallTitle?: boolean
  /**
   * Got to include the children
   */
  children?: any
}

const Modal = ({
  type = 'default',
  title,
  prefix,
  className,
  smallTitle,
  children,
  ...rest
}: TaxModalProps) => (
  <AntModal
    {...rest}
    title={(
      <span>
        {prefix}
        {title}
      </span>
    )}
    className={[
      className,
      `modal-type-${type}`,
      smallTitle && 'small-title',
    ].filter(Boolean).join(' ')}
  >
    {children}
  </AntModal>
);

Modal.confirm = antConfirm;

export default Modal;
