/* eslint-disable quote-props */
/* eslint-disable max-len */
const enInternationalization = {
  translation: {
    'scheduleCall.error': 'There is no more dates available for this day.',
    'scheduleCall.callScheduled': 'Call Scheduled',
    'scheduleCall.schedule': 'Schedule',
    'scheduleCall.selectOption': 'Time',
    'scheduleCall.duration': 'Duration',
    'scheduleCall.date': 'Date',
    'scheduleCall.next': 'Next',
    'scheduleCall.skipNotes': 'Skip notes',
    'scheduleCall.notes': 'Notes',
    'scheduleCall.scheduleCall': 'Schedule Appointment',
    'chat.downloadFile.error': 'This file is corrupted or has been deleted',
    'chat.incomingCall.accept': 'Accept',
    'chat.incomingCall.decline': 'Decline',
    'chat.incomingCall.title': 'Incoming Call…',
    'chat.chatInput.uploadLabel': 'Attach File',
    'chat.chatInput.emoji': 'Emoji',
    'chat.chatInput.fileUploaded': 'file uploaded successfully.',
    'chat.chatInput.fileNotUploaded': 'file upload failed.',
    'chat.chatInput.selectProject': 'Select a project to upload a file.',
    'chat.chatInput.sendMessage': 'Send Message',
    'chat.userActive': 'Active Now',
    'chat.messagesList.missedCall': 'Declined Call',
    'videocall.call.meetingWith': 'Meeting with',
    'videocall.call.callScheduledFor': 'This call is scheduled for {{date}} at {{timeH}}:{{timeM}} you may access it on that time.',
    'call.callErrors.description.cleanMeeting': 'Please, choose your audio and video settings.',
    'call.callErrors.title.userNotInvited': 'User not invited to call',
    'call.callErrors.description.userNotInvited': 'You are not invited to this call.',
    'call.callErrors.title.mustLogin': 'User not logged in',
    'call.callErrors.description.mustLogin': 'Log in to enter this call.',
    'call.callErrors.button.mustLogin': 'Log in.',
    'call.callErrors.title.earlierAccess': 'This room is not available yet',
    'call.callErrors.title.laterAccess': 'This call has ended',
    'call.callErrors.description.laterAccess': 'You may still access chat messages.',
    'call.callErrors.title.callCancelled': 'This video call was cancelled',
    'call.callErrors.description.callCancelled':
      'This video call was cancelled by one or more of the participants.',
    'call.callErrors.title.noCam': 'Camera permission denied',
    'call.callErrors.description.noCam':
      'You have not granted or blocked camera access. Please update your browser settings to allow access.',
    'call.callErrors.title.noMic': 'Microphone permission denied',
    'call.callErrors.description.noMic':
      'You have not granted or blocked microphone access. Please update your browser settings to allow access.',
    'call.callErrors.title.callExpired': 'This call has already expired',
    'call.callErrors.description.callExpired':
      'If you think this an error, please contact your Tax Preparer.',
    'call.join': 'Join Now',
    'videocall.me': 'Me',
    'videocall.callMenu.recordSession': 'Record Session',
    'videocall.callMenu.stopRecording': 'Stop Recording',
    'client.onboarding.signin': 'Sign in',
    'client.onboarding.title': 'Register for {{name}} to prepare your taxes',
    'client.onboarding.subtitle': 'Provide some basic information to get started',
    'client.onboarding.welcome': 'Welcome to our secure portal',
    'client.onboarding.alreadyClient': 'Already a client?',
    'client.onboarding.ours': 'Ours is an online platform used to easily and securely share information with your tax preparer.',
    'client.onboarding.instructions': 'You\'re one step away from working with your tax preparer entirely online, but first you need to set a password for your account. This will allow you to get back to your account later by logging in to our portal with your email address.',
    'client.onboarding.agreeTerms': 'I agree to the platform\'s ',
    'client.onboarding.termsOfUse': 'Terms of Use',
    'client.onboarding.getStarted': 'Get Started',
    'client.onboarding.success': 'Your account has been successfully activated. You can now use our platform by logging in.',
    'client.onboarding.failure': 'Something went wrong during the account activation process. Please make sure that the data you entered is correct. If the problem persists please contact the preparer who sent you the invitation.',
    'client.onboarding.fields.email': 'Email',
    'client.onboarding.fields.firstName': 'First Name',
    'client.onboarding.fields.lastName': 'Last Name',
    'client.onboarding.fields.birthday': 'Date of Birth',
    'client.onboarding.fields.landline': 'Landline',
    'client.onboarding.fields.mobileNumber': 'Mobile Number',
    'client.onboarding.fields.workNumber': 'Work Number',
    'client.onboarding.fields.createPassword': 'Create Password',
    'client.onboarding.fields.confirmPassword': 'Confirm Password',
    'client.onboarding.fields.taxYear': 'Which tax year is this for?',
    'client.onboarding.fields.job': 'What tax filling is this for?',
    'client.onboarding.fields.language': 'Language',
    'client.onboarding.fields.validate.email': 'The email is required',
    'client.onboarding.fields.validate.invalidEmail': 'Invalid email',
    'client.onboarding.fields.validate.firstName': 'First Name is required',
    'client.onboarding.fields.validate.lastName': 'Last Name is required',
    'client.onboarding.fields.validate.birthday': 'Birthday is required',
    'client.onboarding.fields.validate.mobileNumber': 'Mobile Number is required',
    'client.onboarding.fields.validate.createPassword': 'Password is required',
    'client.onboarding.fields.validate.createPassword2': 'The password must be at least 8 characters long',
    'client.onboarding.fields.validate.createPassword3': 'The password must contain at least one lowecase letter, one uppercase letter, a number from 0-9 and one of these characters: !@#$%^&*',
    'client.onboarding.fields.validate.confirmPassword': 'Confirm Password is required',
    'client.onboarding.fields.validate.confirmPassword2': 'Password must match',
    'client.onboarding.fields.validate.taxYear': 'Tax Year is required',
    'client.onboarding.fields.validate.job': 'Tax filling is required',
  },
  emojiMart: {
    search: 'Search',
    clear: 'Clear', // Accessible label on "clear" button
    notfound: 'No Emoji Found',
    skintext: 'Choose your default skin tone',
    categories: {
      search: 'Search Results',
      recent: 'Frequently Used',
      smileys: 'Smileys & Emotion',
      people: 'People & Body',
      nature: 'Animals & Nature',
      foods: 'Food & Drink',
      activity: 'Activity',
      places: 'Travel & Places',
      objects: 'Objects',
      symbols: 'Symbols',
      flags: 'Flags',
      custom: 'Custom',
    },
    categorieslabel: 'Emoji categories', // Accessible title for the list of categories
    skintones: {
      1: 'Default Skin Tone',
      2: 'Light Skin Tone',
      3: 'Medium-Light Skin Tone',
      4: 'Medium Skin Tone',
      5: 'Medium-Dark Skin Tone',
      6: 'Dark Skin Tone',
    },
  },
};

export default enInternationalization;
