import {
  FC, useEffect, useReducer, useState,
} from 'react';
import axios from 'axios';
import {
  Typography,
  Row,
  Col,
  Upload,
  message,
  Space,
  TaxarooButton,
  Divider,
  Modal,
  Layout,
  Icons,
  Popconfirm,
  Spin,
} from 'taxaroo-ui';
import { BlockPicker } from 'react-color';
import { useAppSelector } from '~src/redux/hooks';
import {
  CustomDomainCertificateStatusQuery,
  CustomDomainUserDocument,
  GetCustomDomainUserDocument,
  GetCustomLayoutsDocument,
  useCustomDomainCertificateStatusLazyQuery,
  useCustomDomainCnameStatusLazyQuery,
  useCustomDomainUserQuery,
  useGetCustomLayoutsQuery,
  useIsDomainLiveLazyQuery,
} from '~src/graphql/queries/settings';
import {
  useCreateCustomDomainMutation,
  useCreateCustomLayoutMutation,
  useCustomDomainResendEmailsMutation,
  useGetSiteBrandingLogoPresignedUrlMutation,
  useRemoveCustomDomainMutation,
  useUpdateCustomLayoutMutation,
} from '~src/graphql/mutations/settings';
import { CustomDomainStatuses } from '~src/graphql';
import SettingsHeader from '~src/components/organisms/Settings/SettingsHeader';
import CustomDomain from '~src/components/organisms/CustomDomain';
import CustomLogo from '~src/components/organisms/CustomLogo';
import CustomColor from '~src/components/organisms/CustomColor';
import LayoutPreview from '~src/components/organisms/LayoutPreview';
import { colors, moreColors, categoryTitle } from './utils';
import { customLayoutReducer, initialState, CustomLayoutCategory } from './reducer';
import * as styles from './style.module.css';

const { SaveOutlined, CloseOutlined } = Icons;
const { Paragraph } = Typography;

interface CustomLayoutValues {
  id: string;
  headerBackgroundColor?: string;
  headerFontColor?: string;
  logo?: string;
}

export interface CustomDomainState {
  id: string | null;
  name: string;
}

function getNonCachedLogo(logo: string): string {
  return `${logo}?t=${new Date().getTime()}`;
}

const SiteBranding: FC = () => {
  // Access to user information in the Redux store
  const { firmAccountId } = useAppSelector((state) => state.session);

  // Manage state for custom layout
  const [customLayoutId, setCustomLayoutId] = useState<string | null>(null);
  const [customColors, dispatch] = useReducer(customLayoutReducer, initialState);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [moreColorHex, setMoreColorHex] = useState<string>();
  const [isDomainsModalVisible, setIsDomainsModalVisible] = useState<boolean>(false);
  const [cnameError, setCnameError] = useState<string>('');

  // Manage state to store color category (header background color, header text color, etc)
  const [colorCategory, setColorCategory] = useState<CustomLayoutCategory>();

  // Manage state for custom logo
  const [logo, setLogo] = useState<string>('');
  const [nonCachedLogo, setNonCachedLogo] = useState<string>('');

  // Manage state for custom domain
  const [customDomain, setCustomDomain] = useState<CustomDomainState>({ id: null, name: '' });
  const [subdomain, setSubdomain] = useState<string>('');
  const [domain, setDomain] = useState<string>('');

  // Manage state for user interface
  const [enableEditCustomDomain, setEnableEditCustomDomain] = useState<boolean>(false);
  const [visibleTooltip, setVisibleTooltip] = useState<boolean | undefined>(undefined);

  // Post-mutation handlers
  const handleOnCreateDomainCompleted = () => {
    message.success('Custom domain saved successfully!');
    setEnableEditCustomDomain(false);
    setVisibleTooltip(undefined);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getCustomDomainCertificateStatus();
  };

  const handleOnRemoveCompleted = async () => {
    message.success('Custom domain deleted successfully!');
    setEnableEditCustomDomain(false);
    setCustomDomain({ id: null, name: '' });
    setSubdomain('');
    setDomain('');
    setVisibleTooltip(undefined);
    setCnameError('');
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getCustomDomainCnameStatus();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getCustomDomainCertificateStatus();
  };

  const handleOnError = () => {
    message.error('An error has occurred, please try again.');
  };

  // Query for getting custom domain
  const {
    data: customDomainQueryData,
    loading: loadingCustomDomainQuery,
  } = useCustomDomainUserQuery();

  // using a useState here so I can set it to null when there is an error
  const [certificateStatus, setCertificateStatus] = useState<CustomDomainCertificateStatusQuery>(null);
  // Query for getting custom domain certificate status
  const [getCustomDomainCertificateStatus, { loading: certificateStatusLoading }] = useCustomDomainCertificateStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setCertificateStatus(data);
    },
    onError: (error) => {
      setCertificateStatus(null); // set to null if error
      if (error.message === 'One or more aliases specified for the distribution includes an incorrectly configured DNS record that points to another CloudFront distribution. You must update the DNS record to correct the problem. For more information, see https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/CNAMEs.html#alternate-domain-names-restrictions') {
        setCnameError('You custom domain already has a CNAME record pointing to a different domian. Please remove your existing CNAME record and reload the page.');
      }
    },
  });
  const { CustomDomainCertificateStatus } = certificateStatus || {};

  // Query for getting custom domain cname status
  const [getCustomDomainCnameStatus,
    { data: cnameStatus, loading: cnameStatusLoading }] = useCustomDomainCnameStatusLazyQuery({
    onError: (error) => {
      if (error?.message?.includes('queryCname ENODATA') || error?.message?.includes('queryCname ENOTFOUND')) {
        setCnameError('CNAME record not found.');
      }
    },
    onCompleted: (cnameStatusData) => {
      if (cnameStatusData?.CustomDomainCnameStatus?.cnameStatus !== 'VERIFIED') {
        setCnameError(cnameStatusData?.CustomDomainCnameStatus?.cnameStatus);
      }
    },
  });

  // Query for getting custom layout
  const { data: customLayoutData, loading: loadingCustomLayoutQuery } = useGetCustomLayoutsQuery();

  // Query for checking if custom domain is live
  // check if custom domain is live
  const [checkIfCustomDomainIsLive, { data: domainLiveData }] = useIsDomainLiveLazyQuery({
    variables: {
      url: customDomain?.name,
    },
  });
  const { IsDomainLive: isDomainLive } = domainLiveData || {};

  // Mutation for creating a custom domain
  const [createCustomDomain, { loading: loadingCreateMutation }] = useCreateCustomDomainMutation({
    onCompleted: handleOnCreateDomainCompleted,
    onError: handleOnError,
    refetchQueries: [{ query: GetCustomDomainUserDocument }],
    awaitRefetchQueries: true,
  });

  // Mutation for creating a custom layout
  const [createCustomLayout, { loading: loadingCreateLayout }] = useCreateCustomLayoutMutation({
    onCompleted: () => message.success('Custom layout saved successfully!'),
    onError: handleOnError,
    refetchQueries: [{ query: GetCustomLayoutsDocument }],
    awaitRefetchQueries: true,
  });

  // Mutation for updating custom layout
  const [updateCustomLayout, { loading: loadingUpdateLayout }] = useUpdateCustomLayoutMutation({
    onCompleted: () => message.success('Custom layout saved successfully!'),
    onError: handleOnError,
    refetchQueries: [{ query: GetCustomLayoutsDocument }],
    awaitRefetchQueries: true,
  });

  // Mutation for removing custom domain
  const [removeCustomDomain, { loading: loadingDeleteMutation }] = useRemoveCustomDomainMutation({
    onCompleted: handleOnRemoveCompleted,
    onError: handleOnError,
    refetchQueries: [
      { query: CustomDomainUserDocument },
    ],
    awaitRefetchQueries: true,
  });

  // Mutation to get presigned URL for logo
  const [getSiteBrandingLogoPresignedURL,
    { loading: presignedUrlLoading }] = useGetSiteBrandingLogoPresignedUrlMutation();
  const [uploadingToS3, setUploadingToS3] = useState<boolean>(false);

  const [customDomainResendEmails,
    { loading: resendEmailsLoading }] = useCustomDomainResendEmailsMutation({
    onError: handleOnError,
  });

  const handleGetCertificateStatus = async () => {
    const status = await getCustomDomainCertificateStatus({
      onError: (error) => {
        setCertificateStatus(null);
        if (error.message === 'One or more aliases specified for the distribution includes an incorrectly configured DNS record that points to another CloudFront distribution. You must update the DNS record to correct the problem. For more information, see https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/CNAMEs.html#alternate-domain-names-restrictions') {
          message.info('Certificate is successfully issued.');
        } else {
          message.error(`Error getting certificate status: ${error.message}`);
        }
      },
    });
    const { Status } = status.data.CustomDomainCertificateStatus;
    if (Status === CustomDomainStatuses.Issued) {
      message.info('Certificate is successfully issued.');
    } else {
      message.info(
        `Status: ${Status
          .toLowerCase()
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')}`,
        5,
      );
    }
  };

  const handleResendEmails = async () => {
    const status = await customDomainResendEmails();
    if (status.data.CustomDomainResendEmails) {
      message.success('Emails are successfully sent.');
    } else {
      handleOnError();
    }
  };

  // Mutation handlers
  const handleCreateDomain = async () => {
    const result = await createCustomDomain({
      variables: {
        createCustomDomainsInput: {
          name: `${subdomain}.${domain}`,
          firmAccountId,
          status: CustomDomainStatuses.Issued,
        },
      },
    });

    if (result.data.createCustomDomains.id) {
      // setIsDomainsModalVisible(true); // do not show this modal for DNS verification
      setTimeout(getCustomDomainCertificateStatus, 3000); // to get DNS verification values
    } else {
      handleOnError();
    }
  };

  const handleUpsertCustomLayout = () => {
    if (customLayoutId) {
      updateCustomLayout({
        variables: {
          updateCustomLayoutInput: {
            ...customColors,
            logo,
          },
        },
      });
    } else {
      createCustomLayout({
        variables: {
          createCustomLayoutInput: {
            ...customColors,
            firmAccountId,
            logo,
          },
        },
      });
    }
  };

  const handleRemoveDomain = async () => {
    await removeCustomDomain({
      variables: {
        id: customDomain.id,
      },
    });
  };

  const setCustomLayoutInitialValues = (customLayoutInitialValues: CustomLayoutValues) => {
    const {
      id,
      logo: initialLogo,
      headerBackgroundColor,
      headerFontColor,
    } = customLayoutInitialValues;

    setCustomLayoutId(id);
    setLogo(initialLogo || '');
    setNonCachedLogo(initialLogo ? getNonCachedLogo(initialLogo) : '');
    dispatch({
      type: CustomLayoutCategory.INITIAL_VALUES,
      payload: {
        headerBackgroundColor: headerBackgroundColor || '#ffffff',
        headerFontColor: headerFontColor || '#ffffff',
      },
    });
  };

  const handleCancelChanges = () => {
    // Set logo and custom layout initial values
    if (customLayoutData) {
      setCustomLayoutInitialValues(customLayoutData.CustomLayout);
    } else {
      setCustomLayoutInitialValues({
        ...initialState,
        id: customLayoutId,
        logo: null,
      });
    }
    message.success('The changes were cancelled');
  };

  const handleResetDefault = () => {
    updateCustomLayout({
      variables: {
        updateCustomLayoutInput: {
          ...initialState,
          // id: customLayoutId,
          logo: null,
        },
      },
    });
  };

  // Props for Ant Design dragger
  const draggerProps = {
    name: 'file',
    multiple: false,
    accept: 'image/png,image/jpg,image/jpeg',
    beforeUpload: async (file: File) => {
      // Check if file type is allowed
      const acceptedTypes = ['image/png', 'image/jpg', 'image/jpeg'];
      if (!acceptedTypes.includes(file.type)) {
        message.error('File type not allowed, please try again');
        return Upload.LIST_IGNORE;
      }

      // Check file size
      if (file.size > 5242880) { // 5MB
        message.error('File is too big, please try again');
        return Upload.LIST_IGNORE;
      }

      try {
        const presignedUrlData = await getSiteBrandingLogoPresignedURL({
          variables: {
            mimeType: file.type,
          },
        });
        const fileUrl = new URL(presignedUrlData.data.GetSiteBrandingLogoPresignedURL);

        setUploadingToS3(true);
        await axios.put(
          fileUrl.toString(),
          file,

          {
            headers: {
              'Content-Type': file.type,
            },
          },
        );
        setUploadingToS3(false);

        const logoUrl = `${fileUrl.origin}${fileUrl.pathname}`;
        setLogo(logoUrl);
        setNonCachedLogo(getNonCachedLogo(logoUrl));
      } catch (e) {
        setUploadingToS3(false);
        message.error('An error has occurred, please try again.');
      }

      // Return false so that image is not automatically uploaded
      return false;
    },
  };

  // Color helper functions
  const chooseMoreColors = (category: CustomLayoutCategory, currentColorSelected: string) => {
    setIsModalVisible(true);
    setMoreColorHex(currentColorSelected);
    setColorCategory(category);
  };

  const moreColorChooseModalHandleOk = () => {
    setIsModalVisible(false);
    dispatch({
      type: colorCategory,
      payload: { [colorCategory]: moreColorHex },
    });
  };

  const moreColorChooseModalHandleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (!isDomainLive) {
      // if custom domain is not working
      // and we need to run some checks to display why
      // fetch the current status of the certificate (this will also trigger an update to the CustomDomain status in the db)
      getCustomDomainCertificateStatus();
      // fetch the current status of the cname
      getCustomDomainCnameStatus();
    }
  }, [isDomainLive]);

  useEffect(() => {
    if (customDomain?.name) {
      checkIfCustomDomainIsLive();
    }
  }, [customDomain?.name, CustomDomainCertificateStatus?.Status, cnameError]);

  useEffect(() => {
    // Set custom domain initial values
    if (customDomainQueryData) {
      // If it exists, get custom domain name and id
      if (customDomainQueryData.CustomDomainUser?.name) {
        const { id, name } = customDomainQueryData.CustomDomainUser;

        setCustomDomain({ id, name });
        const [subdomainSection, ...domainSection] = name.split('.');
        // Separate subdomain and domain and save data in state
        setSubdomain(subdomainSection);
        setDomain(domainSection.join('.'));
      }
    }
  }, [customDomainQueryData]);

  useEffect(() => {
    // Set logo and custom layout initial values
    if (customLayoutData?.CustomLayout) {
      setCustomLayoutInitialValues(customLayoutData.CustomLayout);
    }
  }, [customLayoutData]);

  if (loadingCustomDomainQuery || loadingCustomLayoutQuery) {
    return <Spin size="large" />;
  }

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <Space direction="vertical" style={{ width: '98%' }}>
        <SettingsHeader
          title="Custom Branding"
          tooltipTitle="How does this work?"
          tooltipContent={(
            <Paragraph>
              You can use options below to customize Taxaroo so that it better matches your brand.
              The theming will only be visible to clients, so use the preview provided to evaluate
              your selections. Using custom domain on Taxaroo allows your firm to have your client
              portal hosted on a subdomain of your main website domain name.
            </Paragraph>
          )}
        />
        <CustomDomain
          customDomain={customDomain}
          status={CustomDomainCertificateStatus?.Status || customDomainQueryData.CustomDomainUser?.status}
          failureReason={CustomDomainCertificateStatus?.FailureReason}
          cnameError={cnameError}
          domain={domain}
          enableEditCustomDomain={enableEditCustomDomain}
          handleCreateDomain={handleCreateDomain}
          handleRemoveDomain={handleRemoveDomain}
          loadingCreateMutation={loadingCreateMutation}
          loadingDeleteMutation={loadingDeleteMutation}
          setDomain={setDomain}
          setEnableEditCustomDomain={setEnableEditCustomDomain}
          setSubdomain={setSubdomain}
          setVisibleTooltip={setVisibleTooltip}
          subdomain={subdomain}
          visibleTooltip={visibleTooltip}
          handleGetCertificateStatus={handleGetCertificateStatus}
          handleResendEmails={handleResendEmails}
          certificateStatusLoading={certificateStatusLoading}
          resendEmailsLoading={resendEmailsLoading}
          certificateStatus={certificateStatus}
        />
        <Divider className={styles.rowDivider} />
        <CustomLogo
          logo={logo}
          draggerProps={draggerProps}
          setLogo={(s: string) => {
            setLogo(s);
            setNonCachedLogo(getNonCachedLogo(s));
          }}
          isLoading={Boolean(presignedUrlLoading || uploadingToS3)}
        />
        <Divider className={styles.rowDivider} />
        <CustomColor
          category={CustomLayoutCategory.HEADER_BACKGROUND_COLOR}
          chooseMoreColors={chooseMoreColors}
          colors={colors}
          customColor={customColors.headerBackgroundColor}
          dispatch={dispatch}
          title="Step 3"
        />
        <Divider className={styles.rowDivider} />
        <CustomColor
          category={CustomLayoutCategory.HEADER_FONT_COLOR}
          chooseMoreColors={chooseMoreColors}
          colors={colors}
          customColor={customColors.headerFontColor}
          dispatch={dispatch}
          title="Step 4"
        />
        <Divider className={styles.rowDivider} />
        <LayoutPreview customColors={customColors} logo={nonCachedLogo} />
        <Row style={{ marginTop: 20 }} justify="space-between" gutter={16}>
          <Col className="gutter-row" sm={24} lg={12}>
            <TaxarooButton
              type="primary"
              onClick={handleUpsertCustomLayout}
              icon={<SaveOutlined />}
              loading={loadingCreateLayout || loadingUpdateLayout}
            >
              Save changes
            </TaxarooButton>
          </Col>
          <Col md={24} lg={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <TaxarooButton
              onClick={handleCancelChanges}
              icon={<CloseOutlined />}
              style={{ marginRight: 10 }}
            >
              Cancel changes
            </TaxarooButton>
            <Popconfirm
              title="Are you sure you want to reset to default values?"
              onConfirm={handleResetDefault}
              okText="Yes"
              cancelText="No"
              placement="topRight"
            >
              <TaxarooButton danger>Reset Default</TaxarooButton>
            </Popconfirm>
          </Col>
        </Row>
      </Space>
      <Modal
        smallTitle
        title={categoryTitle[colorCategory]}
        width={300}
        visible={isModalVisible}
        onOk={moreColorChooseModalHandleOk}
        onCancel={moreColorChooseModalHandleCancel}
      >
        <Row gutter={16}>
          <Col span={24}>
            <BlockPicker
              triangle="hide"
              styles={{ default: { card: { minWidth: 'fit-content' } } }}
              colors={moreColors}
              color={moreColorHex}
              onChange={(color: { hex: string }) => setMoreColorHex(color?.hex)}
            />
          </Col>
        </Row>
      </Modal>

      <Modal
        title="First Step: Activate Certificate"
        width="75%"
        open={isDomainsModalVisible}
        onCancel={() => setIsDomainsModalVisible(false)}
        footer={[
          <TaxarooButton
            onClick={() => setIsDomainsModalVisible(false)}
            type="primary"
          >
            Accept
          </TaxarooButton>,
        ]}
      >
        <Row gutter={16}>
          <Col span={24}>
            We have triggered an email sent to the technical contact
            on your domain registration (ex on Godaddy or Network Solutions, etc)
            that you must approve for the SSL certificate of your white-labled client
            interview portal to work properly on the subdomain of your domain name.
            Please click the link in that email to approve this to complete your custom
            domain setup.
            <ul>
              <li>{`admin@${domain}`}</li>
              <li>{`administrator@${domain}`}</li>
              <li>{`hostmaster@${domain}`}</li>
              <li>{`postmaster@${domain}`}</li>
              <li>{`webmaster@${domain}`}</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Paragraph>
              <br />
              *Activating your certificate is the first step of two. In the next step, you will add a DNS record.
            </Paragraph>
          </Col>
        </Row>
      </Modal>
    </Layout>
  );
};

export default SiteBranding;
