import { message, Select } from 'taxaroo-ui';

const { Option } = Select;

type ArrayOptions = Array<{ value: string | number; label: string }>;

const {
  success, error, warning, info,
} = message;

const getOptions = (array: ArrayOptions) => array.map(({ value, label }) => (
  <Option value={`${value}`} key={value}>
    {label}
  </Option>
));

const Message = (content:string, type:'success' | 'error' | 'warning' | 'info') => {
  const style = { marginTop: '5vh' };
  switch (type) {
    case 'success':
      return success({ content, style });
    case 'error':
      return error({ content, style });
    case 'warning':
      return warning({ content, style });
    default:
      return info({ content, style });
  }
};

export { Message, getOptions };
