.dragContainer {
  position: relative;
  margin-bottom: 12px;
}

.moveIcon {
  z-index: 20;
  top: -130px;
  left: -130px;
  padding: 5px;
  font-size: 16px;
  position: absolute;
  border-radius: 10%;
  visibility: hidden;
  background: #ddd;
  transition: visibility 200 ease;
  cursor: grab;
  transform: scale(0.1);
  opacity: 0.9;
}

/* .dragContainer:hover > .moveIcon {
  visibility: visible;
} */

.questionDragContainer {
  position: relative;
}

/* .questionDragContainer:hover > .moveIcon {
  visibility: visible;
} */

.sectionNameInput {
  width: 100% !important;
}

.deleteSectionButton {
  margin-left: 12px;
}

.noQuestionsAlert {
  margin-bottom: 16px !important;
}

.subSection {
  margin-left: 20px;
}
