.chatSelectorContainer {
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  padding-left: 12px;
  padding-right: 12px;
}

.containerTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.containerTop h4 {
  margin-right: auto;
}

.input {
  width: 100%;
  margin: 0 !important;
  max-width: none !important;
}

.input button {
  height: 32px !important;
  border-radius: 0 !important;
  padding: 2.4px 0 !important;
}

.title {
  color: #565656 !important;
  font-size: 20px !important;
  font-weight: 600;
  margin-bottom: 0 !important;
}

.addbtn {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #22a7e7;
  font-weight: 600;
}
