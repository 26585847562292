/* eslint-disable camelcase */
import { useCallback, useEffect, useState } from 'react';
import {
  Form,
  Input,
  Typography,
  InputNumber,
  Skeleton,
} from 'taxaroo-ui';
import { useGetBusinessInformationQuery } from '~src/graphql/queries/settings';
import { useAppDispatch, useAppSelector } from '~src/redux/hooks';
import {
  RegisterStep, selectRegisterState, setBusinessAddress, setBusinessInfo,
} from '~src/redux/slices/registerSlice';
import PhoneInputField from '../../../atoms/PhoneInputField';
import CardNav from '../CardNav.tsx';

const { Title } = Typography;

interface BusinessInfoProps {
  setStep: (arg1: keyof typeof RegisterStep) => void;
  signUpFlow: boolean;
}
function BusinessInfo({
  setStep,
  signUpFlow,
}: BusinessInfoProps) {
  const dispatch = useAppDispatch();
  const {
    businessInfo: {
      the_tax_returns,
      the_business_name,
      the_business_phone,
      the_business_phone_id,
    },
  } = useAppSelector(selectRegisterState);
  const [form] = Form.useForm();
  const [disabledNext, setDisabledNext] = useState(true);
  const [loadingBusinessInfo, setLoadingBusinessInfo] = useState<boolean>(!signUpFlow);

  // graphql
  // query to fetch existing business information
  const { data: firmData } = useGetBusinessInformationQuery();
  const businessInfo = firmData?.BusinessInformation || {};

  // set the businessInfo initial values if any are present
  useEffect(() => {
    if (businessInfo && form && dispatch) {
      const {
        id,
        FirmAccount,
        Address,
        Phones,
        taxReturns,
      } = businessInfo;
      const { name } = FirmAccount || {};
      // if one of these is present, then the user has already filled out this step
      if ((!the_business_name && name) || (!the_business_phone && Phones?.[0]?.value)) {
        dispatch(setBusinessInfo({
          the_id: id,
          the_tax_returns: taxReturns,
          the_business_name: name,
          the_business_phone: Phones?.[0]?.value,
          the_business_phone_id: Phones?.[0]?.id,
        }));
        dispatch(setBusinessAddress({
          the_id: Address?.[0]?.id,
          the_line1: Address?.[0]?.lineOne,
          the_line2: Address?.[0]?.lineTwo,
          the_city: Address?.[0]?.city,
          the_state: Address?.[0]?.state,
          the_zip_code: Address?.[0]?.zip,
        }));
      }
      setLoadingBusinessInfo(false);
      if (name) {
        setDisabledNext(false);
      }
    }
  }, [businessInfo, businessInfo?.Phones?.length, businessInfo?.Address?.length, form, dispatch]);

  const onSubmit = useCallback((values) => {
    dispatch(setBusinessInfo({
      the_tax_returns: values?.the_tax_returns,
      the_business_name: values?.the_business_name,
      the_business_phone: values?.the_business_phone,
    }));
    setStep('BUSINESS_ADDRESS');
  }, [setStep]);

  // const handleCancel = useCallback(() => {
  //   form.resetFields();
  // }, [form]);

  const handleFormChange = (target) => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors?.length);
    const noValue = !form.getFieldValue('the_business_name')?.length;
    setDisabledNext(hasErrors || noValue);
    // const [{ name: [name], value }] = target;
  };

  useEffect(() => {
    if (the_business_name?.length) {
      setDisabledNext(false);
    }
  }, []);

  if (loadingBusinessInfo) {
    return <Skeleton paragraph={{ rows: 8 }} />;
  }

  return (
    <Form
      style={{
        width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
      }}
      layout="vertical"
      form={form}
      onFinish={onSubmit}
      onFieldsChange={handleFormChange}
      // eslint-disable-next-line no-template-curly-in-string
      validateMessages={{ required: '${label} is required' }}
      initialValues={{
        the_tax_returns,
        the_business_name,
        the_business_phone,
      }}
    >
      <div>
        <Title level={4}>Business Information</Title>
        <Form.Item
          name="the_business_name"
          label="Business Name"
          rules={[{ required: true }]}
        >
          <Input placeholder="Business Name" autoComplete="organization" />
        </Form.Item>

        <Form.Item
          name="the_business_phone"
          label="Business Phone"
          rules={[{ required: false }]}
        >
          <PhoneInputField phoneNumber={the_business_phone} autoComplete="tel" />
        </Form.Item>

        <Form.Item
          name="the_tax_returns"
          label="Number of Tax Returns in the Past Season"
          rules={[{ required: false }]}
        >
          <InputNumber style={{ width: '100%' }} placeholder="Numer of returns" />
        </Form.Item>

      </div>
      <CardNav
        onBack={() => setStep('PERSONAL_INFO')}
        onSkip={() => setStep('BUSINESS_ADDRESS')}
        showSkip={false}
        disabled={disabledNext}
      />
    </Form>
  );
}

export default BusinessInfo;
