import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateChannelMutationVariables = Types.Exact<{
  createChannelInput: Types.CreateChannelInput;
}>;


export type CreateChannelMutation = { __typename?: 'Mutation', createChannel: { __typename?: 'ChannelEntity', id: string, createAt: any, updateAt: any, description?: string | null, Messages?: Array<{ __typename?: 'MessageEntity', id: string, text?: string | null, userId: string, readDate?: any | null, createAt: any, updateAt: any, channelId: string, attachment?: string | null, isSms: boolean, Users?: { __typename?: 'UserEntity', id: string, email?: string | null, createAt: any, updateAt: any, languageId?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, userId?: string | null, createAt?: any | null, updateAt?: any | null, birthday?: any | null, lastName: string, firstName: string } | null } | null }> | null, ChannelMembers?: Array<{ __typename?: 'ChannelMemberEntity', id: string, userId: string, createAt: any, channelId: string, lastConnection: any, Users?: { __typename?: 'UserEntity', id: string, email?: string | null, createAt: any, updateAt: any, languageId?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, userId?: string | null, createAt?: any | null, updateAt?: any | null, birthday?: any | null, lastName: string, firstName: string, Phones?: Array<{ __typename?: 'PhonesEntity', id: string, type: Types.PhoneType, value: string, createAt: any, updateAt: any }> | null } | null } | null }> | null } };

export type UpdateMessageMutationVariables = Types.Exact<{
  updateMessageInput: Types.UpdateMessageInput;
}>;


export type UpdateMessageMutation = { __typename?: 'Mutation', updateMessage: { __typename?: 'MessageEntity', id: string, text?: string | null, userId: string, readDate?: any | null, createAt: any, updateAt: any, channelId: string, attachment?: string | null, isSms: boolean, Users?: { __typename?: 'UserEntity', id: string, email?: string | null, createAt: any, updateAt: any, languageId?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, userId?: string | null, createAt?: any | null, updateAt?: any | null, birthday?: any | null, lastName: string, firstName: string } | null } | null } };

export type UpdateMessagesMutationVariables = Types.Exact<{
  updateMessagesInput: Array<Types.UpdateMessageInput> | Types.UpdateMessageInput;
}>;


export type UpdateMessagesMutation = { __typename?: 'Mutation', updateMessages: Array<{ __typename?: 'MessageEntity', id: string, text?: string | null, userId: string, readDate?: any | null, createAt: any, updateAt: any, channelId: string, attachment?: string | null, isSms: boolean, Users?: { __typename?: 'UserEntity', id: string, email?: string | null, createAt: any, updateAt: any, languageId?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, userId?: string | null, createAt?: any | null, updateAt?: any | null, birthday?: any | null, lastName: string, firstName: string } | null } | null }> };

export type CreateMessageWithFileMutationVariables = Types.Exact<{
  createMessageWithFileInput: Types.CreateMessageWithFileInput;
}>;


export type CreateMessageWithFileMutation = { __typename?: 'Mutation', createMessageWithFile: { __typename?: 'FileMessageEntity', id: string, name: string, size: number, s3Key: string, folder: string, taxYearInterviewId: string, deleteAt?: any | null, uploadedBy: string, deletedBy?: string | null } };

export type SendChatMessageMutationVariables = Types.Exact<{
  createMessageInput: Types.CreateMessageInput;
}>;


export type SendChatMessageMutation = { __typename?: 'Mutation', createMessage: { __typename?: 'MessageEntity', id: string, userId: string, channelId: string, text?: string | null, isSms: boolean, createAt: any, updateAt: any } };

export type CreateBulkMessageMutationVariables = Types.Exact<{
  createBulkMessage: Types.CreateBulkMessageInput;
}>;


export type CreateBulkMessageMutation = { __typename?: 'Mutation', createBulkMessage: { __typename?: 'CreateBulkMessageOutput', message: string } };


export const CreateChannelDocument = gql`
    mutation CreateChannel($createChannelInput: CreateChannelInput!) {
  createChannel(createChannelInput: $createChannelInput) {
    id
    createAt
    updateAt
    description
    Messages {
      id
      text
      userId
      readDate
      createAt
      updateAt
      channelId
      attachment
      isSms
      Users {
        id
        email
        createAt
        updateAt
        languageId
        UserInformation {
          id
          userId
          createAt
          updateAt
          birthday
          lastName
          firstName
        }
      }
    }
    ChannelMembers {
      id
      userId
      createAt
      channelId
      lastConnection
      Users {
        id
        email
        createAt
        updateAt
        languageId
        UserInformation {
          id
          userId
          createAt
          updateAt
          birthday
          lastName
          firstName
          Phones {
            id
            type
            value
            createAt
            updateAt
          }
        }
      }
    }
  }
}
    `;
export type CreateChannelMutationFn = Apollo.MutationFunction<CreateChannelMutation, CreateChannelMutationVariables>;

/**
 * __useCreateChannelMutation__
 *
 * To run a mutation, you first call `useCreateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChannelMutation, { data, loading, error }] = useCreateChannelMutation({
 *   variables: {
 *      createChannelInput: // value for 'createChannelInput'
 *   },
 * });
 */
export function useCreateChannelMutation(baseOptions?: Apollo.MutationHookOptions<CreateChannelMutation, CreateChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChannelMutation, CreateChannelMutationVariables>(CreateChannelDocument, options);
      }
export type CreateChannelMutationHookResult = ReturnType<typeof useCreateChannelMutation>;
export type CreateChannelMutationResult = Apollo.MutationResult<CreateChannelMutation>;
export type CreateChannelMutationOptions = Apollo.BaseMutationOptions<CreateChannelMutation, CreateChannelMutationVariables>;
export const UpdateMessageDocument = gql`
    mutation UpdateMessage($updateMessageInput: UpdateMessageInput!) {
  updateMessage(updateMessageInput: $updateMessageInput) {
    id
    text
    userId
    readDate
    createAt
    updateAt
    channelId
    attachment
    isSms
    Users {
      id
      email
      createAt
      updateAt
      languageId
      UserInformation {
        id
        userId
        createAt
        updateAt
        birthday
        lastName
        firstName
      }
    }
  }
}
    `;
export type UpdateMessageMutationFn = Apollo.MutationFunction<UpdateMessageMutation, UpdateMessageMutationVariables>;

/**
 * __useUpdateMessageMutation__
 *
 * To run a mutation, you first call `useUpdateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageMutation, { data, loading, error }] = useUpdateMessageMutation({
 *   variables: {
 *      updateMessageInput: // value for 'updateMessageInput'
 *   },
 * });
 */
export function useUpdateMessageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMessageMutation, UpdateMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMessageMutation, UpdateMessageMutationVariables>(UpdateMessageDocument, options);
      }
export type UpdateMessageMutationHookResult = ReturnType<typeof useUpdateMessageMutation>;
export type UpdateMessageMutationResult = Apollo.MutationResult<UpdateMessageMutation>;
export type UpdateMessageMutationOptions = Apollo.BaseMutationOptions<UpdateMessageMutation, UpdateMessageMutationVariables>;
export const UpdateMessagesDocument = gql`
    mutation UpdateMessages($updateMessagesInput: [UpdateMessageInput!]!) {
  updateMessages(updateMessagesInput: $updateMessagesInput) {
    id
    text
    userId
    readDate
    createAt
    updateAt
    channelId
    attachment
    isSms
    Users {
      id
      email
      createAt
      updateAt
      languageId
      UserInformation {
        id
        userId
        createAt
        updateAt
        birthday
        lastName
        firstName
      }
    }
  }
}
    `;
export type UpdateMessagesMutationFn = Apollo.MutationFunction<UpdateMessagesMutation, UpdateMessagesMutationVariables>;

/**
 * __useUpdateMessagesMutation__
 *
 * To run a mutation, you first call `useUpdateMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessagesMutation, { data, loading, error }] = useUpdateMessagesMutation({
 *   variables: {
 *      updateMessagesInput: // value for 'updateMessagesInput'
 *   },
 * });
 */
export function useUpdateMessagesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMessagesMutation, UpdateMessagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMessagesMutation, UpdateMessagesMutationVariables>(UpdateMessagesDocument, options);
      }
export type UpdateMessagesMutationHookResult = ReturnType<typeof useUpdateMessagesMutation>;
export type UpdateMessagesMutationResult = Apollo.MutationResult<UpdateMessagesMutation>;
export type UpdateMessagesMutationOptions = Apollo.BaseMutationOptions<UpdateMessagesMutation, UpdateMessagesMutationVariables>;
export const CreateMessageWithFileDocument = gql`
    mutation CreateMessageWithFile($createMessageWithFileInput: CreateMessageWithFileInput!) {
  createMessageWithFile(createMessageWithFileInput: $createMessageWithFileInput) {
    id
    name
    size
    s3Key
    folder
    taxYearInterviewId
    deleteAt
    uploadedBy
    deletedBy
  }
}
    `;
export type CreateMessageWithFileMutationFn = Apollo.MutationFunction<CreateMessageWithFileMutation, CreateMessageWithFileMutationVariables>;

/**
 * __useCreateMessageWithFileMutation__
 *
 * To run a mutation, you first call `useCreateMessageWithFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageWithFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageWithFileMutation, { data, loading, error }] = useCreateMessageWithFileMutation({
 *   variables: {
 *      createMessageWithFileInput: // value for 'createMessageWithFileInput'
 *   },
 * });
 */
export function useCreateMessageWithFileMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageWithFileMutation, CreateMessageWithFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMessageWithFileMutation, CreateMessageWithFileMutationVariables>(CreateMessageWithFileDocument, options);
      }
export type CreateMessageWithFileMutationHookResult = ReturnType<typeof useCreateMessageWithFileMutation>;
export type CreateMessageWithFileMutationResult = Apollo.MutationResult<CreateMessageWithFileMutation>;
export type CreateMessageWithFileMutationOptions = Apollo.BaseMutationOptions<CreateMessageWithFileMutation, CreateMessageWithFileMutationVariables>;
export const SendChatMessageDocument = gql`
    mutation sendChatMessage($createMessageInput: CreateMessageInput!) {
  createMessage(createMessageInput: $createMessageInput) {
    id
    userId
    channelId
    text
    isSms
    createAt
    updateAt
  }
}
    `;
export type SendChatMessageMutationFn = Apollo.MutationFunction<SendChatMessageMutation, SendChatMessageMutationVariables>;

/**
 * __useSendChatMessageMutation__
 *
 * To run a mutation, you first call `useSendChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChatMessageMutation, { data, loading, error }] = useSendChatMessageMutation({
 *   variables: {
 *      createMessageInput: // value for 'createMessageInput'
 *   },
 * });
 */
export function useSendChatMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendChatMessageMutation, SendChatMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendChatMessageMutation, SendChatMessageMutationVariables>(SendChatMessageDocument, options);
      }
export type SendChatMessageMutationHookResult = ReturnType<typeof useSendChatMessageMutation>;
export type SendChatMessageMutationResult = Apollo.MutationResult<SendChatMessageMutation>;
export type SendChatMessageMutationOptions = Apollo.BaseMutationOptions<SendChatMessageMutation, SendChatMessageMutationVariables>;
export const CreateBulkMessageDocument = gql`
    mutation createBulkMessage($createBulkMessage: CreateBulkMessageInput!) {
  createBulkMessage(createBulkMessage: $createBulkMessage) {
    message
  }
}
    `;
export type CreateBulkMessageMutationFn = Apollo.MutationFunction<CreateBulkMessageMutation, CreateBulkMessageMutationVariables>;

/**
 * __useCreateBulkMessageMutation__
 *
 * To run a mutation, you first call `useCreateBulkMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulkMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulkMessageMutation, { data, loading, error }] = useCreateBulkMessageMutation({
 *   variables: {
 *      createBulkMessage: // value for 'createBulkMessage'
 *   },
 * });
 */
export function useCreateBulkMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateBulkMessageMutation, CreateBulkMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBulkMessageMutation, CreateBulkMessageMutationVariables>(CreateBulkMessageDocument, options);
      }
export type CreateBulkMessageMutationHookResult = ReturnType<typeof useCreateBulkMessageMutation>;
export type CreateBulkMessageMutationResult = Apollo.MutationResult<CreateBulkMessageMutation>;
export type CreateBulkMessageMutationOptions = Apollo.BaseMutationOptions<CreateBulkMessageMutation, CreateBulkMessageMutationVariables>;
export const namedOperations = {
  Mutation: {
    CreateChannel: 'CreateChannel',
    UpdateMessage: 'UpdateMessage',
    UpdateMessages: 'UpdateMessages',
    CreateMessageWithFile: 'CreateMessageWithFile',
    sendChatMessage: 'sendChatMessage',
    createBulkMessage: 'createBulkMessage'
  }
}