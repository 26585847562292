.channelListContainer {
  overflow-y: auto;
  height: calc(100vh - 120px);
  margin-top: 25px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.container::-webkit-scrollbar {
  display: none;
}

.channelsLoadingContainer {
  display: block;
  text-align: center;
  color: #0a64b2;
}

@media only screen and (max-device-width: 700px) {
  .channelListContainer {
    height: unset;
    width: 100%;
  }
}
