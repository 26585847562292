import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTaxYearInterviewProgressStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  progressStatusId: Types.Scalars['String'];
}>;


export type UpdateTaxYearInterviewProgressStatusMutation = { __typename?: 'Mutation', updateTaxYearInterviewProgressStatus: { __typename?: 'TaxYearInterviewEntity', id: string, Interviews?: { __typename?: 'InterviewEntity', id: string, name: string } | null, ProgressStatuses?: { __typename?: 'ProgressStatusesEntity', id: string, status: string, underlyingStatus: Types.TaxYearStatus, index: number } | null } };

export type AddJobMutationVariables = Types.Exact<{
  addJobInput: Types.AddJobInput;
}>;


export type AddJobMutation = { __typename?: 'Mutation', AddTaxYearInterviewJob: { __typename?: 'TaxYearInterviewEntity', id: string } };


export const UpdateTaxYearInterviewProgressStatusDocument = gql`
    mutation UpdateTaxYearInterviewProgressStatus($id: String!, $progressStatusId: String!) {
  updateTaxYearInterviewProgressStatus(
    id: $id
    progressStatusId: $progressStatusId
  ) {
    id
    Interviews {
      id
      name
    }
    ProgressStatuses {
      id
      status
      underlyingStatus
      index
    }
  }
}
    `;
export type UpdateTaxYearInterviewProgressStatusMutationFn = Apollo.MutationFunction<UpdateTaxYearInterviewProgressStatusMutation, UpdateTaxYearInterviewProgressStatusMutationVariables>;

/**
 * __useUpdateTaxYearInterviewProgressStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTaxYearInterviewProgressStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxYearInterviewProgressStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaxYearInterviewProgressStatusMutation, { data, loading, error }] = useUpdateTaxYearInterviewProgressStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      progressStatusId: // value for 'progressStatusId'
 *   },
 * });
 */
export function useUpdateTaxYearInterviewProgressStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaxYearInterviewProgressStatusMutation, UpdateTaxYearInterviewProgressStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaxYearInterviewProgressStatusMutation, UpdateTaxYearInterviewProgressStatusMutationVariables>(UpdateTaxYearInterviewProgressStatusDocument, options);
      }
export type UpdateTaxYearInterviewProgressStatusMutationHookResult = ReturnType<typeof useUpdateTaxYearInterviewProgressStatusMutation>;
export type UpdateTaxYearInterviewProgressStatusMutationResult = Apollo.MutationResult<UpdateTaxYearInterviewProgressStatusMutation>;
export type UpdateTaxYearInterviewProgressStatusMutationOptions = Apollo.BaseMutationOptions<UpdateTaxYearInterviewProgressStatusMutation, UpdateTaxYearInterviewProgressStatusMutationVariables>;
export const AddJobDocument = gql`
    mutation addJob($addJobInput: AddJobInput!) {
  AddTaxYearInterviewJob(addJobInput: $addJobInput) {
    id
  }
}
    `;
export type AddJobMutationFn = Apollo.MutationFunction<AddJobMutation, AddJobMutationVariables>;

/**
 * __useAddJobMutation__
 *
 * To run a mutation, you first call `useAddJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addJobMutation, { data, loading, error }] = useAddJobMutation({
 *   variables: {
 *      addJobInput: // value for 'addJobInput'
 *   },
 * });
 */
export function useAddJobMutation(baseOptions?: Apollo.MutationHookOptions<AddJobMutation, AddJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddJobMutation, AddJobMutationVariables>(AddJobDocument, options);
      }
export type AddJobMutationHookResult = ReturnType<typeof useAddJobMutation>;
export type AddJobMutationResult = Apollo.MutationResult<AddJobMutation>;
export type AddJobMutationOptions = Apollo.BaseMutationOptions<AddJobMutation, AddJobMutationVariables>;
export const namedOperations = {
  Mutation: {
    UpdateTaxYearInterviewProgressStatus: 'UpdateTaxYearInterviewProgressStatus',
    addJob: 'addJob'
  }
}