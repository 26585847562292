import React, { useContext, useMemo, useState } from 'react';
import {
  Modal, Select, Form, message,
} from 'taxaroo-ui';
import { JobContext } from '~src/components/providers/job-provider';
import { useUpdateTaxYearInterviewMutation } from '~src/graphql/mutations/settings';
import getTaxYearOptions from '../../../../../helpers/getTaxYearOptions';

const { Option } = Select;

interface UpdateTaxYearModalProps {
  visible: boolean;
  setChangeTaxYearModalVisible: (value: boolean) => void;
  taxYear: number;
  interviewId: string;
}

const UpdateTaxYearModal: React.FC<UpdateTaxYearModalProps> = ({
  visible,
  setChangeTaxYearModalVisible,
  taxYear,
  interviewId,
}) => {
  const [newTaxYear, setNewTaxYear] = useState(taxYear);
  // context
  const jobContext = useContext(JobContext);
  const { taxYearInterviewId, entityId } = jobContext;
  // graphql
  const [updateTaxYearInterview, { loading: loadingUpdateTaxYear }] = useUpdateTaxYearInterviewMutation({
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: () => {
      message.success('Tax year updated successfully.');
    },
  });
  const taxYears = useMemo(() => getTaxYearOptions(10), []);

  const handleUpdateTaxYear = () => {
    try {
      updateTaxYearInterview({
        variables: {
          updateTaxYearInterviewInput: {
            id: taxYearInterviewId,
            entityId,
            interviewId,
            year: newTaxYear.toString(),
            previousYear: taxYear.toString(),
          },
        },
        refetchQueries: ['GetAllEntityDataByEntityId'],
      });
    } catch (error) {
      message.error('Something went wrong while trying to update the tax year.');
    } finally {
      setChangeTaxYearModalVisible(false);
    }
  };

  const onSelectChangeHandler = (value: React.SetStateAction<number>) => {
    setNewTaxYear(value);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Modal
        title="Update Tax Year"
        visible={visible}
        onCancel={() => setChangeTaxYearModalVisible(false)}
        okText="Update"
        okButtonProps={{ loading: loadingUpdateTaxYear, disabled: loadingUpdateTaxYear }}
        onOk={handleUpdateTaxYear}
        destroyOnClose
      >
        <Form
          layout="vertical"
        >
          <Form.Item
            name="taxYear"
            label="Tax Year:"
          >
            <Select
              defaultValue={taxYear}
              value={newTaxYear}
              style={{ width: '100%' }}
              placeholder="Select a tax year"
              onChange={onSelectChangeHandler}
            >
              {
                taxYears.map(({ label, value }) => (
                  <Option key={`taxYearKey-${value}`} value={value}>
                    {label}
                    {' '}
                  </Option>
                ))
              }
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdateTaxYearModal;
