import { useState } from 'react';
import { Input, Button } from 'taxaroo-ui';

const { TextArea } = Input;

interface NotesPanelProps {
  initialNotes?: string;
  onSaveNotes?: (notes: string) => void;
  loading?: boolean;
}

const NotesPanel: React.FC<NotesPanelProps> = ({
  initialNotes,
  onSaveNotes,
  loading,
}: NotesPanelProps) => {
  const [savedNotes, setSavedNotes] = useState<string>(initialNotes);
  const [notes, setNotes] = useState<string>(initialNotes);

  const onSaveClick = () => {
    if (onSaveNotes) {
      onSaveNotes(notes);
    }
    setSavedNotes(notes);
  };

  return (
    <>
      <TextArea
        autoSize={{ minRows: 4, maxRows: 25 }}
        placeholder="Enter client notes here..."
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
      />
      <Button
        style={{ marginTop: 10 }}
        type="primary"
        size="small"
        onClick={onSaveClick}
        disabled={notes === savedNotes}
        loading={loading}
      >
        Save
      </Button>
    </>
  );
};

NotesPanel.defaultProps = {
  initialNotes: null,
  onSaveNotes: null,
  loading: false,
};

export default NotesPanel;
