import moment from 'moment';
import momentTz from 'moment-timezone';

const MS_PER_MINUTE = 60000;

/**
 * @function
 * @param {string} format12Hrs - Hour in format 12hrs (1:15pm)
 */
const parseFormat12Hrs = (format12Hrs: string) => {
  const pm = format12Hrs.toUpperCase().includes('PM');
  const cleanHrs = format12Hrs.toUpperCase().replace('AM', '').replace('PM', '');

  const horMin = cleanHrs.split(':');
  const hrInt = parseInt(horMin[0], 10);
  const hr = (hrInt === 12 ? 0 : hrInt) + (pm ? 12 : 0);
  const min = horMin[1];
  return {
    format12Hrs,
    format24Hrs: `${hr}:${min}`,
    hours: hr,
    minutes: parseInt(min, 10),
  };
};

/**
 * @function
 * @param  {string | undefined} hours - Range hours (9:00am - 1:15pm)
 * @param  {string} separator - Range separator Default('-')
 */
const parseHoursRange = (hours: any, separator = '-') => {
  const hoursArray = hours.split(separator);
  const startHour = parseFormat12Hrs(hoursArray[0].trim());
  const endHour = parseFormat12Hrs(hoursArray[1].trim());
  return { startHour, endHour };
};

/**
 * @function
 * @param {any} day - day
 * @param {int} hours - hours
 * @param {int} minutes - minutes
 */
const createDate = (day: any, hours: any, minutes: any) => moment(day.format())
  .set({
    hours,
    minutes,
  })
  .format();

const getTimeZone = (time?: any) => {
  const date = time ? momentTz(new Date(time)) : momentTz(new Date());
  let timeZone = momentTz.tz.guess();
  if (!timeZone) timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZoneAbreviation = date.tz(timeZone).format('z');

  return {
    large: timeZone,
    short: timeZoneAbreviation,
  };
};

const getBrackets = (from: any, to: any, duration: any) => {
  const fromArray = `${from}`.split(':');
  const toArray = `${to}`.split(':');
  const toTime = moment().set({ hour: Number(toArray[0]), minute: Number(toArray[1]) });
  const start = moment().set({ hour: Number(fromArray[0]), minute: Number(fromArray[1]) });
  const end = moment(start).add(duration, 'm');
  const times = [];
  do {
    times.push({
      startHour: start.hour(),
      startMinute: start.minute(),
      endHour: end.hour(),
      endMinute: end.minute(),
    });
    start.add(duration, 'm');
    end.add(duration, 'm');
  } while (start.isBefore(toTime));

  return times;
};

const getSchedules = (duration: any, init?: any) => {
  const time = `${init}`.split(':');
  const start = moment().set(init ? {
    hour: Number(time[0]),
    minute: Number(time[1]),
  } : { hour: 0, minute: 0 });
  const end = moment().set(init ? { hour: 24, minute: 0 } : { hour: 23, minute: 59 });
  const times = [];
  do {
    times.push({
      value: start.format('HH:mm'),
      label: start.format('hh:mm A'),
    });
    start.add(duration, 'm');
  } while (start.isSameOrBefore(end));

  return times;
};

const getTime = (hour: any, minute: any) => moment().set({ hour, minute });

const renderTime = (ms: number) => {
  const days = ms / (60 * 60 * 24 * 1000);
  if (days < 1) {
    const minutes = Math.floor(ms / 60000);
    if (minutes < 60) {
      if (minutes === 0) {
        return 'A few seconds';
      }
      return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
    }
    return `${Math.floor(minutes / 60)} ${Math.floor(minutes / 60) === 1 ? 'hour' : 'hours'}`;
  }
  if (days < 30) {
    return `${Math.floor(days)} ${Math.floor(days) === 1 ? 'day' : 'days'}`;
  }
  return `${Math.floor(days / 30)} ${Math.floor(days / 30) === 1 ? 'month' : 'months'}`;
};

export {
  parseHoursRange,
  parseFormat12Hrs,
  createDate,
  getBrackets,
  getSchedules,
  getTime,
  MS_PER_MINUTE,
  getTimeZone,
  renderTime,
};
