.billing-redirect-page {
  min-height: calc(100vh);
}

.billing-redirect-wrapper {
  margin: 50px 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
