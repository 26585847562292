import { useTranslation } from 'react-i18next';
import getTaxYearOptions from '~src/components/helpers/getTaxYearOptions';
import { FormValues, ItemValue } from '../types';

const TAX_YEAR_OPTIONS = getTaxYearOptions(10);

export const formFields = (
  {
    job,
    email,
    taxYear,
    landline,
    lastName,
    language,
    birthday,
    firstName,
    workNumber,
    mobileNumber,
    createPassword,
    confirmPassword,
  }: FormValues,
  jobOptions?: Array<{ label: string; value: string; }>,
  languageOptions?: Array<{ label: string, value: string }>,
): ItemValue[] => {
  const { t } = useTranslation();

  const span = {
    xs: 24,
  };

  const emailField = {
    span,
    key: 'email',
    value: email,
    required: true,
    inputType: 'text',
    placeHolder: '',
    label: t('client.onboarding.fields.email'),
    autoComplete: 'email',
  };

  const firstNameField = {
    span,
    required: true,
    placeHolder: '',
    key: 'firstName',
    value: firstName,
    inputType: 'text',
    label: t('client.onboarding.fields.firstName'),
    autoComplete: 'given-name',
  };

  const lastNameField = {
    span,
    required: true,
    key: 'lastName',
    placeHolder: '',
    value: lastName,
    inputType: 'text',
    label: t('client.onboarding.fields.lastName'),
    autoComplete: 'family-name',
  };

  const birthdayField = {
    span,
    required: true,
    key: 'birthday',
    value: birthday,
    placeHolder: '',
    inputType: 'date',
    label: t('client.onboarding.fields.birthday'),
    autoComplete: 'bday',
  };

  const landlineField = {
    span,
    required: false,
    key: 'landline',
    value: landline,
    placeHolder: '',
    inputType: 'text',
    label: t('client.onboarding.fields.landline'),
    autoComplete: 'tel',
  };

  const mobileNumberField = {
    span,
    required: true,
    placeHolder: '',
    inputType: 'text',
    key: 'mobileNumber',
    value: mobileNumber,
    label: t('client.onboarding.fields.mobileNumber'),
    autoComplete: 'tel',
  };

  const workNumberField = {
    span,
    required: false,
    placeHolder: '',
    key: 'workNumber',
    value: workNumber,
    inputType: 'text',
    label: t('client.onboarding.fields.workNumber'),
    autoComplete: 'tel',
  };

  const createPasswordField = {
    span,
    required: true,
    placeHolder: '',
    inputType: 'password',
    key: 'createPassword',
    value: createPassword,
    label: t('client.onboarding.fields.createPassword'),
    autoComplete: 'new-password',
  };

  const confirmPasswordField = {
    span,
    required: true,
    placeHolder: '',
    inputType: 'password',
    value: confirmPassword,
    key: 'confirmPassword',
    label: t('client.onboarding.fields.confirmPassword'),
    autoComplete: 'new-password',
  };

  const taxYearField = {
    span,
    key: 'taxyYear',
    value: taxYear,
    placeHolder: '',
    inputType: 'select',
    options: TAX_YEAR_OPTIONS,
    required: typeof taxYear !== 'undefined',
    label: t('client.onboarding.fields.taxYear'),
  };

  const jobField = {
    span,
    key: 'job',
    value: job,
    placeHolder: '',
    inputType: 'select',
    options: jobOptions ?? [],
    required: typeof job !== 'undefined',
    label: t('client.onboarding.fields.job'),
  };

  const languageField = {
    span,
    required: false,
    value: language,
    key: 'language',
    placeHolder: '',
    inputType: 'select',
    options: languageOptions ?? [],
    label: t('client.onboarding.fields.language'),
  };

  return [
    emailField,
    firstNameField,
    lastNameField,
    birthdayField,
    landlineField,
    mobileNumberField,
    workNumberField,
    createPasswordField,
    confirmPasswordField,
    ...(taxYear ? [
      taxYearField,
    ] : []),
    ...(job ? [
      jobField,
    ] : []),
    languageField,
  ];
};
