import { FC } from 'react';
import { Button } from 'taxaroo-ui';
import Empty from './empty.png';
import * as styles from './style.module.css';

interface EmptyChatProps {
  showModal: () => void;
}

const EmptyState: FC<EmptyChatProps> = ({ showModal }) => (
  <div className={styles.chatEmptyContainer}>
    <div className={styles.emptyContent}>
      <img alt="No Conversations Yet" src={Empty} />
      <p className={styles.small}>
        <Button onClick={showModal}>
          Start New Conversation
        </Button>
      </p>
    </div>
  </div>
);

export default EmptyState;
