import React, { useContext, useRef, useState } from 'react';
import { Select, Icons, message } from 'taxaroo-ui';
import { CSVLink } from 'react-csv';
import { useGetTaxYearInterviewDrakeFileLazyQuery } from '~src/graphql/queries/taxYearInterview';
import UpdateTaxYearModal from '~src/components/organisms/Client/Jobs/Job/UpdateTaxyearModal';
import DeleteJobModal from '~src/components/organisms/Client/Jobs/Job/DeleteJobModal';
import { useGetProgressStatusesQuery } from '~src/graphql/queries/settings';
import { JobContext } from '~src/components/providers/job-provider';
import { isMobileView } from '~src/components/helpers/screen';
import { Features, useUpgradeModal } from '~src/components/atoms/UpgradeModal/ModalProvider';
import { TaxYearStatus } from '../../../graphql';
import SelectionDropdown from '../SelectionDropdown';

const { Option } = Select;
const { SettingOutlined } = Icons;

interface JobExtraProps {
  onChange: (value: TaxYearStatus) => void;
  progressStatusId: string;
  taxYear: number;
  interviewId: string;
  interviewName: string;
  loading: boolean;
}

const JobExtra: React.FC<JobExtraProps> = ({
  onChange,
  progressStatusId,
  taxYear,
  interviewId,
  interviewName,
  loading,
}: JobExtraProps) => {
  const jobContext = useContext(JobContext);

  const [changeTaxYearModalVisible, setChangeTaxYearModalVisible] = useState(false);
  const [deleteJobModalVisible, setDeleteJobModalVisible] = useState(false);
  const [csvFileName, setCsvFileName] = useState('');
  const [csvFileContent, setCsvFileContent] = useState('');

  const { showModal, accessObject } = useUpgradeModal();

  // graphql
  // get all progress statuses
  const { data: progressStatusesData } = useGetProgressStatusesQuery();
  const progressStatuses = progressStatusesData?.ProgressStatuses || [];

  const csvLink = useRef<any>();

  const [getTaxYearInterviewDrakeFile] = useGetTaxYearInterviewDrakeFileLazyQuery();

  const handleDropdownSelection = (
    option: { type: string, title: string },
  ) => {
    const { type } = option;
    if (type === 'delete_job') {
      setDeleteJobModalVisible(true);
    } else if (type === 'change_tax_year') {
      setChangeTaxYearModalVisible(true);
    } else if (type === 'drake_export') {
      // show upgrade modal if user doesn't have access
      if (!accessObject?.[Features.export]) {
        showModal(Features.export);
        return;
      }
      getTaxYearInterviewDrakeFile({
        variables: {
          taxYearInterviewId: jobContext.taxYearInterviewId,
        },
        onCompleted(data) {
          setCsvFileName(data.taxYearInterviewDrakeFile.fileName);
          setCsvFileContent(data.taxYearInterviewDrakeFile.fileContent);
          if (csvLink?.current?.link) {
            csvLink.current.link.click();
          }
          message.success('Drake export complete');
        },
        onError(error) {
          let text = 'Drake export error';
          const apolloText = error?.graphQLErrors[0]?.message;
          if (apolloText) {
            text = `${text}: ${apolloText}`;
          }
          message.error(text);
        },
      });
    }
  };

  return (
    <>
      <Select
        style={{ width: isMobileView() ? 150 : 200 }}
        onChange={onChange}
        value={progressStatusId}
        onClick={(event) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
        }}
        loading={loading}
      >
        {progressStatuses.map((progressStatus) => (
          <Option
            value={progressStatus.id}
            key={progressStatus.id}
          >
            {progressStatus.status}
          </Option>
        ))}
      </Select>
      <SelectionDropdown
        buttonProps={{
          style: { marginLeft: 10, padding: 0 },
          icon: <SettingOutlined />,
          // iconDirection: 'left',
          type: 'link',
          onClick: (event: any) => {
            // If you don't want click extra trigger collapse, you can prevent this:

            event.stopPropagation();
          },
        }}
        dropDownProps={{
          placement: 'bottomLeft',
        }}
        onSelect={(option: { type: string, title: string }) => handleDropdownSelection(option)}
        options={[
          { type: 'change_tax_year', title: 'Change Tax Year' },
          ...(interviewName === 'Individual' ? [{ type: 'drake_export', title: 'Drake Export' }] : []),
          { type: 'delete_job', title: 'Delete Job', danger: true },
        ]}
        keyText="title"
      />
      <UpdateTaxYearModal
        visible={changeTaxYearModalVisible}
        setChangeTaxYearModalVisible={setChangeTaxYearModalVisible}
        taxYear={taxYear}
        interviewId={interviewId}
      />
      <DeleteJobModal
        visible={deleteJobModalVisible}
        setDeleteJobModalVisible={setDeleteJobModalVisible}
      />
      <CSVLink
        data={csvFileContent}
        filename={csvFileName}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </>
  );
};

export default JobExtra;
