import { FC, useEffect, useState } from 'react';
import {
  PageHeader,
  Skeleton,
  Row,
  Col,
  message,
  Divider,
} from 'taxaroo-ui';
import { UserStepEntity } from '~src/graphql';
import { useAppSelector } from '~src/redux/hooks';
import { useGetUserStepsByUserIdQuery } from '~src/graphql/queries/getStarted';
import { useUpdateUserStepMutation } from '~src/graphql/mutations/getStarted';
import ProgressBar from '../../components/molecules/ProgressBar';
import OnBoardingList from './components/OnBoardingList';
import RegisterFlow from '../../components/organisms/RegisterFlow';
import * as styles from './style.module.css';

// interface OnboardingProps {}

const Onboarding: FC = () => {
  const { userId, userRole } = useAppSelector((state) => state.session);
  const [basicInfoUserStep, setBasicInfoStep] = useState<UserStepEntity>();
  const [basicInfoStepWasJustUpdated, setBasicInfoStepWasJustUpdated] = useState<boolean>(false);
  const [showRegisterFlow, setShowRegisterFlow] = useState<boolean>(false);

  // graphql
  const { data: stepsData, loading: stepsLoading } = useGetUserStepsByUserIdQuery({
    variables: {
      userId,
    },
    onError: (error) => {
      message.error(error.toString());
    },
  });
  let { userSteps } = stepsData || {};
  // filter out userSteps that staff shouldn't see
  if (userRole === 'STAFF') {
    userSteps = userSteps?.filter((userStep: UserStepEntity) => userStep?.Steps?.name !== 'workflow'
      && userStep?.Steps?.name !== 'branding'
      && userStep?.Steps?.name !== 'stripe'
      && userStep?.Steps?.name !== 'templated-email'
      && userStep?.Steps?.name !== 'interview');
  }
  // update userStep
  const [updateUserStep, { loading: loadingUpdateUserStep }] = useUpdateUserStepMutation({
    onCompleted: () => {
      message.success('Status updated');
    },
    onError: (error) => {
      message.error(error.toString());
    },
  });

  // a function for updating userStep
  const updateUserStepStatus = (theUserId: string, stepId: string, completed: boolean) => {
    // update UserStep
    updateUserStep({
      variables: {
        updateUserStepInput: {
          userId: theUserId,
          stepId,
          completed,
        },
      },
    });
    // if the step is basicInfo, set basicInfoStepWasJustUpdated to true (avoid the RegisterFlow from poping back up)
    if (stepId === basicInfoUserStep?.stepId) {
      setBasicInfoStepWasJustUpdated(true);
    }
  };

  // useEffect to find basicInfo step
  useEffect(() => {
    if (userSteps) {
      setBasicInfoStep(userSteps.filter((userStep: UserStepEntity) => userStep?.Steps?.name === 'basic-info')[0]);
    }
  }, [userSteps, setBasicInfoStep]);

  // function to update basicInfoStep to completed
  const markBasicInfoStepComplete = () => {
    if (basicInfoUserStep) {
      updateUserStepStatus(basicInfoUserStep.userId, basicInfoUserStep.stepId, true);
    }
  };

  useEffect(() => {
    if (basicInfoUserStep) {
      setShowRegisterFlow(!basicInfoUserStep?.completed && !basicInfoStepWasJustUpdated);
    }
  }, [basicInfoUserStep, basicInfoStepWasJustUpdated]);

  if (stepsLoading) {
    return (
      <>
        <Skeleton active paragraph={{ rows: 3 }} />
        <Divider />
        <Skeleton active paragraph={{ rows: 3 }} />
        <Divider />
        <Skeleton active paragraph={{ rows: 3 }} />
        <Divider />
      </>
    );
  }

  return (
    <div>
      <PageHeader
        title="Get Started"
        style={{ display: 'flex', justifyContent: 'center' }}
      />
      <Row gutter={24} justify="center">
        <Col className={styles.containerList} span={20}>
          <Skeleton loading={stepsLoading}>
            <ProgressBar total={userSteps?.length} completed={userSteps?.filter((step) => step.completed)?.length} />
          </Skeleton>
          <OnBoardingList
            userSteps={userSteps}
            updateUserStepStatus={updateUserStepStatus}
            setShowRegisterFlow={setShowRegisterFlow}
          />
        </Col>
      </Row>
      <RegisterFlow
        showRegisterFlow={showRegisterFlow}
        setShowRegisterFlow={setShowRegisterFlow}
        markBasicInfoStepComplete={markBasicInfoStepComplete}
      />
    </div>
  );
};
export default Onboarding;
