import {
  FC, Dispatch, SetStateAction, useState,
} from 'react';
import {
  Typography,
  Row,
  Col,
  Input,
  Tooltip,
  Icons,
  Popconfirm,
  Button,
  Switch,
} from 'taxaroo-ui';
import CustomDomainDNSModal from '~src/components/atoms/CustomDomainDNSModal';
import CustomDomainStatusAlert from '~src/components/atoms/CustomDomainStatusAlert';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import { CustomDomainState } from '~src/components/templates/SiteBranding';
import { CustomDomainStatuses, FailureReason } from '~src/graphql';
import { CustomDomainCertificateStatusQuery } from '~src/graphql/queries/settings';

const {
  SaveOutlined,
  DeleteOutlined,
  LinkOutlined,
  MailOutlined,
  ReloadOutlined,
  InfoCircleOutlined,
} = Icons;
const { Title, Paragraph, Text } = Typography;

interface CustomDomainProps {
  customDomain: CustomDomainState;
  status: CustomDomainStatuses | null;
  failureReason: FailureReason | null;
  cnameError: string | null;
  domain: string;
  enableEditCustomDomain: boolean;
  handleCreateDomain: () => void;
  handleRemoveDomain: () => void,
  loadingCreateMutation: boolean;
  loadingDeleteMutation: boolean;
  setDomain: Dispatch<SetStateAction<string>>;
  setEnableEditCustomDomain: Dispatch<SetStateAction<boolean>>;
  setSubdomain: Dispatch<SetStateAction<string>>;
  setVisibleTooltip: Dispatch<SetStateAction<boolean | undefined>>;
  subdomain: string;
  visibleTooltip: boolean | undefined;
  handleGetCertificateStatus: () => void;
  handleResendEmails: () => void;
  certificateStatusLoading: boolean;
  resendEmailsLoading: boolean;
  certificateStatus: CustomDomainCertificateStatusQuery,
}

const CustomDomain: FC<CustomDomainProps> = (props) => {
  const {
    customDomain,
    status,
    failureReason,
    cnameError,
    domain,
    enableEditCustomDomain,
    handleCreateDomain,
    handleRemoveDomain,
    loadingCreateMutation,
    loadingDeleteMutation,
    setDomain,
    setEnableEditCustomDomain,
    setSubdomain,
    setVisibleTooltip,
    subdomain,
    visibleTooltip,
    handleGetCertificateStatus,
    handleResendEmails,
    certificateStatusLoading,
    resendEmailsLoading,
    certificateStatus,
  } = props;

  const [isCnameModalVisible, setIsCnameModalVisible] = useState<boolean>(false);

  return (
    <>
      <Row gutter={2}>
        <Col span={24}>
          <CustomDomainStatusAlert
            status={status}
            failureReason={failureReason}
            cnameError={cnameError}
            setIsCnameModalVisible={setIsCnameModalVisible}
            certificateStatus={certificateStatus}
            loadingCertificateStatus={certificateStatusLoading}
          />
        </Col>
        <Col span={24}>
          <Typography>
            <Title level={5}>
              <Text>Step 1</Text>
            </Title>
            <Paragraph>
              <Text style={{ fontWeight: 'bold' }}>Custom Domain </Text>
              <a
                href="https://help.taxaroo.com/article/87-how-to-create-custom-domain"
                target="_blank"
                rel="noreferrer"
              >
                <InfoCircleOutlined
                  style={{
                    fontSize: '14px',
                    position: 'relative',
                    top: '-0.3em',
                    left: '0.3em',
                  }}
                />
              </a>
            </Paragraph>
          </Typography>
        </Col>
      </Row>
      {!customDomain.id
          && (
            <Row gutter={2}>
              <Col span={24}>
                <div style={{ display: 'flex', marginLeft: 5 }}>
                  <Switch
                    checked={enableEditCustomDomain}
                    size="small"
                    onChange={(check: boolean) => {
                      setEnableEditCustomDomain(check);

                      trackCustomerIoEvent(CUSTOMER_IO_EVENTS.CLICK, {
                        Title: 'Enable domain editing',
                        Source: 'Toggle click',
                        value: check,
                        url: window.location.href,
                      });
                    }}
                    style={{ marginTop: 2 }}
                  />
                  <Paragraph small style={{ marginLeft: 5 }}>
                    {enableEditCustomDomain
                      ? 'Disable domain editing'
                      : 'Enable domain editing'}
                  </Paragraph>
                </div>
              </Col>
            </Row>
          )}
      <Row align="middle" justify="center" gutter={10}>
        <Col sm={6} md={4} lg={2}>
          <Text
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: 18,
              whiteSpace: 'nowrap',
            }}
          >
            https://
          </Text>
        </Col>
        <Col sm={18} md={7} lg={9}>
          <Input
            size="large"
            disabled={!enableEditCustomDomain}
            value={subdomain}
            onChange={(e) => setSubdomain(e.target.value)}
            placeholder="clients"
          />
        </Col>
        <Col sm={4} md={1}>
          <Text
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontWeight: 'bold',
              fontSize: 18,
            }}
          >
            .
          </Text>
        </Col>
        <Col sm={20} md={8} lg={9}>
          <Input
            disabled={!enableEditCustomDomain}
            size="large"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            placeholder="taxaroo.com"
          />
        </Col>
        <Col md={4} lg={3}>
          {!customDomain.id
            && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Tooltip title="Save Changes">
                <Button
                  type="primary"
                  shape="circle"
                  size="large"
                  disabled={!enableEditCustomDomain}
                  style={{ cursor: 'pointer' }}
                  onClick={handleCreateDomain}
                  icon={loadingCreateMutation ? null : <SaveOutlined />}
                  loading={loadingCreateMutation}
                />
              </Tooltip>
            </div>
            )}
          {customDomain.id
             && (
               <div style={{ display: 'flex', justifyContent: 'center' }}>
                 <Tooltip title="Go to website" arrowPointAtCenter>
                   <Button
                     type="primary"
                     shape="circle"
                     size="large"
                     style={{ cursor: 'pointer' }}
                     onClick={() => window.open(`https://${customDomain.name}`, '_blank')}
                     icon={<LinkOutlined />}
                   />
                 </Tooltip>
                 <Tooltip
                   title="Delete custom domain"
                   open={visibleTooltip}
                   arrowPointAtCenter
                 >
                   <Popconfirm
                     title="Are you sure you want to delete this domain?"
                     onConfirm={handleRemoveDomain}
                     onCancel={() => setVisibleTooltip(undefined)}
                     okText="Yes"
                     cancelText="No"
                     placement="topRight"
                   >
                     <Button
                       type="default"
                       danger
                       shape="circle"
                       size="large"
                       style={{ cursor: 'pointer', marginLeft: 8 }}
                       onClick={() => setVisibleTooltip(false)}
                       icon={loadingDeleteMutation ? null : <DeleteOutlined />}
                       loading={loadingDeleteMutation}
                     />
                   </Popconfirm>
                 </Tooltip>
               </div>

             )}
        </Col>
      </Row>
      {customDomain.id && (
        <Row align="middle" justify="center" gutter={25} style={{ paddingTop: '15px' }}>
          <Col>
            <Tooltip
              title="Check certificate status"
              arrowPointAtCenter
              open={visibleTooltip}
            >
              <Button
                type="default"
                shape="circle"
                size="large"
                style={{ cursor: 'pointer' }}
                onClick={() => { setVisibleTooltip(undefined); handleGetCertificateStatus(); }}
                icon={<ReloadOutlined />}
                disabled={Boolean(certificateStatusLoading || resendEmailsLoading)}
              />
            </Tooltip>
          </Col>
          {/* only if certificate is not issued */}
          {/* status !== CustomDomainStatuses?.Issued && (
            <Col>
              <Tooltip
                title="Re-send validation emails"
                arrowPointAtCenter
                open={visibleTooltip}
              >
                <Button
                  type="default"
                  shape="circle"
                  size="large"
                  style={{ cursor: 'pointer' }}
                  onClick={() => { setVisibleTooltip(undefined); handleResendEmails(); }}
                  icon={<MailOutlined />}
                  disabled={Boolean(certificateStatusLoading || resendEmailsLoading)}
                />
              </Tooltip>
            </Col>
          ) */}
        </Row>
      )}
      {(customDomain?.id && status === CustomDomainStatuses.Issued) && (
        <CustomDomainDNSModal
          isDNSModalVisible={isCnameModalVisible}
          setIsDNSModalVisible={setIsCnameModalVisible}
        />
      )}
    </>
  );
};

export default CustomDomain;
