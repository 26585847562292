.chatListContainer {
  height: 100%;
  display: flex;
  padding-top: 0;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 5px 5px 5px #f2f2f2;
}

@media only screen and (max-device-width: 700px) {
  .chatListContainer {
    min-height: calc(25vh - 100px);
    max-height: calc(25vh - 100px);
  }
}
