import React, { FC, useState } from 'react';
import {
  Badge,
  Form,
  FormInstance,
  Table,
  Select,
  TaxarooButton,
  Col,
  Row,
  Typography,
  Space,
  Switch as Toggle,
  Tooltip,
  Card,
  Spin,
  Icons,
  Modal,
  Button,
  Divider,
} from 'taxaroo-ui';
import moment from 'moment';
import { useGetInterviewNamesQuery } from '~src/graphql/queries/interview';
import { useFirmTaxPreparersQuery } from '~src/graphql/queries/import-clients';
import getTaxYearOptions from '~src/components/helpers/getTaxYearOptions';
import SelectJobType from '~src/components/atoms/SelectJobType';
import * as styles from './styles.module.css';
import { ClientEntity } from '.';
import { importClientProperties } from './MapContacts';

const { Option } = Select;
const { Text, Paragraph, Title } = Typography;
const { InfoCircleOutlined } = Icons;

export const badgeBgColors = {
  orange: 'rgb(235,145,58)',
  green: 'rgb(114,194,64)',
  blue: 'rgb(50,73,196)',
  red: 'rgb(250,73,96)',
};

const TAX_YEAR_OPTIONS = getTaxYearOptions(10);

interface TableFromProps {
  tableForm: FormInstance<any>;
  clients: ClientEntity[],
  selectedRowKeys: React.Key[],
  setSelectedRowKeys: (keys: React.Key[]) => void,
  selectedInvitations: React.Key[],
  setSelectedInvitations: (keys: React.Key[]) => void,
  selectedClients: ClientEntity[],
  updateSelectedClients: () => void,
  onPaginationChange: (pagination: any) => void,
}

const TableForm: FC<TableFromProps> = (props: TableFromProps) => {
  const {
    tableForm,
    clients,
    selectedRowKeys,
    setSelectedRowKeys,
    selectedInvitations,
    setSelectedInvitations,
    selectedClients,
    updateSelectedClients,
    onPaginationChange,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { data: jobTypes, loading: loadingGetInterviews } = useGetInterviewNamesQuery({
    variables: {
      filterLiveInterviews: false,
    },
  });
  const { data: preparers, loading: loadingPreparers } = useFirmTaxPreparersQuery();

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const sendEmailInvitationToggle = (index: React.Key, check: boolean) => {
    if (check) {
      return setSelectedInvitations([...selectedInvitations, index]);
    }
    return setSelectedInvitations(
      selectedInvitations.filter((key: React.Key) => key !== index),
    );
  };

  const preparerFormNames = clients.map((client) => `preparer_${client.key}`);

  const onPreparerSelect = (value: string) => {
    const newPreparerValues = Object.assign(
      {},
      ...preparerFormNames.map((preparerName) => {
        const map = new Map<string, string>();
        map.set(preparerName, value);
        return Object.fromEntries(map);
      }),
    );
    tableForm.setFieldsValue(newPreparerValues);
    updateSelectedClients();
  };

  const columns = [
    {
      dataIndex: 'isUpdate',
      key: 'import',
      title: 'Import Type',
      render: (isUpdate: boolean) => (isUpdate
        ? (
          <Text
            className={`${styles.importTypeText} ${styles.backgroundOrange}`}
            strong
            small
          >
            Update
          </Text>
        )
        : (
          <Text
            className={`${styles.importTypeText} ${styles.backgroundGreen}`}
            strong
            small
          >
            New Client
          </Text>
        )),
    },
    {
      key: 'sendInvitation',
      title: (
        <div>
          Send Invitation
          <InfoCircleOutlined
            style={{ padding: '0 0 0 10px', cursor: 'pointer' }}
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      ),
      render: (text: undefined, record: ClientEntity) => (
        <Toggle
          checked={selectedInvitations.some((invitation) => invitation === record.key)}
          onChange={(check: boolean) => sendEmailInvitationToggle(record.key, check)}
        />
      ),
    },
    {
      key: 'preparer',
      title: 'Preparer',
      width: 210,
      render: (text: undefined, record: ClientEntity) => (
        <Form.Item
          name={`preparer_${record.key}`}
          label="Preparer"
        >
          <Select
            placeholder="Select a preparer"
            allowClear
            onChange={updateSelectedClients}
          >
            {preparers?.FirmTaxPreparers.map((item) => (
              <Option value={item.id} key={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
      ),
    },
    {
      key: 'jobType',
      title: 'Job Type',
      width: 210,
      render: (text: undefined, record: ClientEntity) => (
        <Form.Item
          name={`jobType_${record.key}`}
          label="Job Type"
          rules={[{ required: true }]}
        >
          <SelectJobType
            onChange={updateSelectedClients}
          />
        </Form.Item>
      ),
    },
  ];

  const generateColumnProc = (key: string) => {
    if (clients.some((cli) => cli[key])) {
      return {
        dataIndex: key,
        key,
        title: importClientProperties[key],
        render: (value: any, record: ClientEntity) => {
          if (key === 'phones') {
            return value?.map((p: any, index: number) => (
              <Paragraph
                key={index}
              >
                {`${p.type[0]}${p.type.slice(1).toLowerCase()}: ${p.value}`}
              </Paragraph>
            ));
          }

          const recordValue = key === 'dob' && moment(value).isValid()
            ? moment.utc(value).format('MM/DD/YYYY')
            : value;

          if (record.updatedFields?.some((field) => field === key)) {
            return (
              <Text
                style={key === 'dob' ? { whiteSpace: 'nowrap' } : {}}
                className={`${styles.updateCell} ${styles.backgroundLightBlue}`}
              >
                {recordValue}
              </Text>
            );
          }
          return (
            <Text style={key === 'dob' ? { whiteSpace: 'nowrap' } : {}}>
              {recordValue}
            </Text>
          );
        },
      };
    }
    return undefined;
  };

  // initial form values
  const jobTypeFormNames = clients.map((client) => `jobType_${client.key}`);
  const jobTypesInitial = Object.assign(
    {},
    ...jobTypeFormNames.map((jtName) => {
      const map = new Map<string, string>();
      const key = Number(jtName.replace('jobType_', ''));
      const value = clients[key].jobType;
      if (value) {
        map.set(jtName, value);
        return Object.fromEntries(map);
      }
      return {};
    }),
  );
  const preparersInitial = Object.assign(
    {},
    ...preparerFormNames.map((preparerName) => {
      const map = new Map<string, string>();
      const key = Number(preparerName.replace('preparer_', ''));
      const value = clients[key].preparer;
      if (value) {
        map.set(preparerName, value);
        return Object.fromEntries(map);
      }
      return {};
    }),
  );

  const newClients = selectedClients.filter((cli) => !cli.isUpdate);
  const updateClients = selectedClients.filter((cli) => cli.isUpdate);

  const generatedColumns = {
    address1: generateColumnProc('address1'),
    address2: generateColumnProc('address2'),
    city: generateColumnProc('city'),
    dob: generateColumnProc('dob'),
    email: generateColumnProc('email'),
    firstName: generateColumnProc('firstName'),
    lastName: generateColumnProc('lastName'),
    phones: generateColumnProc('phones'),
    zip: generateColumnProc('zip'),
    ssn: generateColumnProc('ssn'),
    state: generateColumnProc('state'),
  };

  let sortedColumns = [];
  sortedColumns.push(columns[0]);
  if (generatedColumns.firstName) sortedColumns.push(generatedColumns.firstName);
  if (generatedColumns.lastName) sortedColumns.push(generatedColumns.lastName);
  sortedColumns = sortedColumns.concat(columns.slice(1));
  if (generatedColumns.email) sortedColumns.push(generatedColumns.email);
  if (generatedColumns.phones) sortedColumns.push(generatedColumns.phones);
  if (generatedColumns.dob) sortedColumns.push(generatedColumns.dob);
  if (generatedColumns.ssn) sortedColumns.push(generatedColumns.ssn);
  if (generatedColumns.address1) sortedColumns.push(generatedColumns.address1);
  if (generatedColumns.address2) sortedColumns.push(generatedColumns.address2);
  if (generatedColumns.city) sortedColumns.push(generatedColumns.city);
  if (generatedColumns.state) sortedColumns.push(generatedColumns.state);
  if (generatedColumns.zip) sortedColumns.push(generatedColumns.zip);

  if (loadingGetInterviews || loadingPreparers) {
    return (
      <Row justify="center" className="showScrollbar">
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <section>
      <Form
        layout="vertical"
        form={tableForm}
        // eslint-disable-next-line no-template-curly-in-string
        validateMessages={{ required: 'Required' }}
        initialValues={{
          preparer: undefined,
          jobType: undefined,
          taxYear: undefined,
          sendInvitation: false,
          ...jobTypesInitial,
          ...preparersInitial,
        }}
        style={{ width: '100%' }}
      >
        <Row justify="center" align="middle" gutter={25}>
          {Boolean(newClients.length)
            && (
              <Col>
                <Badge
                  overflowCount={99999999}
                  count={newClients.length}
                  className={styles.badgeClientsCount}
                  style={{ backgroundColor: badgeBgColors.green }}
                />
                <Text>New clients will be created</Text>
              </Col>
            )}
          {Boolean(updateClients.length)
            && (
              <Col>
                <Badge
                  overflowCount={99999999}
                  count={updateClients.length}
                  className={styles.badgeClientsCount}
                  style={{ backgroundColor: badgeBgColors.orange }}
                />
                <Text>Existing clients will be updated</Text>
              </Col>
            )}
          <Col>
            <Badge
              overflowCount={99999999}
              count={selectedClients.length}
              className={styles.badgeClientsCount}
              style={{ backgroundColor: badgeBgColors.blue }}
              showZero
            />
            <Text>Total Clients Imported</Text>
          </Col>
        </Row>

        <Card
          title="Bulk Assignments"
          style={{ margin: '30px', padding: 0 }}
          headStyle={{ textAlign: 'center' }}
          size="small"
          bordered={false}
        >
          <Space style={{ display: 'flex', justifyContent: 'center' }}>
            <Row justify="center" align="middle" gutter={25}>
              <Col>
                <Form.Item
                  name="sendInvitation"
                  label={(
                    <div>
                      Send Invitation
                      <InfoCircleOutlined
                        style={{ padding: '0 0 0 10px', cursor: 'pointer' }}
                        onClick={(e: any) => { setIsModalOpen(true); e.preventDefault(); }}
                      />
                    </div>
                  )}
                >
                  <Toggle
                    onChange={(check: boolean) => {
                      if (check) {
                        setSelectedInvitations(clients.map((c) => c.key));
                      } else {
                        setSelectedInvitations([]);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="preparer"
                  label="Preparer"
                >
                  <Select
                    style={{ width: '200px' }}
                    placeholder="Select a preparer"
                    tool
                    allowClear
                    onSelect={onPreparerSelect}
                    onChange={onPreparerSelect}
                  >
                    {preparers?.FirmTaxPreparers.map((item) => (
                      <Option value={item.id} key={item.id}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="jobType"
                  label="Job Type"
                >
                  <SelectJobType
                    onChange={updateSelectedClients}
                    style={{ width: '200px' }}
                    onSelect={(value: string) => {
                      const newJobTypeValues = Object.assign(
                        {},
                        ...jobTypeFormNames.map((jtName) => {
                          const map = new Map<string, string>();
                          map.set(jtName, value);
                          return Object.fromEntries(map);
                        }),
                      );
                      tableForm.setFieldsValue(newJobTypeValues);
                      updateSelectedClients();
                    }}
                  />
                  {/* <Select
                    style={{ width: '200px' }}
                    placeholder="Select a job"
                    onSelect={(value: string) => {
                      const newJobTypeValues = Object.assign(
                        {},
                        ...jobTypeFormNames.map((jtName) => {
                          const map = new Map<string, string>();
                          map.set(jtName, value);
                          return Object.fromEntries(map);
                        }),
                      );
                      tableForm.setFieldsValue(newJobTypeValues);
                      updateSelectedClients();
                    }}
                  >
                    {jobTypes?.interviewNames
                      .filter((i) => i.isLive)
                      .map((item) => (
                        <Option value={item.id} key={item.id}>{item.name}</Option>
                      ))}
                  </Select> */}
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="taxYear"
                  label="Tax Year"
                  rules={[{ required: true }]}
                >
                  <Select
                    onChange={updateSelectedClients}
                    placeholder="Select a tax year"
                  >
                    {TAX_YEAR_OPTIONS.map(({ value, label }) => (
                      <Option value={value} key={value}>{label}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Card>

        <Row justify="start" align="middle" gutter={15}>
          <Col>
            <TaxarooButton
              type="link"
              size="small"
              onClick={() => setSelectedRowKeys(clients.map((client) => client.key))}
            >
              Select All
            </TaxarooButton>
            <TaxarooButton
              type="link"
              size="small"
              onClick={() => setSelectedRowKeys([])}
            >
              Unselect All
            </TaxarooButton>
            {clients.filter((client) => client.isUpdate).length > 0 && (
              <Tooltip
                color="rgba(0, 0, 0, 0.85)"
                title={(
                  <>
                    Unselect all clients with an
                    {' '}
                    <strong>Import Type</strong>
                    {' '}
                    of
                    {' '}
                    <strong>Update</strong>
                    {' '}
                    (clients that are already on Taxaroo).
                  </>
                )}
              >
                <TaxarooButton
                  type="link"
                  size="small"
                  onClick={() => {
                    const selectedUpdates = selectedClients.filter((client) => client.isUpdate);
                    const selectedRowsNew = selectedRowKeys.filter(
                      (key) => !selectedUpdates.some((client) => client.key === key),
                    );
                    setSelectedRowKeys(selectedRowsNew);
                  }}
                >
                  Unselect All Updates
                </TaxarooButton>
              </Tooltip>
            )}
          </Col>
        </Row>

        <Table
          columns={sortedColumns}
          pagination={{
            defaultPageSize: 10,
          }}
          dataSource={clients}
          rowSelection={{
            type: 'checkbox',
            fixed: true,
            columnWidth: 35,
            ...rowSelection,
          }}
          scroll={{ x: 1800 }}
          // validate form on pagination change
          onChange={(pagination: any) => onPaginationChange(pagination)}
        />

        <Row justify="start" align="middle">
          <Text className={`${styles.updateDetailsMark} ${styles.backgroundLightBlue}`} />
          <Text>Details to be update</Text>
        </Row>
      </Form>

      <Modal
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Close"
      >
        <Row justify="center">
          <Paragraph>
            This email will be sent to your clients upon import
          </Paragraph>
        </Row>
        <Divider style={{ margin: '0 0 20px 0' }} />
        <Paragraph>
          From:
          {' '}
          <Text strong>Your Firm Name</Text>
        </Paragraph>
        <Paragraph>
          To:
          {' '}
          <Text strong>Client Name</Text>
        </Paragraph>

        <div style={{ paddingLeft: '30px' }}>
          <Title
            level={4}
            style={{ margin: 0 }}
          >
            Account activation required in secure portal.
          </Title>
          <Paragraph>
            Please click on the activate button below to activate your account and begin using our secure client portal.
          </Paragraph>
          <Paragraph>
            You can use our secure portal on any device to exchange information, pay invoices, and electronically sign documents and more.
          </Paragraph>
          <Row justify="center">
            <Button
              type="primary"
              onClick={() => setIsModalOpen(false)}
            >
              Activate
            </Button>
          </Row>
        </div>
      </Modal>
    </section>
  );
}; // end tableForm

export default TableForm;
