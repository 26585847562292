import React from 'react';
import * as styles from './style.module.css';

function EmptyState() {
  return (
    <div className={styles.emptyUserSidebarContainer}>
      <div className={styles.avatar} />
      <br />
      <div className={styles.row} />
      <div className={styles.rowmed} />
      <div className={styles.rowsmall} />
      <br />
      <div className={styles.rowbuttons}>
        <div className={styles.button} />
        <div className={styles.button} />
        <div className={styles.button} />
      </div>
      <br />
      <div className={styles.row} />
      <div className={styles.row} />
      <div className={styles.row} />
      <br />
      <div className={styles.row} />
      <div className={styles.row} />
      <div className={styles.row} />
    </div>
  );
}

export default EmptyState;
