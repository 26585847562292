import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResetPasswordMutationVariables = Types.Exact<{
  resetPasswordInput: Types.ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', ResetPassword: string };

export type ActivateClientAccountMutationVariables = Types.Exact<{
  activateClientInput: Types.ActivateClientInput;
}>;


export type ActivateClientAccountMutation = { __typename?: 'Mutation', ActivateClientAccount: string };

export type CreatePasswordResetByEmailMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type CreatePasswordResetByEmailMutation = { __typename?: 'Mutation', createPasswordResetByEmail: { __typename?: 'PasswordResetEntity', expiresAt: any } };


export const ResetPasswordDocument = gql`
    mutation ResetPassword($resetPasswordInput: ResetPasswordInput!) {
  ResetPassword(resetPasswordInput: $resetPasswordInput)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      resetPasswordInput: // value for 'resetPasswordInput'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ActivateClientAccountDocument = gql`
    mutation ActivateClientAccount($activateClientInput: ActivateClientInput!) {
  ActivateClientAccount(activateClientInput: $activateClientInput)
}
    `;
export type ActivateClientAccountMutationFn = Apollo.MutationFunction<ActivateClientAccountMutation, ActivateClientAccountMutationVariables>;

/**
 * __useActivateClientAccountMutation__
 *
 * To run a mutation, you first call `useActivateClientAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateClientAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateClientAccountMutation, { data, loading, error }] = useActivateClientAccountMutation({
 *   variables: {
 *      activateClientInput: // value for 'activateClientInput'
 *   },
 * });
 */
export function useActivateClientAccountMutation(baseOptions?: Apollo.MutationHookOptions<ActivateClientAccountMutation, ActivateClientAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateClientAccountMutation, ActivateClientAccountMutationVariables>(ActivateClientAccountDocument, options);
      }
export type ActivateClientAccountMutationHookResult = ReturnType<typeof useActivateClientAccountMutation>;
export type ActivateClientAccountMutationResult = Apollo.MutationResult<ActivateClientAccountMutation>;
export type ActivateClientAccountMutationOptions = Apollo.BaseMutationOptions<ActivateClientAccountMutation, ActivateClientAccountMutationVariables>;
export const CreatePasswordResetByEmailDocument = gql`
    mutation createPasswordResetByEmail($email: String!) {
  createPasswordResetByEmail(email: $email) {
    expiresAt
  }
}
    `;
export type CreatePasswordResetByEmailMutationFn = Apollo.MutationFunction<CreatePasswordResetByEmailMutation, CreatePasswordResetByEmailMutationVariables>;

/**
 * __useCreatePasswordResetByEmailMutation__
 *
 * To run a mutation, you first call `useCreatePasswordResetByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordResetByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordResetByEmailMutation, { data, loading, error }] = useCreatePasswordResetByEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreatePasswordResetByEmailMutation(baseOptions?: Apollo.MutationHookOptions<CreatePasswordResetByEmailMutation, CreatePasswordResetByEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePasswordResetByEmailMutation, CreatePasswordResetByEmailMutationVariables>(CreatePasswordResetByEmailDocument, options);
      }
export type CreatePasswordResetByEmailMutationHookResult = ReturnType<typeof useCreatePasswordResetByEmailMutation>;
export type CreatePasswordResetByEmailMutationResult = Apollo.MutationResult<CreatePasswordResetByEmailMutation>;
export type CreatePasswordResetByEmailMutationOptions = Apollo.BaseMutationOptions<CreatePasswordResetByEmailMutation, CreatePasswordResetByEmailMutationVariables>;
export const namedOperations = {
  Mutation: {
    ResetPassword: 'ResetPassword',
    ActivateClientAccount: 'ActivateClientAccount',
    createPasswordResetByEmail: 'createPasswordResetByEmail'
  }
}