.invite-container {
  flex: 1;
  display: flex;
}

.spiner-container {
  flex: 1;
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
