import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateClientsImportMutationVariables = Types.Exact<{
  clientsArray: Array<Types.ValidateClientFromFileInput> | Types.ValidateClientFromFileInput;
}>;


export type ValidateClientsImportMutation = { __typename?: 'Mutation', ValidateClientsImport: Array<{ __typename?: 'ValidateClientEntity', address1?: string | null, address2?: string | null, dob?: any | null, city?: string | null, email?: string | null, firstName: string, lastName?: string | null, ssn?: string | null, state?: string | null, zip?: string | null, isUpdate: boolean, updatedFields?: Array<string> | null, preparer?: string | null, jobType?: string | null, taxYear?: number | null, phones?: Array<{ __typename?: 'PhonesEntity', type: Types.PhoneType, value: string }> | null }> };

export type ImportClientsFromFileMutationVariables = Types.Exact<{
  clientsArray: Array<Types.ClientsImportInput> | Types.ClientsImportInput;
}>;


export type ImportClientsFromFileMutation = { __typename?: 'Mutation', ImportClientsFromFile: boolean };


export const ValidateClientsImportDocument = gql`
    mutation ValidateClientsImport($clientsArray: [ValidateClientFromFileInput!]!) {
  ValidateClientsImport(validateClientsArray: $clientsArray) {
    address1
    address2
    dob
    city
    email
    firstName
    lastName
    phones {
      type
      value
    }
    ssn
    state
    zip
    isUpdate
    updatedFields
    preparer
    jobType
    taxYear
  }
}
    `;
export type ValidateClientsImportMutationFn = Apollo.MutationFunction<ValidateClientsImportMutation, ValidateClientsImportMutationVariables>;

/**
 * __useValidateClientsImportMutation__
 *
 * To run a mutation, you first call `useValidateClientsImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateClientsImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateClientsImportMutation, { data, loading, error }] = useValidateClientsImportMutation({
 *   variables: {
 *      clientsArray: // value for 'clientsArray'
 *   },
 * });
 */
export function useValidateClientsImportMutation(baseOptions?: Apollo.MutationHookOptions<ValidateClientsImportMutation, ValidateClientsImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateClientsImportMutation, ValidateClientsImportMutationVariables>(ValidateClientsImportDocument, options);
      }
export type ValidateClientsImportMutationHookResult = ReturnType<typeof useValidateClientsImportMutation>;
export type ValidateClientsImportMutationResult = Apollo.MutationResult<ValidateClientsImportMutation>;
export type ValidateClientsImportMutationOptions = Apollo.BaseMutationOptions<ValidateClientsImportMutation, ValidateClientsImportMutationVariables>;
export const ImportClientsFromFileDocument = gql`
    mutation ImportClientsFromFile($clientsArray: [ClientsImportInput!]!) {
  ImportClientsFromFile(importClientsFromFile: $clientsArray)
}
    `;
export type ImportClientsFromFileMutationFn = Apollo.MutationFunction<ImportClientsFromFileMutation, ImportClientsFromFileMutationVariables>;

/**
 * __useImportClientsFromFileMutation__
 *
 * To run a mutation, you first call `useImportClientsFromFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportClientsFromFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importClientsFromFileMutation, { data, loading, error }] = useImportClientsFromFileMutation({
 *   variables: {
 *      clientsArray: // value for 'clientsArray'
 *   },
 * });
 */
export function useImportClientsFromFileMutation(baseOptions?: Apollo.MutationHookOptions<ImportClientsFromFileMutation, ImportClientsFromFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportClientsFromFileMutation, ImportClientsFromFileMutationVariables>(ImportClientsFromFileDocument, options);
      }
export type ImportClientsFromFileMutationHookResult = ReturnType<typeof useImportClientsFromFileMutation>;
export type ImportClientsFromFileMutationResult = Apollo.MutationResult<ImportClientsFromFileMutation>;
export type ImportClientsFromFileMutationOptions = Apollo.BaseMutationOptions<ImportClientsFromFileMutation, ImportClientsFromFileMutationVariables>;
export const namedOperations = {
  Mutation: {
    ValidateClientsImport: 'ValidateClientsImport',
    ImportClientsFromFile: 'ImportClientsFromFile'
  }
}