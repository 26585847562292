import React, { FC, useMemo } from 'react';
import {
  Row, Alert, TaxarooButton, Icons,
} from 'taxaroo-ui';
import { useGetOnBoardingLinkMutation } from '~src/graphql/mutations/settings';

const { BankOutlined } = Icons;

interface StripeInfoProps {
  connectAccountId?: string;
  isOnBoardingFinished: boolean;
  areStripeInfoLoading: boolean;
  handleSetError: (error?: string) => void;
}

const StripeInfo: FC<StripeInfoProps> = ({
  handleSetError,
  isOnBoardingFinished,
  areStripeInfoLoading,
  connectAccountId: accountId,
}) => {
  const connectAccountId = useMemo(() => accountId, [accountId]);
  const loadingStripeInfo = useMemo(() => areStripeInfoLoading, [areStripeInfoLoading]);
  const finishedOnboarding = useMemo(() => isOnBoardingFinished, [isOnBoardingFinished]);
  const [getOnBoardingLink, { loading: loadingOnboardingLink }] = useGetOnBoardingLinkMutation();

  const handleGetOnboardingLink = async () => {
    try {
      const link = await getOnBoardingLink({
        variables: {
          returnUrl: window.location.href,
        },
      });

      window.open(link.data.GetOnBoardingLink, '_blank');
    } catch (err) {
      handleSetError(err.message);
    }
  };

  return (
    finishedOnboarding
      ? (
        <Row align="middle" justify="center">
          <Alert
            showIcon
            message=""
            description={(
              <>
                Your bank account is successfully linked to Stripe,
                and client payments will now be processed directly to you.
                To update any of your bank information in the future, you can do so directly with
                <a href="https://stripe.com" target="_blank" rel="noreferrer"> Stripe</a>
                .
              </>
            )}
            type="success"
          />
        </Row>
      )
      : (
        <Row align="middle" justify="start">
          {connectAccountId
          && (
            <Alert
              style={{ width: '100%', padding: '15px', marginBottom: '20px' }}
              message="Please connect to Stripe below to invoice clients within Taxaroo"
              type="warning"
              closable
              showIcon
            />
          )}
          <TaxarooButton
            type="primary"
            size="large"
            style={{ cursor: 'pointer' }}
            onClick={handleGetOnboardingLink}
            icon={<BankOutlined />}
            disabled={Boolean(loadingStripeInfo || loadingOnboardingLink)}
          >
            Connect to Stripe
          </TaxarooButton>
        </Row>
      )
  );
};

StripeInfo.defaultProps = {
  connectAccountId: null,
};

export default StripeInfo;
