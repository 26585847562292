import React from 'react';
import { Link } from 'react-router-dom';
import { CUSTOMER_IO_EVENTS, trackCustomerIoEvent } from '~src/components/helpers/customer.io';
import { useAppDispatch, useAppSelector } from '~src/redux/hooks';
import { selectClient, setClientData } from '~src/redux/slices/clientSlice';
import { NameColumnProps } from './props';

export default function NameColumn({
  email,
  phone,
  userId,
  entityId,
  name,
  workNumber,
  languageId,
  landlineNumber,
  userInformationId,
  taxYearInterviewId,
  allTaxYearInterview = false,
}: NameColumnProps) {
  const { jobs } = useAppSelector(selectClient);
  const dispatch = useAppDispatch();
  const to = `/clients/details?entityId=${entityId}${!allTaxYearInterview ? `&taxYearInterviewId=${taxYearInterviewId}` : ''}`;

  const setClientState = () => {
    dispatch(setClientData({
      email,
      entityId,
      name,
      languageId,
      id: userId,
      workNumber,
      landlineNumber,
      userInformationId,
      phoneNumber: phone,
      job: jobs.filter((item) => item.id === taxYearInterviewId)[0] ?? undefined,
    }));
  };

  const handleLinkClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    // setClientState();

    trackCustomerIoEvent(CUSTOMER_IO_EVENTS.JOB_DETAILS, {
      email,
      url: window.location.href,
      to,
    });
  };

  return (
    <Link
      onClick={handleLinkClick}
      to={to}
    >
      <div style={{ whiteSpace: 'nowrap' }}>
        {name}
      </div>
    </Link>
  );
}
