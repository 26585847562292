export default class Users {
  public static getUserName(user: {
    UserInformation?: {
      firstName: string;
      lastName: string;
    }
  }): string {
    const userInfo = user?.UserInformation;
    return userInfo ? `${userInfo.firstName} ${userInfo.lastName}` : null;
  }
}
