import { FC } from 'react';
import { Popover, Row, Typography } from 'taxaroo-ui';
import { lightColors } from '~src/components/helpers/colorPickerOptions';
import { EventStatuses } from '~src/graphql';
import AppointmentModal from '../../atoms/AppointmentModal';
import { UpdateAppointmentProps } from '../NewAppointmentModal/NewAppointmentModal';

const { Text } = Typography;

interface AppointmentProps {
  id: string;
  title: string;
  date: string;
  duration: number;
  participants: Array<string>;
  startDate: string;
  endDate: string;
  meetingId: string;
  status: EventStatuses;
  color: string;
  position: any;
  type?: string;
  isExternal: boolean;
  timezone: string;
  clientId?: string;
  eventTypeId?: string;
  notes?: string;
  handleUpdateEvent: (initialUpdateValues: UpdateAppointmentProps) => void,
  reloadEvents: () => void;
}

const Appointment: FC<AppointmentProps> = ({
  id,
  color,
  date,
  title,
  participants,
  duration,
  meetingId,
  status,
  startDate,
  endDate,
  position,
  type,
  isExternal,
  timezone,
  clientId,
  eventTypeId,
  notes,
  handleUpdateEvent,
  reloadEvents,
}) => {
  const backgroundColor = status !== EventStatuses.Canceled ? color : '#eeeeee';
  return (
    <Popover
      placement={position}
      title={title}
      content={(
        <AppointmentModal
          id={id}
          date={date}
          duration={duration}
          participants={participants}
          start={new Date(startDate)}
          end={new Date(endDate)}
          meeting={meetingId}
          status={status}
          type={type}
          isExternal={isExternal}
          timezone={timezone}
          clientId={clientId}
          eventTypeId={eventTypeId}
          notes={notes}
          handleUpdateEvent={handleUpdateEvent}
          reloadEvents={reloadEvents}
        />
      )}
      trigger="click"
    >
      <Row
        style={{
          backgroundColor,
          width: '100%',
          height: '100%',
          borderRadius: '5px',
        }}
        align="middle"
      >
        <Text
          style={{ color: lightColors.includes(backgroundColor) ? '#565656' : 'azure' }}
        >
          {title}
        </Text>
      </Row>
    </Popover>
  );
};

Appointment.defaultProps = {
  type: 'VIDEO',
  clientId: '',
  eventTypeId: '',
  notes: '',
};

export default Appointment;
