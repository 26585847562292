const status: any = {
  CANCELED: 'Canceled',
  COMPLETE: 'Complete',
  CONFIRMED: 'Confirmed',
  INCOMPLETE: 'Incomplete',
  RESCHEDULED: 'Rescheduled',
  SCHEDULED: 'Scheduled',
};

export default status;
