import { FC, useCallback } from 'react';
import {
  Input, Form, Col, Select, Radio, DatePicker,
} from 'taxaroo-ui';
import PhoneInputField from '~src/components/atoms/PhoneInputField';
import { QuestionType } from '~src/graphql';

const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

interface EditInterviewQuestionProps {
  required: boolean;
  type: QuestionType;
  questionName: string;
  options: Array<{ label: string; value: string }>;
  onAnswerChange: (questionId: string, answer: string) => void;
  currentValue?: any;
  questionId: string;
}

const EditInterviewQuestion: FC<EditInterviewQuestionProps> = ({
  type,
  options,
  required,
  questionName,
  onAnswerChange,
  currentValue,
  questionId,
}) => {
  const onAnswerChangeHandler = useCallback((event) => {
    if (type === QuestionType.Ssn) {
      let ssn = event.target.value.replace(/[^0-9-]/g, '');
      if (ssn.startsWith('-')) {
        ssn = ssn.slice(1);
      }
      if (ssn.indexOf('--') !== -1) {
        ssn = ssn.slice(0, ssn.indexOf('--')) + ssn.slice(ssn.indexOf('--') + 1);
      }
      const onlyDashes = ssn.replace(/[0-9]/g, '');
      if (onlyDashes.length > 2) {
        ssn = ssn.slice(0, ssn.lastIndexOf('-')) + ssn.slice(ssn.lastIndexOf('-') + 1);
      }
      const onlyNums = ssn.replace(/-/g, '');
      if (onlyNums.length > 9 || (onlyNums.length === 9 && ssn[ssn.length - 1] === '-')) {
        ssn = ssn.slice(0, -1);
      }
      return onAnswerChange(questionName, ssn);
    }
    if (type === QuestionType.Float) {
      const value = event.target.value.replace(/[^0-9.]/g, '');
      return onAnswerChange(questionName, value);
    }
    return onAnswerChange(questionName, event.target.value);
  }, [questionName]);

  const onAnswerChangeHandlerAntd = useCallback((value) => {
    onAnswerChange(questionName, value);
  }, [questionName]);

  const getInputType = () => {
    switch (type) {
      case QuestionType.Text:
        return (
          <Input
            onChange={onAnswerChangeHandler}
          />
        );

      case QuestionType.Dropdown:
        return (
          <Select
            onChange={onAnswerChangeHandlerAntd}
          >
            {
              options.map(({ value, label }) => (
                <Option value={value}>
                  {label}
                </Option>
              ))
            }
          </Select>
        );

      case QuestionType.Radio:
        return (
          <Radio.Group
            onChange={onAnswerChangeHandler}
          >
            {
              options.map(({ value, label }) => (
                <Radio value={value}>{label}</Radio>
              ))
            }
          </Radio.Group>
        );

      case QuestionType.Date:
        return (
          <DatePicker
            style={{ width: '100%' }}
            format="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            onChange={onAnswerChangeHandlerAntd}
          />
        );

      case QuestionType.Phone:
        return (
          <PhoneInputField
            onChange={(value: string) => onAnswerChange(questionName, value)}
            phoneNumber={currentValue}
          />
        );

      case QuestionType.TextArea:
        return (
          <TextArea
            onChange={onAnswerChangeHandler}
          />
        );

      case QuestionType.Ssn:
        return (
          <Input
            type="text"
            maxLength="11"
            placeholder="123-45-6789"
            onChange={onAnswerChangeHandler}
          />
        );

      case QuestionType.Float:
        return (
          <Input
            onChange={onAnswerChangeHandler}
          />
        );

      case QuestionType.Email:
        return (
          <Input
            type="email"
            onChange={onAnswerChangeHandler}
          />
        );

      default:
        return (
          <Input
            onChange={onAnswerChangeHandler}
          />
        );
    }
  };

  return (
    <Col sm={10} md={6} lg={20}>
      <Item label={questionName} name={questionId} rules={[{ required }]}>
        {getInputType()}
      </Item>
    </Col>
  );
};

EditInterviewQuestion.defaultProps = {
  currentValue: '',
};

export default EditInterviewQuestion;
