import {
  Table,
} from 'taxaroo-ui';
import type { TableRowSelection, RowSelectMethod } from 'taxaroo-ui/node_modules/antd/es/table/interface';
import type { ColumnsType } from 'taxaroo-ui/node_modules/antd/lib/table';
import ClientFileUsedUpdate, { ClientFileUsedUpdateType } from '~src/components/atoms/ClientFileUsedUpdate';
import DeleteClientFileButton from '~src/components/atoms/DeleteClientFileButton';
import DeleteFirmFileButton from '~src/components/atoms/DeleteFirmFileButton';
import './style.css';
import { humanDate, humanFileSize } from '~src/components/helpers/humanFormat';
import FileNameClickableOpen from '~src/components/atoms/FileNameClickableOpen';
import DeleteClientRequestedFileButton from '~src/components/atoms/DeleteClientRequestedFileButton';
import { useState } from 'react';
import { FileEntity } from '~src/graphql';

export enum DocumentsTableType {
  Client,
  Firm,
  All,
}

type dataType = {
  id: string;
  s3Key: string;
  name: string;
  size: string;
  updateAt: string;
  documentUsed: boolean;
  taxYearInterviewId: string;
  createAt: string;
};

const getColumns = (tableType: DocumentsTableType): ColumnsType<dataType> => ([
  {
    title: 'File Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (text: string, record) => (
      record.s3Key
        ? (
          <FileNameClickableOpen
            id={record.id}
            name={record.name}
            s3Key={record.s3Key}
            source={tableType}
          />
        )
        : (
          <div
            style={{ padding: '0 15px' }}
          >
            {`Pending file request: ${record.name || 'first document'}`}
          </div>
        )
    ),
  },
  {
    title: 'Size',
    dataIndex: 'size',
    key: 'size',
    sorter: (a, b) => {
      if (!a?.size || !b?.size) {
        return 0;
      }
      return parseInt(a.size, 10) - parseInt(b.size, 10);
    },
    render: (size: number, record: any) => (
      record.s3Key ? humanFileSize(size) : ''
    ),
    width: 120,
    align: 'right',
  },
  {
    title: tableType === DocumentsTableType.Firm ? 'Date Uploaded' : 'Date',
    dataIndex: 'createAt',
    key: 'createAt',
    sorter: (a, b) => {
      const dateA = new Date(a.createAt);
      const dateB = new Date(b.createAt);
      if (!dateA || !dateB) {
        return 0;
      }
      return dateA.getTime() - dateB.getTime();
    },
    render: (date: Date) => (
      humanDate(date)
    ),
    width: 140,
    align: 'right',
  },
  {
    title: 'Mark File Entered',
    dataIndex: 'documentUsed',
    key: 'documentUsed',
    render: (value: boolean, record) => (
      record.s3Key && (
        <ClientFileUsedUpdate
          type={ClientFileUsedUpdateType.Used}
          id={record.id}
          used={value}
        />
      )
    ),
    width: 70,
    align: 'center',
  },
  {
    title: 'Mark File Reviewed',
    dataIndex: 'documentReviewed',
    key: 'documentReviewed',
    render: (value: boolean, record) => (
      record.s3Key && (
        <ClientFileUsedUpdate
          type={ClientFileUsedUpdateType.Reviewed}
          id={record.id}
          used={value}
        />
      )
    ),
    width: 70,
    align: 'center',
  },
  {
    title: 'Delete',
    dataIndex: 'delete',
    key: 'delete',
    render: (_, record) => {
      if (!record.s3Key) {
        return (
          <DeleteClientRequestedFileButton
            id={record.id}
            taxYearInterviewId={record.taxYearInterviewId}
          />
        );
      }
      return (
        record.s3Key.split('/').find((e) => e.toLowerCase() === 'firmfiles')
          ? (
            <DeleteFirmFileButton
              s3Key={record.s3Key}
              taxYearInterviewIdFromParent={record.taxYearInterviewId}
            />
          )
          : (
            <DeleteClientFileButton
              s3Key={record.s3Key}
              taxYearInterviewIdFromParent={record.taxYearInterviewId}
            />
          )
      );
    },
    align: 'center',
    width: 50,
  },
]);

type DataType = FileEntity & {
  key: React.Key;
};

interface DocumentsTableProps {
  tableType: DocumentsTableType;
  tableData: any[];
  loading: any;
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
}

function DocumentsTable({
  tableType,
  tableData,
  loading,
  selectedRowKeys,
  setSelectedRowKeys,
}: DocumentsTableProps) {
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: DataType[], info: { type: RowSelectMethod }) => {
    // unselect
    if (info.type === 'all' && tableData.length === selectedRowKeys.length) { // if selecting all but all already selected
      setSelectedRowKeys([]);
      return;
    }
    // select all
    if (info.type === 'all' && newSelectedRowKeys.length > 0) {
      setSelectedRowKeys(tableData.map((x) => x.id));
      return;
    }
    // otherwise
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    columnWidth: 55,
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: DataType) => ({
      disabled: !record.key, // Disable checkbox based on some property and conditions
    }),
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
    });
  };

  return (
    <Table
      dataSource={tableData}
      columns={getColumns(tableType)}
      loading={loading}
      scroll={{ x: true }}
      rowSelection={rowSelection}
      pagination={tableParams.pagination}
      onChange={handleTableChange}
    />
  );
}

export default DocumentsTable;
